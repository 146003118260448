import React from 'react';
import { Button, Text, PageLoader } from 'new-ui';
import { getText } from '../../../../i18n';

import CloudPayments from '../../CloudPayments';

import { PATTERN } from '../../../bi/constants/dateFormats';

import MoneyFormat from '../../../bi/utils/money';
import { isSmartAgent } from '../../../bi/utils/env';
import { formatDate, getMoment } from '../../../bi/utils/formatDate';
import { MainAnalytic } from '../../../bi/utils/analytics';

import { ITotal, IDetails } from '../../../bi/types/report';

import styles from '../styles/total.module.css';

const LABELS = {
  NO_DEBTS: getText('components:operationsTable.total.noDebts'),
  OVERPAYMENT: getText('components:operationsTable.total.overpayment'),
  DEBTS_BY_DATE: (date: string) => getText('components:operationsTable.total.debtsByDate', { date }),
  INVOICE_FOR_DEBT: getText('components:operationsTable.total.invoiceByDebt'),
  TOTAL: getText('components:operationsTable.total.total'),
  CREATE_INVOICE: getText('components:operationsTable.total.buttonNames.createInvoice'),
  INVOICE_RANDOM: getText('components:operationsTable.total.buttonNames.invoiceRandom'),
};

const MAX_OVERDRAFT = 21000;

const getButtonName = (overdraft: number | null) => (overdraft && overdraft < MAX_OVERDRAFT ? LABELS.CREATE_INVOICE : LABELS.INVOICE_RANDOM);

const renderDetail = (
  notRenderDetails: boolean,
  { NoDebts, IsOverpayment, Overpayment, Debt }:IDetails) => {
  if (notRenderDetails) {
    return null;
  }

  if (NoDebts) {
    return <div className={ styles.detail }><Text type='NORMAL_18'>{ LABELS.NO_DEBTS }</Text></div>;
  }

  if (IsOverpayment) {
    const moneyOverpayment = MoneyFormat.moneyWithDecimal(Overpayment, true);

    return (
      <div className={ styles.detail }>
        <Text type='NORMAL_18'>{ LABELS.OVERPAYMENT }</Text>
        &nbsp;
        <Text type='NORMAL_18' color='red'>{ moneyOverpayment }</Text>
      </div>
    );
  }

  const moneyDebt = MoneyFormat.moneyWithDecimal(Debt);
  const date = formatDate(getMoment(), PATTERN.DAY_OF_MONTH_LONG);

  return (
    <div className={ styles.detail }>
      <div className={ styles.detail_title }>
        <Text type='NORMAL_18'>{LABELS.DEBTS_BY_DATE(date)}</Text>
        &nbsp;
        <Text type='NORMAL_18' color='red'>{ moneyDebt }</Text>
      </div>
    </div>
  );
};

const Total = ({
  total,
  notRenderDetails,
  onOpenDialog,
  onOpenDownloadInvoiceDialog,
  overdraft = null,
  showFinanceDetails,
  isDemo,
  loading,
  email,
  companyName,
  companyInn,
  companyId,
  companies,
  paymentService,
  allCompanies,
  multi,
}: ITotal) => {
  const detailHtml = renderDetail(notRenderDetails, total);
  const { NoDebts, IsOverpayment, Debit, Credit } = total;
  const secondaryTheme = 'secondary';

  const handleOpenDialog = () => {
    onOpenDialog();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.PREPAY_INVOICE_PRESSED);
  };

  const handleOpenDownloadInvoiceDialog = () => {
    onOpenDownloadInvoiceDialog();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.DEB_INVOICE_PRESSED);
  };

  const handleCartPressed = () => MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.CART_PRESSED);

  const invoiceDebtsButtonHtml = !notRenderDetails && !NoDebts && !IsOverpayment && (
    <Button
      type={ secondaryTheme }
      onClick={ handleOpenDownloadInvoiceDialog }
      className={ styles.button }
    >
      { LABELS.INVOICE_FOR_DEBT }
    </Button>
  );

  const depositInvoiceHtml = (
    <Button
      className={ styles.button }
      type='secondary'
      onClick={ handleOpenDialog }
    >
      { getButtonName(overdraft) }
    </Button>);

  const dontHaveIsShowReporting = multi ? !companies.length : !allCompanies.find(({ IsShowReporting }) => IsShowReporting);

  const renderPaymnentButtonForSmartWay = isSmartAgent || isDemo || dontHaveIsShowReporting ? null : (
    <>
      <CloudPayments
        email={ email }
        companyName={ companyName }
        companyInn={ companyInn }
        companyId={ companyId }
        companies={ companies }
        paymentService={ paymentService }
        allCompanies={ allCompanies }
        onClickAnalytics={ handleCartPressed }
      />
    </>
  );

  const notShowReportPayment = allCompanies.find(({ IsShowReporting, CompanyName: currentCompanyName }) =>
    !IsShowReporting && companyName === currentCompanyName) || false;

  const renderPaymnentButtonForSmartAgent = !isSmartAgent || isDemo || dontHaveIsShowReporting ? null : (
    <>
      <CloudPayments
        buttonType={ secondaryTheme }
        email={ email }
        companyName={ companyName }
        companyInn={ companyInn }
        companyId={ companyId }
        companies={ companies }
        paymentService={ paymentService }
        allCompanies={ allCompanies }
        onClickAnalytics={ handleCartPressed }
        notShowReportPayment={ notShowReportPayment }
      />
    </>
  );

  const renderLoading = (
    <div className={ styles.empty_wrap }>
      <PageLoader/>
    </div>
  );

  const sumHtml = showFinanceDetails ? (
    <div className={ styles.total }>
      <Text type='NORMAL_18' className={ styles.total_title }>{ LABELS.TOTAL }</Text>
      <Text type='SEMIBOLD_18' className={ styles.total_col }>
        { MoneyFormat.moneyWithDecimal(Credit, true) }
      </Text>
      <Text type='SEMIBOLD_18' className={ styles.total_col }>
        { MoneyFormat.moneyWithDecimal(Debit, true) }
      </Text>
    </div>
  ) : null;

  const renderSum = loading ? renderLoading : sumHtml;

  return (
    <div className={ styles.wrap }>
      { renderSum }
      <div className={ styles.bottom }>
        { detailHtml }
        <div className={ styles.buttons }>
          { invoiceDebtsButtonHtml }
          { depositInvoiceHtml }
          { renderPaymnentButtonForSmartWay }
          { renderPaymnentButtonForSmartAgent }
        </div>
      </div>
    </div>
  );
};

export { Total };
