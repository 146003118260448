import React, { FC } from 'react';

import { Tooltip, Text, LinkButton } from 'new-ui';

import { getText } from '../../../i18n';

import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import styles from './styles/index.module.css';

const LABELS = {
  warning: getText('components:nameErrorsTravelPolicy.tpWarning'),
};

interface NameErrorsTravelPolicyProps {
  item: object,
  applyTP: boolean,
  oneTravelPolicy: boolean,
  hiddenNames?: boolean,
  travelPolicyList: any[],
  selectedTravelPolicy?: string,
  className?: string,
  position?: string,
}

const NameErrorsTravelPolicy: FC<NameErrorsTravelPolicyProps> = ({
  item: {
    // @ts-ignore
    TravelPolicy: {
      Errors,
    },
  },
  applyTP,
  oneTravelPolicy = false,
  travelPolicyList,
  selectedTravelPolicy = '',
  className = '',
  hiddenNames = false,
  position = 'bottom',
}: NameErrorsTravelPolicyProps) => {
  if (!applyTP) {
    return null;
  }

  const arrayErrors = Object.keys(Errors);
  const travelPolicyCurrentList = travelPolicyList.filter(tp => !!arrayErrors.find(id => tp.Id === id));

  if (!travelPolicyCurrentList.length) {
    return null;
  }

  const tooltipClassNames = [styles.tooltip];

  if (className) {
    tooltipClassNames.push(className);
  }

  const labelsHtml = oneTravelPolicy
    ? travelPolicyCurrentList.find(tp => tp.Id === selectedTravelPolicy).Name
    : travelPolicyCurrentList.map(tpCurrent => tpCurrent.Name).join(', ');

  const tooltipHtml = oneTravelPolicy
    ? (
      <Text color='white' type='NORMAL_14_130'>
        { Errors[selectedTravelPolicy] }
      </Text>
    )
    : arrayErrors.map((error, index) => (
      <Text color='white' type='NORMAL_14_130' key={ index }>{ Errors[error] }</Text>),
    );

  const namesHtml = !hiddenNames ? `: ${labelsHtml}` : '';

  return (
    <Tooltip
      // @ts-ignore
      position={ position }
      className={ tooltipClassNames.join(' ') }
      renderContent={ () => (
        <div className={ styles['tooltip-wrapper'] }>
          { tooltipHtml }
        </div>
      ) }
    >
      <LinkButton
        className={ styles.link }
        theme='red'
        qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.warning.header }
      >
        {LABELS.warning}{namesHtml}
      </LinkButton>
    </Tooltip>
  );
};

export { NameErrorsTravelPolicy };
