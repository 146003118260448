import React from 'react';
import { Progress } from 'new-ui';
import { observer } from 'mobx-react';

import { getText } from '../../../i18n';

import { withStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { clusterMinContentLayout } from './components/clusterContent';
import { myMinBalloonContentLayout, myBalloonLayout } from './components/balloon';
import { iconMinContentLayout } from './components/iconContent';

import { preloadOrigUrl } from '../../bi/utils/images';
import { getFirstThirtyHotels, throttleQueue } from '../../bi/utils/yandexMap';
import { prepareRating } from '../../bi/utils/hotels';
import { isSmartAgent } from '../../bi/utils/env';
import api from './utils/api';

import { CONSTANTS } from '../../bi/constants/yandexMap';

import {
  clusterOptions,
  placeMarkOptions,
  placeMarkPointOptions,
  selectionOptions,
} from './options';

import { Placemarks } from '../../bi/services/hotels/types';

import smartHotelRedIcon from './images/smart-hotel-min.svg';
import smartHotelGreenIcon from './images/smart-hotel-green-min.svg';
import wallet from './images/wallet.svg';
import closeButtonIcon from './images/map-close.svg';

import noPhoto from '../NoPhoto/styles/images/camera.svg';

import './styles/index.css';

const {
  CONTROLS: {
    GEOLOCATIONCONTROL,
    ROUTEEDITOR,
    RULERCONTROL,
    ZOOMCONTROL,
  },
  SEARCHCONTROLPROVIDERS: {
    YANDEXSEARCH,
  },
} = CONSTANTS;

const LABELS = {
  LOADING: '...',
  NO_PHOTO: getText('components:noPhoto.title'),
  SELECT_ROOM: getText('components:yaMap.selectRoom'),
  FROM: getText('components:yaMap.from'),
  FOR_AMOUNT: getText('components:yaMap.forAmount'),
};

interface YandexMapsProps {
  withSelection?: boolean,
  selectionRadius?: number,
  singleMarker?: boolean,
  placeMarks?: Placemarks[],
  width?: string,
  height?: string,
  type?: string,
  background?: boolean,
  customPlacemarks?: boolean,
  progress?: number,
  APIKEY: string,
  nightsCount?: string,
  center: number[],
  zoom?: number,
  itemsMapIds?: number[],
  qaAttr?: string,
  onTogglePoint?(coords?: number[]): void,
  onToggleSelection?(coords?: number[]): void,
  onMapLoaded?(): void,
  generateLink?(hotelId: number, defaultLink: boolean): string,
  onFormatMoney?(value: number, withSymbol?: boolean): string,
  onResetRadius?(): void,
  onUpdateVisibleHotel(ids: number[], value?: boolean): void,
  stores?: any,
  setCacheOnMapToggler?: boolean,
  agentMode?: boolean,
}

interface YandexMapsState {
  ymaps: any,
  selection: any,
  point: any,
  usePoint: boolean,
  openPlacemark: boolean,
}

// @ts-ignore
@withStores([MOBX_STORES.NEW_HOTELS_STORE])
@observer
class YandexMaps extends React.Component<YandexMapsProps, YandexMapsState> {
  // ---------
  declare readonly props: YandexMapsProps &
  Required<Pick<YandexMapsProps, keyof typeof YandexMaps.defaultProps>>;
  // ---------

  timeoutId: ReturnType<typeof setTimeout> | undefined;
  geoPlaceMarks: any[];
  throttledAddPlaceMarks:(placeMarks?: Placemarks[]) => void;
  mapNode: HTMLDivElement;

  constructor(props: YandexMapsProps) {
    super(props);

    this.state = {
      ymaps: null,
      selection: null,
      point: null,
      usePoint: false,
      openPlacemark: false,
    };

    this.geoPlaceMarks = [];
    // @ts-ignore
    this.throttledAddPlaceMarks = throttleQueue(this.addPlaceMarks, 300);
  }

  static defaultProps: Partial<YandexMapsProps> = {
    singleMarker: false,
    placeMarks: [],
    onFormatMoney: () => '',
    generateLink: () => '',
    onMapLoaded: () => {},
    width: '100%',
    height: '100%',
    type: 'yandex#map',
    background: false,
    progress: 0,
    nightsCount: '',
    zoom: CONSTANTS.DEFAULT_ZOOM,
    customPlacemarks: false,
    withSelection: false,
    selectionRadius: 0,
    itemsMapIds: [],
    onTogglePoint: () => {},
    onToggleSelection: () => {},
    onResetRadius: () => {},
    onUpdateVisibleHotel: () => {},
    qaAttr: '',
    agentMode: false,
  };

  componentDidMount() {
    const { APIKEY } = this.props;
    const { QUERY, VERSION } = CONSTANTS.PARAMS;
    api.get(QUERY, VERSION, APIKEY)
      .then(ymaps => this.setState({ ymaps }, () => this.mount()));
  }

  componentDidUpdate(prevProps: YandexMapsProps, prevState: YandexMapsState) {
    const {
      withSelection,
      placeMarks,
      selectionRadius,
      onToggleSelection,
      onTogglePoint,
      setCacheOnMapToggler,
      itemsMapIds,
      onUpdateVisibleHotel,
      agentMode,
    } = this.props;
    const { usePoint, selection, point } = this.state;

    if (prevProps.agentMode !== agentMode) {
      this.forceUpdate();
    }

    const unequals = JSON.stringify(placeMarks) !== JSON.stringify(prevProps.placeMarks);

    if (prevProps.setCacheOnMapToggler !== setCacheOnMapToggler) {
      onUpdateVisibleHotel(itemsMapIds, false);
    }

    if (!withSelection && unequals) {
      this.throttledAddPlaceMarks(placeMarks);

      return;
    }

    if (!selection && unequals) {
      this.throttledAddPlaceMarks(placeMarks);
    }

    if (prevProps.selectionRadius !== selectionRadius && !selectionRadius) {
      if (!usePoint && selection) {
        this.removeSelection();
        this.removePoint();
        onToggleSelection();
      }

      if (usePoint && selection) {
        this.removeSelection();
        onToggleSelection(point.geometry.getCoordinates());
        onTogglePoint(point.geometry.getCoordinates());
      }

      return;
    }

    if (prevProps.selectionRadius !== selectionRadius && selectionRadius) {
      this.initSelection();
    }

    if (
      unequals
      || prevProps.selectionRadius !== selectionRadius
      || selection !== prevState.selection
    ) {
      this.addPlaceMarksWithinSelection();
    }
  }

  componentWillUnmount() {
    const {
      stores: {
        newHotelsStore: {
          mapInstance,
        },
      },
    } = this.props;

    if (mapInstance) mapInstance.destroy();

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  initSelection = () => {
    const { center, onToggleSelection } = this.props;
    const { point } = this.state;

    const coords = point ? point.geometry.getCoordinates() : center;

    if (!point) {
      this.createOrUpdatePoint(coords);
    }

    this.createOrUpdateSelection(coords);
    onToggleSelection(coords);

    this.setBoundsForMap();
  };

  getMapNode = (ref: HTMLDivElement) => {
    this.mapNode = ref;
  };

  addPlaceMarksWithinSelection = () => {
    const { placeMarks, onUpdateVisibleHotel } = this.props;
    const { ymaps, selection } = this.state;

    if (!selection) {
      return;
    }

    const query = ymaps.geoQuery(placeMarks.map(this.createGeoPlacemark));
    const placemarksWithInSelection = query.searchInside(selection);

    const newPlacemarks: Placemarks[] = [];

    // @ts-ignore
    placemarksWithInSelection.each(({ properties }) => {
      const placeMark = placeMarks.find(({ ClassificatorId }) => properties.get('hotelId') === ClassificatorId);

      if (!placeMark) {
        return;
      }

      newPlacemarks.push(placeMark);
    });

    const visiblePlacemarksIds = newPlacemarks.map(({ ClassificatorId }) => ClassificatorId);
    onUpdateVisibleHotel(visiblePlacemarksIds);

    this.throttledAddPlaceMarks(newPlacemarks);
  };

  insideFilterToMap = (ids: number[]) => {
    const {
      stores: {
        newHotelsStore: {
          clusterInstance,
        },
      },
    } = this.props;

    if (!ids.length) {
      clusterInstance.removeAll();

      return;
    }

    const geoObjectsToRemove = this.geoPlaceMarks
      .filter(({ properties }) => ids.some(id => id !== properties.get('hotelId')));

    let geoObjectsToAdd = this.geoPlaceMarks
      .filter(({ properties }) => ids.some(id => id === properties.get('hotelId')));

    if (geoObjectsToAdd.length > CONSTANTS.PLACEMARKS_MAX_COUNT_ON_MAP) {
      geoObjectsToAdd = geoObjectsToAdd.slice(0, CONSTANTS.PLACEMARKS_MAX_COUNT_ON_MAP);
    }

    clusterInstance.remove(geoObjectsToRemove);
    clusterInstance.add(geoObjectsToAdd);
  };

  // @ts-ignore
  handleMapClick = (event) => {
    const { withSelection, onTogglePoint } = this.props;

    if (!withSelection) {
      return;
    }

    this.setState({ usePoint: true });

    const coords = event.get('coords');
    this.createOrUpdatePoint(coords);

    onTogglePoint(coords);
  };

  getVisiblePlaceMarks = (placeMarks: Placemarks[]) => {
    const { ymaps } = this.state;
    const {
      stores: {
        newHotelsStore: {
          mapInstance,
        },
      },
    } = this.props;

    const query = ymaps.geoQuery(this.geoPlaceMarks);
    const visibleGeoObjects = query.searchInside(mapInstance);

    const visiblePlacemarks: any[] = [];

    // @ts-ignore
    visibleGeoObjects.each(({ properties }) => {
      const placeMark = placeMarks.find(({ ClassificatorId }) => properties.get('hotelId') === ClassificatorId);

      if (!placeMark) {
        return;
      }

      visiblePlacemarks.push(placeMark);
    });

    return visiblePlacemarks;
  };

  updateVisiblePlaceMarks = () => {
    const {
      placeMarks,
      onUpdateVisibleHotel,
      stores: {
        newHotelsStore,
      },
    } = this.props;

    const visiblePlacemarks = this.getVisiblePlaceMarks(placeMarks);
    const visiblePlacemarksIds = visiblePlacemarks.map(({ ClassificatorId }) => ClassificatorId);

    const unequals = JSON.stringify(visiblePlacemarksIds) !== JSON.stringify(newHotelsStore.visiblePlacemarksIds);

    if (unequals) {
      newHotelsStore.setVisiblePlacemarksIds(visiblePlacemarksIds);
      onUpdateVisibleHotel(visiblePlacemarksIds);
      this.insideFilterToMap(visiblePlacemarksIds);
    }
  };

  handleMapBoundsChange = () => {
    const { selection, openPlacemark } = this.state;
    const {
      selectionRadius,
      singleMarker,
    } = this.props;

    if (
      selection
      || selectionRadius
      || singleMarker
      || openPlacemark
    ) {
      return;
    }

    this.updateVisiblePlaceMarks();
  };

  handlePreloadImg = async (target: any) => {
    const res = await preloadOrigUrl(target.properties.get('img'));

    if (!res) {
      target.properties.set({
        noPhoto: true,
        img: noPhoto,
      });
    }
  };

  // @ts-ignore
  handlePlacemarkClick = async (e) => {
    this.setState({ openPlacemark: true });
    const target = e.get('target');
    await this.handlePreloadImg(target);
  };

  createOrUpdatePoint = (coords: number[]) => {
    const {
      stores: {
        newHotelsStore: {
          mapInstance,
        },
      },
    } = this.props;
    const { ymaps, point: existingPoint, selection } = this.state;

    if (existingPoint) {
      existingPoint.geometry.setCoordinates(coords);
      mapInstance.panTo(coords, { flying: true });

      if (selection) {
        this.handleUpdateSelection(coords);
        this.addPlaceMarksWithinSelection();
      }

      return;
    }

    const point = new ymaps.Placemark(coords, {}, placeMarkPointOptions);

    point.events.add('click', this.handlePointClick);
    point.events.add('drag', this.handlePointDrag);
    point.events.add('dragend', this.handlePointDragend);

    mapInstance.geoObjects.add(point);
    mapInstance.panTo(coords, { flying: true });

    this.setState({ point });
  };

  removePoint = () => {
    const {
      stores: {
        newHotelsStore: {
          mapInstance,
        },
      },
    } = this.props;
    const { point } = this.state;

    mapInstance.geoObjects.remove(point);
    this.setState({ point: null, usePoint: false });
  };

  removePointWithUsePoint = () => {
    const { selection } = this.state;
    const { onTogglePoint, onToggleSelection, onResetRadius, placeMarks } = this.props;

    if (selection) {
      this.removePoint();
      this.removeSelection();
      onResetRadius();
      onTogglePoint();
      onToggleSelection();
      this.throttledAddPlaceMarks(placeMarks);
      this.updateVisiblePlaceMarks();
    } else {
      this.removePoint();
      onTogglePoint();
    }
  };

  removePointWithoutUsePoint = () => {
    const { onToggleSelection, onResetRadius } = this.props;

    this.removePoint();
    this.removeSelection();
    onResetRadius();
    onToggleSelection();
  };

  handlePointClick = () => {
    const { usePoint } = this.state;

    if (usePoint) {
      this.removePointWithUsePoint();
    } else {
      this.removePointWithoutUsePoint();
    }
  };

  handlePointDrag = () => {
    const { point, selection: existingSelection } = this.state;

    if (existingSelection) {
      const coords = point.geometry.getCoordinates();
      existingSelection.geometry.setCoordinates(coords);
    }
  };

  handlePointDragend = () => {
    const {
      stores: {
        newHotelsStore: {
          mapInstance,
        },
      },
    } = this.props;
    const { point, selection } = this.state;
    const { onTogglePoint } = this.props;
    const coords = point.geometry.getCoordinates();
    mapInstance.panTo(coords, { flying: true });

    onTogglePoint(coords);

    this.setState({ usePoint: true }, () => {
      if (selection) {
        this.handleUpdateSelection(coords);
        this.addPlaceMarksWithinSelection();
      }
    });
  };

  handleUpdateSelection = (coords: number[] = []) => {
    const { onToggleSelection } = this.props;

    this.createOrUpdateSelection(coords);

    this.setBoundsForMap();
    onToggleSelection(coords);
  };

  createOrUpdateSelection = (coords: number[]) => {
    const {
      stores: {
        newHotelsStore: {
          mapInstance,
        },
      },
    } = this.props;
    const { ymaps, selection: existingSelection } = this.state;
    const { selectionRadius } = this.props;

    if (existingSelection) {
      existingSelection.geometry.setRadius(selectionRadius * 1000);
      existingSelection.geometry.setCoordinates(coords);

      return;
    }

    const selection = new ymaps.Circle([coords, selectionRadius * 1000], {}, selectionOptions);

    selection.events.add('click', this.handleSelectionClick);

    mapInstance.geoObjects.add(selection);

    this.setState({ selection });
  };

  handleSelectionClick = () => {
    const { usePoint } = this.state;
    const { onToggleSelection, onResetRadius, placeMarks } = this.props;

    if (usePoint) {
      this.removeSelection();
      onToggleSelection();
      onResetRadius();
    } else {
      this.removeSelection();
      this.removePoint();
      onToggleSelection();
      onResetRadius();
    }

    this.throttledAddPlaceMarks(placeMarks);
  };

  removeSelection = () => {
    const {
      stores: {
        newHotelsStore: {
          mapInstance,
        },
      },
    } = this.props;
    const { selection } = this.state;

    mapInstance.geoObjects.remove(selection);
    this.setState({ selection: null });
  };

  setBoundsForMap() {
    const {
      stores: {
        newHotelsStore: {
          mapInstance,
        },
      },
    } = this.props;

    setTimeout(() => {
      mapInstance.setBounds(mapInstance.geoObjects.getBounds(), {
        checkZoomRange: true,
      });
    }, 400);
  }

  mount = () => {
    const { ymaps } = this.state;
    const {
      center,
      zoom,
      type,
      background,
      placeMarks,
      withSelection,
      selectionRadius,
      stores: {
        newHotelsStore,
      },
      onMapLoaded,
    } = this.props;

    let behaviors;
    const controls = [];
    const options = {
      searchControlProvider: YANDEXSEARCH,
      suppressMapOpenBlock: true,
      fullscreenZIndex: 100000,
    };
    const fullScreenOptions = {
      options: {
        float: 'left',
        position: { left: 410, top: 10 },
      },
    };
    const searchControlOptions = {
      options: {
        noSuggestPanel: true,
      },
    };

    if (background) {
      behaviors = [];
      options.suppressMapOpenBlock = true;
    } else {
      controls.push(GEOLOCATIONCONTROL, ROUTEEDITOR, RULERCONTROL, ZOOMCONTROL);
    }

    const mapInstance = new ymaps.Map(this.mapNode, { center, zoom, behaviors, type, controls }, options);

    const fullScreenControl = new ymaps.control.FullscreenControl(fullScreenOptions);
    const searchControl = new ymaps.control.SearchControl(searchControlOptions);

    mapInstance.controls.add(fullScreenControl);
    mapInstance.controls.add(searchControl);

    const clusterInstance = new ymaps.Clusterer({
      ...clusterOptions,
      clusterIconContentLayout: clusterMinContentLayout(ymaps, isSmartAgent),
    });

    clusterInstance.events.add(['mouseenter', 'mouseleave'], (e: any) => {
      const target = e.get('target');
      const typeEvent = e.get('type');

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      if (typeof target.getGeoObjects === 'undefined') {
        if (typeEvent === 'mouseenter' && !target.balloon.isOpen()) {
          this.handlePlacemarkClick(e);

          this.timeoutId = setTimeout(() => {
            target.balloon.open();
          }, 500);
        }

        if (typeEvent === 'mouseleave' && !target.balloon.isOpen()) {
          this.setState({ openPlacemark: false });
          clearTimeout(this.timeoutId);
        }
      }
    });

    mapInstance.geoObjects.add(clusterInstance);

    mapInstance.events.add('click', this.handleMapClick);
    mapInstance.events.add('boundschange', this.handleMapBoundsChange);

    newHotelsStore.setMapInstance(mapInstance);
    newHotelsStore.setClusterInstance(clusterInstance);

    this.throttledAddPlaceMarks(placeMarks);

    onMapLoaded();

    if (withSelection && selectionRadius) {
      this.initSelection();
    }
  };

  renderStars = (stars = 0) => {
    const html = [];

    for (let i = 0; i < stars; i++) {
      html.push('star');
    }

    return html;
  };

  createGeoPlacemark = ({
    Name,
    MainImageUrl,
    Address,
    Latitude,
    Longitude,
    Stars,
    rate,
    ClassificatorId,
    Rating,
    hideMarkerPrice,
  }: Placemarks) => {
    const {
      generateLink,
      customPlacemarks,
      nightsCount,
      onFormatMoney,
      singleMarker,
      agentMode,
    } = this.props;
    const { ymaps } = this.state;

    const hasPrice = !!rate && !!rate.Price && rate.Price.TotalPrice;
    const hasAgentFee = !!rate && !!rate.Price && rate.Price.AgentFee;

    const pricePlaceholder = singleMarker && !hideMarkerPrice ? LABELS.LOADING : '';

    const price = hasPrice
      ? onFormatMoney(rate.Price.TotalPrice, true)
      : pricePlaceholder;

    const agentFee = hasAgentFee
      ? onFormatMoney(rate.Price.AgentFee, true)
      : pricePlaceholder;

    const rating = Rating ? prepareRating(Rating.Value) : 0;

    const smartHotelIcon = isSmartAgent ? smartHotelGreenIcon : smartHotelRedIcon;
    const walletIcon = isSmartAgent && wallet;

    const placemark = new ymaps.Placemark(
      [Latitude, Longitude],
      {
        name: Name,
        img: MainImageUrl,
        noPhoto: false,
        address: Address,
        price,
        agentFee,
        stars: this.renderStars(Stars),
        smartHotelIcon,
        isSmartAgent,
        agentMode,
        walletIcon,
        closeButtonIcon,
        nights: nightsCount,
        rating,
        hotelId: ClassificatorId,
        labels: LABELS,
        active: false,
      }, {
        ...placeMarkOptions,
        iconContentLayout: iconMinContentLayout(ymaps),
        balloonLayout: customPlacemarks && myBalloonLayout(ymaps),
        balloonContentLayout: customPlacemarks && myMinBalloonContentLayout(ymaps, generateLink, ClassificatorId, Name, rating),
      },
    );

    placemark.events.add('click', (e: any) => {
      const target = e.get('target');

      if (!target.balloon.isOpen()) {
        this.handlePlacemarkClick(e);
      }

      window.open(generateLink(ClassificatorId, true), '_blank');
    });

    placemark.events.add('balloonclose', () => {
      this.setState({ openPlacemark: false });
    });

    return placemark;
  };

  addPlaceMarks = (newPlacemarks: Placemarks[]): void => {
    const { ymaps, selection } = this.state;
    const {
      singleMarker,
      stores: {
        newHotelsStore: {
          clusterInstance,
        },
      },
      itemsMapIds,
    } = this.props;

    if (!newPlacemarks || !ymaps || !clusterInstance) return;

    let geoObjectsToAdd: any[] = [];
    const geoObjectsToRemove: any[] = [];
    const geoObjectsToUpdate: any[] = [];

    newPlacemarks.forEach((newPlaceMark) => {
      const equalHotelId = this.geoPlaceMarks
        .some(geoPlaceMark => newPlaceMark.ClassificatorId === geoPlaceMark.properties.get('hotelId'));
      const unequalPrice = this.geoPlaceMarks
        .some(geoPlaceMark => newPlaceMark.ClassificatorId === geoPlaceMark.properties.get('hotelId')
        && (newPlaceMark.rate && newPlaceMark.rate.Price.TotalPrice !== geoPlaceMark.properties.get('price'))
        && (newPlaceMark.rate && newPlaceMark.rate.Price.AgentFee !== geoPlaceMark.properties.get('agentFee')));

      if (!equalHotelId || singleMarker) {
        geoObjectsToAdd.push(this.createGeoPlacemark(newPlaceMark));
      }

      if (unequalPrice) {
        geoObjectsToUpdate.push(this.createGeoPlacemark(newPlaceMark));
      }
    });

    geoObjectsToAdd = [...geoObjectsToAdd, ...geoObjectsToUpdate];

    this.geoPlaceMarks.forEach((geoPlaceMark) => {
      const equalHotelIdNewPlacemarks = newPlacemarks
        .some(newPlaceMark => newPlaceMark.ClassificatorId === geoPlaceMark.properties.get('hotelId'));

      const equalHotelIdUpdatePlacemarks = geoObjectsToUpdate
        .some(newPlaceMark => newPlaceMark.properties.get('hotelId') === geoPlaceMark.properties.get('hotelId'));

      if (!equalHotelIdNewPlacemarks || singleMarker) {
        geoObjectsToRemove.push(geoPlaceMark);
      }

      if (equalHotelIdUpdatePlacemarks) {
        geoObjectsToRemove.push(geoPlaceMark);
      }
    });

    const updatedGeoPlaceMarks = this.geoPlaceMarks
      .filter(item => !geoObjectsToRemove
        .some(placemark => item.properties.get('hotelId') === placemark.properties.get('hotelId')),
      );

    this.geoPlaceMarks = [...updatedGeoPlaceMarks, ...geoObjectsToAdd];

    if (geoObjectsToAdd.length > CONSTANTS.PLACEMARKS_MAX_COUNT_ON_MAP && !selection) {
      geoObjectsToAdd = getFirstThirtyHotels(geoObjectsToAdd, itemsMapIds);
    }

    clusterInstance.add(geoObjectsToAdd);
    clusterInstance.remove(geoObjectsToRemove);
  };

  renderProgress = () => {
    const { progress } = this.props;

    return (progress > 0 && progress < 1) ? (
      <Progress value={ progress } speed='slow' animation />
    ) : null;
  };

  render(): JSX.Element {
    const { width, height, background, qaAttr } = this.props;

    return (
      <div
        data-qa={ qaAttr }
        style={ { width, height } }
        ref={ this.getMapNode }
        className={ `ymap ${background ? 'ymap-background' : ''}` }
      >
        {this.renderProgress()}
      </div>
    );
  }
}

export { YandexMaps };
