import { action, computed, makeObservable, observable } from 'mobx';

import { BUTTON_LABELS } from '../../../constants/train';

import mapSavedTicketToTrainItemModel from '../utils/mapSavedTicketToTrainItemModel';

import { SavedTicket } from './types';

export interface ITrainSavedTicketsStore {
  savedTickets: any[],
  cacheTickets: any[],
  currentBackTickets: any[],
  currentItem: any | null,
  isTicketWithTransfer: boolean,
  isChoosingBack: boolean,
  isDateBack: boolean,
  mappedSavedTickets: SavedTicket[],
  notAllSavedTickets: boolean,
  buttonLabel: string,
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable savedTickets = []; // все сохраненные
  @observable cacheTickets = []; // все выбранные поезда на странице поиска
  @observable currentBackTickets = []; // сохраненные билеты обратно
  @observable currentItem = null; // текущий поезд
  @observable isTicketWithTransfer = false;
  @observable isChoosingBack = false;
  @observable isDateBack = false;

  @computed
  get mappedSavedTickets() {
    return this.isTicketWithTransfer && this.savedTickets.length > 0
      ? this.savedTickets.map(mapSavedTicketToTrainItemModel)
      : [];
  }

  @computed
  get notAllSavedTickets() {
    return (
      (this.currentBackTickets.length || this.savedTickets.length) !== this.cacheTickets.length
      && !!this.currentItem
    );
  }

  @computed
  get buttonLabel() {
    const lastTicket = this.cacheTickets.length - 1;
    const ticketsFrom = !this.isChoosingBack && lastTicket === this.savedTickets.length;

    if (this.isDateBack && ticketsFrom) {
      return BUTTON_LABELS.SEARCH_BACK_TICKETS;
    }

    if ((!this.isDateBack && ticketsFrom) ||
      (this.isDateBack && this.isChoosingBack && (lastTicket === this.currentBackTickets.length || lastTicket === -1))) {
      return BUTTON_LABELS.TO_CART;
    }

    return BUTTON_LABELS.FURTHER;
  }

  @action
  setCacheTickets = (tickets: never[]) => {
    this.isTicketWithTransfer = true;
    this.cacheTickets = tickets;
    this.currentItem = this.cacheTickets[0];
  };

  @action
  setSavedTickets = (item: never, isDateBack: boolean) => {
    this.isDateBack = isDateBack;
    this.savedTickets.push(item);

    if (this.isChoosingBack) {
      return this.setCurrentBackTickets(item);
    }

    if (this.cacheTickets.length !== this.savedTickets.length) {
      return this.setCurrentItem(this.savedTickets);
    }

    return this.clearCurrentItem();
  };

  @action
  setSavedTicketsWithoutTransfer = (item: never) => {
    this.savedTickets.push(item);
  };

  @action
  setFromSavedTicket = (item: never, isDateBack: boolean) => {
    this.isDateBack = isDateBack;
    this.savedTickets.push(item);
    this.isChoosingBack = true;
  };

  @action
  setCurrentBackTickets = (item: never) => {
    this.currentBackTickets.push(item);

    if (this.cacheTickets.length !== this.currentBackTickets.length) {
      return this.setCurrentItem(this.currentBackTickets);
    }

    return this.clearCurrentBackTickets();
  };

  @action
  setCurrentItem = (tickets: any[]) => {
    this.currentItem = this.cacheTickets.filter(({ TrainId }) =>
      !tickets.map((ticket) => ticket?.TrainId).includes(TrainId),
    )[0];
  };

  @action
  clearAfterGoBack = () => {
    if (this.notAllSavedTickets) {
      const filteredSavedTickets = this.savedTickets
      // @ts-ignore
        .filter(({ TrainId }) => !this.currentBackTickets.some((i) => i.TrainId === TrainId));
      this.savedTickets = this.isChoosingBack ? filteredSavedTickets : [];

      this.currentBackTickets = [];
      this.cacheTickets = [];
      this.currentItem = null;
    }
  };

  @action
  clearCurrentItem = () => {
    this.cacheTickets = [];
    this.currentItem = null;

    if (this.isDateBack) {
      this.isChoosingBack = true;
    }
  };

  @action
  clearCurrentBackTickets = () => {
    this.currentBackTickets = [];
    this.cacheTickets = [];
    this.currentItem = null;
    this.isChoosingBack = false;
  };

  @action
  clearStore = () => {
    this.savedTickets = [];
    this.cacheTickets = [];
    this.currentBackTickets = [];
    this.currentItem = null;
    this.isChoosingBack = false;
    this.isTicketWithTransfer = false;
    this.isDateBack = false;
  };
}

const TrainSavedTicketsStore = new Store();

export { TrainSavedTicketsStore };
