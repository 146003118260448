import { getText, getTextArray } from '../../../i18n';

import { AnalyticTaxiClass } from '../types/taxi';

const FIELDS_VOUCHER = {
  BEGIN_AMOUNT: 'beginAmount',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  AVAILABLE_CLASSES: 'availableClasses',
};

const VAT = 0.2;

const TAXI_PROVIDERS = {
  YANDEX: 'yandex',
};

const ACTIVE_PROVIDERS = [TAXI_PROVIDERS.YANDEX];

const TAXI_COUNT = getTextArray('constants:taxi.taxiDeclines');

const TAXI_CLASSES = {
  ECONOMY: 1,
  COMFORT: 2,
  COMFORT_PLUS: 3,
  BUSINESS: 4,
  CHILDREN: 5,
  MINIVAN: 6,
  CRUISE: 7,
  PREMIER: 8,
};

const TAXI_CLASSES_TITLE = {
  [TAXI_CLASSES.ECONOMY]: getText('taxi:classes.economy'),
  [TAXI_CLASSES.COMFORT]: getText('taxi:classes.comfort'),
  [TAXI_CLASSES.COMFORT_PLUS]: getText('taxi:classes.comfort_plus'),
  [TAXI_CLASSES.BUSINESS]: getText('taxi:classes.business'),
  [TAXI_CLASSES.CHILDREN]: getText('taxi:classes.children'),
  [TAXI_CLASSES.MINIVAN]: getText('taxi:classes.minivan'),
  [TAXI_CLASSES.CRUISE]: getText('taxi:classes.cruise'),
  [TAXI_CLASSES.PREMIER]: getText('taxi:classes.premier'),
};

const AVAILABLE_CLASSES = [
  {
    value: TAXI_CLASSES.ECONOMY,
    label: TAXI_CLASSES_TITLE[TAXI_CLASSES.ECONOMY],
    analyticValue: AnalyticTaxiClass.economy,
  },
  {
    value: TAXI_CLASSES.COMFORT,
    label: TAXI_CLASSES_TITLE[TAXI_CLASSES.COMFORT],
    analyticValue: AnalyticTaxiClass.comfort,
  },
  {
    value: TAXI_CLASSES.COMFORT_PLUS,
    label: TAXI_CLASSES_TITLE[TAXI_CLASSES.COMFORT_PLUS],
    analyticValue: AnalyticTaxiClass.comfort_plus,
  },
  {
    value: TAXI_CLASSES.BUSINESS,
    label: TAXI_CLASSES_TITLE[TAXI_CLASSES.BUSINESS],
    analyticValue: AnalyticTaxiClass.business,
  },
  {
    value: TAXI_CLASSES.CHILDREN,
    label: TAXI_CLASSES_TITLE[TAXI_CLASSES.CHILDREN],
    analyticValue: AnalyticTaxiClass.children,
  },
  {
    value: TAXI_CLASSES.MINIVAN,
    label: TAXI_CLASSES_TITLE[TAXI_CLASSES.MINIVAN],
    analyticValue: AnalyticTaxiClass.minivan,
  },
  {
    value: TAXI_CLASSES.CRUISE,
    label: TAXI_CLASSES_TITLE[TAXI_CLASSES.CRUISE],
    analyticValue: AnalyticTaxiClass.cruise,
  },
  {
    value: TAXI_CLASSES.PREMIER,
    label: TAXI_CLASSES_TITLE[TAXI_CLASSES.PREMIER],
    analyticValue: AnalyticTaxiClass.prime,
  },
];

const STATUS_VOUCHER = {
  0: 'создан',
  1: 'активный',
  2: 'завершен',
};

const TYPE_ESSENCE = {
  1: 'ваучер',
  2: 'абонемент',
};

const SUBSCRIPTIONS = [
  { id: 1, user: 'Семенов О.И.', availableClasses: [TAXI_CLASSES.ECONOMY, TAXI_CLASSES.BUSINESS], currentAmount: '5000' },
  { id: 2, user: 'Семенов О.И.', availableClasses: [TAXI_CLASSES.ECONOMY, TAXI_CLASSES.BUSINESS], currentAmount: '5000' },
  { id: 3, user: 'Семенов О.И.', availableClasses: [TAXI_CLASSES.ECONOMY, TAXI_CLASSES.BUSINESS], currentAmount: '5000' },
  { id: 4, user: 'Семенов О.И.', availableClasses: [TAXI_CLASSES.ECONOMY, TAXI_CLASSES.BUSINESS], currentAmount: '5000' },
  { id: 5, user: 'Семенов О.И.', availableClasses: [TAXI_CLASSES.ECONOMY, TAXI_CLASSES.BUSINESS], currentAmount: '5000' },
];

const TAXI_GROUP = 'taxiAndTaxiVouchers';

export {
  TAXI_PROVIDERS,
  ACTIVE_PROVIDERS,
  TAXI_COUNT,
  FIELDS_VOUCHER,
  AVAILABLE_CLASSES,
  TAXI_CLASSES_TITLE,
  TAXI_CLASSES,
  VAT,
  TAXI_GROUP,
  SUBSCRIPTIONS,
  STATUS_VOUCHER,
  TYPE_ESSENCE,
};
