enum VIEW_MODE_TYPES {
  LIST_MAP = 'listMap',
  LIST = 'list',
}

enum SEARCH_MENU_TYPES {
  DEFAULT = 'DEFAULT',
  SUB_MENU = 'SUBMENU',
  DETAILS_MENU = 'DETAILSMENU',
}

enum SEARCH_MENU_PAGE {
  HOTEL = 'HOTEL',
}

export { VIEW_MODE_TYPES, SEARCH_MENU_TYPES, SEARCH_MENU_PAGE };
