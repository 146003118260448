import { getText } from '../../../i18n';

import { SimpleSignatureType } from '../services/employee/types';

const TYPE_IMAGE = {
  SVG: 'data:image/svg+xml;base64,',
  PNG: 'data:image/png;base64,',
  JPEG: 'data:image/jpeg;base64,',
};

const LOADINGS_TYPE = {
  GET_SIGNATURE_CODE: 'loadingGetSignatureCode',
  GET_SIGNATURE_IMAGES: 'loadingGetSignatureImages',
  SEND_SIGNATURE_CODE: 'loadingSendSignatureCode',
  DELETE_SIGNATURE: 'loadingDeleteSignature',
  GET_DOCS_SIGNATURE: 'loadingGetDocsSignature',
  GET_DOC_SINGING_BUTTON: 'loadingDocSingingButton',
  GET_DOC_PREVIEW: 'loadingDocPreview',
};

const DEFAULT_SIGNATURE: SimpleSignatureType = {
  registrationNumber: null,
  email: '',
  phoneNumber: '',
};

const DEFAULT_DATA = {
  email: '',
  phoneNumber: '',
};

const DEFAULT_SIGNATURE_CODE = [
  { key: 1, value: '' },
  { key: 2, value: '' },
  { key: 3, value: '' },
  { key: 4, value: '' },
  { key: 5, value: '' },
  { key: 6, value: '' },
];

const DEFAULT_PAGINATE = {
  currentPage: 1,
  itemsPerPage: 6,
  total: 0,
};

const DEFAULT_LOADINGS: { [p: string]: boolean } = {
  loadingGetSignatureCode: false,
  loadingGetSignatureImages: false,
  loadingSendSignatureCode: false,
  loadingDeleteSignature: false,
  loadingGetDocsSignature: false,
  loadingDocSingingButton: false,
};

const ERRORS = {
  EMAIL_FORMAT: getText('services:employee.store.employee.emailFormat'),
  PHONE_LENGTH: getText('services:employee.store.employee.phoneLength'),
  PHONE_FORMAT: getText('services:employee.store.employee.phoneFormat'),
};

export {
  TYPE_IMAGE,
  LOADINGS_TYPE,
  DEFAULT_SIGNATURE,
  DEFAULT_SIGNATURE_CODE,
  DEFAULT_DATA,
  DEFAULT_LOADINGS,
  ERRORS,
  DEFAULT_PAGINATE,
};
