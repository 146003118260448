const formatCurrency = (value: string) => {
  const trimmedString = value.trim();
  const numberPart = trimmedString.slice(0, -1).replace(/\s+/g, '');
  const currencyPart = trimmedString.slice(-1);

  if (!isNaN(Number(numberPart)) && (currencyPart === '₽' || currencyPart.match(/[a-zA-Zа-яА-Я]/))) {
    return `${numberPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')} ${currencyPart}`;
  }

  return trimmedString;
};

const isTotalAmount = (amount: string) => amount !== '0 ₽';

export {
  formatCurrency,
  isTotalAmount,
};
