import React from 'react';

import { Button, IconButton, StyledWrapper, Text } from 'new-ui';

import { IPopupComponent } from '../types';

import styles from './styles.module.css';

const Popup = ({
  popup: {
    title,
    subTitle,
    position,
    closing,
    cancel: { action: cAction, label: cLabel = '' },
    submit: { action: sAction, label: sLabel = '' },
    description,
    image,
    qaAttrs,
    link,
  },
}: IPopupComponent) => {
  const closingClass = closing ? styles.closing : '';

  const submitButton = link ? (
    <a href={ link } target='example'>
      <Button
        className={ styles.button }
        onClick={ sAction }
        qaAttr={ qaAttrs?.submitButton || '' }
      >
        { sLabel }
      </Button>
    </a>
  ) : (
    <Button
      className={ styles.button }
      onClick={ sAction }
      qaAttr={ qaAttrs?.submitButton || '' }
    >
      { sLabel }
    </Button>
  );

  const cancelButton = cLabel && (
    <Button
      type='textual-medium'
      onClick={ cAction }
      qaAttr={ qaAttrs?.cancelButton || '' }
    >
      { cLabel }
    </Button>
  );

  const imgHtml = image ? <img src={ image } alt='vector' className={ styles.imageStyle } /> : null;

  const submitButtonHtml = sLabel ? submitButton : null;
  const subTitleHtml = subTitle ?
    <Text type='bold_20' >{ subTitle }</Text> : null;

  return (
    <StyledWrapper className={ `${styles.wrapper} ${styles[position]} ${closingClass}` }>
      { imgHtml }
      <div className={ styles.wrap_icon }>
        <IconButton
          iconType='closeButton'
          onClick={ cAction }
          qaAttr={ qaAttrs?.closeButton || '' }
        />
      </div>
      <Text type='bold_20' qaAttr={ qaAttrs?.title || '' } >{ title }</Text>
      { subTitleHtml }
      <Text
        type='NORMAL_14_130'
        className={ styles.description }
        qaAttr={ qaAttrs?.text || '' }
      >
        { description }
      </Text>
      <div className={ styles.active }>
        { submitButtonHtml }
        { cancelButton }
      </div>
    </StyledWrapper>
  );
};

export default Popup;
