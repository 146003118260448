import React from 'react';
import { Text, Tooltip } from 'new-ui';

import styles from './styles/index.module.css';

interface RoundIconWithTooltipProps {
  className: string;
  label: string;
  text?: string;
}

const RoundIconWithTooltip = ({ className = '', label, text = '' }: RoundIconWithTooltipProps) => {
  const tooltip = () => (
    <Text
      color='white'
      type='NORMAL_14_130'
      className={ styles.tooltip_box }
    >
      { text }
    </Text>
  );

  const wrappedStyles = [styles.wrapper];

  if (className) {
    wrappedStyles.push(className);
  }

  return (
    <Tooltip
      className={ wrappedStyles.join(' ') }
      show={ !!text.length }
      renderContent={ tooltip }
    >
      <Text type='NORMAL_12' color='gray'>
        { label }
      </Text>
    </Tooltip>
  );
};

export { RoundIconWithTooltip };
