import React from 'react';

import styles from './styles.module.css';

import exitImage from '../../images/exit.svg';

const alt = 'exit';

const Exit = () => (
  <div className={ styles.empty_row }>
    <img
      className={ styles.exit }
      src={ exitImage }
      alt={ alt }
    />
    <img
      className={ `${styles.exit} ${styles.exit_right}` }
      src={ exitImage }
      alt={ alt }
    />
  </div>
);

export default Exit;
