// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';

const FormWrapper = ({ children, className, onSubmit }) => (
  <form
    autoComplete={ 'off' }
    className={ className }
    onSubmit={ e => {
      e.preventDefault();
      onSubmit();
    } }
  >
    { children }
  </form>
);

FormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
};

FormWrapper.defaultProps = {
  className: '',
  onSubmit: () => {},
};

export { FormWrapper };
