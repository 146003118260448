import { getText } from '../../../i18n';

export const CONSISTENTCARTMODES = {
  APPROVER: 'APPROVER',
  VIEWER: 'VIEWER',
};

export enum ConsistentCartModes {
  approver = 'APPROVER',
  viewer = 'VIEWER',
}

export const FIELD_APPROVAL_REQUEST = {
  GENERAL: getText('approvalScheme:general'),
  EMPLOYEES: getText('approvalScheme:employees'),
  COMPANIES: getText('approvalScheme:company'),
  BUDGET: getText('approvalScheme:budget'),
  DATES: getText('approvalScheme:dates'),
  CITY: getText('approvalScheme:city'),
  BUDGET_TRIP: getText('approvalScheme:budgetTrip'),
  PURPOSE: getText('approvalScheme:purpose'),
  COMMENT: getText('approvalScheme:comment'),
  INTERMEDIATE_CITY: getText('approvalScheme:intermediateCity'),
  APPROVAL_HISTORY: getText('approvalScheme:approvalHistory'),
  PROJECT: getText('approvalScheme:project'),
  CUSTOM_ANALYTIC: getText('approvalScheme:customAnalytic'),
};

export const anotherConst = {
  prop: 'value',
};

export const ExpenseStatus = {
  Accepted: 'Accepted',
  Declined: 'Declined',
  AutoAccepted: 'AutoAccepted',
  AutoDeclined: 'AutoDeclined',
};

export const APPROVE_TYPE = {
  TRIPS: 'Trips',
  REQUESTS: 'Requests',
  EXPENSE_REPORTS: 'ExpenseReports',
};

export const CHANGE_FIELD = {
  BUDGET: 'Budget',
  ANALYTICS: 'Analytics',
  PURPOSE: 'Purpose',
  EMPLOYEES: 'Employees',
  COMMENT: 'Comment',
  START_DATE: 'StartDate',
  END_DATE: 'EndDate',
};

export enum QuickApprovalActionTypes {
  Declined = 'decline',
  Accepted = 'accept',
}

export enum TravelApprovalStatus {
  Declined = 1,
  Accepted = 2,
}

export enum ExpenseApprovalStatus {
  Normal,
  WaitingApprove,
  Approved,
  Declined,
  AutoApproved,
  AutoDeclined,
}
