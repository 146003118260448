const MOBILE_APP_BANNER = 'mobile-app-banner';
const TAXI_BANNER = 'taxi-banner';
const VIP_HALL_BANNER = 'vip-hall-banner';
const SERVICE_QUALITY_POPUP = 'service-quality-popup';
const SEATMAPS_POPUP = 'seatmaps-popup';
const MAIN_MENU_WRAPPER = 'main-header-items-wrapper';

export {
  MOBILE_APP_BANNER,
  TAXI_BANNER,
  VIP_HALL_BANNER,
  SERVICE_QUALITY_POPUP,
  SEATMAPS_POPUP,
  MAIN_MENU_WRAPPER,
};
