import { Moment } from 'moment';
import { ItemsType, SettingsExpenseType } from '../services/expenseReports/types';

import { diffDays } from './formatDate';

export const countDailyExpenses = (
  startDay: string | Date | Moment,
  endDay: string | Date | Moment,
  trips: ItemsType[] | any,
  { RuDailyExpenses, OverseasDailyExpenses }: SettingsExpenseType,
): number => {
  const days = diffDays(startDay, endDay) + 1;
  const dailyExpenses = trips.length && trips.some(({ IsRu }: { IsRu: boolean }) => !IsRu) ?
    OverseasDailyExpenses :
    RuDailyExpenses;

  return days * dailyExpenses;
};
