import React, { FC, ReactNode } from 'react';
import { Skeleton } from 'new-ui';

import styles from './styles/index.module.css';

interface CollapseSkeletonProps {
  width: number
  children?: ReactNode,
}

const CollapseSkeleton: FC<CollapseSkeletonProps> = ({ width, children }) => (
  <div className={ styles['collapse-skeleton'] }>
    <div className={ styles.label }>
      <Skeleton height={ 10 } width={ width } className={ styles.text } />
    </div>
    <div className={ styles.children }>
      { children }
    </div>
  </div>
);

export { CollapseSkeleton };
