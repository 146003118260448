import React from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../i18n';

import { formatRangeDateWithSimplicity, textualMonthPatternWithoutZero } from '../../bi/utils/formatDate';

import { FlightItemOrder } from '../../bi/types/flightCertificate';

import styles from './styles/index.module.css';

const LABELS = {
  FLIGHT_CERTIFICATE_NUMBER: getText('components:flightCertificateItem.number'),
  FLIGHT_CERTIFICATE_TITLE: getText('components:flightCertificateItem.title'),
};

interface FlightCertificateItemProps {
  meta: FlightItemOrder,
  qaAttr?: string,
}

const FlightCertificateItem = ({
  meta: {
    FlightData: { Airlines, CheckinDate, CheckoutDate, Direction, Tickets },
  },
  qaAttr,
}: FlightCertificateItemProps) => {
  const airlines = Airlines.join(', ');
  const number = `${LABELS.FLIGHT_CERTIFICATE_NUMBER}${Tickets}`;
  const date = formatRangeDateWithSimplicity(
    CheckinDate,
    CheckoutDate,
    { monthPattern: textualMonthPatternWithoutZero, withoutSpaceBetweenDates: true },
  );

  return (
    <div className={ styles.wrap } data-qa={ qaAttr }>
      <div>
        <Text type='SEMIBOLD_16' className={ styles.reference_texet }>{ LABELS.FLIGHT_CERTIFICATE_TITLE }</Text>
        <Text type='NORMAL_14'>{ `${number}, ${airlines}, ${Direction}, ${date}`}</Text>
      </div>
    </div>
  );
};

export default FlightCertificateItem;
