import React, { createRef, Ref } from 'react';
import { Suggest, Text } from 'new-ui';

import { getText } from '../../../../i18n';

import { IProjects } from '../../../bi/types/employees';

import styles from './styles/index.module.css';

const LABELS = {
  ADD_COST_CENTRE: getText('components:employeeList.projectSelect.addCostCenter'),
  QUICK_SEARCH: getText('components:employeeList.projectSelect.quickSearch'),
};

interface ProjectSelectProp {
  projects: IProjects[];
  onSelect: (id: number, name: string) => void;
  // onClose: () => void,
  projectName?: string;
  loading?: boolean;
  showAdd?: boolean;
  onAddProject?: () => void;
  qaAttr?: string;
  qaAttrFirstEl?: string;
  qaAttrLoading?: string;
  setInterNameCostCenter?: (name: string) => void;
}

interface ProjectSelectState {
  value?: string;
  suggestions: IProjects[];
  meaning: string;
}

class ProjectSelect extends React.Component<ProjectSelectProp, ProjectSelectState> {
  static defaultProps = {
    showAdd: false,
    firstAdd: false,
    loading: false,
    projectName: '',
    onAddProject: () => {},
    setInterNameCostCenter: () => {},
    qaAttr: '',
    qaAttrFirstEl: '',
    qaAttrLoading: '',
  };

  ref: Ref<HTMLInputElement>;

  constructor(props: ProjectSelectProp) {
    super(props);

    this.state = {
      value: props.projectName,
      // инициализируется полным списком, а меняется в зависимости от input, т.е. this.state.value
      suggestions: this.listSuggestions(),
      meaning: '',
    };

    this.ref = createRef();
  }

  componentDidUpdate(prevProps: ProjectSelectProp) {
    const { projectName } = this.props;

    if (prevProps.projectName !== projectName) {
      this.setState({ value: projectName });
    }
  }

  listSuggestions = () => {
    const { projects } = this.props;

    return [
      ...projects.filter(i => !i.IsArchival),
    ];
  };

  getSuggestions = (value: string) => {
    const inputValue = value.trim().toLowerCase();

    return (
      this.listSuggestions().filter((project) => {
        const name = project.Name.toLowerCase();

        return name.includes(inputValue);
      })
    );
  };

  handleUpdateSuggestions = (value: string) => {
    this.setState({
      meaning: value,
      suggestions: this.getSuggestions(value),
    });
  };

  handleSelectSuggestion = ({ Id, Name }: IProjects) => {
    const { onSelect } = this.props;

    return onSelect(Id, Name);
  };

  handleAdd = () => {
    const { onAddProject = () => {}, setInterNameCostCenter = () => {} } = this.props;
    const { meaning } = this.state;

    setInterNameCostCenter(meaning);
    onAddProject();
  };

  renderSuggestion = (suggestion: IProjects) => (
    <Text className={ styles.item }>{ suggestion.Name }</Text>
  );

  render() {
    const { loading, qaAttr, qaAttrFirstEl, qaAttrLoading, showAdd } = this.props;
    const { value, suggestions } = this.state;

    return (
      <div className={ styles.projects }>
        <Suggest
          onAdd={ this.handleAdd }
          addButton={ showAdd }
          addButtonLabel={ LABELS.ADD_COST_CENTRE }
          withLabel={ false }
          loading={ loading }
          disabled={ loading }
          placeholder={ LABELS.QUICK_SEARCH }
          onChange={ this.handleUpdateSuggestions }
          theme='border'
          items={ suggestions }
          renderItem={ this.renderSuggestion }
          onSelect={ this.handleSelectSuggestion }
          value={ value }
          autoFocus
          ref={ this.ref }
          // useRegisterOutsideClick fix
          // onClose={ onClose }
          shouldFocusItem={ () => false }
          qaAttr={ qaAttr }
          qaAttrFirstEl={ qaAttrFirstEl }
          qaAttrLoading={ qaAttrLoading }
        />
      </div>
    );
  }
}

export default ProjectSelect;
