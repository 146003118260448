import React from 'react';

import styles from './styles.module.css';

import { AIRLINECLASSES } from '../../consts';

import type { AirlineClassType } from '../../types/types';

const Space = ({ airlineClass }: { airlineClass: AirlineClassType }) => {
  const classNames = [styles.space];

  if (airlineClass === AIRLINECLASSES.econom) {
    classNames.push(styles.space_econom);
  }

  if (airlineClass === AIRLINECLASSES.business) {
    classNames.push(styles.space_business);
  }

  if (airlineClass === AIRLINECLASSES.first) {
    classNames.push(styles.space_first);
  }

  return <div className={ classNames.join(' ') } />;
};

export default Space;
