import { observable, makeObservable, action } from 'mobx';

import {
  DEFAULT_MAIN_INFO,
  DEFAULT_PAGING,
  DEFAULT_SELECTED_VIP_HALL,
  SORT_FIELDS,
} from '../../../constants/vipHall';

import { IMainInfo } from '../../../types/vipHall';
import { AirportsListType, SelectedVipHall, AdditionalServices, PagingType } from '../types';

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable loading = false;
  @observable items: AirportsListType[] = [];
  @observable itemsFiltered: AirportsListType[] = [];
  @observable mainInfo: IMainInfo = DEFAULT_MAIN_INFO;
  @observable paging: PagingType = DEFAULT_PAGING;
  @observable choosenVipHall: SelectedVipHall = DEFAULT_SELECTED_VIP_HALL;
  @observable additionalServices: AdditionalServices[] = [];
  @observable newCost: number | null = null;

  @action
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  setItems = (items: AirportsListType[]) => {
    this.items = items;
    this.paging.total = items.length;
    this.setPagingItems(this.paging.current, items);
  };

  @action
  setMainInfo = (item: IMainInfo) => {
    this.mainInfo = item;
  };

  @action
  setPagingItems = (paging: number, items: AirportsListType[] = this.items) => {
    const newPaging = {
      ...this.paging,
      current: paging,
    };
    this.paging = newPaging;
    this.itemsFiltered = this.getPagingView(items, newPaging);
  };

  @action
  getPagingView = (items: AirportsListType[], paging: PagingType) => {
    const startInd = paging.count * (paging.current - 1);

    return items.slice(startInd, startInd + paging.count);
  };

  @action
  setChoosenVipHall = (data: SelectedVipHall) => {
    this.choosenVipHall = data;
  };

  @action
  changeAdditionalService = (service: AdditionalServices) => {
    if (!this.additionalServices.some(({ index }) => index === service.index)) {
      this.additionalServices.push(service);
    } else {
      this.additionalServices = this.additionalServices.filter(({ index }) => index !== service.index);
    }
  };

  changeAdditionalCount = (index: number, count: number) => {
    this.additionalServices = this.additionalServices.map(item => {
      if (item.index === index) {
        return { ...item, count };
      }

      return item;
    });
  };

  @action
  updateCost = (cost: number) => {
    this.choosenVipHall.one_passenger_cost = cost;
  };

  @action
  filterItems = (type: string) => {
    let itemsList = this.items;

    switch (type) {
      case SORT_FIELDS.PRICE_UP: {
        itemsList = this.items.sort((item, nextItem) => item.price - nextItem.price);
        break;
      }

      case SORT_FIELDS.PRICE_DOWN: {
        itemsList = this.items.sort((item, nextItem) => nextItem.price - item.price);
        break;
      }
    }

    this.items = itemsList;
    this.paging.total = itemsList.length;
    this.setPagingItems(this.paging.current, itemsList);
  };

  @action
  changeCost = (cost: number) => {
    this.newCost = cost;
  };

  @action
  resetStore = () => {
    this.loading = false;
    this.items = [];
    this.itemsFiltered = [];
    this.mainInfo = DEFAULT_MAIN_INFO;
    this.paging = DEFAULT_PAGING;
    this.choosenVipHall = DEFAULT_SELECTED_VIP_HALL;
    this.additionalServices = [];
  };
}

const VipHallStore = new Store();

export interface IVipHallStore {
  loading: boolean;
  items: AirportsListType[];
  itemsFiltered: AirportsListType[];
  mainInfo: IMainInfo;
  paging: PagingType;
  choosenVipHall: SelectedVipHall;
  additionalServices: AdditionalServices[];
  newCost: number | null;
  setLoading(value: boolean): void;
  setItems(items: AirportsListType[]): void;
  setMainInfo(item: IMainInfo): void;
  setPagingItems(paging: number, items: AirportsListType[]): void;
  getPagingView(items: AirportsListType[], paging: PagingType): void;
  setChoosenVipHall(data: SelectedVipHall): void;
  changeAdditionalService(service: AdditionalServices): void;
  changeAdditionalCount(index: number, count: number): void;
  updateCost(cost: number): void;
  filterItems(type: string): void;
  changeCost(cost: number): void;
  resetStore(): void;
}

export { VipHallStore };
