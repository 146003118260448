import React from 'react';

import { Moment } from 'moment/moment';

import SmartdeskPanel from '../SmartdeskPanel';

import { EventsSAProps } from '../../bi/types/eventsSA';

const EventsSA = ({
  calendar,
  update,
  getCalendarTrips,
  isShow,
}: EventsSAProps) => {
  const handleChangeCalendarMonth = (value: Moment) => {
    if (update) {
      update((prevCalendar) => ({
        ...prevCalendar,
        date: value,
      }));
    }

    if (getCalendarTrips) {
      getCalendarTrips(value);
    }
  };

  return (
    <div >
      <SmartdeskPanel
        calendar={ calendar }
        onChangeMonth={ handleChangeCalendarMonth }
        getShowElementForSmartagent={ isShow }
      />
    </div>
  );
};

export default EventsSA;
