import SYMBOLS from '../constants/symbols';

const arrayWithSeparators = (array: string[], separate = SYMBOLS.COMMA.value, end = SYMBOLS.DOT.value) => {
  const lastIndex = array.length - 1;

  return array.map((item, i) => {
    let sepSymbol = separate;
    let result = item;

    if (i === 0) {
      result = item[0].toUpperCase() + item.slice(1);
    }

    if (i !== 0 && separate === SYMBOLS.COMMA.value) {
      result = item[0].toLowerCase() + item.slice(1);
    }

    if (i === lastIndex) {
      sepSymbol = end;
    }

    return `${result}${sepSymbol}`;
  });
};

export default arrayWithSeparators;
