import { PurposeInterface } from '../types/travelApproval';
import { DestinationType } from '../services/travelApproval/consts';

const filterArrayByIndex = (destinations: DestinationType[], index: number): DestinationType[] => destinations.filter((_elem, i) => i !== index);

const replaceObjectByIndex = (destinations: DestinationType[], index: number, newCity: DestinationType): DestinationType[] => {
  const destinationListCopy = destinations.slice();

  if (index >= 0 && index < destinationListCopy.length) {
    destinationListCopy.splice(index, 1, newCity);
  }

  return destinationListCopy;
};

const findMatchingElement = (obj: PurposeInterface, arr: PurposeInterface[]) => {
  if (!obj) return null;

  const match = arr.find(item => item.label === obj.Name);

  return Number.isInteger(match?.value) ? match?.value : null;
};

export { filterArrayByIndex, replaceObjectByIndex, findMatchingElement };
