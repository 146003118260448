import React from 'react';

import { IconButton } from 'new-ui';

import styles from './styles/index.module.css';

interface ClearButtonProps {
  onClick(): void,
  qaAttr?: string
}

const ClearButton = ({ onClick, qaAttr = '' }: ClearButtonProps) => (
  <IconButton
    className={ styles.wrap }
    iconType='closeButton'
    iconColor='gray'
    onClick={ onClick }
    qaAttr={ qaAttr }
  />
);

export { ClearButton };
