import { getText } from '../../../i18n';

const PASSPORTS = {
  FOREIGN_PASSPORT: {
    TYPE: 'ForeignPassport',
    VALUE: getText('constants:passport.foreignPassport'),
  },
  RU_PASSPORT: {
    TYPE: 'RuPassport',
    VALUE: getText('constants:passport.ruPassport'),
  },
  DOMESTIC_PASSPORT: {
    TYPE: 'DomesticPassport',
    VALUE: getText('constants:passport.domesticPassport'),
  },
  BIRTH_CERTIFICATE: {
    TYPE: 'BirthCertificate',
    VALUE: getText('constants:passport.birthCertificate'),
  },
  FOREIGNER_DOMESTIC_PASSPORT: {
    TYPE: 'DomesticPassport',
    VALUE: getText('constants:passport.foreignerDomesticPassport'),
  },
};

export type PassportsType = typeof PASSPORTS;

export default PASSPORTS;
