import { action, makeObservable, observable } from 'mobx';

import { DEFAULT_CHART } from '../../../constants/chartsAnalytics';

import { ChartType, ListType } from '../types';

export interface IChartsAnalyticsGeneralStore {
  loading: boolean,
  chart: ChartType | null,
  leftList: any[],
  rightList: any[],
  isCommonChart: boolean,
}

export class ChartsAnalyticsGeneralStore {
  constructor() {
    makeObservable(this);
  }

  @observable loading = true;
  @observable chart = { ...DEFAULT_CHART };
  @observable leftList = [];
  @observable rightList = [];
  @observable comparisonCards = [];
  @observable isCommonChart = true;

  @action
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  setChart = (obj: ChartType | any) => {
    this.chart = { ...this.chart, ...obj };
  };

  @action
  setCommonChart = (value: boolean) => {
    this.isCommonChart = value;
  };

  @action
  setLists = (leftList: ListType[] | any, rightList: ListType[] | any) => {
    this.leftList = leftList;
    this.rightList = rightList;
  };

  @action
  setComparisonCards = (value: any) => {
    this.comparisonCards = value;
  };
}

export const chartsAnalyticsGeneralStore = new ChartsAnalyticsGeneralStore();
