import React from 'react';
import { Text, Tooltip, Button } from 'new-ui';

import { getText } from '../../../i18n';

import { ITableRow } from './types';

import styles from './styles/index.module.css';

const LABELS = {
  REMOVE: getText('components:tableRow.remove'),
  EDIT: getText('components:tableRow.edit'),
  COUNT_OF_EMPLOYEES: getText('components:tableRow.countOfEmployees'),
  WATCH: getText('components:tableRow.watch'),
};

const TableRow = ({
  id,
  title,
  description = '',
  headOfDepartment,
  countOfEmployees,
  isDepartments = false,
  remove: {
    action: rAction = () => {},
    tooltip: rTooltip = '',
    text: rText = LABELS.REMOVE,
  },
  edit: {
    action: eAction = () => {},
    tooltip: eTooltip = '',
    text: eText = LABELS.EDIT,
  },
  watch = null,
  onClick = () => {},
  qaAttrEdit = '',
  qaAttrRemove = '',
  qaAttrTitle = '',
  qaAttrWrapper = '',
}: ITableRow) => {
  const renderTooltip = (text: string) => (
    <Text
      className={ styles.tooltip }
      type='NORMAL_14'
      color='white'
    >
      { text }
    </Text>
  );

  const onHandle = (e: any, action: () => void) => {
    e.stopPropagation();
    action();
  };

  const removeContent = rAction && rText && (
    <Tooltip
      show={ !!rTooltip }
      className={ styles.action }
      renderContent={ () => renderTooltip(rTooltip) }
    >
      <Button
        disabled={ !!rTooltip }
        type='textual'
        onClick={ e => onHandle(e, rAction) }
        qaAttr={ qaAttrRemove }
      >
        { rText }
      </Button>
    </Tooltip>
  );

  const watchContent = () => {
    if (!watch || !watch.action || !watch.text) {
      return null;
    }

    const { action: wAction, text: wText, tooltip: wTooltip } = watch;

    const text = wText || LABELS.WATCH;

    return (
      <Tooltip
        show={ !!wTooltip }
        className={ styles.action }
        renderContent={ () => renderTooltip(wTooltip as string) }
      >
        <Button
          disabled={ !!wTooltip }
          type='textual'
          onClick={ e => onHandle(e, wAction) }
          qaAttr={ qaAttrRemove }
        >
          { text }
        </Button>
      </Tooltip>
    );
  };

  const editContent = eAction && eText && (
    <Tooltip
      show={ !!eTooltip }
      className={ styles.action }
      renderContent={ () => renderTooltip(eTooltip) }
    >
      <Button
        disabled={ !!eTooltip }
        type='textual'
        onClick={ e => onHandle(e, eAction) }
        qaAttr={ qaAttrEdit }
      >
        { eText }
      </Button>
    </Tooltip>
  );

  const descriptionContent = description && (
    <Text
      className={ styles.description }
      color='gray'
      type='NORMAL_12'
    >
      { description }
    </Text>
  );

  const countOfEmployeesContent = isDepartments && (
    <Text
      color='gray'
      type='NORMAL_14'
    >
      {`${LABELS.COUNT_OF_EMPLOYEES} ${countOfEmployees}` }
    </Text>
  );

  const headContent = isDepartments && (
    <Text
      className={ styles.head }
      color='gray'
      type='NORMAL_14'
    >
      { headOfDepartment }
    </Text>
  );

  return (
    <div
      className={ styles.wrapper }
      onClick={ e => {
        e.stopPropagation();
        onClick();
      } }
      data-qa={ qaAttrWrapper }
    >
      <div className={ styles.anchor } id={ id }/>
      <div className={ styles.gridTemplate }>
        <div className={ styles.text }>
          <Text qaAttr={ qaAttrTitle }>{ title }</Text>
          { descriptionContent }
        </div>
        <div className={ styles.employees }>
          { countOfEmployeesContent }
        </div>
        <div className={ styles.headOfDepartmant }>
          { headOfDepartment && headContent }
        </div>
        <div className={ styles.actions }>
          { watchContent() }
          { editContent }
          { removeContent }
        </div>
      </div>
    </div>
  );
};

export { TableRow };
