// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Dialog } from 'new-ui';
import { createPortal } from 'react-dom';

import { getText } from '../../../i18n';

import { MainAnalytic } from '../../bi/utils/analytics';
import { isSmartAgent } from '../../bi/utils/env';

import { CloudPaymentsDialog } from '../CloudPaymentsDialog';

import styles from './styles/index.module.css';

const LABEL = getText('components:cloudPayments.label');

class CloudPayments extends React.Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    companyInn: PropTypes.string.isRequired,
    companyId: PropTypes.number.isRequired,
    companies: PropTypes.array.isRequired,
    paymentService: PropTypes.object.isRequired,
    allCompanies: PropTypes.array.isRequired,
    notShowReportPayment: PropTypes.bool,
    onClickAnalytics: PropTypes.func,
    buttonType: PropTypes.string,
  };

  static defaultProps = {
    onClickAnalytics: () => {},
    buttonType: '',
    notShowReportPayment: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      showCompaniesSelect: true,
      showApproveForm: false,
      showPayment: false,
    };
  }

  handleSetDialogVisibility = value => {
    const isCompanies = isSmartAgent ? null : this.props.companies.length > 1;

    this.setState({
      showDialog: value,
      showCompaniesSelect: isCompanies,
      showApproveForm: !isCompanies,
      showPayment: false,
    });
  };

  handleClickCloudPayments = (value) => {
    this.handleSetDialogVisibility(value);
    this.props.onClickAnalytics();

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMARTDESC.SMARTDESK_REPLENISHMENT_CARD_PRESSED);
  };

  handleToggleShowCompaniesSelect = () => {
    const { showCompaniesSelect, showApproveForm } = this.state;

    this.setState({
      showCompaniesSelect: !showCompaniesSelect,
      showApproveForm: !showApproveForm,
    });
  };

  handleToggleShowApproveForm = () => {
    const { showApproveForm, showPayment } = this.state;

    this.setState({
      showApproveForm: !showApproveForm,
      showPayment: !showPayment,
    });
  };

  renderDialog = () => {
    const {
      email,
      companies,
      companyId,
      paymentService,
      companyName,
      companyInn,
      allCompanies,
    } = this.props;
    const {
      showDialog,
      showCompaniesSelect,
      showApproveForm,
      showPayment,
    } = this.state;

    return createPortal(
      <Dialog
        show={ showDialog }
        showClosing
        onChange={ () => this.handleSetDialogVisibility(false) }
      >
        <CloudPaymentsDialog
          onCloseDialog={ () => this.handleSetDialogVisibility(false) }
          onChangeCompaniesSelect={ this.handleToggleShowCompaniesSelect }
          onChangeApproveForm={ this.handleToggleShowApproveForm }
          companyEmail={ email }
          companyName={ companyName }
          companyInn={ companyInn }
          currentCompany={ companyId }
          companiesItems={ companies }
          showCompaniesSelect={ showCompaniesSelect }
          showApproveForm={ showApproveForm }
          paymentService={ paymentService }
          showPayment={ showPayment }
          allCompanies={ allCompanies }
          autoFocusInput={ showDialog }
        />
      </Dialog>,
      document.body,
    );
  };

  renderButtonForSmartWay = () => (
    <Button
      className={ styles.button }
      type='primary-outline'
      onClick={ () => this.handleClickCloudPayments(true) }
    >
      { LABEL }
    </Button>
  );

  renderButtonForSmartAgent = () => {
    const { buttonType, notShowReportPayment } = this.props;
    const checkTypeTheme = buttonType ? 'secondary' : 'textual-decoration';

    if (notShowReportPayment) {
      return null;
    }

    return (
      <Button
        type={ checkTypeTheme }
        onClick={ () => this.handleClickCloudPayments(true) }
      >
        {LABEL}
      </Button>
    );
  };

  renderButtonPayment = () => (isSmartAgent ? this.renderButtonForSmartAgent() : this.renderButtonForSmartWay());

  render() {
    return (
      <>
        {this.renderButtonPayment()}
        {this.renderDialog()}
      </>
    );
  }
}

export default CloudPayments;
