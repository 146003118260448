// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';

import { Tooltip, Icon, Text, PROPS } from 'new-ui';

import styles from './index.module.css';

const {
  TEXT: {
    TYPES: { NORMAL_14_130 },
    COLORS: { WHITE },
  },
  TOOLTIP: {
    POSITION: {
      TOP,
    },
  },
  ICON: {
    TYPES: {
      INFO,
    },
  },
} = PROPS;

const InformationIcon =
  ({
    text,
    className,
    iconClassName,
    tooltipPosition,
    iconType,
    classNameTooltipText,
  }) => (
    <Tooltip
      className={ className }
      position={ tooltipPosition }
      renderContent={ () => (
        <Text
          type={ NORMAL_14_130 }
          color={ WHITE }
          className={ classNameTooltipText }
        >
          { text }
        </Text>
      ) }
    >
      <Icon type={ iconType } className={ iconClassName }/>
    </Tooltip>
  );

InformationIcon.propTypes = {
  text: PropTypes.string.isRequired,
  iconClassName: PropTypes.string,
  className: PropTypes.string,
  iconType: PropTypes.string,
  tooltipPosition: PropTypes.string,
  classNameTooltipText: PropTypes.string,
};

InformationIcon.defaultProps = {
  tooltipPosition: TOP,
  iconType: INFO,
  classNameTooltipText: styles.text,
  iconClassName: '',
  className: '',
};

export { InformationIcon };
