// @ts-nocheck
const mapSavedTicketToTrainItemModel = ({
  car: {
    ClassService,
    CarNumber,
    parentCar: {
      TrainNumber,
      TypeShow,
    },
  },
  train: {
    ArrivalDateLocal,
    ArrivalDate,
    DepartureDateLocal,
    DepartureDate,
    StationFrom,
    StationTo,
    StationDepart,
    StationArrive,
    TrainId,
  },
  places,
  totalPrice,
}) => {
  const selectedPlaces = places ? places.reduce((arr, { place }) => {
    if (!place) return arr;

    return [...arr, ...place];
  }, []) : [];

  const selectedPlacesMinMax = selectedPlaces.length > 1 && !isNaN(selectedPlaces[0])
    ? [Math.min(...selectedPlaces), Math.max(...selectedPlaces)]
    : selectedPlaces;

  return {
    ClassService,
    DateArrivalLocal: ArrivalDateLocal,
    DateArrive: ArrivalDate,
    DateDepartureLocal: DepartureDateLocal,
    DateDeparture: DepartureDate,
    TrainNumber,
    CarNumber,
    CarType: TypeShow,
    StationDepart,
    StationArrive,
    StationFrom,
    StationTo,
    PlaceNumber: selectedPlacesMinMax,
    TrainId,
    travellers: places?.length,
    totalPrice,
  };
};

export default mapSavedTicketToTrainItemModel;
