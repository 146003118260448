import { SPECIALCHARSBYGROUPS } from '../constants/regExp';

const textAbbreviation = (str: string, number: number) => {
  if (str.length <= number) {
    return str;
  }

  const cutStr = str.substring(0, number);
  const lastLetter = cutStr.charAt(cutStr.length - 1);

  if (lastLetter === '.') {
    return `${cutStr}..`;
  }

  const splitCutStr = str.split(' ');
  const newArrayStr: string[] = [];

  splitCutStr.forEach((s) => {
    if (newArrayStr.join(' ').length < number) {
      newArrayStr.push(s);
    }
  });

  if (newArrayStr.join(' ').length > number) {
    newArrayStr.pop();
  }

  const newStr = newArrayStr.join(' ');

  const lastSymbol = newStr.charAt(newStr.length - 1);
  const isValid = SPECIALCHARSBYGROUPS.test(lastSymbol);

  const changedStr = isValid ? newStr.substring(0, newStr.length - 1) : newStr;

  return `${changedStr}...`;
};

export default textAbbreviation;
