// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dialog, Button, Dropdown, RadioButton, Datepicker, Text, Select, PROPS } from 'new-ui';
import { getText } from '../../../../../../i18n';

import { isSmartAgent } from '../../../../../bi/utils/env';

import FORMATS from '../../../../../bi/constants/formats';
import { RADIO_VALUES } from '../../../../../bi/constants/report';

import styles from './index.module.css';

const RADIOFIELD = 'radioField';
const STARTDATE = 'startDate';
const ENDDATE = 'endDate';

const LABELS = {
  TITLE: getText('components:operationsTable.dialogs.downloadInvoice.title'),
  SUBTITLE: getText('components:operationsTable.dialogs.downloadInvoice.subtitle'),
  UNDO: getText('common:undo'),
  ALL_TIME: getText('components:operationsTable.dialogs.downloadInvoice.allTime'),
  AFTER_LAST_PAY: getText('components:operationsTable.dialogs.downloadInvoice.afterLastTime'),
  PICK_INVOICE: getText('components:operationsTable.dialogs.downloadInvoice.pickInvoice'),
  PDF: getText('components:operationsTable.dialogs.downloadInvoice.pdf'),
  XLSX: getText('components:operationsTable.dialogs.downloadInvoice.xlsx'),
  FROM: getText('components:operationsTable.dialogs.downloadInvoice.from'),
  FROM_LOWER: getText('components:operationsTable.dialogs.downloadInvoice.fromLower'),
  BY: getText('components:operationsTable.dialogs.downloadInvoice.by'),
};

class DownloadInvoiceDialog extends Component {
  static propTypes = {
    reportService: PropTypes.object.isRequired,
    onDownloadInvoiceForDateRange: PropTypes.func.isRequired,
    onDownloadInvoiceDebt: PropTypes.func.isRequired,
    onCloseDialog: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super();

    const {
      invoice: {
        startDate,
        endDate,
      },
      allInvoicesInfo: {
        loadingAllInvoices,
        allInvoices,
      },
    } = props.reportService.getOperations();

    this.state = {
      [RADIOFIELD]: RADIO_VALUES.LAST_PAYMENT,
      [STARTDATE]: startDate,
      [ENDDATE]: endDate,
      loadingAllInvoices,
      allInvoices,
      selectedInvoice: '',
    };
  }

  componentDidMount() {
    const { reportService: { subscribeOperations } } = this.props;
    this.unsubscribeFn = subscribeOperations(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribeFn();
  }

  updateState = ({ allInvoicesInfo: {
    loadingAllInvoices,
    allInvoices,
  } }) => {
    this.setState({
      loadingAllInvoices,
      allInvoices,
      selectedInvoice: allInvoices[0] ? JSON.parse(allInvoices[0].value) : '',
    });
  };

  handleSetInvoice = (selectedInvoice) => this.setState({ selectedInvoice: JSON.parse(selectedInvoice) });

  handleDownload = (format) => {
    const {
      reportService,
      onDownloadInvoiceForDateRange,
      onDownloadInvoiceDebt,
      onCloseDialog,
    } = this.props;
    const {
      [RADIOFIELD]: radioField,
      [STARTDATE]: startDate,
      [ENDDATE]: endDate,
      selectedInvoice,
    } = this.state;

    switch (radioField) {
      case RADIO_VALUES.ALL_TIME: {
        const data = {
          Period: RADIO_VALUES.ALL_TIME,
          Format: format,
          Invoice: null,
        };

        onDownloadInvoiceDebt(data);
        break;
      }
      case RADIO_VALUES.LAST_PAYMENT: {
        const data = {
          Period: RADIO_VALUES.LAST_PAYMENT,
          Format: format,
          Invoice: null,
        };

        onDownloadInvoiceDebt(data);
        break;
      }

      case RADIO_VALUES.DATE_RANGE: {
        onDownloadInvoiceForDateRange(startDate, endDate, format);
        break;
      }

      case RADIO_VALUES.WITH_DATE: {
        const data = {
          Period: RADIO_VALUES.WITH_DATE,
          Format: format,
          Invoice: selectedInvoice,
        };

        onDownloadInvoiceDebt(data);
        break;
      }
    }

    reportService.setInvoiceDialogSettings(RADIOFIELD, radioField);
    onCloseDialog();
  };

  handleSwitchRadio = (value) => {
    if (this.state[RADIOFIELD] !== value) {
      this.setState({ [RADIOFIELD]: value });
    }
  };

  handleChangeDate = (field, value) => {
    const dateField = this.state[field];

    if (dateField && !dateField.isSame(value)) {
      this.setState({
        [field]: value,
      });
    }
  };

  renderLabel = (item) => (
    <div className={ styles.wrap_label }>
      {LABELS.PICK_INVOICE}
      &nbsp;
      <Text color={ PROPS.TEXT.COLORS.ACCENT } type={ PROPS.TEXT.TYPES.NORMAL_16 }>{item.label}</Text>
    </div>
  );

  renderSelectInvoice = () => {
    const { allInvoices, selectedInvoice, [RADIOFIELD]: radioField } = this.state;

    if (!allInvoices.length) {
      return null;
    }

    const value = selectedInvoice ? JSON.stringify(selectedInvoice) : '';

    return (
      <div className={ styles.radio }>
        <RadioButton
          value={ RADIO_VALUES.WITH_DATE }
          checked={ radioField === RADIO_VALUES.WITH_DATE }
          alternativeDesign={ isSmartAgent }
          onChange={ () => this.handleSwitchRadio(RADIO_VALUES.WITH_DATE) }
        >
          <div className={ styles.range } onClick={ () => this.handleSwitchRadio(RADIO_VALUES.WITH_DATE) }>
            <Select
              search
              items={ allInvoices }
              value={ value }
              className={ styles.selectInvoice }
              onChange={ this.handleSetInvoice }
              renderLabel={ this.renderLabel }
            />
          </div>
        </RadioButton>
      </div>
    );
  };

  render() {
    const { onCloseDialog, show } = this.props;
    const {
      [RADIOFIELD]: radioField,
      [STARTDATE]: startDate,
      [ENDDATE]: endDate,
      selectedInvoice,
    } = this.state;

    const disabledBySelectedInvoice = radioField === RADIO_VALUES.WITH_DATE && !selectedInvoice;

    return (
      <Dialog
        show={ show }
        showClosing
        onChange={ onCloseDialog }
      >
        <div className={ styles.main }>
          <Text type={ PROPS.TEXT.TYPES.BOLD_20 }>{ LABELS.TITLE }</Text>
          <Text className={ styles.subtitle }>{ LABELS.SUBTITLE }</Text>
          <div className={ styles.radios }>
            <div className={ styles.radio }>
              <RadioButton
                value={ RADIO_VALUES.LAST_PAYMENT }
                checked={ radioField === RADIO_VALUES.LAST_PAYMENT }
                alternativeDesign={ isSmartAgent }
                onChange={ () => this.handleSwitchRadio(RADIO_VALUES.LAST_PAYMENT) }
              >
                <Text>{ LABELS.AFTER_LAST_PAY }</Text>
              </RadioButton>
            </div>
            {this.renderSelectInvoice()}
            <div className={ styles.radio }>
              <RadioButton
                value={ RADIO_VALUES.ALL_TIME }
                checked={ radioField === RADIO_VALUES.ALL_TIME }
                alternativeDesign={ isSmartAgent }
                onChange={ () => this.handleSwitchRadio(RADIO_VALUES.ALL_TIME) }
              >
                <Text>{ LABELS.ALL_TIME }</Text>
              </RadioButton>
            </div>
            <div className={ styles.radio }>
              <RadioButton
                value={ RADIO_VALUES.DATE_RANGE }
                checked={ radioField === RADIO_VALUES.DATE_RANGE }
                alternativeDesign={ isSmartAgent }
                onChange={ () => this.handleSwitchRadio(RADIO_VALUES.DATE_RANGE) }
              >
                <Text>{ LABELS.FROM }</Text>
              </RadioButton>
              <div className={ styles.range }>
                <Datepicker
                  placeholder={ LABELS.FROM_LOWER }
                  value={ startDate }
                  wrapperClassName={ styles.datepicker }
                  onChange={ v => this.handleChangeDate(STARTDATE, v) }
                  onFocus={ () => this.handleSwitchRadio(RADIO_VALUES.DATE_RANGE) }
                />
                <Text className={ styles.middleText }>{LABELS.BY}</Text>
                <Datepicker
                  placeholder={ LABELS.BY }
                  value={ endDate }
                  minDate={ startDate }
                  wrapperClassName={ styles.datepicker }
                  onChange={ v => this.handleChangeDate(ENDDATE, v) }
                  onFocus={ () => this.handleSwitchRadio(RADIO_VALUES.DATE_RANGE) }
                />
              </div>
            </div>
          </div>
          <div className={ styles.actions }>
            <Dropdown
              renderLabel={ () => <Text className={ styles.dropdown_title } type={ PROPS.TEXT.TYPES.NORMAL_14 }>{LABELS.PDF}</Text> }
              theme={ PROPS.DROPDOWN.THEME.PRIMARY }
              onClick={ disabledBySelectedInvoice ? () => {} : () => this.handleDownload(FORMATS.PDF) }
            >
              <Button
                className={ styles.subitem }
                type={ PROPS.BUTTON.TYPES.TEXTUAL }
                onClick={ () => this.handleDownload(FORMATS.XLSX) }
                disabled={ disabledBySelectedInvoice }
              >
                { LABELS.XLSX }
              </Button>
            </Dropdown>
            <Button
              className={ styles.cancel }
              type={ PROPS.BUTTON.TYPES.TEXTUAL }
              onClick={ onCloseDialog }
            >
              { LABELS.UNDO }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export { DownloadInvoiceDialog };
