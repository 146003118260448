import React, { useEffect, useState } from 'react';
import { Button, Dialog, Text, Input } from 'new-ui';

import { getText } from '../../../../../i18n';

import { additionalFeeValidation } from '../../../../bi/utils/additionalFee';

import { PRICE_FLOAT_REG_EXP, PRICE_FLOAT_REG_EXP_NO_OGR } from '../../../../bi/constants/regExp';

import styles from './styles/index.module.css';

const LABELS = {
  INPUT_CHECK: getText('cart:addFee:additionalFee:inputModal:add'),
};

interface ModalFeeProps {
  feeValue?: string,
  show: boolean,
  title: string,
  label: string,
  onSend(value: string): void,
  onCancel(): void,
  onDelete(): void,
  labelButtonDone: string
  labelButtonCancel: string,
  correctValueLabel: string,
}

const ModalFee = ({
  feeValue = '',
  show,
  title,
  label,
  onSend,
  onCancel,
  onDelete,
  labelButtonDone,
  labelButtonCancel,
  correctValueLabel,
}: ModalFeeProps) => {
  const [value, setValue] = useState(feeValue);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(additionalFeeValidation(value, PRICE_FLOAT_REG_EXP, PRICE_FLOAT_REG_EXP_NO_OGR));
  }, [value]);

  const handleDelete = () => {
    onDelete();
    setValue('');
  };

  const handleCancel = () => {
    onCancel();
    setValue('');
  };

  const handleCancelDialog = () => (
    title === LABELS.INPUT_CHECK ? handleCancel() : onCancel()
  );

  const isDisabledDone = value.length === 0 || !isValid;

  const handleSumbit = (e: any, data: string) => {
    e.preventDefault();

    if (isValid) {
      onSend(data);
    }
  };

  const renderError = () => {
    if (isValid || !value.length) {
      return null;
    }

    return (
      <Text
        type='NORMAL_10'
        color='orange'
        className={ styles.error }
      >
        { correctValueLabel }
      </Text>
    );
  };

  const labelHtml = label.length > 0
    ? <Text className={ styles.label }>{ label }</Text>
    : null;

  return (
    <Dialog
      onChange={ handleCancelDialog }
      show={ show }
      showClosing
    >
      <div className={ styles.wrapper }>
        <Text
          type='bold_20'
          className={ styles.title }
        >
          { title }
        </Text>
        <form
          onSubmit={ (e) => handleSumbit(e, value) }
        >
          { labelHtml }
          <Input
            type='text'
            value={ value }
            onChange={ (data: string) => setValue(data) }
          />
          { renderError() }
          <div className={ styles.action }>
            <Button
              formType='submit'
              disabled={ isDisabledDone }
            >
              { labelButtonDone }
            </Button>
            <Button
              formType='submit'
              className={ styles.cancel }
              type='textual-medium'
              onClick={ handleDelete }
            >
              { labelButtonCancel }
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default ModalFee;
