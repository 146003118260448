import { action, observable, makeObservable } from 'mobx';
import { ServerResponse } from './types';

class Store {
  @observable employeeTransactionsList: ServerResponse = {
    transactions: [],
    metadata: { totalCount: 0, limit: 6, offset: 0, sortBy: '', order: '' },
  };
  @observable balance = null;
  @observable commissionIsLoading = false;
  @observable commission = 0;
  @observable personalBalance = 0;

  constructor() {
    makeObservable(this);
  }

  @action setTransactions = (fetchedTransactions: ServerResponse) => {
    this.employeeTransactionsList = fetchedTransactions;
  };

  @action setCommission = (commission: number) => {
    this.commission = commission;
  };
  @action setCommissionLoading = (isLoading: boolean) => {
    this.commissionIsLoading = isLoading;
  };

  @action setPersonalBalance = (balance: number) => {
    this.personalBalance = balance;
  };
}
const EmployeePaymentStore = new Store();

export { EmployeePaymentStore, Store as EmployeePaymentStoreType };
