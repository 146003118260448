// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Button, Dialog, Text, Input, PROPS } from 'new-ui';

import { getText } from '../../../i18n';

import styles from './styles/index.module.css';

const {
  BUTTON: { FORM_TYPES: { SUBMIT }, TYPES: { TEXTUAL_MEDIUM } },
  TEXT: { TYPES: { BOLD_20 } },
} = PROPS;

const LABELS = {
  SEND: getText('common:send'),
  UNDO: getText('common:undo'),
};

const ID_APPROVAL_EMAIL = 'approvalEmail';

class InputModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.node,
    onSend: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    isValid: PropTypes.bool,
    onChange: PropTypes.func,
    qaAttrs: PropTypes.oneOfType([
      PropTypes.oneOf([null]),
      PropTypes.object,
    ]),
  };

  static defaultProps = {
    type: 'text',
    label: '',
    children: null,
    disabled: false,
    qaAttrs: null,
    isValid: true,
    onChange: () => null,
  };

  state = {
    value: '',
  };

  handleChange = value => {
    this.props.onChange(value);
    this.setState({
      value,
    });
  };

  render() {
    const {
      type,
      title,
      label,
      children,
      onSend,
      onCancel,
      show,
      loading,
      qaAttrs,
      isValid,
    } = this.props;

    const { value } = this.state;

    const labelHtml = label.length > 0 ? <Text className={ styles.label }>{ label }</Text> : null;

    return (
      <Dialog qaAttr={ qaAttrs?.qaAttrDialog || '' } onChange={ onCancel } show={ show } showClosing>
        <div className={ styles.wrapper }>
          <Text qaAttr={ qaAttrs?.qaAttrTitle || '' } type={ BOLD_20 } className={ styles.title }>{ title }</Text>
          <form onSubmit={ (e) => {
            e.preventDefault();
            onSend(value);
          } }
          >
            { labelHtml }
            <Input
              qaAttr={ qaAttrs?.qaAttrInput || '' }
              type={ type }
              id={ ID_APPROVAL_EMAIL }
              value={ value }
              onChange={ this.handleChange }
            />
            { children }
            <div className={ styles.action }>
              <Button
                qaAttr={ qaAttrs?.qaAttrSend || '' }
                formType={ SUBMIT }
                disabled={ value.length === 0 || !isValid }
                loading={ loading }
              >
                { LABELS.SEND }
              </Button>
              <Button
                qaAttr={ qaAttrs?.qaAttrClose || '' }
                formType={ SUBMIT }
                className={ styles.cancel }
                type={ TEXTUAL_MEDIUM }
                onClick={ onCancel }
                loading={ loading }
              >
                { LABELS.UNDO }
              </Button>
            </div>
          </form>
        </div>
      </Dialog>
    );
  }
}

export default InputModal;
