import { IPhoneNumberCountries } from '../../bi/types/shared';

const countriesList: IPhoneNumberCountries[] = [
  {
    label: '+7',
    value: 'RU',
    name: 'Russia (Россия)',
  },
  {
    label: '+375',
    value: 'BY',
    name: 'Belarus (Беларусь)',
  },
  {
    label: '+380',
    value: 'UA',
    name: 'Ukraine (Україна)',
  },
  {
    label: '+93',
    value: 'AF',
    name: 'Afghanistan (\u202bافغانستان\u202c\u200e)',
  },
  {
    label: '+355',
    value: 'AL',
    name: 'Albania (Shqipëri)',
  },
  {
    label: '+213',
    value: 'DZ',
    name: 'Algeria (\u202bالجزائر\u202c\u200e)',
  },
  {
    label: '+1',
    value: 'AS',
    name: 'American Samoa',
  },
  {
    label: '+376',
    value: 'AD',
    name: 'Andorra',
  },
  {
    label: '+244',
    value: 'AO',
    name: 'Angola',
  },
  {
    label: '+1',
    value: 'AI',
    name: 'Anguilla',
  },
  {
    label: '+1',
    value: 'AG',
    name: 'Antigua and Barbuda',
  },
  {
    label: '+54',
    value: 'AR',
    name: 'Argentina',
  },
  {
    label: '+374',
    value: 'AM',
    name: 'Armenia (Հայաստան)',
  },
  {
    label: '+297',
    value: 'AW',
    name: 'Aruba',
  },
  {
    label: '+61',
    value: 'AU',
    name: 'Australia',
  },
  {
    label: '+43',
    value: 'AT',
    name: 'Austria (Österreich)',
  },
  {
    label: '+994',
    value: 'AZ',
    name: 'Azerbaijan (Azərbaycan)',
  },
  {
    label: '+1',
    value: 'BS',
    name: 'Bahamas',
  },
  {
    label: '+973',
    value: 'BH',
    name: 'Bahrain (\u202bالبحرين\u202c\u200e)',
  },
  {
    label: '+880',
    value: 'BD',
    name: 'Bangladesh (বাংলাদেশ)',
  },
  {
    label: '+1',
    value: 'BB',
    name: 'Barbados',
  },
  {
    label: '+32',
    value: 'BE',
    name: 'Belgium (België)',
  },
  {
    label: '+501',
    value: 'BZ',
    name: 'Belize',
  },
  {
    label: '+229',
    value: 'BJ',
    name: 'Benin (Bénin)',
  },
  {
    label: '+1',
    value: 'BM',
    name: 'Bermuda',
  },
  {
    label: '+975',
    value: 'BT',
    name: 'Bhutan (འབྲུག)',
  },
  {
    label: '+591',
    value: 'BO',
    name: 'Bolivia',
  },
  {
    label: '+387',
    value: 'BA',
    name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
  },
  {
    label: '+267',
    value: 'BW',
    name: 'Botswana',
  },
  {
    label: '+55',
    value: 'BR',
    name: 'Brazil (Brasil)',
  },
  {
    label: '+246',
    value: 'IO',
    name: 'British Indian Ocean Territory',
  },
  {
    label: '+1',
    value: 'VG',
    name: 'British Virgin Islands',
  },
  {
    label: '+673',
    value: 'BN',
    name: 'Brunei',
  },
  {
    label: '+359',
    value: 'BG',
    name: 'Bulgaria (България)',
  },
  {
    label: '+226',
    value: 'BF',
    name: 'Burkina Faso',
  },
  {
    label: '+257',
    value: 'BI',
    name: 'Burundi (Uburundi)',
  },
  {
    label: '+855',
    value: 'KH',
    name: 'Cambodia (កម្ពុជា)',
  },
  {
    label: '+237',
    value: 'CM',
    name: 'Cameroon (Cameroun)',
  },
  {
    label: '+1',
    value: 'CA',
    name: 'Canada',
  },
  {
    label: '+238',
    value: 'CV',
    name: 'Cape Verde (Kabu Verdi)',
  },
  {
    label: '+599',
    value: 'BQ',
    name: 'Caribbean Netherlands',
  },
  {
    label: '+1',
    value: 'KY',
    name: 'Cayman Islands',
  },
  {
    label: '+236',
    value: 'CF',
    name: 'Central African Republic (République centrafricaine)',
  },
  {
    label: '+235',
    value: 'TD',
    name: 'Chad (Tchad)',
  },
  {
    label: '+56',
    value: 'CL',
    name: 'Chile',
  },
  {
    label: '+86',
    value: 'CN',
    name: 'China (中国)',
  },
  {
    label: '+61',
    value: 'CX',
    name: 'Christmas Island',
  },
  {
    label: '+61',
    value: 'CC',
    name: 'Cocos (Keeling) Islands',
  },
  {
    label: '+57',
    value: 'CO',
    name: 'Colombia',
  },
  {
    label: '+269',
    value: 'KM',
    name: 'Comoros (\u202bجزر القمر\u202c\u200e)',
  },
  {
    label: '+243',
    value: 'CD',
    name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
  },
  {
    label: '+242',
    value: 'CG',
    name: 'Congo (Republic) (Congo-Brazzaville)',
  },
  {
    label: '+682',
    value: 'CK',
    name: 'Cook Islands',
  },
  {
    label: '+506',
    value: 'CR',
    name: 'Costa Rica',
  },
  {
    label: '+225',
    value: 'CI',
    name: 'Côte d’Ivoire',
  },
  {
    label: '+385',
    value: 'HR',
    name: 'Croatia (Hrvatska)',
  },
  {
    label: '+53',
    value: 'CU',
    name: 'Cuba',
  },
  {
    label: '+599',
    value: 'CW',
    name: 'Curaçao',
  },
  {
    label: '+357',
    value: 'CY',
    name: 'Cyprus (Κύπρος)',
  },
  {
    label: '+420',
    value: 'CZ',
    name: 'Czech Republic (Česká republika)',
  },
  {
    label: '+45',
    value: 'DK',
    name: 'Denmark (Danmark)',
  },
  {
    label: '+253',
    value: 'DJ',
    name: 'Djibouti',
  },
  {
    label: '+1',
    value: 'DM',
    name: 'Dominica',
  },
  {
    label: '+1',
    value: 'DO',
    name: 'Dominican Republic (República Dominicana)',
  },
  {
    label: '+593',
    value: 'EC',
    name: 'Ecuador',
  },
  {
    label: '+20',
    value: 'EG',
    name: 'Egypt (\u202bمصر\u202c\u200e)',
  },
  {
    label: '+503',
    value: 'SV',
    name: 'El Salvador',
  },
  {
    label: '+240',
    value: 'GQ',
    name: 'Equatorial Guinea (Guinea Ecuatorial)',
  },
  {
    label: '+291',
    value: 'ER',
    name: 'Eritrea',
  },
  {
    label: '+372',
    value: 'EE',
    name: 'Estonia (Eesti)',
  },
  {
    label: '+251',
    value: 'ET',
    name: 'Ethiopia',
  },
  {
    label: '+500',
    value: 'FK',
    name: 'Falkland Islands (Islas Malvinas)',
  },
  {
    label: '+298',
    value: 'FO',
    name: 'Faroe Islands (Føroyar)',
  },
  {
    label: '+679',
    value: 'FJ',
    name: 'Fiji',
  },
  {
    label: '+358',
    value: 'FI',
    name: 'Finland (Suomi)',
  },
  {
    label: '+33',
    value: 'FR',
    name: 'France',
  },
  {
    label: '+594',
    value: 'GF',
    name: 'French Guiana (Guyane française)',
  },
  {
    label: '+689',
    value: 'PF',
    name: 'French Polynesia (Polynésie française)',
  },
  {
    label: '+241',
    value: 'GA',
    name: 'Gabon',
  },
  {
    label: '+220',
    value: 'GM',
    name: 'Gambia',
  },
  {
    label: '+995',
    value: 'GE',
    name: 'Georgia (საქართველო)',
  },
  {
    label: '+49',
    value: 'DE',
    name: 'Germany (Deutschland)',
  },
  {
    label: '+233',
    value: 'GH',
    name: 'Ghana (Gaana)',
  },
  {
    label: '+350',
    value: 'GI',
    name: 'Gibraltar',
  },
  {
    label: '+30',
    value: 'GR',
    name: 'Greece (Ελλάδα)',
  },
  {
    label: '+299',
    value: 'GL',
    name: 'Greenland (Kalaallit Nunaat)',
  },
  {
    label: '+1',
    value: 'GD',
    name: 'Grenada',
  },
  {
    label: '+590',
    value: 'GP',
    name: 'Guadeloupe',
  },
  {
    label: '+1',
    value: 'GU',
    name: 'Guam',
  },
  {
    label: '+502',
    value: 'GT',
    name: 'Guatemala',
  },
  {
    label: '+44',
    value: 'GG',
    name: 'Guernsey',
  },
  {
    label: '+224',
    value: 'GN',
    name: 'Guinea (Guinée)',
  },
  {
    label: '+245',
    value: 'GW',
    name: 'Guinea-Bissau (Guiné Bissau)',
  },
  {
    label: '+592',
    value: 'GY',
    name: 'Guyana',
  },
  {
    label: '+509',
    value: 'HT',
    name: 'Haiti',
  },
  {
    label: '+504',
    value: 'HN',
    name: 'Honduras',
  },
  {
    label: '+852',
    value: 'HK',
    name: 'Hong Kong (香港)',
  },
  {
    label: '+36',
    value: 'HU',
    name: 'Hungary (Magyarország)',
  },
  {
    label: '+354',
    value: 'IS',
    name: 'Iceland (Ísland)',
  },
  {
    label: '+91',
    value: 'IN',
    name: 'India (भारत)',
  },
  {
    label: '+62',
    value: 'ID',
    name: 'Indonesia',
  },
  {
    label: '+98',
    value: 'IR',
    name: 'Iran (\u202bایران\u202c\u200e)',
  },
  {
    label: '+964',
    value: 'IQ',
    name: 'Iraq (\u202bالعراق\u202c\u200e)',
  },
  {
    label: '+353',
    value: 'IE',
    name: 'Ireland',
  },
  {
    label: '+44',
    value: 'IM',
    name: 'Isle of Man',
  },
  {
    label: '+972',
    value: 'IL',
    name: 'Israel (\u202bישראל\u202c\u200e)',
  },
  {
    label: '+39',
    value: 'IT',
    name: 'Italy (Italia)',
  },
  {
    label: '+1',
    value: 'JM',
    name: 'Jamaica',
  },
  {
    label: '+81',
    value: 'JP',
    name: 'Japan (日本)',
  },
  {
    label: '+44',
    value: 'JE',
    name: 'Jersey',
  },
  {
    label: '+962',
    value: 'JO',
    name: 'Jordan (\u202bالأردن\u202c\u200e)',
  },
  {
    label: '+7',
    value: 'KZ',
    name: 'Kazakhstan (Казахстан)',
  },
  {
    label: '+254',
    value: 'KE',
    name: 'Kenya',
  },
  {
    label: '+686',
    value: 'KI',
    name: 'Kiribati',
  },
  {
    label: '+383',
    value: 'XK',
    name: 'Kosovo',
  },
  {
    label: '+965',
    value: 'KW',
    name: 'Kuwait (\u202bالكويت\u202c\u200e)',
  },
  {
    label: '+996',
    value: 'KG',
    name: 'Kyrgyzstan (Кыргызстан)',
  },
  {
    label: '+856',
    value: 'LA',
    name: 'Laos (ລາວ)',
  },
  {
    label: '+371',
    value: 'LV',
    name: 'Latvia (Latvija)',
  },
  {
    label: '+961',
    value: 'LB',
    name: 'Lebanon (\u202bلبنان\u202c\u200e)',
  },
  {
    label: '+266',
    value: 'LS',
    name: 'Lesotho',
  },
  {
    label: '+231',
    value: 'LR',
    name: 'Liberia',
  },
  {
    label: '+218',
    value: 'LY',
    name: 'Libya (\u202bليبيا\u202c\u200e)',
  },
  {
    label: '+423',
    value: 'LI',
    name: 'Liechtenstein',
  },
  {
    label: '+370',
    value: 'LT',
    name: 'Lithuania (Lietuva)',
  },
  {
    label: '+352',
    value: 'LU',
    name: 'Luxembourg',
  },
  {
    label: '+853',
    value: 'MO',
    name: 'Macau (澳門)',
  },
  {
    label: '+389',
    value: 'MK',
    name: 'Macedonia (FYROM) (Македонија)',
  },
  {
    label: '+261',
    value: 'MG',
    name: 'Madagascar (Madagasikara)',
  },
  {
    label: '+265',
    value: 'MW',
    name: 'Malawi',
  },
  {
    label: '+60',
    value: 'MY',
    name: 'Malaysia',
  },
  {
    label: '+960',
    value: 'MV',
    name: 'Maldives',
  },
  {
    label: '+223',
    value: 'ML',
    name: 'Mali',
  },
  {
    label: '+356',
    value: 'MT',
    name: 'Malta',
  },
  {
    label: '+692',
    value: 'MH',
    name: 'Marshall Islands',
  },
  {
    label: '+596',
    value: 'MQ',
    name: 'Martinique',
  },
  {
    label: '+222',
    value: 'MR',
    name: 'Mauritania (\u202bموريتانيا\u202c\u200e)',
  },
  {
    label: '+230',
    value: 'MU',
    name: 'Mauritius (Moris)',
  },
  {
    label: '+262',
    value: 'YT',
    name: 'Mayotte',
  },
  {
    label: '+52',
    value: 'MX',
    name: 'Mexico (México)',
  },
  {
    label: '+691',
    value: 'FM',
    name: 'Micronesia',
  },
  {
    label: '+373',
    value: 'MD',
    name: 'Moldova (Republica Moldova)',
  },
  {
    label: '+377',
    value: 'MC',
    name: 'Monaco',
  },
  {
    label: '+976',
    value: 'MN',
    name: 'Mongolia (Монгол)',
  },
  {
    label: '+382',
    value: 'ME',
    name: 'Montenegro (Crna Gora)',
  },
  {
    label: '+1',
    value: 'MS',
    name: 'Montserrat',
  },
  {
    label: '+212',
    value: 'MA',
    name: 'Morocco (\u202bالمغرب\u202c\u200e)',
  },
  {
    label: '+258',
    value: 'MZ',
    name: 'Mozambique (Moçambique)',
  },
  {
    label: '+95',
    value: 'MM',
    name: 'Myanmar (Burma) (မြန်မာ)',
  },
  {
    label: '+264',
    value: 'NA',
    name: 'Namibia (Namibië)',
  },
  {
    label: '+674',
    value: 'NR',
    name: 'Nauru',
  },
  {
    label: '+977',
    value: 'NP',
    name: 'Nepal (नेपाल)',
  },
  {
    label: '+31',
    value: 'NL',
    name: 'Netherlands (Nederland)',
  },
  {
    label: '+687',
    value: 'NC',
    name: 'New Caledonia (Nouvelle-Calédonie)',
  },
  {
    label: '+64',
    value: 'NZ',
    name: 'New Zealand',
  },
  {
    label: '+505',
    value: 'NI',
    name: 'Nicaragua',
  },
  {
    label: '+227',
    value: 'NE',
    name: 'Niger (Nijar)',
  },
  {
    label: '+234',
    value: 'NG',
    name: 'Nigeria',
  },
  {
    label: '+683',
    value: 'NU',
    name: 'Niue',
  },
  {
    label: '+672',
    value: 'NF',
    name: 'Norfolk Island',
  },
  {
    label: '+850',
    value: 'KP',
    name: 'North Korea (조선 민주주의 인민 공화국)',
  },
  {
    label: '+1',
    value: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    label: '+47',
    value: 'NO',
    name: 'Norway (Norge)',
  },
  {
    label: '+968',
    value: 'OM',
    name: 'Oman (\u202bعُمان\u202c\u200e)',
  },
  {
    label: '+92',
    value: 'PK',
    name: 'Pakistan (\u202bپاکستان\u202c\u200e)',
  },
  {
    label: '+680',
    value: 'PW',
    name: 'Palau',
  },
  {
    label: '+970',
    value: 'PS',
    name: 'Palestine (\u202bفلسطين\u202c\u200e)',
  },
  {
    label: '+507',
    value: 'PA',
    name: 'Panama (Panamá)',
  },
  {
    label: '+675',
    value: 'PG',
    name: 'Papua New Guinea',
  },
  {
    label: '+595',
    value: 'PY',
    name: 'Paraguay',
  },
  {
    label: '+51',
    value: 'PE',
    name: 'Peru (Perú)',
  },
  {
    label: '+63',
    value: 'PH',
    name: 'Philippines',
  },
  {
    label: '+48',
    value: 'PL',
    name: 'Poland (Polska)',
  },
  {
    label: '+351',
    value: 'PT',
    name: 'Portugal',
  },
  {
    label: '+1',
    value: 'PR',
    name: 'Puerto Rico',
  },
  {
    label: '+974',
    value: 'QA',
    name: 'Qatar (\u202bقطر\u202c\u200e)',
  },
  {
    label: '+262',
    value: 'RE',
    name: 'Réunion (La Réunion)',
  },
  {
    label: '+40',
    value: 'RO',
    name: 'Romania (România)',
  },
  {
    label: '+250',
    value: 'RW',
    name: 'Rwanda',
  },
  {
    label: '+590',
    value: 'BL',
    name: 'Saint Barthélemy (Saint-Barthélemy)',
  },
  {
    label: '+290',
    value: 'SH',
    name: 'Saint Helena',
  },
  {
    label: '+1',
    value: 'KN',
    name: 'Saint Kitts and Nevis',
  },
  {
    label: '+1',
    value: 'LC',
    name: 'Saint Lucia',
  },
  {
    label: '+590',
    value: 'MF',
    name: 'Saint Martin (Saint-Martin (partie française))',
  },
  {
    label: '+508',
    value: 'PM',
    name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
  },
  {
    label: '+1',
    value: 'VC',
    name: 'Saint Vincent and the Grenadines',
  },
  {
    label: '+685',
    value: 'WS',
    name: 'Samoa',
  },
  {
    label: '+378',
    value: 'SM',
    name: 'San Marino',
  },
  {
    label: '+239',
    value: 'ST',
    name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
  },
  {
    label: '+966',
    value: 'SA',
    name: 'Saudi Arabia (\u202bالمملكة العربية السعودية\u202c\u200e)',
  },
  {
    label: '+221',
    value: 'SN',
    name: 'Senegal (Sénégal)',
  },
  {
    label: '+381',
    value: 'RS',
    name: 'Serbia (Србија)',
  },
  {
    label: '+248',
    value: 'SC',
    name: 'Seychelles',
  },
  {
    label: '+232',
    value: 'SL',
    name: 'Sierra Leone',
  },
  {
    label: '+65',
    value: 'SG',
    name: 'Singapore',
  },
  {
    label: '+1',
    value: 'SX',
    name: 'Sint Maarten',
  },
  {
    label: '+421',
    value: 'SK',
    name: 'Slovakia (Slovensko)',
  },
  {
    label: '+386',
    value: 'SI',
    name: 'Slovenia (Slovenija)',
  },
  {
    label: '+677',
    value: 'SB',
    name: 'Solomon Islands',
  },
  {
    label: '+252',
    value: 'SO',
    name: 'Somalia (Soomaaliya)',
  },
  {
    label: '+27',
    value: 'ZA',
    name: 'South Africa',
  },
  {
    label: '+82',
    value: 'KR',
    name: 'South Korea (대한민국)',
  },
  {
    label: '+211',
    value: 'SS',
    name: 'South Sudan (\u202bجنوب السودان\u202c\u200e)',
  },
  {
    label: '+34',
    value: 'ES',
    name: 'Spain (España)',
  },
  {
    label: '+94',
    value: 'LK',
    name: 'Sri Lanka (ශ්\u200dරී ලංකාව)',
  },
  {
    label: '+249',
    value: 'SD',
    name: 'Sudan (\u202bالسودان\u202c\u200e)',
  },
  {
    label: '+597',
    value: 'SR',
    name: 'Suriname',
  },
  {
    label: '+47',
    value: 'SJ',
    name: 'Svalbard and Jan Mayen',
  },
  {
    label: '+268',
    value: 'SZ',
    name: 'Swaziland',
  },
  {
    label: '+46',
    value: 'SE',
    name: 'Sweden (Sverige)',
  },
  {
    label: '+41',
    value: 'CH',
    name: 'Switzerland (Schweiz)',
  },
  {
    label: '+963',
    value: 'SY',
    name: 'Syria (\u202bسوريا\u202c\u200e)',
  },
  {
    label: '+886',
    value: 'TW',
    name: 'Taiwan (台灣)',
  },
  {
    label: '+992',
    value: 'TJ',
    name: 'Tajikistan',
  },
  {
    label: '+255',
    value: 'TZ',
    name: 'Tanzania',
  },
  {
    label: '+66',
    value: 'TH',
    name: 'Thailand (ไทย)',
  },
  {
    label: '+670',
    value: 'TL',
    name: 'Timor-Leste',
  },
  {
    label: '+228',
    value: 'TG',
    name: 'Togo',
  },
  {
    label: '+690',
    value: 'TK',
    name: 'Tokelau',
  },
  {
    label: '+676',
    value: 'TO',
    name: 'Tonga',
  },
  {
    label: '+1',
    value: 'TT',
    name: 'Trinidad and Tobago',
  },
  {
    label: '+216',
    value: 'TN',
    name: 'Tunisia (\u202bتونس\u202c\u200e)',
  },
  {
    label: '+90',
    value: 'TR',
    name: 'Turkey (Türkiye)',
  },
  {
    label: '+993',
    value: 'TM',
    name: 'Turkmenistan',
  },
  {
    label: '+1',
    value: 'TC',
    name: 'Turks and Caicos Islands',
  },
  {
    label: '+688',
    value: 'TV',
    name: 'Tuvalu',
  },
  {
    label: '+1',
    value: 'VI',
    name: 'U.S. Virgin Islands',
  },
  {
    label: '+256',
    value: 'UG',
    name: 'Uganda',
  },
  {
    label: '+971',
    value: 'AE',
    name: 'United Arab Emirates (\u202bالإمارات العربية المتحدة\u202c\u200e)',
  },
  {
    label: '+44',
    value: 'GB',
    name: 'United Kingdom',
  },
  {
    label: '+1',
    value: 'US',
    name: 'United States',
  },
  {
    label: '+598',
    value: 'UY',
    name: 'Uruguay',
  },
  {
    label: '+998',
    value: 'UZ',
    name: 'Uzbekistan (Oʻzbekiston)',
  },
  {
    label: '+678',
    value: 'VU',
    name: 'Vanuatu',
  },
  {
    label: '+39',
    value: 'VA',
    name: 'Vatican City (Città del Vaticano)',
  },
  {
    label: '+58',
    value: 'VE',
    name: 'Venezuela',
  },
  {
    label: '+84',
    value: 'VN',
    name: 'Vietnam (Việt Nam)',
  },
  {
    label: '+681',
    value: 'WF',
    name: 'Wallis and Futuna',
  },
  {
    label: '+212',
    value: 'EH',
    name: 'Western Sahara (\u202bالصحراء الغربية\u202c\u200e)',
  },
  {
    label: '+967',
    value: 'YE',
    name: 'Yemen (\u202bاليمن\u202c\u200e)',
  },
  {
    label: '+260',
    value: 'ZM',
    name: 'Zambia',
  },
  {
    label: '+263',
    value: 'ZW',
    name: 'Zimbabwe',
  },
  {
    label: '+358',
    value: 'AX',
    name: 'Åland Islands',
  },
];

export { countriesList };
