import React from 'react';
import { Moment } from 'moment';

import { getText } from '../../../i18n';

import { InformationIcon } from '../InformationIcon';

const LABELS = {
  TOOLTIP: (timeType: string, moscowDate: string) => getText('components:localTime.tooltip', { timeType, moscowDate }),
};

interface LocalTimeProps {
  timeType: string;
  dateMoscow: Moment;
  dateLocal: Moment;
  dayMoscow: string;
  timeMoscow: string;
  className?: string;
  iconClassName?: string;
}

const LocalTime = ({
  dateLocal,
  dateMoscow,
  dayMoscow,
  timeMoscow,
  timeType,
  className = '',
  iconClassName = '',
}: LocalTimeProps) => {
  const moscowDate = dateLocal.isSame(dateMoscow, 'day') ? timeMoscow : dayMoscow;
  const tooltipText = LABELS.TOOLTIP(timeType, moscowDate);

  return !dateLocal.isSame(dateMoscow) &&
    <InformationIcon className={ className } text={ tooltipText } iconClassName={ iconClassName } />;
};

export { LocalTime };
