import React, { ReactNode } from 'react';
import { Input } from 'new-ui';

import { StickyPanel } from '../Sticky';

import { STICKY } from '../../bi/constants/stickySearch';

import styles from './index.module.css';

interface StickySearchPanelProps {
  adjustment: number,
  initial: number,
  style: typeof STICKY.STYLES,
  query?: string,
  placeholder?: string,
  children?: ReactNode | null,
  disabled?: boolean,
  onChangeInput?(value: string): void,
  onBlurFastSearch?(): void,
  qaAttr?: string,
}

const StickySearchPanel = ({
  adjustment,
  initial,
  style,
  query = '',
  placeholder = '',
  children = null,
  disabled = false,
  onChangeInput = () => {},
  onBlurFastSearch = () => {},
  qaAttr = '',
}: StickySearchPanelProps) => (
  <StickyPanel
    adjustment={ adjustment }
    initial={ initial }
    fixedClass={ styles['search--fixed'] }
    style={ style }
    className={ styles.panel }
    renderOnHeader
  >
    <div className={ styles.wrapper }>
      <div className={ styles.content }>
        <div className={ styles.search }>
          <Input
            className={ styles.input }
            onChange={ onChangeInput }
            value={ query }
            disabled={ disabled }
            isCleansing
            placeholder={ placeholder }
            onBlur={ onBlurFastSearch }
            qaAttr={ qaAttr }
          />
        </div>
        { children }
      </div>
    </div>
  </StickyPanel>
);

export default StickySearchPanel;
