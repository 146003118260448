import React from 'react';
import { Text, Price as PriceComponent } from 'new-ui';
import { getText } from '../../../../../i18n';

import { formatDate } from '../../../../bi/utils/formatDate';
import MoneyFormat from '../../../../bi/utils/money';

import { PATTERN } from '../../../../bi/constants/dateFormats';

import { ITripItemActualVersion } from '../../../../bi/types/trips';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('components:tripItem.historyChange.title'),
  PURCHASE: getText('components:tripItem.historyChange.purchase'),
};

interface IHistoryChangeProps {
  items: ITripItemActualVersion[];
}

const HistoryChange = ({ items }: IHistoryChangeProps) => {
  const historyHtml = items.reduce<React.ReactElement[]>((arr, {
    EventDate, Description, Id, Price, Penalties, Surcharges, ReturnFunds,
  }, index) => {
    const nextItem = items[index + 1];

    if (!nextItem || Price !== nextItem.Price) {
      const eventDate = formatDate(EventDate, PATTERN.FULLDATEWITHTIME);

      const content = (finances: any) => !!finances && !!finances.length && finances[0]?.Description && (
        <Text type='NORMAL_14_130' className={ styles.info } color='gray'>
          { finances[0].Description }: { MoneyFormat.money(finances[0].Amount, true) }
        </Text>
      );
      const description = !Description && !Penalties?.length && !Surcharges?.length && !ReturnFunds?.length ?
        LABELS.PURCHASE :
        Description;

      const html = (
        <div key={ Id } className={ styles.history }>
          <div className={ styles.col_1 }>
            <Text type='NORMAL_14' color='gray'>
              { eventDate }
            </Text>
            <Text type='NORMAL_14_130' className={ styles.info } color='gray'>
              { description }
            </Text>
            { content(Penalties) }
            { content(Surcharges) }
            { content(ReturnFunds) }
          </div>
          <PriceComponent marginSmall value={ Price } type='bold_16' typeCurrency='NORMAL_14' />
        </div>
      );

      return [...arr, html];
    }

    return arr;
  }, []);

  return (
    <div className={ styles.wrapper }>
      <Text type='SEMIBOLD_16' className={ styles.title }>{ LABELS.TITLE }</Text>
      { historyHtml }
    </div>
  );
};

export { HistoryChange };
