import { Moment } from 'moment';

import { getText } from '../../../../i18n';

import {
  dateWithoutMoment,
  isAfterDate,
  momentUtc,
  momentObject,
} from '../formatDate';
import { getAirlineIdOrCode } from '../airline';

import { PATTERN } from '../../constants/dateFormats';
import { FLY_CLASS_ENUM, VALUETICKET } from '../../constants/airline';
import { PASSPORTS_ADDITIONAL_CHANGE, PATH } from '../../constants/changeAirTrip';
import ROUTES from '../../constants/routes';

import { TypeDocument } from '../../../components/ChangeAirTripHeader/types';
import {
  RouteInterface,
  SelectedInterface,
  RouteSearchInterface,
  RouteInfoInterface,
  DocumentInterface,
  AirInterface,
} from './types';

const LABELS = {
  FARE: getText('components:fareDetails.fare'),
  CRUMB_TRIP: getText('components:changeAirTrip.breadCrumbs.trip'),
  CRUMB_TYPE_OF_CHANGE: getText('components:changeAirTrip.breadCrumbs.typeOfChange'),
  CRUMB_OTHER: getText('components:changeAirTrip.breadCrumbs.other'),
  CRUMB_PASSPORT: getText('components:changeAirTrip.breadCrumbs.changePassport'),
  CRUMB_DATE_AND_TIME: getText('components:changeAirTrip.breadCrumbs.changeDateAndTime'),
  CRUMB_DIRECTION: getText('components:changeAirTrip.breadCrumbs.changeDirection'),
  PASSPORT: getText('components:changeAirTrip.passport'),
  FOREIGN_PASSPORT: getText('components:changeAirTrip.foreignPassport'),
  BIRTH_CERTIFICATE: getText('components:changeAirTrip.birthCertificate'),
  FLIGHT: getText('air:seats.headerPanel.flight'),
};

const contentCrumbsChangeAir = (tripId: string, ticketId: string, changeTypeCrumbs: string) => {
  const crumbsResult = (startTripId: string, startTicketId: string, nameEnd: string) => [
    { name: LABELS.CRUMB_TRIP, link: ROUTES.CHANGE_AIR_TRIP.TRIP(startTripId) },
    { name: LABELS.CRUMB_TYPE_OF_CHANGE, link: ROUTES.CHANGE_AIR_TRIP.SELECT_CHANGE(startTripId, startTicketId) },
    { name: nameEnd },
  ];

  switch (changeTypeCrumbs) {
    case PATH.OTHER:
      return crumbsResult(tripId, ticketId, LABELS.CRUMB_OTHER);

    case PATH.PASSPORT:
      return crumbsResult(tripId, ticketId, LABELS.CRUMB_PASSPORT);

    case PATH.DATE:
      return crumbsResult(tripId, ticketId, LABELS.CRUMB_DATE_AND_TIME);

    case PATH.DIRECTION:
      return crumbsResult(tripId, ticketId, LABELS.CRUMB_DIRECTION);

    case PATH.DATE_RESULT:
      return crumbsResult(tripId, ticketId, LABELS.CRUMB_DATE_AND_TIME);

    case PATH.DIRECTION_RESULT:
      return crumbsResult(tripId, ticketId, LABELS.CRUMB_DIRECTION);

    default:
      return ([
        { name: LABELS.CRUMB_TRIP, link: ROUTES.CHANGE_AIR_TRIP.TRIP(tripId) },
        { name: LABELS.CRUMB_TYPE_OF_CHANGE },
      ]);
  }
};

const conditionFlightClass = (currentFlightClass: string): string => {
  const englishFlightClasses = [FLY_CLASS_ENUM.ECONOM, FLY_CLASS_ENUM.BUSINESS, FLY_CLASS_ENUM.FIRST];
  const isThereFlightClass = englishFlightClasses.includes(currentFlightClass);

  if (isThereFlightClass) {
    return currentFlightClass;
  }

  const russianFlightClasses = ['Эконом', 'Бизнес', 'Первый'];
  const currentIndexFlightClass = russianFlightClasses.indexOf(currentFlightClass);

  const flightClassInEnglish = englishFlightClasses[currentIndexFlightClass];
  const resultFlightClass = flightClassInEnglish || currentFlightClass;

  return resultFlightClass;
};

const isTicketChangeable = (IsTicketChangeable: string, ChangeTicketWithCondition: { [key: string]: string }) => (
  (IsTicketChangeable !== VALUETICKET.NOTOFFERED && IsTicketChangeable !== VALUETICKET.NOTDEFINED)
  || !!Object.keys(ChangeTicketWithCondition).length);

const isTicketChangeableAfterDeparture = (IsTicketChangeableAfterDeparture: string) => IsTicketChangeableAfterDeparture !== VALUETICKET.NOTOFFERED && IsTicketChangeableAfterDeparture !== VALUETICKET.NOTDEFINED;

const checkConditionTime = (route: RouteInterface, IsTicketChangeableAfterDeparture: string) => {
  const firstSegment = route.Segments[0];
  const lastSegment = route.Segments[route.Segments.length - 1];
  const checkInDate = firstSegment.DepartureDate;
  const checkOutDate = lastSegment.ArrivalDate;

  return isTicketChangeableAfterDeparture(IsTicketChangeableAfterDeparture)
    ? isAfterDate(checkOutDate, momentUtc())
    : isAfterDate(checkInDate, momentUtc());
};

const isCheckAfterDeparture = (Routes: RouteInterface[], IsTicketChangeableAfterDeparture: string) => (
  Routes.some((route: RouteInterface) => checkConditionTime(route, IsTicketChangeableAfterDeparture)));

const getCurrentSearchRoute = (Routes: RouteInterface[], IsTicketChangeableAfterDeparture: string) => (
  Routes.filter((route: RouteInterface) => checkConditionTime(route, IsTicketChangeableAfterDeparture)));

const defaultFieldSearchChange = (route: RouteInterface): [SelectedInterface, SelectedInterface, Moment] => {
  const firstSegment = route.Segments[0];
  const lastSegment = route.Segments[route.Segments.length - 1];

  const departureDateMoment = momentObject(firstSegment.DepartureDate);

  const dataDirectionFrom: SelectedInterface = {
    City: firstSegment.DepartureCity,
    Code: firstSegment.DepartureAirport.Code,
    Country: firstSegment.DepartureCountry,
    Name: firstSegment.DepartureAirport.Name,
  };

  const dataDirectionTo: SelectedInterface = {
    City: lastSegment.ArrivalCity,
    Code: lastSegment.ArrivalAirport.Code,
    Country: lastSegment.ArrivalCountry,
    Name: lastSegment.ArrivalAirport.Name,
  };

  return [dataDirectionFrom, dataDirectionTo, departureDateMoment];
};

const updatedFieldSearchChangeRoutesInfo = (route: RouteInfoInterface): [SelectedInterface, SelectedInterface, Moment] => {
  const departureDateMoment = route.departureTimeSource;

  const dataDirectionFrom: SelectedInterface = {
    City: route.from,
    Code: route.departureAirport.ID,
    Country: '',
    Name: route.departureAirport.Name,
  };

  const dataDirectionTo: SelectedInterface = {
    City: route.to,
    Code: route.arrivalAirport.ID,
    Country: '',
    Name: route.arrivalAirport.Name,
  };

  return [dataDirectionFrom, dataDirectionTo, departureDateMoment];
};

const updatedFieldSearchChangeRoutesSearch = (route: RouteSearchInterface): [SelectedInterface, SelectedInterface, Moment] => {
  const departureDateMoment = route.date;

  const dataDirectionFrom: SelectedInterface = {
    City: route.from.selected.City,
    Code: route.from.selected.Code,
    Country: route.from.selected.Country,
    Name: route.from.selected.Name,
  };

  const dataDirectionTo: SelectedInterface = {
    City: route.to.selected.City,
    Code: route.to.selected.Code,
    Country: route.to.selected.Country,
    Name: route.to.selected.Name,
  };

  return [dataDirectionFrom, dataDirectionTo, departureDateMoment];
};

const createFromToAirTicketChange = (air: AirInterface, newFareName: string) => {
  const result: string[] = [];

  air.Routes.forEach((route) => {
    const firstSegment = route.Segments[0];
    const lastSegment = route.Segments[route.Segments.length - 1];
    const flightNumbers = route.Segments.map(
      (segment) => `${getAirlineIdOrCode(segment)} ${segment.FlightNumber}`,
    ).join(', ');

    const flight = `\n${LABELS.FLIGHT}: ${flightNumbers}`;
    const from = `\n${firstSegment.DepartureCity} (${firstSegment.DepartureAirport.ID})`;
    const to = `${lastSegment.ArrivalCity} (${lastSegment.ArrivalAirport.ID})`;
    const timeFrom = dateWithoutMoment(firstSegment.DepartureTime, PATTERN.DATE_TIME_WITHOUT_SECONDS);
    const timeTo = dateWithoutMoment(lastSegment.ArrivalTime, PATTERN.DATE_TIME_WITHOUT_SECONDS);

    result.push(`${flight}${from} - ${to}, ${timeFrom} - ${timeTo}`);
  });

  result.push(`\n${LABELS.FARE}: ${newFareName}`);

  return result.join('');
};

const createCheckboxPassport = (
  isChildren: boolean,
  documents: DocumentInterface[],
  allowedDocumentTypes: string[] = [
    PASSPORTS_ADDITIONAL_CHANGE.DOMESTIC_PASSPORT.TYPE,
    PASSPORTS_ADDITIONAL_CHANGE.BIRTH_CERTIFICATE.TYPE,
    PASSPORTS_ADDITIONAL_CHANGE.FOREIGN_PASSPORT.TYPE,
  ],
) => {
  const domesticDocuments = documents.filter(doc => doc.Type === PASSPORTS_ADDITIONAL_CHANGE.DOMESTIC_PASSPORT.TYPE);
  const birthCertificate = documents.filter(doc => doc.Type === PASSPORTS_ADDITIONAL_CHANGE.BIRTH_CERTIFICATE.TYPE);
  const foreignDocuments = documents.filter(doc => doc.Type === PASSPORTS_ADDITIONAL_CHANGE.FOREIGN_PASSPORT.TYPE);

  const listDocument = [
    {
      ...PASSPORTS_ADDITIONAL_CHANGE.DOMESTIC_PASSPORT,
      NUMBER: domesticDocuments[0].Type,
    },
    PASSPORTS_ADDITIONAL_CHANGE.ADD_NEW_FIRST_FOREIGN_PASSPORT,
  ];

  if (isChildren) {
    listDocument[0] = {
      ...PASSPORTS_ADDITIONAL_CHANGE.BIRTH_CERTIFICATE,
      NUMBER: birthCertificate[0].Type,
    };
  }

  if (documents[1]?.Id !== 0 && (!documents[2] || documents[2]?.Id === 0)) {
    listDocument[1] = {
      ...PASSPORTS_ADDITIONAL_CHANGE.FOREIGN_PASSPORT,
      NUMBER: foreignDocuments[0]?.Number,
    };
    listDocument[2] = PASSPORTS_ADDITIONAL_CHANGE.ADD_NEW_SECOND_FOREIGN_PASSPORT;
  }

  if (documents[1]?.Id !== 0 && documents[2]?.Id !== 0 && documents[2]) {
    listDocument[1] = {
      ...PASSPORTS_ADDITIONAL_CHANGE.FOREIGN_PASSPORT,
      VALUE: `${PASSPORTS_ADDITIONAL_CHANGE.FOREIGN_PASSPORT.VALUE} #${foreignDocuments[0]?.Number}`,
      NUMBER: foreignDocuments[0]?.Number,
    };
    listDocument[2] = {
      ...PASSPORTS_ADDITIONAL_CHANGE.SECOND_FOREIGN_PASSPORT,
      VALUE: `${PASSPORTS_ADDITIONAL_CHANGE.FOREIGN_PASSPORT.VALUE} #${foreignDocuments[1]?.Number}`,
      NUMBER: foreignDocuments[1]?.Number,
    };
  }

  return listDocument.filter(document => allowedDocumentTypes.includes(document.TYPE));
};

const getPassportChangeAirTrip = (Type: TypeDocument) => {
  if (Type === 2) return LABELS.FOREIGN_PASSPORT;

  if (Type === 3) return LABELS.BIRTH_CERTIFICATE;

  return LABELS.PASSPORT;
};

export {
  contentCrumbsChangeAir,
  isTicketChangeable,
  isCheckAfterDeparture,
  getCurrentSearchRoute,
  defaultFieldSearchChange,
  updatedFieldSearchChangeRoutesInfo,
  updatedFieldSearchChangeRoutesSearch,
  createFromToAirTicketChange,
  conditionFlightClass,
  createCheckboxPassport,
  getPassportChangeAirTrip,
};
