const SYMBOLS = {
  COMMA: {
    type: 'comma',
    value: ',',
  },
  DOT: {
    type: 'dot',
    value: '.',
  },
  PLUS: {
    type: 'plus',
    value: '+',
  },
};

export default SYMBOLS;
