import React from 'react';
import { Button, Dialog, Text } from 'new-ui';
import { getText } from '../../../../../../../i18n';

import styles from '../../index.module.css';

const LABELS = {
  CLOSE_DIALOG: getText('components:tripDetailsDialog.automaticCancelTrip.close'),
};

interface IErrorDialogProps {
  showErrorDialog: boolean;
  errorMsg: string;
  toggleErrorDialog(): void;
}
const ErrorDialog = ({
  showErrorDialog,
  errorMsg,
  toggleErrorDialog,
}: IErrorDialogProps) => {
  if (!showErrorDialog) return null;

  return (
    <Dialog
      show
      showClosing
      onChange={ () => toggleErrorDialog() }
      className={ styles.error_dialog }
    >
      <Text type='NORMAL_16_140'>{ errorMsg }</Text>
      <Button
        className={ styles.error_button }
        type='textual-medium'
        onClick={ toggleErrorDialog }
      >
        { LABELS.CLOSE_DIALOG }
      </Button>
    </Dialog>
  );
};

export { ErrorDialog };
