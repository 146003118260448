import React from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../i18n';

import { formatDate } from '../../bi/utils/formatDate';

import { ADDITIONAL_SERVICES_CONSTS } from '../../bi/constants/additionalServices';
import { PATTERN } from '../../bi/constants/dateFormats';

import { IAirAdditionalServicesSegment } from '../../bi/types/airline';

import styles from './styles/style.module.css';

interface AdditionalServiceItemProps {
  item: {
    Type: number;
    TicketNumber: string;
    Segments: IAirAdditionalServicesSegment[];
    SelectedSeat?: {
      Number: number;
    };
    Baggage?: {
      Weight: number;
    };
    OtherService?: {
      Description: string;
    }
  }
}

const ADDITIONAL_SERVICES_LABELS: { [key: string]: string } = {
  Number: getText('components:tripItem.additionalServices.number'),
  Mass: getText('components:tripItem.additionalServices.mass'),
  DescriptionBaggage: getText('components:tripItem.additionalServices.descriptionBaggage'),
  Description: getText('components:tripItem.additionalServices.description'),
  emd: getText('components:tripItem.additionalServices.emd'),
  supplier: getText('components:tripItem.additionalServices.supplier'),
  price: getText('components:tripItem.additionalServices.price'),
  commission: getText('components:tripItem.additionalServices.commission'),
  vat: getText('components:tripItem.additionalServices.vat'),
};

const LABELS = {
  TICKET: (ticketNumber: string) => `${getText('components:tripItem.paidSeats.ticket')} #${ticketNumber}, `,
};

const AdditionalServiceItem = ({ item }: AdditionalServiceItemProps) => {
  const { Type, TicketNumber } = item;

  const ticketNumber = TicketNumber ? LABELS.TICKET(TicketNumber) : '';

  const getTicketTitle = (
    DepartureCity: string,
    DepartureAirportCode: string,
    ArrivalCity: string,
    ArrivalAirportCode: string,
  ) => `${DepartureCity} (${DepartureAirportCode}) - ${ArrivalCity} (${ArrivalAirportCode})`;

  const renderTicketAdditionalInfo = (
    id: number,
    routeTitle: string,
    departureDate: string,
  ) => (
    <Text key={ id } className={ styles.additional_info } type='NORMAL_14_130'>
      { ticketNumber }
      { routeTitle },&nbsp;
      { formatDate(departureDate, PATTERN.DATE_WITH_YEAR) }
    </Text>
  );

  const renderSegmentsInfo = () => {
    const { Segments } = item;

    return Segments.map(({
      Id,
      DepartureAirport: {
        City: DepartureCity,
        Code: DepartureAirportCode,
      },
      ArrivalAirport: {
        City: ArrivalCity,
        Code: ArrivalAirportCode,
      },
      DepartureDate,
    }) => {
      const routeTitle = getTicketTitle(
        DepartureCity,
        DepartureAirportCode,
        ArrivalCity,
        ArrivalAirportCode);

      return renderTicketAdditionalInfo(Id, routeTitle, DepartureDate);
    });
  };

  const renderAdditionalField = () => {
    let addField: string | number | undefined = '';

    if (Type === 0) {
      addField = item.SelectedSeat?.Number;
    }

    if (Type === 2 || Type === 3 || Type === 4) {
      addField = item.Baggage?.Weight;
    }

    if (Type === 11) {
      addField = item.OtherService?.Description;
    }

    return (
      ADDITIONAL_SERVICES_CONSTS[Type].fields.map((field, index) => (
        <Text
          key={ `${Type}_${index}_${ADDITIONAL_SERVICES_CONSTS[Type].name}` }
          className={ styles.additional_info }
          type='NORMAL_14_130'
        >
          { ADDITIONAL_SERVICES_LABELS[field] }: { addField }
        </Text>
      ))
    );
  };

  return (
    <>
      <Text type='bold_18'>{ADDITIONAL_SERVICES_CONSTS[Type].name}</Text>
      { renderSegmentsInfo() }
      { renderAdditionalField() }
    </>
  );
};

export { AdditionalServiceItem };
