import { getText } from '../../../i18n';

const CARD_FORM_ERRORS = {
  CardNumber_Empty: getText('payment:paymentFormErrors.cardNumberEmpty'),
  CardNumber_Invalid: getText('payment:paymentFormErrors.cardNumberInvalid'),
  Cvv_Empty: getText('payment:paymentFormErrors.cvvEmpty'),
  Cvv_Invalid: getText('payment:paymentFormErrors.cvvInvalid'),
  ExpDateMonthYear_Empty: getText('payment:paymentFormErrors.expDateMonthYearEmpty'),
  ExpDateMonthYear_Invalid: getText('payment:paymentFormErrors.expDateMonthYearInvalid'),
  ExpDateMonth_Empty: getText('payment:paymentFormErrors.expDateMonthEmpty'),
  ExpDateMonth_Invalid: getText('payment:paymentFormErrors.expDateMonthInvalid'),
  ExpDateYear_Empty: getText('payment:paymentFormErrors.expDateYearEmpty'),
  ExpDateYear_Invalid: getText('payment:paymentFormErrors.expDateYearInvalid'),
  EmptyAmount: getText('payment:topUpPersonalAccount.emptyAmount'),
};

export { CARD_FORM_ERRORS };
