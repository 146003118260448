import React, { useState } from 'react';
import { Checkbox, Dialog, Text, Textarea } from 'new-ui';
import { getText } from '../../../../../i18n';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';

import { ButtonTrip } from '../ButtonTrip';
import { ContainsHotelOrders } from '../ContainsHotelOrders';

import { ITripItem } from '../../../../bi/types/trips';
import { IVoucherTravellers, IVoucherTravellersWithId } from '../../../../bi/services/hotels/types';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('components:tripDetailsDialog.editDialog.title'),
  AIR_TITLE: getText('components:tripDetailsDialog.editDialog.airTitle'),
  REFUND_TITLE: getText('components:tripDetailsDialog.requestRefund.title'),
  CONTENT: getText('components:tripDetailsDialog.editDialog.content'),
  SELECT_EMPLOYEE: getText('components:tripDetailsDialog.requestRefund.chooseEmployes'),
  CONTENT_REFUND: getText('components:tripDetailsDialog.requestRefund.contentRefund'),
  SOON_ANSWER: getText('components:tripDetailsDialog.editDialog.soonAnswer'),
  HEADER: {
    AIR: getText('components:tripDetailsDialog.editDialog.header.air'),
    HOTEL: getText('components:tripDetailsDialog.editDialog.header.hotel'),
    DEFAULT: getText('components:tripDetailsDialog.editDialog.header.default'),
  },
  PLACEHOLDER: getText('components:tripDetailsDialog.editDialog.placeholder'),
  BUTTON: getText('components:tripDetailsDialog.editDialog.edit'),
};

interface IEditDialogProps {
  orderItems: ITripItem[],
  nameTrip: string,
  item: ITripItem,
  isChatLoading: boolean,
  isDisabled: boolean,
  onCloseDialog: () => void,
  onSubmitCancellationModal: (
    result: boolean,
    item: { ActualVersion: { ServiceType: string } } | ITripItem[],
    inputValue: string,
    serviceType: string,
    selectedEmployes?: IVoucherTravellersWithId[],
  ) => void,
}

const EditDialog = ({
  orderItems,
  item,
  item: { ActualVersion: { ServiceType } },
  nameTrip,
  isChatLoading,
  isDisabled,
  onCloseDialog,
  onSubmitCancellationModal,
}: IEditDialogProps) => {
  const isInsurance = ServiceType === SERVICETYPE.INSURANCE;
  const [inputValue, setInputValue] = useState('');
  const [selectedHotelOrders, setSelectedHotelOrders] = useState([item]);
  const [selectedEmployes, setSelectedEmployes] = useState<IVoucherTravellersWithId[]>([]);
  const titleEditOrRefund = isInsurance ? LABELS.REFUND_TITLE : LABELS.TITLE;
  const title = ServiceType === SERVICETYPE.AIR ? LABELS.AIR_TITLE : titleEditOrRefund;

  const getTooltipText = (type: string) => {
    if (type === SERVICETYPE.AIR) {
      return LABELS.HEADER.AIR;
    }

    if (type === SERVICETYPE.HOTEL) {
      return LABELS.HEADER.HOTEL;
    }

    return LABELS.HEADER.DEFAULT;
  };

  const handleChangeContainsCheckbox = (value: boolean, containsOrder: ITripItem) => {
    let newOrders = [...selectedHotelOrders];

    if (value) {
      newOrders.push(containsOrder);

      return setSelectedHotelOrders(newOrders);
    }

    newOrders = newOrders.filter(({ Id }) => Id !== containsOrder.Id);

    return setSelectedHotelOrders(newOrders);
  };

  const handleConfirmDialog = () => {
    if (ServiceType === SERVICETYPE.HOTEL) {
      return onSubmitCancellationModal(true, selectedHotelOrders, inputValue, ServiceType);
    }

    if (isInsurance) {
      return onSubmitCancellationModal(true, item, inputValue, ServiceType, selectedEmployes);
    }

    return onSubmitCancellationModal(true, item, inputValue, ServiceType);
  };

  const handleChange = (value: string) => setInputValue(value);

  const handleChangeCheckbox = (value: boolean, containsOrder: IVoucherTravellers, indx: number) => {
    const checkedItem: IVoucherTravellersWithId = { ...containsOrder, id: indx };
    const newOrders = [...selectedEmployes];

    if (value) {
      newOrders.push(checkedItem);

      return setSelectedEmployes(newOrders);
    }

    const updatedOrders = newOrders.filter(({ id }) => id !== checkedItem.id);

    return setSelectedEmployes(updatedOrders);
  };

  const renderCheckboxes = (traveller: IVoucherTravellers, index: number) => {
    const { Surname, Name, Patronymic = '' } = traveller;
    const isSelected = !!selectedEmployes.find(({ id }) => id === index);

    return (
      <Checkbox
        key={ index }
        value={ isSelected }
        onChange={ (value: boolean) => handleChangeCheckbox(value, traveller, index) }
        className={ styles.checkbox }
      >
        <Text type='SEMIBOLD_16'>{ `${Surname} ${Name} ${Patronymic}` }</Text>
      </Checkbox>
    );
  };

  const renderEmployeesCheckboxes = () => {
    if (!isInsurance) return null;

    const { VoucherTravellers } = JSON.parse(item.ActualVersion.JsonData);
    const checkboxes = VoucherTravellers.map((traveller: IVoucherTravellers, indx: number) => renderCheckboxes(traveller, indx));

    return (
      <div className={ styles.checkboxes }>
        <Text type='NORMAL_16_140'>{ LABELS.SELECT_EMPLOYEE }</Text>
        { checkboxes }
      </div>
    );
  };

  const isDisableSubmitBtn = isInsurance ? !selectedEmployes.length : !inputValue;
  const subtitle = isInsurance ? LABELS.CONTENT_REFUND : LABELS.SOON_ANSWER;
  const content = isInsurance ? '' : LABELS.CONTENT;

  return (
    <Dialog
      show
      showClosing
      className={ styles.wrapper }
      onChange={ () => onCloseDialog() }
    >
      <div className={ styles.content_wrapper }>
        <Text type='bold_20'>{ title }</Text>
        <Text type='NORMAL_16_140' className={ styles.trip_name }>{ nameTrip }</Text>
        <Text type='NORMAL_16_140'>{ subtitle }</Text>
        <ContainsHotelOrders
          serviceType={ ServiceType }
          orderItems={ orderItems }
          currentOrder={ item }
          onChangeCheckbox={ handleChangeContainsCheckbox }
          selectedHotelOrders={ selectedHotelOrders }
        />
        { renderEmployeesCheckboxes() }
        <Textarea
          placeholder={ getTooltipText(ServiceType) }
          className={ styles.textarea }
          value={ inputValue }
          onChange={ handleChange }
        />
        <Text type='NORMAL_14_130' color='gray' className={ styles.content }>
          { content }
        </Text>
        <ButtonTrip
          valueIsEmpty={ isDisableSubmitBtn }
          loading={ isChatLoading }
          hasDisabled={ isDisabled }
          firstWord={ LABELS.BUTTON }
          onCloseDialog={ onCloseDialog }
          onSubmitCancellationModal={ handleConfirmDialog }
        />
      </div>
    </Dialog>
  );
};

export { EditDialog };
