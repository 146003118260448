import React, { FC } from 'react';
import { Text } from 'new-ui';

import styles from './styles/index.module.css';

type RatingProps = {
  value: {
    Value: number,
    Text: string
  },
  onClick?: () => void,
  fullSize?: boolean,
  style?: any,
  qaAttr?: any,
  isFiltersHotelsInMicroservice?: boolean,
};

const prepareColor = (value: number) => {
  const preparedNumber = Number(value);

  if (preparedNumber < 7) {
    return '#3A4252';
  }

  if (preparedNumber >= 7 && preparedNumber < 8.5) {
    return '#275A45';
  }

  return '#209866';
};

const prepareRating = (value: number) => {
  if (value === 10) {
    return value;
  }

  return value.toFixed(1);
};

const Rating: FC<RatingProps> = ({
  onClick = () => {},
  value: { Value, Text: text },
  fullSize = false,
  style = {},
  qaAttr = '',
  isFiltersHotelsInMicroservice,
}) => {
  if (!Number(Value)) {
    return null;
  }

  const color = prepareColor(Value);
  const textContent = fullSize && !isFiltersHotelsInMicroservice && (<Text className={ styles.description } type='SEMIBOLD_14'>{ text }</Text>);
  const cursorStyle = !onClick ? '' : styles.pointer;
  const textContentNewSearch = isFiltersHotelsInMicroservice && fullSize
    ? (
      <Text className={ styles.description } type='SEMIBOLD_14'>{ text }</Text>
    ) : null;

  return (
    <div className={ `${styles.wrapper} ${cursorStyle}` } style={ style } onClick={ onClick }>
      { textContentNewSearch }
      <div data-qa={ qaAttr } className={ styles.number } style={ { backgroundColor: color } }>
        <Text className={ styles.text } type='NORMAL_16' >{ prepareRating(Value) }</Text>
      </div>
      { textContent }
    </div>
  );
};

export { Rating };
