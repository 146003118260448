const AIRFILTERTYPE = {
  PRICE: 'price',
  DIRECTTIME: 'directTime',
  DIRECTCOUNT: 'directCount',
  AIRLINES: 'airlines',
  ROUTESSELECTED: 'routesSelected',
  AIRPORTS: 'airports',
  ROUTESBORDERTIME: 'routesBorderTime',
  ROUTE_TRAVEL_TIME: 'routesTravelTime',
  ROUTE_TRAVEL_TIME_FIELD: 'duration',
  ONLYAIRLINE: 'onlyAirline',
  FLIGHTNUMBER: 'flightNumber',
  FLIGHTSNUMBERS: 'flightsNumbers',
  FAVORITES: 'favoriteId',
  REFUNDABLE: 'refundable',
  BAGGAGE: 'baggage',
  SELECTEDTRAVELPOLICY: 'selectedTravelPolicy',
  TRANSFER_AIRPORTS: 'transferAirports',
};

const HOTELFILTERTYPE = {
  BREAKFAST: 'breakfast',
  PRICE: 'price',
  SMARTHOTEL: 'smartHotel',
  PRICE_GUARANTEED: 'priceGuaranteed',
  HOTELNAME: 'hotelName',
  HASCANCELLATION: 'hasCancellation',
  STARS: 'stars',
  TYPE: 'type',
  AMENITIES: 'amenities',
  FAVORITES: 'favoriteId',
  VAT: 'hasVat',
  SELECTEDTRAVELPOLICY: 'selectedTravelPolicy',
  RATING: 'rating',
  ONLINE: 'online',
  RADIUS: 'radius',
  RECOMMENDED: 'recommended',
};

export { AIRFILTERTYPE as default, AIRFILTERTYPE, HOTELFILTERTYPE };
