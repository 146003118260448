import { action, computed, makeObservable, observable } from 'mobx';

import { ITripTag, IMappedTripTag } from './types';

export interface ITripTagsStore {
  tripTags: ITripTag[];
  loading: boolean;
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable tripTags: ITripTag[] = [];
  @observable loading = true;

  @action
  setTripTags = (tripTags: ITripTag[]): void => {
    this.tripTags = tripTags;
  };

  @action
  setLoading = (value: boolean): void => {
    this.loading = value;
  };

  @action
  addTripTag = (tripTag: ITripTag): void => {
    this.tripTags = [...this.tripTags, tripTag];
  };

  @computed
  get mappedTripTags(): IMappedTripTag[] {
    return this.tripTags.map(({ Id, Value }) => ({ value: Id, label: Value }));
  }

  getTripTags = (): ITripTag[] => this.tripTags;
}

const TripTagsStore = new Store();
export { TripTagsStore, Store as TripTagsStoreType };
