import React, { Component, createRef, RefObject } from 'react';
import { Moment, MomentInput } from 'moment';
import {
  StyledWrapper,
  Suggest,
  Datepicker,
  Button,
  Select,
  Text,
  Icon,
  Timepicker,
  TextColor,
} from 'new-ui';
import clsx from 'clsx';

import { getText } from '../../../../i18n';

import HotelsService from '../../../bi/services/hotels';
import FeatureFlags from '../../../bi/services/featureFlags';

import { HotelSuggestion } from '../../HotelSuggestion';

import { isSmartAgent } from '../../../bi/utils/env';
import {
  momentObject,
  formatDate,
  fullFormYear,
  isTimeOver,
} from '../../../bi/utils/formatDate';
import MainAnalytic from '../../../bi/utils/analytics/main';
import { getPlacingLabels } from '../../../bi/utils/hotels';

import ACTION from '../../../bi/services/hotels/action';
import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';
import { ADULTFIELD, CUSTOMCHECKINVALUES, CUSTOMCHECKOUTVALUES, DEFAULT_TIME_VALUE } from './constants';
import { CHECKIN, CHECKOUT, SELECTTRAVELLERS } from '../../../bi/constants/hotelsSearch';
import { DATEFORMATS, PATTERN } from '../../../bi/constants/dateFormats';
import { generateTravelers } from '../../../bi/constants/travelers';
import { HOTEL_FORM_REQUEST_FIELDS } from '../../../bi/constants/hotel';
import { SEARCH_MENU_TYPES, SEARCH_MENU_PAGE } from '../../../bi/services/hotels/constants/searchMenuTypes';

import {
  HotelAutocompleteItem,
  HotelSuggestItem,
  Region,
  ISearchStore,
} from '../../../bi/services/hotels/types';

import styles from './styles/hotel_search.module.css';

type Date = HotelAutocompleteItem | Moment | number | string | null;

const LABELS = {
  SUGGEST_PLACEHOLDER: getText('components:menu.hotel.cityOrHotel'),
  DATE_IN_PLACEHOLDER: getText('components:menu.hotel.dateIn'),
  DATE_OUT_PLACEHOLDER: getText('components:menu.hotel.dateOut'),
  TIME: getText('components:menu.hotel.time'),
  SEARCH: getText('common:search'),
  PLACING: getText('components:menu.hotel.placing'),
  GUESTS: getText('components:menu.hotel.guests'),
  EARLY_IN: getText('components:menu.hotel.earlyIn'),
  LATE_OUT: getText('components:menu.hotel.lateOut'),
  WITHOUT_EARLY_IN_OR_LATE_OUT: getText('components:menu.hotel.withoutEarlyInOrLateOut'),
};

const TRAVELERS = generateTravelers(6);

const TYPE_MENU = {
  SUB_MENU: 'subMenu',
  MENU: 'menu',
};

const DASH = '-';

const DATE_TIME_VALUES = {
  CHECKIN_TIME: 'checkin.time',
  CHECKOUT_TIME: 'checkout.time',
};

const DIRECTION_TYPES = {
  FROM: 'from',
  TO: 'to',
};

const PLUS_HOUR = 2;

interface HotelSearchMenuProps {
  hotelsService: HotelsService,
  onSearch(): void,
  onSearchSubMenu(): void,
  onChangeForm(): void,
  type: string,
  typePage: string,
  openDatePickerTo(mode: (mode: string) => void): void,
  openDatePickerFrom(mode: (mode: string) => void): void,
  disableRegion: boolean,
  searchOnTimeChanged: boolean,
  buttonsRefs: RefObject<HTMLElement>[],
  featureFlagsService: FeatureFlags,
}

interface HotelSearchMenuState {
  region: Region,
  checkin: Moment | null,
  checkout: Moment | null,
  customCheckin: Moment | null,
  customCheckout: Moment | null
  checkinMinDate: Moment,
  checkoutMinDate: Moment,
  adult: number
  travellersCount: number
  isValid: boolean,
  datepickersOpened: {
    from: boolean,
    to: boolean,
  },
  timePicker: {
    from: string,
    to: string,
  },
  isChangedTime: boolean,
  hotelName: string,
  colorText: TextColor,
}

class HotelSearchMenu extends Component<HotelSearchMenuProps, HotelSearchMenuState> {
  static defaultProps = {
    type: SEARCH_MENU_TYPES.DEFAULT,
    typePage: '',
    disableRegion: false,
    openDatePickerTo: () => {},
    openDatePickerFrom: () => {},
    searchOnTimeChanged: false,
    buttonsRefs: [],
  };

  suggest: React.RefObject<HTMLInputElement>;
  mapMenuTypesToRender: { [key: string]: () => JSX.Element };
  mapMenuTypesToSearch: { [key: string]: () => void };
  unsubscribeFn: () => void;

  constructor(props: HotelSearchMenuProps) {
    super(props);

    const { onSearchSubMenu, onSearch } = this.props;

    const {
      region,
      adult,
      travellersCount,
      checkin,
      checkout,
      checkinMinDate,
      checkoutMinDate,
      customCheckin,
      customCheckout,
    } = props.hotelsService.getSearchState();

    this.suggest = createRef();

    this.state = {
      region,
      checkin,
      checkout,
      checkinMinDate,
      checkoutMinDate,
      adult,
      travellersCount,
      customCheckin,
      customCheckout,
      isValid: this.handleValidSearchForm(),
      datepickersOpened: {
        from: false,
        to: false,
      },
      timePicker: {
        from: '',
        to: '',
      },
      isChangedTime: false,
      hotelName: region.label,
      colorText: this.props.featureFlagsService.getShowElementForSmartagent() && isSmartAgent ? 'white' : 'default',
    };

    this.mapMenuTypesToRender = {
      [SEARCH_MENU_TYPES.DEFAULT]: this.renderPanelMenu,
      [SEARCH_MENU_TYPES.SUB_MENU]: this.renderSubMenu,
    };

    this.mapMenuTypesToSearch = {
      [SEARCH_MENU_TYPES.DEFAULT]: onSearch,
      [SEARCH_MENU_TYPES.SUB_MENU]: onSearchSubMenu,
      [SEARCH_MENU_TYPES.DETAILS_MENU]: this.handleSearchFromDetailsMenu,
    };
  }

  componentDidMount() {
    this.unsubscribeFn = this.props.hotelsService.subscribeSearch(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribeFn();
  }

  updateState = (state: ISearchStore) => {
    const {
      region,
      travellersCount,
      adult,
      checkin,
      checkout,
      checkinMinDate,
      checkoutMinDate,
      customCheckin,
      customCheckout,
    } = state;
    this.setState({
      region,
      travellersCount,
      adult,
      checkin,
      checkout,
      checkinMinDate,
      checkoutMinDate,
      customCheckin,
      customCheckout,
      isValid: this.handleValidSearchForm(),
    });
  };

  setOpenedDatepicker = (field: string, value: boolean) =>
    this.setState((state) => ({
      ...state,
      datepickersOpened: { ...state.datepickersOpened,
        [field]: value },
    }));

  handleValidSearchForm = () => {
    const { hotelsService, type } = this.props;

    return hotelsService.searchFormIsValid(type);
  };

  handleSuggestSelected = (
    field: string,
    suggest: Date,
  ) => {
    if (suggest !== null) {
      this.props.hotelsService.setSearch(field, suggest);
      this.setOpenedDatepicker(DIRECTION_TYPES.FROM, true);

      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SEARCH.SEARCH_HOTEL_CITY_CHOSEN);
    }
  };

  handleChangeForm = (
    field: string,
    value: Date,
    withTime: boolean | string | Moment = false,
    type: string = TYPE_MENU.SUB_MENU,
  ) => {
    const { timePicker } = this.state;
    const { hotelsService, searchOnTimeChanged, onChangeForm } = this.props;

    const { errorHotelResearch } = hotelsService.getHotelState();

    const preparedField = field === ADULTFIELD
      ? ADULTFIELD
      : `${field}.${withTime ? 'time' : 'date'}`;

    if (field !== ADULTFIELD && !withTime) {
      this.setState({
        timePicker: {
          from: field === CHECKIN ? DASH : timePicker.from,
          to: field === CHECKOUT ? DASH : timePicker.to,
        },
        isChangedTime: true,
      });

      hotelsService.setSearch(`${field}.time`, null);
    }

    hotelsService.setSearch(preparedField, value);

    if (field === CHECKIN && preparedField === DATE_TIME_VALUES.CHECKIN_TIME) {
      const analyticsType = type === TYPE_MENU.MENU ?
        MainAnalytic.ACTIONS.HOTELS.SEARCH_EARLY_CHECKIN :
        MainAnalytic.ACTIONS.HOTELS.REGION_SEARCH_EARLY_CHECKIN;
      const preparedTime = formatDate(value as MomentInput, DATEFORMATS.TIME);

      MainAnalytic.sendFirebase(analyticsType, {
        name: preparedTime,
      });

      this.setState({
        timePicker: {
          from: preparedTime,
          to: timePicker.to,
        },
        isChangedTime: true,
      });
    }

    if (field === CHECKOUT && preparedField === DATE_TIME_VALUES.CHECKOUT_TIME) {
      const analyticsType = type === TYPE_MENU.MENU ?
        MainAnalytic.ACTIONS.HOTELS.SEARCH_LATE_CHECKOUT :
        MainAnalytic.ACTIONS.HOTELS.REGION_SEARCH_LATE_CHECKOUT;
      const preparedTime = formatDate(value as MomentInput, DATEFORMATS.TIME);

      MainAnalytic.sendFirebase(analyticsType, {
        name: preparedTime,
      });

      this.setState({
        timePicker: {
          from: timePicker.from,
          to: preparedTime,
        },
        isChangedTime: true,
      });
    }

    if (searchOnTimeChanged && (field === CHECKIN || field === CHECKOUT) && !errorHotelResearch) {
      onChangeForm();
    }
  };

  handleEditDateAmp = <Fn extends (...args: any[]) => void>(func: Fn, value: Moment, ...args: Parameters<Fn>) => {
    func(...args);
    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_HOTEL_DATE_CHOSEN(value.format()),
    );
  };

  handleChangeTime = (value: { from: string, to: string }) => {
    const {
      checkin,
      checkout,
      customCheckout,
      customCheckin,
    } = this.state;
    const { hotelsService, searchOnTimeChanged, onChangeForm } = this.props;

    const preparedCheckin = customCheckin || checkin;
    const preparedCheckout = customCheckout || checkout;

    if (value.from) {
      const momentValue = momentObject(`${formatDate(preparedCheckin, fullFormYear)} ${value.from}`);

      MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.HOTELS.REGION_SEARCH_EARLY_CHECKIN_TIME, {
        name: value.from,
      });

      hotelsService.setSearch(DATE_TIME_VALUES.CHECKIN_TIME, value.from !== DASH ? momentValue : null);
    }

    if (value.to) {
      const momentValue = momentObject(`${formatDate(preparedCheckout, fullFormYear)} ${value.to}`);

      MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.HOTELS.REGION_SEARCH_LATE_CHECKOUT_TIME, {
        name: value.to,
      });

      hotelsService.setSearch(DATE_TIME_VALUES.CHECKOUT_TIME, value.to !== DASH ? momentValue : null);
    }

    this.setState({
      timePicker: {
        from: value.from,
        to: value.to,
      },
      isChangedTime: true,
    });

    if (searchOnTimeChanged) {
      onChangeForm();
    }
  };

  handleChangeTravellersCount = (value: number | string) => {
    const { hotelsService } = this.props;
    const { adult } = this.state;

    const hasRoomCount = SELECTTRAVELLERS[value as number].find(item => item.value === adult);
    const newAdult = hasRoomCount ? adult : 1;

    hotelsService.setSearch(ADULTFIELD, newAdult);
    hotelsService.setSearch(HOTEL_FORM_REQUEST_FIELDS.TRAVELLERS_COUNT, value);
  };

  handleGetSuggests = (query: string) => {
    const { typePage, hotelsService } = this.props;
    const { hotelName } = this.state;

    if (typePage === SEARCH_MENU_PAGE.HOTEL && hotelName !== query) {
      hotelsService.errorHotelResearch(true);
    }

    if (typePage !== SEARCH_MENU_PAGE.HOTEL || hotelName !== query) {
      hotelsService.autocomplete(query);
    }
  };

  handleSearchFromDetailsMenu = () => {
    const { onSearch } = this.props;

    return onSearch();
  };

  handleSearch = (type: string) => {
    const { typePage, hotelsService } = this.props;

    if (typePage === SEARCH_MENU_PAGE.HOTEL) {
      hotelsService.errorHotelResearch(false);
    }

    const analyticsType = type === TYPE_MENU.SUB_MENU ?
      MainAnalytic.ACTIONS.HOTELS.REGION_SEARCH_BUTTON :
      MainAnalytic.ACTIONS.HOTELS.SEARCH_BUTTON;

    MainAnalytic.sendFirebase(analyticsType);

    this.mapMenuTypesToSearch[this.props.type]();
  };

  renderSuggestion = ({
    title = '',
    items = [],
    Name = '',
    FullName = '',
  }: HotelSuggestItem): JSX.Element => {
    const { region: { label: query } } = this.state;

    return (
      <HotelSuggestion
        items={ items }
        title={ title }
        Name={ Name }
        FullName={ FullName }
        query={ query }
      />
    );
  };

  handleGetHours = (preparedData: Moment, format: string) => (format === DATEFORMATS.DATE
    ? DEFAULT_TIME_VALUE
    : preparedData.hours()
  );

  handleChangeHours = (value: string | number, base: Moment, field: string) => {
    const {
      customCheckin,
      checkin,
    } = this.state;

    const departureDate = customCheckin || checkin;
    const isNumber = typeof value === 'number';
    const timeOver = isTimeOver(value, departureDate, PLUS_HOUR);

    if (field === CHECKIN && isNumber && timeOver) return;

    const newValue = isNumber
      ? base.clone().set({ hours: value, minutes: 0 })
      : null;

    this.handleChangeForm(field, newValue, true, TYPE_MENU.MENU);

    MainAnalytic.sendAmplitude(
      field === CHECKIN
        ? MainAnalytic.ACTIONS.SEARCH.SEARCH_HOTEL_EARLY_CHECK_IN_CHOSEN
        : MainAnalytic.ACTIONS.SEARCH.SEARCH_HOTEL_LATE_CHECK_OUT_CHOSEN,
    );
  };

  getKey = ({ items = [], title, Id }: HotelSuggestItem): number | string => {
    if (items.length) {
      return title;
    }

    return Id;
  };

  renderSelectItem = (time: number | string, label: string, field: string) => {
    const {
      customCheckin,
      checkin,
    } = this.state;

    const departureDate = customCheckin || checkin;
    const isNumber = typeof time === 'number';
    const timeOver = isTimeOver(time, departureDate, PLUS_HOUR);

    if (field === CHECKIN && isNumber && timeOver) {
      return (
        <div className={ styles.disable }>
          { label }
        </div>);
    }

    return label;
  };

  renderPanelMenu = (): JSX.Element => {
    const {
      region,
      checkin,
      checkout,
      checkinMinDate,
      checkoutMinDate,
      isValid,
      travellersCount,
      adult,
      customCheckin,
      customCheckout,
      datepickersOpened: {
        from,
        to,
      },
      colorText,
    } = this.state;
    const { featureFlagsService: { getShowElementForSmartagent } } = this.props;

    const optionsWrapper = clsx(styles.options, {
      [styles.options_sa]: isSmartAgent && getShowElementForSmartagent(),
    });

    const preparedCheckin = customCheckin || checkin;
    const preparedCheckout = customCheckout || checkout;
    const checkinFormat = customCheckin ? PATTERN.FULLDATEWITHTIME : DATEFORMATS.DATE;
    const checkoutFormat = customCheckout ? PATTERN.FULLDATEWITHTIME : DATEFORMATS.DATE;

    const placingHtml = travellersCount > 1 && (
      <div className={ styles.placing }>
        <Text type='NORMAL_14' className={ styles.text } color={ colorText }>
          { LABELS.PLACING }
        </Text>
        <div className={ styles['select-wrapper'] }>
          <Select
            qaAttr={ QA_ATTRIBUTES.hotels.search.travelersCount }
            className={ styles['select-round-edges'] }
            theme='default-small'
            items={ SELECTTRAVELLERS[travellersCount] }
            value={ adult }
            onChange={ (value: Date) => this.handleChangeForm(ADULTFIELD, value) }
          />
        </div>
      </div>
    );

    return (
      <StyledWrapper className={ styles.search }>
        <div className={ styles.menu }>
          <div className={ styles.suggest }>
            <Suggest
              isFetching={ region.loading }
              contentClassName={ styles['suggest-animation'] }
              inputClassName={ styles.suggest_border }
              placeholder={ LABELS.SUGGEST_PLACEHOLDER }
              ref={ this.suggest }
              value={ region.label }
              // @ts-ignore //TODO
              items={ region.suggests }
              // @ts-ignore
              onSelect={ suggest => this.handleSuggestSelected(ACTION.UPDATEREGIONSELECTED, suggest) }
              onChange={ this.handleGetSuggests }
              // @ts-ignore
              renderItem={ this.renderSuggestion }
              // @ts-ignore
              keyExtractor={ this.getKey }
              qaAttr={ QA_ATTRIBUTES.hotels.search.suggest }
              qaAttrFirstEl={ QA_ATTRIBUTES.hotels.search.suggestResult }
              qaAttrLoadingSuggest={ QA_ATTRIBUTES.hotels.search.suggestLoading }
              isCursorSelect
            />
          </div>
          <div className={ styles.date }>
            <Datepicker
              closeOnTabOut
              open={ from }
              withLabel
              placeholder={ LABELS.DATE_IN_PLACEHOLDER }
              type='dateTimeList'
              inputTheme='open'
              value={ preparedCheckin }
              onChange={ (value: Date, withTime: boolean | string | Moment) => {
                this.handleEditDateAmp(this.handleChangeForm, value as Moment, CHECKIN, value, withTime);
              } }
              onBlur={ () => this.setOpenedDatepicker(DIRECTION_TYPES.TO, true) }
              min={ checkinMinDate }
              format={ checkinFormat }
              direction='from'
              getCurrentOpen={ (value: boolean) => this.setOpenedDatepicker(DIRECTION_TYPES.FROM, value) }
              wrapperClassName={ styles.wrapper }
              inputClassName={ styles.input }
              wrapperDialogClassName={ styles['datepicker-dialog-class'] }
              isDuration
              // @ts-ignore
              durationDates={ [preparedCheckin, preparedCheckout] }
              alternativeDesign={ isSmartAgent }
              qaAttr={ QA_ATTRIBUTES.hotels.search.checkIn }
              qaAttrNextMonth={ QA_ATTRIBUTES.hotels.search.checkInNextMonth }
              disableTimeOver
            />
          </div>
          <div className={ styles.date }>
            <Datepicker
              closeOnTabOut
              open={ to }
              placeholder={ LABELS.DATE_OUT_PLACEHOLDER }
              withLabel
              type='dateTimeList'
              inputTheme='open'
              value={ preparedCheckout }
              onChange={ (value: Date, withTime: boolean | string | Moment) => {
                this.handleEditDateAmp(this.handleChangeForm, value as Moment, CHECKOUT, value, withTime);
              } }
              min={ checkoutMinDate }
              format={ checkoutFormat }
              direction='to'
              getCurrentOpen={ (value: boolean) => this.setOpenedDatepicker(DIRECTION_TYPES.TO, value) }
              wrapperClassName={ styles.wrapper }
              inputClassName={ styles.input }
              wrapperDialogClassName={ styles['datepicker-dialog-class'] }
              isDuration
              // @ts-ignore
              durationDates={ [preparedCheckin, preparedCheckout] }
              alternativeDesign={ isSmartAgent }
              qaAttr={ QA_ATTRIBUTES.hotels.search.checkOut }
              qaAttrNextMonth={ QA_ATTRIBUTES.hotels.search.checkOutNextMonth }
            />
          </div>
          <div className={ styles.action }>
            <Button
              qaAttr={ isValid ? QA_ATTRIBUTES.hotels.search.searchButton : '' }
              type='large'
              onClick={ () => this.handleSearch(TYPE_MENU.MENU) }
              className={ styles['search-btn'] }
              disabled={ !isValid }
            >
              { LABELS.SEARCH }
            </Button>
          </div>
        </div>
        <div className={ optionsWrapper }>
          <div className={ styles['placing-wrapper'] }>
            <div className={ styles.adults }>
              <Text
                type='NORMAL_14'
                className={ styles.text }
                color={ colorText }
              >
                { LABELS.GUESTS }
              </Text>
              <div>
                <Select
                  qaAttr={ QA_ATTRIBUTES.hotels.search.travelers }
                  className={ styles['select-round-edges'] }
                  theme='default-small'
                  items={ TRAVELERS }
                  value={ travellersCount }
                  alternativeDesign={ isSmartAgent }
                  onChange={ (value: number | string) => this.handleChangeTravellersCount(value) }
                />
              </div>
            </div>
            { placingHtml }
          </div>
          <div className={ styles['custom-checkin-checkout-wrapper'] }>
            <div className={ styles['custom-checkin-wrapper'] }>
              <Text
                type='NORMAL_14'
                className={ styles.text }
                color={ colorText }
              >
                { LABELS.EARLY_IN }
              </Text>
              <div className={ styles['select-wrapper'] }>
                <Select
                  qaAttr={ QA_ATTRIBUTES.hotels.search.earlyIn }
                  className={ styles['select-round-edges'] }
                  theme='default-small'
                  items={ CUSTOMCHECKINVALUES }
                  // @ts-ignore
                  value={ this.handleGetHours(preparedCheckin, checkinFormat) }
                  // @ts-ignore
                  onChange={ (value) => this.handleChangeHours(value, preparedCheckin, CHECKIN) }
                  renderLabel={ ({ value, label }: { value: string, label: string }) => {
                    if (value === DEFAULT_TIME_VALUE) {
                      return LABELS.WITHOUT_EARLY_IN_OR_LATE_OUT;
                    }

                    return label;
                  } }
                  renderItem={ ({ value, label }: { value: string, label: string }) => this.renderSelectItem(value, label, CHECKIN) }
                />
              </div>
            </div>
            <div className={ styles['custom-checkout-wrapper'] }>
              <Text
                type='NORMAL_14'
                className={ styles.text }
                color={ colorText }
              >
                { LABELS.LATE_OUT }
              </Text>
              <div className={ styles['select-wrapper'] }>
                <Select
                  qaAttr={ QA_ATTRIBUTES.hotels.search.lateOut }
                  className={ styles['select-round-edges'] }
                  theme='default-small'
                  items={ CUSTOMCHECKOUTVALUES }
                  // @ts-ignore
                  value={ this.handleGetHours(preparedCheckout, checkoutFormat) }
                  // @ts-ignore
                  onChange={ (value) => this.handleChangeHours(value, preparedCheckout, CHECKOUT) }
                  renderLabel={ ({ value, label }: { value: string, label: string }) => {
                    if (value === DEFAULT_TIME_VALUE) {
                      return LABELS.WITHOUT_EARLY_IN_OR_LATE_OUT;
                    }

                    return label;
                  } }
                />
              </div>
            </div>
          </div>
        </div>
      </StyledWrapper>
    );
  };

  renderSubMenu = (): JSX.Element => {
    const {
      region,
      checkin,
      checkout,
      checkinMinDate,
      checkoutMinDate,
      isValid,
      travellersCount,
      adult,
      customCheckout,
      customCheckin,
      timePicker,
      isChangedTime,
    } = this.state;

    const {
      hotelsService,
      openDatePickerTo,
      openDatePickerFrom,
      disableRegion,
      buttonsRefs,
    } = this.props;

    const preparedCheckin = customCheckin || checkin;
    const preparedCheckout = customCheckout || checkout;
    const checkinFormat = customCheckin ? PATTERN.FULLDATEWITHTIME : DATEFORMATS.DATE;
    const checkoutFormat = customCheckout ? PATTERN.FULLDATEWITHTIME : DATEFORMATS.DATE;
    const preparedCheckinTime = formatDate(preparedCheckin, DATEFORMATS.TIME);
    const preparedCheckoutTime = formatDate(preparedCheckout, DATEFORMATS.TIME);

    const prepareTime = {
      from: customCheckin ? preparedCheckinTime : DASH,
      to: customCheckout ? preparedCheckoutTime : DASH,
    };

    const valueTime = isChangedTime ? timePicker : prepareTime;

    // @ts-ignore
    const placingIcon = <span className='smartway-worker' />;
    const placingLabel: (JSX.Element | string)[] =
      getPlacingLabels(travellersCount, adult, placingIcon);

    const placingLabelMinWidth = travellersCount * 24;

    const renderPlacingLabel = () => <div style={ { minWidth: `${placingLabelMinWidth}px` } }>{ placingLabel.map(item => item) }</div>;

    const travellersMoreThanOne = travellersCount > 1;

    const placingHtml = travellersMoreThanOne && (
      <div className={ styles['travellers-wrapper'] }>
        <Select
          qaAttr={ QA_ATTRIBUTES.hotels.result.search.placing }
          className={ styles['select-round-edges'] }
          placeholder={ LABELS.PLACING }
          withLabel
          theme='dark'
          items={ SELECTTRAVELLERS[travellersCount] }
          value={ adult }
          renderLabel={ renderPlacingLabel }
          onChange={ (value: Date) => this.handleChangeForm(ADULTFIELD, value) }
        />
      </div>
    );

    // @ts-ignore
    return (
      <div className={ styles.sub }>
        <div className={ styles.wrapper }>
          <div className={ styles.it }>
            <Icon
              type='smartdeskHotel'
              className={ styles.icon }
              alternativeDesign={ isSmartAgent }
              qaAttr={ QA_ATTRIBUTES.hotels.result.search.icon }
            />
          </div>
          <div className={ `${styles.suggest} ${styles.it}` }>
            <div className={ styles['suggest-wrapper'] }>
              <Suggest
                qaAttr={ QA_ATTRIBUTES.hotels.result.search.suggest }
                contentClassName={ styles['suggest-animation'] }
                isFetching={ region.loading }
                ref={ this.suggest }
                theme='dark'
                placeholder={ LABELS.SUGGEST_PLACEHOLDER }
                value={ region.label }
                // @ts-ignore
                items={ region.suggests }
                // @ts-ignore
                onSelect={ suggest => this.handleSuggestSelected('region.selected', suggest) }
                // @ts-ignore
                onSaveStats={ (query, suggests, type, selected) =>
                  hotelsService.saveAutocompleteStats(query, suggests, type, selected) }
                onChange={ this.handleGetSuggests }
                // @ts-ignore
                renderItem={ this.renderSuggestion }
                disabled={ disableRegion }
                // @ts-ignore
                keyExtractor={ this.getKey }
              />
            </div>
          </div>
          <div className={ `${styles.it} ${styles.date}` }>
            <Datepicker
              placeholder={ LABELS.DATE_IN_PLACEHOLDER }
              withLabel
              type='dateTimeList'
              inputTheme='open'
              direction='from'
              theme='dark'
              value={ preparedCheckin }
              onChange={ (value: Date, withTime: boolean | string | Moment) => this.handleChangeForm(CHECKIN, value, withTime) }
              min={ checkinMinDate }
              format={ checkinFormat }
              openFromOutside={ openDatePickerFrom }
              inputClassName={ styles.input }
              wrapperClassName={ styles.date_wrapper }
              isDuration
              // @ts-ignore
              durationDates={ [preparedCheckin, preparedCheckout] }
              ref={ buttonsRefs[0] }
              alternativeDesign={ isSmartAgent }
              qaAttr={ QA_ATTRIBUTES.hotels.result.search.checkIn }
              qaAttrTopSwitcher={ QA_ATTRIBUTES.hotels.result.search.checkInDirection }
              qaAttrHours={ QA_ATTRIBUTES.hotels.result.search.checkInHours }
              qaAttrNextMonth={ QA_ATTRIBUTES.hotels.result.search.checkInNextMonth }
              disableTimeOver
            />
          </div>
          <div className={ `${styles.it} ${styles.date}` }>
            <Datepicker
              placeholder={ LABELS.DATE_OUT_PLACEHOLDER }
              withLabel
              min={ checkoutMinDate }
              type='dateTimeList'
              inputTheme='open'
              direction='to'
              theme='dark'
              value={ preparedCheckout }
              onChange={ (value: Date, withTime: boolean | string | Moment) => this.handleChangeForm(CHECKOUT, value, withTime) }
              format={ checkoutFormat }
              openFromOutside={ openDatePickerTo }
              inputClassName={ styles.input }
              wrapperClassName={ styles.date_wrapper }
              isDuration
              // @ts-ignore
              durationDates={ [preparedCheckin, preparedCheckout] }
              ref={ buttonsRefs[1] }
              alternativeDesign={ isSmartAgent }
              qaAttr={ QA_ATTRIBUTES.hotels.result.search.checkOut }
              qaAttrTopSwitcher={ QA_ATTRIBUTES.hotels.result.search.checkOutDirection }
              qaAttrHours={ QA_ATTRIBUTES.hotels.result.search.checkOutHours }
              qaAttrNextMonth={ QA_ATTRIBUTES.hotels.result.search.checkOutNextMonth }
            />
          </div>
          <div className={ `${styles.it} ${styles.time}` }>
            <Timepicker
              placeholder={ LABELS.TIME }
              value={ valueTime }
              inputClassName={ styles.input }
              wrapperClassName={ styles.time_wrapper }
              onChange={ this.handleChangeTime }
              departureDate={ preparedCheckin }
              disableTimeOver
            />
          </div>
          <div className={ `${styles.it} ${styles.travellers}` }>
            <div className={ styles['travellers-wrapper'] }>
              <Select
                qaAttr={ QA_ATTRIBUTES.hotels.result.search.guests }
                className={ styles.select }
                placeholder={ LABELS.GUESTS }
                withLabel
                theme='dark'
                items={ TRAVELERS }
                value={ travellersCount }
                alternativeDesign={ isSmartAgent }
                onChange={ (value: number | string) => this.handleChangeTravellersCount(value) }
              />
            </div>
            { placingHtml }
          </div>
          <div className={ `${styles.action}` }>
            <Button
              qaAttr={ QA_ATTRIBUTES.hotels.result.search.searchButton }
              type='search-large'
              onClick={ () => this.handleSearch(TYPE_MENU.SUB_MENU) }
              disabled={ !isValid }
            >
              { LABELS.SEARCH }
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.mapMenuTypesToRender[this.props.type]();
  }
}

export { HotelSearchMenu };
