import { action, computed, makeObservable, observable } from 'mobx';

import {
  isLoaderContext,
  LoaderContext,
  LoadingStatus,
  StatusObject,
  Config,
} from './types';
import { omit } from '../../../utils/common';

/**
 * Utilities store for wrap network requests and another async task.
 * Support features:
 *  - withLoading and withErrorBoundary decorators
 *  - static method returns value by status
 *  - status interaction (getter, setter)
 *  - message status (errors by default, and has customizable) interaction (getter, setter)
 * Simple usage example:
 * ```
 * class SomeService {
 *   // Enumerate the required status names here ↓ so that TS understands you better
 *   networkStore = new NetworkStatesStore<'requestStatus'>();
 *
 *   // bound is a decorator that adds a method to the object context
 *   // withInternalMethod is a decorator that calls method with wrapper passed in arg
 *
 *   // Flow (source status NEVER):
 *   // withLoading -- wrap function and set statuses to internal `networkStore` map.
 *   // Changing statuses: LOADING -> method processing -> LOADED
 *   // withErrorBoundary -- wrap function to try/catch.
 *   // On catch exit function with set ERROR status and save error
 *   @bound
 *   @withInternalMethod((o) => o.networkStore.withLoading('requestStatus'))
 *   @withInternalMethod((o) => o.networkStore.withErrorBoundary('requestStatus'))
 *   async processingRequest() {
 *      await this.api.request();
 *      // ServerRequest/ComplexLogic/Or other logic you want to wrap
 *   }
 *
 *   // this approximate semantic analogue processingRequest:
 *   async processingRequest2() {
 *      const result = null;
 *      try {
 *        this.loading = 'LOADING';
 *        result = await this.api.request();
 *        this.loading = 'LOADED';
 *      } catch (e) {
 *        this.error = e;
 *        this.loading = 'ERROR';
 *        return result
 *      }
 *      return result;
 *   }
 * }
 * ```
 * Usage in component:
 * ```
 * const Fc = () => {
 *    const someService = useServices('Some')
 *    const status = someService.networkStore.getStatus('requestStatus')
 *
 *    const className = useStatusCondition(status, {
 *     onLoading: 'loadingClass',
 *     onLoaded: 'successClass',
 *     // Field fallback required if at least one of keys (`onNever`, `onLoading`,
 *     // `onLoaded`, `onError`) not provided
 *     // In StatusCondition component same logic
 *     fallback: 'baseClass',
 *   })
 *    return (
 *      <div className={ className }>
 *        <StatusCondition
 *           onNever={ Form }
 *           onLoading={ DotLoading }
 *           onLoaded={ SuccessSent }
 *           onError={ ErrorInData }
 *           status={ status }
 *        />
 *      </div>
 *    )
 * }
 * ```
 */
export default class NetworkStatesStore<Names extends string | number> {
  loadingStatuses = LoadingStatus;

  @observable statusMap: Partial<Record<Names, LoadingStatus>> = {};
  @observable contextMap: Partial<Record<Names, LoaderContext>> = {};
  @observable errorMessages: Partial<Record<Names, any>> = {};
  config: Config | null = null;

  constructor(config?: Config) {
    makeObservable(this);

    if (config) {
      this.config = config;
    }
  }

  @action.bound
  setContext = (name: Names, value: LoadingStatus) => {
    const ctx = this.contextMap[name];

    if (isLoaderContext(ctx) && ctx.callNumbers) {
      ctx.callNumbers[value] = this.getCallNumber(name, value) + 1;
    } else {
      this.contextMap[name] = {
        ...ctx,
        callNumbers: { [value]: 1 },
      };
    }
  };

  @action.bound
  public setStatus = (name: Names, value: LoadingStatus) => {
    this.statusMap[name] = value;

    if (this.config?.contextRecord) this.setContext(name, value);
  };

  @action.bound
  public clearStatus = (name: Names) => {
    this.statusMap[name] = LoadingStatus.NEVER;
    this.errorMessages[name] = null;
  };

  @action.bound
  public clearAll = () => {
    this.contextMap = {};
    this.statusMap = {};
    this.errorMessages = {};
  };

  @action.bound
  public setMessages = (name: Names, value: any) => {
    this.errorMessages[name] = value;
  };

  public getStatus = computed(
    () => (name: Names) => this.statusMap[name] || LoadingStatus.NEVER,
  ).get();

  public getIsStatus = computed(
    () => (name: Names, status: LoadingStatus) => this.statusMap[name] === status,
  ).get();

  public getIsSomeStatuses = computed(
    () => (names: Names[], status: LoadingStatus) => (
      names.some((name) => this.statusMap[name] === status)
    ),
  ).get();

  getAbortTokenAxios = (name: Names) => {
    const axiosAbort = this.contextMap[name]?.axiosAbort;

    if (axiosAbort) {
      return axiosAbort.signal;
    }

    throw new Error('Check that function use wrapper withAbortRequestAxios');
  };

  getCallNumber = (name: Names, status: LoadingStatus) => {
    if (this.config?.contextRecord) {
      const ctx = this.contextMap[name];

      if (isLoaderContext(ctx)) {
        return ctx.callNumbers[status] || 0;
      }
    }

    throw new Error('context recording off!');
  };

  public withLoading = (name: Names) => async <Return>(originalMethod: () => Return): Promise<Return | null> => {
    this.setStatus(name, LoadingStatus.LOADING);
    const res = await originalMethod();

    if (this.statusMap[name] !== LoadingStatus.ERROR) {
      this.setStatus(name, LoadingStatus.LOADED);
    }

    return res;
  };

  public withLoadingAndCounter = (name: Names) => async <Return>(originalMethod: () => Return): Promise<Return | null> => {
    if (this.config?.contextRecord) {
      this.setStatus(name, LoadingStatus.LOADING);

      const res = await originalMethod();

      const counterAdd = this.getIsStatus(name, LoadingStatus.ERROR) ? 0 : 1;
      const callDuration = this.getCallNumber(name, LoadingStatus.LOADING)
        - this.getCallNumber(name, LoadingStatus.LOADED)
        - this.getCallNumber(name, LoadingStatus.ERROR)
        - counterAdd;

      if (this.statusMap[name] !== LoadingStatus.ERROR) {
        if (callDuration < 0) {
          this.contextMap = omit(this.contextMap, [name]);
        }

        if (callDuration < 1) {
          this.setStatus(name, LoadingStatus.LOADED);
        } else {
          this.setContext(name, LoadingStatus.LOADED);
        }
      }

      return res;
    }

    throw new Error('context recording is off!');
  };

  public withErrorBoundary = (
    name: Names,
    fallback?: (err: any, obj: NetworkStatesStore<Names>) => void,
  ) => async <Return>(
    originalMethod: () => Return,
  ): Promise<Return | null> => {
    let result: Return | null = null;

    try {
      result = await originalMethod();
    } catch (e) {
      this.setMessages(name, e);
      this.setStatus(name, LoadingStatus.ERROR);

      if (typeof fallback !== 'undefined') {
        fallback(e, this);
      }
    }

    return result;
  };

  withAbortRequestAxios = (
    name: Names,
  ) => async <Return>(
    originalMethod: () => Return,
  ): Promise<Return | null> => {
    const oldCtxAbort = this.contextMap[name]?.axiosAbort;

    if (oldCtxAbort) {
      oldCtxAbort.abort();
    }

    this.contextMap[name] = {
      ...this.contextMap[name],
      axiosAbort: new AbortController(),
    };

    return originalMethod();
  };

  withLoaderFlow = (
    name: Names,
    fallback?: (err: any, obj: NetworkStatesStore<Names>) => void,
  ) => async <Return>(originalMethod: () => Return) =>
    this.withErrorBoundary(name, fallback)(async () => this.withLoading(name)(originalMethod));

  withLoaderFlowCoroutine = (
    name: Names,
    fallback?: (err: any, obj: NetworkStatesStore<Names>) => void,
  ) => async <Return>(originalMethod: () => Return) =>
    this.withErrorBoundary(name, fallback)(async () => this.withLoadingAndCounter(name)(originalMethod));

  static statusCondition = <Return>(
    status: LoadingStatus,
    { onNever, onLoading, onLoaded, onError, ...fb }: StatusObject<Return>,
  ): Return => {
    switch (status) {
      case LoadingStatus.NEVER: {
        if (onNever) {
          return onNever;
        }

        break;
      }
      case LoadingStatus.LOADING: {
        if (onLoading) {
          return onLoading;
        }

        break;
      }
      case LoadingStatus.LOADED: {
        if (onLoaded) {
          return onLoaded;
        }

        break;
      }
      case LoadingStatus.ERROR: {
        if (onError) {
          return onError;
        }

        break;
      }
    }

    if ('fallback' in fb) {
      return fb.fallback;
    }

    throw Error('Bad args!');
  };
}
