import React from 'react';
import { Price, Progress, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import styles from '../../styles/index.module.css';

const LABELS = {
  ACCESS: getText('smartdesk:smartagentMenu.balance.access'),
  DEBT: getText('smartdesk:smartagentMenu.balance.debt'),
};

export interface IPrepaymentBalance {
  value: number,
  valueProgress: number,
  color: string,
  debt?: number,
}

const PrepaymentBalance = ({
  value,
  valueProgress,
  color,
  debt = 0,
}: IPrepaymentBalance) => {
  const overdraftClassname = debt ? styles.wrapper_title : '';
  const wrapperLabel = debt ? styles.wrapper_label : '';

  const renderTitle = (val: number, label: string, isOver: boolean = false) => {
    if (isOver && !val) {
      return null;
    }

    const overStyles = debt ? `${styles.text} ${styles.text_w}` : `${styles.text}`;

    return (
      <div className={ overStyles }>
        <Text type='bold_16'>
          { label }
        </Text>
        <Price
          type='bold_16'
          value={ val }
        />
      </div>
    );
  };

  const renderTable = (val: number, col: string, isOver: boolean = false) => {
    const classname = (debt && !isOver && styles.width_overdraft)
      || (debt && isOver && styles.overdraft)
      || '';

    if (isOver && !debt) {
      return null;
    }

    return (
      <div className={ classname }>
        <Progress
          isBig
          rounded
          value={ val }
          color={ col }
        />
      </div>
    );
  };

  return (
    <div className={ styles.value }>
      <div className={ overdraftClassname }>
        { renderTitle(value, LABELS.ACCESS) }
        { renderTitle(debt, LABELS.DEBT, true) }
      </div>
      <div className={ wrapperLabel }>
        { renderTable(valueProgress, color) }
        { renderTable(valueProgress, color, true) }
      </div>
    </div>
  );
};

export default PrepaymentBalance;
