import React from 'react';
import { MomentInput } from 'moment';

import FormatService from '../../bi/services/format';

import styles from './styles/request-hotel-item.module.css';

interface RequestHotelItemProps {
  formatService: FormatService,
  searchOptions: {
    Name: string,
    Address: string,
    CheckinDate: MomentInput,
    CheckoutDate: MomentInput,
  },
  comment: string,
}

const RequestHotelItem = ({
  formatService,
  searchOptions: {
    Name,
    Address,
    CheckinDate,
    CheckoutDate,
  },
  comment,
}: RequestHotelItemProps) => {
  const address = Address ? (<div>{ Address }</div>) : '';

  const hotelItem = (
    <div className={ styles.wrap }>
      <div className={ `${styles.column} ${styles.left}` }>
        <div className={ styles.city }>{ Name }</div>
        { address }
        <div className={ styles.date }>
          { formatService.date(CheckinDate) }
        </div>
      </div>
      <div className={ `${styles.column} ${styles.right}` }>
        <div className={ styles.date }>
          { formatService.date(CheckoutDate) }
        </div>
      </div>
    </div>
  );

  return (
    <div>
      { hotelItem }
      <div className={ styles.comment }>{ comment }</div>
    </div>
  );
};

export default RequestHotelItem;
