import { observable, action, makeObservable } from 'mobx';

import {
  DEFAULT_FILTER_SELECTOR,
  DEFAULT_REQUEST_BODY,
  DEFAULT_RESPONSE,
  DEFAULT_SELECTED,
  DEFAULT_SELECTED_VALUE,
  DEFAULT_TAGS,
  DEFAULT_TAGS_SEARCH,
  DEFAULT_REQUEST_BODY_TAGS,
  NULL_VALUE_NUMBER_ORG,
  NO_SELECTED_UNSHIFT,
} from '../../../constants/filters';

import {
  FilterSelectorType,
  TMappedResponse,
  IRequestBody,
  ItemEasy,
  filterTagsType,
  mappedCodeType,
  mappedCompanyType,
  mappedDepartmentsType,
  mappedTagsType,
  mappedType,
  responseFilters,
  selectedValues,
  TValueFilter,
  TagsType,
} from '../../../types/filters';

enum COUNTER {
  ONE = 1,
  ZERO = 0,
  MINUS_ONE = -1,
}

const editResponse = (selected: any) => {
  const isValueToReplace = (value: any) =>
    (Array.isArray(value) && value.length === COUNTER.ZERO) || value === COUNTER.MINUS_ONE;

  return Object.fromEntries(
    Object.entries(selected).map(([key, value]) => [
      key,
      !isValueToReplace(value) ? value : null,
    ]),
  );
};

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable response: responseFilters = DEFAULT_RESPONSE;
  @observable valueSelected: selectedValues = DEFAULT_SELECTED_VALUE;
  @observable filterSelector: FilterSelectorType = DEFAULT_FILTER_SELECTOR;
  @observable filterTags: filterTagsType = DEFAULT_TAGS;
  @observable filterTagsSearch: filterTagsType = DEFAULT_TAGS_SEARCH;
  @observable filterValues: IRequestBody = DEFAULT_REQUEST_BODY;
  @observable filterCounter: number = COUNTER.ZERO;
  @observable loadingResponse: boolean = false;
  @observable waitingResponseEmployee: boolean = true;
  @observable errorResponseEmployee: boolean = true;
  @observable errorResponse: boolean = false;
  @observable isFiltered: boolean = false;
  @observable multiValue: string[] = [
    this.filterSelector.Codes,
    this.filterSelector.Departments,
    this.filterSelector.Projects,
    this.filterSelector.TravelPolicies,
    this.filterSelector.Tags,
  ];

  @action
  setWaitingResponseEmployee = (value: boolean) => {
    this.waitingResponseEmployee = value;
  };

  @action
  setErrorResponseEmployee = (value: boolean) => {
    this.errorResponseEmployee = value;
  };

  @action
  mappedValues = (array: mappedType[]) => {
    const values = array.map(({ Name, Id } : mappedType) => ({ label: Name, value: Id }));

    return this.sortingValue(values);
  };

  @action
  sortingValue = (value: ItemEasy[]) =>
    value.slice().sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

  @action
  mappedCompanyValues = (array: mappedCompanyType[]) => {
    const company = array.map(({ CompanyName, ShortCompanyName, CompanyId } : mappedCompanyType) =>
      ({ label: ShortCompanyName || CompanyName, value: CompanyId }));

    return this.sortingValue(company);
  };

  @action
  mappedDepartmentsAll = (array: mappedCompanyType[]) => {
    const departments = array.reduce((result, { Departments }) => [...result, ...Departments], [] as mappedDepartmentsType[])
      .map(({ Id, Name, CompanyId }) => ({ value: Id, label: Name, company: CompanyId }));

    return this.sortingValue(departments);
  };

  @action
  mappedTagsValues = (array: mappedTagsType[]) => {
    const tags = array.map(({ Value, Id }: mappedTagsType) => ({ label: Value, value: Id }));

    return this.sortingValue(tags);
  };

  @action
  mappedDepartments = (companyId: number) => {
    const resDepartments = this.response.allDepartments.filter((item: ItemEasy) => item.company === companyId);

    this.response.Departments = this.sortingValue(resDepartments);

    if (resDepartments.length) {
      this.response.Departments.unshift(NO_SELECTED_UNSHIFT.DEPARTMENTS);
    }
  };

  @action
  mappedCode = (array: mappedCodeType[]) => {
    const codes = array.map(({ Code, Name }) => ({ label: Name, value: Code }));

    return this.sortingValue(codes);
  };

  @action
  mappedResponse = (data: TMappedResponse) => {
    this.response.Company = this.mappedCompanyValues(data[0]);
    this.response.Company.unshift(NULL_VALUE_NUMBER_ORG);

    this.response.allDepartments = this.mappedDepartmentsAll(data[0]);
    this.response.Projects = this.mappedValues(data[1]);
    this.response.Codes = this.mappedCode(data[4]);

    if (data[1].length) {
      this.response.Projects.unshift(NO_SELECTED_UNSHIFT.COST_CENTER);
    }

    this.response.Tags = this.mappedTagsValues(data[2]);

    if (data[2].length) {
      this.response.Tags.unshift(NO_SELECTED_UNSHIFT.TAGS);
    }

    this.response.TravelPolicies = this.mappedValues(data[3]);

    if (data[3].length) {
      this.response.TravelPolicies.unshift(NO_SELECTED_UNSHIFT.TRAVEL_POLICY);
    }
  };

  @action
  setLoadingResponse = (value: boolean) => {
    this.loadingResponse = value;
  };

  @action
  setErrorResponse = (value: boolean) => {
    this.errorResponse = value;
  };

  @action
  setValueSelected = (value: number | number[], type: string) => {
    this.valueSelected = {
      ...this.valueSelected,
      [type]: value,
    };
  };

  @action
  setValueFilter = (value: TValueFilter, type: string) => {
    this.filterValues = {
      ...this.filterValues,
      [type]: value,
    };

    this.isFiltered = JSON.stringify(this.filterValues) !== JSON.stringify(DEFAULT_REQUEST_BODY);
  };

  @action
  setFilters = (value: number | number[], type: string) => {
    const newValue = this.multiValue.includes(type) && value === COUNTER.MINUS_ONE ? [] : value;

    this.setValueSelected(newValue, type);

    if (Array.isArray(newValue)) {
      this.filterTags[type] = (newValue as number[]).flatMap((v: number) =>
        this.response[type].filter((r) => (r.value === v)),
      );

      return;
    }

    if (type === this.filterSelector.Company) {
      this.mappedDepartments(newValue);
    }

    this.filterTags[type] = this.response[type].filter(({ value: Value }) => Value === value);
  };

  @action
  resetSelected = () => {
    this.valueSelected = DEFAULT_SELECTED_VALUE;
    this.response.Departments = [];
    this.filterTags = DEFAULT_TAGS;
  };

  @action
  applyTags = () => {
    this.filterTagsSearch = JSON.parse(JSON.stringify(this.filterTags));

    const filterStatic = {
      Page: this.filterValues.Page,
      Step: this.filterValues.Step,
      SearchString: this.filterValues.SearchString,
    };

    this.filterValues = { ...editResponse(this.valueSelected), ...filterStatic };
  };

  @action
  calculateFilterCounter = () => {
    const {
      Sex,
      Document,
      Departments,
      Company,
      BonusCards,
      Status,
      Projects,
      Tags,
      TravelPolicies,
      Codes,
    } = this.valueSelected;

    const countSingle = (value: number, condition = COUNTER.MINUS_ONE) => Number(value > condition);

    const singleCounter = countSingle(Sex)
      + countSingle(Document)
      + countSingle(BonusCards)
      + countSingle(Status)
      + countSingle(Company);

    const arrayCounter = [...Projects, ...Tags, ...TravelPolicies, ...Codes, ...Departments].length;

    const counters = singleCounter + arrayCounter;

    this.filterCounter = counters > COUNTER.ZERO ? counters : COUNTER.ZERO;

    this.isFiltered = this.filterTagsSearch !== DEFAULT_TAGS_SEARCH;
  };

  @action
  editSelector = (type: string) => {
    this.setValueFilter(null, type);

    if (type === this.filterSelector.Company) {
      this.setFilters(DEFAULT_SELECTED.ALL_NEW, this.filterSelector.Departments);
      this.setValueFilter(null, this.filterSelector.Departments);

      return this.setFilters(DEFAULT_SELECTED.ALL_NEW, type);
    }

    return this.setFilters(DEFAULT_SELECTED.ALL_NEW, type);
  };

  @action
  decreaseСounter = (value: number = 0) => {
    const data = value || COUNTER.ONE;

    this.filterCounter = Math.max(COUNTER.ZERO, this.filterCounter - data);
  };

  @action
  deleteTag = (value: ItemEasy | TagsType[], type: string) => {
    this.decreaseСounter();

    if (type === this.filterSelector.Company) {
      if (this.filterTagsSearch.Departments instanceof Array &&
        JSON.stringify(this.filterTagsSearch.Departments) !== JSON.stringify(DEFAULT_TAGS_SEARCH.Departments)) {
        this.decreaseСounter(this.filterTagsSearch.Departments.length);
      }

      this.filterTagsSearch.Departments = DEFAULT_TAGS_SEARCH.Departments;
    }

    this.filterTagsSearch = Object.entries(this.filterTagsSearch).reduce<Record<string, any>>((acc, [key, val]) => {
      if (Array.isArray(val) && val.some((obj: ItemEasy) => obj === value)) {
        const filteredArray = val.filter((obj: ItemEasy) => obj !== value);

        if (filteredArray.length > COUNTER.ZERO) {
          acc[key] = filteredArray;

          this.setFilters(filteredArray.map(({ value: Value }) => Value) as number | number[], type);
          this.setValueFilter(filteredArray.map(({ value: Value }) => Value) as TValueFilter, type);
        } else {
          this.setFilters(DEFAULT_SELECTED.ARR, type);
          this.setValueFilter(null, type);
        }
      } else if (val !== value) {
        acc[key] = val;

        if (!this.multiValue.includes(type)) {
          this.editSelector(type);
        }
      }

      return acc;
    }, {});
  };

  @action
  editSearchString = (value: string) => {
    this.filterValues.SearchString = value;
  };

  @action
  editPage = (value: number) => {
    this.filterValues.Page = value;
  };

  @action
  resetTags = () => {
    this.filterTags = DEFAULT_TAGS;
    this.filterCounter = COUNTER.ZERO;
    this.filterTagsSearch = DEFAULT_TAGS_SEARCH;
    this.valueSelected = DEFAULT_SELECTED_VALUE;
    this.filterValues = { ...this.filterValues, ...DEFAULT_REQUEST_BODY_TAGS };
  };
}

const FiltersStore = new Store();

export { FiltersStore, Store as FiltersStoreType };
