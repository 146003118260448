import { action, makeObservable, observable } from 'mobx';

import type { IErrorDialog, IDownloadDialog } from '../../types/report';

export class ApproveReportStore {
  constructor() {
    makeObservable(this);
  }

  @observable downloadDialog: IDownloadDialog = {
    show: false,
    loading: false,
  };

  @observable errorDialog: IErrorDialog = {
    show: false,
    msg: '',
  };
  @observable commentsDialog: number | null = null;

  @action.bound
  openCommentDialog = (value: number) => {
    this.commentsDialog = value;
  };

  @action.bound
  closeCommentDialog = () => {
    this.commentsDialog = null;
  };

  @action.bound
  setDownloadDialog = (show: boolean, loading: boolean) => {
    this.downloadDialog = { show, loading };
  };

  @action.bound
  setErrorDialog = (show: boolean, msg: string) => {
    this.errorDialog = { show, msg };
  };
}

export const approveReportStore = new ApproveReportStore();
