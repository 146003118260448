import { logEvent, setUserProperties } from 'firebase/analytics';
import amplitude from 'amplitude-js';

import CONFIG from '../../../../config';
import { firebaseAnalytics } from '../../../../utils/firebase';

import { ACCOUNT_TYPE } from '../../constants/accounts';

import { CATEGORY, CATEGORY_RU, ACTIONS, ACTIONS_RU, LABELS } from './analyticConstants';

const HITTYPE = 'event';

const DEMO_ID = {
  WORKPLACE: 1434,
  SMART_AGENT: 40286,
};

const send = (eventCategory: string, eventAction: string, additional: any | null = null) => {
  // @ts-ignore
  if (CONFIG.METRIKS.GOOGLE.ENABLED && window.ga) {
    const data = {
      hitType: HITTYPE,
      eventCategory,
      eventAction,
    };

    if (additional && additional.label) {
      // @ts-ignore
      data.eventLabel = additional.label;
    }

    if (additional && typeof additional.value === 'number') {
      // @ts-ignore
      data.eventValue = additional.value;
    }

    // @ts-ignore
    window.ga('send', data);
  }
};

const sendFirebase = (eventAction: string, additional: any = null) => {
  if (CONFIG.METRIKS.GOOGLE.ENABLED) {
    logEvent(firebaseAnalytics, eventAction, additional);
  }
};

class Analytics {
  CATEGORY = CATEGORY;
  ACTIONS = ACTIONS;
  LABELS = LABELS;

  GALLERY = {
    LEFT: 'Стрелка влево',
    RIGHT: 'Стрелка вправо',
    PREVIEW: 'Превью',
  };

  companyId: number;

  // additional = { label: string, value: number }
  send = (category: string, action: string, additional: { label?: string | null, value?: any } | null = null) => {
    if (this.companyId && ((this.companyId === DEMO_ID.WORKPLACE) || (this.companyId === DEMO_ID.SMART_AGENT))) {
      return;
    }

    if (!ACTIONS_RU[category]) {
      return;
    }

    send(CATEGORY_RU[category], ACTIONS_RU[category][action], additional);
  };

  initAmplitude = (userId: string, accountId: number, companyId: number, type: string) => {
    const amplitudeKey = () => {
      if (type === ACCOUNT_TYPE.SMART_AGENT) {
        return CONFIG.ANALYTICS.AMPLITUDE.SMART_AGENT_KEY;
      }

      return CONFIG.ANALYTICS.AMPLITUDE.KEY;
    };

    amplitude.getInstance().init(amplitudeKey(), userId);
    amplitude.getInstance().setUserProperties({
      accountId,
      companyId,
    });
  };

  setUser = ({ CompanyId, Email, Rights: { AccountId } }: { CompanyId: number, Email: string, Rights: { AccountId: number } }) => {
    if (!CompanyId) return;

    this.companyId = CompanyId;

    // @ts-ignore
    if (CONFIG.METRIKS.GOOGLE.ENABLED && window.ga) {
      // @ts-ignore
      window.ga('set', 'userId', CompanyId);
      setUserProperties(firebaseAnalytics, {
        account_id: AccountId,
        email: Email,
        company_id: CompanyId,
      });
    }
  };

  sendFirebase = (action: string, additional: any = null) => {
    if (this.companyId && ((this.companyId === DEMO_ID.WORKPLACE) || (this.companyId === DEMO_ID.SMART_AGENT))) {
      return;
    }

    sendFirebase(action, additional);
  };

  sendAmplitude = (action: string, properties?: any) => {
    amplitude.getInstance().logEvent(action, properties);
  };

  sendAmplitudeArrayArgs = ([action, properties]: [string, any]) => {
    this.sendAmplitude(action, properties);
  };

  sendGalleryEvent = (category: string, action: string, type: string) => {
    if (!ACTIONS_RU[category]) {
      return;
    }

    send(CATEGORY_RU[category], ACTIONS_RU[category][action], { label: type });
  };

  sendHotelFavorite = (hotelId: number, pagePart: string) => {
    let label = 'Результат поиска';

    if (pagePart === 'top') {
      label = 'Страница отеля: первый заголовок';
    } else if (pagePart === 'bottom') {
      label = 'Страница отеля: второй заголовок';
    }

    send(CATEGORY_RU.HOTELS, ACTIONS_RU.HOTELS.ADDTOFAVORITES, { label, value: Number(hotelId) });
  };

  sendSearchMove = (label: string) => {
    if (label) {
      send(CATEGORY_RU.SMARTDESC, ACTIONS_RU.SMARTDESC.GOTOSEARCH, { label });
    }
  };
}

const MainAnalytic = new Analytics();

export default MainAnalytic;
