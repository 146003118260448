import { EnumRateName, IDates } from '../types/insurance';

const DEFAULT_BOOKED_INSURANCE = {
  orderDate: ['', ''] as IDates,
  rate: EnumRateName.DEFAULT,
  price: 0,
  isCart: true,
  items: [],
};

const DEFAULT_RATES = [
  { Rate: EnumRateName.BASE, Price: 500 },
  { Rate: EnumRateName.OPTIMAL, Price: 750 },
  { Rate: EnumRateName.BUSINESS, Price: 1000 },
];

const LABELS_OF_REFUND = {
  NOTOFFERED: 'NotOffered',
};

export {
  DEFAULT_BOOKED_INSURANCE,
  DEFAULT_RATES,
  LABELS_OF_REFUND,
};
