import React from 'react';

import styles from './styles/index.module.css';

interface BackgroundMapProps {
  withPlanes: boolean,
  withoutMargin: boolean,
}

const BackgroundMap = ({ withPlanes, withoutMargin }: BackgroundMapProps) => {
  const classNames = [styles.wrap];

  if (withPlanes) {
    classNames.push(styles['with-planes']);
  }

  if (withoutMargin) {
    classNames.push(styles['without-margin']);
  }

  return (
    <div className={ classNames.join(' ') } />
  );
};

export { BackgroundMap };
