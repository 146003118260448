import React, { FC } from 'react';
import { IconButton, Text } from 'new-ui';
import { IconColor } from 'new-ui/src/components/Icon/types';

import { getText } from '../../../i18n';

import { svgColor } from '../../bi/utils/env';

const LABELS = {
  DEFAULT: getText('components:toNote.default'),
};

interface ToNoteProps {
  label?: string,
  className?: string,
  disabled?: boolean,
  iconColor?: IconColor | string,
  onClick(e: React.MouseEvent): void,
  qaAttr?: string,
}

const ToNote: FC<ToNoteProps> = ({
  label = LABELS.DEFAULT,
  onClick = () => {},
  disabled = false,
  className = '',
  iconColor = '',
  qaAttr = '',
}) => (
  <div className={ className }>
    <IconButton
      disabled={ disabled }
      iconType='menuNote'
      iconColor={ iconColor as IconColor || svgColor }
      onClick={ onClick }
      qaAttr={ qaAttr }
    >
      <Text
        type='NORMAL_14'
      >
        { label }
      </Text>
    </IconButton>
  </div>
);

export { ToNote };
