import React from 'react';
import { Price, Skeleton, StyledWrapper, Text } from 'new-ui';
import { NavLink } from 'react-router-dom';
import { Moment } from 'moment';

import { getText } from '../../../i18n';

import PrepaymentBalance from './components/Prepayment';
import PrepaymentClosing from './components/PaymentClosing';

import ROUTES from '../../bi/constants/routes';
import { PAYMENT_TYPE } from '../../bi/constants/payment';

import { ICompanyFunds } from '../../bi/types/app';
import { IBalancePanel } from './types';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('smartdesk:smartagentMenu.balance.title'),
  ADD: getText('smartdesk:smartagentMenu.balance.add'),
  LIMIT: getText('smartdesk:smartagentMenu.balance.limit'),
  RUBLES: getText('common:currency'),
};

const DEFAULT_BAR: number = 1;

const BalancePanel = ({
  appService,
  workspaceService,
}: IBalancePanel) => {
  const { header: { CompanyFunds } } = appService.get();

  const renderPayment = ({
    HasDebt,
    PaymentType,
    BookingAmount,
    Debt,
    TotalOverdraft,
    NextPaymentAmount,
    NextPaymentDate,
  }: ICompanyFunds) => {
    if (PaymentType === PAYMENT_TYPE.PREPAYMENT) {
      const color = !BookingAmount || Debt > 0 ? 'red' : 'green';
      const debt = Debt < 0 ? 0 : Debt;

      return (
        <PrepaymentBalance
          value={ BookingAmount }
          valueProgress={ DEFAULT_BAR }
          color={ color }
          debt={ debt }
        />
      );
    }

    return (
      <PrepaymentClosing
        hasDebt={ HasDebt }
        nextPaymentDate={ NextPaymentDate as Moment | null }
        nextPaymentAmount={ NextPaymentAmount }
        totalLimit={ TotalOverdraft }
        bookingAmount={ BookingAmount }
        debt={ Debt }
      />
    );
  };

  const renderHeader = ({ PaymentType, TotalOverdraft }: ICompanyFunds) => {
    const renderTitle = () => {
      if (PaymentType && PaymentType === PAYMENT_TYPE.POST_PAYMENT) {
        return (
          <div className={ styles.wrapper_title }>
            <Text
              className={ styles.title }
              type='bold_24'
            >
              { LABELS.TITLE }
            </Text>
            <div className={ styles.total_overdraft }>
              <Text type='NORMAL_12' color='white' className={ styles.icon }>
                { LABELS.RUBLES }
              </Text>
              <Text
                className={ styles.limit }
                type='bold_14'
              >
                { LABELS.LIMIT }
              </Text>
              <Price
                marginSmall
                color='green'
                type='bold_16'
                typeCurrency='bold_16'
                value={ TotalOverdraft }
              />
            </div>
          </div>
        );
      }

      return (
        <div>
          <Text type='bold_24'>
            { LABELS.TITLE }
          </Text>
        </div>
      );
    };

    const renderAddBalance = () => {
      if (!workspaceService.canViewReports) {
        return null;
      }

      return (
        <NavLink to={ ROUTES.SETTINGS.BALANCE }>
          <Text color='emerald' type='NORMAL_14'>
            { LABELS.ADD }
          </Text>
        </NavLink>
      );
    };

    return (
      <div className={ styles.header }>
        { renderTitle() }
        { renderAddBalance() }
      </div>
    );
  };

  const renderContent = (funds: ICompanyFunds) => {
    if (!funds) {
      return (
        <StyledWrapper className={ styles.skeleton }>
          <Skeleton className={ styles.skeleton }/>
        </StyledWrapper>
      );
    }

    return (
      <StyledWrapper className={ styles.panel }>
        { renderHeader(funds) }
        <div className={ styles.payment }>
          { renderPayment(funds) }
        </div>
      </StyledWrapper>
    );
  };

  return (
    <div className={ styles.wrapper }>
      { renderContent(CompanyFunds[0]) }
    </div>
  );
};

export default BalancePanel;
