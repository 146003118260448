import { ValuesNewAnalytic } from '../types/analyticsSelect';

const onlyNewAnalyticsValues = (Values: ValuesNewAnalytic[], value: { Id: number }) => {
  const finderValues = value?.Id ? Values.find(({ Id }) => Id === value.Id)
    : null;

  return finderValues ? [{
    label: finderValues.Value,
    value: finderValues.Id,
  }] : [];
};

export {
  onlyNewAnalyticsValues,
};
