import React, { ReactNode } from 'react';

import RowDesignations from './components/RowDesignations';
import Space from './components/Space';
import Seat from './components/Seat';
import Tooltip from './components/Tooltip';
import Row from './components/Row';
import Legend from './components/Legend';

import type {
  AirlineClassType,
  SeatType,
  WingType,
  ExitType,
  SegmentType,
  PropertyType,
} from './types/types';

import styles from './styles/styles.module.css';

interface SelectedSeatsType {
  seat: SeatType;
  travellerId: number;
}

interface AirlineSeatMapProps {
  data: SegmentType;
  onSelect: (seat: SeatType) => void;
  selectedSeats: SelectedSeatsType[];
  employeeSelected?: number | null;
  hiddenLegend?: boolean;
  numbersOnSeats?: boolean;
  propertiesToHideInLegend?: PropertyType[];
  renderRightPanel?: () => ReactNode;
  employeeIdList: number[];
  qaAttrRow?: string;
  qaAttrUnselectedSeat?: string;
  arrayInfo: string | null;
}

const getWingType = ({
  wingStart,
  wingEnd,
  rowNumber,
}: {
  wingStart: number,
  wingEnd: number,
  rowNumber: number,
}): WingType => {
  if (rowNumber === wingStart) {
    return 'start';
  }

  if (rowNumber === wingEnd) {
    return 'end';
  }

  if (rowNumber > wingStart && rowNumber < wingEnd) {
    return 'regular';
  }

  return 'none';
};

const getExitType = ({
  seats = [],
  isLastRow = false,
}: {
  seats: SeatType[],
  isLastRow: boolean,
}): ExitType => {
  const hasExit = seats.some(({ Properties }) => Properties.includes('exit'));

  if (!hasExit) {
    return 'none';
  }

  if (isLastRow) {
    return 'back';
  }

  return 'front';
};

class AirlineSeatMap extends React.Component<AirlineSeatMapProps> {
  renderSeats({
    seats,
    airlineClass,
    columns,
  }: {
    seats: SeatType[],
    airlineClass: AirlineClassType,
    columns: string[]
  }) {
    const {
      onSelect,
      selectedSeats,
      numbersOnSeats = false,
      employeeIdList = [],
      employeeSelected,
      qaAttrUnselectedSeat,
    } = this.props;

    return columns
      .map((column: string, index: number) => {
        const seat = seats.find(({ Column }) => Column === column);

        if (!seat) {
          return <Space key={ index } airlineClass={ airlineClass } />;
        }

        const selectedSeat = selectedSeats.find(({ seat: { Row: sRow, Column } }) => seat.Row === sRow && seat.Column === Column);
        const activeSeat = selectedSeat?.travellerId === employeeSelected;
        const handleSelect = () => onSelect(seat);
        const isAvailable = seat.Properties.includes('availability');
        const tooltip = isAvailable && (
          <div className={ styles.tooltip_wrap }>
            <Tooltip seat={ seat } />
          </div>
        );

        const indexNumber = !!selectedSeat && numbersOnSeats
          ? employeeIdList.findIndex(id => selectedSeat.travellerId === id) + 1
          : null;

        return (
          <div
            key={ `${seat.Row}${seat.Column}` }
            className={ styles.seat_wrap }
          >
            <Seat
              number={ indexNumber }
              active={ activeSeat }
              selected={ !!selectedSeat }
              properties={ seat.Properties }
              airlineClass={ airlineClass }
              onSelect={ handleSelect }
              className={ styles.seat }
              qaAttrUnselectedSeat={ (!selectedSeat && isAvailable) ? qaAttrUnselectedSeat : '' }
            />
            { tooltip }
          </div>
        );
      });
  }

  renderRows() {
    const {
      data: {
        Rows,
        Class,
        EndWing,
        StartWing,
        Columns,
      },
      qaAttrRow = '',
    } = this.props;

    const wingStart = Number(StartWing);
    const wingEnd = Number(EndWing);
    const centeredNumber: boolean = Columns.split(' ').length % 2 === 0;
    const columns: string[] = Columns.split('');

    return Rows.map((seats, index) => {
      const rowNumber = Number(seats[0].Row);

      const wingType: WingType = getWingType({
        wingStart,
        wingEnd,
        rowNumber,
      });

      const exitType: ExitType = getExitType({
        seats,
        isLastRow: Rows.length - 1 === index,
      });

      const renderedSeats = this.renderSeats({
        seats,
        airlineClass: Class,
        columns,
      });

      return (
        <Row
          key={ rowNumber }
          number={ rowNumber }
          centeredNumber={ centeredNumber }
          wingType={ wingType }
          exitType={ exitType }
          qaAttr={ qaAttrRow }
        >
          {renderedSeats}
        </Row>
      );
    });
  }

  renderRowDesignations() {
    const { data: { Columns, Class } } = this.props;

    return <RowDesignations airlineClass={ Class } columns={ Columns } />;
  }

  renderPlane() {
    const rowDesignations = this.renderRowDesignations();
    const rows = this.renderRows();

    return (
      <div className={ styles.plane_wrap }>
        { rowDesignations }
        { rows }
      </div>
    );
  }

  renderInfo() {
    const { arrayInfo } = this.props;

    if (!arrayInfo) return null;

    return (
      <span className={ styles.info }>
        { arrayInfo }
      </span>
    );
  }

  renderLegend() {
    const {
      hiddenLegend = false,
      data: { Class },
      propertiesToHideInLegend,
    } = this.props;

    if (hiddenLegend) {
      return null;
    }

    return (
      <div className={ styles.legend }>
        <Legend
          airlineClass={ Class }
          hiddenProperties={ propertiesToHideInLegend }
        />
      </div>
    );
  }

  render() {
    const { renderRightPanel = () => null } = this.props;
    const plane = this.renderPlane();
    const legend = this.renderLegend();
    const selectedSeats = renderRightPanel();
    const info = this.renderInfo();

    return <div className={ styles.wrap }>
      { plane }
      <div className={ styles.column }>
        <div className={ styles.sticky_wrap }>
          { selectedSeats }
          { info }
          { legend }
        </div>
      </div>
    </div>;
  }
}

export { AirlineSeatMap };
