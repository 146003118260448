import numeral from 'numeral';

// symbol: '₽ '
// @ts-ignore
numeral.language('ru', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  currency: {
    symbol: ' ₽',
  },
});
// @ts-ignore
numeral.language('ru');

const isInteger = (num: number | string): boolean => num === parseInt(num?.toString(), 10);

class MoneyFormat {
  static money = (value: number | string, withSymbol = false) => {
    const isInt = isInteger(value);
    let pattern = isInt ? '0,0' : '0,0.00';

    if (withSymbol) pattern = isInt ? '0,0$' : '0,0$.00';

    return numeral(value).format(pattern);
  };

  static moneyWithDecimal = (value: number, withSymbol = false) => {
    let pattern = '0,0[.]00';

    if (withSymbol) pattern = '0,0[.]00$';

    return numeral(value).format(pattern);
  };

  static moneyWithDecimalField = (value: number | string | undefined, withSymbol = false) => {
    let pattern = '0,0[.]00';

    if (withSymbol) pattern = '0,0[.]00$';

    if (!value) {
      return null;
    }

    return numeral(value).format(pattern);
  };
}

export default MoneyFormat;
