import { COMPANY_DEPARTMENT_DIVIDER } from '../constants/cart';
import { withoutMentionSmartway } from '../constants/companiesRules';

interface CompanyWithDepartmentBody {
  CompanyId: string,
  DepartmentId: string | null;
}

const prepareAddEmployeeCompanyData = (value: string): CompanyWithDepartmentBody => {
  const splitedCompanyId = String(value).split(COMPANY_DEPARTMENT_DIVIDER);
  const isMulti = splitedCompanyId.length > 1;

  return {
    CompanyId: isMulti ? splitedCompanyId[0] : value,
    DepartmentId: isMulti ? splitedCompanyId[1] : null,
  };
};

const isWithoutMentionSmartway = (companyName: string) => withoutMentionSmartway.includes(companyName);

export { prepareAddEmployeeCompanyData, isWithoutMentionSmartway };
