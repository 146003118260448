import { getText } from '../../../i18n';
import { FIELDS_DIRECTION } from '../constants/vipHall';

import { Segment } from '../services/vipHall/types';
import { IMainInfo, IEmployee } from '../types/vipHall';

import { formatDate, fullFormYear } from './formatDate';

const LABELS = {
  AIRPORT: getText('vipHall:payment.airport'),
};

const prepareAirportName = (ticketData: any, mainInfo: IMainInfo, route: number, segment: number) => {
  const segments = ticketData.Routes[route].Segments[segment];

  return mainInfo.type === FIELDS_DIRECTION.ARRIVAL
    ? `${segments.ArrivalAirport.Name} (${segments.ArrivalAirport.Code})`
    : `${segments.DepartureAirport.Name} (${segments.DepartureAirport.Code})`;
};

const prepareFormatDate = (ticketData: any, mainInfo: IMainInfo, route: string, segment: number, format: string) => {
  const segments = ticketData.Routes[route].Segments[segment];

  return mainInfo.type === FIELDS_DIRECTION.ARRIVAL
    ? formatDate(segments.ArrivalDate, format)
    : formatDate(segments.DepartureDate, format);
};

const prepareFullInfoAirport = (ticketData: any, mainInfo: IMainInfo, route: string, segment: number) => {
  const segments = ticketData.Routes[route].Segments[segment];

  return mainInfo.type === FIELDS_DIRECTION.ARRIVAL
    ? `${segments.ArrivalCity} ${segments.ArrivalAirport.Name}`
    : `${segments.DepartureCity} ${segments.DepartureAirport.Name}`;
};

const prepareEmployeesBirth = (employees: IEmployee[], selectedEmployees: string[]) => {
  const listSelected = employees.filter(employee => selectedEmployees.includes(employee.Id.toString()));

  return listSelected.map(item => formatDate(item.Birthday, fullFormYear));
};

const prepareDataFlight = (segmentItem: Segment[], iataData: any, mainInfo: any, segment: number) => {
  const { type, terminal_name } = iataData;
  const { type: typeMain } = mainInfo;

  return segmentItem.map(({
    OperatingAirline,
    FlightNumber,
    ArrivalDate,
    DepartureDate,
    DepartureAirport,
    DepartureTerminal,
    DepartureCity,
    ArrivalAirport,
    ArrivalTerminal,
    ArrivalCity,
  }, index) => ({
    isTransit: typeMain === FIELDS_DIRECTION.TRANSIT && Number(segment) === index,
    number: `${OperatingAirline.Code} ${FlightNumber}`,
    date: type === FIELDS_DIRECTION.ARRIVAL
      ? ArrivalDate
      : DepartureDate,
    type: typeMain,
    departureDate: DepartureDate,
    arrivalDate: ArrivalDate,
    departureAirport: {
      name: `${LABELS.AIRPORT} ${DepartureAirport.Name}`,
      code: DepartureAirport.Code,
      terminal: type === FIELDS_DIRECTION.DEPARTURE
        ? terminal_name
        : DepartureTerminal,
      city: DepartureCity,
    },
    arrivalAirport: {
      name: `${LABELS.AIRPORT} ${ArrivalAirport.Name}`,
      code: ArrivalAirport.Code,
      terminal: type === FIELDS_DIRECTION.ARRIVAL
        ? terminal_name
        : ArrivalTerminal,
      city: ArrivalCity,
    },
  }));
};

export {
  prepareAirportName,
  prepareFormatDate,
  prepareEmployeesBirth,
  prepareFullInfoAirport,
  prepareDataFlight,
};
