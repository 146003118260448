import marker from '../images/map-point.svg';
import markerGreen from '../images/map-point-green.svg';
import markerSolid from '../images/map-point-solid.svg';
import markerSolidGreen from '../images/map-point-solid-green.svg';

import { isSmartAgent } from '../../../bi/utils/env';

const clusterOptions = {
  clusterIcons: [
    {
      href: isSmartAgent ? markerSolidGreen : markerSolid, // реализация перестала работать(яндекс лопухи), работает эта логика в clusterContent.ts. Эту оставить, что бы не было дефолтного страшного кластера от яндекса.
      size: [24, 35],
      offset: [-12, -35],
    },
  ],
  minClusterSize: 5,
  gridSize: 128,
  iconContentLayout: 'cluster#iconContent',
  maxZoom: 16,
};

const placeMarkOptions = {
  iconLayout: 'default#imageWithContent',
  iconImageHref: isSmartAgent ? markerGreen : marker,
  iconImageSize: [24, 35],
  iconImageOffset: [-12, -35],
  hideIconOnBalloonOpen: false,
  balloonContentLayout: null,
};

const placeMarkPointOptions = {
  draggable: true,
  preset: 'islands#redCircleDotIcon',
  zIndex: 1000,
};

const selectionOptions = {
  geodesic: true,
  opacity: 0.7,
  fill: true,
  fillColor: '#ff1d1d',
  fillOpacity: 0.2,
  strokeColor: '#ff1d1d',
  strokeWidth: 1,
};

export {
  clusterOptions,
  placeMarkOptions,
  placeMarkPointOptions,
  selectionOptions,
};
