const DEFAULT_MIN_AMOUNT = {
  VALUES: 1,
  LABELS: 'Нулевая поездка',
};

const TOTAL_AMOUNT_LENGTH = {
  DEFAULT: 9,
};

export {
  DEFAULT_MIN_AMOUNT,
  TOTAL_AMOUNT_LENGTH,
};
