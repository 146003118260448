import { getText } from '../../../i18n';

import { formatDate, formatRangeDateWithSimplicity } from './formatDate';
import formatHotelRangeDate from './hotel';

import { SERVICETYPE } from '../constants/serviceType';
import { AEROEXPRESS_TARIFF_MAP } from '../services/aeroexpress/const';
import { QA_ATTRIBUTES } from '../constants/attributesForTests';

import { OrderItem, TripVersion } from '../types/order';
import { AirRoute } from '../types/airline';

const LABELS = {
  SERVICES: {
    AIR: getText('trip:services.air'),
    TRAIN: getText('trip:services.train'),
    TRANSFER: getText('trip:services.transfer'),
    AEROEXPRESS: getText('trip:services.aeroexpress'),
    TAXI: getText('trip:services.taxi'),
  },
  DOUBLE_CANCELLATION: getText('components:tripDetailsDialog.button.doubleCancellation'),
  TAXI_DOUBLE_CANCELLATION: getText('components:tripDetailsDialog.button.taxiDoubleCancellation'),
  CANCEL_RESERVATION: getText('components:tripDetailsDialog.button.cancelReservation'),
  AIR_CANCEL_RESERVATION: getText('components:tripDetailsDialog.button.airCancelReservation'),
  SEND_REQUEST: getText('components:tripDetailsDialog.button.sendRequest'),
};

const getTripItems = (mappedOrderItems: OrderItem[]) => mappedOrderItems
  .reduce<{ activeItems: OrderItem[], cancelledItems: OrderItem[] }>(
  (arr, item) => {
    const {
      ActualVersion: { Status },
    } = item;

    if (Status === 4) {
      return {
        activeItems: arr.activeItems,
        cancelledItems: arr.cancelledItems,
      };
    }

    return {
      activeItems:
          Status !== 2
            ? [...arr.activeItems, item]
            : arr.activeItems,
      cancelledItems:
          Status === 2
            ? [...arr.cancelledItems, item]
            : arr.cancelledItems,
    };
  },
  { activeItems: [], cancelledItems: [] },
);

export interface ITripItemsByGroups {
  other: OrderItem[];
  taxiAndTaxiVouchers: OrderItem[];
}

const getTripItemsByGroups = (items: OrderItem[]): ITripItemsByGroups => {
  const newItems = items.reduce<{ other: OrderItem[], taxiVouchers: OrderItem[], taxi: OrderItem[] }>((arr, item) => {
    const { ActualVersion: { ServiceType } } = item;

    if (ServiceType === SERVICETYPE.TAXI_VOUCHER) {
      return {
        other: arr.other,
        taxiVouchers: [...arr.taxiVouchers, item],
        taxi: arr.taxi,
      };
    }

    if (ServiceType === SERVICETYPE.TAXI) {
      return {
        other: arr.other,
        taxiVouchers: arr.taxiVouchers,
        taxi: [...arr.taxi, item],
      };
    }

    return {
      other: [...arr.other, item],
      taxiVouchers: arr.taxiVouchers,
      taxi: arr.taxi,
    };
  }, { other: [], taxiVouchers: [], taxi: [] });

  return {
    other: newItems.other,
    taxiAndTaxiVouchers: [...newItems.taxi, ...newItems.taxiVouchers],
  };
};

const prepareTripDialogTitle = (item: TripVersion) => {
  const data = JSON.parse(item.JsonData);
  const nameEmployee = item.Employees.map(
    ({ Surname, Name, Patronymic }) =>
      `${Surname} ${Name} ${Patronymic}`,
  );

  switch (item.ServiceType) {
    case SERVICETYPE.AIR: {
      const name = data.Routes.map(
        (route: AirRoute) =>
          `${route.Segments[0].DepartureCity} – ${
            route.Segments[route.Segments.length - 1].ArrivalCity
          }`,
      ).join(', ');
      const ticketNumber =
        data.Tickets.join() || data.TicketsExtended[0].Num;
      const date = formatRangeDateWithSimplicity(
        item.CheckinDate,
        item.CheckoutDate,
        { withoutSpaceBetweenDates: true },
      );

      return `${LABELS.SERVICES.AIR} ${name}, ${date}. #${ticketNumber}, ${nameEmployee}`;
    }
    case SERVICETYPE.HOTEL: {
      const date = formatHotelRangeDate(
        data.CustomCheckInDate,
        data.CustomCheckOutDate,
        item.CheckinDate,
        item.CheckoutDate,
      );

      return `${data.Name}, ${data.Room.Category}, ${nameEmployee}, ${date}`;
    }
    case SERVICETYPE.TRANSFER: {
      const name = `${data.StartPlace.Address} – ${data.EndPlace.Address}`;
      const date = formatDate(data.DateArrival);

      return `${LABELS.SERVICES.TRANSFER} ${name}, ${nameEmployee}, ${date}`;
    }
    case SERVICETYPE.TRAIN: {
      const name = `${data.StationDepart} – ${data.StationArrive}`;
      const date = formatRangeDateWithSimplicity(
        item.CheckinDate,
        item.CheckoutDate,
      );

      return `${LABELS.SERVICES.TRAIN} ${name}, #${data.TicketNumber}, ${nameEmployee}, ${date}`;
    }
    case SERVICETYPE.CUSTOM: {
      const date = formatRangeDateWithSimplicity(
        item.CheckinDate,
        item.CheckoutDate,
      );

      return `${data.Name}, ${nameEmployee}, ${date}`;
    }
    case SERVICETYPE.AEROEXPRESS: {
      const {
        Trip: { Direction, TariffType },
      } = data;
      const date = formatRangeDateWithSimplicity(
        item.CheckinDate,
        item.CheckoutDate,
      );

      return `${LABELS.SERVICES.AEROEXPRESS} (${AEROEXPRESS_TARIFF_MAP[TariffType]}) ${Direction}, ${date}`;
    }
    case SERVICETYPE.TAXI_VOUCHER: {
      const date = formatRangeDateWithSimplicity(
        item.CheckinDate,
        item.CheckoutDate,
      );

      return `${LABELS.SERVICES.TAXI}, ${date}, ${nameEmployee}`;
    }
    default: {
      return '';
    }
  }
};

const getButtonCancelDialogQa = (isCancelTrain: boolean, isDoubleCancelTrain: boolean, isHotel: boolean, isAir: boolean, isTaxiVoucher: boolean, isTransfer: boolean, activeButton: boolean) => {
  if (activeButton && (isDoubleCancelTrain || isTaxiVoucher)) {
    return QA_ATTRIBUTES.trips.trip.cancelDialog.button.confirmReturn;
  }

  if (activeButton && ((isCancelTrain && isAir) || (isCancelTrain && isHotel) || isCancelTrain || isTransfer)) {
    return QA_ATTRIBUTES.trips.trip.cancelDialog.button.returnTicket;
  }

  if (activeButton) {
    return QA_ATTRIBUTES.trips.trip.cancelDialog.button.sendRequest;
  }

  return QA_ATTRIBUTES.trips.trip.cancelDialog.button.disabled;
};

const getMainButtonLabel = (
  isCancelTrain: boolean,
  isDoubleCancelTrain: boolean,
  isHotel: boolean,
  isAir: boolean,
  isTaxiVoucher: boolean,
  isTransfer: boolean,
): string => {
  if (isDoubleCancelTrain || isTransfer) {
    return LABELS.DOUBLE_CANCELLATION;
  }

  if (isTaxiVoucher) {
    return LABELS.TAXI_DOUBLE_CANCELLATION;
  }

  if (isCancelTrain && isHotel) {
    return LABELS.CANCEL_RESERVATION;
  }

  if ((isCancelTrain && isAir) || isCancelTrain) {
    return LABELS.AIR_CANCEL_RESERVATION;
  }

  return LABELS.SEND_REQUEST;
};

export {
  getTripItems,
  getTripItemsByGroups,
  prepareTripDialogTitle,
  getButtonCancelDialogQa,
  getMainButtonLabel,
};
