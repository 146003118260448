import React, { ReactNode, MouseEvent } from 'react';
import { StyledWrapper, BookMark } from 'new-ui';
import { BookMarkColor } from 'new-ui/src/components/BookMark/types';

import styles from './index.module.css';

interface StyledWrapperBookMarkProps {
  children: ReactNode;
  className?: string;
  onClick?(e: MouseEvent<HTMLDivElement>): void;
  bookmarkText?: string,
  bookmarkColor?: BookMarkColor,
  bookmarkClassName?: string,
  flatTop?: boolean;
  qaAttr?: string;
}

const StyledWrapperBookMark = ({
  children,
  className = '',
  onClick = () => {},
  bookmarkText = '',
  bookmarkClassName = '',
  bookmarkColor = 'default',
  flatTop = false,
  qaAttr = '',
}: StyledWrapperBookMarkProps) => {
  const renderBookMark = () => {
    if (!bookmarkText.length) return null;

    return (
      <div className={ styles['bookmark-wrapper'] }>
        <BookMark text={ bookmarkText } color={ bookmarkColor } className={ bookmarkClassName } />
      </div>
    );
  };

  return (
    <div className={ styles.wrap }>
      { renderBookMark() }
      <StyledWrapper
        className={ className }
        onClick={ onClick }
        flatTop={ flatTop }
        qaAttr={ qaAttr }
      >
        { children }
      </StyledWrapper>
    </div>
  );
};

export { StyledWrapperBookMark };
