import React from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../i18n';

import textAbbreviation from '../../bi/utils/textAbbreviation';

import styles from './styles/index.module.css';

interface CollapsibleTextProps {
  text: string,
  className?: string,
}

interface CollapsibleTextState {
  full: boolean,
}

const LABELS = {
  SHOW_IN_ALL: getText('components:collapsibleText.showMore'),
};

class CollapsibleText extends React.Component<CollapsibleTextProps, CollapsibleTextState> {
  static defaultProps = {
    className: '',
  };

  state = {
    full: false,
  };

  handleClick = () => this.setState({ full: true });

  renderFull = () => {
    const { text, className } = this.props;

    return (
      <Text
        type='NORMAL_16_140'
        className={ `${className} ${styles.text}` }
      >
        { text }
      </Text>
    );
  };

  renderPreview = () => {
    const { text, className } = this.props;

    const abbreviatedText = textAbbreviation(text, 270);

    const showMoreContent = text.length > 270 && (
      <div
        className={ styles.text }
        onClick={ this.handleClick }
      >
        <Text
          type='NORMAL_16_140'
          className={ styles['show-more'] }
        >
          { LABELS.SHOW_IN_ALL }
        </Text>
      </div>
    );

    return (
      <div className={ styles.preview }>
        <Text
          type='NORMAL_16_140'
          className={ `${styles.text} ${className}` }
        >
          { abbreviatedText}&nbsp;
        </Text>
        { showMoreContent }
      </div>
    );
  };

  render() {
    const { full } = this.state;

    const content = full ? this.renderFull() : this.renderPreview();

    return (
      <div className={ styles.wrapper }>
        { content }
      </div>
    );
  }
}

export default CollapsibleText;
