import React from 'react';
import { Icon, Skeleton, StyledWrapper, Text } from 'new-ui';

import { getText } from '../../../i18n';

import { IReservedPanel } from './types';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('smartdesk:balancePanel.title'),
  GO_TO: getText('smartdesk:balancePanel.goTo'),
  AVIA: getText('smartdesk:balancePanel.avia'),
  HOTEL: getText('smartdesk:balancePanel.hotel'),
  SOON: getText('smartdesk:balancePanel.soon'),
};

const ReservedPanel = ({
  note: {
    air,
  },
  isNote,
}: IReservedPanel) => {
  const renderContent = () => {
    if (!isNote) {
      return (
        <StyledWrapper className={ styles.skeleton }>
          <Skeleton className={ styles.skeleton }/>
        </StyledWrapper>
      );
    }

    return (
      <StyledWrapper className={ styles.panel }>
        <div className={ styles.header }>
          <Text type='bold_20'>
            { LABELS.TITLE }
          </Text>
        </div>
        <div className={ styles.table }>
          <div className={ styles.subsidiary_table }>
            <Icon
              className={ styles.icon }
              type='smartdeskAir'
              alternativeDesign
            />
            <div className={ styles.subsidiary }>
              <Text className={ styles.num } type='bold_24'>
                { String(air) }
              </Text>
              <Text type='NORMAL_16' className={ styles.str }>
                { LABELS.AVIA }
              </Text>
            </div>
          </div>
          <div>
            <div className={ `${styles.subsidiary_table} ${styles.left}` }>
              <Icon
                type='smartdeskHotel'
                alternativeDesign
                className={ styles.icon }
              />
              <div className={ styles.wrapper_hotel }>
                <div className={ styles.hotel_soon }/>
                <Text type='NORMAL_10' className={ styles.text }>
                  { LABELS.SOON }
                </Text>
              </div>
            </div>
          </div>
        </div>
      </StyledWrapper>
    );
  };

  return (
    <div className={ styles.wrapper }>
      { renderContent() }
    </div>
  );
};

export default ReservedPanel;
