import React from 'react';
import { Icon, Text } from 'new-ui';

import InfoDialog from './infoDialog';

import { getText } from '../../../../i18n';

import styles from '../styles/complex.module.css';

interface IComplex {
  show: boolean;
  warningShortTransferTime: boolean;
  onClick: () => void;
}

const LABELS = {
  complex: getText('components:airlineSearchItem.complex.complexRoute'),
};

const Complex: React.FC<IComplex> = ({ show, onClick, warningShortTransferTime }) => (
  <div
    className={ styles.wrapper }
    onClick={ onClick }
  >
    <Text type='bold_14' className={ styles.text }>
      { LABELS.complex }
    </Text>
    <Icon type='warning' />
    <InfoDialog
      show={ show }
      warningShortTransferTime={ warningShortTransferTime }
      onlyInfo
    />
  </div>
);

export { Complex };
