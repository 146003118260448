import { getText } from '../../../i18n';

const CART_STATUS = {
  AUTHORISED: 'Approved',
  REJECTED: 'Declined',
  IN_PROGRESS: 'WaitingApprove',
  NORMAL: 'Normal',
};

const CART_LIST_HEADERS = {
  AUTHORISED: getText('constants:cart.cartListHeaders.authorised'),
  REJECTED: getText('constants:cart.cartListHeaders.rejected'),
  IN_PROGRESS: getText('constants:cart.cartListHeaders.inProgress'),
};

const CART_APPROVE_TYPES = {
  DEFAULT: 'Default',
  OPTIONS: 'Options',
};

const SPECIFIC_CART_TYPES = {
  APPROVER: 'APPROVER',
  VIEWER: 'VIEWER',
};

const CART_ITEM_RENDER_OPTIONS = {
  hotelItem: {
    fullInfo: true,
  },
};

const CARTACTIONTYPES = {
  DEFAULT: 'DEFAULT',
  APPROVE: 'APPROVE',
  UNAVAILABLE: 'UNAVAILABLE',
  MANDATORYPROJECT: 'MANDATORYPROJECT',
  APPROVEMANDATORYPROJECT: 'APPROVEMANDATORYPROJECT',
  APPROVALSCHEME: 'APPROVALSCHEME',
  TRAVEL_APPROVAL_SCHEME: 'TRAVEL_APPROVAL_SCHEME',
  CHOOSE_VALID_USER: 'CHOOSE_VALID_USER',
  EMPTY_EMPLOYEE: 'EMPTY_EMPLOYEE',
};

const COMPANY_DEPARTMENT_DIVIDER = '--';

const VALID_SYMBLOL = {
  DOT: '.',
  COMMA: ',',
  ZERO: '0',
};

export {
  CART_STATUS,
  CART_LIST_HEADERS,
  CART_APPROVE_TYPES,
  SPECIFIC_CART_TYPES,
  CART_ITEM_RENDER_OPTIONS,
  COMPANY_DEPARTMENT_DIVIDER,
  CARTACTIONTYPES,
  VALID_SYMBLOL,
};
