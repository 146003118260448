import CONFIG from '../../../../config';

class Amplitude {
  static TYPE = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    LOGINFAIL: 'LOGIN_FAIL',
    AIRLINE: {
      SEARCH: 'AIRLINE_SEARCH',
      ADDTONOTEPAD: 'AIRLINE_ADD_TO_NOTEPAD',
      ADDTOCART: 'AIRLINE_ADD_TO_CART',
      BOOKINGINNOTEPAD: 'AIRLINE_BOOKING_IN_NOTEPAD',
      BUY: 'AIRLINE_BUY',
      BOOKINGERRORINNOTEPAD: 'AIRLINE_BOOKING_ERROR_IN_NOTEPAD',
      BOOKINGERROR: 'AIRLINE_BOOKING_ERROR',
    },
    HOTEL: {
      SEARCH: 'HOTEL_SEARCH_BY_REGION',
      SEARCHHOTEL: 'HOTEL_SEARCH',
      VISITHOTELPAGE: 'HOTEL_VISIT_HOTEL_PAGE',
      ADDTOCART: 'HOTEL_ADD_TO_CART',
      ADDTONOTEPAD: 'HOTEL_ADD_TO_NOTEPAD',
      BUY: 'HOTEL_BUY',
      BOOKINGERROR: 'HOTEL_BOOKING_ERROR',
    },
    TRAIN: {
      SEARCH: 'TRAIN_SEARCH',
      ADDTOCART: 'TRAIN_ADD_TO_CART',
      BUY: 'TRAIN_BUY',
      BOOKINGERROR: 'TRAIN_BOOKING_ERROR',
      ADDTONOTE: 'TRAIN_ADD_TO_NOTE',
    },
  };

  static setUser(companyName: string) {
    if (!companyName) return;

    if (CONFIG.ANALYTICS.AMPLITUDE.ENABLED && window.amplitude) {
      window.amplitude.getInstance().setUserId(companyName);
      window.amplitude.getInstance().regenerateDeviceId();
    }
  }

  static setUnauthUser() {
    if (CONFIG.ANALYTICS.AMPLITUDE.ENABLED && window.amplitude) {
      window.amplitude.getInstance().setUserId(null);
      window.amplitude.getInstance().regenerateDeviceId();
    }
  }

  static pushEvent(event = '', obj = {}) {
    if (!event) return;

    if (CONFIG.ANALYTICS.AMPLITUDE.ENABLED && window.amplitude) {
      window.amplitude.getInstance().logEvent(event, obj);
    }
  }

  static pushBookingErrorByTrip(trip: any) {
    switch (trip.ServiceType) {
      case 'Air': {
        Amplitude.pushEvent(Amplitude.TYPE.AIRLINE.BOOKINGERROR);
        break;
      }
      case 'Hotel': {
        Amplitude.pushEvent(Amplitude.TYPE.HOTEL.BOOKINGERROR);
        break;
      }
      case 'Train': {
        Amplitude.pushEvent(Amplitude.TYPE.TRAIN.BOOKINGERROR);
        break;
      }
    }
  }

  static pushBookingEventByTrip(trip: any, price: any) {
    switch (trip.ServiceType) {
      case 'Air': {
        Amplitude.pushEvent(Amplitude.TYPE.AIRLINE.BUY, price);
        break;
      }
      case 'Hotel': {
        Amplitude.pushEvent(Amplitude.TYPE.HOTEL.BUY, price);
        break;
      }
      case 'Train': {
        Amplitude.pushEvent(Amplitude.TYPE.TRAIN.BUY, price);
        break;
      }
    }
  }
}

export default Amplitude;
