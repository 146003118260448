import React from 'react';
import { getText } from '../../../i18n';

import HelpIcon from '../HelpIcon';

import COUNTRIES from '../../bi/constants/countries';

import styles from './styles/index.module.css';

type RefundHotelProps = {
  countryCode?: string,
  lowercaseText?: boolean,
};

const LABELS = {
  REFUND: getText('components:removableListItem.refund'),
  REFUND_FOREIGN: getText('components:removableListItem.refundForeign'),
  TOOLTIP: getText('components:removableListItem.tooltip'),
  TOOLTIP_FOREIGN: getText('components:removableListItem.tooltipForeign'),
};

const RefundHotel = ({ countryCode, lowercaseText = false }: RefundHotelProps) => {
  const textRefund = countryCode === COUNTRIES.RU.SHORTNAME ? LABELS.REFUND : LABELS.REFUND_FOREIGN;
  const textTooltip = countryCode === COUNTRIES.RU.SHORTNAME ? LABELS.TOOLTIP : LABELS.TOOLTIP_FOREIGN;

  return (
    <div className={ styles.refund }>
      <div className={ `${styles.text} ${lowercaseText ? styles.lowercase : ''}` }>{ textRefund }</div>
      <HelpIcon text={ textTooltip } />
    </div>
  );
};

export { RefundHotel };
