import React, { FC } from 'react';
import { Text, Icon } from 'new-ui';

import { getText } from '../../../../../i18n';

import { getFullAddress } from '../../../../bi/utils/taxi';

import { TaxiItemRoutesProps } from '../../../../bi/types/taxi';

import styles from './index.module.css';

const LABELS = {
  STOP: getText('taxi:route.stop'),
};

const TaxiItemRoutes: FC<TaxiItemRoutesProps> = ({
  item,
  index,
  length,
}) => {
  const addressHtml = getFullAddress(item);

  const renderIcon = () => (length > 1 && index !== length ? (
    <div className={ styles.arrow }>
      <Icon type='arrowsDown' />
    </div>
  ) : null);

  return (
    <div className={ styles.wrapper }>
      <Text type='SEMIBOLD_16' className={ styles.address }>{ addressHtml }</Text>
      <Text type='NORMAL_14' color='gray'>{ LABELS.STOP } { index }</Text>
      { renderIcon() }
    </div>
  );
};

export { TaxiItemRoutes };
