import React from 'react';
import { Text } from 'new-ui';

import styles from './styles/index.module.css';

export interface ILabelProps {
  label: string
  fullLabel: string
  anotherLabel: string
  secondLabel: string
  showFull?: boolean
  value: number
}

export const LabelSelect = ({
  label, fullLabel, anotherLabel, showFull = false,
}: Omit<ILabelProps, 'secondLabel'>) => (
  <Text type='SEMIBOLD_16'>
    { `${showFull ? fullLabel : label}${anotherLabel}` }
  </Text>
);

export const LabelItemSelect = ({ secondLabel, ...props }: ILabelProps) => (
  <>
    <LabelSelect { ...props } showFull />
    <Text className={ styles.hint } color='gray' type='NORMAL_12'>
      { secondLabel }
    </Text>
  </>
);
