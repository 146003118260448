import React from 'react';

import { getText } from '../../../../i18n';

import styles from './styles/index.module.css';

const LABELS = {
  COPIED: getText('components:linkAction.copied'),
};

const Notification = ({ show = false }) => {
  const classNameWrapper = [styles.wrapper];

  if (show) {
    classNameWrapper.push(styles.notification_activated);
  }

  return (
    <div className={ classNameWrapper.join(' ') }>
      <div className={ styles.content }>{LABELS.COPIED}</div>
    </div>
  );
};

export { Notification };
