import React from 'react';
import { Text, Icon } from 'new-ui';

import { getText } from '../../../i18n';

import {
  formatRangeDateWithSimplicity,
} from '../../bi/utils/formatDate';

import {
  AEROEXPRESS_LATE_TEXT,
  AEROEXPRESS_TARIFF_DESCRIPTIONS,
  AEROEXPRESS_TARIFF_MAP,
} from '../../bi/services/aeroexpress/const';

import { IItem } from '../../bi/types/aeroExpress';

import styles from './styles.module.css';

const LABELS = {
  TITLE: (tariff: string) => getText('components:aeroexpressItem.title', { tariff }),
};
interface IAeroexpressItemProps {
  item: IItem,
  fromTrip?: boolean,
  qaAttrDate?: string,
}
const AeroexpressItem = ({ item, fromTrip = false, qaAttrDate = '' }: IAeroexpressItemProps) => {
  const {
    Trip: { tariff_type: tariffType, departure_date, backward_date: backDate },
  } = item;

  const prepareTitle = () => LABELS.TITLE(AEROEXPRESS_TARIFF_MAP[tariffType]);

  const renderDescription = () => (
    AEROEXPRESS_TARIFF_DESCRIPTIONS[tariffType](departure_date, backDate)
      .split('\n')
      .map((value, index) => <Text key={ index } type='NORMAL_14_130'>{ value }</Text>)
  );

  if (fromTrip) {
    return (
      <div className={ styles['from-trip-wrapper'] }>
        <div style={ { minWidth: '55px' } }>
          <Icon type='itemAeroexpress' />
        </div>
        <div className={ styles.content }>
          <Text type='bold_18'>{ prepareTitle() }</Text>
          <Text type='NORMAL_14' color='gray' className={ styles.dates }>
            { formatRangeDateWithSimplicity(departure_date, backDate) }
          </Text>
          <div className={ styles.description }>
            { renderDescription() }
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={ styles.wrapper }>
      <Text type='bold_18'>{ prepareTitle() }</Text>
      <Text qaAttr={ qaAttrDate } type='NORMAL_14' color='gray' className={ styles.dates }>
        { formatRangeDateWithSimplicity(departure_date, backDate) }
      </Text>
      <div className={ styles.description }>
        { renderDescription() }
        <Text type='NORMAL_14'>{ AEROEXPRESS_LATE_TEXT }</Text>
      </div>
    </div>
  );
};

export default AeroexpressItem;
