import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Moment } from 'moment';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import EventsSA from '../EventsSA/EventsSA';
import InitialCalendarComponent from './Components/InitialCalendar';

import { momentObject, getStartMonth, getEndOfMonth } from '../../bi/utils/formatDate';

import { ICalendar, ICalendarSa } from '../../bi/types/eventsSA';

import styles from './styles/index.module.css';

const CalendarSa = observer(({
  isShow,
  smartdeskService,
  handleShowSmartdeskPanel,
}: ICalendarSa) => {
  const { smartdeskStore: { tripsInCalendar } } = useStores([MOBX_STORES.SMARTDESK]);

  const [calendar, setCalendar] = useState<ICalendar>({
    date: momentObject(new Date()),
    trips: null,
  });

  useEffect(() => {
    InitialCalendarComponent({ handleShowSmartdeskPanel, setCalendar, tripsInCalendar });
  }, [tripsInCalendar, setCalendar, handleShowSmartdeskPanel]);

  const getCalendarTrips = (value: Moment) => {
    const startDate = getStartMonth(value);
    const endDate = getEndOfMonth(value);

    smartdeskService.getTripsInCalendar({ startDate, endDate }).then(() => {
      InitialCalendarComponent({ tripsInCalendar, setCalendar, handleShowSmartdeskPanel });
    });
  };

  useEffect(() => {
    getCalendarTrips(calendar.date);
  }, [calendar.date]);

  return (
    <div className={ styles.wrap }>
      <div className={ styles.panel }>
        <EventsSA
          update={ setCalendar }
          calendar={ calendar }
          getCalendarTrips={ getCalendarTrips }
          isShow={ isShow }
        />
      </div>
    </div>
  );
});

export default CalendarSa;
