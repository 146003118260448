const AIRLINECLASSES = {
  econom: 0,
  business: 1,
  first: 2,
};

const WINGSTYPES = {
  none: 'none',
  start: 'start',
  end: 'end',
  regular: 'regular',
};

const EXITTYPES = {
  none: 'none',
  front: 'front',
  back: 'back',
};

const PROPERTIES = {
  notReclined: 'notRecline',
  availability: 'availability',
  extraSpace: 'extraSpace',
  exit: 'exit',
};

export { AIRLINECLASSES, WINGSTYPES, EXITTYPES, PROPERTIES };
