import { getText } from '../../../i18n';

import PASSPORTS from './passport';

const LABELS = {
  ADD_FOREIGN_PASSPORT: getText('settings:employees.employee.addForeignPassport'),
  FOREIGN_PASSPORT_FULL: getText('settings:employees.employee.steps.documents.names.foreignPassport'),
};

const VALUES_CHECKBOX = {
  SELECT: 'select',
  DATE_TIME: 'date',
  FLIGHT_DIRECTION: 'direction',
  PASSPORT: 'passport',
  OTHER: 'other',
};

const FIELDS_CHANGE = {
  COMPLEX: 'complex',
  FLIP: 'flip',
  DATE: 'date',
  DATE_BACK: 'date.back',
  SUGGEST_SELECT: 'suggest.select',
};

const PATH = {
  MAIN: '/trip/:tripId/changeAirTrip/:ticketId',
  DATE: '/trip/:tripId/changeAirTrip/:ticketId/date',
  DATE_RESULT: '/trip/:tripId/changeAirTripResult/:ticketId/date/:guid',
  DIRECTION: '/trip/:tripId/changeAirTrip/:ticketId/direction',
  DIRECTION_RESULT: '/trip/:tripId/changeAirTripResult/:ticketId/direction/:guid',
  PASSPORT: '/trip/:tripId/changeAirTrip/:ticketId/passport/:personId',
  OTHER: '/trip/:tripId/changeAirTrip/:ticketId/other',
};

const PASSPORTS_ADDITIONAL_CHANGE = {
  DOMESTIC_PASSPORT: {
    ...PASSPORTS.DOMESTIC_PASSPORT,
    AUXILIARY_TYPE: PASSPORTS.DOMESTIC_PASSPORT.TYPE,
    INDEX: 1,
    NUMBER: '',
  },
  BIRTH_CERTIFICATE: {
    ...PASSPORTS.BIRTH_CERTIFICATE,
    AUXILIARY_TYPE: PASSPORTS.BIRTH_CERTIFICATE.TYPE,
    INDEX: 1,
    NUMBER: '',
  },
  FOREIGN_PASSPORT: {
    ...PASSPORTS.FOREIGN_PASSPORT,
    AUXILIARY_TYPE: PASSPORTS.FOREIGN_PASSPORT.TYPE,
    VALUE: LABELS.FOREIGN_PASSPORT_FULL,
    INDEX: 1,
    NUMBER: '',
  },
  ADD_NEW_FIRST_FOREIGN_PASSPORT: {
    TYPE: PASSPORTS.FOREIGN_PASSPORT.TYPE,
    AUXILIARY_TYPE: 'AddNewFirstForeignPassport',
    VALUE: LABELS.ADD_FOREIGN_PASSPORT,
    INDEX: 1,
    NUMBER: '',
  },
  ADD_NEW_SECOND_FOREIGN_PASSPORT: {
    TYPE: PASSPORTS.FOREIGN_PASSPORT.TYPE,
    AUXILIARY_TYPE: 'AddNewSecondForeignPassport',
    VALUE: LABELS.ADD_FOREIGN_PASSPORT,
    INDEX: 2,
    NUMBER: '',
  },
  SECOND_FOREIGN_PASSPORT: {
    ...PASSPORTS.FOREIGN_PASSPORT,
    AUXILIARY_TYPE: 'SecondForeignPassport',
    VALUE: LABELS.FOREIGN_PASSPORT_FULL,
    INDEX: 2,
    NUMBER: '',
  },
};

export {
  VALUES_CHECKBOX,
  FIELDS_CHANGE,
  PATH,
  PASSPORTS_ADDITIONAL_CHANGE,
};
