import { ReactNode } from 'react';

const truncateString = (str: string, maxLength: number) =>
  (str.length > maxLength ? `${str.slice(0, maxLength)}...` : str);

const reductionEmp = (value: string) => (value ? `${value}.` : '');

const reductionDot = (value: boolean) => (value ? ', ' : '');

const reductionProbel = (value: boolean, element: ReactNode) => (value ? element : '');

export {
  truncateString,
  reductionEmp,
  reductionDot,
  reductionProbel,
};
