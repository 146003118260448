import { action, computed, makeObservable, observable } from 'mobx';

import mapSavedTicketToTrainItemModel from '../utils/mapSavedTicketToTrainItemModel';

import { ISavedTicket, SavedTicket } from './types';

export interface ITrainSavedTicketStore {
  savedTicket: ISavedTicket | null,
  isChoosingBackTrain: boolean,
  mappedSavedTicket: SavedTicket | null,
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable savedTicket: ISavedTicket | null = null;
  @observable isChoosingBackTrain: boolean = false;

  @computed
  get mappedSavedTicket() {
    return this.savedTicket
      ? mapSavedTicketToTrainItemModel(this.savedTicket)
      : null;
  }

  @action
  setIsChoosingBackTrain = (value: boolean) => {
    this.isChoosingBackTrain = value;
  };

  @action
  setSavedTicket = (value: null | ISavedTicket) => {
    this.savedTicket = value;
  };

  @action
  clearStore = () => {
    this.savedTicket = null;
    this.isChoosingBackTrain = false;
  };
}

const TrainSavedTicketStore = new Store();

export { TrainSavedTicketStore };
