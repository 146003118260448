import React from 'react';

import { getText } from '../../../../../i18n';

import Seat from '../Seat';

import type { AirlineClassType, PropertyType } from '../../types/types';

import styles from './styles.module.css';

interface LegendItemType {
  properties?: PropertyType[];
  description?: string;
  airlineClass?: AirlineClassType;
  selected?: boolean;
  active?: boolean;
}

const DESCRIPTIONS = {
  standard: getText('components:airlineSeatMap.legend.standard'),
  recline: getText('components:airlineSeatMap.legend.recline'),
  extra: getText('components:airlineSeatMap.legend.extra'),
  unavailable: getText('components:airlineSeatMap.legend.unavailable'),
  selected: getText('components:airlineSeatMap.legend.selected'),
  active: getText('components:airlineSeatMap.legend.active'),
};

const businessLegend: LegendItemType[] = [
  { airlineClass: 1, properties: ['availability'], description: DESCRIPTIONS.standard },
  { airlineClass: 1, description: DESCRIPTIONS.unavailable },
  { airlineClass: 1, properties: ['availability'], description: DESCRIPTIONS.selected, selected: true },
];

const economLegend: LegendItemType[] = [
  { properties: ['availability'], description: DESCRIPTIONS.standard },
  { properties: ['availability', 'notRecline'], description: DESCRIPTIONS.recline },
  { properties: ['availability', 'extraSpace'], description: DESCRIPTIONS.extra },
  { description: DESCRIPTIONS.unavailable },
  { properties: ['availability'], description: DESCRIPTIONS.selected, selected: true },
];

const getLegendItemsByAirlineClass = (airlineClass: AirlineClassType): LegendItemType[] => {
  if (airlineClass === 0) {
    return economLegend;
  }

  if (airlineClass === 1) {
    return businessLegend;
  }

  return [];
};

const renderSeat = ({
  airlineClass = 0,
  properties = [],
  description = '',
  selected = false,
}: LegendItemType, index: number) => (
  <div key={ index } className={ styles.place_wrap }>
    <Seat
      example
      selected={ selected }
      airlineClass={ airlineClass }
      properties={ properties }
    />
    <div className={ styles.description }>{ description }</div>
  </div>
);

interface LegendProps {
  airlineClass?: AirlineClassType,
  hiddenProperties?: PropertyType[]
}

const Legend = ({ airlineClass = 0, hiddenProperties = [] }: LegendProps) => {
  const legendItems = getLegendItemsByAirlineClass(airlineClass);
  const preparedLegendItems = legendItems
    .filter(({ properties = [] }) => !properties.some(property => hiddenProperties.includes(property)))
    .map(renderSeat);

  return (
    <div className={ styles.wrap }>
      { preparedLegendItems }
    </div>
  );
};

export default Legend;
