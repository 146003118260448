import { observable, action, computed, makeObservable } from 'mobx';

import type { CommentType } from './types';

export interface ITripCommentsStore {
  comments: CommentType[],
  loading: boolean,
  setComments: (list: CommentType[]) => void,
  setComment: (data: CommentType) => void,
  setLoading: (value: boolean) => void,
  isHistory?: boolean,
  disabled?: boolean
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable comments: CommentType[] = [];
  @observable loading = false;

  @action
  setComments = (list: CommentType[]): void => {
    this.comments = list;
  };

  @action
  setComment = (data: CommentType): void => {
    this.comments.unshift(data);
  };

  @action
  setLoading = (value: boolean): void => {
    this.loading = value;
  };

  @computed
  get isHistory(): boolean {
    return !!this.comments?.length;
  }
}

const TripCommentsStore = new Store();

export { TripCommentsStore };
