import React from 'react';
import { Text } from 'new-ui';

import styles from '../styles/trainCancelled.module.css';

const SorryService = () => (
  <div>
    <Text type='NORMAL_18'>
      15 апреля с 11:00 до 12:30 по МСК будет обновление сервиса тревел-помощника, из-за чего некоторые функции чата будут недоступны.
    </Text>
    <Text type='NORMAL_18' className={ styles.text }>
      Мы остаёмся на связи по телефону: 8 800 775 14 28 или по почте help@smartway.today
    </Text>
  </div>
);

export { SorryService };
