import React from 'react';
import { Button, Dialog, DotLoading, Text } from 'new-ui';

import { getText } from '../../../i18n';

import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import styles from './styles/index.module.css';

interface RateDialogProps {
  show: boolean,
  onClose: () => void,
  onBack: () => void,
}

interface RateDialogState {
  loading: boolean,
}

const LABELS = {
  TITLE: getText('components:rateDialog.title'),
  INFO: {
    FIRST: getText('components:rateDialog.info.first'),
    SECOND: getText('components:rateDialog.info.second'),
    THIRD: getText('components:rateDialog.info.third'),
    FOURTH: getText('components:rateDialog.info.fourth'),
  },
  BOOKING: getText('components:rateDialog.booking'),
  EDIT: getText('components:rateDialog.edit'),
  CANCEL: getText('components:rateDialog.cancel'),
};

class RateDialog extends React.Component<RateDialogProps, RateDialogState> {
  state: RateDialogState = {
    loading: false,
  };

  renderLoading = () => (
    <div className={ styles.loader }>
      <DotLoading />
    </div>
  );

  renderInfo = () => {
    const { onBack, onClose } = this.props;

    return (
      <div className={ styles.wrapper }>
        <Text
          type='bold_20'
          className={ styles.header }
          qaAttr={ QA_ATTRIBUTES.cart.hotel.noReturnTitle }
        >
          {LABELS.TITLE}
        </Text>
        <Text type='NORMAL_16_140'>
          {LABELS.INFO.FIRST}
        </Text>
        <Text type='NORMAL_16_140'>
          {LABELS.INFO.SECOND}
        </Text>
        <Text type='NORMAL_16_140'>
          {LABELS.INFO.THIRD}
        </Text>
        <Text type='NORMAL_16_140'>
          {LABELS.INFO.FOURTH}
        </Text>
        <Text className={ styles.title }>
          {LABELS.BOOKING}
        </Text>
        <div className={ styles.action }>
          <Button
            type='primary'
            onClick={ onBack }
            qaAttr={ QA_ATTRIBUTES.cart.hotel.noReturnCorrect }
          >
            { LABELS.EDIT }
          </Button>
          <Button
            onClick={ onClose }
            type='textual-medium'
            className={ styles.cancel }
            qaAttr={ QA_ATTRIBUTES.cart.hotel.noReturnRight }
          >
            { LABELS.CANCEL }
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { show, onClose } = this.props;
    const html = this.state.loading ? this.renderLoading() : this.renderInfo();

    return (
      <Dialog show={ show } showClosing onChange={ onClose } outsideClickClosing={ false }>
        { html }
      </Dialog>
    );
  }
}

export default RateDialog;
