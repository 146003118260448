// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';

import { Text, Tooltip, PROPS, Price } from 'new-ui';

import { isSmartAgent } from '../../bi/utils/env';

import SYMBOLS from '../../bi/constants/symbols';

import FareDetails from '../FareDetails';

import styles from './styles/index.module.css';

const {
  TEXT: {
    TYPES: {
      NORMAL_14,
      NORMAL_14_130,
    },
    COLORS: {
      WHITE,
    },
  },
  TOOLTIP: {
    POSITION: {
      BOTTOM,
    },
  },
} = PROPS;

const FareItem = ({
  fare,
  currentFareId,
  currentPrice,
  onFareClick,
  customRefundabilityAndExchangeability,
  isChangeAirTrip,
  agentMode,
}) => {
  const { Id, Name, Price: { TotalPrice, AgentFee } } = fare;
  const currentFare = currentFareId === Id;
  const wrapperStyles = [styles.wrapper];
  const labelStyles = [styles.label];

  if (currentFare) {
    wrapperStyles.push(styles.current);
  }

  if (isChangeAirTrip) {
    labelStyles.push(styles.label_change_air_trip);
  }

  const color = currentFare ? PROPS.TEXT.COLORS.DEFAULT : PROPS.TEXT.COLORS.ACCENT;

  const price = currentFare ? currentPrice : TotalPrice - currentPrice;
  const curAgentFee = !agentMode ? AgentFee : 0;
  const curPriceSA = currentFare ? currentPrice + curAgentFee : TotalPrice + curAgentFee;

  const preparedPrice = isSmartAgent ? curPriceSA : price;

  const symbolHtml = !currentFare && !isSmartAgent && TotalPrice - currentPrice > 0 && (
    <Text type={ NORMAL_14 } color={ color }>{SYMBOLS.PLUS.value}&thinsp;</Text>
  );

  const tooltip = () => (
    <Text
      type={ NORMAL_14_130 }
      color={ WHITE }
      className={ styles.tooltip }
    >
      <FareDetails
        customRefundabilityAndExchangeability={ customRefundabilityAndExchangeability }
        fare={ fare }
      />
    </Text>
  );

  const renderPrice = () => (!isChangeAirTrip && (
    <div className={ styles.price }>
      { symbolHtml }
      <Price
        marginSmall
        type={ NORMAL_14 }
        typeCurrency={ NORMAL_14 }
        color={ color }
        value={ preparedPrice }
      />
    </div>
  ));

  return (
    <Tooltip
      position={ BOTTOM }
      renderContent={ tooltip }
      className={ wrapperStyles.join(' ') }
      childrenClassName={ styles.children_tooltip }
    >
      <div className={ styles.content } onClick={ () => onFareClick(fare) }>
        <div className={ styles.fare }>
          <Text type={ NORMAL_14 } className={ labelStyles.join(' ') }>{ Name }</Text>
          { renderPrice() }
        </div>
      </div>
    </Tooltip>
  );
};

FareItem.propTypes = {
  fare: PropTypes.object.isRequired,
  currentFareId: PropTypes.string,
  currentPrice: PropTypes.number.isRequired,
  onFareClick: PropTypes.func.isRequired,
  customRefundabilityAndExchangeability: PropTypes.bool,
  isChangeAirTrip: PropTypes.bool,
};

FareItem.defaultProps = {
  currentFareId: null,
  warning: false,
  customRefundabilityAndExchangeability: false,
  isChangeAirTrip: false,
};

export default FareItem;
