import React from 'react';
import { Link } from 'react-router-dom';

import { LinkButton, Text, IconButton } from 'new-ui';

import styles from './styles/index.module.css';

import ROUTES from '../../bi/constants/routes';

type RemovableListItemType = {
  isUnique?: boolean,
  value: any,
  className?: string,
  renderLabel(value: any): React.ReactNode,
  onClick?: () => void,
  onChangeStatus(data: any): void,
  employeeId?: number | null,
};

const RemovableListItem = ({
  isUnique,
  value,
  className = '',
  renderLabel,
  onClick,
  onChangeStatus,
  employeeId = null,
}: RemovableListItemType) => {
  const isRemoved = value?.isRemoved;

  const renderAction = () => {
    const iconType = isRemoved
      ? 'arrowsRevert'
      : 'closeButton';

    return (
      <IconButton
        className={ styles.btn }
        iconColor='black'
        iconType={ iconType }
        onClick={ () => onChangeStatus({ ...value, isRemoved: !isRemoved }) }
      />
    );
  };

  const wrapperClassNames = [styles.wrapper];

  const textClassNames = [styles.text];

  if (className) {
    wrapperClassNames.push(className);
  }

  if (isRemoved) {
    textClassNames.push(styles.removed);
  }

  const textColor = isRemoved ? 'gray' : 'accent';

  const renderList = () => {
    if (employeeId) {
      return (
        <Link
          to={ { pathname: `${ROUTES.EMPLOYEE}${employeeId}`, search: `newPage=${true}` } }
          target='_blank'
          className={ styles.linkList }
        >
          { renderLabel(value) }
        </Link>
      );
    }

    return (
      <Text
        className={ textClassNames.join(' ') }
        color={ textColor }
      >
        { renderLabel(value) }
      </Text>
    );
  };

  const renderActions = !isUnique ? renderAction() : null;

  return (
    <div className={ wrapperClassNames.join(' ') }>
      { renderActions }
      <LinkButton
        theme='blue-without-border'
        onClick={ onClick }
      >
        { renderList() }
      </LinkButton>
    </div>
  );
};

export { RemovableListItem };
