import React from 'react';
import { observer } from 'mobx-react';
import { Dialog, Text } from 'new-ui';

import { useServices } from '../../../../../bi/context/services';

import { LABELS } from '../../../consts';

import styles from './styles.module.css';

export const ErrorMessage = observer(() => {
  const {
    departmentsService: { store: { closeDialog, dialogMeta } },
  } = useServices(['Departments']);

  return (
    <Dialog
      show
      showClosing
      onChange={ closeDialog }
      className={ styles.dialog }
    >
      <div className={ styles.content }>
        <Text type='bold_20'>
          { LABELS.ERROR }
        </Text>
        { dialogMeta }
      </div>
    </Dialog>
  );
});
