import React from 'react';
import { Icon, Text, Tooltip } from 'new-ui';
import { getText } from '../../../i18n';

import MoneyFormat from '../../bi/utils/money';

import styles from './styles/index.module.css';

interface ITaxiDetailsPriceProps {
  tariffAmount: number,
  vatAmount: number,
  showDetails?: boolean,
  showAvailable?: boolean,
}

const LABELS = {
  TARIFF: (amount: string) => getText('taxi:voucher.tariff', { amount }),
  VAT: (vat: string) => getText('taxi:voucher.rubVat', { vat }),
  DETAILS_INFO: getText('taxi:voucher.detailsInfo'),
  TOOLTIP_ONE: getText('taxi:voucher.tooltip.one'),
  TOOLTIP_TWO: getText('taxi:voucher.tooltip.two'),
  AVAILABLE: getText('taxi:voucher.available'),
};

const TaxiDetailsPrice = ({
  tariffAmount,
  vatAmount,
  showDetails = false,
  showAvailable = false,
}: ITaxiDetailsPriceProps) => {
  const renderTooltip = () => (
    <Tooltip renderContent={ () => (
      <div className={ styles['tooltip-wrapper'] }>
        <Text type='NORMAL_14_130' color='white' className={ styles.row }>{ LABELS.TOOLTIP_ONE }</Text>
        <Text type='NORMAL_14_130' color='white' className={ styles.row }>{ LABELS.TOOLTIP_TWO }</Text>
      </div>
    ) }
    >
      <Icon type='question' />
    </Tooltip>
  );

  const preparedTariffAmount = MoneyFormat.money(tariffAmount);
  const preparedVatAmount = MoneyFormat.money(vatAmount);

  const availableStyle = [styles.text];
  const textMarginStyle = [styles.text, styles.textMargin];

  if (showAvailable) {
    availableStyle.push(styles.available);
  }

  const renderAvailableText = showAvailable ?
    (
      <Text type='NORMAL_12' color='gray' className={ availableStyle.join(' ') }>
        { LABELS.AVAILABLE }
      </Text>
    ) : '';

  const renderDetailsText = showDetails ?
    (
      <Text type='NORMAL_12' color='gray' className={ textMarginStyle.join(' ') }>
        { LABELS.DETAILS_INFO }
      </Text>
    ) : '';

  return (
    <div className={ styles.wrapper }>
      { renderAvailableText }
      <Text type='NORMAL_12' color='gray' className={ textMarginStyle.join(' ') }>
        { LABELS.TARIFF(preparedTariffAmount) }
      </Text>
      <Text type='NORMAL_12' color='gray' className={ styles.text }>
        { LABELS.VAT(preparedVatAmount) }  &nbsp;
        { renderTooltip() }
      </Text>
      { renderDetailsText }
    </div>
  );
};

export default TaxiDetailsPrice;
