import React from 'react';
import { Text } from 'new-ui';

import styles from '../styles/smartdesk.module.css';

const WarningSmartdesk = () => (
  <div className={ styles.wrapper }>
    <Text>
      К сожалению, сейчас вы не сможете до нас дозвониться по техническим причинам.
    </Text>
    <Text className={ styles.text }>
      Мы уже решаем эту проблему, восстановим работу телефонии в самое ближайшее время.
    </Text>
    <Text className={ styles.text }>
      Извините, пожалуйста, за это неудобство.
    </Text>
    <Text className={ styles.text }>
      Вы можете написать вопрос в чат travel-помощнику.
    </Text>
  </div>
);

export { WarningSmartdesk };
