import React, { FC } from 'react';
import { Text, Tooltip } from 'new-ui';

import type { TextType, TextColor } from 'new-ui';

import { getText } from '../../../i18n';

import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { IEmployee } from '../../page/ApproveExpenseReports/components/expenseReportItem';

import styles from './styles.module.css';

const LABELS = {
  SENT: getText('cart:approveStatuses.sent'),
  SEND_OFFLINE: getText('cart:approveStatuses.sendOffline'),
  REPORT_WAS_CREATED: getText('expenseReports:report.reportWasCreated'),
  APPROVE_REQUEST_FROM: getText('cart:approveStatuses.approveRequestFrom'),
};

interface AddresserInfoItemProps {
  employee?: IEmployee
  date: string;
  email?: string;
  name?: string;
  label?: string;
  typeText?: TextType;
  color?: TextColor;
  isOffline?: boolean;
}

const getEmployeeName = (firstName?: string, lastName?: string) => `${firstName} ${lastName}`.trim();

const renderEmployeeInfo = (
  employee?: IEmployee,
  typeText?: TextType,
  color?: TextColor,
) => {
  if (!employee) {
    return null;
  }

  const { Email, Surname, Name } = employee;

  return (
    <Text color={ color } className={ styles['approve-list-item'] } type={ typeText }>
      { LABELS.REPORT_WAS_CREATED }&nbsp;
      <Tooltip
        renderContent={ () => (
          <div className={ styles['tooltip-content'] }>
            <Text
              color='white'
              type='NORMAL_14_130'
              qaAttr={ QA_ATTRIBUTES.approve.cart.sender.email }
            >
              { Email }
            </Text>
          </div>
        ) }
      >
        <Text
          type={ typeText }
          color={ color }
          className={ styles.name }
          qaAttr={ QA_ATTRIBUTES.approve.cart.sender.name }
        >
          { getEmployeeName(Surname, Name) }
        </Text>
      </Tooltip>
    </Text>
  );
};

const AddresserInfoItem: FC<AddresserInfoItemProps> = ({
  employee,
  email,
  name,
  date,
  label = LABELS.APPROVE_REQUEST_FROM,
  typeText = 'NORMAL_14',
  color = 'default',
  isOffline = false,
}) => {
  const sendLabel = isOffline ?
    LABELS.SEND_OFFLINE :
    LABELS.SENT;

  return (
    <>
      <Text color={ color } className={ styles['approve-list-item'] } type={ typeText }>
        { label }&nbsp;
        <Tooltip renderContent={ () => (
          <div className={ styles['tooltip-content'] }>
            <Text color='white' type='NORMAL_14_130' qaAttr={ QA_ATTRIBUTES.approve.cart.sender.email }>{ email }</Text>
          </div>
        ) }
        >
          <Text
            type={ typeText }
            color={ color }
            className={ styles.name }
            qaAttr={ QA_ATTRIBUTES.approve.cart.sender.name }
          >
            { name }
          </Text>
        </Tooltip>
      </Text>
      { renderEmployeeInfo(employee, typeText, color) }
      <div className={ styles.sent }><Text type={ typeText } color={ color }>{ sendLabel } { date }.</Text></div>
    </>
  );
};

export { AddresserInfoItem };
