const AMOUNT = 2;
const CHARACTER = '0';

const lpad = (n: number, m = AMOUNT, c = CHARACTER): string => {
  let strN = `${n}`;
  let newM = m - strN.length;

  while (newM-- > 0) strN = c + strN;

  return strN;
};

const rpad = (n: number, m = AMOUNT, c = CHARACTER): string => {
  let strN = `${n}`;
  let newM = m - strN.length;

  while (newM-- > 0) strN += c;

  return strN;
};

export {
  rpad,
  lpad,
};
