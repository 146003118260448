import React, { useState } from 'react';
import { Button, RadioButton, Text, Tooltip } from 'new-ui';

import { getText } from '../../../../../i18n';

import { defineOrderDateForTrips, defineWarnLabel } from '../../../../bi/utils/insurance';

import ANIMATION from '../../../../bi/constants/animation';

import { EnumRateName, IItemInTrip, IOrderedInsurance, IDates, IListItem } from '../../../../bi/types/insurance';

import styles from '../../styles/index.module.css';

const LABELS = {
  RATE_TITLE: (name: EnumRateName) => getText(`components:insurance.rate.${name}.title`),
  DESCRIPTION: (name: EnumRateName) => getText(`components:insurance.rate.${name}.desc`),
  PRICE_SYMBOL: getText('components:insurance.price'),
  ADDED_TO_CART: getText('common:addedToCart'),
  ADD_TO_CART: getText('common:addToCart'),
  NOT_VALID_MSG: getText('components:insurance.notValid'),
  NOT_EMPLOYEES: getText('components:insurance.notEmployees'),
  NOT_TRANSFER_VALIDATION: getText('components:insurance.validationTransfer'),
  ALL_ITEMS_IN_TRIP: getText('components:insurance.allItemsInTrip'),
  NOT_ALLOW_TO_CHOOSE_ITEM: getText('components:insurance.notShowedInsurance'),
  ITEMS_DOES_NOT_EXIST: getText('components:insurance.itemsDoesNotExist'),
  CHOOSE_ITEMS: getText('components:insurance.chooseItems'),
};

interface InsuranceRateProps {
  rateName: EnumRateName,
  price: number,
  onChangeRate: React.Dispatch<React.SetStateAction<EnumRateName.BASE | EnumRateName.OPTIMAL | EnumRateName.BUSINESS>>,
  selectedRate: EnumRateName,
  selectedItems: number[] | IItemInTrip[],
  isValidDate: boolean,
  listItems: IListItem[],
  onAddRate: (value: EnumRateName) => void,
  addedRate: EnumRateName,
  isEmptyEmployees: boolean,
  orderDate: IDates;
  isCart: boolean,
  tripId: undefined | number,
  cartItems: number[],
  sendInsuranceToCartService: (insuranceOrdered: IOrderedInsurance, Id: number | null) => void,
  setAddedInsurance: (ids: number[]) => void,
  validationTransferFields: boolean,
  priceBookedInsurance: number,
  onUpdateNormalCarts: () => void,
}

type ButtonType = 'secondary' | 'primary';

interface ICartButton {
  label: string,
  disabled: boolean,
  type: ButtonType,
}

const InsuranceRate = ({
  addedRate,
  cartItems = [],
  isCart,
  isEmptyEmployees,
  isValidDate,
  listItems = [],
  onAddRate,
  onChangeRate,
  onUpdateNormalCarts,
  orderDate,
  price,
  priceBookedInsurance,
  rateName,
  selectedItems,
  selectedRate,
  sendInsuranceToCartService,
  setAddedInsurance,
  tripId,
  validationTransferFields,
}: InsuranceRateProps) => {
  const [animatedClass, setAnimatedClass] = useState('');

  const isActiveRate = selectedRate === rateName;
  const isAddedToCart = addedRate === rateName;

  let finalPrice = isAddedToCart ? priceBookedInsurance : price;
  finalPrice ??= price;

  const warnLbelText = defineWarnLabel(
    isCart,
    cartItems,
    addedRate,
    isEmptyEmployees,
    validationTransferFields,
    isValidDate,
    isActiveRate,
    listItems,
    selectedItems,
    LABELS);

  const cartButtonOpts: ICartButton = {
    label: isAddedToCart ? LABELS.ADDED_TO_CART : LABELS.ADD_TO_CART,
    type: isActiveRate ? 'secondary' : 'primary',
    disabled: !!warnLbelText,
  };

  const rateStyles = [styles.rate, animatedClass];

  if (!isValidDate) {
    rateStyles.push(styles.disabled);
  }

  if (addedRate) {
    rateStyles.push(styles.added);
  }

  if (isActiveRate) {
    rateStyles.push(styles.active);
  }

  const handleChangeRate = (value: EnumRateName.BASE | EnumRateName.OPTIMAL | EnumRateName.BUSINESS) => {
    if (addedRate) return;

    onChangeRate(value);
  };

  const handleButtonClick = async () => {
    if (!isActiveRate || isAddedToCart) return;

    if (!isCart) setAnimatedClass(ANIMATION.MOVETOCART);

    setAddedInsurance(cartItems);

    const orderDateForTrips = defineOrderDateForTrips(listItems, selectedItems as IItemInTrip[]);

    const items = isCart ? cartItems : selectedItems;
    const dates = isCart ? orderDate : orderDateForTrips;

    const insuranceOrdered: IOrderedInsurance = {
      rate: rateName,
      orderDate: dates as IDates,
      price,
      items,
      isCart,
    };

    const id = tripId || null;

    onAddRate(rateName);

    await sendInsuranceToCartService(insuranceOrdered, id);

    const intervalId = setInterval(() => {
      setAnimatedClass(() => (''));
      clearInterval(intervalId);
    }, 1500);

    await onUpdateNormalCarts();
  };

  const renderRadioChildren = () => (
    <>
      <Text
        type='SEMIBOLD_16'
      >
        { LABELS.RATE_TITLE(rateName) }
      </Text>
      <Text
        type='NORMAL_14_130'
      >
        {`${finalPrice} ${LABELS.PRICE_SYMBOL}`}
      </Text>
    </>
  );

  const renderWarningText = () => (
    <div className={ styles.tooltip }>
      <Text color='white' type='NORMAL_14_130'>{ warnLbelText }</Text>
    </div>
  );

  return (
    <div className={ rateStyles.join(' ') }>
      <RadioButton
        className={ styles.rate_top }
        contentClassName={ styles.rate_box }
        value={ rateName }
        checked={ isActiveRate }
        onChange={ handleChangeRate }
      >
        { renderRadioChildren() }
      </RadioButton>
      <ul className={ styles.rate_description_list }>
        <li>
          <Text
            className={ styles.rate_description }
            type='NORMAL_14_130'
          >
            { LABELS.DESCRIPTION(rateName) }
          </Text>
        </li>
      </ul>
      <Tooltip
        show={ !!warnLbelText }
        position='top'
        renderContent={ renderWarningText }
      >
        <Button
          className={ styles.add_cart_btn }
          onClick={ handleButtonClick }
          disabled={ cartButtonOpts.disabled }
          type={ cartButtonOpts.type }
        >
          { cartButtonOpts.label }
        </Button>
      </Tooltip>
    </div>
  );
};

export { InsuranceRate };
