// @ts-nocheck
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { Select, DatepickerDialog, Text, PROPS, useRegisterOutsideClick } from 'new-ui';
import { getText } from '../../../i18n';

import { formatDate, defaultPattern, getMoment } from '../../bi/utils/formatDate';
import { isSmartAgent } from '../../bi/utils/env';

import styles from './styles/index.module.css';

const LABELS = {
  ANY_DATE: getText('components:combinedDatepicker.anyDate'),
  SELECT_NUMBER: getText('components:combinedDatepicker.selectNumber'),
  SELECT_PERIOD: getText('components:combinedDatepicker.selectPeriod'),
};

const {
  DATEPICKER: {
    TYPES: {
      DATE_RANGE,
      DATE,
    },
  },
  SELECT: {
    THEMES: {
      DEFAULT_LARGE,
    },
  },
  TEXT: {
    COLORS: {
      GRAY,
    },
  },
} = PROPS;

const options = [
  { label: LABELS.ANY_DATE, value: 'none' },
  { label: LABELS.SELECT_NUMBER, value: 'single' },
  { label: LABELS.SELECT_PERIOD, value: 'range' },
];

const TYPES = {
  none: 'none',
  single: 'single',
  range: 'range',
};

const CombinedDatepicker = ({ date, optionalDate, onChange, notChooseOptions, className }) => {
  const [type, setType] = useState(TYPES.none);
  const [calendarMode, setCalendarMode] = useState(PROPS.DATEPICKER.MODE.DATE);

  const selectRefs = [React.createRef(), React.createRef()];
  const datepickerRef = useRef(null);

  const hideCalendar = () => {
    if (type !== TYPES.none) {
      setType(TYPES.none);
    }
  };

  useRegisterOutsideClick([datepickerRef, selectRefs[0], selectRefs[1]], hideCalendar);

  const onHandleChange = (date1, date2 = null, shouldUpdate = true) => {
    if (shouldUpdate) onChange(date1, date2);

    if (type !== TYPES.range) {
      hideCalendar();
    }
  };

  const onDateTypeChange = (calendarType) => {
    if (calendarType === TYPES.none) {
      onChange(null, null);
    }

    setType(calendarType);
  };

  const renderCalendar = () => {
    const isRangeDatepicker = type === TYPES.range;
    const datePickerType = isRangeDatepicker ? DATE_RANGE : DATE;
    const min = getMoment().add(-3, 'y');
    const max = getMoment().add(1, 'y');

    return (
      <div ref={ datepickerRef }>
        <DatepickerDialog
          min={ min }
          max={ max }
          type={ datePickerType }
          value={ date }
          secondValue={ optionalDate }
          setMode={ (mode, currentDate, secondDate) => {
            setCalendarMode(PROPS.DATEPICKER.MODE.DATE);

            if (date) {
              onHandleChange(currentDate, secondDate, false);
            }
          } }
          onChangeSwitcher={ ({ mode }) => {
            setCalendarMode(mode);
          } }
          mode={ calendarMode }
          onChange={ onHandleChange }
          datePickerParent
          alternativeDesign={ isSmartAgent }
        />
      </div>
    );
  };

  const getLabel = item => {
    const { label } = item;

    if (date && optionalDate) {
      return `${formatDate(date, defaultPattern)} — ${formatDate(optionalDate, defaultPattern)}`;
    }

    if (date) {
      return formatDate(date, defaultPattern);
    }

    return label;
  };

  const renderLabel = item => <Text color={ GRAY }>{ getLabel(item) }</Text>;

  const selectHtml = notChooseOptions ? (
    <Select
      theme={ DEFAULT_LARGE }
      items={ [{ label: LABELS.SELECT_PERIOD, value: 'range' }] }
      value={ TYPES.range }
      renderLabel={ renderLabel }
      onChange={ onDateTypeChange }
      className={ className }
      ref={ selectRefs[0] }
    />
  ) : (
    <Select
      theme={ DEFAULT_LARGE }
      items={ options }
      value={ type }
      renderLabel={ renderLabel }
      onChange={ onDateTypeChange }
      className={ className }
      ref={ selectRefs[1] }
    />
  );

  const calendarHtml = type !== TYPES.none && renderCalendar();

  return (
    <div
      className={ styles.wrapper }
    >
      { selectHtml }
      { calendarHtml }
    </div>
  );
};

CombinedDatepicker.propTypes = {
  date: PropTypes.object,
  optionalDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  notChooseOptions: PropTypes.bool,
  className: PropTypes.string,
};

CombinedDatepicker.defaultProps = {
  date: null,
  optionalDate: null,
  notChooseOptions: false,
};

export { CombinedDatepicker };
