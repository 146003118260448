import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Button, IconButton, StyledWrapper, Suggest } from 'new-ui';

import clsx from 'clsx';
import { useStores } from '../../../../../bi/context';
import { MOBX_STORES } from '../../../../../bi/context/stores';
import { getText } from '../../../../../../i18n';

import { MainAnalytic } from '../../../../../bi/utils/analytics';
import { isSmartAgent } from '../../../../../bi/utils/env';

import { TRANSFER_SEARCH_FIELDS } from '../../../../../bi/constants/transfers';
import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';

import { TransferMenuProps, SuggestItems } from '../../../../../bi/types/transfer';

import styles from './styles.module.css';

const LABELS = {
  SEARCH: getText('components:menu.transfer.search'),
  FROM: getText('components:menu.transfer.from'),
  TO: getText('components:menu.transfer.to'),
};

const TransferMainMenu: FC<TransferMenuProps> = observer(({
  onSearch,
  swapPlaces,
  updateSuggestions,
  selectedSuggest,
  renderSuggestion,
  getShowElementForSmartagent,
}) => {
  const {
    transferSearchStore: {
      transferFrom,
      transferTo,
    },
  } = useStores([MOBX_STORES.TRANSFER_SEARCH_STORE]);

  const handleChangeCityAmp = <Params extends any[], Fn extends (...args: Params) => void>(
    func: Fn, ...value: Params
  ) => {
    func(...value);

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_TRANSFER_CITY_CHOSEN(value[1].Address),
    );
  };

  const suggestValue = {
    from: transferFrom.value || '',
    to: transferTo.value || '',
  };

  const isActive = !!transferFrom.selected && !!transferTo.selected;

  const wrapperSearch = clsx(
    styles.wrapper,
    isSmartAgent && getShowElementForSmartagent && styles.search_sa,
  );

  return (
    <StyledWrapper className={ wrapperSearch }>
      <div className={ styles.menu }>
        <div className={ styles.top }>
          <div className={ styles.suggest_wrap }>
            <div>
              <Suggest
                className={ styles.suggest }
                theme='light'
                placeholder={ LABELS.FROM }
                value={ suggestValue.from }
                items={ transferFrom.suggestions }
                onSelect={ (suggest: any) => {
                  handleChangeCityAmp(selectedSuggest, TRANSFER_SEARCH_FIELDS.TRANSFER_FROM, suggest);
                } }
                onChange={ query => updateSuggestions(TRANSFER_SEARCH_FIELDS.TRANSFER_FROM, query) }
                renderItem={ renderSuggestion }
                keyExtractor={ ({ Id }: SuggestItems) => Id }
                qaAttr={ QA_ATTRIBUTES.transfer.search.from.input }
                qaAttrFirstEl={ QA_ATTRIBUTES.transfer.search.from.suggest }
              />
            </div>
          </div>
          <IconButton tabIndex={ -1 } iconType='arrowsDoubleGray' onClick={ swapPlaces }/>
          <div className={ styles.suggest_wrap }>
            <div>
              <Suggest
                className={ styles.suggest }
                theme='light'
                placeholder={ LABELS.TO }
                value={ suggestValue.to }
                items={ transferTo.suggestions }
                onSelect={ (suggest: any) => {
                  handleChangeCityAmp(selectedSuggest, TRANSFER_SEARCH_FIELDS.TRANSFER_TO, suggest);
                } }
                onChange={ query => updateSuggestions(TRANSFER_SEARCH_FIELDS.TRANSFER_TO, query) }
                renderItem={ renderSuggestion }
                keyExtractor={ ({ Id }: SuggestItems) => Id }
                qaAttr={ QA_ATTRIBUTES.transfer.search.to.input }
                qaAttrFirstEl={ QA_ATTRIBUTES.transfer.search.to.suggest }
              />
            </div>
          </div>
          <Button
            className={ styles.button }
            type='large'
            onClick={ onSearch }
            disabled={ !isActive }
            qaAttr={ QA_ATTRIBUTES.transfer.search.button }
          >
            { LABELS.SEARCH }
          </Button>
        </div>
      </div>
    </StyledWrapper>
  );
});

export { TransferMainMenu };
