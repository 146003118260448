import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, IconType, Price as PriceComponent, Sticker, Text, Tooltip } from 'new-ui';
import { TypeSticker } from 'new-ui/src/components/Sticker/types';

import { getText } from '../../../i18n';

import { StatusMarker } from '../StatusMarker';

import ROUTES from '../../bi/constants/routes';
import { FLIGHT_CERTIFICATE, TRIPSTATUS } from '../../bi/constants/trips';

import { defaultPatternWithTime, formatDate, formatRangeDateWithSimplicity } from '../../bi/utils/formatDate';
import { getIconType } from '../../bi/utils/trip';
import { isSmartAgent } from '../../bi/utils/env';

import { EnumTripInsuredType, TripData } from '../../bi/types/trips';

import styles from './styles/trip.module.css';

interface ITripProps {
  value: TripData,
  isDispayInsurance: boolean,
  isWhiteLabel: boolean,
  agentMode: boolean,
  showEventSticker?: boolean,
  onDownload?(e: any):void,
  canPayWithPersonalFunds?: boolean,
}

const LABELS = {
  EDITED: getText('components:trip.edited'),
  ADD_FEE: (price: number) => getText('cart:addFee:trip:trip', { price }),
  DOWNLOAD_VOUCHERS: getText('components:trip.downloadVouchers'),
};

const Trip = ({
  value: {
    Name,
    ChangeDate,
    CheckInDate,
    CheckOutDate,
    AdditionalFee,
    Price,
    Services,
    Status,
    EmployeesFull,
    MICEEventId,
    Labels,
    Id,
    InsuranceStatus = '',
  },
  showEventSticker = false,
  isDispayInsurance,
  canPayWithPersonalFunds = false,
  isWhiteLabel,
  agentMode,
  onDownload = () => {},
}: ITripProps) => {
  const servicesType = isSmartAgent ? Services.filter((item) => item !== FLIGHT_CERTIFICATE) : Services;

  const isRenderPrice = !!Price && Status !== TRIPSTATUS.AWAITINGTICKETING;
  const isShowStickerInsurance = isDispayInsurance && InsuranceStatus && !isWhiteLabel;

  const servicesHtml = (service: string, key: number) => {
    const iconType: IconType | null = getIconType(service);

    if (!iconType) {
      return null;
    }

    return (
      <div className={ styles.icon } key={ `${service}_${key}` }>
        <Icon type={ iconType } className={ styles.service } alternativeDesign={ isSmartAgent }/>
      </div>
    );
  };

  const employeesFull = () => {
    if (!EmployeesFull.length) {
      return null;
    }

    return EmployeesFull.map(({ FullName, EmployeeId }) => (
      <Text
        key={ EmployeeId }
        type='NORMAL_14'
        className={ styles.employee }
      >
        {FullName}&nbsp;
      </Text>
    ));
  };

  const renderPrice = () => {
    if (!isRenderPrice) {
      return null;
    }

    return (
      <PriceComponent
        marginSmall
        type='SEMIBOLD_18'
        value={ Price }
      />
    );
  };

  const renderAdditionalFee = () => {
    if (!AdditionalFee || !isRenderPrice || !isSmartAgent || !agentMode) {
      return null;
    }

    return (
      <div
        className={ styles.add_wrapper }
      >
        <Text
          color='gray'
          type='NORMAL_12'
        >
          { LABELS.ADD_FEE(AdditionalFee) }
        </Text>
      </div>
    );
  };

  const renderStickers = () => {
    // моканные данные для объяснения создания последующих стикеров
    // когда добавится второй стикер - удалить
    const isPaySticker = Labels && Labels.includes('CardPayment') && canPayWithPersonalFunds;

    const typeInsur: EnumTripInsuredType =
      EnumTripInsuredType[InsuranceStatus as keyof typeof EnumTripInsuredType] ?? EnumTripInsuredType.NotInsured;

    const isFirstSticker = isPaySticker || !showEventSticker;
    const renderSticker = (value: string | boolean | null, type: TypeSticker, first = true) => value && (
      <div
        className={ styles.sticker }
      >
        <Sticker
          firstSticker={ first }
          type={ type }
        />
      </div>
    );

    return (
      <div
        className={ styles.wrapper_sticker }
      >
        { renderSticker(isPaySticker, 'pay') }
        { renderSticker(MICEEventId && showEventSticker, 'event', !isPaySticker) }
        { renderSticker(isShowStickerInsurance, typeInsur, isFirstSticker)}
        {/* у последующего будет третьим пропсом в firstSticker будет !isPaySticker && !MICEEventId */}
      </div>
    );
  };

  const renderTooltipContent = (
    <Text
      type='NORMAL_14_130'
      color='white'
      className={ styles.content }
    >
      { LABELS.DOWNLOAD_VOUCHERS }
    </Text>
  );

  const renderDownloadVouchers = (Status === TRIPSTATUS.BOOKED || Status === TRIPSTATUS.COMPLETED) ? (
    <div className={ styles.download_icon }>
      <Tooltip
        bodyClassName={ styles.tooltip }
        renderContent={ () => renderTooltipContent }
        position='bottom'
      >
        <Icon
          onClick={ (e) => onDownload(e) }
          type='downloadIcon'
          alternativeDesign={ isSmartAgent }
        />
      </Tooltip>
    </div>
  ) : <div className={ styles.download_empty } />;

  const downloadVouchersForm = isSmartAgent && renderDownloadVouchers;

  const renderContent = (
    <>
      { renderStickers() }
      <div className={ styles.wrapper }>
        <div className={ `${styles.column} ${styles.three}` }>
          <Text
            type='bold_18'
          >
            { Name }
          </Text>
          <div className={ styles.employees }>
            { employeesFull() }
          </div>
          <Text
            type='NORMAL_14'
            className={ styles['change-date'] }
          >
            { LABELS.EDITED }
            { formatDate(ChangeDate, defaultPatternWithTime) }
          </Text>
        </div>
        <div
          className={ `${styles.column} ${styles.icons} ${styles.three}` }
        >
          { servicesType.map(servicesHtml) }
        </div>
        <div className={ `${styles.column} ${styles.three}` }>
          <Text type='NORMAL_18'>
            { formatRangeDateWithSimplicity(CheckInDate, CheckOutDate) }
          </Text>
        </div>
        <div className={ `${styles.column} ${styles.two}` }>
          { renderPrice() }
          { renderAdditionalFee() }
        </div>
        <div className={ `${styles.column} ${styles.three}` }>
          <StatusMarker status={ Status } rightIcon/>
        </div>
        { downloadVouchersForm }
      </div>
    </>
  );

  return isSmartAgent ? renderContent : (
    <NavLink
      to={ `${ROUTES.TRIP.MAIN}/${Id}` }
    >
      { renderContent }
    </NavLink>
  );
};

export default Trip;
