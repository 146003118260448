import { observable, action, computed, makeObservable } from 'mobx';

import { UNIQUE_FORMED_TP } from '../../constants/travelPolicy';

import { TravelPolicyItem } from '../userSession/types';

import { TEXTS_FOR_UNIQUE_TRAVEL_POLICY } from './consts';

import { IUniqueTravelPolicy, IDefaultStore } from './types';
import { ITravelPolicyListItem } from '../../types/travelPolicy';

const DEFAULT_STORE: IDefaultStore = {
  name: '',
  description: '',
  id: null,
  loading: false,
  editTP: false,
};

const DEFAULT_SUGGESTIONS = {
  loading: false,
  results: [],
};

const DEFAULT_UNIQUE = {
  loading: false,
  wasNoQuestionnaire: false,
  formedTP: UNIQUE_FORMED_TP.NEW,
  headerText: {
    header: TEXTS_FOR_UNIQUE_TRAVEL_POLICY.HEADER,
    subTextOne: TEXTS_FOR_UNIQUE_TRAVEL_POLICY.SUB_TEXT_ONE,
    subTextTwo: TEXTS_FOR_UNIQUE_TRAVEL_POLICY.SUB_TEXT_TWO,
  },
  travelPolicyModel: null,
};

const DEFAULT_EMPLOYEES = {
  selected: [],
  list: [],
};

type CommonTravelPolicyServerModel = {
  Name: string,
  Description: string,
  Id: string,
};

export interface ITravelPolicyStore {
  store: {
    name: string,
    description: string,
    id: null | any,
    loading: boolean,
    editTP: boolean
    list: {
      value: unknown,
    },
  },
  validationResult: any[],
  suggestions: { loading: boolean, results: any[] },
  employeeTravelPolicy: TravelPolicyItem | null,
  uniqueTravelPolicy: IUniqueTravelPolicy,
  employees: {
    selected: string[],
    list: string[],
  },
  list: ITravelPolicyListItem[],
  isSelectAll: boolean,
  loading: boolean;
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable store = DEFAULT_STORE;
  @observable validationResult = [];
  @observable suggestions = DEFAULT_SUGGESTIONS;
  @observable employeeTravelPolicy: TravelPolicyItem | null = null;
  @observable uniqueTravelPolicy = DEFAULT_UNIQUE;
  @observable employees = DEFAULT_EMPLOYEES;
  @observable isSelectAll = false;

  @computed
  get preparedToSaveModel() {
    const { name, description, id } = this.store;

    return {
      Name: name,
      Description: description,
      Id: id,
    };
  }

  @computed
  get isValid() {
    return !!this.store.name;
  }

  @action
  mapServerModelToClient({ Name, Description, Id }: CommonTravelPolicyServerModel) {
    this.store = {
      name: Name,
      description: Description,
      id: Id,
      loading: false,
      editTP: true,
    };
  }

  @action
  updateStore(payload: any) {
    this.store = {
      ...this.store,
      ...payload,
    };
  }

  @action
  clearStore = () => {
    this.store = DEFAULT_STORE;
  };

  @action
  setSuggestionsLoading = (value: boolean) => {
    this.suggestions = {
      ...this.suggestions,
      loading: value,
    };
  };

  @action
  setSuggestions = (suggestions: any[]) => {
    const preparedSuggestions = suggestions.reduce((r, it) =>
      (it.ChildLocation ? ([...r, it, ...it.ChildLocation]) : ([...r, it])), []);

    this.suggestions = {
      loading: false,
      results: preparedSuggestions,
    };
  };

  @action
  setEmployeeTravelPolicy = (value: TravelPolicyItem | null) => {
    this.employeeTravelPolicy = value;
  };

  @action
  setUniqueTravelPolicyLoading = (value: boolean) => {
    this.uniqueTravelPolicy = {
      ...this.uniqueTravelPolicy,
      loading: value,
    };
  };

  @action
  setNotQuestionnaire = (value: boolean) => {
    this.uniqueTravelPolicy = {
      ...this.uniqueTravelPolicy,
      wasNoQuestionnaire: value,
    };
  };

  @action
  setEmployeeList = (list: any[] | any) => {
    this.employees = {
      ...this.employees,
      list: list.map(({ Surname, Name, Patronymic, Id }:
      { Surname: string, Name: string, Patronymic: string, Id: number }) => ({
        label: `${Surname} ${Name} ${Patronymic || ''} `,
        value: Id,
      })),
    };
  };

  @action
  setEmployeeSelected = (values: string[] | any) => {
    this.employees = {
      ...this.employees,
      selected: values,
    };
  };

  @action
  setSelectAll = (value: boolean) => {
    this.isSelectAll = value;
  };

  @action
  setTravelPolicyModel = (values: any) => {
    this.uniqueTravelPolicy = {
      ...this.uniqueTravelPolicy,
      travelPolicyModel: { ...values },
    };
  };

  @action
  setFormedTP = (value: string) => {
    this.uniqueTravelPolicy = {
      ...this.uniqueTravelPolicy,
      formedTP: value,
    };
  };

  @action
  setResetTravelPolicyUnique = () => {
    this.uniqueTravelPolicy = DEFAULT_UNIQUE;
    this.employees = DEFAULT_EMPLOYEES;
    this.isSelectAll = false;
  };
}

const TravelPolicyStore = new Store();

export { TravelPolicyStore };
