import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Datepicker, MultiSelect, Text } from 'new-ui';
import { useRouteMatch } from 'react-router-dom';
import { Moment } from 'moment';
import clsx from 'clsx';

import { getText } from '../../../../../i18n';

import { CommonChartsAnalyticsType } from '../../../../bi/services/chartsAnalytics/types';

import { defaultPattern, formatDate } from '../../../../bi/utils/formatDate';
import { isSmartAgent } from '../../../../bi/utils/env';

import { PATTERN } from '../../../../bi/constants/dateFormats';

import styles from './styles.module.css';

const LABELS = {
  HEADER: getText('chartsAnalytics:analytic'),
};

interface InfoSmartAgentParams {
  startDate: string;
  endDate: string;
  companyIds: number[];
}

interface ChartAnalyticsDatesProps {
  onSelectCompanies(values: any): void,
  onChangeStartDate(value: string | Moment | null): void,
  onChangeEndDate(value: string | Moment | null): void,
  common: CommonChartsAnalyticsType,
  getInfo?(tabId: string): void,
  getInfoSmartagent?(value: InfoSmartAgentParams): void,
  isHeader?: boolean,
}

interface MatchParams {
  tabId: string
}

const ChartAnalyticsDates = observer(({
  onSelectCompanies,
  onChangeStartDate,
  onChangeEndDate,
  common: {
    startDate,
    endDate,
    minDate,
    maxDate,
    selectedCompanies,
    companies,
  },
  getInfo = () => {},
  getInfoSmartagent = () => {},
  isHeader = true,
}: ChartAnalyticsDatesProps) => {
  const { params: { tabId } } = useRouteMatch<MatchParams>();

  useEffect(() => {
    if (isSmartAgent && !isHeader && selectedCompanies.length && formatDate(endDate, PATTERN.YEARMONTHDAY)) {
      getInfoSmartagent({
        startDate: formatDate(startDate, PATTERN.YEARMONTHDAY),
        endDate: formatDate(endDate, PATTERN.YEARMONTHDAY),
        companyIds: selectedCompanies,
      });
    }
  }, [startDate, endDate, selectedCompanies]);

  const getInformation = () => !isSmartAgent && getInfo(tabId);

  const handleChangeSelectedCompanies = async (values: []) => {
    if (!values.length) return;

    onSelectCompanies(values);
    getInformation();
  };

  const handleChangeStartDate = async (value: string | Moment | null) => {
    onChangeStartDate(value);
    getInformation();
  };

  const handleChangeEndDate = async (value: string | Moment | null) => {
    onChangeEndDate(value);
    getInformation();
  };

  const companies_wrapper = (style: string) => clsx(style, {
    [styles.companies_sa]: isSmartAgent,
  });

  const renderHeaderCompanies = () => {
    if (companies.length === 1) {
      return (
        <div className={ companies_wrapper(styles.company) }>
          <Text type='NORMAL_16' className={ styles.company_text }>
            { companies[0].label }
          </Text>
        </div>
      );
    }

    return (
      <div className={ companies_wrapper(styles.companies) }>
        <MultiSelect
          className={ styles.multi_select_border }
          itemClassName={ styles.multi_select_item }
          list={ companies }
          values={ selectedCompanies }
          onChange={ handleChangeSelectedCompanies }
        />
      </div>
    );
  };

  const renderHeader = () => {
    if (!isHeader) {
      return null;
    }

    return (
      <Text type='bold_32' className={ styles.header_text }>
        { LABELS.HEADER }
      </Text>
    );
  };

  return (
    <div className={ styles.wrapper_header }>
      { renderHeader() }
      <div className={ styles.filters }>
        <div className={ styles.dates }>
          <Datepicker
            isHiddenInput={ !isHeader }
            type='date'
            inputTheme='open'
            inputClassName={ styles.input_wrap }
            value={ startDate }
            min={ minDate }
            max={ maxDate }
            format={ defaultPattern }
            onChange={ value => handleChangeStartDate(value) }
            isDuration
            durationDates={ [startDate, endDate] }
          />
          &mdash;
          <Datepicker
            isHiddenInput={ !isHeader }
            type='date'
            inputTheme='open'
            direction='to'
            inputClassName={ styles.input_wrap }
            value={ endDate }
            min={ startDate }
            max={ maxDate }
            format={ defaultPattern }
            onChange={ value => handleChangeEndDate(value) }
            isDuration
            durationDates={ [startDate, endDate] }
          />
        </div>
        { renderHeaderCompanies() }
      </div>
    </div>
  );
});

export { ChartAnalyticsDates };
