import { observable, action, makeObservable } from 'mobx';
import { ReactNode } from 'react';

export interface ISidePanelStore {
  show: boolean,
  renderFn(): ReactNode
}
class Store {
  constructor() {
    makeObservable(this);
  }

  @observable show = false;
  @observable renderFn: () => void = () => {};

  @action
  setShow(value: boolean): void {
    this.show = value;
  }

  @action
  setRenderFn(fn: () => void): void {
    this.renderFn = fn;
  }
}

const SidePanelStore = new Store();

export { SidePanelStore };
