import React from 'react';
import { Moment } from 'moment';
import { Text } from 'new-ui';

import { formatDate } from '../../bi/utils/formatDate';

import styles from './styles/index.module.css';

interface IEventItem {
  item: {
    Name: string,
    CheckinDate: string | Moment | null,
    CheckoutDate: string | Moment | null,
  }
}

const EventItem = ({
  item: {
    Name,
    CheckinDate,
    CheckoutDate,
  },
}: IEventItem) => {
  const dates = `${formatDate(CheckinDate)} - ${formatDate(CheckoutDate)}`;

  return (
    <div className={ styles.wrapper }>
      <Text
        type='bold_18'
        className={ styles.title }
      >
        { Name }
      </Text>
      <Text
        type='NORMAL_14'
        color='gray'
      >
        { dates }
      </Text>
    </div>
  );
};

export { EventItem };
