import React from 'react';

import { getText } from '../../../../../i18n';

import Property from '../Property';

import moneyFormat from '../../../../bi/utils/money';

import type { SeatType, PropertyType } from '../../types/types';

import styles from './styles.module.css';

const LABELS = {
  place: getText('components:airlineSeatMap.tooltip.place'),
  price: getText('components:airlineSeatMap.tooltip.price'),
  closeAlt: getText('components:airlineSeatMap.tooltip.closeAlt'),
  imageAlt: getText('components:airlineSeatMap.tooltip.imageAlt'),
};

const excludingPropertiesList: PropertyType[] = ['infant', 'child'];

interface TooltipProps {
  seat: SeatType,
}

const Tooltip = ({
  seat: {
    Column,
    Row,
    Price,
    Image,
    Properties = [],
  },
}: TooltipProps) => {
  const price: string = moneyFormat.money(Price, true);
  const properties: PropertyType[] = Properties.filter(prop => !excludingPropertiesList.includes(prop));
  const excludingProperties: PropertyType[] = excludingPropertiesList.filter(prop => !Properties.includes(prop));
  const propertiesJsx = [...properties, ...excludingProperties]
    .map((prop, i) => (
      <div key={ `${prop}_${i}` } className={ styles.property }>
        <Property property={ prop } />
      </div>
    ));

  const image = Image && (
    <div className={ styles.image }>
      <img className={ styles.img } src={ Image } alt={ LABELS.imageAlt } />
    </div>
  );

  return (
    <div className={ styles.wrap }>
      <div className={ styles.place }>
        {LABELS.place} {Row}{Column}
      </div>
      <div className={ styles.price }>
        {LABELS.price}: {price}
      </div>
      { image }
      <div className={ styles.properties }>
        { propertiesJsx }
      </div>
    </div>
  );
};

export default Tooltip;
