import React, { useRef } from 'react';
import { Input, Button, Text, Dialog } from 'new-ui';
import { getText } from '../../../../../i18n';

import styles from './styles.module.css';

const LABELS = {
  TITLE: getText('components:analyticsSelect.dialog.title'),
  PLACEHOLDER: '',
  SAVE: getText('common:add'),
  CANCEL: getText('common:undo'),
};

type AnalyticsSelectDialogProps = {
  onAdd(value: string): void,
  visible?: boolean,
  onChangeVisible(value: boolean): void,
  loading?: boolean,
  initialValue?: string,
  setInitialValue(value: string): void,
  qaAttrTitle?: string,
  qaAttrInput?: string,
  qaAttrButtonSave?: string,
  qaAttrButtonCancel?:string,
};

const AnalyticsSelectDialog = ({
  onAdd,
  visible = false,
  onChangeVisible,
  loading = false,
  initialValue = '',
  setInitialValue,
  qaAttrTitle = '',
  qaAttrInput = '',
  qaAttrButtonSave = '',
  qaAttrButtonCancel = '',
}: AnalyticsSelectDialogProps) => {
  const inputRef = useRef(null);

  const handleAdd = (e = { preventDefault: () => {} }) => {
    e.preventDefault();

    onAdd(initialValue);
    setInitialValue('');
  };

  if (!!inputRef?.current && visible) {
    // @ts-ignore
    inputRef?.current?.focus();
  }

  return (
    <Dialog
      showClosing
      show={ visible }
      onChange={ onChangeVisible }
    >
      <form
        onSubmit={ handleAdd }
        className={ styles.dialog }
      >
        <Text
          className={ styles.header }
          type='bold_20'
          qaAttr={ qaAttrTitle }
        >
          { LABELS.TITLE }
        </Text>
        <Input
          value={ initialValue }
          placeholder={ LABELS.PLACEHOLDER }
          onChange={ setInitialValue }
          ref={ inputRef }
          qaAttr={ qaAttrInput }
        />
        <div className={ styles.actions }>
          <Button
            loading={ loading }
            disabled={ !initialValue }
            type='primary'
            onClick={ handleAdd }
            qaAttr={ qaAttrButtonSave }
          >
            { LABELS.SAVE }
          </Button>
          <Button
            className={ styles.close }
            type='textual-medium'
            disabled={ loading }
            onClick={ () => onChangeVisible(false) }
            qaAttr={ qaAttrButtonCancel }
          >
            { LABELS.CANCEL }
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default AnalyticsSelectDialog;
