import { getText } from '../../../i18n';

const CARCLASS_LABELS = {
  STANDART: getText('constants:transfer.standart'),
  TOLL_ROAD_STANDART: getText('constants:transfer.tollRoadStandart'),
  COMFORT: getText('constants:transfer.comfort'),
  TOLL_ROAD_COMFORT: getText('constants:transfer.tollRoadComfort'),
  BUSINESS: getText('constants:transfer.business'),
  TOLL_ROAD_BUSINESS: getText('constants:transfer.tollRoadBusiness'),
  BUSINESS_LIGHT: getText('constants:transfer.businessLite'),
  MINIVAN: getText('constants:transfer.minivan'),
  MINIVAN_VIP: getText('constants:transfer.minivanVip'),
  MINIBUS: getText('constants:transfer.minibus'),
  LARGE_MINIBUS: getText('constants:transfer.largeMinibus'),
  BUS: getText('constants:transfer.bus'),
  EXECUTIVE: getText('constants:transfer.executive'),
};

const TRANSFERFIELDS = {
  DateArrival: 'DateArrival',
  DateDeparture: 'DateDeparture',
  ArrivalNumber: 'ArrivalNumber',
  TimeDeparture: 'TimeDeparture',
  TimeArrival: 'TimeArrival',
  DepartureNumber: 'DepartureNumber',
  LocationAddress: 'LocationAddress',
  DestinationAddress: 'DestinationAddress',
  TableSignature: 'TableSignature',
  StartTerminals: 'StartTerminals',
  EndTerminals: 'EndTerminals',
  DepartureTerminal: 'DepartureTerminal',
  ArrivalTerminal: 'ArrivalTerminal',
  Notes: 'Notes',
  CarriageNumber: 'CarriageNumber',
  Viewers: 'Viewers',
};

const TRANSFERFILTERCLASS = {
  Standart: 'Standart',
  TollRoadStandart: 'TollRoadStandart',
  Comfort: 'Comfort',
  TollRoadComfort: 'TollRoadComfort',
  Business: 'Business',
  TollRoadBusiness: 'TollRoadBusiness',
  BusinessLite: 'BusinessLite',
  Minivan: 'Minivan',
  MinivanVip: 'MinivanVip',
  Minibus: 'MiniBus',
  LargeMiniBus: 'LargeMiniBus',
  Bus: 'Bus',
  Executive: 'Executive',
};

const MAPCARCLASS_RU = {
  [TRANSFERFILTERCLASS.Standart]: CARCLASS_LABELS.STANDART,
  [TRANSFERFILTERCLASS.TollRoadStandart]: CARCLASS_LABELS.TOLL_ROAD_STANDART,
  [TRANSFERFILTERCLASS.Comfort]: CARCLASS_LABELS.COMFORT,
  [TRANSFERFILTERCLASS.TollRoadComfort]: CARCLASS_LABELS.TOLL_ROAD_COMFORT,
  [TRANSFERFILTERCLASS.Business]: CARCLASS_LABELS.BUSINESS,
  [TRANSFERFILTERCLASS.TollRoadBusiness]: CARCLASS_LABELS.TOLL_ROAD_BUSINESS,
  [TRANSFERFILTERCLASS.BusinessLite]: CARCLASS_LABELS.BUSINESS_LIGHT,
  [TRANSFERFILTERCLASS.Minivan]: CARCLASS_LABELS.MINIVAN,
  [TRANSFERFILTERCLASS.MinivanVip]: CARCLASS_LABELS.MINIVAN_VIP,
  [TRANSFERFILTERCLASS.Minibus]: CARCLASS_LABELS.MINIBUS,
  [TRANSFERFILTERCLASS.LargeMiniBus]: CARCLASS_LABELS.LARGE_MINIBUS,
  [TRANSFERFILTERCLASS.Bus]: CARCLASS_LABELS.BUS,
  [TRANSFERFILTERCLASS.Executive]: CARCLASS_LABELS.EXECUTIVE,
};

const MAPCARCLASS = {
  Standart: 'standard',
  TollRoadStandart: 'standard',
  Comfort: 'comfort',
  TollRoadComfort: 'comfort',
  Business: 'business',
  TollRoadBusiness: 'business',
  BusinessLite: 'businessLite',
  Minivan: 'minivan',
  MinivanVip: 'minivanVip',
  MiniBus: 'minibus',
  LargeMiniBus: 'minibus',
  Executive: 'representative',
};

const FIELDSLABELS = {
  DateArrival: getText('constants:transfer.dateArrival'),
  TimeArrival: getText('constants:transfer.timeArrival'),
  DateDepartureAirline: getText('constants:transfer.dateDepartureAirline'),
  DateDepartureRailway: getText('constants:transfer.dateDepartureRailway'),
  DateDepartureTimeAirline: getText('constants:transfer.timeDepartureAirline'),
  DateDepartureTimeRailway: getText('constants:transfer.timeDepartureRailway'),
  ArrivalNumberAirline: getText('constants:transfer.arrivalNumberAirline'),
  ArrivalNumberRailway: getText('constants:transfer.arrivalNumberRailway'),
  TableSignature: getText('constants:transfer.tableSignature'),
  Notes: getText('constants:transfer.notes'),
  CarriageNumber: getText('constants:transfer.carriageNumber'),
  DATE_PLACEHOLDER: getText('constants:transfer.datePlaceholder'),
  StartTerminals: getText('constants:transfer.startTerminals'),
  EndTerminals: getText('constants:transfer.endTerminals'),
  Viewers: getText('constants:transfer.viewers'),
};

const PLACETYPE = {
  AIRPORT: 'Airport',
  RAILSTATION: 'RailwayStation',
  HOTEL: 'Hotel',
  CITY: 'Address',
};

const CAR_CLASS_LABELS = {
  STANDARD: getText('services:transfer.tariffMap.standart'),
  TOLL_ROAD_STANDART: getText('services:transfer.tariffMap.tollRoadStandart'),
  COMFORT: getText('services:transfer.tariffMap.comfort'),
  TOLL_ROAD_COMFORT: getText('services:transfer.tariffMap.tollRoadComfort'),
  BUSINESS: getText('services:transfer.tariffMap.business'),
  TOLL_ROAD_BUSINESS: getText('services:transfer.tariffMap.tollRoadBusiness'),
  BUSINESS_LITE: getText('services:transfer.tariffMap.businessLite'),
  MINIVAN: getText('services:transfer.tariffMap.minivan'),
  MINIVAN_VIP: getText('services:transfer.tariffMap.minivanVip'),
  MINIBUS: getText('services:transfer.tariffMap.minibus'),
  LARGE_MINIBUS: getText('services:transfer.tariffMap.largeMinibus'),
  REPRESENTATIVE: getText('services:transfer.tariffMap.executive'),
};

const MAP_CAR_CLASS_TITLE = {
  [TRANSFERFILTERCLASS.Standart]: CAR_CLASS_LABELS.STANDARD,
  [TRANSFERFILTERCLASS.TollRoadStandart]: CAR_CLASS_LABELS.TOLL_ROAD_STANDART,
  [TRANSFERFILTERCLASS.Comfort]: CAR_CLASS_LABELS.COMFORT,
  [TRANSFERFILTERCLASS.TollRoadComfort]: CAR_CLASS_LABELS.TOLL_ROAD_COMFORT,
  [TRANSFERFILTERCLASS.Business]: CAR_CLASS_LABELS.BUSINESS,
  [TRANSFERFILTERCLASS.TollRoadBusiness]: CAR_CLASS_LABELS.TOLL_ROAD_BUSINESS,
  [TRANSFERFILTERCLASS.BusinessLite]: CAR_CLASS_LABELS.BUSINESS_LITE,
  [TRANSFERFILTERCLASS.Minivan]: CAR_CLASS_LABELS.MINIVAN,
  [TRANSFERFILTERCLASS.MinivanVip]: CAR_CLASS_LABELS.MINIVAN_VIP,
  [TRANSFERFILTERCLASS.Minibus]: CAR_CLASS_LABELS.MINIBUS,
  [TRANSFERFILTERCLASS.LargeMiniBus]: CAR_CLASS_LABELS.LARGE_MINIBUS,
  [TRANSFERFILTERCLASS.Executive]: CAR_CLASS_LABELS.REPRESENTATIVE,
};

export {
  TRANSFERFIELDS as default,
  TRANSFERFIELDS,
  TRANSFERFILTERCLASS,
  MAPCARCLASS_RU,
  MAPCARCLASS,
  FIELDSLABELS,
  PLACETYPE,
  MAP_CAR_CLASS_TITLE,
};
