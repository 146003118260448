import { action, computed, makeObservable, observable } from 'mobx';
import { Moment } from 'moment';

import { fullFormPattern, momentObject, nextDateAfterCurrent } from '../../utils/formatDate';

import {
  AEROEXPRESS_DEFAULT_SCHEDULE_LIST,
  AEROEXPRESS_TARIFF_TYPES,
  IAeroexpressRoutesResponse,
  IStandardModel,
} from './const';

const getDefaultSearchSettings = () => ({
  type: AEROEXPRESS_TARIFF_TYPES.STANDARD,
  dateDeparture: momentObject(),
  dateBack: '',
  count: 1,
  route: { label: '', isSelected: false, code: '', type: '' },
  schedules: {
    dateDeparture: null,
    dateBack: null,
  },
});

const DEFAULT_SEARCH_RESULT = {
  loading: false,
  items: [],
  error: null,
};

export interface IAeroexpressStore {
  schedules: {
    dateDeparture: { loading: boolean, list: { value: null, label: string, rolledUpLabel: string }[], error: null | string }
    dateBack: { loading: boolean, list: { value: null, label: string, rolledUpLabel: string }[], error: null | string }
  },
  searchSettings: {
    type: string,
    dateDeparture: Moment,
    dateBack: string | Moment,
    count: number,
    route: { label: string, isSelected: boolean, code: string, type: string },
    schedules: { dateDeparture: null, dateBack: null }
  },
  searchResult: { loading: boolean, items: any[], error: null | any },
  isTravelApproval: boolean
}

export class AeroexpressStore {
  constructor() {
    makeObservable(this);
  }

  @observable rawRoutes: IAeroexpressRoutesResponse[] = [];
  @observable schedules = {
    dateDeparture: { loading: false, list: AEROEXPRESS_DEFAULT_SCHEDULE_LIST, error: null },
    dateBack: { loading: false, list: AEROEXPRESS_DEFAULT_SCHEDULE_LIST, error: null },
  };
  @observable searchSettings: IAeroexpressStore['searchSettings'] = getDefaultSearchSettings();
  @observable searchResult = DEFAULT_SEARCH_RESULT;
  @observable isTravelApproval = false;
  @observable isFormValid = false;

  @computed
  get routes() {
    const { route: { label, isSelected } } = this.searchSettings;
    const preparedValue = label.toLowerCase();

    if (!label) {
      return [...this.rawRoutes];
    }

    if (isSelected) {
      return [];
    }

    return this.rawRoutes.filter(({ from: { name: fName }, to: { name: tName } }) =>
      (preparedValue ? fName.toLowerCase().includes(preparedValue) || tName.toLowerCase().includes(preparedValue) : true));
  }

  @computed
  get stringifyDepartureDate() {
    return this.searchSettings.dateDeparture
      .clone()
      .startOf('day')
      .format(fullFormPattern);
  }

  @computed
  get stringifyBackDate() {
    return momentObject(this.searchSettings.dateBack)
      .clone()
      .startOf('day')
      .format(fullFormPattern);
  }

  @computed
  get preparedSearchModel() {
    const { type, count } = this.searchSettings;

    const getSpecificModel = ({
      [AEROEXPRESS_TARIFF_TYPES.STANDARD]: () => [this.prepareStandardSearchModel],
      [AEROEXPRESS_TARIFF_TYPES.BUSINESS]: () => [this.prepareStandardSearchModel],
    })[type];

    return {
      tariff_type: type,
      count,
      specific: getSpecificModel(),
    };
  }

  @computed
  get prepareStandardSearchModel() {
    const { dateBack } = this.searchSettings;

    const model: Partial<IStandardModel> = {
      departure_date: this.stringifyDepartureDate,
    };

    if (dateBack) {
      model.backward_date = this.stringifyBackDate;
    }

    return model;
  }

  @computed
  get departureDateAvailableRange() {
    return { min: momentObject(), max: momentObject().add({ d: 90 }) };
  }

  @computed
  get backDateAvailableRange() {
    const { dateDeparture } = this.searchSettings;

    return {
      min: dateDeparture.clone(),
      max: momentObject().add({ d: 90 }),
    };
  }

  @action
  setRawRoutes(list: IAeroexpressRoutesResponse[]) {
    this.rawRoutes = list;
  }

  @action
  // TODO: change model after release CLAIM-168045
  setRawRoutesFromApproval({ Employees, StartDate, EndDate }: any) {
    const employeesLength = Employees.length;
    const travellers = employeesLength > 9 ? 9 : employeesLength;

    this.searchSettings = {
      ...this.searchSettings,
      dateDeparture: nextDateAfterCurrent(StartDate),
      dateBack: momentObject(EndDate),
      count: travellers,
    };
    this.isTravelApproval = true;
  }

  @action
  updateSearchSettings(payload = {}) {
    this.searchSettings = { ...this.searchSettings, ...payload };
  }

  @action
  updateSearchResult(payload = {}) {
    this.searchResult = { ...this.searchResult, ...payload };
  }

  @action
  updateSearchSchedule(payload = {}) {
    this.schedules = { ...this.schedules, ...payload };
  }

  @action
  clearSearchSettings() {
    this.searchSettings = getDefaultSearchSettings();
    this.isTravelApproval = false;
  }

  @action
  disableSearchButton = (isDateSelected = true) => {
    const { route: { isSelected } } = this.searchSettings;

    this.isFormValid = isSelected && isDateSelected;
  };
}

export const aeroexpressStore = new AeroexpressStore();
