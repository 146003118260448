import i18next from 'i18next';
import moment from 'moment';
import 'moment/dist/locale/ru';
import { resourcesI18 } from 'new-ui';
import { initReactI18next } from 'react-i18next';

import { languages, namespaces, storageKeys } from './locales/consts';
import { resources } from './locales';

const events = {
  languageChanged: 'languageChanged',
};

const storageKey = storageKeys.userLanguage;

const currentLng = window.localStorage.getItem(storageKey) || languages.ru;

const isRuLng = () => currentLng === languages.ru;

const nsValues = Object.values(namespaces);
const lngValues = Object.values(languages);

const resourcesWithUI = {
  en: {
    ...resources.en,
    common: { ...resources.en.common, ...resourcesI18.en.translation },
  },
  ru: {
    ...resources.ru,
    common: { ...resources.ru.common, ...resourcesI18.ru.translation },
  } };

const options = {
  debug: process.env.NODE_ENV !== 'production',

  resources: resourcesWithUI,
  lng: currentLng,
  fallbackLng: languages.ru,
  supportedLngs: lngValues,
  ns: nsValues,

  interpolation: {
    escapeValue: false,
  },
};

const changeLanguage = (lng: string | undefined) => {
  // global moment localization
  moment.locale(lng);

  if (!!lng && lng !== currentLng) {
    window.localStorage.setItem(storageKey, lng);
    window.location.reload();
  }
};

const getText = (key: string, opt = {}) => i18next.t(key, opt);

const getTextArray = (key: string) => i18next.t(key, { returnObjects: true });

// eslint-disable-next-line no-console
const lngCb = () => console.log('language change cb invoked!');

i18next.on(events.languageChanged, lngCb);

i18next
  .use(initReactI18next)
  .init(options);

export { changeLanguage, getText, getTextArray, currentLng, isRuLng };
