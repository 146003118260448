import { action, computed, makeObservable, observable } from 'mobx';
import { UiLanguageType, UiSettingsType } from './types';

import CONFIG from '../../../../config';

import { isSmartAgent } from '../../utils/env';

const DEFAULT_SETTINGS = {
  brandColor: '#ff1d1d',
  companyName: 'smartway',
  showS7Program: true,
  showReports: true,
  customHeroTitle: false,
  showPaymentButton: true,
  showPaymentScore: true,
  paymentRedirectUrl: null,
  showLastInvoice: true,
  showCompanyInfo: true,
  showChartsAnalytics: true,
  copyrightName: 'Smartway',
  headTitle: 'Smartway',
  phone: CONFIG.ABOUT.PHONEWITHHYPHENS,
  email: 'help@smartway.today',
  tabReportRedirectUrl: null,
  reportRedirectUrl: null,
  showTabReportsWithoutFinanceRights: false,
  showTaxi: true,
  emailToQuality: '',
};

const DEFAULT_SMARTAGENT_SETTINGS = {
  brandColor: '#ff1d1d',
  companyName: 'smartagent',
  showS7Program: true,
  showReports: true,
  customHeroTitle: false,
  showPaymentButton: true,
  showPaymentScore: true,
  paymentRedirectUrl: null,
  showLastInvoice: true,
  showCompanyInfo: true,
  showChartsAnalytics: true,
  copyrightName: 'Smartagent',
  headTitle: 'Smartagent',
  phone: CONFIG.ABOUT.PHONEWITHHYPHENS,
  email: 'help@smartagent.online',
  tabReportRedirectUrl: null,
  reportRedirectUrl: null,
  showTabReportsWithoutFinanceRights: false,
  showTaxi: true,
  emailToQuality: '',
};

const DEFAULT_LANGUAGE: UiLanguageType = 'ru';

export interface IUiSettingsStore {
  settings: UiSettingsType,
  language: UiLanguageType
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable settings: UiSettingsType = isSmartAgent ? DEFAULT_SMARTAGENT_SETTINGS : DEFAULT_SETTINGS;
  @observable language: UiLanguageType = DEFAULT_LANGUAGE;
  @observable aggregationId: number | null = null;

  @computed
  get getCurrentLng() {
    return this.language;
  }

  @computed
  get getHeadTitle() {
    return this.settings.headTitle;
  }

  @computed
  get getCompanyName() {
    return this.settings.companyName;
  }

  @computed
  get getAggregationId() {
    return this.aggregationId;
  }

  @computed
  get showChartsAnalytics() {
    return this.settings.showChartsAnalytics;
  }

  @computed
  get isWhiteLabel() {
    return this.aggregationId !== null;
  }

  @action
  setUiSettings = (value: UiSettingsType): void => {
    this.settings = value;
  };

  @action
  setAggregationId = (value: number | null): void => {
    this.aggregationId = value;
  };

  @action
  setLngSettings = (value: UiLanguageType): void => {
    this.language = value;
  };
}

const UiSettingsStore = new Store();

export { UiSettingsStore };
