import React, { FC } from 'react';
import { Text } from 'new-ui';
import { observer } from 'mobx-react';
import * as queryString from 'query-string';

import { getText } from '../../../../i18n';

import { defaultPattern, hoursAndMinutesPattern } from '../../../bi/utils/formatDate';
import { prepareAirportName, prepareFormatDate } from '../../../bi/utils/vipHall';

import { VipHallMenu } from '../../../bi/types/vipHall';

import styles from './index.module.css';

const LABELS = {
  DATE: getText('search:vipHall.date'),
  TIME: getText('search:vipHall.time'),
  COUNT_GUESTS: getText('search:vipHall.countGuests'),
};

const VipHallSearchMenu: FC<VipHallMenu> = observer(({
  ticketData,
  mainInfo,
}: VipHallMenu) => {
  const { route, segment }: any = queryString.parse(location.search);

  const airportHtml = prepareAirportName(ticketData, mainInfo, route, segment);
  const dateHtml = prepareFormatDate(ticketData, mainInfo, route, segment, defaultPattern);
  const timeHtml = prepareFormatDate(ticketData, mainInfo, route, segment, hoursAndMinutesPattern);

  return (
    <div>
      <div className={ styles.submenu }>
        <div className={ styles.wrapper }>
          <div className={ styles.airport }>
            <Text type='NORMAL_16'>{ airportHtml }</Text>
          </div>
          <div className={ styles.info }>
            <div className={ styles['info-row'] }>
              <Text type='NORMAL_14' color='gray'>{ LABELS.DATE }</Text>
              <Text type='NORMAL_14' className={ styles['info-value'] }>{ dateHtml }</Text>
            </div>
            <div className={ styles['info-row'] }>
              <Text type='NORMAL_14' color='gray'>{ LABELS.TIME }</Text>
              <Text type='NORMAL_14' className={ styles['info-value'] }>{ timeHtml }</Text>
            </div>
            <div className={ styles['info-row'] }>
              <Text type='NORMAL_14' color='gray'>{ LABELS.COUNT_GUESTS }</Text>
              <Text type='NORMAL_14' className={ styles['info-value'] }>1</Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export { VipHallSearchMenu };
