import React, { Component, ReactEventHandler } from 'react';
import { IconButton } from 'new-ui';

import styles from './styles/index.module.css';

const MIN_INTERVAL = 500;

const throttle = (func: () => any, wait: number, ...funcArguments: any[]) => {
  let context: any;
  let args: any[] | null;
  let result: any;
  let timeout: number | null = null;
  let previous = 0;

  const later = () => {
    previous = new Date().getTime();
    timeout = null;
    result = func.apply(context, args as []);

    if (!timeout) {
      args = null;
      context = args;
    }
  };

  return () => {
    const now = new Date().getTime();
    const remaining = wait - (now - previous);
    context = this;
    args = funcArguments;

    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        window.clearTimeout(timeout);
        timeout = null;
      }

      previous = now;
      result = func.apply(context, args as []);

      if (!timeout) {
        args = null;
        context = args;
      }
    } else if (!timeout) {
      timeout = window.setTimeout(later, remaining);
    }

    return result;
  };
};

interface ImageGalleryProps {
  items: any[],
  showNav?: boolean,
  autoPlay?: boolean,
  lazyLoad?: boolean,
  infinite?: boolean,
  showIndex?: boolean,
  showBullets?: boolean,
  showThumbnails?: boolean,
  slideOnThumbnailHover?: boolean,
  disableThumbnailScroll?: boolean,
  defaultImage?: string,
  indexSeparator?: string,
  theme?: string,
  startIndex?: number,
  slideInterval?: number,
  originalImageStyles?: object,
  onSlide?(index: number): void,
  onPause?(index: number): void,
  onPlay?(index: number): void,
  onClick?(): void,
  onSlideLeft?(): void,
  onSlideRight?(): void,
  onImageLoad?(): void,
  onImageError?(): void,
  onThumbnailError?(): void,
}

interface ImageGalleryState {
  currentIndex: number,
  thumbsTranslateX: number,
  offsetPercentage: number,
  galleryWidth: number,
  previousIndex: number,
  hovering: boolean,
  style: any,
}

class ImageGallery extends Component<ImageGalleryProps, ImageGalleryState> {
  thumbnailDelay: number;
  ghotClickDelay: number;
  preventGhostClick: boolean;
  intervalId: number | null;
  preventGhostClickTimer: number | null;
  thumbnailTimer: number | null;
  imageGallery: HTMLElement | null;
  thumbnails: HTMLElement | null;

  constructor(props: ImageGalleryProps) {
    super(props);
    this.state = {
      // @ts-ignore
      currentIndex: props.startIndex,
      thumbsTranslateX: 0,
      offsetPercentage: 0,
      galleryWidth: 0,
    };

    this.slideLeft = throttle(this.slideLeft, MIN_INTERVAL, true);
    this.slideRight = throttle(this.slideRight, MIN_INTERVAL, true);
  }

  static defaultProps = {
    items: [],
    showNav: true,
    autoPlay: false,
    lazyLoad: false,
    infinite: true,
    showIndex: false,
    showBullets: false,
    showThumbnails: true,
    slideOnThumbnailHover: false,
    disableThumbnailScroll: false,
    indexSeparator: ' / ',
    theme: 'default',
    startIndex: 0,
    slideInterval: 3000,
    defaultImage: null,
    onSlide: () => {},
    onPause: () => {},
    onPlay: () => {},
    onClick: () => {},
    onImageLoad: () => {},
    onImageError: () => {},
    onThumbnailError: () => {},
    onSlideLeft: () => {},
    onSlideRight: () => {},
    originalImageStyles: null,
  };

  componentDidUpdate(prevProps: ImageGalleryProps, prevState: ImageGalleryState) {
    if (prevState.galleryWidth !== this.state.galleryWidth ||
      prevProps.showThumbnails !== this.props.showThumbnails) {
      // adjust thumbnail container when window width is adjusted
      this.setThumbsTranslateX(
        -this.getThumbsTranslateX(
          this.state.currentIndex > 0 ? 1 : 0) * this.state.currentIndex);
    }

    if (prevState.currentIndex !== this.state.currentIndex) {
      if (this.props.onSlide) {
        this.props.onSlide(this.state.currentIndex);
      }

      this.updateThumbnailTranslateX(prevState);
    }
  }

  componentDidMount() {
    this.thumbnailDelay = 300;
    this.ghotClickDelay = 600;
    this.preventGhostClick = false;
    window.setTimeout(() => this.handleResize(), 300);

    if (this.props.autoPlay) {
      this.play();
    }

    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('resize', this.handleResize);

    if (this.intervalId) {
      window.clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  play = (callback = true) => {
    if (this.intervalId) {
      return;
    }

    const { slideInterval } = this.props;
    // @ts-ignore
    const timeout = slideInterval > MIN_INTERVAL ? slideInterval : MIN_INTERVAL;
    this.intervalId = window.setInterval(() => {
      if (!this.state.hovering) {
        if (!this.props.infinite && !this.canSlideRight()) {
          this.pause();
        } else {
          this.slideToIndex(this.state.currentIndex + 1);
        }
      }
    }, timeout as number);

    if (this.props.onPlay && callback) {
      this.props.onPlay(this.state.currentIndex);
    }
  };

  pause = (callback = true) => {
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
      this.intervalId = null;
    }

    if (this.props.onPause && callback) {
      this.props.onPause(this.state.currentIndex);
    }
  };

  slideToIndex = (index: number, event?: any) => {
    const slideCount = this.props.items.length - 1;
    let currentIndex = index;

    if (index < 0) {
      currentIndex = slideCount;
    } else if (index > slideCount) {
      currentIndex = 0;
    }

    this.setState({
      previousIndex: this.state.currentIndex,
      currentIndex,
      offsetPercentage: 0,
      style: {
        transition: 'transform .2s ease-out',
      },
    });

    if (event) {
      if (this.intervalId) {
        // user event, while playing, reset interval
        this.pause(false);
        this.play(false);
      }
    }
  };

  wrapClick = (func: (item: any) => void) => {
    if (typeof func === 'function') {
      return (event: Event) => {
        if (this.preventGhostClick === true) {
          return;
        }

        func(event);
      };
    }

    return null;
  };

  touchEnd = () => {
    this.preventGhostClick = true;
    this.preventGhostClickTimer = window.setTimeout(() => {
      this.preventGhostClick = false;
      this.preventGhostClickTimer = null;
    }, this.ghotClickDelay);
  };

  handleResize = () => {
    if (this.imageGallery) {
      this.setState({ galleryWidth: this.imageGallery.offsetWidth });
    }
  };

  handleKeyDown = (event: KeyboardEvent) => {
    const LEFT_ARROW = 37;
    const RIGHT_ARROW = 39;
    // @ts-ignore
    const key = parseInt(event.keyCode || event.which || 0, 10);

    switch (key) {
      case LEFT_ARROW:
        if (this.canSlideLeft() && !this.intervalId) {
          this.slideLeft();
        }

        break;
      case RIGHT_ARROW:
        if (this.canSlideRight() && !this.intervalId) {
          this.slideRight();
        }

        break;
    }
  };

  handleMouseOverThumbnails = (index: number) => {
    if (this.props.slideOnThumbnailHover) {
      this.setState({ hovering: true });

      if (this.thumbnailTimer) {
        window.clearTimeout(this.thumbnailTimer);
        this.thumbnailTimer = null;
      }

      this.thumbnailTimer = window.setTimeout(() => {
        this.slideToIndex(index);
      }, this.thumbnailDelay);
    }
  };

  handleMouseLeaveThumbnails = () => {
    if (this.thumbnailTimer) {
      window.clearTimeout(this.thumbnailTimer);
      this.thumbnailTimer = null;

      if (this.props.autoPlay === true) {
        this.play(false);
      }
    }

    this.setState({ hovering: false });
  };

  handleMouseOver = () => {
    this.setState({ hovering: true });
  };

  handleMouseLeave = () => {
    this.setState({ hovering: false });
  };

  handleImageError = (event: any) => {
    let targetImage = event.target.src;

    if (this.props.defaultImage &&
      targetImage.indexOf(this.props.defaultImage) === -1) {
      targetImage = this.props.defaultImage;
    }

    return null;
  };

  canNavigate = () => this.props.items.length >= 2;

  canSlideLeft = () => {
    if (this.props.infinite) {
      return true;
    }

    return this.state.currentIndex > 0;
  };

  canSlideRight = () => {
    if (this.props.infinite) {
      return true;
    }

    return this.state.currentIndex < this.props.items.length - 1;
  };

  updateThumbnailTranslateX = (prevState: ImageGalleryState) => {
    if (this.state.currentIndex === 0) {
      this.setThumbsTranslateX(0);
    } else {
      const indexDifference = Math.abs(
        prevState.currentIndex - this.state.currentIndex);
      const scrollX = this.getThumbsTranslateX(indexDifference);

      if (scrollX > 0) {
        if (prevState.currentIndex < this.state.currentIndex) {
          this.setThumbsTranslateX(
            this.state.thumbsTranslateX - scrollX);
        } else if (prevState.currentIndex > this.state.currentIndex) {
          this.setThumbsTranslateX(
            this.state.thumbsTranslateX + scrollX);
        }
      }
    }
  };

  setThumbsTranslateX = (thumbsTranslateX: number) => {
    this.setState({ thumbsTranslateX });
  };

  getThumbsTranslateX = (indexDifference: number) => {
    if (this.props.disableThumbnailScroll) {
      return 0;
    }

    if (this.thumbnails) {
      if (this.thumbnails.scrollWidth <= this.state.galleryWidth) {
        return 0;
      }

      const totalThumbnails = this.thumbnails.children.length;
      // total scroll-x required to see the last thumbnail
      const totalScrollX = this.thumbnails.scrollWidth - this.state.galleryWidth;
      // scroll-x required per index change
      const perIndexScrollX = totalScrollX / (totalThumbnails - 1);

      return indexDifference * perIndexScrollX;
    }

    return 0;
  };

  getAlignmentClassName = (index: number) => {
    const { currentIndex } = this.state;
    let alignment = '';
    const LEFT = 'left';
    const CENTER = styles['image-gallery-slide-center'];
    const RIGHT = 'right';

    switch (index) {
      case (currentIndex - 1):
        alignment = LEFT;
        break;
      case (currentIndex):
        alignment = CENTER;
        break;
      case (currentIndex + 1):
        alignment = RIGHT;
        break;
    }

    if (this.props.items.length >= 3 && this.props.infinite) {
      if (index === 0 && currentIndex === this.props.items.length - 1) {
        // set first slide as right slide if were sliding right from last slide
        alignment = ` ${RIGHT}`;
      } else if (index === this.props.items.length - 1 && currentIndex === 0) {
        // set last slide as left slide if were sliding left from first slide
        alignment = ` ${LEFT}`;
      }
    }

    return alignment;
  };

  getSlideStyle = (index: number) => {
    const { currentIndex, offsetPercentage } = this.state;
    const basetranslateX = -100 * currentIndex;
    const totalSlides = this.props.items.length - 1;

    let translateX = basetranslateX + (index * 100) + offsetPercentage;
    let zIndex = 1;

    if (this.props.infinite && this.props.items.length > 1) {
      if (currentIndex === 0 && index === totalSlides) {
        // make the last slide the slide before the first
        translateX = -100 + offsetPercentage;
      } else if (currentIndex === totalSlides && index === 0) {
        // make the first slide the slide after the last
        translateX = 100 + offsetPercentage;
      }
    }

    // current index has more zIndex so slides wont fly by toggling infinite
    if (index === currentIndex) {
      zIndex = 3;
    } else if (index === this.state.previousIndex) {
      zIndex = 2;
    }

    const translate3d = `translate3d(${translateX}%, 0, 0)`;

    return {
      WebkitTransform: translate3d,
      MozTransform: translate3d,
      msTransform: translate3d,
      OTransform: translate3d,
      transform: translate3d,
      zIndex,
    };
  };

  getThumbnailStyle = () => {
    const translate3d = `translate3d(${this.state.thumbsTranslateX}px, 0, 0)`;

    return {
      WebkitTransform: translate3d,
      MozTransform: translate3d,
      msTransform: translate3d,
      OTransform: translate3d,
      transform: translate3d,
    };
  };

  slideLeft = () => {
    this.slideToIndex(this.state.currentIndex - 1);

    if (this.props.onSlideLeft) {
      this.props.onSlideLeft();
    }
  };

  slideRight = () => {
    this.slideToIndex(this.state.currentIndex + 1);

    if (this.props.onSlideRight) {
      this.props.onSlideRight();
    }
  };

  render() {
    const { currentIndex } = this.state;
    const { theme, originalImageStyles } = this.props;

    const thumbnailStyle = this.getThumbnailStyle();

    const slideLeft = this.slideLeft;
    const slideRight = this.slideRight;

    const slides: JSX.Element[] = [];
    const thumbnails: JSX.Element[] = [];
    const bullets: JSX.Element[] = [];
    const smallStyles = theme === 'small' ? styles.small : '';

    this.props.items.map((item, index) => {
      const alignment = this.getAlignmentClassName(index);
      const originalClass = item.originalClass ?
        ` ${item.originalClass}` : '';
      const thumbnailClass = item.thumbnailClass ?
        ` ${item.thumbnailClass}` : '';
      const slideCursorClass = this.props.onClick ? styles.clickable : '';

      let onImageError: ReactEventHandler<HTMLImageElement> = this.handleImageError;

      if (this.props.onImageError) {
        onImageError = this.props.onImageError;
      }

      const slideImgStyles = originalImageStyles || {};

      const slide = (
        <div
          key={ item.original }
          className={ `${styles['image-gallery-slide']} ${alignment} ${originalClass} ${slideCursorClass}` }
          style={ Object.assign(this.getSlideStyle(index), this.state.style) }
          // @ts-ignore
          onClick={ this.wrapClick(this.props.onClick) }
          onTouchStart={ this.props.onClick }
          onTouchEnd={ this.touchEnd }
        >
          <div className={ styles['image-gallery-image'] }>
            <img
              src={ item.original }
              className={ styles.background }
              alt={ item.originalAlt }
            />
            <img
              style={ slideImgStyles }
              src={ item.original }
              alt={ item.originalAlt }
              className={ smallStyles }
              onLoad={ this.props.onImageLoad }
              onError={ onImageError }
            />
            {
              item.description &&
              <span className={ styles['image-gallery-description'] }>
                {item.description}
              </span>
            }
          </div>
        </div>
      );

      if (this.props.lazyLoad) {
        if (alignment) {
          slides.push(slide);
        }
      } else {
        slides.push(slide);
      }

      let onThumbnailError: ReactEventHandler<HTMLImageElement> = this.handleImageError;

      if (this.props.onThumbnailError) {
        onThumbnailError = this.props.onThumbnailError;
      }

      if (this.props.showThumbnails) {
        thumbnails.push(
          <a
            onMouseOver={ () => this.handleMouseOverThumbnails(index) }
            onMouseLeave={ this.handleMouseLeaveThumbnails }
            key={ index }
            className={ `${styles['image-gallery-thumbnail']} ${currentIndex === index ? styles['image-gallery-thumbnail-active' +
            ''] : ''} ${thumbnailClass}` }
            onTouchStart={ () => this.slideToIndex(index) }
            onTouchEnd={ this.touchEnd }
            // @ts-ignore
            onClick={ () => this.wrapClick(this.slideToIndex(index)) }
          >

            <img
              src={ item.thumbnail }
              alt={ item.thumbnailAlt }
              onError={ onThumbnailError }
            />
          </a>,
        );
      }

      if (this.props.showBullets) {
        bullets.push(
          <li
            key={ index }
            className={ `${styles['image-gallery-bullet']} ${currentIndex === index ? styles['image-gallery-bullet-active'] : ''}` }
            onTouchStart={ () => this.slideToIndex(index) }
            onTouchEnd={ this.touchEnd }
            // @ts-ignore
            onClick={ this.wrapClick(this.slideToIndex(index)) }
          />,
        );
      }

      return null;
    });

    return (
      <section
        ref={ (i) => { this.imageGallery = i; } }
        className={ styles['image-gallery'] }
      >
        <div
          onMouseOver={ this.handleMouseOver }
          onMouseLeave={ this.handleMouseLeave }
          className={ styles['image-gallery-content'] }
        >
          {
            this.canNavigate() ?
              [
                this.props.showNav &&
                <span key='navigation'>
                  {
                  this.canSlideLeft() &&
                    <IconButton
                      iconType='arrowsLeftRoundBig'
                      // @ts-ignore
                      onClick={ () => this.wrapClick(slideLeft)() }
                      className={ `${styles['image-gallery-left-nav']} ${smallStyles}` }
                    />
                }
                  {
                  this.canSlideRight() &&
                    <IconButton
                      iconType='arrowsRightRoundBig'
                      // @ts-ignore
                      onClick={ () => this.wrapClick(slideRight)() }
                      className={ `${styles['image-gallery-right-nav']} ${smallStyles}` }
                    />
                }
                </span>,
                <div className={ styles['image-gallery-slides'] } key='slides'>
                  {slides}
                </div>,
              ]
              :
              <div className={ styles['image-gallery-slides'] } key='slides'>
                {slides}
              </div>
          }
          {
            this.props.showBullets &&
            <div className={ styles['image-gallery-bullets'] } key='bullets'>
              <ul className={ styles['image-gallery-bullets-container'] }>
                {bullets}
              </ul>
            </div>
          }
          {
            this.props.showIndex &&
            <div className={ styles['image-gallery-index'] }>
              <span className={ styles['image-gallery-index-current'] }>
                {this.state.currentIndex + 1}
              </span>
              <span className={ styles['image-gallery-index-separator'] }>
                {this.props.indexSeparator}
              </span>
              <span className={ styles['image-gallery-index-total'] }>
                {this.props.items.length}
              </span>
            </div>
          }
        </div>

        {
          this.props.showThumbnails &&
          <div className={ styles['image-gallery-thumbnails'] }>
            <div
              ref={ (t) => { this.thumbnails = t; } }
              className={ styles['image-gallery-thumbnails-container'] }
              style={ thumbnailStyle }
            >
              {thumbnails}
            </div>
          </div>
        }
      </section>
    );
  }
}

export { ImageGallery };
