import React from 'react';
import { Text } from 'new-ui';

import styles from './styles.module.css';

interface IAirlineSearchMenuSuggestItemProps {
  city: (React.ReactElement | string)[] | string;
  name: (React.ReactElement | string)[] | string;
  code: (React.ReactElement | string)[] | string;
}

const AirlineSearchMenuSuggestItem = ({ city, name, code }: IAirlineSearchMenuSuggestItemProps) => (
  <div className={ styles.suggest }>
    <div className={ styles.city }>
      <Text>{ city }</Text>
      <Text>{ code }</Text>
    </div>
    <Text type='NORMAL_12'>{ name }</Text>
  </div>
);

export { AirlineSearchMenuSuggestItem };
