import React, { useState, useEffect, useCallback, useRef, FC, ReactNode } from 'react';
import * as ReactDOM from 'react-dom';

import { MAIN_MENU_WRAPPER } from '../../bi/constants/elementsIds';

import styles from './styles/index.module.css';

interface StickyPanelProps {
  children: ReactNode,
  adjustment?: number,
  fixedClass?: string,
  className?: string,
  style?: any,
  renderOnHeader?: boolean,
  initial?: number,
}

const StickyPanel: FC<StickyPanelProps> = ({
  children,
  fixedClass,
  className,
  renderOnHeader,
  initial,
  adjustment = null,
  style: pStyle }) => {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const getRect = useCallback(() => (ref.current ? ref.current.getBoundingClientRect() : { height: '', left: '', width: '', top: '' }), [ref]);

  const handleScroll = useCallback((event: any) => {
    const scrollY = event.target.scrollingElement.scrollTop + adjustment;

    // @ts-ignore
    if (getRect().top < scrollY) {
      return setSticky(true);
    }

    if (adjustment && adjustment >= scrollY) {
      return setSticky(false);
    }

    return null;
  }, [adjustment, getRect]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const classNames = [];

  const style = {
    // @ts-ignore
    left: getRect().left,
    // @ts-ignore
    width: getRect().width,
    ...pStyle,
  };

  if (isSticky) {
    classNames.push(styles.fixed, fixedClass);
  }

  if (initial) {
    style.top = initial;
  }

  if (isSticky && renderOnHeader) {
    style.zIndex = 1;
  }

  if (isSticky) {
    style.top = adjustment;
  }

  if (!isSticky && className) {
    classNames.push(className);
  }

  if (isSticky) {
    // @ts-ignore
    const height = getRect().height;
    const stickyContent = (
      <div>
        <div style={ { height: `${height}px` } } />
        <div
          ref={ ref }
          className={ classNames.join(' ') }
          style={ style }
        >
          { children }
        </div>
      </div>
    );

    if (renderOnHeader) {
      return ReactDOM.createPortal(stickyContent, document.getElementById(MAIN_MENU_WRAPPER) as Element);
    }

    return stickyContent;
  }

  return (
    <div
      className={ classNames.join(' ') }
      style={ style }
      ref={ ref }
    >
      { children }
    </div>
  );
};

export { StickyPanel };
