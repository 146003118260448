import React from 'react';
import { Text } from 'new-ui';

import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';

import parseJsonString from '../../../bi/utils/parseJsonString';
import { getAirlineType } from '../../../bi/utils/airline';
import { formatRangeDateWithSimplicity } from '../../../bi/utils/formatDate';

import { AirSegment, AirRoute } from '../../../bi/types/airline';

import styles from './styles/index.module.css';

interface TripAirItem {
  id: number,
  jsonData: string,
  serviceType: string,
}

const getAirline = (segment: AirSegment) => segment[getAirlineType(segment)].Code;

const TripAir = (
  item: TripAirItem,
  travellers: string | null | React.JSX.Element,
  serviceType: string,
) => {
  const { jsonData, id } = item;
  const data = parseJsonString(jsonData);

  if (!data) return null;

  const { Routes } = data;

  const routesHtml = (Routes as AirRoute[]).map(({ Segments }, index: number) => {
    const { DepartureDate, DepartureAirport, DepartureCity } = Segments[0];
    const { ArrivalDate, ArrivalAirport, ArrivalCity } = Segments[Segments.length - 1];

    const date = formatRangeDateWithSimplicity(DepartureDate, ArrivalDate, { withTime: true });
    const segmentsOfFlightNumber: AirSegment[] = [];

    Segments.forEach((segment) => {
      if (segmentsOfFlightNumber.every(({ FlightNumber }) => FlightNumber !== segment.FlightNumber)) {
        segmentsOfFlightNumber.push(segment);
      }
    });

    const flightNumbers = segmentsOfFlightNumber.map(segment => `${getAirline(segment)} ${segment.FlightNumber}`).join(', ');

    return (
      <div key={ `trip_trip_conflict_item_${id}_${index}` }>
        <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.date }>{ date }, { flightNumbers }</Text>
        <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.info } type='NORMAL_14_130'>
          { serviceType } { DepartureAirport.Name } ({ DepartureCity }) &ndash; { ArrivalAirport.Name } ({ ArrivalCity })
        </Text>
        <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.travellers } type='NORMAL_14' color='gray'>
          { travellers }
        </Text>
      </div>
    );
  });

  return (
    <div key={ `trip_conflict_${id}` } className={ styles.item }>
      { routesHtml }
    </div>
  );
};

export default TripAir;
