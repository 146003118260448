import { action, makeObservable, observable } from 'mobx';
import { PROPS } from 'new-ui';

import {
  DEFAULT_SUB_TABLE_INFO,
  DEFAULT_CHART,
  DEFAULT_PAGINATE,
  DEFAULT_TABLE_INFO,
  DEFAULT_AIRLINE_COLORS,
  STRUCTURE_TYPE,
} from '../../../constants/chartsAnalytics';

import type {
  ChartType,
  TableInfoType,
  SubTableType,
  PaginateType,
  ContentType,
  SubTableStringsType,
} from '../types';

export class ChartsAnalyticsSummaryStore {
  constructor() {
    makeObservable(this);
  }

  @observable chart: ChartType = { ...DEFAULT_CHART };
  @observable donutChart: ChartType = { ...DEFAULT_CHART };
  @observable tableInfo: TableInfoType = { ...DEFAULT_TABLE_INFO };
  @observable subTableInfo: SubTableType = { ...DEFAULT_SUB_TABLE_INFO };
  @observable paginate: PaginateType = { ...DEFAULT_PAGINATE };
  @observable countTrips: number | null = 0;
  @observable loading = true;
  @observable type = '';
  @observable voucherId = null;
  @observable showTooltip = false;
  @observable notShowFilterProject = false;
  @observable subPageInfo: any | null = null;
  @observable analyticIds = [];
  @observable depIds = [];
  @observable projectIds = [];
  @observable title = '';
  @observable serviceType = '';

  @action
  setChart = (chart: ChartType, donutChart?: ChartType, isAirCompany = false, isCustom = false) => {
    const { values: chartValues, labels: chartLabels, dates } = chart;

    if (isAirCompany) {
      chartLabels.forEach((item: string, index: number) => {
        const finder = DEFAULT_AIRLINE_COLORS.find(({ label }) => label === item);
        this.chart.colors[index] = finder ? finder.value : DEFAULT_CHART.colors[index];
      });
    }

    if (isCustom && donutChart) {
      this.chart = {
        ...this.chart,
        colors: PROPS.CHART_COLORS.ALTERNATIVE,
        labels: chartLabels,
        values: chartValues,
      };

      this.donutChart = {
        ...this.donutChart,
        values: donutChart.values,
        labels: donutChart.labels,
        colors: PROPS.CHART_COLORS.ALTERNATIVE,
      };

      return;
    }

    this.chart = {
      ...this.chart,
      values: chartValues,
      labels: chartLabels,
      dates,
    };

    this.donutChart = donutChart ? {
      ...this.donutChart,
      values: donutChart.values,
      labels: donutChart.labels,
      colors: this.chart.colors,
    } : this.donutChart;
  };

  @action
  setTableInfo = (
    labelsHeader: string[],
    content: ContentType[],
  ) => {
    this.tableInfo = {
      labelsHeader,
      content,
    };
  };

  @action
  updateShowTooltip = (value: boolean) => {
    this.showTooltip = value;
  };

  @action
  setSubPageInfo = (value: any) => {
    this.subPageInfo = value;
  };

  @action
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  setCountTrips = (value: number) => {
    this.countTrips = value;
    this.paginate = {
      ...this.paginate,
      total: value,
    };
  };

  @action
  setTrips = (strings: SubTableStringsType[], columnsName: string[]) => {
    this.subTableInfo = {
      ...this.subTableInfo,
      strings,
      columnsName,
    };
  };

  @action
  setPageTrips = (page: number) => {
    this.paginate = {
      ...this.paginate,
      currentPage: page,
    };
  };

  @action
  setType = (type: string) => {
    this.type = type || STRUCTURE_TYPE.OTHER;
  };

  @action
  setVoucherId = (value: any) => {
    this.voucherId = value;
  };

  @action
  setAnalyticValueId = (ids: number[] | any) => {
    this.analyticIds = ids.map((id: number) => Number(id));
  };

  @action
  setDepartmentIds = (ids: number[] | any) => {
    this.depIds = ids.map((id: number) => Number(id));
  };

  @action
  setProjectIds = (ids: number[] | any) => {
    this.projectIds = ids.map((id: number) => Number(id));
  };

  @action
  setTitle = (title: string) => {
    this.title = title;
  };

  @action
  setResetPaginate = () => {
    this.paginate = { ...DEFAULT_PAGINATE };

    return this.paginate;
  };
}

export const chartsAnalyticsSummaryStore = new ChartsAnalyticsSummaryStore();
