import { observable, action, computed, makeObservable } from 'mobx';

import { checkApprovalSchemeArray } from '../../../utils/approval';

import { DEFAULT_APPROVAL_SCHEME_FORM_VALUE, StatusScheme } from '../../../constants/approvalSchemes';
import { TravelApprovalTemplateSchema, TravelApprovalTemplateType } from '../../travelApproval/consts';
import { RequestInterface } from '../../../types/applicationScheme';

import type { ApprovalSchemeFAQStore, ApprovalSchemeFormStore, ApprovalSchemesListStore } from '../types';

export interface IApprovalSchemesStore {
  list: ApprovalSchemesListStore,
  form: ApprovalSchemeFormStore,
  faq: ApprovalSchemeFAQStore,
  status: string,
  applicationSchemeTemplate: RequestInterface,
  loadingList: boolean,
  isFormValid: boolean,
  employeesCount: number,
}

export class ApprovalSchemesStore {
  constructor() {
    makeObservable(this);
  }

  @observable list: ApprovalSchemesListStore = {
    loading: false,
    value: [],
    error: false,
  };
  @observable form: ApprovalSchemeFormStore = { ...DEFAULT_APPROVAL_SCHEME_FORM_VALUE };
  @observable faq: ApprovalSchemeFAQStore = { id: null };
  @observable status: StatusScheme = StatusScheme.CREATE;
  @observable applicationSchemeTemplate: TravelApprovalTemplateType = TravelApprovalTemplateSchema.getDefault();
  @observable getApproverListLoading = false;
  @observable loadingList = false;
  @observable employeesCount = 0;

  @computed
  get isFormValid(): boolean {
    const { Steps, PreSteps, Name } = this.form;

    return !!Name.trim().length && checkApprovalSchemeArray(Steps) && checkApprovalSchemeArray(PreSteps);
  }

  @action
  updateList = (value: ApprovalSchemesListStore) => {
    this.list = {
      ...this.list,
      ...value,
    };
  };

  @action
  updateApplicationSchemeTemplate = (schemeTemplate: TravelApprovalTemplateType) => {
    this.applicationSchemeTemplate = {
      ...schemeTemplate,
    };
  };

  @action
  updateApproverListLoading = (value: boolean) => {
    this.getApproverListLoading = value;
  };

  @action
  updateForm = (value: ApprovalSchemeFormStore | any) => {
    this.form = {
      ...this.form,
      ...value,
    };
  };

  @action
  updateFaq = (value: ApprovalSchemeFAQStore) => {
    this.faq = {
      ...this.faq,
      ...value,
    };
  };

  @action
  setStatus = (value: StatusScheme) => {
    this.status = value;
  };

  @action
  setEmployeesCount = (count: number) => {
    this.employeesCount = count;
  };

  @action
  setLoadingList = (value: boolean) => {
    this.loadingList = value;
  };

  @action
  clearExceptList = () => {
    this.form = { ...DEFAULT_APPROVAL_SCHEME_FORM_VALUE };
    this.faq = { id: null };
    this.status = StatusScheme.CREATE;
  };
}

export const approvalSchemesStore = new ApprovalSchemesStore();
