import React from 'react';
import { IconButton, Text } from 'new-ui';

import { getText } from '../../../i18n';

import { formatDate } from '../../bi/utils/formatDate';
import { PATTERN } from '../../bi/constants/dateFormats';
import { NAMESAKES } from '../../bi/constants/employee';
import { DisplayFF, containsFF, getEmployeeFullName } from '../../bi/utils/employees';
import { firstSymbolUpperCase } from '../../bi/utils/text';

import { EmployeesObj } from '../../bi/types/employees';

import styles from './styles/index.module.css';

const LABELS = {
  ADD_EMPLOYEE: getText('components:employeeSuggest.addEmployee'),
  DATE_BIRTHDAY: getText('components:employeeSuggest.birthday'),
  DOCUMENT: getText('components:employeeSuggest.document'),
};

type EmployeeType = EmployeesObj & {
  addNewEmployee: boolean,
};

type NamesakeType = EmployeesObj & {
  namesake: string,
};

interface EmployeeCloneItemProps {
  value: EmployeeType
  highlitedWord?: string,
  className?:string,
  qaAttr?:string,
  displayFF?: [],
  displayBirthday?: boolean,
}

const prepareNamesake = ({ namesake, Documents, Email, Birthday }: NamesakeType) => {
  switch (namesake) {
    case NAMESAKES.Birthday:
      return formatDate(Birthday, PATTERN.DAY_OF_MONTH_WITH_YEAR);
    case NAMESAKES.Passport:
      return Documents[0].Number;
    case NAMESAKES.Email:
      return Email;
    default:
      return '';
  }
};

const highlightTextFragment = (highlitedWord: string, fullWord: string) => {
  const highlitedWordUpperCase = firstSymbolUpperCase(highlitedWord);

  return fullWord.startsWith(highlitedWordUpperCase) ? `<mark>${highlitedWordUpperCase}</mark>${fullWord.substring(highlitedWord.length, fullWord.length)}` : fullWord;
};

const EmployeeCloneItem = ({ value, highlitedWord, className, qaAttr, displayFF = [], displayBirthday = false }:EmployeeCloneItemProps) => {
  if (value?.addNewEmployee) {
    return (
      <div className={ styles.suggestion }>
        <IconButton qaAttr={ qaAttr } iconType='plusDefault' className={ styles['add-employee-icon'] }>
          { LABELS.ADD_EMPLOYEE }
        </IconButton>
      </div>
    );
  }

  const fullName = getEmployeeFullName(value);
  let highlightedLine = null;

  if (highlitedWord) {
    const { Name, Patronymic, Surname } = value;

    const highlightedName = highlightTextFragment(highlitedWord, Name);
    const highlightedSurname = highlightTextFragment(highlitedWord, Surname);
    const highlightedPatronymic = highlightTextFragment(highlitedWord, Patronymic);
    highlightedLine = `${highlightedSurname} ${highlightedName} ${highlightedPatronymic}`;
  }

  const birthdayNamesake: string | boolean = displayBirthday
    && `${LABELS.DATE_BIRTHDAY} ${prepareNamesake({ ...value, namesake: NAMESAKES.Birthday })}`;
  const passportNamesake: string | boolean = containsFF(displayFF, DisplayFF.DOCUMENTS)
    && value.Documents.length ? `${LABELS.DOCUMENT} ${prepareNamesake({ ...value, namesake: NAMESAKES.Passport })}` : '';

  const namesakeContent = (namesake:string | boolean) => namesake && (
  <Text
    color='gray'
    type='NORMAL_12'
    className={ styles.hint }
  >
    { namesake }
  </Text>
  );

  return (
    <div className={ `${className} ${styles.wrapper}` }>
      { /* eslint-disable-next-line react/no-danger */ }
      <div className={ styles.text } dangerouslySetInnerHTML={ { __html: highlightedLine ?? fullName } } />
      { namesakeContent(birthdayNamesake) }
      { namesakeContent(passportNamesake) }
    </div>
  );
};

export { EmployeeCloneItem };
