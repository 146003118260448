import React from 'react';
import { Checkbox, Text } from 'new-ui';
import MoneyFormat from '../../../../../../bi/utils/money';
import { getText } from '../../../../../../../i18n';

import { ButtonTrip } from '../../../ButtonTrip';
import { ErrorDialog } from '../ErrorDialog/ErrorDialog';

import styles from '../../index.module.css';

const LABELS = {
  FINAL_STEP: (value: string) =>
    getText('components:tripDetailsDialog.automaticCancelTrip.finalStep', { value }),
  REFUND_PENALTY: (value: string) =>
    getText('components:tripDetailsDialog.automaticCancelTrip.refundPenalty', { value }),
  WILL_BE_CANCELLED: getText('components:tripDetailsDialog.automaticCancelTrip.willBeCancelled'),
  AGREEMENT: getText('components:tripDetailsDialog.automaticCancelTrip.agreement'),
  VOUCHER: getText('components:tripDetailsDialog.automaticCancelTrip.voucher'),
};

interface IFreeTaxiProps {
  agreement: boolean;
  refund: number;
  loadingCancellation: boolean;
  isTaxiVoucher?: boolean;
  hasDisabled: boolean;
  showErrorDialog: boolean,
  errorMsg: string,
  handleChangeCheckbox(value: boolean): void;
  onCancelAutomaticCancellation?(): void;
  onCloseDialog(): void;
  onCancelTrip(): any;
  toggleErrorDialog(): void;
}
const FreeTaxi = ({
  agreement,
  refund,
  handleChangeCheckbox,
  loadingCancellation,
  isTaxiVoucher = false,
  hasDisabled,
  showErrorDialog,
  errorMsg,
  onCloseDialog,
  onCancelTrip,
  onCancelAutomaticCancellation,
  toggleErrorDialog,
}: IFreeTaxiProps) => {
  const agreementHtml = agreement && (
    <Text className={ styles.warning } type='NORMAL_16_140' color='red'>
      { LABELS.FINAL_STEP(LABELS.WILL_BE_CANCELLED) }
    </Text>
  );

  return (
    <>
      <Text type='NORMAL_16_140'>
        { LABELS.REFUND_PENALTY(MoneyFormat.moneyWithDecimal(refund)) }
      </Text>

      <div className={ styles.checkbox }>
        <Checkbox
          value={ agreement }
          onChange={ handleChangeCheckbox }
        >
          {LABELS.AGREEMENT} {LABELS.VOUCHER}
        </Checkbox>
      </div>
      { agreementHtml }
      <ButtonTrip
        isTaxiVoucher={ isTaxiVoucher }
        loading={ loadingCancellation }
        disabledTrain={ !agreement }
        hasDisabled={ hasDisabled }
        onCloseDialog={ onCloseDialog }
        onSubmitCancellationModal={ onCancelTrip }
        onCancel={ onCancelAutomaticCancellation }
      />

      <ErrorDialog
        showErrorDialog={ showErrorDialog }
        errorMsg={ errorMsg }
        toggleErrorDialog={ toggleErrorDialog }
      />
    </>
  );
};

export { FreeTaxi };
