import React from 'react';
import { Button, Text, Tooltip } from 'new-ui';
import { getText } from '../../../../../i18n';

import { isSmartAgent } from '../../../../bi/utils/env';
import { getButtonCancelDialogQa, getMainButtonLabel } from '../../../../bi/utils/tripDetails';

import styles from './styles/index.module.css';

interface IButtonTripProps {
  hasDisabled: boolean,
  valueIsEmpty?: boolean,
  disabledTrain?: boolean,
  isCancelTrain?: boolean,
  loading?: boolean,
  isDoubleCancelTrain?: boolean,
  firstWord?: string,
  onCloseDialog(): void,
  onSubmitCancellationModal(text: string | undefined): void,
  onClick?(): void,
  onCancel?(): void,
  isHotel?: boolean,
  isAir?: boolean,
  isTaxiVoucher?: boolean,
  isTransfer?: boolean,
  text?: string,
}

const LABELS = {
  DEMO: getText('components:tripDetailsDialog.button.demo'),
  DEMO_SMARTAGENT: getText('components:tripDetailsDialog.button.demoSmartagent'),
  CLOSE: getText('components:tripDetailsDialog.button.close'),
};

const ButtonTrip = ({
  text = '',
  hasDisabled,
  valueIsEmpty = false,
  disabledTrain = false,
  firstWord = '',
  loading = false,
  isCancelTrain = false,
  isDoubleCancelTrain = false,
  isHotel = false,
  isTaxiVoucher = false,
  isTransfer = false,
  isAir = false,
  onCloseDialog,
  onSubmitCancellationModal,
  onClick = () => {},
  onCancel = () => {},
}: IButtonTripProps) => {
  const mainButtonLabel = getMainButtonLabel(
    isCancelTrain,
    isDoubleCancelTrain,
    isHotel,
    isAir,
    isTaxiVoucher,
    isTransfer,
  );
  const mainButtonType = isDoubleCancelTrain ? 'secondary' : 'primary';
  const activeButton = !hasDisabled && !disabledTrain && !valueIsEmpty;
  const mainButtonQa = getButtonCancelDialogQa(
    isCancelTrain,
    isDoubleCancelTrain,
    isHotel,
    isAir,
    isTaxiVoucher,
    isTransfer,
    activeButton,
  );

  const handleClose = (): void => {
    onCloseDialog();

    if (isDoubleCancelTrain) {
      onCancel();

      return;
    }

    onClick();
  };

  const renderTooltipContent = () => {
    const labelSecondWord = isSmartAgent ? LABELS.DEMO_SMARTAGENT : LABELS.DEMO;

    return (
      <Text
        color='white'
        type='NORMAL_14_130'
        className={ styles.tooltip_content }
      >
        { firstWord }&nbsp;{ labelSecondWord }
      </Text>
    );
  };

  return (
    <div className={ styles.wrapper }>
      <Tooltip
        show={ hasDisabled }
        renderContent={ renderTooltipContent }
      >
        <Button
          loading={ loading }
          disabled={ hasDisabled || disabledTrain || valueIsEmpty }
          type={ mainButtonType }
          onClick={ () => onSubmitCancellationModal(text) }
          qaAttr={ mainButtonQa }
        >
          {mainButtonLabel}
        </Button>
      </Tooltip>
      <Button
        type='textual-medium'
        className={ styles.close_button }
        disabled={ loading }
        onClick={ () => handleClose() }
      >
        { LABELS.CLOSE }
      </Button>
    </div>
  );
};

export { ButtonTrip };
