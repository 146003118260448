import React from 'react';
import { Link } from 'react-router-dom';

import { Text } from 'new-ui';

import { MainAnalytic } from '../../../bi/utils/analytics';
import { momentObject } from '../../../bi/utils/formatDate';

import ROUTES from '../../../bi/constants/routes';
import { DATEFORMATS } from '../../../bi/constants/dateFormats';

import {
  IList,
  ISmartdeskPanelProps,
  TripIndexProps,
  TripsInCalendar,
} from '../../../bi/types/eventsSA';

import styles from '../styles/index.module.css';

const InitialCalendarComponent = ({ tripsInCalendar, setCalendar, handleShowSmartdeskPanel } : ISmartdeskPanelProps) => {
  const initialCalendar = () => {
    const updateListWithNewEmployees = (list: IList, index: number, newEmployees: string[]) => {
      const existingEmployees = new Set(list[index].Employees);
      newEmployees.forEach((employee) => existingEmployees.add(employee));
      const listCopy = [...list];
      listCopy[index].Employees = Array.from(existingEmployees);

      return listCopy;
    };

    const handleClickToCalendarTrip = () => {
      handleShowSmartdeskPanel();
      MainAnalytic.send(
        MainAnalytic.CATEGORY.SMARTDESC,
        MainAnalytic.ACTIONS.SMARTDESC.GOTOTRIPS, {
          label: MainAnalytic.LABELS.SMARTDESC.CALENDAR,
        });
    };

    const renderEmployees = (item: string[]) => {
      const additionalEmployees = item.length > 1 && (<span> +{item.length - 1}</span>);

      return (
        <Text
          type='NORMAL_14'
          className={ styles.employees }
        >
          {item}
          {additionalEmployees}
        </Text>
      );
    };

    const renderTooltip = (item: IList[], trip: TripsInCalendar) => {
      const renderItem = (
        item.map(({ Id, Name, Employees }, ind) => (
          <Link
            to={ `${ROUTES.TRIP.MAIN}/${Id}` }
            onClick={ handleClickToCalendarTrip }
            className={ styles.trip }
            key={ `day_${trip.Id}_${ind}` }
          >
            <Text
              type='SEMIBOLD_14'
              className={ styles.name }
            >
              {Name}
            </Text>
            {renderEmployees(Employees)}
          </Link>
        ))
      );

      return (
        <div className={ styles.wrapper_tooltip }>
          {renderItem}
        </div>
      );
    };

    setCalendar((prevCalendar: { trips: TripsInCalendar }) => ({
      ...prevCalendar,
      trips: {
        ...prevCalendar.trips,
        ...tripsInCalendar.reduce((item: any, trip: TripsInCalendar) => {
          const key = momentObject(trip.CheckInDate).format(DATEFORMATS.DATE);

          if (item[key]) {
            const sameTripIndex = item[key].list.findIndex(({ Id }: TripIndexProps) => Id === trip.Id);
            const newList = (sameTripIndex !== -1 && trip.Employees.length !== 0)
              ? updateListWithNewEmployees(item[key].list, sameTripIndex, trip.Employees)
              : [...item[key].list, trip];

            return {
              ...item,
              [key]: {
                ...item[key],
                list: newList,
              },
            };
          }

          return {
            ...item,
            [key]: {
              list: [trip],
              renderContent: (list: IList[]) => renderTooltip(list, trip),
            },
          };
        }, {}),
      },
    }));
  };

  return initialCalendar();
};

export default InitialCalendarComponent;
