import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfigProd = {
  apiKey: 'AIzaSyC8lvqK6YGE13_oOhPtDo5HNTPjAO9umQg',
  authDomain: 'smartway-2eef4.firebaseapp.com',
  projectId: 'smartway-2eef4',
  storageBucket: 'smartway-2eef4.appspot.com',
  messagingSenderId: '617652047258',
  appId: '1:617652047258:web:a9371dd8b3baaf2e3cfe23',
  measurementId: 'G-4DYRZREJ3S',
};

const firebaseConfigDev = {
  apiKey: 'AIzaSyAoOt2PwsYclnMvYw8HHYZDzJnzwBNxeRc',
  authDomain: 'smartway-dev.firebaseapp.com',
  databaseURL: 'https://smartway-dev.firebaseio.com',
  projectId: 'smartway-dev',
  storageBucket: 'smartway-dev.appspot.com',
  messagingSenderId: '340574210573',
  appId: '1:340574210573:web:d3f9d07a2c66186918e344',
  measurementId: 'G-X76JH5W871',
};

// @ts-ignore
const config = import.meta.env.MODE === 'development' ? firebaseConfigDev : firebaseConfigProd;

const app = initializeApp(config);
const firebaseAnalytics = getAnalytics(app);

export { firebaseAnalytics };
