import React from 'react';
import { MomentInput } from 'moment';

import { getText } from '../../../i18n';

import { formatDate } from '../../bi/utils/formatDate';

import { FARECLASS } from '../../../constants/Request';
import { PATTERN } from '../../bi/constants/dateFormats';

import styles from './styles/request-air-item.module.css';

interface IAirportItem {
  Code: string,
  City: string,
}

interface ISearchOptionsRouteType {
  DepartureAirport: IAirportItem,
  ArrivalAirport: IAirportItem,
  DepartureDate: MomentInput,
  Id: number,
}

interface ISearchOptionsType {
  Routes: ISearchOptionsRouteType[],
  IsDirect: boolean,
  FareClass: string,
}

interface IRequestAirItemProps {
  searchOptions: ISearchOptionsType,
  comment: string,
}

const LABELS = {
  WITHOUT_TRANSFERS: getText('components:requestAirItem.withoutTransfers'),
  WITH_TRANSFERS: getText('components:requestAirItem.withTransfers'),
};

const RequestAirItem = ({ searchOptions, comment }: IRequestAirItemProps) => {
  const pairsStart: number[] = [];

  searchOptions.Routes.reduce((prevItemArrivalAirportCode, item, idx) => {
    if (item.DepartureAirport.Code === prevItemArrivalAirportCode
      && (idx - pairsStart[pairsStart.length - 1] !== 2)) {
      pairsStart.push(idx - 1);
    }

    return item.ArrivalAirport.Code;
  }, searchOptions.Routes[0].ArrivalAirport.Code);

  const renderPair = (idx: number) => {
    const forwardRoute = searchOptions.Routes[idx];
    const backwardRoute = searchOptions.Routes[idx + 1];

    const forwardRouteDepartureDate = formatDate(forwardRoute.DepartureDate, PATTERN.LONG_FULL_YEAR);
    const backwardRouteDepartureDate = formatDate(backwardRoute.DepartureDate, PATTERN.LONG_FULL_YEAR);

    return (
      <div key={ forwardRoute.Id } className={ `${styles.wrap} ${idx > 0 ? styles['additional-route'] : ''}` }>
        <div className={ `${styles.column} ${styles.left}` }>
          <div className={ styles.city }>
            { `${forwardRoute.DepartureAirport.City}, ${forwardRoute.DepartureAirport.Code}` }
          </div>
          <div className={ styles.date }>{ forwardRouteDepartureDate }</div>
        </div>
        <div className={ `${styles.column} ${styles.middle}` }>
          <div><span className={ `${styles.arrow} smartway-exchange` } /></div>
        </div>
        <div className={ `${styles.column} ${styles.right}` }>
          <div className={ styles.city }>
            { `${forwardRoute.ArrivalAirport.City}, ${forwardRoute.ArrivalAirport.Code}` }
          </div>
          <div className={ styles.date }>{ backwardRouteDepartureDate }</div>
        </div>
      </div>
    );
  };

  const airItems = searchOptions.Routes.map((route, idx) => {
    if (pairsStart.indexOf(idx) !== -1) return renderPair(idx);

    if (pairsStart.indexOf(idx - 1) !== -1) return null;

    const departureDate = formatDate(route.DepartureDate, PATTERN.LONG_FULL_YEAR);

    return (
      <div key={ route.Id } className={ `${styles.wrap} ${idx > 0 ? styles['additional-route'] : ''}` }>
        <div className={ `${styles.column} ${styles.left}` }>
          <div className={ styles.city }>
            { `${route.DepartureAirport.City}, ${route.DepartureAirport.Code}` }
          </div>
          <div className={ styles.date }>{ departureDate }</div>
        </div>
        <div className={ `${styles.column} ${styles.middle}` }>
          <div><span className={ `${styles.arrow} smartway-long_arrow_right` } /></div>
        </div>
        <div className={ `${styles.column} ${styles.right}` }>
          <div className={ styles.city }>
            { `${route.ArrivalAirport.City}, ${route.ArrivalAirport.Code}` }
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      { airItems }
      <div>
        <span className={ styles['fare-class'] }>{ FARECLASS[searchOptions.FareClass] }, </span>
        <span className={ styles['is-direct'] }>
          {searchOptions.IsDirect ? LABELS.WITHOUT_TRANSFERS : LABELS.WITH_TRANSFERS}
        </span>
      </div>
      <div className={ styles.comment }>{ comment }</div>
    </div>
  );
};

export default RequestAirItem;
