const TYPERULE = {
  AIRLINERULE: 'AirlineRule',
  TRAINRULE: 'TrainRule',
  HOTELRULE: 'HotelRule',
  TAXIRULE: 'TaxiVoucherRule',
};

const PERMITTYPE = {
  ANY: 'Any',
  COUNTRY: 'Country',
  ROUTES: 'Routes',
  CITIES: 'Cities',
  HOTELS: 'Hotels',
};

const TIMETYPE = {
  ANY: 'Any',
  WEEK: 'Week',
  DAYS: 'Days',
};

const PRICETYPE = {
  ANY: 'Any',
  ONEPRICE: 'OnePrice',
  ROUTES: 'Routes',
};

const TRAVELPOLICYFILTER = {
  NOTAPPLIED: 'NotApplied',
};

const UNIQUE_FORMED_TP = {
  NEW: 'new',
  OLD: 'old',
};

enum TravelPolicyFilter {
  NotApplied = 'NotApplied',
}

export {
  TYPERULE as default,
  TYPERULE,
  PERMITTYPE,
  TIMETYPE, PRICETYPE,
  TRAVELPOLICYFILTER,
  TravelPolicyFilter,
  UNIQUE_FORMED_TP,
};
