import React, { useEffect, useState } from 'react';
import { Button, Icon, IconButton, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { isSmartAgent } from '../../../../bi/utils/env';
import { MainAnalytic } from '../../../../bi/utils/analytics';
import MoneyFormat from '../../../../bi/utils/money';

import { VALID_SYMBLOL } from '../../../../bi/constants/cart';

import ModalFee from '../ModalFee/ModalFee';

import styles from '../../styles/itemLayout.module.css';

const LABELS = {
  FEE_TITLE: getText('cart:addFee:additionalFee:fee'),
  FEE_TOOLTIP: getText('cart:addFee:additionalFee:tooltip'),
  ADD: getText('cart:addFee:additionalFee:add'),
  MODAL: {
    MODAL_SUBTITLE: getText('cart:addFee:additionalFee:modalTitle'),
    INPUT_MODAL: {
      EDIT: getText('cart:addFee:additionalFee:inputModal:edit'),
      ADD: getText('cart:addFee:additionalFee:inputModal:add'),
    },
    BUTTON_DONE: {
      ADD: getText('cart:addFee:additionalFee:buttonDone:add'),
      EDIT: getText('cart:addFee:additionalFee:buttonDone:edit'),
    },
    BUTTON_CANCEL: {
      DELETE: getText('cart:addFee:additionalFee:buttonCancel:delete'),
      CANCEL: getText('cart:addFee:additionalFee:buttonCancel:cancel'),
    },
    CORRECT_VALUE: getText('cart:addFee:additionalFee:correctValue'),
  },
};

interface AdditionalFeeProps {
  fee: number,
  setFee(fe: number): void,
  isTrip: boolean,
  isNote: boolean,
}

const AdditionalFee = ({
  fee,
  setFee,
  isTrip,
  isNote,
}: AdditionalFeeProps) => {
  const dataFee = fee.toString();

  const [value, setValue] = useState(dataFee);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    setValue(dataFee);
  }, [fee]);

  const handleShowEdit = () => {
    setShowEdit(!showEdit);

    if (isTrip) {
      return MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.TRIP_AGENCY_FEE_PRESSED);
    }

    if (isNote) {
      return MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.NOTE_AGENCY_FEE_PRESSED);
    }

    return MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.CARD_AGENCY_FEE_PRESSED);
  };

  const renderDialog = () => {
    const valueCheck = value && value !== VALID_SYMBLOL.ZERO;

    const titleInputModal = valueCheck ? LABELS.MODAL.INPUT_MODAL.EDIT : LABELS.MODAL.INPUT_MODAL.ADD;
    const labelButtonDone = valueCheck ? LABELS.MODAL.BUTTON_DONE.EDIT : LABELS.MODAL.BUTTON_DONE.ADD;
    const labelButtonCancel = valueCheck ? LABELS.MODAL.BUTTON_CANCEL.DELETE : LABELS.MODAL.BUTTON_CANCEL.CANCEL;

    const handleDelete = () => {
      setFee(+VALID_SYMBLOL.ZERO);
      setValue(VALID_SYMBLOL.ZERO);
      setShowEdit(false);

      if (isTrip) {
        return MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.TRIP_AGENCY_FEE_DELETE_PRESSED);
      }

      if (isNote) {
        return MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.NOTE_AGENCY_FEE_DELETE_PRESSED);
      }

      return MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.AGENCY_FEE_DELETE_PRESSED);
    };

    const handleDeleteValue = () => (
      valueCheck ? handleDelete() : setShowEdit(false)
    );

    const handleDone = (data: string) => {
      setFee(+data);
      setValue(data);
      setShowEdit(false);

      if (valueCheck) {
        if (isTrip) {
          return MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.TRIP_AGENCY_FEE_EDIT_PRESSED);
        }

        if (isNote) {
          return MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.NOTE_AGENCY_FEE_EDIT_PRESSED);
        }

        return MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.AGENCY_FEE_EDIT_PRESSED);
      }

      if (isTrip) {
        return MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.TRIP_AGENCY_FEE_ADD_PRESSED);
      }

      if (isNote) {
        return MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.NOTE_AGENCY_FEE_ADD_PRESSED);
      }

      return MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.AGENCY_FEE_ADD_PRESSED);
    };

    const modalValue = valueCheck ? value : '';

    return (
      <ModalFee
        feeValue={ modalValue }
        show={ showEdit }
        title={ titleInputModal }
        label={ LABELS.MODAL.MODAL_SUBTITLE }
        onSend={ handleDone }
        onCancel={ () => setShowEdit(false) }
        onDelete={ handleDeleteValue }
        labelButtonDone={ labelButtonDone }
        labelButtonCancel={ labelButtonCancel }
        correctValueLabel={ LABELS.MODAL.CORRECT_VALUE }
      />
    );
  };

  const renderFee = value !== VALID_SYMBLOL.ZERO && (
    <div
      className={ styles.value_wrapper }
    >
      <div
        className={ styles.fee_value_wrapper }
      >
        <Text
          type='NORMAL_16'
          className={ styles.fee }
          color='green'
        >
          { MoneyFormat.moneyWithDecimal(+value, true) }
        </Text>
        <IconButton
          iconType='menuTrips'
          iconColor='dim_green'
          alternativeDesign={ isSmartAgent }
          onClick={ () => setShowEdit(!showEdit) }
        />
      </div>
    </div>
  );

  const renderAddFee = () => value === VALID_SYMBLOL.ZERO && (
    <div className={ styles.button_wrapper }>
      <div
        className={ styles.button }
        onClick={ handleShowEdit }
      >
        <Button
          icon='smartdeskAir'
          type='textual-medium'
        >
          { LABELS.ADD }
        </Button>
      </div>
    </div>
  );

  return (
    <div className={ styles.fee_wrapper }>
      <div className={ styles.fee_label_wrapper }>
        <div className={ styles.label }>
          <Icon
            type='wallet'
            color='dim_green'
          />
          <Text
            color='gray'
            type='NORMAL_14_120'
            className={ styles.label_text }
          >
            { LABELS.FEE_TITLE }
          </Text>
        </div>
        { renderFee }
        <div className={ styles.add_fee }>
          { renderAddFee() }
        </div>
      </div>
      { renderDialog() }
    </div>
  );
};

export default AdditionalFee;
