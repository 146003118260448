import { action, makeObservable, observable } from 'mobx';
import { getText } from '../../../../i18n';

import { filteredFavoritesByType, filteredFavoritesBySearch } from '../../utils/favorites';

import { SERVICETYPERUMN } from '../../constants/serviceType';

import {
  IFavorite,
  ITab,
  TAB_TYPES,
} from '../../types/favorites';

export interface IFavoritesStore {
  cache: IFavorite[],
  favorites: IFavorite[],
  tabs: ITab[],
  currentTab: TAB_TYPES.All | string,
  loading: boolean,
  searchInput: string,
}

const TAB_ALL = {
  label: getText('services:favorites.all'),
  value: TAB_TYPES.All,
};

class Store {
  constructor() {
    makeObservable(this);
  }

  cache: IFavorite[] = [];
  @observable favorites: IFavorite[] = [];
  @observable tabs: ITab[] = [];
  @observable currentTab: TAB_TYPES.All | string = TAB_TYPES.All;
  @observable loading: boolean = false;
  @observable searchInput: string = '';

  setFavorites = (favorites: IFavorite[]): void => {
    this.favorites = favorites;
  };

  @action
  setLoading = (value: boolean): void => {
    this.loading = value;
  };

  @action
  setSearchInput = (value: string): void => {
    this.searchInput = value;
  };

  setTabs = (tabs: ITab[]): void => {
    let result: ITab[] = [];

    if (tabs.length > 1) {
      result = [TAB_ALL, ...tabs];
    }

    this.tabs = result;
  };

  @action
  setCurrentTab = (value: string): void => {
    this.currentTab = value;
  };

  @action
  filteredFavorites = (): void => {
    this.setFavorites(filteredFavoritesBySearch(filteredFavoritesByType(this.cache, this.currentTab), this.searchInput));
  };

  @action
  loadFavorites = (favorites: IFavorite[]): void => {
    this.favorites = favorites;
    this.cache = favorites;

    this.refreshTabs();

    this.setCurrentTab(TAB_TYPES.All);
    this.setSearchInput('');
  };

  refreshTabs = (): void => {
    const tabsName: object = {};

    this.cache.forEach((item: IFavorite): void => {
      // @ts-ignore
      tabsName[item.Type] = true;
    });

    const arr = Object.keys(tabsName).map(field =>
      ({ label: SERVICETYPERUMN[field], value: field }),
    );

    this.setTabs(arr);
  };

  @action
  removeFavorite = (removeId: string): void => {
    this.cache = this.cache.filter(favorite => favorite.Id !== removeId);
    this.refreshTabs();

    const currentTab = this.tabs.find(tab => tab.value === this.currentTab);

    if (!currentTab) {
      this.setCurrentTab(TAB_TYPES.All);
    }

    this.filteredFavorites();
  };
}

const FavoritesStore = new Store();

export { FavoritesStore, Store as FavoritesStoreType };
