import React from 'react';

import { IconButton } from 'new-ui';

interface IAddButtonProps {
  text: string,
  className?: string,
  qaAttr?: string,
  onClick(): void,
  disabled?: boolean,
}

const AddButton = ({
  text,
  className = '',
  onClick,
  qaAttr = '',
  disabled = false,
}: IAddButtonProps) => {
  const classNames = [];

  if (className) {
    classNames.push(className);
  }

  return (
    <IconButton
      className={ classNames.join(' ') }
      theme='default-red'
      iconType='plusRound'
      onClick={ onClick }
      qaAttr={ qaAttr }
      disabled={ disabled }
    >
      { text }
    </IconButton>
  );
};

export { AddButton };
