import { getText } from '../../../i18n';

const LABELS = {
  SEAT_SELECTION: getText('constants:additionalServices.seatSelection'),
  FOOD: getText('constants:additionalServices.food'),
  ADDITIONAL_BAGGAGE: getText('constants:additionalServices.additionalBaggage'),
  ADDITIONAL_CARRYON: getText('constants:additionalServices.additionalCarryon'),
  EXCESS_BAGGAGE: getText('constants:additionalServices.excessBaggage'),
  CARRIAGE_ANIMAL: getText('constants:additionalServices.carriageAnimal'),
  CARRYING_WEAPONS: getText('constants:additionalServices.carryingWeapons'),
  SPORTS_EQUIPMENT: getText('constants:additionalServices.sportsEquipment'),
  BICYCLE_BAGGAGE: getText('constants:additionalServices.bicycleBaggage'),
  INSURANCE: getText('constants:additionalServices.insurance'),
  CONGRATULATIONS_ON_BOARD: getText('constants:additionalServices.congratulationsOnBoard'),
  OTHER: getText('constants:additionalServices.other'),
};

const ADDITIONAL_SERVICES_CONSTS = [
  { name: LABELS.SEAT_SELECTION, type: 0, fields: ['Number'] },
  { name: LABELS.FOOD, type: 1, fields: [] },
  { name: LABELS.ADDITIONAL_BAGGAGE, type: 2, fields: ['DescriptionBaggage'] },
  { name: LABELS.ADDITIONAL_CARRYON, type: 3, fields: ['Mass'] },
  { name: LABELS.EXCESS_BAGGAGE, type: 4, fields: ['Mass'] },
  { name: LABELS.CARRIAGE_ANIMAL, type: 5, fields: [] },
  { name: LABELS.CARRYING_WEAPONS, type: 6, fields: [] },
  { name: LABELS.SPORTS_EQUIPMENT, type: 7, fields: [] },
  { name: LABELS.BICYCLE_BAGGAGE, type: 8, fields: [] },
  { name: LABELS.INSURANCE, type: 9, fields: [] },
  { name: LABELS.CONGRATULATIONS_ON_BOARD, type: 10, fields: [] },
  { name: LABELS.OTHER, type: 11, fields: ['Description'] },
];

export { ADDITIONAL_SERVICES_CONSTS };
