// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, CircleLoading, Icon, IconButton, PROPS, Select, Text, Tooltip } from 'new-ui';

import { getText } from '../../../i18n';

import {
  bindServices,
  UseServicesReturnType,
} from '../../bi/context/services';
import { servicesProtocolsContext } from '../../bi/context/context';

import { EmployeePassport } from '../EmployeePassport';
import ProjectSelect from './ProjectSelect';
import { EmployeeSuggest } from '../EmployeeSuggest';
import EmployeeBonusCard from '../EmployeeBonusCard';

import { SERVICETYPE } from '../../bi/constants/serviceType';
import PASSPORTS from '../../bi/constants/passport';
import { COMPANY_DEPARTMENT_DIVIDER } from '../../bi/constants/cart';

import { BUYTRIPSACCOUNTRIGHT, EDITEMPLOYEERIGHT } from '../../bi/constants/rights';
import { getEmployeeFullName } from '../../bi/utils/employees';
import { isDepartmentId } from '../../bi/utils/validation';
import { MainAnalytic } from '../../bi/utils/analytics';
import { formatDate } from '../../bi/utils/formatDate';
import { isSmartAgent } from '../../bi/utils/env';

import { LoadingFields } from '../../bi/services/approvalSchemes';

import styles from './styles/index.module.css';

const {
  BUTTON: {
    TYPES: {
      TEXTUAL,
    },
  },
  ICON: {
    TYPES: {
      ADD_EMPLOYEE,
      CLOSE_BUTTONS: {
        DEFAULT,
      },
      QUESTION,
    },
  },
  TEXT: {
    TYPES: {
      NORMAL_14,
      NORMAL_14_130,
      SEMIBOLD_16,
      NORMAL_16_130,
    },
    COLORS: {
      GRAY,
      RED,
      WHITE,
    },
  },
} = PROPS;

const ERRORS = {
  PASSPORT_ERRORS: {
    NODOMESTIC_PASSPORT: getText('components:employeeList.passportErrors.noDomestic'),
    NOFOREIGN_PASSPORT: getText('components:employeeList.passportErrors.noForeign'),
    FOULFOREIGN_PASSPORT: getText('components:employeeList.passportErrors.foulForeign'),
    NODOMESTIC_PASSPORT_SA: getText('components:employeeList.passportErrors.noDomesticSA'),
    NOFOREIGN_PASSPORT_SA: getText('components:employeeList.passportErrors.noForeignSA'),
    FOULFOREIGN_PASSPORT_SA: getText('components:employeeList.passportErrors.foulForeignSA'),
  },
  MOBILE_ERRORS: {
    NO_CORRECT_MOBILE: getText('components:employeeList.mobileErrors.noCorrect'),
    NO_CORRECT_MOBILE_SA: getText('components:employeeList.mobileErrors.noCorrectSA'),
  },
};

const PROJECTTEXT = {
  INCLUDED: getText('components:employeeList.project.included'),
  NOTINCLUDED: getText('components:employeeList.project.notIncluded'),
  LABEL: getText('components:employeeList.project.label'),
};

const LABELS = {
  NOT_REQUIRED: getText('components:employeeList.notRequired'),
  SELECT_EMPLOYEE: getText('components:employeeList.selectEmployee'),
  SELECT_TRAVELER: getText('components:employeeList.selectTraveler'),
  SELECT_DEPARTMENT: getText('components:employeeList.selectDepartment'),
  SELECT_COMPANY: getText('components:employeeList.selectCompany'),
  NO_CORRECT_MOBILE: {
    FIRST: getText('components:employeeList.noCorrectMobile.first'),
    SECOND: getText('components:employeeList.noCorrectMobile.second'),
    SECOND_SA: getText('components:employeeList.noCorrectMobile.secondSA'),
  },
  BIRTHDAY: getText('components:employeeSimpleList.birthday'),
};

const getDocuments = (item, DocumentId) => {
  const documents = [];
  const selectedDocuments = [];
  let currentDocumentId = null;

  if (item && item.Documents) {
    const { Employee, Documents } = item;
    Employee.Documents.forEach((e) => {
      if (Documents.AllowedDocumentTypes.includes(e.Type)) {
        documents.push(e);
      }

      if (e.Selected) {
        selectedDocuments.push(e.Id);
        currentDocumentId = e.Id;
      }
    });
  }

  if (DocumentId && selectedDocuments.includes(DocumentId)) {
    currentDocumentId = DocumentId;
  }

  return {
    documents,
    currentDocumentId,
  };
};

const findProject = (projects, selectedProjectId) => projects.find(project => project.Id === selectedProjectId);

class EmployeeItem extends Component {
  static contextType = servicesProtocolsContext;

  static propTypes = {
    number: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    getEmployees: PropTypes.func.isRequired,
    onSetEmployeeInCartItem: PropTypes.func.isRequired,
    item: PropTypes.object,
    routes: PropTypes.array,
    isCart: PropTypes.bool,
    items: PropTypes.array.isRequired,
    isApprovalHotel: PropTypes.bool,
    projects: PropTypes.array.isRequired,
    enumOfDocuments: PropTypes.object.isRequired,
    workspaceService: PropTypes.object.isRequired,
    settingsService: PropTypes.object.isRequired,
    cloudPaymentService: PropTypes.object.isRequired,
    buyingEmployee: PropTypes.object,
    employeesWithCompanies: PropTypes.array.isRequired,
    isMulti: PropTypes.bool.isRequired,
    isPersonalBonusCards: PropTypes.bool.isRequired,
    showDocument: PropTypes.bool.isRequired,
    isBlockEditEmployees: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    departmentOnly: PropTypes.bool.isRequired,
    mandatoryProject: PropTypes.bool.isRequired,
    optionalProject: PropTypes.bool.isRequired,
    isOptionalTitle: PropTypes.bool.isRequired,
    isLastAddedEmployee: PropTypes.bool,
    isTitle: PropTypes.bool.isRequired,
    handleAddDocument: PropTypes.func.isRequired,
    onHandlesMetrics: PropTypes.object,
    onAddCompany: PropTypes.func.isRequired,
    handleChangeLastAddedEmployee: PropTypes.func,
    onAddEmployee: PropTypes.func.isRequired,
    handleRemoveEmployee: PropTypes.func.isRequired,
    redirectToEmployee: PropTypes.func.isRequired,
    redirectToProject: PropTypes.func.isRequired,
    onChangeProjectSelect: PropTypes.func.isRequired,
    isChoiceForEachEmployee: PropTypes.bool.isRequired,
    isReserved: PropTypes.bool.isRequired,
    readonlyProjects: PropTypes.bool,
    serviceType: PropTypes.string.isRequired,
    onSelectedEmployee: PropTypes.func,
    onSelectedOptionEmployee: PropTypes.func,
    onSelectDocument: PropTypes.func,
    onSelectCompany: PropTypes.func,
    onSelectProject: PropTypes.func,
    onRemoveEmployee: PropTypes.func,
    onRemoveOptionalEmployee: PropTypes.func,
    onAddProject: PropTypes.func,
    highlightNonFilledEmployee: PropTypes.bool,
    handleResetNonFilledItem: PropTypes.func,
    onValidRequest: PropTypes.func,
    withNumber: PropTypes.bool,
    isTravelApprovalAndNoBooking: PropTypes.bool,
    chosenApprovedRequest: PropTypes.object,
    manyEmployees: PropTypes.bool,
    getEmployee: PropTypes.func,
    cartEmployeeBirthday: PropTypes.bool,
    qaAttrPaidSeats: PropTypes.object,
    qaAttr: PropTypes.object,
    setAddedNewEmployee: PropTypes.func,
    addedNewEmployee: PropTypes.object,
    approvedList: PropTypes.array,
    accountSettingsService: PropTypes.object.isRequired,
  };

  static defaultProps = {
    item: null,
    routes: [],
    buyingEmployee: null,
    isCart: false,
    readonlyProjects: false,
    highlightNonFilledEmployee: false,
    isLastAddedEmployee: false,
    isTravelApprovalAndNoBooking: false,
    chosenApprovedRequest: null,
    handleChangeLastAddedEmployee: () => {},
    onSelectedEmployee: () => {},
    onSelectedOptionEmployee: () => {},
    onSelectDocument: () => {},
    onSelectCompany: () => {},
    onSelectProject: () => {},
    onRemoveEmployee: () => {},
    onRemoveOptionalEmployee: () => {},
    onAddProject: () => {},
    onValidRequest: () => {},
    handleResetNonFilledItem: () => {},
    onHandlesMetrics: {
      handleMetricStartSelectEmployee: () => {},
      handleMetricStartDeleteEmployee: () => {},
      handleMetricStartSelectProject: () => {},
      handleMetricStartDeleteProject: () => {},
      handleMetricStartDeleteItem: () => {},
      handleMetricStartSelectTag: () => {},
      endTime: () => {},
    },
    withNumber: false,
    manyEmployees: false,
    cartEmployeeBirthday: false,
    getEmployee: () => {},
    qaAttrPaidSeats: {},
    qaAttr: null,
    setAddedNewEmployee: () => {},
    addedNewEmployee: {},
    approvedList: [],
  };

  services: UseServicesReturnType<'ApprovalSchemes'>;

  constructor(props, ctx) {
    super(props);

    const {
      item,
      workspaceService,
      onAddProject,
      employeesWithCompanies,
      accountSettingsService,
    } = props;

    this.services = bindServices(ctx, ['ApprovalSchemes']);

    const employee = item ? item.Employee : null;
    const mobile = employee ? employee.MobilePhone : null;
    const employeeId = employee ? employee.Id : null;
    const isCorrectMobile = mobile && mobile.charAt(1) === '7' ? (mobile.charAt(2) === '7' || mobile.charAt(2) === '9') : true;

    const cartItem = Array.isArray(employeesWithCompanies) ? employeesWithCompanies.find(({ EmployeeId }) => EmployeeId === employeeId) : null;
    const { documents, currentDocumentId } = getDocuments(item, cartItem?.DocumentId);

    this.travelerOrEmployee = isSmartAgent ? LABELS.SELECT_TRAVELER : LABELS.SELECT_EMPLOYEE;
    this.deleteProject = accountSettingsService.getDeleteProject();

    this.state = {
      hasAdd: false,
      companyLoading: false,
      userLoading: false,
      projectLoading: false,
      inputValue: '',
      isProjectSelectable: false,
      projectName: '',
      employee,
      currentDocumentId,
      documents,
      canAddEmployee: workspaceService.canEditEmployee && workspaceService.canBuyAccount,
      isCorrectMobile,
    };

    if (employee?.ProjectId) {
      onAddProject(employee.Id, employee.ProjectId);
    }
  }

  componentDidMount() {
    const {
      buyingEmployee,
      index,
      chosenApprovedRequest,
      isTravelApprovalAndNoBooking,
      onValidRequest,
      approvedList,
      isReserved,
      item,
      workspaceService,
    } = this.props;

    const { employee } = this.state;

    if ((isTravelApprovalAndNoBooking || workspaceService.canBuyOnlySelf) &&
      !!buyingEmployee &&
      !isReserved &&
      (!item?.Employee || item?.Employee.Id !== buyingEmployee.Id) &&
      index === 0
    ) {
      this.handleAddEmployee(buyingEmployee);
    }

    if (isTravelApprovalAndNoBooking && chosenApprovedRequest && chosenApprovedRequest?.Employees?.length === 1 && approvedList.length === 1) {
      if (employee && chosenApprovedRequest.Employees[0].Id !== employee.Id) {
        this.handleRemoveEmployee(employee).then(() => {
          onValidRequest();

          if (chosenApprovedRequest?.Employees.length === 1) {
            this.handleAddEmployee(chosenApprovedRequest.Employees[0]);
          }
        });
      }

      if (employee && chosenApprovedRequest && chosenApprovedRequest.Employees[0].Id === employee.Id) {
        onValidRequest();
      }

      if (!employee) {
        this.handleAddEmployee(chosenApprovedRequest.Employees[0]).then(() => {
          onValidRequest();
        });
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<any>) {
    const {
      chosenApprovedRequest,
      buyingEmployee,
    } = this.props;

    const {
      chosenApprovedRequest: prevRequest,
      buyingEmployee: prevEmployee,
    } = prevProps;

    if (chosenApprovedRequest?.Id !== prevRequest?.Id || buyingEmployee?.Id !== prevEmployee?.Id) {
      const {
        isTravelApprovalAndNoBooking,
        isReserved,
        item,
        index,
        workspaceService,
      } = this.props;

      if ((isTravelApprovalAndNoBooking || workspaceService.canBuyOnlySelf) &&
        !!buyingEmployee &&
        !isReserved &&
        (!item?.Employee || item?.Employee.Id !== buyingEmployee.Id) &&
        index === 0
      ) {
        this.handleAddEmployee(buyingEmployee);
      }

      this.recalculateEmployee();
    }
  }

  recalculateEmployeeBody = () => {
    const {
      chosenApprovedRequest,
      onValidRequest,
      approvedList,
      isTravelApprovalAndNoBooking,
    } = this.props;

    const { employee } = this.state;

    if (isTravelApprovalAndNoBooking &&
      chosenApprovedRequest &&
      chosenApprovedRequest?.Employees?.length === 1 &&
      approvedList.length === 1
    ) {
      if (employee && chosenApprovedRequest.Employees[0].Id !== employee.Id) {
        this.handleRemoveEmployee().then(() => {
          onValidRequest();

          if (chosenApprovedRequest?.Employees.length === 1) {
            this.handleAddEmployee(chosenApprovedRequest.Employees[0]);
          }
        });
      }

      if (employee && chosenApprovedRequest && chosenApprovedRequest.Employees[0].Id === employee.Id) {
        onValidRequest();
      }

      if (!employee) {
        this.handleAddEmployee(chosenApprovedRequest.Employees[0]).then(() => {
          onValidRequest();
        });
      }
    }
  };

  recalculateEmployee = () => this.services
    .approvalSchemesService
    .netStore
    .withLoaderFlowCoroutine(LoadingFields.recalculateEmployee)(
      this.recalculateEmployeeBody,
    );

  getSelectedProjectId = () => {
    const { item, employeesWithCompanies } = this.props;
    const employee = item ? item.Employee : null;

    if (!employee) {
      return 0;
    }

    return employeesWithCompanies.find(emply => emply.EmployeeId === employee.Id).ProjectId;
  };

  setProjectSelectable = value =>
    this.setState({ isProjectSelectable: value }, this.props.handleResetNonFilledItem);

  handleSwitchEmpty = () => {
    this.setState({
      hasAdd: !this.state.hasAdd,
    }, this.props.handleResetNonFilledItem);
  };

  handleInputChange = value => this.handleFetchRequest(value);

  handleFetchRequest = (value) => {
    const { items, getEmployees } = this.props;

    return getEmployees(value, false).then(res =>
      res.filter(employee =>
        !items.some(item => item.Employee.Id === employee.Id),
      ));
  };

  checkAddCompany = (isMulti, companiesLength, serviceType) => {
    const { index, isTravelApprovalAndNoBooking } = this.props;

    if (serviceType === SERVICETYPE.TRAIN || serviceType === SERVICETYPE.AIR || serviceType === SERVICETYPE.AEROEXPRESS || serviceType === SERVICETYPE.TAXI_VOUCHER) {
      if (!isMulti || (companiesLength === 1 || isTravelApprovalAndNoBooking)) return true;
    }

    if (serviceType === SERVICETYPE.HOTEL || serviceType === SERVICETYPE.TRANSFER) {
      if ((!isMulti || (companiesLength === 1 || isTravelApprovalAndNoBooking)) && Number(index) === 0) return true;
    }

    return false;
  };

  handleAddCompany = (suggestion) => {
    const {
      isTravelApprovalAndNoBooking,
      onAddCompany,
      departmentOnly,
      serviceType,
    } = this.props;

    const { Companies, Id } = suggestion;
    const { CompanyId, Departments } = Companies[0];

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.COMPANY_CHANGED, { service: serviceType });

    if (isTravelApprovalAndNoBooking) {
      if (Departments.length) {
        return onAddCompany(Id, `${CompanyId}${COMPANY_DEPARTMENT_DIVIDER}${Departments[0].Id}`);
      }

      return onAddCompany(Id, suggestion.CompanyId || CompanyId);
    }

    if (departmentOnly) {
      return onAddCompany(Id, `${CompanyId}${COMPANY_DEPARTMENT_DIVIDER}`, true);
    }

    if (Departments?.length === 1) {
      return onAddCompany(Id, `${CompanyId}${COMPANY_DEPARTMENT_DIVIDER}${Departments[0].Id}`);
    }

    return onAddCompany(Id, CompanyId);
  };

  handleAddEmployee = async (suggestion) => {
    const {
      isMulti,
      onAddEmployee,
      serviceType,
      number,
      onSelectedEmployee,
      onSelectedOptionEmployee,
      handleChangeLastAddedEmployee,
      onHandlesMetrics,
      isApprovalHotel,
      setAddedNewEmployee,
    } = this.props;

    this.setState({ userLoading: true });

    const res = await onAddEmployee(suggestion);
    const value = this.state.inputValue.length;

    if (this.checkAddCompany(isMulti, suggestion.Companies.length, serviceType)) {
      this.handleAddCompany(suggestion);
    }

    let newEmployeeWithoutCompanies = null;

    if (isApprovalHotel) {
      newEmployeeWithoutCompanies = { ...suggestion, Companies: [] };
      setAddedNewEmployee(suggestion.Id, true);
    }

    this.setState({
      inputValue: getEmployeeFullName(suggestion),
      hasAdd: false,
      employee: newEmployeeWithoutCompanies || this.state.employee,
    });

    onSelectedEmployee(value);

    handleChangeLastAddedEmployee(suggestion);

    if ((serviceType === SERVICETYPE.HOTEL || serviceType === SERVICETYPE.TRANSFER) && number !== 1) {
      onSelectedOptionEmployee(serviceType);
    }

    this.setState({ userLoading: false });

    onHandlesMetrics.endTime();

    return res;
  };

  createNewEmployee = async () => {
    const {
      redirectToEmployee,
      onHandlesMetrics,
    } = this.props;

    onHandlesMetrics.handleMetricStartSelectEmployee();

    return redirectToEmployee();
  };

  handleSelected = async (suggestion) => {
    const {
      getEmployee,
      onSetEmployeeInCartItem,
      onHandlesMetrics,
      isCart,
      serviceType,
    } = this.props;

    onHandlesMetrics.handleMetricStartSelectEmployee();

    if (!suggestion) {
      return null;
    }

    if (isCart) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.EMPLOYEE_CHANGED, { service: serviceType });

      this.setState({ userLoading: true });

      await onSetEmployeeInCartItem(suggestion.Id);

      this.setState({ userLoading: false });

      return onHandlesMetrics.endTime();
    }

    const employee = await getEmployee(suggestion.Id);

    return this.handleAddEmployee(employee);
  };

  prepareCompanyAndDepartmentValue = ({ CompanyId, DepartmentId }) => {
    if (DepartmentId) {
      return `${CompanyId}${COMPANY_DEPARTMENT_DIVIDER}${DepartmentId}`;
    }

    return CompanyId;
  };

  handleRemoveEmployee = async () => {
    const {
      handleChangeLastAddedEmployee,
      handleRemoveEmployee,
      onRemoveEmployee,
      onRemoveOptionalEmployee,
      onHandlesMetrics,
      serviceType,
      number,
    } = this.props;
    const { employee } = this.state;

    onHandlesMetrics.handleMetricStartDeleteEmployee();

    this.setState({ userLoading: true });

    const res = await handleRemoveEmployee(employee);

    this.setState({
      employee: null,
      documents: [],
      inputValue: '',
    });

    onRemoveEmployee();

    handleChangeLastAddedEmployee(null);

    if ((serviceType === SERVICETYPE.HOTEL || serviceType === SERVICETYPE.TRANSFER) && number !== 1) {
      onRemoveOptionalEmployee();
    }

    this.setState({ userLoading: false });

    onHandlesMetrics.endTime();

    return res;
  };

  handleChangeSelect = (value) => {
    const { documents } = this.state;

    this.setState({
      currentDocumentId: value,
    }, () => {
      this.props.handleAddDocument(this.state.employee.Id, value);
    });

    const document = documents.find(item => item.Id === value);

    if (document) {
      this.props.onSelectDocument(document.Type);
    }
  };

  handleChangeCompany = async (value) => {
    const { onAddCompany, onSelectCompany, serviceType } = this.props;

    this.setState({ companyLoading: true });

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.DEPARTMENT_CHANGED, { service: serviceType });
    const res = await onAddCompany(this.state.employee.Id, value);
    await onSelectCompany(res);

    this.setState({ companyLoading: false });
  };

  handleSelectProject = (id, projectName) => {
    const { onHandlesMetrics, serviceType } = this.props;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.COST_CENTER_CHANGED, { service: serviceType });
    onHandlesMetrics.handleMetricStartSelectProject();

    this.handleChangeSelectedProject(id, projectName);
  };

  handleDeleteProject = (id, projectName) => {
    const { onHandlesMetrics, serviceType } = this.props;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.COST_CENTER_CHANGED, { service: serviceType });
    onHandlesMetrics.handleMetricStartDeleteProject();

    this.handleChangeSelectedProject(id, projectName);
  };

  handleChangeSelectedProject = async (id, projectName) => {
    const { onChangeProjectSelect, onSelectProject, onHandlesMetrics } = this.props;

    this.setState({ projectLoading: true, isProjectSelectable: false, projectName });

    try {
      await onChangeProjectSelect(this.state.employee.Id, id);

      await onSelectProject(id);
    } catch {
      this.setState({ isProjectSelectable: false });
    }

    this.setState({ projectLoading: false });

    onHandlesMetrics.endTime();
  };

  handleAddProject = () => {
    const { redirectToProject } = this.props;
    const { Id: employeeId } = this.state.employee;

    redirectToProject(employeeId);
  };

  handleCheckFoulsInForeignPassport = () => {
    const { item } = this.props;

    return !!item && !!item.errors && !!item.errors.selectedForeignPassportFoul;
  };

  renderEnterEmployee() {
    const {
      chosenApprovedRequest,
      accountSettingsService,
      qaAttr,
    } = this.props;
    const {
      inputValue,
      canAddEmployee,
      userLoading,
    } = this.state;

    const addEmployee = canAddEmployee && !chosenApprovedRequest;

    return (
      <div className={ styles['enter-container'] }>
        { this.renderNumber() }
        <div className={ styles.suggest }>
          <EmployeeSuggest
            onFetch={ this.handleInputChange }
            createNewEmployee={ this.createNewEmployee }
            onSelect={ this.handleSelected }
            userLoading={ userLoading }
            value={ inputValue }
            canAddEmployee={ addEmployee }
            autoFocus
            withFetching
            qaAttrAddEmployee={ qaAttr?.addEmployee || '' }
            qaAttrFirstEl={ qaAttr?.firstEl || '' }
            qaAttrInputEmployee={ qaAttr?.input || '' }
            qaAttrAddEmployeeLoading={ qaAttr?.loadingEmployee || '' }
            qaAttrSelectEmployeeLoading={ qaAttr?.loadingSuggest || '' }
            accountSettingsService={ accountSettingsService }
            debounceMs={ 0 }
          />
        </div>
      </div>
    );
  }

  renderDefault() {
    const {
      isOptionalTitle,
      isTitle,
      isBlockEditEmployees,
      highlightNonFilledEmployee,
      qaAttr,
      manyEmployees,
      index,
    } = this.props;

    const isManyEmployees = () => (qaAttr ? `${qaAttr.select}-${index}` : '');
    const isNotManyEmployees = () => qaAttr?.select || '';

    const qaAttrSelect = manyEmployees ? isManyEmployees() : isNotManyEmployees();

    const title = isOptionalTitle
      ? LABELS.NOT_REQUIRED
      : (() => (isTitle ? this.travelerOrEmployee : ''))();

    const classNames = [styles['default-container']];

    if (highlightNonFilledEmployee && !isOptionalTitle && isTitle) {
      classNames.push(styles.highlight);
    }

    return (
      <div className={ classNames.join(' ') }>
        { this.renderNumber() }
        <div className={ styles['add-employee'] }>
          <Button
            onClick={ this.handleSwitchEmpty }
            type={ TEXTUAL }
            className={ styles.button }
            qaAttr={ qaAttrSelect }
            disabled={ isBlockEditEmployees }
          >
            <Icon
              type={ ADD_EMPLOYEE }
              className={ styles.icon }
              alternativeDesign={ isSmartAgent }
            />
            { title }
          </Button>
        </div>
      </div>
    );
  }

  renderCompanyName = companyName => (
    <Text
      color={ GRAY }
      className={ styles.company }
      qaAttr={ this.props.qaAttr?.company?.selected || '' }
    >
      { companyName }
    </Text>
  );

  renderSingleCompany = ({ ShortCompanyName, CompanyName, Departments }, list, value, currentItem) => {
    const { companyLoading } = this.state;
    const {
      isLastAddedEmployee,
      qaAttr,
    } = this.props;

    if (!Departments.length) {
      return null;
    }

    if (Departments.length === 1 && currentItem && currentItem.DepartmentId) {
      return this.renderCompanyName(list[0].label);
    }

    const selectClassNames = [styles.company];

    if (!isDepartmentId(value) && list.length > 1 && isLastAddedEmployee) {
      selectClassNames.push(styles.highlight);
    }

    if (Departments.length) {
      return (
        <div className={ selectClassNames.join(' ') }>
          <Select
            placeholder={ LABELS.SELECT_DEPARTMENT }
            loading={ companyLoading }
            excludeValue
            value={ value }
            items={ list }
            onChange={ this.handleChangeCompany }
            className={ styles.select }
            qaAttr={ qaAttr?.department?.select || '' }
            qaAttrLabel={ qaAttr?.department?.selectLabel || '' }
            qaAttrLoading={ qaAttr?.department?.selectLoading || '' }
          />
        </div>
      );
    }

    const defaultCompanyName = ShortCompanyName || CompanyName;

    if (defaultCompanyName) return this.renderCompanyName(defaultCompanyName);

    return <div className={ styles['employee-company'] } />;
  };

  renderCompanies() {
    const { employee: { Companies: employeeCompanies, Id }, companyLoading } = this.state;
    const {
      employeesWithCompanies,
      isChoiceForEachEmployee,
      items,
      index,
      serviceType,
      highlightNonFilledEmployee,
      isLastAddedEmployee,
      chosenApprovedRequest,
      qaAttr,
      departmentOnly,
    } = this.props;

    const isEmptyDepartments = employeesWithCompanies.some(({ DepartmentId }) => !DepartmentId);

    if (departmentOnly && !isEmptyDepartments) {
      const itemCompanies = employeeCompanies.map(({ Departments }) => Departments).flat();
      const itemDepartmentsIds = itemCompanies.map(({ Id: id }) => id);
      const generalDepartments = employeesWithCompanies.some(({ DepartmentId }) => itemDepartmentsIds.includes(DepartmentId));

      if (!generalDepartments) return null;
    }

    const isManyEmployees = items.length > 1 && index > 0 && !isChoiceForEachEmployee;

    if ((serviceType !== SERVICETYPE.TRAIN && isManyEmployees)) return null;

    const employeeCompaniesWithDepartments = employeeCompanies.reduce((r, { CompanyId, Departments = [], ShortCompanyName, CompanyName }) => {
      if (Departments.length) {
        return [...r, ...Departments.map(({ Id: departmentId, Name }) =>
          ({ value: `${CompanyId}${COMPANY_DEPARTMENT_DIVIDER}${departmentId}`, label: `${ShortCompanyName || CompanyName} (${Name})` }),
        )];
      }

      return [...r, { value: CompanyId, label: ShortCompanyName || CompanyName }];
    }, []);

    const currentItem = employeesWithCompanies.find(({ EmployeeId }) => Id === EmployeeId);

    const value = currentItem ? this.prepareCompanyAndDepartmentValue(currentItem) : null;

    if (chosenApprovedRequest) {
      const { Employees } = chosenApprovedRequest;
      const selectedEmployee = Employees.find(employee => employee.Id === currentItem.EmployeeId);

      if (!selectedEmployee) {
        return null;
      }

      const { Companies, CompanyId, DepartmentId } = selectedEmployee;
      const { ShortCompanyName, CompanyName, Departments } = Companies.find(company => company.CompanyId === CompanyId);
      const selectedDepartment = Departments?.length ? Departments?.find(department => department.Id === DepartmentId) : null;
      const DepartmentLabel = selectedDepartment ? `(${selectedDepartment?.Name})` : '';

      return this.renderCompanyName(`${ShortCompanyName || CompanyName} ${DepartmentLabel}`);
    }

    if (employeeCompanies && employeeCompanies.length === 1) {
      return this.renderSingleCompany(employeeCompanies[0], employeeCompaniesWithDepartments, value, currentItem);
    }

    const highlightNonFilledCompany = employeeCompaniesWithDepartments.length > 1 && isLastAddedEmployee;

    const wrapClassNames = [styles.company];

    if (!value && (highlightNonFilledEmployee || highlightNonFilledCompany)) {
      wrapClassNames.push(styles.highlight);
    }

    return (
      <div className={ wrapClassNames.join(' ') }>
        <Select
          placeholder={ LABELS.SELECT_COMPANY }
          loading={ companyLoading }
          excludeValue
          value={ value }
          qaAttrLabel={ qaAttr?.company?.selectLabel || '' }
          qaAttrLoading={ qaAttr?.company?.selectLoading || '' }
          items={ employeeCompaniesWithDepartments }
          onChange={ this.handleChangeCompany }
          className={ styles.select }
          qaAttr={ qaAttr?.company?.select || '' }
        />
      </div>
    );
  }

  renderProjects = () => {
    const { cloudPaymentService: { isPersonalPaymentMethod } } = this.props;

    if (isPersonalPaymentMethod) {
      return null;
    }

    const {
      projects,
      readOnly,
      readonlyProjects,
      isReserved,
      mandatoryProject,
      optionalProject,
      serviceType,
      workspaceService: {
        rights: {
          BuyTripAccount,
          EditEmployee,
        },
      },
      settingsService: {
        setInterNameCostCenter,
      },
      highlightNonFilledEmployee,
      qaAttr,
    } = this.props;
    const {
      isProjectSelectable,
      employee: { ProjectId },
      projectName,
      projectLoading,
    } = this.state;

    const isInsurance = serviceType === SERVICETYPE.INSURANCE;

    const selectedProjectId = this.getSelectedProjectId();

    if (readonlyProjects && !selectedProjectId) {
      return null;
    }

    if (!projects.length && !mandatoryProject) return null;

    // нужно разобраться с условиями редактирования
    const editable = !(readOnly || isReserved) || isInsurance;

    if (ProjectId && !this.deleteProject) {
      const selectedProject = findProject(projects, ProjectId);

      return (
        <Text qaAttr={ qaAttr?.project?.selected || '' }>
          { PROJECTTEXT.LABEL }: { selectedProject.Name }
        </Text>
      );
    }

    if (readOnly && !isInsurance) {
      const selectedProject = findProject(projects, selectedProjectId);
      const readonlyText = selectedProject ? `${PROJECTTEXT.LABEL}: ${selectedProject.Name}` : `${PROJECTTEXT.LABEL}: ${PROJECTTEXT.NOTINCLUDED}`;

      return (
        <div className={ styles.project }>
          <Text
            className={ styles.content }
            color={ GRAY }
            qaAttr={ qaAttr?.project?.selected || '' }
          >
            { readonlyText }
          </Text>
        </div>
      );
    }

    if (!isProjectSelectable && !selectedProjectId && !projectLoading) {
      const selectProjectFn = editable ? () => this.setProjectSelectable(true) : () => {};

      const isMandatoryProject = () => qaAttr?.project?.mandatory || '';
      const isNotMandatoryProject = () => qaAttr?.project?.select || '';

      const textProject = mandatoryProject ? PROJECTTEXT.INCLUDED : PROJECTTEXT.NOTINCLUDED;
      const selectProjectAttr = mandatoryProject ? isMandatoryProject() : isNotMandatoryProject();

      const classNames = [styles.content];

      if (highlightNonFilledEmployee && mandatoryProject) {
        classNames.push(styles.highlight);
      }

      return (
        <div className={ styles.project }>
          <div className={ classNames.join(' ') }>
            <Text color={ GRAY } qaAttr={ qaAttr?.project?.selected || '' }>
              { PROJECTTEXT.LABEL }:
            </Text>
            <Button
              className={ styles.button }
              onClick={ selectProjectFn }
              type={ TEXTUAL }
              qaAttr={ selectProjectAttr }
            >
              &nbsp;{ textProject }
            </Button>
          </div>
        </div>
      );
    }

    if (!isProjectSelectable && selectedProjectId) {
      const { Name: selectedProjectName } = findProject(projects, selectedProjectId);

      const deleteProjectButton = editable && (
        <IconButton
          iconType={ DEFAULT }
          onClick={ () => this.handleDeleteProject(0) }
          className={ styles.remove }
          qaAttr={ qaAttr?.project?.delete || '' }
        />
      );

      const icon = projectLoading
        ? <CircleLoading className={ styles.loading } size={ 20 } qaAttr={ qaAttr?.project?.loadingDelete || '' } />
        : deleteProjectButton;

      return (
        <div className={ styles.project }>
          <div className={ styles.content }>
            <Text color={ GRAY }>{ PROJECTTEXT.LABEL }:</Text>
            &nbsp;
            <Text qaAttr={ qaAttr?.project?.selected || '' }>
              { selectedProjectName }
            </Text>
            { icon }
          </div>
        </div>
      );
    }

    if (editable) {
      return (
        <div className={ styles.project }>
          <ProjectSelect
            projects={ projects }
            projectName={ projectName }
            loading={ projectLoading }
            onSelect={ this.handleSelectProject }
            showAdd={ BuyTripAccount !== BUYTRIPSACCOUNTRIGHT.Unavailable && EditEmployee === EDITEMPLOYEERIGHT.All }
            firstAdd={ optionalProject }
            onAddProject={ this.handleAddProject }
            setInterNameCostCenter={ setInterNameCostCenter }
            // useRegisterOutsideClick fix
            onClose={ () => this.setProjectSelectable(true) }
            qaAttr={ qaAttr?.project?.input || '' }
            qaAttrFirstEl={ qaAttr?.project?.firstEl || '' }
            qaAttrLoading={ qaAttr?.project?.loading || '' }
          />
        </div>
      );
    }

    return null;
  };

  renderNoCorrectMobile = () => {
    const { serviceType, workspaceService: { canEditEmployee }, item: { Employee }, enumOfDocuments, redirectToEmployee } = this.props;
    const { isCorrectMobile } = this.state;
    const clickEmployeeError = () => (canEditEmployee ? redirectToEmployee(Employee, enumOfDocuments) : () => {});

    const noCorrectMobileText = isSmartAgent
      ? ERRORS.MOBILE_ERRORS.NO_CORRECT_MOBILE_SA
      : ERRORS.MOBILE_ERRORS.NO_CORRECT_MOBILE;

    const noCorrectMobileTextSecond = isSmartAgent
      ? LABELS.NO_CORRECT_MOBILE.SECOND_SA
      : LABELS.NO_CORRECT_MOBILE.SECOND;

    const classnameIconQuestion = isSmartAgent
      ? styles.questionIcon
      : '';

    const textHtml = (
      <div
        className={ styles.error }
        onClick={ clickEmployeeError }
      >
        <Text
          color={ RED }
          className={ styles.error_text }
        >
          { noCorrectMobileText }
          <Tooltip
            className={ styles.tip }
            renderContent={ () => (
              <div className={ styles.tip_content }>
                <Text
                  type={ NORMAL_14_130 }
                  color={ WHITE }
                >
                  { LABELS.NO_CORRECT_MOBILE.FIRST }
                </Text>
                <Text
                  type={ NORMAL_14_130 }
                  color={ WHITE }
                >
                  { noCorrectMobileTextSecond }
                </Text>
              </div>
            ) }
          >
            <Icon
              className={ classnameIconQuestion }
              alternativeDesign={ isSmartAgent }
              type={ QUESTION }
            />
          </Tooltip>
        </Text>
      </div>
    );

    return !isCorrectMobile && serviceType === SERVICETYPE.AIR ? textHtml : this.renderEmployeePassport();
  };

  renderPassport = () => {
    const { showDocument, item, serviceType } = this.props;
    const { documents } = this.state;

    if (item && showDocument && serviceType !== SERVICETYPE.HOTEL) {
      const haveFoulForeignPassport = this.handleCheckFoulsInForeignPassport();

      return documents.length > 0 && !haveFoulForeignPassport
        ? this.renderNoCorrectMobile()
        : this.renderNoPassport(haveFoulForeignPassport);
    }

    return null;
  };

  allowedOnlyForeignPassport = () => {
    const { item } = this.props;

    return !!item
      && !!item.Documents
      && !!item.Documents.AllowedDocumentTypes
      && item.Documents.AllowedDocumentTypes.length === 1
      && item.Documents.AllowedDocumentTypes[0] === PASSPORTS.FOREIGN_PASSPORT.TYPE;
  };

  renderNoPassport = (haveFoulForeignPassport) => {
    const { workspaceService, item, enumOfDocuments, redirectToEmployee, qaAttrPaidSeats } = this.props;

    let textError;
    let qaAttr;

    if (haveFoulForeignPassport) {
      textError = isSmartAgent
        ? ERRORS.PASSPORT_ERRORS.FOULFOREIGN_PASSPORT_SA
        : ERRORS.PASSPORT_ERRORS.FOULFOREIGN_PASSPORT;

      qaAttr = qaAttrPaidSeats.errors.foulForeignPassport;
    } else {
      const allowedOnlyForeignPassport = this.allowedOnlyForeignPassport();

      const noDomesticPassportText = isSmartAgent
        ? ERRORS.PASSPORT_ERRORS.NOFOREIGN_PASSPORT_SA
        : ERRORS.PASSPORT_ERRORS.NOFOREIGN_PASSPORT;

      const noForgeinPassportText = isSmartAgent
        ? ERRORS.PASSPORT_ERRORS.NODOMESTIC_PASSPORT_SA
        : ERRORS.PASSPORT_ERRORS.NODOMESTIC_PASSPORT;

      textError = allowedOnlyForeignPassport
        ? noDomesticPassportText
        : noForgeinPassportText;

      qaAttr = allowedOnlyForeignPassport
        ? qaAttrPaidSeats.errors.noForeignPassport
        : qaAttrPaidSeats.errors.noDomesticPassport;
    }

    return workspaceService.canEditEmployee ? (
      <div className={ styles.document }>
        <Button
          onClick={ () => redirectToEmployee(item.Employee, enumOfDocuments) }
          type={ TEXTUAL }
          className={ styles.btn }
        >
          <span data-qa={ qaAttr }>{ textError }</span>
        </Button>
      </div>
    ) : <Text color={ RED } className={ styles.document }>{ textError }</Text>;
  };

  renderEmployeePassport = () => {
    const { employee, documents, currentDocumentId } = this.state;
    const { readOnly, enumOfDocuments, serviceType, qaAttr } = this.props;

    return (
      <div className={ styles.document }>
        <EmployeePassport
          employee={ employee }
          documents={ documents }
          enumOfDocuments={ enumOfDocuments }
          currentDocumentId={ currentDocumentId }
          serviceType={ serviceType }
          readOnly={ readOnly }
          onChange={ this.handleChangeSelect }
          qaAttrText={ qaAttr?.passport?.text || '' }
          qaAttrSelect={ qaAttr?.passport?.select || '' }
        />
      </div>
    );
  };

  renderEmployeeAge = (birthday) => {
    if (!this.props.cartEmployeeBirthday) {
      return null;
    }

    const birthdayValue = formatDate(birthday);

    return (
      <Text className={ styles['employee-age'] } type={ NORMAL_14 } color={ GRAY }>
        { LABELS.BIRTHDAY }  { birthdayValue }
      </Text>
    );
  };

  renderBonus = () => {
    const {
      isPersonalBonusCards,
      serviceType,
      routes,
    } = this.props;
    const { employee: { Bonuses } } = this.state;

    if (!isPersonalBonusCards) {
      return null;
    }

    return (
      <div className={ styles['employee-bonus-code'] }>
        <EmployeeBonusCard
          routes={ routes }
          Bonuses={ Bonuses }
          ServiceType={ serviceType }
        />
      </div>
    );
  };

  renderItem() {
    const { employee, userLoading } = this.state;
    const {
      buyingEmployee,
      readOnly,
      isBlockEditEmployees,
      withNumber,
      chosenApprovedRequest,
      isTravelApprovalAndNoBooking,
      qaAttrPaidSeats,
      qaAttr,
      addedNewEmployee,
      isApprovalHotel,
    } = this.props;

    const withAvailablePhone = withNumber && !!employee.MobilePhone;
    const travelApprovalRules = isTravelApprovalAndNoBooking
      && chosenApprovedRequest?.Employees.length === 1
      && chosenApprovedRequest?.Employees[0]?.Id === employee?.Id;

    const phone = withAvailablePhone
      && (
        <Text className={ styles.employee_number } type={ NORMAL_14 }>
          { employee.MobilePhone }
        </Text>
      );

    const canChangeEmployee = !!addedNewEmployee[employee.Id] && isApprovalHotel;
    const realReadOnly = canChangeEmployee ? false : readOnly;
    const deleteContent = (!!buyingEmployee && buyingEmployee.Id) || travelApprovalRules || realReadOnly ? null : (
      <IconButton
        onClick={ this.handleRemoveEmployee }
        iconType={ DEFAULT }
        disabled={ isBlockEditEmployees }
        className={ styles.remove }
        qaAttr={ qaAttr?.removeSelectedEmployee || '' }
      />
    );

    const icon = userLoading
      ? <CircleLoading size={ 20 } className={ styles.loading } qaAttr={ qaAttrPaidSeats.employeeLoading } />
      : deleteContent;

    const birthdayHtml = this.renderEmployeeAge(employee.Birthday);

    const employeeContent = (
      <div className={ styles.employee }>
        <div className={ styles.employee_name }>
          { this.renderNumber() }
          <Text qaAttr={ qaAttrPaidSeats.employee } type={ SEMIBOLD_16 }>
            { getEmployeeFullName(employee) } { birthdayHtml }
            { this.renderBonus() }
          </Text>
          { icon }
        </div>
        { phone }
      </div>
    );

    const passportContent = this.renderPassport();
    const companiesContent = !canChangeEmployee ? this.renderCompanies() : null;
    const projectsContent = this.renderProjects();

    const content = passportContent && companiesContent ? (
      <div className={ styles['two-rows'] }>
        <div className={ styles.row }>
          { employeeContent }
          { passportContent }
          { companiesContent }
        </div>
        { projectsContent }
      </div>
    ) : (
      <div className={ styles['one-row'] }>
        { employeeContent }
        { passportContent }
        { companiesContent }
        { projectsContent }
      </div>
    );

    return (
      <div className={ styles['employee-container'] }>
        { content }
      </div>
    );
  }

  renderNumber = () => {
    const { number } = this.props;

    return (
      <Text className={ styles.number } color={ GRAY } type={ NORMAL_16_130 }>
        { number }.
      </Text>
    );
  };

  render() {
    const { hasAdd, employee } = this.state;

    let html;

    if (employee) {
      html = this.renderItem();
    } else if (hasAdd) {
      html = this.renderEnterEmployee();
    } else {
      html = this.renderDefault();
    }

    return (
      <div className={ styles.wrapper }>
        { html }
      </div>
    );
  }
}

export default EmployeeItem;
