import React, { FC, ReactNode } from 'react';
import { Skeleton } from 'new-ui';

import styles from './styles/index.module.css';

interface CheckboxSkeletonProps {
  className?: string,
  children?: ReactNode,
}

const CheckboxSkeleton: FC<CheckboxSkeletonProps> = ({ className, children }) => {
  const wrapClassNames = [styles['checkbox-skeleton']];

  if (className) {
    wrapClassNames.push(className);
  }

  return (
    <label className={ wrapClassNames.join(' ') } >
      <div className={ styles['svg-wrap'] }>
        <Skeleton width={ 20 } height={ 20 } className={ styles['svg-block'] }/>
      </div>
      <div className={ styles.content }>
        {children}
      </div>
    </label>
  );
};

export { CheckboxSkeleton };
