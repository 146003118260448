import { observable, action, makeObservable } from 'mobx';

import { omit } from '../../utils/common';
import { IUnderageEmployeesItem } from '../../utils/employees';

import { IBaggage, IBaggageItemButton, ISelectedBaggage } from '../../types/airlineAdditionalServicesBaggage';

export class AirlineBaggageStore {
  constructor() {
    makeObservable(this);
  }

  @observable baggageIds: Record<string, IBaggageItemButton[]> = {};
  @observable baggageOffers:Record<string, IBaggage> = {} as Record<string, IBaggage>;
  @observable baggageOffersGetError: Record<string, boolean> = {};
  @observable baggagePrices: Record<string, number[]> = {};
  @observable employeeList: Record<string, IUnderageEmployeesItem[]> = {};
  @observable totalBaggageCost: Record<string, number> = {};
  @observable routes: Record<string, Record<string, number>> = {};
  @observable selectedBaggage: Record<string, ISelectedBaggage> = {} as Record<string, ISelectedBaggage>;
  @observable unselectedBaggageWarning: Record<string, string[]> = {};

  @action
  setBaggageOffers = (baggage: IBaggage | null, ticketId: number) => {
    this.baggageOffers = { ...this.baggageOffers, [ticketId]: baggage };

    if (baggage) {
      const emptyBaggage = {
        SavedPriceItemId: baggage.SavedPriceItemId,
        BaggageRoutes: [],
      };

      this.setSelectedBaggage(emptyBaggage, ticketId);
    }
  };

  @action
  setBaggageOffersGetError = (ticketId: string) => {
    this.baggageOffersGetError[ticketId] = true;
  };

  @action
  setEmployeeList = (employeeList: Record<string, IUnderageEmployeesItem[]>) => {
    this.employeeList = employeeList;
  };

  @action
  setBaggagePrices = (baggagePrices: Record<string, number[]>) => {
    this.baggagePrices = baggagePrices;
  };

  @action
  setRoutes(routes: Record<string, Record<string, number>>) {
    this.routes = routes;
  }

  @action
  setSelectedBaggage(newSelectedBaggage: ISelectedBaggage, ticketId: number) {
    this.selectedBaggage = { ...this.selectedBaggage, [ticketId]: newSelectedBaggage };
  }

  @action
  setUnselectedBaggageWarning = (warnings: string[], ticketId: number) => {
    this.unselectedBaggageWarning = { ...this.unselectedBaggageWarning, [ticketId]: warnings };
  };

  @action
  clearBaggageIds(ticketId: number) {
    this.baggageIds = omit(this.baggageIds, [`${ticketId}`]);
  }

  @action
  clearUnselectedBaggageWarning(ticketId: number) {
    this.unselectedBaggageWarning = omit(this.unselectedBaggageWarning, [`${ticketId}`]);
  }

  @action
  setBaggageIds = (baggage: IBaggageItemButton, ticketId: number) => {
    const filteredBaggage = (this.baggageIds?.[ticketId] || []).filter(baggageIdsItem => {
      if (baggageIdsItem.TravellerId === baggage.TravellerId && baggageIdsItem.routeId === baggage.routeId) {
        return false;
      }

      return true;
    });

    this.baggageIds = {
      ...this.baggageIds,
      [ticketId]: [
        ...filteredBaggage,
        baggage,
      ],
    };
  };

  @action
  setTotalBaggageCost(totalBaggageCost: number, ticketId: number) {
    this.totalBaggageCost[ticketId] = totalBaggageCost;
  }
}

export interface IAirlineBaggageStore {
  baggageIds: Record<string, IBaggageItemButton[]>;
  baggageOffers: Record<string, IBaggage>;
  baggageOffersGetError: Record<string, boolean>;
  baggagePrices: Record<string, number[]>;
  employeeList: Record<string, IUnderageEmployeesItem[]>;
  routes: Record<string, Record<string, number>>;
  selectedBaggage: Record<string, ISelectedBaggage>;
  totalBaggageCost: Record<string, number>;
  unselectedBaggageWarning: Record<string, string[]>;
  clearBaggageIds: (ticketId: number) => void;
  clearUnselectedBaggageWarning: (ticketId: number) => void;
  setBaggageIds: (baggageIds: IBaggageItemButton, ticketId: number) => void;
  setBaggagePrices: (baggagePrices: Record<string, number[]>) => void;
  setBaggageOffers: (baggageOffers: IBaggage | null, ticketId: number) => void;
  setBaggageOffersGetError: (ticketId: string) => void;
  setEmployeeList: (employeeList: Record<string, IUnderageEmployeesItem[]>) => void;
  setRoutes: (routes: Record<string, Record<string, number>>) => void;
  setTotalBaggageCost: (totalBaggageCost: number, ticketId: number) => void;
  setSelectedBaggage: (newSelectedBaggage: ISelectedBaggage, ticketId: number) => void;
  setUnselectedBaggageWarning: (warnings: string[], ticketId: number) => void;
}

export const airlineBaggageStore = new AirlineBaggageStore();
