import React from 'react';
import { IconButton, Text } from 'new-ui';
import { IconColor } from 'new-ui/src/components/Icon/types';
import { getText } from '../../../i18n';

import styles from './styles/index.module.css';

interface ShowOnMapButtonProps {
  onClick(): void,
  style?: any,
  qaAttr?: string,
  iconColor?: IconColor,
}

const LABELS = {
  TITLE: getText('components:showOnMapButton.title'),
};

const ShowOnMapButton = ({
  onClick,
  style = {},
  qaAttr = '',
  iconColor = 'default',
}: ShowOnMapButtonProps) => (
  <div
    data-qa={ qaAttr }
    className={ styles.wrapper }
    onClick={ onClick }
    style={ { ...style } }
  >
    <IconButton iconType='pinsMap' iconColor={ iconColor }>
      <Text type='NORMAL_14' className={ styles.text }>{ LABELS.TITLE }</Text>
    </IconButton>
  </div>
);

export { ShowOnMapButton };
