const DEFAULT_FORM_VALUE = {
  employees: {},
  headOfDepartment: {},
  Name: '',
  Id: null,
  CompanyId: null,
};

const DEPARTMENTS = 'Departments';

export { DEFAULT_FORM_VALUE, DEPARTMENTS };
