import React from 'react';
import { Text } from 'new-ui';

import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';

import parseJsonString from '../../../bi/utils/parseJsonString';
import { formatRangeDateWithSimplicity } from '../../../bi/utils/formatDate';

import { ISourcesItem } from '../../../bi/services/checkout/types';

import styles from './styles/index.module.css';

const CartTrain = (
  item: ISourcesItem,
  employees: string,
  serviceType: string,
) => {
  const { Data, Id } = item;
  const data = parseJsonString(Data);

  if (!data) return null;

  const { StationFrom, StationTo, DateDepartureLocal, DateArrivalLocal, TrainNumber, CarTypeDescription } = data;
  const date = formatRangeDateWithSimplicity(DateDepartureLocal, DateArrivalLocal, { withTime: true });

  return (
    <div key={ `cart_conflict_${Id}` } className={ styles.wrapper }>
      <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.date }>{ date },</Text>
      <Text
        qaAttr={ QA_ATTRIBUTES.cart.duplicate.info }
        className={ styles.capitalize }
        type='NORMAL_14_130'
      >
        { serviceType }
        &nbsp;
        { StationFrom.toLowerCase() }
        &nbsp;
        { StationTo.toLowerCase() },
        &nbsp;
        { TrainNumber },
        &nbsp;
        { CarTypeDescription },
      </Text>
      <Text
        qaAttr={ QA_ATTRIBUTES.cart.duplicate.travellers }
        type='NORMAL_14'
        color='gray'
      >
        { employees }
      </Text>
    </div>
  );
};

export default CartTrain;
