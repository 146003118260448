import React from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../i18n';

import { formatDate } from '../../bi/utils/formatDate';

import { PATTERN } from '../../bi/constants/dateFormats';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('components:busItem.title'),
  LUGGAGE: getText('components:busItem.luggage.name'),
  INCLUEDED: getText('components:busItem.luggage.included'),
  NOT_INCLUEDED: getText('components:busItem.luggage.notIncluded'),
  SEAT: getText('components:busItem.seat'),
  TICKET: getText('components:busItem.ticket'),
};

interface BusItemProps {
  item: {
    Name: string,
    CheckinDate: string,
    CheckoutDate: string,
    HasLuggage: boolean,
    TicketNumber: string,
    SeatNumber: string,
  }
}

const BusItem = ({
  item,
}: BusItemProps) => {
  const checkinDate = `${formatDate(item.CheckinDate, PATTERN.DATE_WITH_YEAR)}, 
  ${formatDate(item.CheckinDate, PATTERN.LOCAL_TIME_PATTERN)}`;

  const checkoutDate = `${formatDate(item.CheckoutDate, PATTERN.DATE_WITH_YEAR)}, 
  ${formatDate(item.CheckoutDate, PATTERN.LOCAL_TIME_PATTERN)}`;

  const isLagggage = item.HasLuggage ? LABELS.INCLUEDED : LABELS.NOT_INCLUEDED;

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.left }>
        <div className={ styles.title } >
          <Text type='SEMIBOLD_16'>{ item.Name }</Text>
        </div>
        <div className={ styles.line }>
          <Text
            color='gray'
            type='NORMAL_16_130'
          >
            {checkinDate} — {checkoutDate}
          </Text>
        </div>
        <div className={ styles.line }>
          <Text color='gray' type='SEMIBOLD_14'>
            { LABELS.LUGGAGE }&nbsp;
          </Text>
          <Text color='gray' type='NORMAL_14'>
            { isLagggage }
          </Text>
        </div>
      </div>
      <div className={ styles.right }>
        <div className={ styles.line }>
          <Text color='gray' type='NORMAL_14_130'>
            { LABELS.TICKET }&nbsp;
          </Text>
          <Text type='NORMAL_14_130'>
            { item.TicketNumber }
          </Text>
        </div>
        <div className={ styles.line }>
          <Text color='gray' type='NORMAL_14_130'>
            { LABELS.SEAT }&nbsp;
          </Text>
          <Text type='NORMAL_14_130'>
            { item.SeatNumber }
          </Text>
        </div>
      </div>
    </div>
  );
};

export { BusItem };
