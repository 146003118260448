import React, { FC, ReactNode } from 'react';

import { StyledWrapper } from 'new-ui';

import styles from './index.module.css';

interface FilterPanelProps {
  children: ReactNode,
}

const FilterPanel: FC<FilterPanelProps> = ({ children }) => (
  <StyledWrapper className={ styles.filters }>
    { children }
  </StyledWrapper>
);

export { FilterPanel };
