import React, { Component } from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { getEmployeeFullNameWithFeatureFlags } from '../../../../bi/utils/employees';

import { Department, ITripItemActualVersion } from '../../../../bi/types/trips';
import { IProjects, OrderEmployees } from '../../../../bi/types/employees';
import { DisplayTripsEmployee } from '../../../../bi/services/accountSettings/types';

import styles from './index.module.css';

interface ITripItemEmployeesProps {
  currentVersion: ITripItemActualVersion,
  companies: Department[],
  projects: IProjects[],
  displayEmployee?: DisplayTripsEmployee | object | any,
  cartEmployeeBirthday?: boolean,
  personalPayment?: boolean,
}

const LABELS = {
  COST_CENTERS: getText('components:tripItem.tripItemEmployees.costCenters'),
};

class TripItemEmployees extends Component<ITripItemEmployeesProps> {
  static defaultProps = {
    displayEmployee: [],
    cartEmployeeBirthday: false,
  };

  renderName = (string: string) => <Text color='gray'>{ string }</Text>;

  renderCompany = () => {
    const { currentVersion: { CompanyId, DepartmentId }, companies, personalPayment } = this.props;

    if (personalPayment) {
      return null;
    }

    if (!CompanyId || !companies || (companies && companies.length === 1 && !DepartmentId)) {
      return null;
    }

    const currentCompany = companies.find(({ CompanyId: Id }) => Id === CompanyId);

    if (!currentCompany) {
      return null;
    }

    const { ShortCompanyName, CompanyName, Departments = [] } = currentCompany;

    if (DepartmentId) {
      const currentDepartment = Departments.find(({ Id }) => Id === DepartmentId);

      return currentDepartment ? this.renderName(`${ShortCompanyName || CompanyName} (${currentDepartment.Name})`) : null;
    }

    return this.renderName(ShortCompanyName || CompanyName);
  };

  renderProject = () => {
    const { currentVersion: { ProjectId }, projects } = this.props;

    if (!ProjectId) {
      return null;
    }

    const currentProject = projects.find(({ Id }) => Id === ProjectId);

    if (!currentProject) {
      return null;
    }

    return (
      <Text color='gray' type='NORMAL_14'>
        { LABELS.COST_CENTERS } — {currentProject.Name}
      </Text>
    );
  };

  renderEmployeeData = (employee: string[]) => employee.map((info, index) => {
    if (index === 0) {
      return (
        <Text type='SEMIBOLD_16' key={ index }>
          { info }
        </Text>
      );
    }

    return (
      <Text type='NORMAL_14' color='gray' key={ index }>
        { info }
      </Text>
    );
  });

  renderEmployeeInRows = (employee: string[]) => (
    <div className={ styles.employeeContainer }>
      {this.renderEmployeeData(employee)}
    </div>
  );

  employeeDecorator = (employee: OrderEmployees, displayFF: string[], showBirthday: boolean | undefined) => {
    const employeesData = getEmployeeFullNameWithFeatureFlags(employee, displayFF, showBirthday);

    if (Array.isArray(employeesData)) return this.renderEmployeeInRows(employeesData);

    return employeesData;
  };

  render() {
    const {
      currentVersion: { Employees },
      displayEmployee = [],
      cartEmployeeBirthday,
    } = this.props;
    // @ts-ignore
    const styleView = displayEmployee.length ? styles.itemFull : styles.item; // TODO: displayEmployee is obj, why array? need to fix

    return (
      <div className={ styles.wrapper }>
        { Employees.map((employee, index) => (
          <div key={ employee.Id } className={ styleView }>
            <div className={ styles.name }>
              <Text color='gray' className={ styles.number }>{ index + 1 }.</Text>
              <Text type='SEMIBOLD_16' className={ styles.text }>
                { this.employeeDecorator(employee, displayEmployee, cartEmployeeBirthday) }
              </Text>
            </div>
            <div className={ styles.content }>
              { this.renderCompany() }
              { this.renderProject() }
            </div>
          </div>
        )) }
      </div>
    );
  }
}

export { TripItemEmployees };
