import React from 'react';
import { Text } from 'new-ui';

import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';

import parseJsonString from '../../../bi/utils/parseJsonString';
import { getAirlineType } from '../../../bi/utils/airline';
import { formatRangeDateWithSimplicity } from '../../../bi/utils/formatDate';
import parseUnix from '../../../bi/utils/parseDateTime';

import { ISourcesItem } from '../../../bi/services/checkout/types';
import { AirSegment, AirRoute } from '../../../bi/types/airline';

import styles from './styles/index.module.css';

const getAirline = (segment: AirSegment) => segment[getAirlineType(segment)].ID;

const CartAir = (
  item: ISourcesItem,
  employees: string,
  serviceType: string,
) => {
  const { Data, Id } = item;
  const data = parseJsonString(Data);

  if (!data) return null;

  const { Routes } = data;

  const routesHtml = (Routes as AirRoute[]).map(({ Segments }, index: number) => {
    const { DepartureTime, DepartureAirport, DepartureCity } = Segments[0];
    const { ArrivalTime, ArrivalAirport, ArrivalCity } = Segments[Segments.length - 1];

    const date = formatRangeDateWithSimplicity(parseUnix(DepartureTime), parseUnix(ArrivalTime), { withTime: true });
    const segmentsOfFlightNumber: AirSegment[] = [];

    Segments.forEach((segment) => {
      if (segmentsOfFlightNumber.every(({ FlightNumber }) => FlightNumber !== segment.FlightNumber)) {
        segmentsOfFlightNumber.push(segment);
      }
    });

    const flightNumbers = segmentsOfFlightNumber.map(segment => `${getAirline(segment)} ${segment.FlightNumber}`).join(', ');

    return (
      <div key={ `cart_conflict_item_${Id}_${index}` }>
        <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.date }>{ date }, { flightNumbers },</Text>
        <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.info } type='NORMAL_14_130'>
          { serviceType } { DepartureAirport.Name } ({ DepartureCity }) &ndash; { ArrivalAirport.Name } ({ ArrivalCity }),
        </Text>
        <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.travellers } type='NORMAL_14' color='gray'>
          { employees }
        </Text>
      </div>
    );
  });

  return (
    <div key={ `cart_conflict_${Id}` } className={ styles.wrapper }>
      { routesHtml }
    </div>
  );
};

export default CartAir;
