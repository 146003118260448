import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'new-ui';

import ROUTES from '../../bi/constants/routes';

import AirlineService from '../../bi/services/airline';

import { isWithoutMentionSmartway } from '../../bi/utils/companies';
import { isSmartAgent } from '../../bi/utils/env';
import { MainAnalytic } from '../../bi/utils/analytics';

interface MainHeaderLogoProps {
  styles: CSSModuleClasses;
  img: string;
  name: string;
  iconSmartway: string | null;
  airlineService: AirlineService,
}

const MainHeaderLogo = ({
  styles,
  img,
  name,
  iconSmartway,
  airlineService,
}: MainHeaderLogoProps) => {
  const renderHelp = () => !isSmartAgent && !isWithoutMentionSmartway(name) && (
  // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <a href={ ROUTES.SMARTWAY_WIKI } target='_blank' rel='noreferrer'>
      <Icon type='question' />
    </a>
  );

  const handleAnalytics = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MENU.LOGO);

    const { isSkeleton } = airlineService.getSearchStore();

    // чтобы не пробрасывать ff - создал константу тут
    const flagNewSmartdesk = false;

    if (isSkeleton && isSmartAgent && flagNewSmartdesk) {
      window.location.reload();
    }
  };

  if (iconSmartway) {
    return (
      <div className={ styles.logoContainer }>
        <Link
          to={ ROUTES.SMARTDESK }
          onClick={ handleAnalytics }
        >
          <img
            src={ iconSmartway }
            className={ styles.logoSmartway }
            alt={ name }
            title={ name }
          />
          <img
            src={ img }
            className={ styles.iconSmartway }
            alt={ name }
            title={ name }
          />
        </Link>
        { renderHelp() }
      </div>
    );
  }

  return (
    <div className={ styles.logoContainer }>
      <Link
        to={ ROUTES.SMARTDESK }
        onClick={ handleAnalytics }
      >
        <img
          src={ img }
          className={ styles.logo }
          alt={ name }
          title={ name }
        />
      </Link>
      { renderHelp() }
    </div>
  );
};

export { MainHeaderLogo };
