import React, { useState } from 'react';
import { LinkButton, Text } from 'new-ui';

import styles from '../styles/airline.module.css';

const SorryAirline = () => {
  const [show, setShow] = useState(false);

  const handleToggleShow = () => {
    if (show) {
      return setShow(false);
    }

    return setShow(true);
  };

  const moreText = show && (
    <div className={ styles.texts }>
      <Text type='NORMAL_16_130' className={ styles.text }>
        С 01 июля мы переходим на новую технологию выписки билетов Аэрофлота. Это требование компании Аэрофлот для всех агентов, если они хотят продавать билеты по цене как на официальном сайте, без наценок.
      </Text>
      <Text type='NORMAL_16_130' className={ styles.text }>
        К сожалению, Аэрофлот предупредил всех агентов о необходимости перехода на новую технологию всего за 3 дня, поэтому в период с 1 июля по 1 августа цена на некоторые билеты Аэрофлота может отличаться от сайта авиакомпании и обмен/возврат таких билетов может быть дольше обычного.
      </Text>
      <Text type='NORMAL_16_130' className={ styles.text }>
        Со своей стороны мы приложим все усилия, чтобы переход никак не отразился на цене авиабилетов и на качестве нашего сервиса.
      </Text>
      <Text type='NORMAL_16_130' className={ styles.text }>
        Благодарим вас за понимание.
      </Text>
    </div>
  );

  const moreButton = show ? 'скрыть' : 'подробнее';

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.general }>
        <Text type='NORMAL_18'>
          Важная информация по билетам авиакомпании Аэрофлот&nbsp;
        </Text>
        <LinkButton theme='blue' onClick={ handleToggleShow }>
          { moreButton }
        </LinkButton>
      </div>
      { moreText }
    </div>
  );
};

export { SorryAirline };
