import React, { useRef } from 'react';
import { Button, Dialog, Text } from 'new-ui';
import { getText } from '../../../i18n';

import { IntroVideo } from './components/introVideo';

import styles from './index.module.css';

interface IIntroDialog {
  show: boolean,
  onChange(value: boolean): void,
  label: string,
  path: string,
}

const LABELS = {
  CLOSE: getText('common:close'),
};

const IntroDialog = ({
  show,
  onChange,
  label,
  path,
}: IIntroDialog) => {
  const videoRef: React.MutableRefObject<HTMLVideoElement | null> = useRef(null);

  const handleClose = (value: boolean): void => {
    videoRef?.current?.pause();
    onChange(value);
  };

  return (
    <Dialog
      show={ show }
      showClosing
      outsideClickClosing={ false }
      className={ styles.dialog }
      onChange={ handleClose }
    >
      <div className={ styles.wrapper }>
        <Text type='bold_32' className={ styles.title }>
          {label}
        </Text>

        <div className={ styles.content }>
          <IntroVideo ref={ videoRef } path={ path } />
        </div>

        <div className={ styles.actions }>
          <Button
            type='primary'
            onClick={ () => handleClose(false) }
          >
            {LABELS.CLOSE}
          </Button>

        </div>
      </div>
    </Dialog>
  );
};

export { IntroDialog };
