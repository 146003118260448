const strijTrains = [
  '702Н',
  '704Н',
  '706Н',
  '708Н',
  '710Н',
  '712Н',
  '714Н',
  '701Н',
  '703Н',
  '705Н',
  '707Н',
  '709Н',
  '711Н',
  '713Н',
  '013М',
  '014М',
  '705М',
  '701М',
  '703М',
  '707М',
  '709М',
  '713В',
];

const isStrij = ({ Number: trainNumber }: any) => strijTrains.includes(trainNumber);

export { isStrij, strijTrains };
