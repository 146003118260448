import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Button, IconButton } from 'new-ui';

import { getText } from '../../../i18n';

import { MainAnalytic } from '../../bi/utils/analytics';

import { DEFAULT_ALT } from './const';

import { IContextAdvertising } from './types';

import styles from './styles/index.module.css';

const LABELS = {
  APPLICATION: getText('event:context'),
};

const ContextAdvertising = ({
  image,
  onClick,
  onClose,
  altName = DEFAULT_ALT,
  classname = '',
  classnameBtn = '',
  amplitude = '',
}: IContextAdvertising) => {
  const wrapper = clsx(styles.wrapper, classname);
  const wrapperBtn = clsx(styles.btn, classnameBtn);

  useEffect(() => {
    MainAnalytic.sendAmplitude(amplitude);
  }, []);

  return (
    <div className={ wrapper }>
      <div className={ styles.wrapper_button } >
        <IconButton
          className={ styles.button }
          onClick={ (e: React.MouseEvent) => {
            e.stopPropagation();
            onClose();
          } }
          iconType='closeButton'
          theme='default-red'
        />
      </div>
      <div className={ styles.wrapper_button }>
        <Button
          type='black'
          className={ wrapperBtn }
          onClick={ () => onClick() }
        >
          { LABELS.APPLICATION }
        </Button>
      </div>
      <img
        className={ styles.wrapper_image }
        src={ image }
        alt={ altName }
      />
    </div>
  );
};

export default ContextAdvertising;
