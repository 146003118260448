import React, { FC } from 'react';
import { Text } from 'new-ui';
import { getText, getTextArray } from '../../../i18n';

import toDecline from '../../bi/utils/toDecline';
import { formatDate, momentObject, momentSubPeriod } from '../../bi/utils/formatDate';

import { DATEFORMATS } from '../../bi/constants/dateFormats';
import { FLIGHT_TYPE_FORMAT } from '../../bi/constants/vipHall';

import { IVipHallJsonData } from '../../bi/types/vipHall';

import styles from './styles/index.module.css';

const ITEMS = {
  DEPARTURE: 'Departure',
};

const LABELS = {
  CANCEL_TRIP: getText('components:vipHall.cancelTrip'),
  HOURS: getTextArray('components:vipHall.hours'),
  NO_MONEY_BACK: getText('components:vipHall.noCancel'),
  FLIGHT_TYPE: getText('components:vipHall.flightType'),
  DOMESTIC: getText('components:vipHall.domestic'),
  INTERNATIONAL: getText('components:vipHall.international'),
  ALL: getText('components:vipHall.all'),
  NAME: getText('components:vipHall.name'),
  FLIGHT_DATE: getText('components:vipHall.flightDate'),
};

interface VipHallItemProps {
  item: IVipHallJsonData,
}
const VipHallItem: FC<VipHallItemProps> = ({ item }) => {
  const {
    TripType,
    CancellationTime,
    Name,
    Flight,
  } = item;

  const transitPlace = Flight.find(({ IsTransit }) => IsTransit);

  const tripType = () => {
    switch (TripType) {
      case FLIGHT_TYPE_FORMAT.DOMESTIC:
        return LABELS.DOMESTIC;
      case FLIGHT_TYPE_FORMAT.INTERNATIONAL:
        return LABELS.INTERNATIONAL;

      default:
        return LABELS.ALL;
    }
  };

  const flightCityAndName = Flight.map(({ DepartureAirport, ArrivalAirport, Type }) => {
    if (Type === ITEMS.DEPARTURE) {
      return `${DepartureAirport.City}, ${DepartureAirport.Name}`;
    }

    return `${ArrivalAirport.City}, ${ArrivalAirport.Name}`;
  });

  const renderCanceledDate = () => {
    if (CancellationTime) {
      return (
        <Text type='NORMAL_14' color='gray' className={ styles.class }>
          { LABELS.CANCEL_TRIP }{' '}
          { CancellationTime } { toDecline(CancellationTime, LABELS.HOURS) }
        </Text>
      );
    }

    return (
      <Text type='NORMAL_14' color='gray' className={ styles.class }>{ LABELS.NO_MONEY_BACK }</Text>
    );
  };

  const renderPlaces = () => (
    Flight.length > 1
      ? `${transitPlace?.DepartureAirport.City}, ${transitPlace?.DepartureAirport.Name}`
      : flightCityAndName
  );

  const canceledDate = renderCanceledDate();
  const flightDate = Flight[0].Type === ITEMS.DEPARTURE
    ? momentSubPeriod(Flight[0].Date, 4, 'h')
    : momentObject(Flight[0].Date);
  const flightDateByType = Flight.length > 1 ? Flight.find(flightItem => flightItem.IsTransit)?.Date : flightDate;

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.row }>
        <div>
          <Text type='bold_18'>{ renderPlaces() }</Text>
        </div>
      </div>
      <Text type='NORMAL_14' color='gray' className={ styles['main-info'] }>
        { LABELS.NAME }: { Name }
      </Text>
      <Text type='NORMAL_14' color='gray' className={ styles['main-info'] }>
        { LABELS.FLIGHT_TYPE }: { tripType() }
      </Text>
      <Text type='NORMAL_14' color='gray' className={ styles.info }>
        { LABELS.FLIGHT_DATE }: { formatDate(flightDateByType, DATEFORMATS.DATE) }
      </Text>
      { canceledDate }
    </div>
  );
};
export default VipHallItem;
