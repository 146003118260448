import React from 'react';
import { Button, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import styles from './styles/index.module.css';

interface ButtonFiltersProps {
  onClick(): void,
  counter: number,
}

const LABELS = {
  FILTERS: getText('settings:employees.allFilters.filters'),
};

const ButtonFilters = ({
  onClick,
  counter,
}: ButtonFiltersProps) => (
  <Button
    className={ styles.wrapper }
    onClick={ () => onClick() }
    type='textual-medium-with-icon'
    badge={ counter }
    icon='filters'
  >
    <Text>
      { LABELS.FILTERS }
    </Text>
  </Button>
);

export { ButtonFilters };
