import React from 'react';

import { Text, Tooltip } from 'new-ui';
import { getText } from '../../../i18n';

import styles from './index.module.css';

const letters =
    ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Э', 'Ю', 'Я', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

const TOOLTIP = {
  PREV_PAGE: getText('settings:employees.alphabet.prevPage'),
  NEXT_PAGE: getText('settings:employees.alphabet.nextPage'),
};

const FIELDS = {
  PREV: 'prev',
  NEXT: 'next',
};

interface IAlphabet {
  anchorPrefix: string,
  activeLetters?: string[],
  className?: string,
  pagesCount: number,
  currentPage: number,
  onChangePage(value: number): void,
}

const Alphabet = ({
  anchorPrefix,
  activeLetters = [],
  className = '',
  pagesCount,
  currentPage,
  onChangePage,
}:IAlphabet) => {
  const classNameWrap = [styles.alphabet];

  if (className) {
    classNameWrap.push(className);
  }

  const renderLetterItem = (letter: string) => (
    activeLetters.includes(letter)
      ? (
        <div className={ `${styles.item} ${styles['included-item']}` } key={ letter }>
          <a href={ anchorPrefix + letter }>{ letter }</a>
        </div>
      )
      : null
  );

  const handleClickPageContext = (isLastPage: boolean, field: string) => (isLastPage || field === FIELDS.PREV ? () =>
    onChangePage(currentPage - 1) : () => onChangePage(currentPage + 1));

  const pageTooltip = (isLastPage: boolean, field: string) =>
    (isLastPage || field === FIELDS.PREV ? TOOLTIP.PREV_PAGE : TOOLTIP.NEXT_PAGE);

  const countMoreOne = pagesCount > 1;
  const rulesForPrevEllipsis = countMoreOne && currentPage !== 1;

  const renderEllipsis = (field: string) => {
    const isLastPage = currentPage === pagesCount;

    return (
      <div
        className={ `${styles.item} ${styles['included-item']}` }
        onClick={ handleClickPageContext(isLastPage, field) }
      >
        <Tooltip
          className={ styles['tooltip-wrap'] }
          renderContent={ () =>
            <Text
              className={ styles.tooltip }
              color='white'
            >
              { pageTooltip(isLastPage, field) }
            </Text>
          }
        >
          <Text>
            ...
          </Text>
        </Tooltip>
      </div>
    );
  };

  if (activeLetters.length < 2) return null;

  return (
    <div className={ classNameWrap.join(' ') }>
      { rulesForPrevEllipsis && renderEllipsis(FIELDS.PREV) }
      { letters.map(renderLetterItem) }
      { countMoreOne && renderEllipsis(FIELDS.NEXT) }
    </div>
  );
};

export { Alphabet };
