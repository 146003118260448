import React, { FC, useState } from 'react';

import { Button, Dialog, Text, IconButton } from 'new-ui';

import { getText } from '../../../i18n';

import { isSmartAgent } from '../../bi/utils/env';

import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { DocumentsType, DocumentsTypeForSave, FileType } from '../../bi/services/expenseReports/types';

import vectorImg from './images/Vector.png';
import vectorImgSmartAgent from './images/VectorSmartAgent.png';

import styles from './styles.module.css';

const BYTES_IN_MB = 1024000;
const TYPE = 'file';

const LABELS = {
  DOWNLOAD_DOC: getText('components:uploadDocument.download'),
  ADD_DOC: getText('components:uploadDocument.addFile'),
  SELECT_FILE: getText('components:uploadDocument.selectFile'),
};

interface DownloadDialogType {
  item?: DocumentsType | FileType | null,
  show: boolean,
  onClose(): void,
  onSave(preparedFile: DocumentsTypeForSave): void,
  onFileAdded(selectFile: FileType): void,
}

const DownloadDialog: FC<DownloadDialogType> = ({
  item = null,
  show,
  onClose,
  onSave,
  onFileAdded,
}) => {
  const [selected, setSelected] = useState(item);
  const [inputRef, setInputRef] = useState(null);

  const openFileManager = () => {
    if (inputRef) {
      // @ts-ignore
      inputRef.click();
    }
  };

  const handleAddFile = (selectFile: FileType) => {
    const { name, size } = selectFile;

    setSelected(selectFile);
    const preparedFile = {
      FileName: name,
      FileSize: `${(size / BYTES_IN_MB).toFixed()} МБ`,
    };

    onSave(preparedFile);
    onFileAdded(selectFile);
    setSelected(null);
  };

  // @ts-ignore
  const selectFile = ({ target: { files } }) => {
    const hasNoFiles = !files.length;

    if (hasNoFiles || selected) {
      return;
    }

    handleAddFile(files[0]);
  };

  const handleDragOver = (event: { preventDefault: () => any }) => event.preventDefault();

  const handleDrop = (event: { preventDefault?: any; dataTransfer?: any; }) => {
    event.preventDefault();

    const { dataTransfer: { files } } = event;
    const hasNoFiles = !files.length;

    if (hasNoFiles || selected) {
      return;
    }

    handleAddFile(files[0]);
  };

  const settInputRef = (ref: React.SetStateAction<null>) => {
    if (ref) {
      setInputRef(ref);
    }
  };

  const vectorImage = isSmartAgent ? vectorImgSmartAgent : vectorImg;

  const renderInput = () => (
    <input
      className={ styles.input }
      type={ TYPE }
      // @ts-ignore
      ref={ settInputRef }
      onChange={ selectFile }
    />
  );

  return (
    <Dialog
      qaAttr={ QA_ATTRIBUTES.expenseReport.item.document.close }
      showClosing
      show={ show }
      className={ styles.wrapper }
      onChange={ onClose }
      isForm
    >
      <Text qaAttr={ QA_ATTRIBUTES.expenseReport.item.document.title } type='bold_20'>
        { LABELS.DOWNLOAD_DOC }
      </Text>
      <div
        className={ styles.field }
        onDragOver={ handleDragOver }
        onDrop={ handleDrop }
      >
        { renderInput() }
        <div className={ styles.folder }>
          <img src={ vectorImage } alt='vector' className={ styles.image } />
          <IconButton
            iconType='arrowsUp'
            iconColor='blue'
            className={ styles.arrow }
            alternativeDesign={ isSmartAgent }
          />
        </div>
        <Text type='NORMAL_12' className={ styles.text } color='gray'> { LABELS.ADD_DOC } </Text>
        <Button type='secondary' className={ styles.button } onClick={ openFileManager }>
          { LABELS.SELECT_FILE }
        </Button>
      </div>
    </Dialog>
  );
};

export default DownloadDialog;
