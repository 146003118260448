import { toJS } from 'mobx';

export interface CompanySetting {
  companyId: number | null,
  isMandatory: boolean,
  prohibitionVouchers: boolean,
  removeFlightCertificate: boolean,
  freeFlightCertificate?: boolean,
}

export enum SettingsType {
  isMandatory = 'isMandatory',
  prohibitionVouchers = 'prohibitionVouchers',
  removeFlightCertificate = 'removeFlightCertificate',
}

export interface CompaniesSettings {
  companiesSettings: CompanySetting[],
  CompanyId: number,
  setting?: SettingsType,
}

export interface EmployeeCartItems {
  Id: number,
  EmployeeId: number,
  CartItemId: number,
  CompanyId: number | null,
  ProjectId: number | null,
  DepartmentId: number | null,
  DocumentId: number,
}

const getTransformationCompanySettings = ({ companiesSettings, CompanyId, setting }: CompaniesSettings) => {
  if (!companiesSettings.length) return false;

  const company = companiesSettings.find(({ companyId }) => CompanyId === companyId);

  if (setting) return company![setting];

  return toJS(company);
};

const getFreeFlightCertificate = (companiesSettings: CompanySetting[], CompanyId: number) => {
  const currentCompany = companiesSettings && companiesSettings.find(item => item.companyId === CompanyId);

  return !!currentCompany?.freeFlightCertificate;
};

const getFreeFlightCertificateInCart = (
  companiesSettings: CompanySetting[],
  travelersCount: number,
  employeeCartItems: EmployeeCartItems[],
) => {
  if (!companiesSettings || !companiesSettings.length) return false;

  const isNotFreeFlightCertificateAll = companiesSettings.every(({ freeFlightCertificate }) => !freeFlightCertificate);

  if (isNotFreeFlightCertificateAll) return false;

  const hasNotCompanyIdSomeone = employeeCartItems.some(({ CompanyId }) => !CompanyId);

  if (hasNotCompanyIdSomeone || employeeCartItems.length < travelersCount) return true;

  const currentCompanies = companiesSettings.filter(({ companyId }) => employeeCartItems.find(item => item.CompanyId === companyId));

  return currentCompanies.some(({ freeFlightCertificate }) => freeFlightCertificate);
};

export {
  getTransformationCompanySettings,
  getFreeFlightCertificate,
  getFreeFlightCertificateInCart,
};
