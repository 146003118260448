import React, { FC } from 'react';
import { Skeleton } from 'new-ui';

import styles from '../../PriceSlider/styles/index.module.css';

interface PriceSliderSkeletonI {
  width?: number
}

const PriceSliderSkeleton: FC<PriceSliderSkeletonI> = ({ width = 276 }) => (
  <div className={ styles.wrapper }>
    <Skeleton width={ width } height={ 10 } className={ styles.slider }/>
    <div className={ styles.description }>
      <div className={ styles.input }>
        <Skeleton width={ 15 } height={ 10 } className={ styles.text }/>
        <Skeleton width={ width / 2.4 } height={ 38 } className={ styles['side-padding-fix'] } />
      </div>
      <div className={ styles.input }>
        <Skeleton width={ 15 } height={ 10 } className={ styles.text }/>
        <Skeleton width={ width / 2.4 } height={ 38 } className={ styles['side-padding-fix'] } />
      </div>
    </div>
  </div>
);

export { PriceSliderSkeleton };
