import React from 'react';
import { Dialog, Text, Button } from 'new-ui';

import { getText } from '../../../i18n';

import { formatDate } from '../../bi/utils/formatDate';

import { PATTERN } from '../../bi/constants/dateFormats';
import { TAXI_CONFLICTS_TYPE } from '../../bi/constants/checkout';

import styles from './index.module.css';

interface ConflictType {
  CartItemId: number;
  Type: string;
}

interface TaxiConflictDialogProps {
  showWrongStartDate: boolean;
  showWrongBothDates: boolean;
  sources: any;
  conflicts: ConflictType[];
  onClose(field: string, value: boolean): void;
  onBack(): void;
  onBackTaxi(): void;
}

const LABELS = {
  BACK: getText('components:taxiDialog.cancel'),
  CANCEL: getText('components:taxiDialog.continue'),
  TITLE: getText('components:taxiDialog.title'),
  WRONG_START_DATE: getText('components:taxiDialog.wrongStartDate'),
  WRONG_START_AND_END_DATE: (date: string) => getText('components:taxiDialog.wrongStartAndEndDates', { date }),
  NEW_VOUCHER: getText('components:taxiDialog.newVoucher'),
  CONTINUE_WITHOUT_ISSUING: getText('components:taxiDialog.continueWithoutIssuing'),
};

const TaxiConflictDialog = ({
  showWrongStartDate,
  showWrongBothDates,
  sources,
  conflicts,
  onClose,
  onBack,
  onBackTaxi,
}: TaxiConflictDialogProps) => {
  const renderDialogError = (show: boolean, closeType: string, contentText: string, labelBack: string, labelContinue: string, onBackButton: () => void) => (
    <Dialog show={ show } showClosing onChange={ () => onClose(closeType, false) } outsideClickClosing={ false }>
      <div className={ styles.wrap }>
        <Text
          className={ styles.title }
          type='bold_20'
        >
          { LABELS.TITLE }
        </Text>
        { contentText }
        <div className={ styles.action }>
          <Button
            type='primary'
            onClick={ onBackButton }
          >
            { labelBack }
          </Button>
          <Button
            className={ styles.cancel }
            type='textual-medium'
            onClick={ () => onClose(closeType, false) }
          >
            { labelContinue }
          </Button>
        </div>
      </div>
    </Dialog>
  );

  const renderError = () => conflicts.map(({ CartItemId, Type }) => {
    const data = sources.find(({ Id }: { Id: number }) => Id === CartItemId)?.Data || '';
    const dataParse = JSON.parse(data);
    const startDate = formatDate(dataParse.StartDate, PATTERN.DAY_OF_MONTH_WITH_YEAR);
    const endDate = formatDate(dataParse.EndDate, PATTERN.DAY_OF_MONTH_WITH_YEAR);

    switch (Type) {
      case TAXI_CONFLICTS_TYPE.WRONG_START_DATE:
        return renderDialogError(showWrongStartDate, TAXI_CONFLICTS_TYPE.DIALOG_WRONG_START_DATE, LABELS.WRONG_START_DATE, LABELS.BACK, LABELS.CANCEL, onBack);

      case TAXI_CONFLICTS_TYPE.WRONG_BOTH_DATES:
        return renderDialogError(showWrongBothDates, TAXI_CONFLICTS_TYPE.DIALOG_WRONG_BOTH_DATES, LABELS.WRONG_START_AND_END_DATE(`${startDate} - ${endDate}`), LABELS.NEW_VOUCHER, LABELS.CONTINUE_WITHOUT_ISSUING, onBackTaxi);

      default: return null;
    }
  });

  return renderError();
};

export { TaxiConflictDialog };
