const COUNTRIES = {
  RU: {
    SHORTNAME: 'RU',
    SHORTNAMELOWERCASE: 'ru',
  },
  BY: {
    SHORTNAME: 'BY',
    SHORTNAMELOWERCASE: 'by',
  },
  UA: {
    SHORTNAME: 'UA',
    SHORTNAMELOWERCASE: 'ua',
  },
  KZ: {
    SHORTNAME: 'KZ',
    SHORTNAMELOWERCASE: 'kz',
  },
};

export default COUNTRIES;
