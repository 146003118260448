import React, { Component } from 'react';
import { Button, Dialog, Text } from 'new-ui';

import { getText } from '../../../i18n';

import RulesItem from './components/RulesItem';

import { ICartItemInfo } from '../../bi/types/airlineAdditionalServices';

import styles from './styles/index.module.css';

const LABELS = {
  RULES: getText('components:rulesDialog.rules'),
  OK: getText('common:ok'),
};

interface DialogRulesProps {
  items: ICartItemInfo[],
  onClose(): void,
  onGetRules(id: number): Promise<void>,
  show: boolean,
}

class DialogRules extends Component<DialogRulesProps> {
  handleGetRule = (itemId: number) => this.props.onGetRules(itemId);

  render() {
    const { items, onClose, show } = this.props;

    const listHtml = items.map((item, index) => (
      <div key={ index } className={ styles['list-item'] }>
        <RulesItem
          item={ item }
          onGetRule={ () => this.handleGetRule(item.Id) }
        />
      </div>
    ));

    return (
      <Dialog
        showClosing
        show={ show }
        className={ styles.dialog }
        onChange={ onClose }
        outsideClickClosing={ false }
      >
        <div className={ styles.wrapper }>
          <Text type='bold_22'>{LABELS.RULES}</Text>
          { listHtml }
          <div className={ styles.action }>
            <Button
              type='secondary'
              onClick={ onClose }
            >
              {LABELS.OK.toUpperCase()}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default DialogRules;
