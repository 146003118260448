import { getText } from '../../../i18n';

import FORMATS from './formats';

const LABELS = {
  SIMPLE: getText('reports:operations.dialogRequestAct.types.simple'),
  EXTENDED: getText('reports:operations.dialogRequestAct.types.extended'),
};

const TYPES_ACT: { label: string, value: number }[] = [
  { label: LABELS.SIMPLE, value: 0 },
  { label: LABELS.EXTENDED, value: 1 },
];

const FORMATS_ACT = [
  { label: FORMATS.PDF, value: FORMATS.PDF },
  { label: FORMATS.XLSX, value: FORMATS.XLSX },
];

const RADIO_VALUES = {
  ALL_TIME: 'AllTime',
  LAST_PAYMENT: 'LastPayment',
  DATE_RANGE: 'dateRange',
  WITH_DATE: 'WithDate',
};

export { TYPES_ACT, FORMATS_ACT, RADIO_VALUES };
