import { action, makeObservable, observable } from 'mobx';

export interface IContractHotelStore {
  loading: boolean,
  message: string,
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable loading = false;
  @observable message = '';

  @action
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  setMessage = (message: string) => {
    this.message = message;
  };
}

const ContractHotelStore = new Store();

export { ContractHotelStore, Store as ContractHotelStoreType };
