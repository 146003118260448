import { IconColor } from 'new-ui/src/components/Icon/types';

import CONFIG from '../../../config';

const isSmartAgent = CONFIG.SMART_AGENT.ENABLED;

const isSmartwayKZ = false;
// временно добавил флаг в false - чтобы можно было ссылаться на него в скрытых релизах
// как только появится окружение под KZ - вернуться и завязаться на конфиг

const svgColor: IconColor = isSmartAgent ? 'dim_green' : 'blue';

export {
  isSmartAgent,
  isSmartwayKZ,
  svgColor,
};
