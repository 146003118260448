import React from 'react';

import NetworkStatesStore from '../../bi/services/utils/network/networkStatesStore';
import { LoadingStatus, StatusObject } from '../../bi/services/utils/network/types';

type IStatusConditionProps = {
  status: LoadingStatus
} & StatusObject<React.FC>;

export const StatusCondition = ({
  status,
  ...onStatus
}: IStatusConditionProps) => {
  const Component = NetworkStatesStore.statusCondition(status, onStatus);

  return Component ? <Component /> : null;
};
