import React, { ReactNode } from 'react';
import { Icon, Button, IconType } from 'new-ui';

import { getText } from '../../../../i18n';

import { isSmartAgent } from '../../../bi/utils/env';

import { SERVICETYPE } from '../../../bi/constants/serviceType';

import styles from './index.module.css';

const ICONS: { [key: string]: IconType } = {
  [SERVICETYPE.TRAIN]: 'smartdeskTrain',
  [SERVICETYPE.AIR]: 'smartdeskAir',
  [SERVICETYPE.HOTEL]: 'smartdeskHotel',
  [SERVICETYPE.TRANSFER]: 'smartdeskTransfer',
};

const LABELS = {
  DEFAULT: getText('common:search'),
};

interface ISearchSubMenuProps {
  children: ReactNode;
  onClick(): void;
  serviceType: string;
  buttonLabel?: string;
  disabled?: boolean;
  searchButton?: boolean;
  qaAttrIcon?: string;
  qaAttrSearchButton?: string;
}

const SearchSubMenu = ({
  children,
  serviceType,
  buttonLabel = LABELS.DEFAULT,
  disabled = false,
  searchButton = true,
  onClick,
  qaAttrIcon = '',
  qaAttrSearchButton = '',
}: ISearchSubMenuProps) => {
  const icon = (
    <div className={ styles.icon }>
      <Icon
        className={ styles.img }
        type={ ICONS[serviceType] }
        qaAttr={ qaAttrIcon }
        alternativeDesign={ isSmartAgent }
      />
    </div>
  );

  const button = searchButton && (
    <Button
      className={ styles.button }
      type='search-large'
      onClick={ onClick }
      disabled={ disabled }
      qaAttr={ qaAttrSearchButton }
    >
      { buttonLabel }
    </Button>
  );

  return (
    <div
      className={ styles.submenu }
    >
      <div className={ styles.container }>
        { icon }
        { children }
        { button }
      </div>
    </div>
  );
};

export { SearchSubMenu };
