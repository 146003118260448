import Api from '../../api';

import { settingSchemeStore } from './stores/settingScheme';
import { approvalSchemesStore } from './stores/schemes';
import { travelApprovalsStore } from '../travelApproval/stores/travelApprovals';

import { mapEmployeesListWithNamesakes } from '../../utils/employees';
import NetworkStatesStore from '../utils/network/networkStatesStore';
import { mergeTwoArrays } from '../../utils/mergeTwoArrays';
import { bound, withIM } from '../utils/dectrators';
import { getScheme } from '../../utils/approval';

import { APPROVETRIPRIGHT } from '../../constants/rights';
import EMPLOYEE_STATUSES from '../../constants/employee';
import {
  APPROVAL,
  APPROVAL_SCHEME_TYPES,
  DEFAULT_APPROVAL_SCHEME_FORM_PRE_STEP,
  DEFAULT_APPROVAL_SCHEME_FORM_STEP,
  DEFAULT_APPROVAL_SCHEME_FORM_VALUE,
  LOADINGS,
  StatusScheme,
  TRAVEL_APPROVAL,
} from '../../constants/approvalSchemes';

import {
  ApprovalSchemeFAQStore,
  type ApprovalSchemeFormStore,
  ApprovalSchemePreStep, ApprovalSchemeStep,
  ListFromBackType,
  RolesType,
} from './types';
import { IApprovalSchemeClientModel } from '../../types/approvalScheme';
import { ITripConflictsUser } from '../checkout/types';

export enum LoadingFields {
  oneC,
  setEmployee,
  deleteEmployee,
  editProject,
  editCompany,
  validateCart,
  getFactApprovers,
  loadScheme,
  recalculateEmployee,
}

class ApprovalSchemesService {
  api: Api;
  netStore = new NetworkStatesStore<LoadingFields>({ contextRecord: true });

  store = approvalSchemesStore;
  settingSchemeStore = settingSchemeStore;

  constructor(api: Api) {
    this.api = api;
  }

  @bound
  @withIM((o) => o.netStore.withLoaderFlowCoroutine(LoadingFields.loadScheme))
  async loadList(ApprovalSchemeId?: number | null) {
    this.store.updateList({ loading: true, error: false });

    try {
      const approvalSchemes: IApprovalSchemeClientModel[] = await this.api.approvalSchemes.getList();
      const foundApprovalScheme = approvalSchemes
        .find(({ Id }: { Id: number }) => Id === ApprovalSchemeId);
      const currentApprovalScheme = foundApprovalScheme?.TemplateRequestId
        ?? ApprovalSchemeId
        ?? DEFAULT_APPROVAL_SCHEME_FORM_VALUE.TemplateRequestId;

      this.store.updateList({ value: approvalSchemes });

      try {
        const applicationSchemeTemplate = await this.api.travelApproval.getApplicationScheme(currentApprovalScheme);
        this.store.updateApplicationSchemeTemplate(applicationSchemeTemplate);
      } catch (e) {}
    } catch (e) {
      this.store.updateList({ error: true });
    }

    this.store.updateList({ loading: false });

    return this.store.list.value;
  }

  removeItem = (id: number) => this.api.approvalSchemes.remove(id);

  loadRoles = () => {
    this.settingSchemeStore.setLoadings(LOADINGS.ROLES, true);
    this.api.userSession.getRoles()
      .then((list: RolesType[]) =>
        this.store.updateForm({
          Roles: {
            ...this.store.form.Roles,
            value: list,
            hash: list.reduce((res, item) => ({ ...res, [item.Id]: item }), {}),
          },
        }),
      )
      .catch((error: string) => this.store.updateForm({ Roles: { ...this.store.form.Roles, error } }))
      .finally(() => {
        this.store.updateForm({ Roles: { ...this.store.form.Roles, loading: false } });
        this.settingSchemeStore.setLoadings(LOADINGS.ROLES, false);
      });
  };

  loadEmployeesWithRights = () => this.api.employee.getWithRights();

  loadUsersInfo = () => this.api.approvalSchemes.getUsersInfo();

  loadApproversRequest = (employeeIds: number[] = []) => this.api.approvalSchemes.getApprovers(employeeIds);

  getScheme = (cartId: number, reqId: string | string[] | null | undefined, isTravelApproval: boolean) => {
    this.store.updateList({ loading: true });

    return isTravelApproval ?
      this.api.approvalSchemes.getCartSchemeTravelApproval(cartId, reqId).finally(() => this.store.updateList({ loading: false })) :
      this.api.approvalSchemes.getCartScheme(cartId).finally(() => this.store.updateList({ loading: false }));
  };

  loadApprovers = async (popupCb: (payload: any) => void) => {
    const {
      form: {
        Employees,
        Steps,
        PreSteps,
      },
      updateForm,
    } = this.store;

    updateForm({ Employees: { ...Employees, loading: true } });

    this.loadEmployeesWithRights()
      .then((list: any[]) => {
        const onlyApprovers: any[] = list.filter(({ Rights, Status }) =>
          Rights && Rights.Approve === APPROVETRIPRIGHT.Available && Status === EMPLOYEE_STATUSES.ACCESS.USER);
        const approversIdList = (steps: any[]) => steps.reduce((res, { Approvers }) => [...res, ...Approvers], []);
        const notFoundApprovers = (steps: any[]) => steps.filter(item =>
          !onlyApprovers.find(({ Rights: { UserId } }) => String(item) === String(UserId)));

        const approversInSteps = approversIdList(Steps);
        const approversInPreSteps = approversIdList(PreSteps);

        const notFoundApproversFromSteps = notFoundApprovers(approversInSteps);
        const notFoundApproversFromPreSteps = notFoundApprovers(approversInPreSteps);

        const users = list.filter(({ Status }) => Status === EMPLOYEE_STATUSES.ACCESS.USER);

        if ((approversInSteps.length && notFoundApproversFromSteps.length) ||
          (approversInPreSteps.length && notFoundApproversFromPreSteps.length)) {
          const ids = mergeTwoArrays(notFoundApproversFromSteps, notFoundApproversFromPreSteps);

          popupCb(ids);

          return updateForm({ Employees: { ...Employees, loading: true, error: true, users } });
        }

        // @ts-ignore
        const preparedList = mapEmployeesListWithNamesakes(onlyApprovers);

        return this.store.updateForm({ Employees: { ...Employees, loading: false, value: preparedList, hash: preparedList.reduce((r, e) => ({ ...r, [e.Rights.UserId]: e }), {}), users } });
      })
      .catch(() => this.store.updateForm({ Employees: { ...this.store.form.Employees, loading: false, error: true } }));
  };

  getSchemeById = async (id: number) => {
    this.settingSchemeStore.setLoadings(LOADINGS.SCHEME, true);

    try {
      const scheme = await this.api.approvalSchemes.getSchemeById(id);

      const { Steps, PreSteps } = scheme;
      const approval = Steps.length ? APPROVAL.EVERY : APPROVAL.UNAVAILABLE;
      const travelApproval = PreSteps.length ? TRAVEL_APPROVAL.AVAILABLE : TRAVEL_APPROVAL.UNAVAILABLE;

      this.settingSchemeStore.setApproval(approval);
      this.settingSchemeStore.setTravelApproval(travelApproval);

      const preparedScheme = getScheme(scheme);

      this.store.updateForm({ ...preparedScheme });
      this.settingSchemeStore.setLoadings(LOADINGS.SCHEME, false);
      this.store.updateForm({ Employees: { ...this.store.form.Employees, loading: false } });
    } catch {
      this.store.updateForm({ ...DEFAULT_APPROVAL_SCHEME_FORM_VALUE });
      this.settingSchemeStore.setLoadings(LOADINGS.SCHEME, false);
    }
  };

  loadEmployeesCount = async () => {
    this.store.updateForm({
      Utils: {
        ...this.store.form.Utils,
        admins: {
          ...this.store.form.Utils.admins,
          loading: true,
        },
      },
    });

    try {
      const { countActiveUsers, admins } = await this.loadUsersInfo();
      this.store.setEmployeesCount(countActiveUsers);
      const preparedAdmins = admins.map(({ id, userName }: { id: string, userName: string }) => ({
        Id: id,
        Email: userName,
      }));

      this.updateForm({
        Utils: {
          ...this.store.form.Utils,
          admins: {
            ...this.store.form.Utils.admins,
            value: preparedAdmins,
            loading: false,
          },
        },
      });
    } catch {
      this.settingSchemeStore.setLoadings(LOADINGS.APPROVERS, false);
      this.store.updateForm({
        Utils: {
          ...this.store.form.Utils,
          admins: {
            ...this.store.form.Utils.admins,
            error: true,
            loading: false,
          },
        },
      });
    }
  };

  loadNewApprovers = async () => {
    this.settingSchemeStore.setLoadings(LOADINGS.APPROVERS, true);

    const { updateForm } = this.store;

    try {
      const onlyApprovers = await this.api.approvalSchemes.getNewApprovers();

      const preparedList = mapEmployeesListWithNamesakes(onlyApprovers);

      updateForm({
        Employees: {
          ...this.store.form.Employees,
          loading: false,
          value: preparedList,
          hash: preparedList.reduce((res, item) => ({ ...res, [item.UserId]: item }), {}),
          users: preparedList,
        },
      });
      this.settingSchemeStore.setLoadings(LOADINGS.APPROVERS, false);
    } catch {
      updateForm({ Employees: { ...this.store.form.Employees, loading: false, error: true } });
      this.settingSchemeStore.setLoadings(LOADINGS.APPROVERS, false);
    }
  };

  loadNewList = () => {
    this.store.setLoadingList(true);

    this.api.approvalSchemes.getNewList()
    // TODO /travel-approval/schemes/short-info уточнить модель
    // @ts-ignore
      .then((value: ListFromBackType[]) => this.store.updateList({ value }))
      .catch(() => this.store.updateList({ error: true }))
      .finally(() => {
        this.store.updateList({ loading: false });
        this.store.setLoadingList(false);

        return this.store.list.value;
      });
  };

  loadAdmins = () => {
    this.store.updateForm({
      Utils: {
        ...this.store.form.Utils,
        admins: {
          ...this.store.form.Utils.admins,
          loading: true,
        },
      },
    });

    return this.api.userSession.getAccountAdmins()
      .then((value: ITripConflictsUser[]) => {
        this.updateForm({
          Utils: {
            ...this.store.form.Utils,
            admins: {
              ...this.store.form.Utils.admins,
              value,
            },
          },
        });
      })
      .catch(() => this.store.updateForm({
        Utils: {
          ...this.store.form.Utils,
          admins: {
            ...this.store.form.Utils.admins,
            error: true,
          },
        },
      }))
      .finally(() => this.store.updateForm({
        Utils: {
          ...this.store.form.Utils,
          admins: {
            ...this.store.form.Utils.admins,
            loading: false,
          },
        },
      }));
  };

  initForm = (data = {}) => {
    if (data) {
      // @ts-ignore
      return this.getSchemeById(data.Id);
    }

    return this.store.updateForm({ ...DEFAULT_APPROVAL_SCHEME_FORM_VALUE, ...data });
  };

  updateForm = (data: Partial<ApprovalSchemeFormStore>) => {
    this.store.updateForm(data);
  };

  updateFaq = (data: ApprovalSchemeFAQStore) => {
    this.store.updateFaq(data);
  };

  submitForm = () => {
    const { form: { Employees, Roles, Utils, ...rest } } = this.store;

    this.store.updateForm({ Utils: { ...Utils, submitLoading: true } });

    return this.api.approvalSchemes.save(rest).finally((res: any) => {
      this.store.updateForm({ Utils: { ...Utils, submitLoading: false } });

      return res;
    });
  };

  updateStatus = (value: StatusScheme) => this.store.setStatus(value);

  updateWhoBuys = (id: string, value: boolean) => this.settingSchemeStore.setWhoBuys(id, value);

  updateTravelApproval = (id: string) => this.settingSchemeStore.setTravelApproval(id);

  updateApproval = (id: string) => this.settingSchemeStore.setApproval(id);

  updateStatusFromCreate = () => {
    const {
      isSettingEmployeesStatus,
      isTravelApprovalUnavailable,
      isApprovalUnavailable,
      isApprovalDisrupted_TP: isApprovalDisruptedTP,
    } = this.settingSchemeStore;

    if (isSettingEmployeesStatus) {
      return this.updateStatus(StatusScheme.SETTING_EMPLOYEES);
    }

    // @ts-ignore
    const preSteps: ApprovalSchemePreStep[] = isTravelApprovalUnavailable
      ? []
      : [{ ...DEFAULT_APPROVAL_SCHEME_FORM_PRE_STEP }];

    const steps = (): ApprovalSchemeStep[] => {
      if (isApprovalUnavailable) {
        return [];
      }

      if (isApprovalDisruptedTP) {
        // @ts-ignore
        return [{
          ...DEFAULT_APPROVAL_SCHEME_FORM_STEP,
          Type: APPROVAL_SCHEME_TYPES.DISRUPTED_TP.value,
        }];
      }

      // @ts-ignore
      return [{ ...DEFAULT_APPROVAL_SCHEME_FORM_STEP }];
    };

    this.updateForm({ PreSteps: preSteps, Steps: steps() });

    return this.updateStatus(StatusScheme.EDIT);
  };

  updateStatusToCreate = () => this.updateStatus(StatusScheme.CREATE);

  @bound
  @withIM(o =>
    o.netStore.withLoaderFlow(LoadingFields.getFactApprovers),
  )
  async getFactApprovers(cartId?: number) {
    if (cartId) {
      const rId = travelApprovalsStore.chosenApprovedRequest?.Id;

      const schema = rId
        ? await this.api.approvalSchemes.getCartSchemeTravelApproval(cartId, rId)
        : await this.api.approvalSchemes.getCartScheme(cartId);

      this.settingSchemeStore.setFactApproversList(schema);
    }
  }

  clearStoresExceptList = () => {
    this.store.clearExceptList();
    this.settingSchemeStore.clear();
  };
}

export default ApprovalSchemesService;
