const statuses = new Map([
  ['NotProcessing', 'Не обрабатывается'],
  ['Processing', 'Обрабатывается'],
  ['Processed', 'Обработана'],
  ['Canceled', 'Отменена'],
]);

const STATUSES = {
  NOTPROCESSING: 'NotProcessing',
  PROCESSING: 'Processing',
  PROCESSED: 'Processed',
  CANCELED: 'Canceled',
};

const TYPES = {
  AIR: 'Air',
  HOTEL: 'Hotel',
  TRANSFER: 'Transfer',
  TRAIN: 'Train',
};

const FARECLASS: Record<string, string> = {
  Econom: 'Эконом',
  Business: 'Бизнес',
  First: 'Первый',
};

const FILTERS_TYPES = {
  STATUSES: 'statuses',
  DEPARTMENTS: 'departments',
  EMPLOYEES: 'employees',
};

export { statuses, STATUSES, TYPES, FARECLASS, FILTERS_TYPES };
