import React from 'react';
import { Text } from 'new-ui';

import styles from './styles/index.module.css';

interface SearchLoadingProps {
  text?: string,
  qaAttrLoadingText?: string,
}

const SearchLoading = ({ text = '', qaAttrLoadingText = '' }: SearchLoadingProps) => {
  const labelContent = !!text && (
    <Text
      className={ styles.text }
      qaAttr={ qaAttrLoadingText }
    >
      { text }
    </Text>
  );

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.inner }>
        <div className={ styles.circle } />
        <i className={ `material-icons ${styles.icon} ${styles.airplane}` }>
          flight
        </i>
        <i className={ `material-icons ${styles.icon} ${styles.train}` }>
          train
        </i>
        <i className={ `material-icons ${styles.icon} ${styles.hotel}` }>
          home
        </i>
      </div>
      { labelContent }
    </div>
  );
};

export { SearchLoading };
