import React from 'react';
import { Text } from 'new-ui';

import parseJsonString from '../../../bi/utils/parseJsonString';
import { formatHotelRangeDate } from '../../../bi/utils/hotel';

import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';

import { ISourcesItem } from '../../../bi/services/checkout/types';

import styles from './styles/index.module.css';

const CartHotel = (item: ISourcesItem, employees: string, serviceType: string) => {
  const { Data, Id } = item;
  const data = parseJsonString(Data);

  if (!data) return null;

  const {
    hotel: { Name, City },
    room: { Name: roomName },
    Rate: {
      CustomCheckInDate,
      CustomCheckOutDate,
      CheckinDate,
      CheckoutDate,
    },
  } = data;
  const date = formatHotelRangeDate(CustomCheckInDate, CustomCheckOutDate, CheckinDate, CheckoutDate);
  const room = roomName ? ` ${roomName}` : null;

  return (
    <div key={ `cart_conflict_${Id}` } className={ styles.wrapper }>
      <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.date }>{date},</Text>
      <Text
        qaAttr={ QA_ATTRIBUTES.cart.duplicate.info }
        type='NORMAL_14_130'
      >
        {serviceType} {Name} ({City}),{room},
      </Text>
      <Text
        qaAttr={ QA_ATTRIBUTES.cart.duplicate.travellers }
        type='NORMAL_14'
        color='gray'
      >
        {employees}
      </Text>
    </div>
  );
};

export default CartHotel;
