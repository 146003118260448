import { VAT } from '../constants/taxi';

import { IRoute } from '../types/taxi';

import { removeSpaces } from './text';

interface IDetailsAmount {
  tariff: number,
  vat: number,
}

const getDetailsAmount = (total: number): IDetailsAmount => {
  if (!total) {
    return { tariff: 0, vat: 0 };
  }

  const tariff = Number((total / (1 + VAT)).toFixed(2));
  const vat = Number((total - tariff).toFixed(2));

  return { tariff, vat };
};

const valueToNumber = (value: string): number => (Number(removeSpaces(value).replace(',', '.')) || 0);

const getFullAddress = (route: IRoute) => {
  const { Country: country, City: city, Address: { Street: street, Number: number, Extra: extra } } = route;

  return `${country ? `${country} ,` : ''} ${city}${street ? `, ${street}` : ''}${number ? `, ${number}` : ''}${extra ? `, ${extra}` : ''}`;
};

export { getDetailsAmount, valueToNumber, getFullAddress };
