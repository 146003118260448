import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import ApprovalSchemeDialog from '../ApprovalSchemeDialog';

import { TRAVEL_APPROVAL_TYPE } from '../../bi/constants/travelApproval';

import { ApprovalSchemeCartDialogProps, Model } from './types';

const ApprovalSchemeCartDialog = observer(({
  workspaceService,
  userSessionService,
  approvalSchemesService,
  cart,
  isTravelApproval,
  showDialog,
  toggleShowDialog,
  handleSendToApprove,
  emptyComment,
  chosenApprovedRequestId,
  approveTexts,
  showApproversCKR,
  isBooking = false,
}: ApprovalSchemeCartDialogProps) => {
  const {
    travelApprovalsStore: {
      validateApprovedRequest: {
        Valid: travelApproveValid,
      },
    },
    approvalSchemesStore: {
      list: {
        error,
        loading: approvalSchemeLoading,
      },
    },
  } = useStores([MOBX_STORES.TRAVEL_APPROVAL_STORE, MOBX_STORES.TRAVEL_APPROVALS, MOBX_STORES.APPROVAL_SCHEMES]);
  const currentScheme = useRef<null | any>(null);

  useEffect(() => {
    approvalSchemesService.getScheme(cart.id, chosenApprovedRequestId, isTravelApproval)
      .then((res: undefined) => {
        currentScheme.current = res;
      });
  }, []);

  const isDisruptedTP = cart.sources.some(({ TravelPolicy }) => Object.keys(TravelPolicy).length);

  const userId = workspaceService.get().Rights.UserId;

  const typeApproval = () => {
    if (isTravelApproval && travelApproveValid) return TRAVEL_APPROVAL_TYPE.TRAVEL_APPROVAL_IN_CART;

    if (isTravelApproval && !travelApproveValid) return TRAVEL_APPROVAL_TYPE.TRAVEL_APPROVAL_IN_CART_NOT_VALID;

    return '';
  };

  const handleSubmit = (model: Model, isPreSteps: boolean) => handleSendToApprove(
    { CartId: cart.id, ...model },
    isPreSteps,
    chosenApprovedRequestId,
    currentScheme.current.CanSkipApproval,
  )
    .then(() => toggleShowDialog());

  return (
    <ApprovalSchemeDialog
      loaded={ approvalSchemeLoading }
      error={ error }
      show={ showDialog }
      scheme={ currentScheme.current }
      approvalType={ typeApproval() }
      isTpDisrupted={ isDisruptedTP }
      currentUserId={ userId }
      onSubmit={ handleSubmit }
      onClose={ toggleShowDialog }
      userSessionService={ userSessionService }
      emptyComment={ emptyComment }
      approveTexts={ approveTexts }
      onlySteps={ false }
      isBooking={ isBooking }
      showApproversCKR={ showApproversCKR }
    />
  );
});

export { ApprovalSchemeCartDialog };
