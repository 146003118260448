import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';

import COUNTRIES from '../constants/countries';

const formattedPhoneNumber = (num: string, region: any) => new AsYouType(region).input(num);

const preparedPhoneNumber = (value: string): { prepareRegion: string, prepareNum: string } => {
  const parsedPhoneNumber: any = parsePhoneNumberFromString(value) || '';

  const initialRegion = parsedPhoneNumber?.country || COUNTRIES.RU.SHORTNAME;
  const initialNum = parsedPhoneNumber?.nationalNumber || value;

  return {
    prepareRegion: initialRegion,
    prepareNum: initialNum,
  };
};

export { formattedPhoneNumber, preparedPhoneNumber };
