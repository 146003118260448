export const UPDATE_INTERVAL = 2000;
export const BOOKING_TIMEOUT = 5;
export const TIMERUPDATEINTERVAL = 1000;
export const WAITING_TRAIN_TIMER = 60000;

export const SPEED = {
  Medium: 'medium',
  Slow: 'slow',
};

export const RIGHTS = {
  ONLYAFTERAPPROVE: 'OnlyAfterApprove',
  APPROVAL_SCHEME: 'ApprovalScheme',
  TRAVELPOLICY: 'TravelPolicy',
};

export const PROGRESSDEFAULTVALUE = 0.25;
export const TIMERDEFAULTVALUE = '00:00';

export const BOOKINGSTEPVALUE = {
  Unknown: 0,
  Metadata: 1,
  PrepareToReserve: 0.3,
  Reservation: 0.4,
  Reserved: 0.5,
  ReservationError: 0.6,
  PrepareToBooking: 0.7,
  Booking: 0.8,
  ReadyToBook: 0.9,
  Booked: 1,
  BookingError: 1,
};

export const CARTBOOKINGSTATUS = {
  Failed: 'Failed',
  InProgress: 'InProgress',
  Booked: 'Booked',
  Metadata: 'Metadata',
  WaitingToContinue: 'WaitingToContinue',
};
