import { action, computed, makeObservable, observable } from 'mobx';

import { LABELS } from '../../../page/Department/consts';
import { DEFAULT_FORM_VALUE } from '../../constants/departments';

import {
  CompanyWithDepartmentList,
  DepartmentFormStore,
  DepartmentSave,
  DepartmentsListStore,
  DialogTypes,
  ICompanyList,
} from './types';
import { ICompany } from '../employee/types';

const DEFAULT_LIST = {
  value: [],
  loading: false,
};

export enum DepartmentStatus {
  all = -1,
  active = 0,
  deleted = 1,
}

const DEFAULT_FILTER = {
  companyId: 0,
  search: '',
  departmentStatus: DepartmentStatus.active,
};

const DEFAULT_FORM = {
  loading: false,
  value: DEFAULT_FORM_VALUE,
  employeesHash: {},
  employeesList: [],
  companies: [],
  hasChanges: false,
  forcedPrompt: false,
  departmentsIsFiltered: false,
  filter: DEFAULT_FILTER,
  departmentsCount: 0,
};

export interface IDepartmentsStore {
  list: DepartmentsListStore,
  form: DepartmentFormStore,
  filteredValue: DepartmentsListStore,
  departmentsIsFiltered: boolean,
  companies: any[],
  filter: {
    companyId: number,
    search: string
  },
  paginate: {
    currentPage: number,
    itemsPerPage: number,
    total: number
  },
  departmentsCount: number,
  departmentDeleteLoading: boolean,
  hideDeleteDepartmentButton: boolean,
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable list: DepartmentsListStore = DEFAULT_LIST;
  @observable departmentForSelectList: CompanyWithDepartmentList[] = [];
  @observable form: DepartmentFormStore = DEFAULT_FORM;
  @observable initialName?: string;
  @observable filteredValue: DepartmentsListStore = DEFAULT_LIST;
  @observable departmentsIsFiltered = false;
  @observable companies: any[] = [];
  @observable filter = DEFAULT_FILTER;
  @observable paginate = {
    currentPage: 1,
    itemsPerPage: 50,
    total: 0,
  };
  @observable departmentsCount = 0;

  @observable departmentDeleteLoading = false;

  @observable hideDeleteDepartmentButton = false;

  @observable activeDialog = DialogTypes.empty;
  @observable dialogMeta: any = null;

  openDialog = (dialog: DialogTypes, meta?: any) => action(() => {
    this.activeDialog = dialog;
    this.dialogMeta = meta;
  });

  @action
  closeDialog = () => {
    this.activeDialog = DialogTypes.empty;
    this.dialogMeta = null;
    this.clearChanges();
  };

  @action
  clearChanges = () => {
    this.form.hasChanges = false;
    window.onbeforeunload = null;
  };

  @action
  asyncClearChanges = async () => {
    this.clearChanges();
    await new Promise(r => setTimeout(r, 1));
  };

  @action
  setInitialName = (name: string) => {
    this.initialName = name;
  };

  @action
  setDepartmentForSelectList = (companies: ICompany[]) => {
    this.departmentForSelectList = companies.map(({ CompanyId, CompanyName, ShortCompanyName, Departments = [] }) =>
      ({
        value: CompanyId,
        label: ShortCompanyName || CompanyName,
        nested: Departments.map(({ Id, Name: dName, HeadOfDepartmentId }) => (
          { value: Id, label: dName, headId: HeadOfDepartmentId }),
        ),
      }));
  };

  @computed
  get headOfDepartment(): any {
    const { employeesHash, value: { headOfDepartment } } = this.form;

    if (Object.keys(employeesHash).length && headOfDepartment && headOfDepartment.Id) {
      return { ...employeesHash[headOfDepartment.Id], ...headOfDepartment };
    }

    return {};
  }

  @computed
  get companyNotSelected() {
    return typeof this.form.value.CompanyId !== 'number';
  }

  @computed
  get chosenEmployees(): any {
    const { employeesHash, value: { employees } } = this.form;

    if (Object.keys(employeesHash).length) {
      return Object.keys(employees).reduce((r, e) => {
        const isEmployeeIsHeadOfDepartment = String(e) !== String(this.headOfDepartment.Id ||
          (this.headOfDepartment.isRemoved && String(e) === String(this.headOfDepartment.Id)));

        if (!employeesHash[e]) {
          return ({ ...r });
        }

        if (isEmployeeIsHeadOfDepartment) {
          return ({ ...r, [e]: { ...employees[e], ...employeesHash[e] } });
        }

        return ({ ...r });
      }, {});
    }

    return {};
  }

  @computed
  get isValid(): boolean {
    const { value: { CompanyId, Name } } = this.form;

    return !!CompanyId && !!Name.length;
  }

  @computed
  get currentId() { return this.form.value.Id as number; }

  @computed
  get isNewDepartment() { return !this.form.value.Id; }

  @computed
  get preparedToSaveModel(): Partial<DepartmentSave> {
    const {
      value: {
        Name,
        CompanyId,
        Id,
        headOfDepartment,
        headOfDepartment: { Id: hId },
        employees,
      },
    } = this.form;

    const headId = headOfDepartment?.isRemoved ? null : hId;
    const activeEmployeesIds = Object.keys(employees).reduce<number[]>((r, i) => {
      if (employees[i]?.isRemoved) {
        return r;
      }

      return [...r, Number(i)];
    }, []);

    return {
      Id,
      Name,
      CompanyId,
      HeadOfDepartmentId: headId ? Number(headId) : null,
      EmployeesIds: activeEmployeesIds,
    };
  }

  @action
  updateList = (data: Partial<DepartmentsListStore>) => {
    this.list = { ...this.list, ...data };
  };

  @action
  setDepartmentDeleteLoader = (isLoading: boolean) => {
    this.departmentDeleteLoading = isLoading;
  };

  @action
  updateForm = (data: Partial<DepartmentFormStore>) => {
    this.form = { ...this.form, ...data };
  };

  updateFormValue = (
    name: keyof DepartmentFormStore['value'],
  ) => (newValue: any) => {
    this.updateForm({
      value: {
        ...this.form.value,
        [name]: newValue,
      },
      hasChanges: true,
    });

    window.onbeforeunload = () => LABELS.EXIT_MESSAGE;
  };

  @action
  setDepartments = (departmentList: any) => {
    this.filteredValue = ({ ...this.filteredValue, value: departmentList });
    this.departmentsIsFiltered = JSON.stringify(this.filter) !== JSON.stringify(DEFAULT_FILTER);
  };

  @action
  setPageDepartment = (page: number) => {
    this.paginate = {
      ...this.paginate,
      currentPage: page,
    };
  };

  @action
  setDepartmentsCount = (res: number) => {
    this.departmentsCount = res;
    this.paginate = {
      ...this.paginate,
      total: res,
    };
  };

  @action
  setDepartmentsFilters = (field: string | number, value: any) => {
    this.filter = {
      ...this.filter,
      [field]: value,
    };
  };

  @action
  setWaitingResponse = (loading: boolean) => {
    this.filteredValue = { ...this.filteredValue, loading };
  };

  @action
  setCompanies = (list: ICompanyList[]) => {
    this.companies = list;
  };
}

const DepartmentsStore = new Store();

export { DepartmentsStore };
