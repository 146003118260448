import React, { FC } from 'react';

import { Icon, Text } from 'new-ui';

import { getText } from '../../../i18n';

import { SERVICETYPE } from '../../bi/constants/serviceType';

import styles from './styles.module.css';

const TEXT = {
  [SERVICETYPE.AIR]: getText('components:underageWarning.serviceTypes.air'),
  [SERVICETYPE.TRAIN]: getText('components:underageWarning.serviceTypes.train'),
  PASSPORT_WARN: getText('components:checkPassportWarning.text'),
};

interface UnderageProps {
  type: keyof typeof TEXT
}

export const UnderageWarning: FC<UnderageProps> = ({ type }) => (
  <div className={ styles.warning }>
    <Icon
      className={ styles.icon }
      type='warning'
    />
    <Text
      type='NORMAL_14'
      color='red'
    >
      { TEXT[type] }
    </Text>
  </div>
);

export { UnderageWarning as CartWarning };
