import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, RadioSwitcher, Text, Tooltip } from 'new-ui';

import { getText } from '../../../i18n';
import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { InsuranceRate } from './components/InsuranceRate';

import { checkDateForInsurance } from '../../bi/utils/formatDate';
import {
  defineWarnLabelForCheckboxes,
  getLabelCheckboxItem,
  getPriceOfAddedInsurance,
} from '../../bi/utils/insurance';

import { SERVICETYPE } from '../../bi/constants/serviceType';

import {
  EnumRateName,
  IListItem,
  InsuranceRateType,
  InsuranceServiceType,
  IDates,
  ICartItem,
} from '../../bi/types/insurance';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('components:insurance.title'),
  TITLE_NOT_REFUNDABLE_SERVICE: getText('components:insurance.titleNotRefundableService'),
  SUBTITLE: getText('components:insurance.subtitle'),
  SUBTITLE_NOT_REFUND: getText('components:insurance.subtitleNotRefundable'),
  SWITCHER: getText('components:insurance.selectRate'),
  ABOUT_RATES: getText('components:insurance.about'),
  SELECT_RATE: getText('components:insurance.letSelectRate'),
  SELECT_ITEMS_TITLE: getText('components:insurance.selectItemsTitle'),
  SELECT_ALL: getText('components:insurance.selectAll'),
  CLEAR_ALL: getText('components:insurance.clearAll'),
  NOT_INSURANCE: getText('components:insurance.allItemsInTrip'),
  NOT_ALLOW_TO_CHOOSE_ITEM: getText('components:insurance.notShowedInsurance'),
  SERVICES: {
    HOTEL: getText('components:insurance.titleService.hotel'),
    AIR: getText('components:insurance.titleService.air'),
    AEROEXPRESS: getText('components:insurance.titleService.aeroExpress'),
    TRANSFER: getText('components:insurance.titleService.transfer'),
    TAXIVOUCHER: getText('components:insurance.titleService.taxi'),
    VIPHALL: getText('components:insurance.titleService.vipHall'),
    TRAIN: getText('components:insurance.titleService.train'),
    CUSTOM: getText('components:insurance.titleService.custom'),
  },
  NOT_VALID_ITEM: getText('components:insurance.notValidItem'),
};

interface IInsuranceProps {
  cartId?: number,
  cartItems?: number[],
  insuranceService: InsuranceServiceType,
  isCart: boolean,
  isEmptyEmployees?: boolean,
  isServiceNotRefundable: boolean,
  onUpdateNormalCarts?: () => void,
  orderDate: IDates,
  rateName?: EnumRateName,
  sources?: ICartItem[],
  tripId?: number,
  tripIds?: number[],
  validationTransferFields?: boolean,
}

const Insurance = observer(({
  insuranceService: {
    clearSelectedItems,
    fetchPriceOfInsurance,
    sendInsuranceToCartService,
    setAddedInsurance,
    setAllItems,
    setChoosenRate,
    setInsuranceSidePanel,
    setIsValidDates,
    setSelectedItems,
  },
  cartId,
  cartItems = [],
  isCart,
  isEmptyEmployees = false,
  isServiceNotRefundable = false,
  onUpdateNormalCarts = () => {},
  orderDate,
  rateName = EnumRateName.DEFAULT,
  sources = [],
  tripId,
  tripIds = [],
  validationTransferFields = false,
}: IInsuranceProps) => {
  const {
    insuranceStore: {
      choosenRate,
      disabledItems,
      isSelectedAll,
      isValidDate,
      listItems,
      rates,
      selectedItems,
    },
  } = useStores([MOBX_STORES.INSURANCE_STORE]);

  const [addedRate, setAddedRate] = useState(rateName || EnumRateName.OPTIMAL);

  useEffect(() => {
    const id = isCart ? (cartId ?? 0) : (tripId ?? 0);

    fetchPriceOfInsurance(id, isCart, rateName);

    if (choosenRate) setAddedRate(choosenRate);
  }, [tripIds.length, cartItems.length]);

  useEffect(() => {
    const isValid = checkDateForInsurance(orderDate);

    setIsValidDates(isValid);

    if (!isCart) {
      setIsValidDates(true);
    }
  }, [orderDate]);

  const priceBookedInsurance = getPriceOfAddedInsurance(sources, addedRate, rates);
  const showWarning = isServiceNotRefundable && isValidDate;
  const title = showWarning ? LABELS.TITLE_NOT_REFUNDABLE_SERVICE : LABELS.TITLE;
  const icon = showWarning ? 'warning' : 'guard';
  const subtitle = showWarning ? LABELS.SUBTITLE_NOT_REFUND : LABELS.SUBTITLE;

  const handleAddAllItems = () => {
    if (!isSelectedAll) {
      return setAllItems();
    }

    return clearSelectedItems();
  };

  const renderTextDisabledCheckbox = (warnLabel: string) => (
    <Text type='NORMAL_14' color='white' className={ styles.tooltip_text }>{ warnLabel }</Text>
  );

  const renderItems = () => listItems.map(({ TripItemId, Amount, ServiceType, Data }: IListItem, idx: number) => {
    if (ServiceType === SERVICETYPE.FLIGHT_CERTIFICATE || !Data) return null;

    const upperServiceType = ServiceType.toUpperCase();
    const bookedInfo = { Amount, TripItemId };
    const selectedItemsIds = selectedItems.map((item: { TripItemId: number }) => item.TripItemId);

    const warnLabel = defineWarnLabelForCheckboxes(isCart, choosenRate, disabledItems, TripItemId, LABELS);

    return (
      <Tooltip
        key={ idx }
        bodyClassName={ styles.tooltip }
        position='top'
        renderContent={ () => renderTextDisabledCheckbox(warnLabel) }
        show={ !!warnLabel }
      >
        <Checkbox
          value={ selectedItemsIds.includes(TripItemId) }
          onChange={ () => setSelectedItems(bookedInfo, tripId) }
          disabled={ !!warnLabel }
        >
          <div className={ styles.checkbox_text }>
            <Text type='NORMAL_16'>{ LABELS.SERVICES[upperServiceType as keyof typeof LABELS.SERVICES] }:</Text>
            <Text type='NORMAL_16' color='gray'>{ getLabelCheckboxItem({ ServiceType, Data }) }</Text>
          </div>
        </Checkbox>
      </Tooltip>
    );
  });

  const renderSelectItems = () => {
    if (isCart || !listItems.length) return null;

    const labelButton = !isSelectedAll ?
      LABELS.SELECT_ALL :
      LABELS.CLEAR_ALL;

    return (
      <>
        <div className={ styles.select_wrapper }>
          <Text
            type='SEMIBOLD_14'
          >
            { LABELS.SELECT_ITEMS_TITLE }
          </Text>
          <Button
            type='textual-medium'
            onClick={ handleAddAllItems }
            disabled={ !!choosenRate }
          >
            { labelButton }
          </Button>
        </div>
        <div className={ styles.select_items }>
          { renderItems() }
        </div>
      </>
    );
  };

  const renderRates = () => rates.map(({ Price, Rate }: InsuranceRateType, idx: number) => (
    <InsuranceRate
      key={ `${styles.rate}_${idx}` }
      addedRate={ choosenRate }
      cartItems={ cartItems }
      isCart={ isCart }
      isEmptyEmployees={ isEmptyEmployees }
      isValidDate={ isValidDate }
      listItems={ listItems }
      onAddRate={ setChoosenRate }
      onChangeRate={ setAddedRate }
      onUpdateNormalCarts={ onUpdateNormalCarts }
      orderDate={ orderDate }
      price={ Price }
      priceBookedInsurance={ priceBookedInsurance }
      rateName={ Rate }
      selectedItems={ selectedItems }
      selectedRate={ addedRate }
      sendInsuranceToCartService={ sendInsuranceToCartService }
      setAddedInsurance={ setAddedInsurance }
      tripId={ tripId }
      validationTransferFields={ validationTransferFields }
    />
  ));

  const renderContent = () => (
    <>
      { renderSelectItems() }
      <div className={ styles.about }>
        <Text
          type='SEMIBOLD_14'
        >
          { LABELS.SELECT_RATE }
        </Text>
        <Button
          type='textual-medium'
          onClick={ () => setInsuranceSidePanel(true) }
        >
          { LABELS.ABOUT_RATES }
        </Button>
      </div>
      <div className={ styles.rate_wrap }>
        { renderRates() }
      </div>
    </>
  );

  return (
    <div className={ styles.wrap }>
      <RadioSwitcher
        classname={ styles.inner }
        iconColor='blue'
        iconType={ icon }
        label={ title }
        subText={ subtitle }
        switchLabel={ LABELS.SWITCHER }
        theme='child'
      >
        { renderContent() }
      </RadioSwitcher>
    </div>
  );
});

export { Insurance };
