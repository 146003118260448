export enum AnalyticSortHotel {
  recommended_first = 'recommended_first',
  cheaper_first = 'cheaper_first',
  more_expensive_first = 'more_expensive_first',
  distance_from_center = 'distance_from_center',
}

export enum DetailAddCartHotel {
  optional_choice = 'optional_choice',
  main_optional = 'main_optional',
  main = 'main',
}

export enum YesNoType {
  yes = 'yes',
  no = 'no',
}

export type RatingType = '8.5+' | '7+' | '6+';

export enum TypeAccomodationType {
  Apartments = 'Apartments',
  'Guest House' = 'Guest House',
  Hostel = 'Hostel',
  Hotel = 'Hotel',
  'Mini Hotel' = 'Mini Hotel',
  'Resort hotel' = 'Resort hotel',
  Sanatorium = 'Sanatorium',
  Others = 'Others',
}

export enum RoomServiceType {
  Sport = 'Sport',
  'Free Wi-Fi' = 'Free Wi-Fi',
  'Bar/restaurant' = 'Bar/restaurant',
  Parking = 'Parking',
  Pool = 'Pool',
  Spa = 'Spa',
}

export enum HotelSearchLocationType {
  region = 'region',
  hotel = 'hotel',
}
