import React from 'react';
import { LinkButton, Text, Tooltip } from 'new-ui';

import { getText } from '../../../i18n';

import { formatRangeDateWithSimplicity } from '../../bi/utils/formatDate';
import { getEmployeeFullNameWithSimplicity, IEmployeeFullNameObj } from '../../bi/utils/employees';

import { ApprovalWarning } from '../../bi/constants/travelApproval';

import { IPrepareSourcesItem } from '../../bi/services/checkout/types';
import { RequestCartValidateType, TravelApprovalType } from '../../bi/services/travelApproval/consts';

import styles from './styles/index.module.css';

const LABELS = {
  WARNING: getText('components:travelApproval.taWarning'),
  AVAILABLE_DATES: getText('components:travelApproval.availableDates'),
  AVAILABLE_DIRECTIONS: getText('components:travelApproval.availableDirections'),
  SELECTED_EMPLOYEES: getText('components:travelApproval.selectedEmployees'),
};

interface NameErrorsTravelApprovalProps {
  item: IPrepareSourcesItem,
  validateApprovedRequest: RequestCartValidateType,
  chosenApprovedRequest: TravelApprovalType,
  className?: string,
}

const NameErrorsTravelApproval = ({
  item,
  validateApprovedRequest,
  chosenApprovedRequest,
  className = '',
}: NameErrorsTravelApprovalProps) => {
  const foundErrors = validateApprovedRequest.CartItemValidationStatuses ? validateApprovedRequest.CartItemValidationStatuses[item.Id] : [];

  if (!foundErrors?.length || foundErrors[0] === ApprovalWarning.valid) {
    return null;
  }

  const tooltipClassNames = [styles.tooltip];

  if (className) {
    tooltipClassNames.push(className);
  }

  const errorMessage = () => {
    const dates = formatRangeDateWithSimplicity(chosenApprovedRequest.StartDate, chosenApprovedRequest.EndDate);
    const destinations = chosenApprovedRequest.Destinations.map(({ Name }) => Name);
    const employees = chosenApprovedRequest.Employees.map(emp =>
      getEmployeeFullNameWithSimplicity(emp as IEmployeeFullNameObj, true)).join(', ');

    return foundErrors.map((error, ind) => {
      const text = () => {
        if (error === ApprovalWarning.wrongDates) {
          return `${LABELS.AVAILABLE_DATES} ${dates}`;
        }

        if (error === ApprovalWarning.wrongPlace) {
          return `${LABELS.AVAILABLE_DIRECTIONS} ${destinations}`;
        }

        return `${LABELS.SELECTED_EMPLOYEES} ${employees}`;
      };

      return (
        <li key={ `error_message_${error}_${ind}` }>
          { text() }
        </li>
      );
    });
  };

  return (
    <Tooltip
      position='bottom'
      className={ tooltipClassNames.join(' ') }
      renderContent={ () => (
        <div className={ styles['tooltip-wrapper'] }>
          <Text
            color='white'
            type='NORMAL_14_130'
          >
            { LABELS.WARNING } &nbsp; { errorMessage() }
          </Text>
        </div>
      ) }
    >
      <LinkButton
        className={ styles.link }
        theme='red'
      >
        { LABELS.WARNING }
      </LinkButton>
    </Tooltip>
  );
};

export { NameErrorsTravelApproval };
