import React from 'react';

import { IconButton, Suggest } from 'new-ui';
import { SuggestItems, SuggestNestedType, SuggestThemes } from 'new-ui/src/components/Suggest/types';

import { isSmartAgent } from '../../bi/utils/env';

import { IAirSearchRouteOpts, IAirSearchRouteItem } from '../../bi/types/airline';
import { ITrainSearchRoute } from '../../bi/types/trains';

import styles from './styles/index.module.css';

interface ISelectedRoutesProps {
  opts: IAirSearchRouteOpts,
  theme?: SuggestThemes,
  nestedType?: SuggestNestedType,
  keyExtractor?: (item: IAirSearchRouteItem | ITrainSearchRoute) => string,
  renderItem: (item: SuggestItems, value: string) => JSX.Element,
  onRevert: () => void,
  qaAttrs?: null | IQaAttrs,
  ind?: number,
}

interface IQaAttrs {
  from: string,
  loadingFrom?: string,
  loadingTo?: string,
  swap: string,
  to: string,
}

enum EnumDefaultProps {
  ARROWS_DOUBLE_GRAY = 'arrowsDoubleGray',
  ARROWS_DOUBLE_BLUE = 'arrowsDoubleBlue',
  THEME = 'light',
  NESTED_TYPE = 'row',
}

const SelectedRoutes = ({
  opts,
  theme = EnumDefaultProps.THEME,
  nestedType = EnumDefaultProps.NESTED_TYPE,
  keyExtractor = (item) => String(item.value),
  renderItem,
  onRevert,
  qaAttrs = null,
  ind = 0,
}: ISelectedRoutesProps) => {
  const {
    from: {
      value,
      placeholder,
      items,
      loading,
      ref,
      onSelect,
      onChange,
    },
    to: {
      value: valueTo,
      placeholder: placeholderTo,
      items: itemsTo,
      loading: loadingTo,
      ref: refTo,
      onSelect: onSelectTo,
      onChange: onChangeTo,
    },
  } = opts;

  const iconType = theme === EnumDefaultProps.THEME
    ? EnumDefaultProps.ARROWS_DOUBLE_GRAY
    : EnumDefaultProps.ARROWS_DOUBLE_BLUE;

  const wrapperClassNames = [styles.wrapper, styles[theme]];

  const qaAttrFromFirstEl = qaAttrs?.from ? `${qaAttrs.from}-first-el` : '';
  const qaAttrToFirstEl = qaAttrs?.to ? `${qaAttrs?.to}-first-el` : '';
  const qaAttrLoadingFrom = qaAttrs?.loadingFrom || '';
  const qaAttrLoadingTo = qaAttrs?.loadingTo || '';
  let qaAttrFrom = qaAttrs?.from || '';
  let qaAttrTo = qaAttrs?.to || '';
  let qaAttrSwap = qaAttrs?.swap || '';

  if (ind !== 0 && qaAttrs) {
    qaAttrFrom = `${qaAttrs.from}-${ind}`;
    qaAttrTo = `${qaAttrs.to}-${ind}`;
    qaAttrSwap = `${qaAttrs.swap}-${ind}`;
  }

  return (
    <form autoComplete={ 'off' } className={ wrapperClassNames.join(' ') }>
      <div className={ styles.suggest }>
        <Suggest
          isFetching={ loading }
          inputClassName={ styles.suggest_border }
          ref={ ref }
          value={ value }
          theme={ theme }
          placeholder={ placeholder }
          nestedType={ nestedType }
          items={ items }
          renderItem={ item => renderItem(item, value) }
          keyExtractor={ keyExtractor }
          onSelect={ onSelect }
          onChange={ onChange }
          qaAttr={ qaAttrFrom }
          qaAttrFirstEl={ qaAttrFromFirstEl }
          qaAttrLoadingSuggest={ qaAttrLoadingFrom }
        />
      </div>
      <IconButton
        tabIndex={ -1 }
        iconType={ iconType }
        onClick={ (e) => {
          e.preventDefault();
          onRevert();
        } }
        alternativeDesign={ isSmartAgent }
        qaAttr={ qaAttrSwap }
      />
      <div className={ styles.suggest }>
        <Suggest
          isFetching={ loadingTo }
          ref={ refTo }
          value={ valueTo }
          theme={ theme }
          placeholder={ placeholderTo }
          nestedType={ nestedType }
          items={ itemsTo }
          renderItem={ item => renderItem(item, valueTo) }
          keyExtractor={ keyExtractor }
          onSelect={ onSelectTo }
          onChange={ onChangeTo }
          qaAttr={ qaAttrTo }
          qaAttrFirstEl={ qaAttrToFirstEl }
          qaAttrLoadingSuggest={ qaAttrLoadingTo }
        />
      </div>
    </form>
  );
};

export { SelectedRoutes };
