import React from 'react';
import clsx from 'clsx';

import { AIRLINECLASSES } from '../../consts';

import { isSmartAgent } from '../../../../bi/utils/env';

import { PropertyType, AirlineClassType } from '../../types/types';

import styles from './styles.module.css';

interface SeatProps {
  number?: number | null;
  airlineClass: AirlineClassType;
  className?: string;
  selected?: boolean;
  active?: boolean;
  properties?: PropertyType[];
  onSelect?: () => void;
  example?: boolean;
  qaAttrUnselectedSeat?: string;
}

const getClassNameByAirlineClass = (airlineClass: AirlineClassType = 0): string => {
  if (airlineClass === AIRLINECLASSES.business) {
    return styles.seat__business;
  }

  if (airlineClass === AIRLINECLASSES.first) {
    return styles.seat__first;
  }

  return styles.seat__econom;
};

const getClassNameByProperties = (properties: PropertyType[]): string | null => {
  const notRecline = properties.includes('notRecline');
  const available = properties.includes('availability');
  const extraSpace = properties.includes('extraSpace');

  if (!available) {
    return styles.not_available;
  }

  if (extraSpace) {
    return styles.extra_space;
  }

  if (notRecline) {
    return styles.not_reclined;
  }

  return null;
};

const Seat = ({
  number = null,
  airlineClass,
  selected = false,
  active = false,
  properties = [],
  className = '',
  onSelect = () => {},
  example = false,
  qaAttrUnselectedSeat = '',
}: SeatProps) => {
  const classNames = clsx(
    styles.seat,
    getClassNameByAirlineClass(airlineClass),
    getClassNameByProperties(properties),
    {
      [styles.active]: active,
      [styles.selected]: selected && !isSmartAgent,
      [styles.selected_sa]: selected && isSmartAgent,
      [styles.example]: example,
      [className]: className,
      [styles.hover_sa]: isSmartAgent,
    },
  );

  return (
    <div data-qa={ qaAttrUnselectedSeat } onClick={ onSelect } className={ classNames }>
      {number}
    </div>
  );
};

export default Seat;
