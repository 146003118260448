// TODO: протипизировать ymaps при переходе на v3
// https://yandex.ru/dev/jsapi30/doc/ru/dg/concepts/typescript

const prepareRatingColor = (value: string | number): string => {
  const preparedNumber = Number(value);

  if (preparedNumber < 7) {
    return '#3A4252';
  }

  if (preparedNumber >= 7 && preparedNumber < 8.5) {
    return '#275A45';
  }

  return '#209866';
};

const prepareTitleFontSize = (name: string): string => {
  const { length } = name;

  if (length >= 35) {
    return '14px';
  }

  if (length >= 30) {
    return '16px';
  }

  if (length >= 25) {
    return '14px';
  }

  return '20px';
};

const myBalloonLayout = (ymaps: any) => {
  const BalloonLayout = ymaps.templateLayoutFactory.createClass(
    `<div class="ymap-popover" id="ymaps-balloon-popover">
      <div class="dark-layer">
      </div>
      <div class="close-wrapper" id="close">
      </div>
      <div class="popover-inner">
        $[[options.contentLayout]]
      </div>
    </div>`,
    {
      /**
       * Строит экземпляр макета на основе шаблона и добавляет его в родительский HTML-элемент.
       * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/layout.templateBased.Base.xml#build
       * @function
       * @name build
       */
      build() {
        BalloonLayout.superclass.build.call(this);

        this.element = document?.querySelector('#ymaps-balloon-popover')?.parentElement;

        this.applyElementOffset();

        this.element.querySelector('#close').addEventListener('click', this.onCloseClick.bind(this));

        this.element.addEventListener('mouseleave', () => {
          setTimeout(() => {
            this.events.fire('userclose');
          }, 500);
        });
      },

      /**
       * Удаляет содержимое макета из DOM.
       * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/layout.templateBased.Base.xml#clear
       * @function
       * @name clear
       */
      clear() {
        this.element.querySelector('#close').removeEventListener('click', this.onCloseClick);

        BalloonLayout.superclass.clear.call(this);
      },

      /**
       * Метод будет вызван системой шаблонов АПИ при изменении размеров вложенного макета.
       * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
       * @function
       * @name onSublayoutSizeChange
       */
      onSublayoutSizeChange() {
        /* eslint-disable-next-line */
        BalloonLayout.superclass.onSublayoutSizeChange.apply(this, arguments);

        if (!this.isElement(this.element)) {
          return;
        }

        this.applyElementOffset();

        this.events.fire('shapechange');
      },

      /**
       * Сдвигаем балун, чтобы "хвостик" указывал на точку привязки.
       * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
       * @function
       * @name applyElementOffset
       */
      applyElementOffset() {
        this.element.children[0].style.left = `${-this.element.children[0].offsetWidth / 2}px`;
        this.element.children[0].style.top = `${-this.element.children[0].offsetHeight}px`;
      },

      /**
       * Закрывает балун при клике на крестик, кидая событие "userclose" на макете.
       * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
       * @function
       * @name onCloseClick
       */
      onCloseClick(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        this.events.fire('userclose');
      },

      /**
       * Используется для автопозиционирования (balloonAutoPan).
       * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/ILayout.xml#getClientBounds
       * @function
       * @name getClientBounds
       * @returns {Number[][]} Координаты левого верхнего и правого нижнего углов шаблона относительно точки привязки.
       */
      getShape() {
        if (!this.isElement(this.element)) {
          return BalloonLayout.superclass.getShape.call(this);
        }

        const left = parseInt(this.element.children[0].style.left, 10);
        const top = parseInt(this.element.children[0].style.top, 10);
        const width = this.element.children[0].offsetWidth;
        const height = this.element.children[0].offsetHeight;

        return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
          [left, top], [
            left + width,
            top + height,
          ],
        ]));
      },

      /**
       * Проверяем наличие элемента (в ИЕ и Опере его еще может не быть).
       * @function
       * @private
       * @name isElement
       * @param [element] Элемент.
       * @returns {Boolean} Флаг наличия.
       */
      isElement(element: HTMLElement) {
        return element && element.children[0];
      },
    },
  );

  return BalloonLayout;
};

// Нигде не используется
const myBalloonContentLayout = (ymaps: any, generateLink: any, classificatorId: number, name: string, rating: string) => {
  const BalloonContentLayout = ymaps.templateLayoutFactory.createClass(
    `<div id="ymaps-balloon-content-layout">
      <div class="header">
       <div class="properties-wrapper">
         {% if properties.isSmart %}
           <img src={{ properties.smartHotelIcon }} class="smarthotel-icon"/>
         {% endif %}
         {% if !!properties.stars.length %}
           <div class="stars">
             {% for i in properties.stars %}
               <span class="smartway-star"></span>
             {% endfor %}
           </div>
         {% endif %}
        </div>
        <div class="image-wrapper">
         {% if properties.noPhoto %}
         <div class="wrapper">
           <div class="info">
             <img class="img" height="180" src={{ properties.img }} alt={ 'no-photo' } />
             <div class="text">{{ properties.labels.NO_PHOTO }}</div>
           </div>
         </div>
         {% endif %}
         {% if !properties.noPhoto %}
           <img class="img" height="180" src={{ properties.img }} alt="img" />
         {% endif %}
          <img src={{ properties.closeButtonIcon }} class="close-btn"/>
        </div>
        {% if properties.rating %}
          <div class="rating">
            {{ properties.rating }}
          </div>
        {% endif %}
      </div>
      <div class="content">
        <div class="title">
          <a href="#" target="_blank">{{ properties.name }}</a>
        </div>
        <div class="address">{{ properties.address }}</div>
      </div>
      <div class="footer">
        <div class="price-with-count">
          <div>
            {% if properties.price %}
              <span class="label">{{ properties.labels.FROM }}</span>
              <span class="value">{{ properties.price }}</span>
              <span class="label">₽</span>
            {% endif %}
          </div>
          {% if properties.nights %}
            <span class="nights">{{ properties.labels.FOR_AMOUNT }} {{ properties.nights }}</span>
          {% endif %}
        </div>
        <a href="#" class="btn" target="_blank">{{ properties.labels.SELECT_ROOM }}</a>
      </div>
    </div>`,
    {
      build() {
        BalloonContentLayout.superclass.build.call(this);

        document?.querySelector('.ymap-popover .header .image-wrapper .close-btn')?.addEventListener('click',
          this.onCloseClick.bind(this));

        this.titleLink = document.querySelector('#ymaps-balloon-content-layout .title a');
        this.buttonLink = document.querySelector('#ymaps-balloon-content-layout .btn');
        const ratingWrapper = document.querySelector<HTMLDivElement>('#ymaps-balloon-content-layout .rating');

        if (ratingWrapper) {
          ratingWrapper.style.backgroundColor = prepareRatingColor(rating);
        }

        this.titleLink.style.fontSize = prepareTitleFontSize(name);

        if (generateLink) {
          this.titleLink.href = generateLink(classificatorId);
          this.buttonLink.href = generateLink(classificatorId);
        } else {
          this.buttonLink.innerHTML = '';
        }
      },

      clear() {
        BalloonContentLayout.superclass.clear.call(this);
      },

      onCloseClick(e: MouseEvent) {
        e.preventDefault();

        this.events.fire('userclose');
      },
    },
  );

  return BalloonContentLayout;
};

const myMinBalloonContentLayout = (
  ymaps: any,
  generateLink: any,
  classificatorId: number,
  name: string,
  rating: string | number,
) => {
  const BalloonContentLayout = ymaps.templateLayoutFactory.createClass(
    `<div id="ymaps-balloon-content-layout">
      <div class="header">
       <div class="properties-wrapper">
         {% if properties.isSmart %}
           <img src={{ properties.smartHotelIcon }} class="smarthotel-icon"/>
         {% endif %}
         {% if !!properties.stars.length %}
           <div class="stars">
             {% for i in properties.stars %}
               <span class="smartway-star"></span>
             {% endfor %}
           </div>
         {% endif %}
        </div>
        <div class="image-wrapper">
         {% if properties.noPhoto %}
         <div class="wrapper">
           <div class="info">
             <img class="img" height="90" src={{ properties.img }} alt={ 'no-photo' } />
             <div class="text">{{ properties.labels.NO_PHOTO }}</div>
           </div>
         </div>
         {% endif %}
         {% if !properties.noPhoto %}
           <img class="img" height="90" src={{ properties.img }} alt="img" />
         {% endif %}
        </div>
        {% if properties.rating %}
          <div class="rating">
            {{ properties.rating }}
          </div>
        {% endif %}
      </div>
      <div class="content">
        <div class="title">
          <a href="#" target="_blank">{{ properties.name }}</a>
        </div>
        <div class="address">{{ properties.address }}</div>
      </div>
      <div class="footer">
        <div class="price-with-count">
          <div>
            {% if properties.price %}
              <span class="label">{{ properties.labels.FROM }}</span>
              <span class="value">
                {{ properties.price }}
              </span>
              <span class="label">₽</span>
            {% endif %}
          </div>
          {% if properties.nights %}
            <span class="nights">{{ properties.labels.FOR_AMOUNT }} {{ properties.nights }}</span>
          {% endif %}
        </div>
        <a href="#" class="btn" target="_blank">{{ properties.labels.SELECT_ROOM }}</a>
      </div>
       {% if properties.isSmartAgent && !!properties.agentFee && !!properties.agentMode %}
          <div class='agentFee'>
            <span class="text_fee">Сбор агентства</span>
            <div class='agentFee_wrapper'>
              {% if properties.isSmartAgent %}
                <span class="fee">{{ properties.agentFee }}</span>
                  <img src={{ properties.walletIcon }} class="fee_icon"/>
              {% endif %}
            </div>
          </div>
        {% endif %}
    </div>`,
    {
      build() {
        BalloonContentLayout.superclass.build.call(this);

        this.titleLink = document.querySelector('#ymaps-balloon-content-layout .title a');
        this.buttonLink = document.querySelector('#ymaps-balloon-content-layout .btn');
        const ratingWrapper = document.querySelector<HTMLDivElement>('#ymaps-balloon-content-layout .rating');

        if (ratingWrapper) {
          ratingWrapper.style.backgroundColor = prepareRatingColor(rating);
        }

        this.titleLink.style.fontSize = prepareTitleFontSize(name);

        if (generateLink) {
          this.titleLink.href = generateLink(classificatorId);
          this.buttonLink.href = generateLink(classificatorId);
        } else {
          this.buttonLink.innerHTML = '';
        }
      },

      clear() {
        BalloonContentLayout.superclass.clear.call(this);
      },
    },
  );

  return BalloonContentLayout;
};

export {
  myBalloonLayout,
  myBalloonContentLayout,
  myMinBalloonContentLayout,
};
