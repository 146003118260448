import { action, makeObservable, observable } from 'mobx';

import type { TransferSearch } from '../types';
import { PrepareTransferPlacesType, TransferFromToType } from '../../../types/transfer';

const DEFAULT_STORE = {
  transferFrom: {
    value: null,
    coordinates: {
      lat: null,
      lon: null,
    },
    selected: null,
    suggestions: [],
  },
  transferTo: {
    value: null,
    coordinates: {
      lat: null,
      lon: null,
    },
    selected: null,
    suggestions: [],
  },
};

export interface ITransferSearchStore {
  transferFrom: TransferSearch,
  transferTo: TransferSearch,
  searchId: string,
  textTemplateWarning: string,
  setSearchId(id: string): void,
  updateTransferFrom(transferFrom: PrepareTransferPlacesType): void,
  updateTransferTo(transferTo: PrepareTransferPlacesType): void,
  updateTransferFromTo(type: string, value: TransferSearch): void,
  swapPlaces(transferFrom: TransferSearch, transferTo: TransferSearch): void,
  setTextTemplateWarning(text: string): void,
  resetStore(): void,
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable transferFrom: TransferSearch = DEFAULT_STORE.transferFrom;
  @observable transferTo: TransferSearch = DEFAULT_STORE.transferTo;
  @observable searchId = '';
  @observable textTemplateWarning: string = '';

  @action
  setSearchId = (id: string) => {
    this.searchId = id;
  };

  @action
  updateTransferFrom = (transferFrom: TransferFromToType) => {
    this.transferFrom = {
      ...this.transferFrom,
      ...transferFrom,
    };
  };

  @action
  updateTransferTo = (transferTo: TransferFromToType) => {
    this.transferTo = {
      ...this.transferTo,
      ...transferTo,
    };
  };

  @action
  updateTransferFromTo = (type: string, value: TransferSearch) => {
    // @ts-ignore
    this[type] = {
      // @ts-ignore
      ...this[type],
      ...value,
    };
  };

  @action
  swapPlaces = (transferFrom: TransferSearch, transferTo: TransferSearch) => {
    this.transferFrom = transferTo;
    this.transferTo = transferFrom;
  };

  @action
  setTextTemplateWarning = (text: string) => {
    this.textTemplateWarning = text;
  };

  @action
  resetStore = () => {
    this.transferFrom = DEFAULT_STORE.transferFrom;
    this.transferTo = DEFAULT_STORE.transferTo;
    this.searchId = '';
  };
}

const TransferSearchStore = new Store();

export { TransferSearchStore };
