import { observable, action, makeObservable } from 'mobx';

import { RequestApprovesType } from '../consts';

export class ApprovesStore {
  constructor() {
    makeObservable(this);
  }

  @observable approves: RequestApprovesType[] = [];
  @observable loading = true;

  @action
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  setApproves = (list: RequestApprovesType[]) => {
    this.approves = list;
  };

  @action
  resetStore = () => {
    this.approves = [];
    this.loading = true;
  };
}

export const approvesStore = new ApprovesStore();
