import { action, makeObservable, observable } from 'mobx';

export interface INewHotelsStore {
  mapInstance: any | null,
  clusterInstance: any | null,
  visiblePlacemarksIds: number[],
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable mapInstance: any = null;
  @observable clusterInstance: any = null;

  @observable visiblePlacemarksIds: number[] = [];

  @action
  setMapInstance = (value: any): void => {
    this.mapInstance = value;
  };

  @action
  setClusterInstance = (value: any): void => {
    this.clusterInstance = value;
  };

  @action
  setVisiblePlacemarksIds = (value: number[]): void => {
    this.visiblePlacemarksIds = value;
  };
}

const NewHotelsStore = new Store();

export { NewHotelsStore, Store as NewHotelsType };
