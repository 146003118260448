import { observable, computed, action, makeObservable } from 'mobx';

interface Banner {
  name: string,
  shown: boolean,
}
export interface IBannersStore {
  bannersState: { [key: string]: boolean },
  banners: Banners,
  activeBanner: Banner | null,
  isShown: boolean,
}

type Banners = { [key: string]: boolean };

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable bannersState: Banners = {};
  @observable banners: Banners = {};
  @observable activeBanner: Banner | null = null;
  @observable isShown = false;

  @action
  setBannersState(data: Banners) {
    this.bannersState = data;
  }

  @computed
  get getBanners(): Banner[] {
    return Object.keys(this.banners)
      .sort((a, b) => Number(a[0]) - Number(b[0]))
      .reduce<Banner[]>((result, k) => {
      const shown = this.banners[k];

      if (!shown) {
        return [...result, { name: k, shown }];
      }

      return result;
    }, []);
  }

  @action
  addBanner(payload: Banner) {
    this.banners = {
      ...this.banners,
      [payload.name]: payload.shown,
    };
  }

  @action
  updateBanner(payload: Banner) {
    this.banners = {
      ...this.banners,
      [payload.name]: !payload.shown,
    };
  }

  @action
  setActiveBanner(value: Banner | null) {
    this.activeBanner = value;
    this.isShown = true;
  }
}

const BannersStore = new Store();

export { BannersStore, Store as BannersStoreType };
