import { observable, action, makeObservable } from 'mobx';
import { Approval } from '../types';

export class ExpenseReportApproveStore {
  constructor() {
    makeObservable(this);
  }

  @observable approves: Approval[] = [];
  @observable loading: boolean = true;
  @observable approveId: null | any = null;

  @action
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  setApproves = (list: Approval[]) => {
    this.approves = list;
  };

  @action
  setApproveId = (id: null | any) => {
    this.approveId = id;
  };
}

export const expenseReportApproveStore = new ExpenseReportApproveStore();
