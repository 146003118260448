import React from 'react';
import { Text } from 'new-ui';
import { getText } from '../../../i18n';

import styles from './index.module.css';

interface DateInSearchProps {
  name: string | string[],
  date: string | string[],
}

const LABELS = {
  TRIP: getText('components:trip.trip'),
  DATE: getText('components:trip.date'),
};

const DateInSearch = ({ name, date }: DateInSearchProps) => (
  <div className={ styles.wrapper }>
    <div className={ styles.item }>
      <Text className={ styles.text } type='NORMAL_14'>{`${LABELS.TRIP}`}</Text>
      <Text className={ styles.text } type='SEMIBOLD_14'>{name}</Text>
    </div>
    <div className={ styles.item }>
      <Text className={ styles.text } type='NORMAL_14'>{`${LABELS.DATE}`}</Text>
      <Text className={ styles.text } type='SEMIBOLD_14'>{date}</Text>
    </div>
  </div>
);

export { DateInSearch };
