import { Moment } from 'moment';

import { getText } from '../../../i18n';

import { diffHours, diffMinutes } from './formatDate';
import { lpad } from './pad';

const LABELS = {
  commonSecondsToLabel: {
    notHours: (min: number) => getText('utils:time.commonSecondsToLabel.notHours', { min }),
    notMinutes: (hours: number) => getText('utils:time.commonSecondsToLabel.notMinutes', { hours }),
    default: (hours: number, minutes: number) => getText('utils:time.commonSecondsToLabel.default', { hours, minutes }),
  },
  secondsToLabelsWithDays: {
    notHours: (days: number, min: string) => getText('utils:time.secondsToLabelsWithDays.notHours', { days, min }),
    notMinutes: (days: number, hours: number) => getText('utils:time.secondsToLabelsWithDays.notMinutes', { days, hours }),
    default: (days: number, hours: number, min: string) => getText('utils:time.secondsToLabelsWithDays.default', { days, hours, min }),
  },
};

const commonSecondsToLabel = (hours: number, minutes: number) => {
  if (!hours) {
    return LABELS.commonSecondsToLabel.notHours(minutes);
  }

  if (!minutes) {
    return LABELS.commonSecondsToLabel.notMinutes(hours);
  }

  return LABELS.commonSecondsToLabel.default(hours, minutes);
};

const secondsToLabelsWithDays = (value: number) => {
  const minutes = Math.floor(value / 60) % 60;
  const hours = Math.floor(value / 3600) % 24;
  const days = Math.floor(value / 86400);

  if (!days) {
    return commonSecondsToLabel(hours, minutes);
  }

  if (!hours) {
    return LABELS.secondsToLabelsWithDays.notHours(days, lpad(minutes));
  }

  if (!minutes) {
    return LABELS.secondsToLabelsWithDays.notMinutes(days, hours);
  }

  return LABELS.secondsToLabelsWithDays.default(days, hours, lpad(minutes));
};

const secondsToLabel = (value: number, isDays = false) => {
  if (isDays) return secondsToLabelsWithDays(value);

  const minutes = Math.floor(value / 60) % 60;
  const hours = Math.floor(value / 3600);

  return commonSecondsToLabel(hours, minutes);
};

const diffMinutesAndHours = (first: Moment, second: Moment) => {
  const minutes = Number(diffMinutes(first, second)) % 60;
  const hours = diffHours(first, second);

  return commonSecondsToLabel(hours, minutes);
};

export { secondsToLabel, diffMinutesAndHours };
