import React, { Component } from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../i18n';

import CONFIG from '../../../config';

import { isSmartAgent } from '../../bi/utils/env';

import WorkspaceService from '../../bi/services/workspace';
import ChatService from '../../bi/services/chat';
import AppService from '../../bi/services/app';

import styles from './styles/inetercom.module.css';

const LABELS = {
  TITLE_ONE: getText('components:intercom.fake.title.one'),
  TITLE_TWO: getText('components:intercom.fake.title.two'),
  TITLE_TWO_SMARTAGENT: getText('components:intercom.fake.title.twoSmartagent'),
  DESCRIPTION: getText('components:intercom.fake.description'),
  IF_CLIENT_ONE: getText('components:intercom.fake.ifClient.one'),
  IF_CLIENT_TWO: getText('components:intercom.fake.ifClient.two'),
  IF_CLIENT_THREE: getText('components:intercom.fake.ifClient.three'),
  TEL: (telNumber: string) => getText('components:intercom.fake.tel', { telNumber }),
};

interface IntercomProps {
  appId: string,
  workspaceService: WorkspaceService,
  appService: AppService,
  chatService: ChatService,
}

interface IntercomState {
  fakeOpen: boolean,
}

class Intercom extends Component<IntercomProps, IntercomState> {
  unsubscribeChatFn: () => void;

  constructor(props: IntercomProps) {
    super(props);

    this.state = {
      fakeOpen: false,
    };

    this.unsubscribeChatFn = props.chatService.subscribe(this.updateState);
  }

  componentDidMount() {
    this.initializeIntercom();
  }

  componentWillUnmount() {
    this.props.chatService.closeSession();

    this.unsubscribeChatFn();
  }

  updateState = () => {
    const store = this.props.chatService.get();

    this.setState({
      fakeOpen: store.show,
    });
  };

  initializeIntercom = () => {
    const { workspaceService, chatService } = this.props;
    const { Email } = workspaceService.get();

    if (CONFIG.INTERCOM.SWITCHON && (chatService.hasDisabledUser(Email) || !workspaceService.canUseIntercom)) {
      chatService.createFakeIntercomObj();
    } else if (CONFIG.INTERCOM.SWITCHON) {
      const ic = window.Intercom;
      const appId = this.props.appId;

      if (!ic) {
        // eslint-disable-next-line
        (function (w, d, id, s: any, x: any) {
          function i() {
            // eslint-disable-next-line
            i.c(arguments);
          }
          // @ts-ignore
          i.q = [];
          // eslint-disable-next-line
          i.c = function (args: any) {
            i.q.push(args);
          };
          // @ts-ignore
          // eslint-disable-next-line
          w.Intercom = i;
          // eslint-disable-next-line
          s = d.createElement('script');
          // eslint-disable-next-line
          s.src = `https://widget.intercom.io/widget/${id}`;
          // eslint-disable-next-line
          x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        }(window, document, appId, undefined, undefined));
      }
    }
  };

  handleClose = () => {
    // @ts-ignore
    this.props.chatService.chat.hide();
  };

  handleLogin = () => {
    this.props.appService.showLogin();
  };

  renderFakeWindow = () => {
    const telNumber = this.props.workspaceService.getPhoneForIntercom();
    const titleTwo = isSmartAgent ? LABELS.TITLE_TWO_SMARTAGENT : LABELS.TITLE_TWO;

    return (
      <div className={ styles.wrap }>
        <div className={ styles.header }>
          <div className={ styles.title }>
            <div className={ styles.main }>{LABELS.TITLE_ONE}</div>
            <div className={ styles.sub }>{ titleTwo } </div>
          </div>
          <div
            className={ styles.cross }
            onClick={ this.handleClose }
          >
            <i className='material-icons'>clear</i>
          </div>
        </div>
        <div className={ styles.body }>
          <Text type='NORMAL_16_130' className={ styles.text }>
            {LABELS.DESCRIPTION}
          </Text>
          <Text type='NORMAL_16_130' className={ styles.text }>
            {LABELS.IF_CLIENT_ONE}
            {' '}
            <a className={ styles.link } onClick={ this.handleLogin }>{LABELS.IF_CLIENT_TWO}</a>
            {' '}
            {LABELS.IF_CLIENT_THREE}</Text>
          <Text type='NORMAL_16_130' className={ styles.text }>
            {LABELS.TEL(telNumber)}
          </Text>
        </div>
      </div>
    );
  };

  render() {
    const { fakeOpen } = this.state;

    if (fakeOpen) return this.renderFakeWindow();

    return false;
  }
}

export default Intercom;
