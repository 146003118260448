import { getText } from '../../../i18n';

import { IEmployeeFieldDefault } from '../services/employee/types';

const LABELS = {
  ARCHIVED: getText('settings:travelers.archived'),
  ACTIVE: getText('settings:travelers.active'),
};

const STATUS = {
  ARCHIVE: 'Archived',
  ACCESS: {
    ALL: 'All',
    USER: 'User',
    ACTIVE: 'Active',
  },
  ACTIVE_SIGNATURE: 'ActiveSignature',
  NO_DEPARTMENT: 'NoDepartment',
};

const EMPLOYEEFIELD: IEmployeeFieldDefault = {
  SURNAME: 'Surname',
  NAME: 'Name',
  PATRONYMIC: 'Patronymic',
  BIRTHDAY: 'Birthday',
  CODE: 'Code',
  SEX: 'Sex',
  MOBILEPHONE: 'MobilePhone',
  EMAIL: 'Email',
  EMPLOYEE_NUMBER: 'EmployeeNumber',
  TRAVEL_POLICY: 'TpRights',
};

const NAMESAKES: IEmployeeFieldDefault = {
  Birthday: 'Birthday',
  Passport: 'Passport',
  Email: 'Email',
  NotDefined: 'NotDefined',
};

const ROLES_TYPE: IEmployeeFieldDefault = {
  PROJECT: 'project',
  DEPARTMENT: 'department',
};

const EMPLOYEE_ROLES = {
  DEPARTMENT_HEAD: 1,
  PROJECT_HEAD: 2,
};

const LANGUAGES: IEmployeeFieldDefault = {
  EN: 'EN',
  RU: 'RU',
  ERR: 'ERR',
};

const DOCUMENTS_TYPE: { [key: string]: string } = {
  DomesticPassport: 'DOMESTIC_PASSPORT',
  DomesticForeignPassport: 'FOREIGNER_DOMESTIC_PASSPORT',
  RU_PASSPORT: 'RU_PASSPORT',
  ForeignPassport: 'FOREIGN_PASSPORT',
  BirthCertificate: 'BIRTH_CERTIFICATE',
};

const TRAVELERS_STATUS_TYPE = [
  { label: LABELS.ACTIVE, value: STATUS.ACCESS.ACTIVE },
  { label: LABELS.ARCHIVED, value: STATUS.ARCHIVE },
];

const TRAVELERS_STEP = 40;

const FILTER_KEYS = {
  SEARCH: 'search',
  COMPANY_ID: 'companyId',
  SELECT: 'select',
};

const SIGNATURE_DATA_FIELDS = {
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
};

enum VALUE_FIELD_FILTERS {
  NEW_NULL = -1,
}

export {
  STATUS as default,
  EMPLOYEEFIELD,
  NAMESAKES,
  ROLES_TYPE,
  EMPLOYEE_ROLES,
  LANGUAGES,
  DOCUMENTS_TYPE,
  TRAVELERS_STEP,
  FILTER_KEYS,
  TRAVELERS_STATUS_TYPE,
  SIGNATURE_DATA_FIELDS,
  VALUE_FIELD_FILTERS,
};
