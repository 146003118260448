import React, { Component } from 'react';
import { Moment } from 'moment';
import { Text, TextColor } from 'new-ui';
import { clearInterval, setInterval } from 'worker-timers';

import { getText } from '../../../i18n';

import trimTimezone from '../../bi/utils/trimTimezone';
import diffTime from '../../bi/utils/diffTime';
import { momentObject } from '../../bi/utils/formatDate';

const formatTimer = (days: number, hours: number, minutes: number, seconds: number) => (days ?
  getText('components:timer.withDays', { days, hours, minutes, seconds }) :
  getText('components:timer.withoutDays', { hours, minutes, seconds })
);

interface ITimerProps {
  deadLine: string,
  onTimeIsUp: () => void,
  currentTime: Moment,
  className?: string,
  color?: TextColor,
}

interface ITimerState {
  time: string,
  deadLineDate: Moment,
  expired: boolean,
  currentTime: Moment,
}

const SECOND = 1000;

class Timer extends Component<ITimerProps, ITimerState> {
  private timer: number;

  constructor(props: ITimerProps) {
    super(props);

    const { deadLine, currentTime } = props;

    const deadLineDate = momentObject(trimTimezone(deadLine));

    if (!deadLineDate.isValid()) {
      throw new Error('deadLine string not valid');
    }

    if (deadLineDate.isBefore(currentTime)) {
      window.console.info('deadline before current time');
    }

    let time = '';
    let expired = false;

    if (currentTime.isAfter(deadLineDate)) {
      time = getText('components:timer.expiredDate');
      expired = true;
    } else {
      const timeInfo = diffTime(deadLineDate, currentTime);

      time = timeInfo ? formatTimer(timeInfo.days, timeInfo.hours, timeInfo.minutes, timeInfo.seconds) : '';
    }

    this.state = {
      time,
      deadLineDate,
      expired,
      currentTime,
    };
  }

  componentDidMount() {
    if (!this.state.expired) {
      this.timer = setInterval(this.updateTimer, SECOND);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  updateTimer = () => {
    this.setState({ currentTime: momentObject(this.state.currentTime).add(1, 's') }, () => {
      const timeInfo = diffTime(this.state.deadLineDate, this.state.currentTime);

      if (timeInfo!.seconds < 0) {
        this.componentWillUnmount();
        this.props.onTimeIsUp();
      } else {
        const time = formatTimer(timeInfo!.days, timeInfo!.hours, timeInfo!.minutes, timeInfo!.seconds);

        this.setState({
          time,
        });
      }
    });
  };

  render() {
    const { className, color } = this.props;
    const { time } = this.state;

    const textColor = color || 'green';

    return (
      <Text type='SEMIBOLD_14' color={ textColor } className={ className }>
        { time }
      </Text>
    );
  }
}

export default Timer;
