import { action, computed, makeObservable, observable } from 'mobx';

import { TagType, FilterType, TransferType, SearchObject, CartItemType } from '../types';
import { PrepareRateType } from '../../../types/transfer';
import { TravelPolicyItem } from '../../userSession/types';

import { TRAVELPOLICYFILTER } from '../../../constants/travelPolicy';
import { FILTER_TYPE, SORT_FIELDS, VALUES_TABLETS } from '../../../constants/transfers';
import { TRANSFERFIELDS } from '../../../constants/transfer';

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable loading = false;
  @observable showSubMenu = false;
  @observable sources: PrepareRateType[] = [];
  @observable items: PrepareRateType[] = [];
  @observable history: SearchObject[] = [];
  @observable tags: TagType[] = [];
  @observable sortBy: string = SORT_FIELDS.PRICE_UP;
  @observable isAnyFavorite = false;
  @observable unavailableTravelPolicy = false;
  @observable travelPolicyAllList: TravelPolicyItem[] = [];
  @observable filter: FilterType = {
    price: {
      min: 0,
      max: 100000,
      from: 0,
      to: 5000,
    },
    carClass: {},
    table: VALUES_TABLETS.WITHOUT_TABLET,
    travelPolicyList: [],
    selectedTravelPolicy: TRAVELPOLICYFILTER.NOTAPPLIED,
  };
  @observable validationTransferFields = false;
  @observable transferCart: any[] = [];

  @action
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  setShowSubMenu = (value: boolean) => {
    this.showSubMenu = value;
  };

  @action
  setSources = (sources: PrepareRateType[], travelPolicy: TravelPolicyItem[]) => {
    this.sources = sources;
    this.travelPolicyAllList = travelPolicy;
    this.createFilter();
    this.items = this.sortedRates;
  };

  @computed
  get sortItems() {
    const order = this.sortBy === 'price_down' ? -1 : 1;

    const priceSort = (first: { Price: number }, second: { Price: number }) => {
      if (first.Price > second.Price) return order;

      if (first.Price < second.Price) return -order;

      return 0;
    };

    return this.applyFilter.sort(priceSort);
  }

  @action
  setSortBy = (value: string) => {
    this.sortBy = value;
    this.items = this.sortedRates;
  };

  @computed
  get sortedRates() {
    const order = this.sortBy === 'price_down' ? -1 : 1;

    const priceSort = (first: { Price: number }, second: { Price: number }) => {
      if (first.Price > second.Price) return order;

      if (first.Price < second.Price) return -order;

      return 0;
    };

    return this.applyFilter.sort(priceSort);
  }

  @computed
  get applyFilter() {
    return this.sources.filter(({
      Price,
      Class,
      AdditionalServices,
    }) => {
      const isPrice = (Price >= this.filter.price.from && Price <= this.filter.price.to);
      const carClassField = Object.keys(this.filter.carClass);
      const isAnyClassSelected = carClassField.filter(field => this.filter.carClass[field]).length > 0;
      const isClass = isAnyClassSelected ? this.filter.carClass[Class] : true;
      const isTable = this.filter.table === VALUES_TABLETS.WITH_TABLET
        ? AdditionalServices.includes(FILTER_TYPE.TABLE)
        : !AdditionalServices.includes(FILTER_TYPE.TABLE);

      return isPrice && isClass && isTable;
    });
  }

  sortedAlreadyInCart = (items: PrepareRateType[], id: string) => {
    const newItems = items.map(item => {
      if (item.RateId !== id) {
        return item;
      }

      return {
        ...item,
        AlreadyInCart: true,
      };
    });

    return newItems;
  };

  @action
  setAlreadyInCart = (id: string) => {
    const newSources = this.sortedAlreadyInCart(this.sources, id);
    const newItems = this.sortedAlreadyInCart(this.items, id);

    this.sources = newSources;
    this.items = newItems;
  };

  @action
  setTransferCart = (items: TransferType) => {
    this.transferCart.push(items);

    this.setValidationTransferFields();
  };

  @action
  checkValidTime = (time: string) => {
    if (!time || time.length < 5) {
      return false;
    }

    return true;
  };

  @action
  setValidationTransferFields = () => {
    let validate = false;
    this.transferCart.forEach(transfer => {
      const {
        AdditionalData,
        TimeDeparture,
        TimeArrival,
        AllowBooking,
      } = transfer;

      if (!AdditionalData.includes(TRANSFERFIELDS.DateDeparture) && AdditionalData.includes(TRANSFERFIELDS.DateArrival)) {
        AdditionalData.forEach((data: string) => {
          if (!transfer[data] || !this.checkValidTime(TimeArrival) || !AllowBooking) {
            validate = true;
          }
        });
      } else {
        AdditionalData.forEach((data: string) => {
          if (!transfer[data] || !this.checkValidTime(TimeArrival) || !this.checkValidTime(TimeDeparture) || !AllowBooking) {
            validate = true;
          }
        });
      }
    });

    this.validationTransferFields = validate;
  };

  @action
  updateTransferCart = (item: CartItemType) => {
    this.transferCart = this.transferCart.map(transfer => (transfer.BookId === item.BookId ? item : transfer));

    this.setValidationTransferFields();
  };

  @action
  deleteTransferCart = (item: { BookId: string }) => {
    this.transferCart = this.transferCart.filter(({ BookId }) => BookId !== item.BookId);

    this.setValidationTransferFields();
  };

  @action
  deleteTransfers = () => {
    this.transferCart = [];
  };

  @action
  setHistory = (history: SearchObject[]) => {
    this.history = history;
  };

  @action
  setTags = (tags: Array<TagType>) => {
    this.tags = tags;
  };

  @action
  setFailTransfer = () => {
    this.items = [];
    this.loading = false;
    this.showSubMenu = false;
  };

  @action
  resetStore = () => {
    this.loading = false;
    this.showSubMenu = false;
    this.sources = [];
    this.items = [];
    this.history = [];
    this.tags = [];
    this.sortBy = SORT_FIELDS.PRICE_UP;
    this.isAnyFavorite = false;
    this.filter = {
      price: {
        min: 0,
        max: 100000,
        from: 0,
        to: 5000,
      },
      carClass: {},
      table: VALUES_TABLETS.WITHOUT_TABLET,
      travelPolicyList: [],
      selectedTravelPolicy: TRAVELPOLICYFILTER.NOTAPPLIED,
    };
  };

  @action
  resetFilters = () => {
    const carClass: { [x: string]: boolean } = {};

    Object.keys(this.filter.carClass).forEach((field: string) => {
      carClass[field] = false;
    });

    this.filter = {
      ...this.filter,
      carClass,
      price: {
        ...this.filter.price,
        from: this.filter.price.min,
        to: this.filter.price.max,
      },
      table: VALUES_TABLETS.WITHOUT_TABLET,
      selectedTravelPolicy: TRAVELPOLICYFILTER.NOTAPPLIED,
    };

    this.items = this.sortedRates;
  };

  @action
  updateTravelPolicyFilter = (value: any) => {
    this.filter = {
      ...this.filter,
      selectedTravelPolicy: value === TRAVELPOLICYFILTER.NOTAPPLIED ? TRAVELPOLICYFILTER.NOTAPPLIED : value.value,
    };
    this.items = this.sortedRates;
  };

  @action
  updateCarClassFilter = (field: string, value: boolean | { value: string }) => {
    this.filter = {
      ...this.filter,
      carClass: {
        ...this.filter.carClass,
        [field]: value,
      },
    };
    this.items = this.sortedRates;
  };

  @action
  updateTableFilter = (value: string) => {
    this.filter = {
      ...this.filter,
      table: value,
    };
    this.items = this.sortedRates;
  };

  @action
  updatePriceFilter = (from: number, to: number) => {
    this.filter.price = {
      ...this.filter.price,
      from,
      to,
    };
    this.items = this.sortedRates;
  };

  @action
  createFilter = () => {
    const carClass: { [x: string]: boolean } = {};
    let min = 0;
    let max = 0;
    const travelPolicyList: string[] = [];

    travelPolicyList.push(TRAVELPOLICYFILTER.NOTAPPLIED);

    this.sources.forEach(({ Price, Class }) => {
      const price = Price;
      min = Math.min(min, price);
      max = Math.max(max, price);
      carClass[Class] = false;
    });

    this.travelPolicyAllList.forEach((tp) => {
      if (tp.TransferVoucherRule.Apply && !travelPolicyList.some(tpInList => tpInList === tp.Id)) {
        travelPolicyList.push(tp.Id);
      }
    });

    this.filter = {
      ...this.filter,
      price: {
        from: min,
        to: max,
        min,
        max,
      },
      carClass,
      travelPolicyList,
      table: VALUES_TABLETS.WITHOUT_TABLET,
    };
  };

  @action
  setItemsFavorite = (items: PrepareRateType[], id: string, favoriteId: string) => {
    const newSources = items.map(item => {
      if (item.Class !== id) {
        return item;
      }

      return {
        ...item,
        FavoriteId: favoriteId,
      };
    });

    return newSources;
  };

  @action
  setFavorite = (id: string, favoriteId: string) => {
    const transfer = this.sources.find(({ Class }) => Class === id);

    if (transfer) {
      this.sources = this.setItemsFavorite(this.sources, id, favoriteId);
      this.items = this.setItemsFavorite(this.items, id, favoriteId);
    }

    this.isAnyFavorite = this.sources.some(({ FavoriteId }) => FavoriteId);
  };

  @action
  isEmpty = () => !this.loading && !this.items.length;
}

const TransferStore = new Store();

export interface ITransferStore {
  loading: boolean,
  showSubMenu: boolean,
  sources: PrepareRateType[],
  items: PrepareRateType[],
  history: SearchObject[],
  tags: TagType[],
  sortBy: string,
  isAnyFavorite: boolean,
  unavailableTravelPolicy: boolean,
  travelPolicyAllList: TravelPolicyItem[],
  filter: FilterType,
  validationTransferFields: boolean,
  transferCart: any[],
  setLoading(value: boolean): void;
  setShowSubMenu(value: boolean): void;
  setSources(sources: PrepareRateType[], travelPolicy: TravelPolicyItem[]): void;
  setSortBy(value: string): void;
  setAlreadyInCart(id: string): void;
  setTransferCart(items: TransferType): void;
  checkValidTime(time: string): void;
  setValidationTransferFields(): void;
  updateTransferCart(item: CartItemType): void;
  deleteTransferCart(item: { BookId: string }): void;
  deleteTransfers(): void;
  setHistory(history: SearchObject[]): void;
  setTags(tags: Array<TagType>): void;
  setFailTransfer(): void;
  resetStore(): void;
  resetFilters(): void;
  updateTravelPolicyFilter(value: boolean | string | { value: string } | number): void;
  updateCarClassFilter(field: string, value: boolean | string | { value: string } | number): void;
  updateTableFilter(value: boolean | string | { value: string } | number): void;
  updatePriceFilter(from: number, to: number): void;
  createFilter(): void;
  setItemsFavorite(items: PrepareRateType[], id: string, favoriteId: string): void;
  setFavorite(id: string, favoriteId: string): void;
  isEmpty(): boolean;
}

export { TransferStore };
