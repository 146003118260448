import { ReportData } from './types';

export const DEFAULT_ANALYTICS_SMARTAGENT: ReportData = {
  totalAmount: '',
  table: {
    columnNames: [''],
    strings: [
      {
        serviceType: '',
        data: [''],
      },
      {
        serviceType: '',
        data: [''],
      },
    ],
  },
  chart: null,
  donutChart: {
    values: [],
    labels: [],
    dates: null,
    subPageInfo: null,
  },
  employees: null,
  departments: null,
  projects: null,
  customAnalytics: null,
  tags: null,
};

export const COLORS_ANALYTICS_SA = [
  '#F19E40',
  '#50A162',
  '#BECFE8',
  '#F8E211',
  '#CE2828',
  '#FFD2D2',
  '#D3F0E4',
  '#F1F3F6',
];
