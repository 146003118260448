import React, { FC, useEffect } from 'react';
import { Text } from 'new-ui';

import { MainAnalytic } from '../../../bi/utils/analytics';

import ROUTES from '../../../bi/constants/routes';

import { TransferMainMenu } from './components/MainMenu';
import { TransferSubMenu } from './components/SubMenu';

import { TransferSearchMenuProps, ISelectedSuggest } from '../../../bi/types/transfer';

const TransferSearchMenu: FC<TransferSearchMenuProps> = ({
  subMenu,
  onSearch,
  transferService,
  getShowElementForSmartagent,
}) => {
  useEffect(() => () => {
    if (!document.location.pathname.includes(ROUTES.SEARCH.TRANSFER)) {
      transferService.resetStore();
    }
  }, []);

  const swapPlaces = () => {
    MainAnalytic.send(MainAnalytic.CATEGORY.TRANSFER, MainAnalytic.ACTIONS.TRANSFER.CHANGEDIRECTION);

    transferService.swapPlaces();
  };

  const updateSuggestions = (type: string, value: string) => {
    transferService.updateSuggestions(type, value);
  };

  const selectedSuggest = (type: string, item: ISelectedSuggest) => {
    transferService.selectedSuggest(type, item);
  };

  const renderSuggestion = ({ Address }: { Address: string }) => <Text type='NORMAL_16'>{ Address }</Text>;

  const props = {
    onSearch,
    transferService,
    swapPlaces,
    updateSuggestions,
    selectedSuggest,
    renderSuggestion,
    getShowElementForSmartagent,
  };

  const renderSubMenu = () => (
    <TransferSubMenu { ...props }/>
  );

  const renderPanelMenu = () => (
    <TransferMainMenu { ...props }/>
  );

  return subMenu
    ? renderSubMenu()
    : renderPanelMenu();
};

TransferSearchMenu.defaultProps = {
  subMenu: false,
};

export default TransferSearchMenu;
