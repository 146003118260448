import React from 'react';
import { Text, TextColor } from 'new-ui';

import { getText } from '../../../i18n';

import TaxiDetailsPrice from '../TaxiDetailsPrice';

import MoneyFormat from '../../bi/utils/money';
import { getDetailsAmount } from '../../bi/utils/taxi';

import styles from './styles/index.module.css';

const LABELS = {
  SPENT: getText('components:priceBalance.spent'),
  OUT: getText('components:priceBalance.out'),
};

interface PriceBalanceProps {
  currentAmount: number,
  beginAmount: number,
}

const PriceBalance = ({
  currentAmount,
  beginAmount,
}: PriceBalanceProps) => {
  const renderDetailsPrice = () => {
    const detailsAmount = getDetailsAmount(beginAmount);

    return (
      <div className={ styles.block }>
        <TaxiDetailsPrice
          tariffAmount={ detailsAmount.tariff }
          vatAmount={ detailsAmount.vat }
        />
      </div>
    );
  };

  const renderPrice = (amount: number, text: string, color: TextColor) => (
    <div className={ styles.block }>
      <Text type='NORMAL_14' color={ color }>
        { text }
      </Text>
        &nbsp;
      <Text type='bold_24' color={ color }>
        { MoneyFormat.moneyWithDecimal(amount) }
      </Text>
      <Text className={ styles.currency_small } color={ color }>
        ₽
      </Text>
    </div>
  );

  const spentAmount = beginAmount - currentAmount;

  return (
    <div className={ styles.wrapper }>
      { renderPrice(spentAmount, LABELS.SPENT, 'default') }
      { renderPrice(beginAmount, LABELS.OUT, 'gray') }
      { renderDetailsPrice() }
    </div>
  );
};

export { PriceBalance };
