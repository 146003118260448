import { Component } from 'react';

import { WorkspaceService } from '../../bi/types/workspace';

declare global {
  interface Window {
    ga: (action: string, item: string, companyId?: string) => void;
  }
}

interface GoogleAnalyticProps {
  workspaceService: WorkspaceService;
}

class GoogleAnalytic extends Component<GoogleAnalyticProps> {
  constructor(props: GoogleAnalyticProps) {
    super(props);

    const data = props.workspaceService.get();

    this.initializeGoogleAnalytic(data);
  }

  initializeGoogleAnalytic = (data: { CompanyId: number }) => {
    const companyId = data.CompanyId.toString();

    if (typeof (window.ga) !== 'undefined') {
      window.ga('set', 'userId', companyId);
      window.ga('send', 'pageview');
    }
  };

  render() {
    return null;
  }
}

export default GoogleAnalytic;
