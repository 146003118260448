import { getText } from '../../../i18n';

const FLIGHT_TYPE_FORMAT = {
  DOMESTIC: 'Domestic',
  INTERNATIONAL: 'International',
};

const FIELDS_DIRECTION = {
  DEPARTURE: 'departure',
  ARRIVAL: 'arrival',
  TRANSIT: 'transit',
};

const SORT_TYPES = {
  BY_DESCENDING: 'priceByDescending',
  BY_ASCENDING: 'priceByAscending',
};

const SORT_FIELDS = {
  PRICE_UP: 'priceUp',
  PRICE_DOWN: 'priceDown',
};

const FAST_TRACK_TYPES = {
  MEET_AND_ASSIST: 'meet-and-assist',
  FAST_TRACK: 'fast-track',
};

const VIP_HALL_TYPES = {
  VIP_HALL: 'vip-hall',
  BUSINESS_HALL: 'business-zal',
};

const ADDITIONAL_TYPES = {
  HOUR: 'hour',
  PERSON: 'person',
};

const OPTIONS_TYPES = {
  LUGGAGE: 'luggage',
};

const SORT = [
  { label: getText('services:vipHall.sort.priceDown'), value: SORT_FIELDS.PRICE_DOWN },
  { label: getText('services:vipHall.sort.priceUp'), value: SORT_FIELDS.PRICE_UP },
];

const DEFAULT_MAIN_INFO = {
  date: '',
  iata: '',
  terminal: '',
  terminal_id: 0,
  type: '',
};

const DEFAULT_PAGING = {
  total: 0,
  current: 1,
  count: 5,
};

const DEFAULT_SELECTED_VIP_HALL = {
  additional_services: [{
    name: '',
    type_label: '',
    description: '',
    billing: '',
    type: '',
    capacity: 0,
    cost: 0,
  }],
  cancellation_description: '',
  citizenship_required: false,
  contact_phone_required: false,
  description: '',
  detailed_description: '',
  hours_to_stay: '',
  images: [''],
  important_information: '',
  latin_names_required: false,
  location: '',
  name: '',
  one_passenger_cost: 0,
  opening_hours: '',
  option_categories: [{
    name: '',
    description: '',
    type: '',
    included: false,
    DetailDescription: '',
  }],
  parking_info_required: false,
  passenger_categories: [{
    ages: {
      min: 0,
      max: 0,
    },
    price: 0,
  }],
  passport_data_required: false,
  special_conditions: '',
  ticket_class_required: false,
};

export {
  FLIGHT_TYPE_FORMAT,
  FIELDS_DIRECTION,
  SORT,
  SORT_FIELDS,
  SORT_TYPES,
  FAST_TRACK_TYPES,
  VIP_HALL_TYPES,
  ADDITIONAL_TYPES,
  OPTIONS_TYPES,
  DEFAULT_MAIN_INFO,
  DEFAULT_PAGING,
  DEFAULT_SELECTED_VIP_HALL,
};
