import React from 'react';

import { RadioButton, Text } from 'new-ui';
import { getText } from '../../../i18n';

import { isSmartAgent } from '../../bi/utils/env';

import styles from './index.module.css';

const LABELS = {
  MAN: getText('components:sexSwitcher.man'),
  WOMAN: getText('components:sexSwitcher.woman'),
};

const TYPES = {
  MAN: 'Man',
  WOMAN: 'Woman',
};

interface ISexSwitcherProps {
  sex: string,
  onChange: (value: string) => void,
  qaAttrMan: string,
  qaAttrFemale: string,
}

const SexSwitcher = ({ sex, onChange, qaAttrMan = '', qaAttrFemale = '' }: ISexSwitcherProps) => (
  <div className={ styles.switcher }>
    <RadioButton
      value={ TYPES.MAN }
      checked={ sex === TYPES.MAN }
      onChange={ () => onChange(TYPES.MAN) }
      onFocus={ () => {
        if (!sex) {
          onChange(TYPES.MAN);
        }
      } }
      className={ styles.radio }
      qaAttr={ qaAttrMan }
      alternativeDesign={ isSmartAgent }
    >
      <Text>
        { LABELS.MAN }
      </Text>
    </RadioButton>
    <RadioButton
      value={ TYPES.WOMAN }
      checked={ sex === TYPES.WOMAN }
      onChange={ () => onChange(TYPES.WOMAN) }
      className={ styles.radio }
      qaAttr={ qaAttrFemale }
      alternativeDesign={ isSmartAgent }
    >
      <Text>
        { LABELS.WOMAN }
      </Text>
    </RadioButton>
  </div>
);

export { SexSwitcher };
