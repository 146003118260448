// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';

import { Text, PROPS } from 'new-ui';
import { getText } from '../../../i18n';

import { UnderageWarning } from '../UnderageWarning';

import { dateWithoutMoment, formatDate } from '../../bi/utils/formatDate';
import { preparePlaceNumber, getSeatType } from '../../bi/utils/train';

import { DATEFORMATS, PATTERN } from '../../bi/constants/dateFormats';
import { CANCELLATION_TRAIN, TRAIN_TARIFFS } from '../../bi/constants/train';
import { SERVICETYPE } from '../../bi/constants/serviceType';

import styles from './styles/index.module.css';

const LABELS = {
  TRAIN_NUMBER: getText('components:trainItem.trainNumber'),
  CAR_NUMBER: getText('components:trainItem.carNumber'),
  PLACE: getText('components:trainItem.place'),
  REFUND: getText('components:trainItem.refund'),
  ALL_COMPARTMENT: (typeShow) => getText('components:trainItem.allCompartment', { typeShow }),
  ATTENTION_KALININGRAD: {
    FIRST: getText('components:trainItem.attentionKaliningrad.first'),
    SECOND: getText('components:trainItem.attentionKaliningrad.second'),
    THIRD: getText('components:trainItem.attentionKaliningrad.third'),
    FOURTH_FIRST: getText('components:trainItem.attentionKaliningrad.fourth_first'),
    FOURTH_SECOND: getText('components:trainItem.attentionKaliningrad.fourth_second'),
    FIFTH: getText('components:trainItem.attentionKaliningrad.fifth'),
    LINK: getText('components:trainItem.attentionKaliningrad.link'),
  },
  KALININGRAD: getText('components:trainItem.kaliningrad'),

  SINGLE: getText('components:trainItem.specialTariffs.single'),
  BP: getText('components:trainItem.specialTariffs.bp'),
  KUPEK: getText('components:trainItem.specialTariffs.kupek'),
};

const {
  TEXT: {
    TYPES: {
      NORMAL_14,
      BOLD_18,
      BOLD_22,
    },
    COLORS: {
      RED,
      GRAY,
    },
  },
} = PROPS;

const TrainItem = ({
  item: {
    ClassService,
    PlaceNumber,
    CarTypeDescription,
    DateDepartureLocal,
    DateDeparture,
    DateArrivalLocal,
    DateArrive,
    TrainNumber,
    TrainNumberLocal,
    CarNumber,
    StationDepart,
    StationArrive,
    StationFrom,
    StationTo,
    CancellationDate,
    Train,
    NonRefundable,
    PlacesInfo,
    SpecialTariff,
  },
  isCancellation,
  hasUnderageEmployees,
  qaAttrFrom,
  qaAttrTo,
  qaAttrDateFrom,
  qaAttrDateTo,
  qaAttrCancellation,
  qaAttrNumber,
  qaAttrPlace,
  differentTrains,
  isSpecialTariffs,
}) => {
  const getPlaneNum = (places) => {
    const buyFullWithoutScheme = Train ? !Train.Car.trainType && Train.Car.BuyTwo : false;

    if (typeof places === 'string') return places.split(';').join(', ');

    if (!Array.isArray(places)) return null;

    const firstPlace = preparePlaceNumber(places[0]);
    const lastPlace = preparePlaceNumber(places[places.length - 1]);

    if (firstPlace === lastPlace) return firstPlace;

    const delimiter = !buyFullWithoutScheme ? ' - ' : ', ';
    const postfix = !buyFullWithoutScheme ? '' : ` (${LABELS.ALL_COMPARTMENT(Train ? Train.Car.TypeShow : '')})`;

    return `${firstPlace}${delimiter}${lastPlace}${postfix}`;
  };

  const specialTariffsText = () => {
    if (!SpecialTariff) {
      return null;
    }

    if (differentTrains && SpecialTariff === TRAIN_TARIFFS.SINGLE) return LABELS.BP;

    if (SpecialTariff === TRAIN_TARIFFS.SINGLE) return LABELS.SINGLE;

    if (SpecialTariff === TRAIN_TARIFFS.KUPEK) return LABELS.KUPEK;

    return null;
  };

  const stationEqualCity = (station, city) => station.toLowerCase() === city.toLowerCase();

  const nonRefundable = Train ? Train.Car.NonRefundable : NonRefundable;

  const cancellationText = CancellationDate
    ? `${CANCELLATION_TRAIN.DATE} ${formatDate(CancellationDate, PATTERN.FULLDATEWITHTIME)}`
    : CANCELLATION_TRAIN.HOUR;
  const classService = ClassService ? `(${ClassService})` : '';
  const placeNumber = getPlaneNum(PlaceNumber);
  const seatType = getSeatType(PlaceNumber, Train, PlacesInfo);

  const departureDate = DateDepartureLocal || DateDeparture;
  const arrivalDate = DateArrivalLocal || DateArrive;

  const trainNumber = TrainNumberLocal || TrainNumber;

  const underageEmployeesHtml = hasUnderageEmployees && <UnderageWarning type={ SERVICETYPE.TRAIN }/>;

  const stationIncludeKaliningrad = stationEqualCity(StationDepart, LABELS.KALININGRAD) || stationEqualCity(StationArrive, LABELS.KALININGRAD);

  const cancellationHtml = isCancellation && !nonRefundable && (
    <Text
      className={ styles['cancellation-text'] }
      type='NORMAL_14'
      qaAttr={ qaAttrCancellation }
    >
      { cancellationText }
    </Text>
  );

  const renderSpecialTariffsHtml = () => {
    if (SpecialTariff && isSpecialTariffs) {
      return (
        <Text
          className={ styles['cancellation-text'] }
          type='NORMAL_14'
          qaAttr={ qaAttrCancellation }
        >
          { specialTariffsText() }
        </Text>
      );
    }

    return null;
  };

  const linkKaliningradAttention = (
    <a href={ LABELS.ATTENTION_KALININGRAD.LINK } target='_blank' rel='noreferrer'>
      { LABELS.ATTENTION_KALININGRAD.LINK }
    </a>
  );

  const kaliningradAttentionHtml = stationIncludeKaliningrad && (
    <div>
      <Text className={ styles['secondary-text'] } type={ NORMAL_14 }>
        { LABELS.ATTENTION_KALININGRAD.FIRST }
      </Text>
      <Text className={ styles['secondary-text'] } type={ NORMAL_14 }>
        { LABELS.ATTENTION_KALININGRAD.SECOND }
      </Text>
      <Text className={ styles['secondary-text'] } type={ NORMAL_14 }>
        { LABELS.ATTENTION_KALININGRAD.THIRD }
      </Text>
      <Text className={ styles['secondary-text'] } type={ NORMAL_14 }>
        { LABELS.ATTENTION_KALININGRAD.FOURTH_FIRST }
        { linkKaliningradAttention }
        { LABELS.ATTENTION_KALININGRAD.FOURTH_SECOND }
      </Text>
      <Text className={ styles['secondary-text'] } type={ NORMAL_14 }>
        { LABELS.ATTENTION_KALININGRAD.FIFTH }
      </Text>
    </div>
  );

  const nonRefundableHtml = nonRefundable && (
    <Text
      className={ styles['secondary-text'] }
      color={ RED }
      type={ NORMAL_14 }
    >
      {LABELS.REFUND}
    </Text>
  );

  const renderStationMainHtml = (station, qaAttr) => (
    <Text
      className={ `${styles.main_station} ${styles.capitalize}` }
      type={ BOLD_18 }
      qaAttr={ qaAttr }
    >
      { station.toLowerCase() }
    </Text>
  );

  const renderStationHtml = (station) => (
    <Text
      className={ `${styles.name} ${styles.capitalize}` }
      color={ GRAY }
      type={ NORMAL_14 }
    >
      { station.toLowerCase() }
    </Text>
  );

  const renderDateHtml = (date, qaAttr) => (
    <div data-qa={ qaAttr } className={ styles.dates }>
      <Text
        className={ styles.time }
        type={ BOLD_22 }
      >
        { dateWithoutMoment(date, DATEFORMATS.TIME) }
      </Text>
      <Text
        type={ NORMAL_14 }
        color={ GRAY }
      >
        { dateWithoutMoment(date, PATTERN.DATEWITHWEEKDAY) }
      </Text>
    </div>
  );

  return (
    <div className={ styles.item }>
      <div className={ styles['item-train'] }>
        <div className={ `${styles.station} ${styles.col_departure}` }>
          { renderStationMainHtml(StationDepart, qaAttrFrom) }
          { StationFrom && renderStationHtml(StationFrom) }
          { renderDateHtml(departureDate, qaAttrDateFrom) }
        </div>
        <div className={ styles['description-wrap'] }>
          <div className={ styles.description } data-qa={ qaAttrNumber }>
            <Text
              type={ NORMAL_14 }
              color={ GRAY }
            >
              { LABELS.TRAIN_NUMBER }
            </Text>
            <Text
              className={ styles.content }
              type={ NORMAL_14 }
            >
              { trainNumber }
            </Text>
          </div>
          <div className={ styles.description }>
            <Text
              type={ NORMAL_14 }
              color={ GRAY }
            >
              { LABELS.CAR_NUMBER }
            </Text>
            <Text
              className={ styles.content }
              type={ NORMAL_14 }
            >
              { CarNumber } { CarTypeDescription }
            </Text>
            <Text
              className={ styles.content }
              type={ NORMAL_14 }
              color={ GRAY }
            >
              { classService }
            </Text>
          </div>
          { !!placeNumber && (
            <div className={ styles.description } data-qa={ qaAttrPlace }>
              <Text
                type={ NORMAL_14 }
                color={ GRAY }
              >
                { LABELS.PLACE }
              </Text>
              <Text
                className={ styles.content }
                type={ NORMAL_14 }
              >
                { placeNumber }
              </Text>
              <Text
                className={ styles.content }
                type={ NORMAL_14 }
                color={ GRAY }
              >
                { seatType }
              </Text>
            </div>
          ) }
        </div>
        <div className={ `${styles.station} ${styles.col_arrival}` }>
          { renderStationMainHtml(StationArrive, qaAttrTo) }
          { StationTo && renderStationHtml(StationTo) }
          { renderDateHtml(arrivalDate, qaAttrDateTo) }
        </div>
      </div>
      { cancellationHtml }
      { renderSpecialTariffsHtml() }
      { kaliningradAttentionHtml }
      { nonRefundableHtml }
      { underageEmployeesHtml }
    </div>
  );
};

TrainItem.propTypes = {
  item: PropTypes.object.isRequired,
  isCancellation: PropTypes.bool,
  hasUnderageEmployees: PropTypes.bool,
  qaAttrFrom: PropTypes.string,
  qaAttrTo: PropTypes.string,
  qaAttrDateFrom: PropTypes.string,
  qaAttrDateTo: PropTypes.string,
  qaAttrCancellation: PropTypes.string,
  qaAttrNumber: PropTypes.string,
  qaAttrPlace: PropTypes.string,
  differentTrains: PropTypes.bool,
  isSpecialTariffs: PropTypes.bool,
};

TrainItem.defaultProps = {
  isCancellation: false,
  hasUnderageEmployees: false,
  qaAttrFrom: '',
  qaAttrTo: '',
  qaAttrDateFrom: '',
  qaAttrDateTo: '',
  qaAttrCancellation: '',
  qaAttrNumber: '',
  qaAttrPlace: '',
  differentTrains: false,
  isSpecialTariffs: false,
};

export default TrainItem;
