import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Icon, LinkButton, Text, Tooltip, DownloadLink } from 'new-ui';
import * as queryString from 'query-string';
import { getText } from '../../../i18n';

import BookingTripItem from '../../../components/trips/BookingTripItem';

import AirlineItem from '../AirlineItem';
import HotelItem from '../HotelItem';
import TransferItem from '../TransferItem';
import TrainItem from '../TrainItem';
import AeroexpressItem from '../AeroexpressItem';
import FlightCertificateItem from '../FlightCertificateItem';
import InputModal from '../InputModal';
import AnalyticsBar from '../AnalyticsBar';

import { getEmployeeFullNameWithSimplicity } from '../../bi/utils/employees';
import { getFreeFlightCertificate } from '../../bi/utils/accountSettings';
import { isSmartAgent } from '../../bi/utils/env';

import getAccountId from '../../bi/utils/getAccountId';
import MainAnalytic from '../../bi/utils/analytics/main';
import { SERVICETYPE } from '../../bi/constants/serviceType';
import NOTIFICATIONS from '../../bi/constants/notifications';
import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { compareByAirCertificate, getAirTripItemId, getAllAirIds } from '../../bi/utils/airline';

import { WorkspaceService } from '../../bi/types/workspace';
import { NotificationService } from '../../bi/types/notification';
import { UserSessionService } from '../../bi/types/userSession';
import { IOrderDetailsData, OrderStore } from '../../bi/types/order';
import { DepartmentsService } from '../../bi/services/departments/types';
import CustomAnalyticsService from '../../bi/services/customAnalytics';
import PopupsService from '../../bi/services/popups';
import SettingsService from '../../bi/services/settings';
import { ICompany } from '../../bi/services/workspace/types';
import { IBookingService } from '../../bi/types/booking';
import { OrderEmployees } from '../../bi/types/employees';
import { ISettingsStore } from '../../bi/services/settings/stores/settings';
import { IUiSettingsStore } from '../../bi/services/uiSettings/types';
import AccountSettings from '../../bi/services/accountSettings';
import OrderService from '../../bi/services/order';
import ReportService from '../../bi/services/report';
import { Department, ITripItem } from '../../bi/types/trips';

import styles from './styles/index.module.css';

const STATUSES = {
  AWAITINGTICKETING: 'AwaitingTicketing',
};

const EMAILMODALTITLES = {
  [SERVICETYPE.AIR]: getText('trip:sendAir'),
  DEFAULT: getText('trip:sendApprove'),
  DOWNLOAD: getText('trip:addToCalendar'),
  TOOLTIP: getText('trip:calendarTooltip'),
};

const LABELS = {
  PUT_EMAIL: getText('components:tripDocuments.putEmail'),
  SEND_SERVICE_BLANK: getText('components:tripDocuments.sendServiceBlank'),
  INVOICE_CREATE: getText('components:tripDocuments.invoiceCreate'),
  INVOICE: getText('components:tripDocuments.invoice'),
  COPY_TICKETS: getText('components:tripDocuments.copyTickets'),
  EMPLOYEE_MAIL: getText('components:tripDocuments.employeeMail'),
  BACK: getText('components:tripDocuments.back'),
  TO_TRIP_DETAILS: getText('components:tripDocuments.toTripDetails'),
  FAILED_TITLE: getText('components:tripDocuments.title.failed.title'),
  FAILED_DESCRIPTION: getText('components:tripDocuments.title.failed.description'),
  TRIP_PAYED: (name: string) => getText('components:tripDocuments.title.tripPayed', { name }),
  TRIP_RESERVED: (name: string) => getText('components:tripDocuments.title.tripReserved', { name }),
  TRIP_ADDID: (name: string) => getText('components:tripDocuments.title.additionalTripPayed', { name }),
};

interface ITripDocumentsProps {
  location:RouteComponentProps['location'],
  history:RouteComponentProps['history'],
  workspaceService: WorkspaceService,
  notificationService: NotificationService,
  userSessionService: UserSessionService,
  orderService: OrderService,
  reportService: ReportService,
  bookingService: IBookingService,
  departmentsService: DepartmentsService,
  customAnalyticsService: CustomAnalyticsService,
  accountSettingsService: AccountSettings,
  settingsService: SettingsService,
  popupsService: PopupsService,
  isLastOrderItem: boolean,
  invoiceNumber: string,
  trip: IOrderDetailsData,
  stores: {
    uiSettingsStore: IUiSettingsStore,
    settingsStore: ISettingsStore,
  }
}

interface ITripDocumentsState {
  shownLink: boolean,
  disabledLink: boolean,
  hasFailedItems: boolean,
  allFlightCertificateStatus: Record<string, string>,
  accountId: number,
  errors: any[],
  details: IOrderDetailsData | null,
  companies: ICompany[],
  projects: any[],
  emailInput: IEmailInput,
}

interface IEmailInput {
  tripItemId: null | number,
  employeeId: null | number,
  serviceId: null | number,
  visible: boolean,
  serviceType?: string,
  title: string,
}

interface ICartItem {
  ServiceType: string,
  Data: string,
}

interface AirRoute {
  ID: any,
}
export class TripDocuments extends Component<ITripDocumentsProps, ITripDocumentsState> {
  constructor(props: ITripDocumentsProps) {
    super(props);

    const { shownLink, disabledLink, allFlightCertificateStatus } = props.orderService.get();

    this.state = {
      accountId: getAccountId(),
      details: null,
      errors: [],
      companies: props.workspaceService.get().Companies,
      projects: props.userSessionService.get().projects,
      emailInput: {
        tripItemId: null,
        employeeId: null,
        serviceId: null,
        visible: false,
        serviceType: '',
        title: '',
      },
      hasFailedItems: false,
      shownLink,
      disabledLink,
      allFlightCertificateStatus,
    };
  }

  componentDidMount() {
    const { departmentsService, orderService, settingsService } = this.props;
    const { accountId } = this.state;

    if (!this.state.errors.length) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.SUCCESSFULLY_PAID);
    }

    this.fetchDetails(queryString.parse(this.props.location.search));
    // @ts-ignore
    this.unsubscribeFn = orderService.subscribeCart(this.updateOrderState);
    departmentsService.loadList();
    settingsService.getProhibitionVouchers(accountId);
  }

  componentWillUnmount() {
    // @ts-ignore
    this.unsubscribeFn();
  }

  updateOrderState = ({ shownLink, disabledLink, allFlightCertificateStatus }: OrderStore) => this.setState({
    shownLink,
    disabledLink,
    allFlightCertificateStatus,
  });

  onGoToSmartDesk = () => {
    const label = this.state.details?.Status === STATUSES.AWAITINGTICKETING ?
      MainAnalytic.LABELS.PAYMENT.WAITINGFORCONFIRMATION : MainAnalytic.LABELS.PAYMENT.SUCCESSFULPAYMENT;

    this.props.history.push(ROUTES.SMARTDESK);

    MainAnalytic.send(MainAnalytic.CATEGORY.PAYMENT, MainAnalytic.ACTIONS.PAYMENT.RETURNINMAIN, {
      label,
    });
  };

  onGoToTripDetails = () => {
    if (!this.state.details) return;

    const { details: { Status, TripId } } = this.state;

    const label = Status === STATUSES.AWAITINGTICKETING ?
      MainAnalytic.LABELS.PAYMENT.WAITINGFORCONFIRMATION : MainAnalytic.LABELS.PAYMENT.SUCCESSFULPAYMENT;

    MainAnalytic.send(MainAnalytic.CATEGORY.PAYMENT, MainAnalytic.ACTIONS.PAYMENT.GOTODETAILS, {
      label,
    });

    this.props.history.push(`${ROUTES.TRIP.MAIN}/${TripId}`);
  };

  onDownloadTickets = (orderItemId: number, employeeId: number, type: string): Promise<void> => {
    const { orderService, reportService } = this.props;

    if (type === SERVICETYPE.FLIGHT_CERTIFICATE) {
      return orderService.downloadFlightCertificate(employeeId);
    }

    // В downloadVoucher employeeId ваще нет.....
    // @ts-ignore
    return reportService.downloadVoucher(orderItemId, employeeId);
  };

  onDownloadBill = () => {
    const { invoiceNumber } = this.props;

    const companyId = invoiceNumber.split('/')[0];

    this.props.reportService.downloadInvoice(companyId, invoiceNumber);

    MainAnalytic.send(MainAnalytic.CATEGORY.PAYMENT, MainAnalytic.ACTIONS.PAYMENT.DOWNLOADINVOICE);
  };

  handleDownloadVoucher = (labelType: string) =>
    MainAnalytic.send(MainAnalytic.CATEGORY.PAYMENT, MainAnalytic.ACTIONS.PAYMENT.DOWNLOADVOUCHER, {
      label: MainAnalytic.LABELS.PAYMENT[labelType],
    });

  fetchDetails = async ({ id }: queryString.ParsedQuery) => {
    const {
      orderService,
      bookingService: {
        getState,
      },
      accountSettingsService: { getCompanySettings },
      popupsService,
      invoiceNumber,
      trip,
    } = this.props;

    const { statuses } = await getState();
    const hasFailedItems = statuses.some(({ BookingStep }) => BookingStep === 'BookingError');

    const details = {
      ...trip,
      Id: id as string,
      InvoiceNumber: invoiceNumber,
    };

    if (trip.Status !== STATUSES.AWAITINGTICKETING) {
      MainAnalytic.send(MainAnalytic.CATEGORY.PAYMENT, MainAnalytic.ACTIONS.PAYMENT.SUCCESS);
    }

    if (details && details.OrderItems) {
      const companiesSettings = getCompanySettings();
      const isFreeFlightCertificate = getFreeFlightCertificate(companiesSettings, details.OrderItems[0].ActualVersion.CompanyId);

      const allAirIds: number[] = getAllAirIds(details.OrderItems);
      await orderService.getAllStatusesFlightCertificate(allAirIds, isFreeFlightCertificate);

      const firstAir = details.OrderItems.find((item) => item.ActualVersion.ServiceType === SERVICETYPE.AIR);

      if (trip.Status !== STATUSES.AWAITINGTICKETING && firstAir) {
        const show = await popupsService.getStateVipHallPopup(firstAir.Id);

        if (show) {
          popupsService.addVipHallPopup(() => {
            this.props.history.push(`${ROUTES.TRIP.MAIN}/${details.Id}`);
          });

          MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.VIP_HALL_BANNERS.AIRTICKETPURCHASE_POPUP_SHOWED);
        }
      }
    }

    this.setState({ details, hasFailedItems });
  };

  handleDownloadCalendar = () => this.props.orderService.downloadCalendar(this.state.details);

  renderSendDocumentModal = () => {
    const { visible, title } = this.state.emailInput;

    return (
      <InputModal
        show={ visible }
        title={ title }
        label={ LABELS.PUT_EMAIL }
        type='email'
        onSend={ this.handleSendDocument }
        onCancel={ () => this.handleHideSendDocumentModal(false) }
      />
    );
  };

  handleSendDocument = (email: string) => {
    const { orderService, notificationService } = this.props;
    const { tripItemId, serviceType, serviceId } = this.state.emailInput;

    const isServiceForm = serviceId !== null;
    const sendDocument = isServiceForm ?
      () => orderService.sendServiceForm({ tripItemId, serviceId, email }) :
      () => orderService.sendDocument({ tripItemId, email });

    const message = serviceType || '';

    sendDocument().then(() => {
      notificationService.send({
        message: this.getNotificationMessage(message, email),
        level: 'success',
      });
    });

    this.handleHideSendDocumentModal(false);
  };

  getNotificationMessage = (serviceType: string, email: string) => {
    const textNotification = NOTIFICATIONS[serviceType] || NOTIFICATIONS.DEFAULT;

    return `${textNotification} ${email}`;
  };

  handleShowSendDocumentModal = (type: string, tripItemId: number, employeeId: number) => {
    const title = EMAILMODALTITLES[type] || EMAILMODALTITLES.DEFAULT;

    return this.setState({
      emailInput: {
        tripItemId,
        employeeId,
        serviceId: null,
        visible: true,
        serviceType: type,
        title,
      },
    });
  };

  handleShowSendDocumentModalForServiceForm = (tripItemId: number, employeeId: number, serviceId: number) => {
    this.setState({
      emailInput: {
        tripItemId,
        employeeId,
        serviceId,
        visible: true,
        title: LABELS.SEND_SERVICE_BLANK,
      },
    });
  };

  handleHideSendDocumentModal = (value: boolean) => this.setState({
    emailInput: {
      ...this.state.emailInput,
      visible: value,
    },
  });

  downloadVoucherLocale = (tripItemId: number, locale: string): Promise<void> =>
    this.props.reportService.downloadVoucherLocale(
      tripItemId,
      locale,
    );

  downloadServiceForm = (tripItemId: number, serviceId: number): Promise<void> =>
    this.props.reportService.downloadServiceForm(tripItemId, serviceId);

  renderCartItem = ({ Data, ServiceType }: ICartItem) => {
    const itemData = JSON.parse(Data);

    switch (ServiceType) {
      case SERVICETYPE.AIR:
        return (
          <ul className={ styles['reset-ul'] }>
            { itemData.Routes.map((route: AirRoute) => (
              <AirlineItem
                key={ route.ID }
                route={ route }
                meta={ itemData.Metadata }
              />
            )) }
          </ul>
        );

      case SERVICETYPE.HOTEL:
        return (
          <HotelItem item={ itemData } />
        );

      case SERVICETYPE.TRANSFER:
        return (
          <TransferItem item={ itemData } />
        );

      case SERVICETYPE.TRAIN:
        return <TrainItem item={ itemData } />;

      case SERVICETYPE.FLIGHT_CERTIFICATE:
        return <FlightCertificateItem meta={ itemData } />;

      case SERVICETYPE.AEROEXPRESS:
        return (
          <AeroexpressItem
            item={ itemData }
            fromTrip
          />
        );
    }

    return null;
  };

  renderTitle = () => {
    const { details, hasFailedItems } = this.state;

    if (hasFailedItems) {
      return (
        <>
          <Text type='bold_32'>{ LABELS.FAILED_TITLE }</Text>
          <Text color='red' className={ styles['rzd-error'] }>{ LABELS.FAILED_DESCRIPTION }</Text>
        </>
      );
    }

    if (!details) return null;

    const additionalTripText = details.OrderItems.some(item => item.IsAdditionalItem === null)
      ? LABELS.TRIP_PAYED(details.Name)
      : LABELS.TRIP_ADDID(details.Name);

    const detailsText = details.Status === STATUSES.AWAITINGTICKETING ?
      LABELS.TRIP_RESERVED(details.Name) :
      additionalTripText;

    return (
      <Text
        qaAttr={ details.Status === STATUSES.AWAITINGTICKETING ? QA_ATTRIBUTES.cart.checkout.successPay.titleNeedConfirmation : QA_ATTRIBUTES.cart.checkout.successPay.title }
        type='bold_32'
      >
        { detailsText }
      </Text>
    );
  };

  renderCustomAnalytics = () => {
    const { customAnalyticsService: {
      store: { sortedCustomAnalytics },
    } } = this.props;
    const { details } = this.state;

    const tripAnalytics = sortedCustomAnalytics.filter(({ ApplyToTrip }) => ApplyToTrip);

    return (
      <AnalyticsBar
        disabled
        cartId={ details?.Id }
        analyticsList={ tripAnalytics }
        userAnalytics={ details?.UserAnalytics || [] }
      />
    );
  };

  render() {
    const {
      details,
      errors,
      companies,
      projects,
      shownLink,
      disabledLink,
      allFlightCertificateStatus,
    } = this.state;
    const {
      isLastOrderItem,
      stores: {
        settingsStore: {
          prohibitionVouchers,
        },
      },
    } = this.props;

    if (!details) {
      return null;
    }

    const {
      workspaceService: {
        canViewReports,
      },
      departmentsService: { store: { list: { value } } },
      customAnalyticsService: { store: { sortedCustomAnalytics } },
      stores: {
        uiSettingsStore: {
          settings: { showReports },
        },
      },
      accountSettingsService,
    } = this.props;

    const isProhibitionVoucher = !!prohibitionVouchers.length;
    const employeesHash: Record<string, OrderEmployees> = details.OrderItems.reduce((r, { ActualVersion: { Employees = [] } }) => {
      const newResult: OrderEmployees | any = { ...r };

      Employees.forEach((e) => {
        newResult[e.Id] = e;
      });

      return newResult;
    }, {});

    const travellers: OrderEmployees[] = Object.values(employeesHash).map((v) => v);

    let invoiceHtml = null;
    const showInvoice = canViewReports && companies.every(item => item.ShowFinanceDetails) && showReports;

    if (showInvoice) {
      invoiceHtml = details && details.InvoiceNumber &&
        details.InvoiceNumber.split(',').map(invoiceNumber =>
          (<Text key={ invoiceNumber } className={ styles['download-bill'] }>
            <div className={ styles.icon }>
              <Icon type='invoiceBlue' alternativeDesign={ isSmartAgent }/>
            </div>
            <span>{ LABELS.INVOICE_CREATE }</span>
            &nbsp;
            <Button type='textual' onClick={ () => this.onDownloadBill() } className={ styles.btn }>
              { LABELS.INVOICE }{ invoiceNumber }
            </Button>
          </Text>),
        );
    }

    const allCompanies = isProhibitionVoucher && details.OrderItems.map(({ ActualVersion }) =>
      ({ CompanyId: ActualVersion.CompanyId, Employees: ActualVersion.Employees }));

    const isAllCompanyProhibition = isProhibitionVoucher && allCompanies
      && allCompanies.every(company =>
        prohibitionVouchers.some(prohibitionId =>
          prohibitionId === company.CompanyId));

    const renderEmployeeFullName = (person: OrderEmployees) => {
      const isProgibitionPerson = () => allCompanies && allCompanies.some(company =>
        prohibitionVouchers.some(porgibitionId =>
          porgibitionId === company.CompanyId)
          && company.Employees.some(employee =>
            employee.Id === person.Id));

      if (isProhibitionVoucher && isProgibitionPerson()) {
        return null;
      }

      return `${getEmployeeFullNameWithSimplicity(person, true)} (${person.Email})`;
    };

    const backToHome = isLastOrderItem && (
      <Button
        type='primary'
        onClick={ this.onGoToSmartDesk }
        qaAttr={ QA_ATTRIBUTES.cart.checkout.successPay.smartdesk }
      >
        { LABELS.BACK }
      </Button>
    );

    const detailsButton = (
      <Button
        className={ styles.link }
        type='textual-medium'
        onClick={ this.onGoToTripDetails }
        qaAttr={ QA_ATTRIBUTES.cart.checkout.successPay.tripDetails }
      >
        { LABELS.TO_TRIP_DETAILS }
      </Button>
    );

    const detailsInInfo = !isLastOrderItem && detailsButton;
    const detailsInAction = isLastOrderItem && detailsButton;

    const createdTripInfoContent = () => {
      if (isSmartAgent || (details?.Status === STATUSES.AWAITINGTICKETING && isAllCompanyProhibition)) {
        return null;
      }

      const renderTooltip = () => (
        <div className={ styles['tooltip-wrapper'] }>
          { travellers.map(person => (
            <Text
              key={ person.Id }
              type='NORMAL_14_130'
              color='white'
              className={ styles.traveller }
            >
              { renderEmployeeFullName(person) }
            </Text>),
          ) }
        </div>
      );

      return (
        <div className={ styles.hint }>
          <Text color='gray'>
            <span>{ LABELS.COPY_TICKETS }&nbsp;</span>
            <Tooltip
              className={ styles.travellers }
              position='bottom'
              renderContent={ () => renderTooltip() }
            >
              <LinkButton theme='blue'>{ LABELS.EMPLOYEE_MAIL }</LinkButton>
            </Tooltip>
          </Text>
          { shownLink && (
          <DownloadLink
            label={ EMAILMODALTITLES.DOWNLOAD }
            text={ EMAILMODALTITLES.TOOLTIP }
            disabled={ disabledLink }
            onDownload={ this.handleDownloadCalendar }
            qaAttr={ QA_ATTRIBUTES.cart.checkout.successPay.addToCalendar }
          />
          ) }
        </div>
      );
    };

    const customAnalytics = this.renderCustomAnalytics();

    const serviceAnalytics = sortedCustomAnalytics.filter(({ ApplyToTrip }) => !ApplyToTrip);

    return (
      <div className={ styles.wrapper }>
        <div className={ styles.title }>
          { this.renderTitle() }
        </div>
        { createdTripInfoContent() }
        { customAnalytics }
        <div className={ styles['cart-items'] }>
          { details.OrderItems.slice()
            .sort(compareByAirCertificate)
            .map(item => (item.IsAdditionalItem === null || item.IsAdditionalItem) &&
              <BookingTripItem
                key={ item.Id }
                item={ item as unknown as ITripItem }
                companies={ value as unknown as Department[] }
                projects={ projects }
                status={ details.Status }
                // Не используется
                // showEmployeeDetails={ false }
                prohibitionVouchers={ prohibitionVouchers }
                onPrintDocument={ this.onDownloadTickets }
                onDownloadVoucher={ this.handleDownloadVoucher }
                onSendDocument={ this.handleShowSendDocumentModal }
                downloadServiceForm={ this.downloadServiceForm }
                onDownloadVoucherLocale={ this.downloadVoucherLocale }
                sendServiceForm={ this.handleShowSendDocumentModalForServiceForm }
                flightCertificateStatus={ allFlightCertificateStatus[getAirTripItemId(item)] }
                customAnalytics={ serviceAnalytics }
                accountSettingsService={ accountSettingsService }
                qaAttrPrice={ QA_ATTRIBUTES.cart.checkout.successPay.price }
                qaAttrAdditionService={ QA_ATTRIBUTES.cart.checkout.successPay.additionalService }
              />,
            ) }
        </div>
        <div className={ styles['info-row'] }>
          { invoiceHtml }
          { detailsInInfo }
        </div>
        <div className={ styles.errors }>
          { errors.map(({ CartItem = null, Message }, index) =>
            (<div key={ index } className={ styles.error }>
              { CartItem && this.renderCartItem(CartItem) }
              <Text color='red' className={ styles.message }>
                { Message }
              </Text>
            </div>),
          ) }
        </div>
        <div className={ styles.actions }>
          { backToHome }
          { detailsInAction }
        </div>
        { this.renderSendDocumentModal() }
      </div>
    );
  }
}
