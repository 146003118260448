import React from 'react';
import { BackLink } from 'new-ui';
import { getText } from '../../../i18n';

import ROUTES from '../../bi/constants/routes';

import styles from './index.module.css';

interface BackToTripLinkProps {
  tripId: string | string[] | null | number
}

const LABELS = {
  BACK_TO_TRIP: getText('components:trip.backToTrip'),
};

const BackToTripLink = ({ tripId }: BackToTripLinkProps) => {
  if (!tripId) return null;

  return (
    <div className={ styles.wrapper }>
      <BackLink
        link={ ROUTES.CHANGE_AIR_TRIP.TRIP(tripId) }
        text={ LABELS.BACK_TO_TRIP }
      />
    </div>
  );
};

export { BackToTripLink };
