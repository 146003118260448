import { getText } from '../../../i18n';

import { AnalyticSortTransfer } from '../types/transfer';

export const actions = {
  TRANSFER_REQUEST: 'TRANSFER_REQUEST',
  TRANSFER_SUCCESS: 'TRANSFER_SUCCESS',
  TRANSFER_FAIL: 'TRANSFER_FAIL',
  TRANSFER_SORT: 'TRANSFER_SORT',
  TRANSFER_UPDATE: 'TRANSFER_UPDATE',
  TRANSFER_HISTORY_SUCCESS: 'TRANSFER_HISTORY_SUCCESS',
  TRANSFER_NEW_SEARCH: 'TRANSFER_NEW_SEARCH',
};

export const SORT_FIELDS = {
  PRICE_UP: 'price_up',
  PRICE_DOWN: 'price_down',
};

export const SORT = [
  {
    label: getText('services:transfer.sort.priceUp'),
    value: SORT_FIELDS.PRICE_UP,
    analyticValue: AnalyticSortTransfer.cheaper_first,
  },
  {
    label: getText('services:transfer.sort.priceDown'),
    value: SORT_FIELDS.PRICE_DOWN,
    analyticValue: AnalyticSortTransfer.more_expensive_first,
  },
];

export const MAPCARCLASS = {
  Undefined: '',
  Standart: 'standard',
  TollRoadStandart: 'tollRoadStandart',
  Comfort: 'economy',
  TollRoadComfort: 'tollRoadComfort',
  Business: 'business',
  TollRoadBusiness: 'tollRoadBusiness',
  BusinessLite: 'business',
  BusinessComfort: 'business',
  Minivan: 'minivan',
  Minibus: 'minibus',
  LargeMiniBus: 'largeMinibus',
  Executive: 'representative',
};
export const MAPCARCLASS_RU = {
  All: getText('services:transfer.tariffMap.all'),
  Undefined: getText('services:transfer.tariffMap.undefined'),
  Standart: getText('services:transfer.tariffMap.standart'),
  TollRoadStandart: getText('services:transfer.tariffMap.tollRoadStandart'),
  Comfort: getText('services:transfer.tariffMap.comfort'),
  TollRoadComfort: getText('services:transfer.tariffMap.tollRoadComfort'),
  Business: getText('services:transfer.tariffMap.business'),
  TollRoadBusiness: getText('services:transfer.tariffMap.tollRoadBusiness'),
  BusinessLite: getText('services:transfer.tariffMap.businessLite'),
  BusinessComfort: getText('services:transfer.tariffMap.businessComfort'),
  Minivan: getText('services:transfer.tariffMap.minivan'),
  MinivanVip: getText('services:transfer.tariffMap.minivanVip'),
  MiniBus: getText('services:transfer.tariffMap.minibus'),
  LargeMiniBus: getText('services:transfer.tariffMap.largeMinibus'),
  Executive: getText('services:transfer.tariffMap.executive'),
};

export const PLACETYPE = {
  AIRPORT: 'Airport',
  RAILSTATION: 'RailwayStation',
  HOTEL: 'Hotel',
  CITY: 'Address',
};

export const AUTOCOMPLETE_DEDICATED_GROUPS = [PLACETYPE.AIRPORT, PLACETYPE.RAILSTATION, PLACETYPE.HOTEL];

export const AUTOCOMPLETE_PLACETYPE_LABEL = {
  [PLACETYPE.CITY]: {
    single: 'Город',
    plural: 'Адреса',
  },
  [PLACETYPE.AIRPORT]: {
    single: 'Аэропорт',
    plural: 'Аэропорты',
  },
  [PLACETYPE.RAILSTATION]: {
    single: 'Вокзал',
    plural: 'Вокзалы',
  },
  [PLACETYPE.HOTEL]: {
    single: 'Отель',
    plural: 'Отели',
  },
};

export const MAIN_TRANSFER_MESSAGE = {
  MAIN_TEXT: getText('constants:transfers.mainText'),
  SECONDARY_TEXT: getText('constants:transfers.secondaryText'),
  REQUIRED_MESSAGE: getText('constants:transfers.requiredMessage'),
  LABELS: {
    TITLE: getText('search:titles.transferTitle'),
    PLACEHOLDER: getText('constants:transfers.placeholder'),
  },
};

export const AUTOCOMPLETE_PLACETYPE = {
  [PLACETYPE.CITY]: {
    icon: 'smartway-map-marker',
    title: AUTOCOMPLETE_PLACETYPE_LABEL[PLACETYPE.CITY].plural,
  },
  [PLACETYPE.AIRPORT]: {
    icon: 'smartway-airplane',
    title: AUTOCOMPLETE_PLACETYPE_LABEL[PLACETYPE.AIRPORT].plural,
  },
  [PLACETYPE.RAILSTATION]: {
    icon: 'smartway-train',
    title: AUTOCOMPLETE_PLACETYPE_LABEL[PLACETYPE.RAILSTATION].plural,
  },
  [PLACETYPE.HOTEL]: {
    icon: 'smartway-hotel',
    title: AUTOCOMPLETE_PLACETYPE_LABEL[PLACETYPE.HOTEL].plural,
  },
};

export const TRANSFER_SEARCH_FIELDS = {
  TRANSFER_FROM: 'transferFrom',
  TRANSFER_TO: 'transferTo',
};

export const VALUES_TABLETS = {
  WITH_TABLET: 'withTablet',
  WITHOUT_TABLET: 'withoutTablet',
};

export const FILTER_TYPE = {
  POLICY: 'travelPolicy',
  PRICE: 'price',
  CAR_CLASS: 'carClass',
  FAVORITE_ID: 'favoriteId',
  TABLE: 'table',
};
