import { getText } from '../../../i18n';

const LABELS_DEFAULT = {
  NO_SELECT: {
    GENERAL: getText('settings:employees.allFilters.modal.staticValue.noSelect.general'),
    COST_CENTER: getText('settings:employees.allFilters.modal.staticValue.noSelect.costCenter'),
    TRAVEL_POLICY: getText('settings:employees.allFilters.modal.staticValue.noSelect.travelPolicy'),
    TAGS: getText('settings:employees.allFilters.modal.staticValue.noSelect.tags'),
    DEPARTMENTS: getText('settings:employees.allFilters.modal.staticValue.noSelect.departments'),
  },
  NO_SELECT_ORG: getText('settings:employees.allFilters.modal.staticValue.AllOrganization'),
  SEX: {
    MAN: getText('settings:employees.allFilters.modal.staticValue.sex.man'),
    WOMAN: getText('settings:employees.allFilters.modal.staticValue.sex.woman'),
  },
  PASSPORT: {
    RU: getText('settings:employees.allFilters.modal.staticValue.passport.ru'),
    ANOTHER: getText('settings:employees.allFilters.modal.staticValue.passport.another'),
    NOT_SPECIFIED: getText('settings:employees.allFilters.modal.staticValue.passport.notSpecified'),
    INTERNATIONAL_PASSPORT: getText('settings:employees.allFilters.modal.staticValue.passport.internationalPassport'),
    BIRTH_CERTIFICATE: getText('settings:employees.allFilters.modal.staticValue.passport.birthCertificate'),
  },
  BONUS_CARDS: {
    FILLED: getText('settings:employees.allFilters.modal.staticValue.bonusCards.filled'),
    NO_FILLED: getText('settings:employees.allFilters.modal.staticValue.bonusCards.noFilled'),
  },
  ACCESS_SERVICE: {
    ALL_USERS: getText('settings:employees.allFilters.modal.staticValue.acessService.allUsers'),
    WITH_ACCESS: getText('settings:employees.allFilters.modal.staticValue.acessService.withAccess'),
    NO_ACCESS: getText('settings:employees.allFilters.modal.staticValue.acessService.noAccess'),
    ARCHIVE: getText('settings:employees.allFilters.modal.staticValue.acessService.archive'),
  },
};

const DEFAULT_FILTER_SELECTOR = {
  Sex: 'Sex',
  Company: 'Company',
  Departments: 'Departments',
  Projects: 'Projects',
  Tags: 'Tags',
  Document: 'Document',
  BonusCards: 'BonusCards',
  Status: 'Status',
  TravelPolicies: 'TravelPolicies',
  Codes: 'Codes',
};

const VALUE_NUMBER = {
  FOUR: 4,
  THREE: 3,
  TWO: 2,
  ONE: 1,
  ZERO: 0,
  MINUS_ONE: -1,
  MINUS_TWO: -2,
  MINUS_TWO_STRING: '-2',
};

const NULL_VALUE_NUMBER = {
  value: VALUE_NUMBER.MINUS_ONE,
  label: LABELS_DEFAULT.NO_SELECT.GENERAL,
};

const NULL_VALUE_NUMBER_ORG = {
  value: VALUE_NUMBER.MINUS_ONE,
  label: LABELS_DEFAULT.NO_SELECT_ORG,
};

const DEFAULT_RESPONSE_LIGTH_NUM = {
  value: VALUE_NUMBER.MINUS_ONE,
  label: '',
};

const DEFAULT_RESPONSE = {
  Company: [DEFAULT_RESPONSE_LIGTH_NUM],
  Departments: [],
  Projects: [],
  Tags: [],
  TravelPolicies: [],
  Codes: [],
  Sex: [
    {
      value: VALUE_NUMBER.MINUS_ONE,
      label: LABELS_DEFAULT.NO_SELECT.GENERAL,
    },
    {
      value: VALUE_NUMBER.ZERO,
      label: LABELS_DEFAULT.SEX.WOMAN,
    },
    {
      value: VALUE_NUMBER.ONE,
      label: LABELS_DEFAULT.SEX.MAN,
    },
  ],
  Document: [
    {
      value: VALUE_NUMBER.MINUS_ONE,
      label: LABELS_DEFAULT.NO_SELECT.GENERAL,
    },
    {
      value: VALUE_NUMBER.FOUR,
      label: LABELS_DEFAULT.PASSPORT.NOT_SPECIFIED,
    },
    {
      value: VALUE_NUMBER.ZERO,
      label: LABELS_DEFAULT.PASSPORT.RU,
    },
    {
      value: VALUE_NUMBER.THREE,
      label: LABELS_DEFAULT.PASSPORT.ANOTHER,
    },
    {
      value: VALUE_NUMBER.ONE,
      label: LABELS_DEFAULT.PASSPORT.INTERNATIONAL_PASSPORT,
    },
    {
      value: VALUE_NUMBER.TWO,
      label: LABELS_DEFAULT.PASSPORT.BIRTH_CERTIFICATE,
    },
  ],
  BonusCards: [
    {
      value: VALUE_NUMBER.MINUS_ONE,
      label: LABELS_DEFAULT.NO_SELECT.GENERAL,
    },
    {
      value: VALUE_NUMBER.ONE,
      label: LABELS_DEFAULT.BONUS_CARDS.FILLED,
    },
    {
      value: VALUE_NUMBER.ZERO,
      label: LABELS_DEFAULT.BONUS_CARDS.NO_FILLED,
    },
  ],
  Status: [
    {
      value: VALUE_NUMBER.MINUS_ONE,
      label: LABELS_DEFAULT.ACCESS_SERVICE.ALL_USERS,
    },
    {
      value: VALUE_NUMBER.TWO,
      label: LABELS_DEFAULT.ACCESS_SERVICE.WITH_ACCESS,
    },
    {
      value: VALUE_NUMBER.ZERO,
      label: LABELS_DEFAULT.ACCESS_SERVICE.NO_ACCESS,
    },
    {
      value: VALUE_NUMBER.ONE,
      label: LABELS_DEFAULT.ACCESS_SERVICE.ARCHIVE,
    },
  ],
};

const DEFAULT_TAGS_LIGTH_NUM = {
  value: VALUE_NUMBER.MINUS_ONE,
  label: LABELS_DEFAULT.NO_SELECT.GENERAL,
};

const DEFAULT_TAGS_LIGHT_NUM_ORG = {
  value: VALUE_NUMBER.MINUS_ONE,
  label: LABELS_DEFAULT.NO_SELECT_ORG,
};

const DEFAULT_TAGS_ACCESS = {
  value: VALUE_NUMBER.MINUS_ONE,
  label: LABELS_DEFAULT.ACCESS_SERVICE.ALL_USERS,
};

const DEFAULT_TAGS = {
  Sex: [DEFAULT_TAGS_LIGTH_NUM],
  Company: [DEFAULT_TAGS_LIGHT_NUM_ORG],
  Departments: [],
  Projects: [],
  Tags: [],
  Document: [DEFAULT_TAGS_LIGTH_NUM],
  BonusCards: [DEFAULT_TAGS_LIGTH_NUM],
  Status: [DEFAULT_TAGS_ACCESS],
  TravelPolicies: [],
  Codes: [],
};

const DEFAULT_TAGS_SEARCH = {
  Sex: [DEFAULT_TAGS_LIGTH_NUM],
  Company: [DEFAULT_TAGS_LIGTH_NUM],
  Departments: [],
  Projects: [],
  Tags: [],
  Document: [DEFAULT_TAGS_LIGTH_NUM],
  BonusCards: [DEFAULT_TAGS_LIGTH_NUM],
  Status: [DEFAULT_TAGS_LIGTH_NUM],
  TravelPolicies: [],
  Codes: [],
};

const DEFAULT_SELECTED = {
  ALL_NEW: -1,
  ALL: 0,
  ACCESS: 1,
  SEX: 2,
  ARR: [],
};

const DEFAULT_SELECTED_VALUE = {
  Company: DEFAULT_SELECTED.ALL_NEW,
  Departments: DEFAULT_SELECTED.ARR,
  Projects: DEFAULT_SELECTED.ARR,
  Tags: DEFAULT_SELECTED.ARR,
  Sex: DEFAULT_SELECTED.ALL_NEW,
  Document: DEFAULT_SELECTED.ALL_NEW,
  BonusCards: DEFAULT_SELECTED.ALL_NEW,
  Status: DEFAULT_SELECTED.ALL_NEW,
  TravelPolicies: DEFAULT_SELECTED.ARR,
  Codes: DEFAULT_SELECTED.ARR,
};

const DEFAULT_REQUEST_BODY = {
  Page: 1,
  Step: 50,
  Status: null,
  Company: null,
  SearchString: null,
  Departments: null,
  Sex: null,
  Codes: null,
  Document: null,
  Projects: null,
  Tags: null,
  BonusCards: null,
  TravelPolicies: null,
};

const DEFAULT_REQUEST_BODY_TAGS = {
  Status: null,
  Company: null,
  Departments: null,
  Sex: null,
  Codes: null,
  Document: null,
  Projects: null,
  Tags: null,
  BonusCards: null,
  TravelPolicies: null,
};

const NO_SELECTED_UNSHIFT = {
  TRAVEL_POLICY: {
    label: LABELS_DEFAULT.NO_SELECT.TRAVEL_POLICY,
    value: VALUE_NUMBER.MINUS_TWO_STRING,
  },
  COST_CENTER: {
    label: LABELS_DEFAULT.NO_SELECT.COST_CENTER,
    value: VALUE_NUMBER.MINUS_TWO,
  },
  TAGS: {
    label: LABELS_DEFAULT.NO_SELECT.TAGS,
    value: VALUE_NUMBER.MINUS_TWO,
  },
  DEPARTMENTS: {
    label: LABELS_DEFAULT.NO_SELECT.DEPARTMENTS,
    value: VALUE_NUMBER.MINUS_TWO,
  },
};

export {
  DEFAULT_SELECTED_VALUE,
  DEFAULT_TAGS_SEARCH,
  DEFAULT_TAGS,
  DEFAULT_RESPONSE,
  NULL_VALUE_NUMBER,
  DEFAULT_SELECTED,
  DEFAULT_FILTER_SELECTOR,
  DEFAULT_REQUEST_BODY,
  DEFAULT_REQUEST_BODY_TAGS,
  NULL_VALUE_NUMBER_ORG,
  NO_SELECTED_UNSHIFT,
};
