import React, { useState } from 'react';

import { IInvoiceDebitData, IOperationsTable } from '../../bi/types/report';

import { Table } from './components/table';
import { Total } from './components/total';
import { DownloadDepositDialog } from '../DownloadDepositDialog';
import { DownloadInvoiceDialog } from './components/dialogs/DownloadInvoice';
import { CLOSING_SCHEMAS } from '../../bi/constants/schemas';

const OperationsTable = ({
  items,
  total = null,
  loading,
  reportService,
  paymentService,
  sendNotification,
  notRenderDetails = false,
  currentCompany,
  overdraft = null,
  showFinanceDetails,
  companyEmail,
  companyName,
  companyInn,
  companiesItems,
  isDemo,
  allCompanies,
  multi,
}: IOperationsTable) => {
  const [showDepositDialog, setShowDepositDialog] = useState(false);
  const [showInvoiceDialog, setShowInvoicetDialog] = useState(false);

  const handleOpenDownloadInvoiceDialog = async () => {
    await reportService.getAllInvoices(currentCompany);
    setShowInvoicetDialog(true);
  };

  const handleDownloadDeposit = (sum: string, format: string) => {
    if (!sum) {
      return;
    }

    reportService.getDeposit(currentCompany, sum, format)
      .then(() => setShowDepositDialog(false));
  };

  const handleDownloadInvoiceDebt = (data: IInvoiceDebitData) => reportService.downloadInvoiceDebt(currentCompany, data);

  const handleDownloadInvoiceForDateRange = (startDate: string, endDate: string, format: string) => {
    reportService.downloadInvoiceForDateRange(currentCompany, startDate, endDate, format);
  };

  const totalHtml = total &&
    (<Total
      multi={ multi }
      total={ total }
      notRenderDetails={ notRenderDetails }
      onOpenDialog={ () => setShowDepositDialog(true) }
      onOpenDownloadInvoiceDialog={ handleOpenDownloadInvoiceDialog }
      overdraft={ overdraft }
      showFinanceDetails={ showFinanceDetails }
      isDemo={ isDemo }
      loading={ loading }
      email={ companyEmail }
      companyName={ companyName }
      companyInn={ companyInn }
      companyId={ currentCompany }
      companies={ companiesItems }
      paymentService={ paymentService }
      allCompanies={ allCompanies }
    />
    );

  const renderPenaltyBySchema = allCompanies.some(({ Schema }) => !CLOSING_SCHEMAS.includes(Schema));

  const tableHtml = showFinanceDetails ? (
    <Table
      items={ items }
      loading={ loading }
      reportService={ reportService }
      sendNotification={ sendNotification }
      currentCompany={ currentCompany }
      renderPenaltyBySchema={ renderPenaltyBySchema }
    />
  ) : null;

  const dialogHtml = (
    <DownloadDepositDialog
      show={ showDepositDialog }
      onClose={ () => setShowDepositDialog(false) }
      onDownloadDeposit={ handleDownloadDeposit }
    />
  );

  const downloadInvoiceDialogHtml = (
    <DownloadInvoiceDialog
      show={ showInvoiceDialog }
      reportService={ reportService }
      onDownloadInvoiceForDateRange={ handleDownloadInvoiceForDateRange }
      onDownloadInvoiceDebt={ handleDownloadInvoiceDebt }
      onCloseDialog={ () => setShowInvoicetDialog(false) }
    />
  );

  return (
    <>
      { tableHtml }
      { totalHtml }
      { dialogHtml }
      { downloadInvoiceDialogHtml }
    </>
  );
};

export default OperationsTable;
