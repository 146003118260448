import React from 'react';

import { getText } from '../../../i18n';

import { Remove } from './components/dialogs/Remove';
import { EditHead } from './components/dialogs/EditHead';
import { ErrorMessage } from './components/dialogs/ErrorMessage';
import { EditEmployees } from './components/dialogs/EditEmployees';

import { DialogTypes } from '../../bi/services/departments/types';

export const Dialogs = {
  [DialogTypes.empty]: () => null,
  [DialogTypes.editHead]: EditHead,
  [DialogTypes.editEmployees]: EditEmployees,
  [DialogTypes.deleteEmployee]: Remove,
  [DialogTypes.deleteHead]: () => <Remove isHead />,
  [DialogTypes.error]: ErrorMessage,
};

export const LABELS = {
  HEADER: getText('settings:departments.department.title'),
  ADD_HEAD_OF_DEPARTMENT: getText('settings:departments.department.addHeadOfDepartment'),
  ADD_EMPLOYEES: getText('settings:departments.department.addEmployees'),
  DIALOG_SUBMIT: getText('settings:departments.department.dialogSubmit'),
  DIALOG_CANCEL: getText('common:undo'),
  SUBMIT_FORM: getText('common:save'),
  CLOSE_DIALOG: getText('settings:departments.department.closeDialog'),
  ADD_EMPLOYEE: getText('settings:departments.department.addEmployee'),
  SELECT_EMPLOYEES: getText('settings:departments.department.selectEmployees'),
  EMPLOYEES: getText('settings:departments.department.employees'),
  ERROR: getText('settings:departments.department.error'),
  DUPLICATE_DEPARTMENT: getText('settings:departments.department.duplicateDepartment'),
  DEPARTMENT_NAME: getText('settings:departments.department.departmentName'),
  ORGANIZATION: getText('settings:departments.department.organization'),
  HEAD: getText('settings:departments.department.head'),
  HEAD_HINT: getText('settings:departments.department.headHint'),
  HEAD_OF_DEPARTMENT: getText('settings:departments.department.headOfDepartment'),
  COMPANY_NULL_ERROR: getText('settings:departments.department.companyNullError'),
  SAVE_SUCCESS: (name: string | number) => getText('settings:departments.department.saveSuccess', { name }),
  CANCEL: getText('common:undo'),
  EXIT_MESSAGE: getText('settings:departments.department.exitMessage'),
  REQUIRED_FIELDS: getText('settings:departments.department.requiredFields'),
  REMOVE: {
    TITLE: getText('settings:departments.department.remove'),
    DESCRIPTION_EMPLOYEE: getText('settings:departments.department.descriptionEmployee'),
    DESCRIPTION_HEAD: getText('settings:departments.department.descriptionHead'),
    DESCRIPTION: (position: string, fullName: string) => getText(
      'settings:departments.department.removeDescription', { position, fullName },
    ),
    CONFIRM: getText('components:travelApproval.deleteRequestDialog.buttons.confirm'),
    CANCEL: getText('common:undo'),
  },
};
