import React from 'react';
import { SidePanel, Text } from 'new-ui';
import { observer } from 'mobx-react';

import { getText, getTextArray } from '../../../../../i18n';
import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { DEFAULT_RATES } from '../../../../bi/constants/insurance';

import { ExternalLink } from '../../../ExternalLink';

import { EnumRateName, InsuranceServiceType } from '../../../../bi/types/insurance';

import styles from './styles/index.module.css';

const FULL_CONDITION_URL = 'https://smartway.today/best_price_guarantee'; // заменить на актуальную ссылку

const LABELS = {
  BASE_RATE_LABEL: 'components:insurance.rate',
  ABOUT_TITLE: getText('components:insurance.about'),
  FULL_CONDITION: getText('components:insurance.fullCondition'),
  EXAMPLE: getText('components:insurance.example'),
  PRICE_SYMBOL: getText('components:insurance.price'),
  INSURANCE_CASE: getText('components:insurance.case'),
  HOW_CONFIRM: getText('components:insurance.confirm'),
  GET_SICK: getText('components:insurance.getSick'),
  SICK_LEAVE: getText('components:insurance.sickLeave'),
  REFUND: getText('components:insurance.refund'),
  REFUND_LIST: getTextArray('components:insurance.refundList'),
  RATE: {
    TITLE: (rateName: EnumRateName) => getText(`${LABELS.BASE_RATE_LABEL}.${rateName}.title`),
    DESC_ONE: (rateName: EnumRateName) => getText(`${LABELS.BASE_RATE_LABEL}.${rateName}.list.one`),
    DESC_TWO: (rateName: EnumRateName) => getText(`${LABELS.BASE_RATE_LABEL}.${rateName}.list.two`),
    EXAMPLE: (rateName: EnumRateName) => getTextArray(`${LABELS.BASE_RATE_LABEL}.${rateName}.example`),
  },
};

interface IInsuranceSidePanelProps {
  insuranceService: InsuranceServiceType,
}

const InsuranceSidePanel = observer(({
  insuranceService: {
    setInsuranceSidePanel,
  },
}: IInsuranceSidePanelProps) => {
  const {
    insuranceStore: {
      isOpenInsuranceSidePanel,
      rates,
    },
  } = useStores([MOBX_STORES.INSURANCE_STORE]);

  const shownRates = rates.length ? rates : DEFAULT_RATES;

  const renderRates = () => shownRates.map((rate, idx) => {
    const { Rate, Price } = rate;

    // для правильных переносов
    const exampleTextHTML = (LABELS.RATE.EXAMPLE(Rate) as string[]).map(str => (
      <Text key={ str } type='NORMAL_14_130'>{ str }</Text>
    ));

    return (
      <div
        className={ styles.rate }
        key={ `${styles.rate}_${idx}` }
      >
        <div className={ styles.rate_top }>
          <Text type='SEMIBOLD_16'>{ LABELS.RATE.TITLE(Rate) }</Text>
          <Text type='NORMAL_14_130'>{`${Price} ${LABELS.PRICE_SYMBOL}`}</Text>
        </div>
        <ul className={ styles.rate_list }>
          <li>
            <Text type='NORMAL_14_130'>{ LABELS.RATE.DESC_ONE(Rate) }</Text>
          </li>
          <li>
            <Text type='NORMAL_14_130'>{ LABELS.RATE.DESC_TWO(Rate) }</Text>
          </li>
        </ul>
        <div className={ styles.rate_example }>
          <Text type='SEMIBOLD_14'>{ LABELS.EXAMPLE }</Text>
          { exampleTextHTML }
        </div>
      </div>
    );
  });

  const renderConditions = () => (
    <div className={ styles.conditions }>
      <div>
        <Text
          color='gray'
          type='SEMIBOLD_14'
          className={ styles.conditions_title }
        >
          { LABELS.INSURANCE_CASE }
        </Text>
        <ul className={ styles.conditions_list }>
          <li><Text type='NORMAL_14_130'>{ LABELS.GET_SICK }</Text></li>
        </ul>
      </div>
      <div>
        <Text
          color='gray'
          type='SEMIBOLD_14'
          className={ styles.conditions_title }
        >
          { LABELS.HOW_CONFIRM }
        </Text>
        <ul className={ styles.conditions_list }>
          <li><Text type='NORMAL_14_130'>{ LABELS.SICK_LEAVE }</Text></li>
        </ul>
      </div>
    </div>
  );

  const renderRefund = () => {
    const refundListItems = (LABELS.REFUND_LIST as string[]).map((str, idx) => (
      <li key={ `${str}_${idx}` }>
        <Text type='NORMAL_14_130'>{ str }</Text>
      </li>
    ));

    return (
      <div className={ styles.refund }>
        <Text
          type='SEMIBOLD_16'
          className={ styles.refund_title }
        >
          { LABELS.REFUND }
        </Text>
        <ul>{ refundListItems }</ul>
      </div>
    );
  };

  const renderContent = () => (
    <div className={ styles.content }>
      <div className={ styles.top }>
        <Text type='bold_24'>{ LABELS.ABOUT_TITLE }</Text>
        <ExternalLink
          link={ FULL_CONDITION_URL }
          text={ LABELS.FULL_CONDITION }
        />
      </div>
      <div className={ styles.rates }>
        { renderRates() }
      </div>
      { renderConditions() }
      { renderRefund() }
    </div>
  );

  return (
    <div className={ styles.wrap }>
      <SidePanel
        show={ isOpenInsuranceSidePanel }
        onClose={ () => setInsuranceSidePanel(false) }
      >
        { renderContent() }
      </SidePanel>
    </div>
  );
});

export { InsuranceSidePanel };
