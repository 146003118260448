const CLOSING_SCHEMAS = [
  'InvoicePlusClosing',
  'InvoicePlusClosingECLC',
  'InvoicePlusClosingForEachAct',
  'InvoicePlusClosingForEachProject',
  'InvoicePlusClosingIntratool',
  'SeparateActInvoicePlusClosing',
];

export { CLOSING_SCHEMAS };
