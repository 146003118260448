import React from 'react';
import { Dialog, Text, Button } from 'new-ui';
import { getText } from '../../../i18n';

import styles from './index.module.css';

interface IBookChildDialogProps {
  show: boolean,
  onClose(): void,
  onContinue(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void,
}

const LABELS = {
  CANCEL: getText('common:undo'),
  CONTINUE: getText('common:continue'),
  TEXT1: getText('components:bookChildDialog.description.one'),
  TEXT2: getText('components:bookChildDialog.description.two'),
  TEXT3: getText('components:bookChildDialog.description.three'),
};

const BookChildDialog = ({ show, onClose, onContinue }: IBookChildDialogProps) => (
  <Dialog show={ show } onChange={ onClose }>
    <div className={ styles.wrapper }>
      <Text type='NORMAL_16_140'>{LABELS.TEXT1}</Text>
      <Text type='NORMAL_16_140'>{LABELS.TEXT2}</Text>
      <Text type='NORMAL_16_140'>{LABELS.TEXT3}</Text>
      <div className={ styles.actions }>
        <Button onClick={ onClose } className={ styles.action }>
          {LABELS.CANCEL}
        </Button>
        <Button onClick={ onContinue } className={ styles.action } type='textual-medium'>
          {LABELS.CONTINUE}
        </Button>
      </div>
    </div>
  </Dialog>
);

export { BookChildDialog };
