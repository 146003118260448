import React from 'react';
import { Text } from 'new-ui';

import splitWithoutRemovingSeparator from '../../bi/utils/splitWithoutRemovingSeparator';

import styles from './styles.module.css';

const renderSuggestionTitle = (title: string) => (
  <Text type='SEMIBOLD_16'>
    { title }
  </Text>
);

const renderSuggestionItem = (name: string | any, region: string) => (
  <div className={ styles.item }>
    <Text className={ styles.name }>
      { name }
    </Text>
    <Text className={ styles.hint }>{ region }</Text>
  </div>
);

const highlightWordStart = (string: string, query: string) => {
  const parts = splitWithoutRemovingSeparator(string, query, true);

  return [
    <Text
      key={ Math.random() * Math.random() }
      type='SEMIBOLD_16'
      className={ styles.highlight }
    >
      { parts[0] }
    </Text>,
    ...parts.slice(1),
  ];
};

interface HotelSuggestionProps {
  title?: string,
  items?: any[],
  Name?: string,
  FullName?: string,
  IsRegion?: boolean,
  query: string,
}

const HotelSuggestion = ({
  title = '',
  items = [],
  Name = '',
  FullName = '',
  query,
}: HotelSuggestionProps) => {
  if (title && items && items.length) return renderSuggestionTitle(title);

  if (!query) return renderSuggestionItem(Name, FullName);

  const queryWords = splitWithoutRemovingSeparator(query);
  const nameWords = splitWithoutRemovingSeparator(Name);

  const highlightedName: any[] = [];
  nameWords.forEach((nameWord) => {
    let match = null;
    queryWords.some((queryWord) => {
      if (nameWord.toLowerCase().startsWith(queryWord.toLowerCase())) {
        match = queryWord;

        return true;
      }

      return false;
    });

    const highlightedNameWord = match
      ? highlightWordStart(nameWord, match)
      : nameWord;

    highlightedName.push(highlightedNameWord);
  });

  return renderSuggestionItem(highlightedName, FullName);
};

export { HotelSuggestion };
