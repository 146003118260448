import React, { Component } from 'react';
import { Dialog, Button, Input, Text, StyledWrapper, LinkButton } from 'new-ui';

import { getText } from '../../../i18n';

import config from '../../../config';

import { isSmartAgent } from '../../bi/utils/env';
import { firstCapitalLetter } from '../../bi/utils/strings';

import AppService from '../../bi/services/app';
import AuthService from '../../bi/services/auth';

import { UiSettingsProtocol } from '../../bi/protocols/uiSettings';

import logoSmartway from '../../../svg/logos/text-smartway-logo.svg';
import iconSmartway from '../../../svg/logos/s-smartway-icon.svg';
import iconSmartagent from '../../../svg/logos/smartagent-logo.svg';

import styles from './styles/index.module.css';

const LABELS = {
  email: getText('login:email'),
  password: getText('login:password'),
  signIn: getText('login:signIn'),
  reset: getText('login:reset'),
};

interface ILoginDialogProps {
  authService: AuthService;
  appService: AppService;
  uiSettingsProtocol?: UiSettingsProtocol,
  isDialogMode?: boolean,
  onLogin(value?: string): Promise<void>,
}

export interface ILoginDialogState {
  showPassword: boolean,
  loginDialog: boolean,
  loading: boolean,
  username: string,
  password: string,
  isValid: boolean,
  error: string,
  buttonDisabled: boolean,
}

class LoginDialog extends Component<ILoginDialogProps, ILoginDialogState> {
  static defaultProps = {
    isDialogMode: true,
    uiSettingsProtocol: null,
  };

  unsubscribeFn: any;
  unsubscribeAppFn: any;

  constructor(props: ILoginDialogProps) {
    super(props);

    const {
      authService: {
        store: {
          getState,
        },
      },
      isDialogMode,
    } = props;

    const {
      buttonDisabled,
      error,
      isValid,
      loading,
      password,
      username,
    } = getState();

    this.state = {
      loading,
      username,
      password,
      isValid,
      error,
      buttonDisabled,
      loginDialog: !isDialogMode,
      showPassword: false,
    };
  }

  componentDidMount() {
    this.unsubscribeFn = this.props.authService.subscribe(this.updateState);
    this.unsubscribeAppFn = this.props.appService.subscribe(this.updateAppState);
  }

  componentWillUnmount() {
    this.unsubscribeFn();
    this.unsubscribeAppFn();
  }

  updateState = (state: ILoginDialogState) => {
    this.setState({
      ...state,
    });
  };

  updateAppState = (state: ILoginDialogState) => {
    if (state.loginDialog !== this.state.loginDialog) {
      this.setState({
        loginDialog: state.loginDialog,
      });
    }
  };

  handleSubmit = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (!this.state.loading) {
      this.props.onLogin().then(() => {
        if (this.props.isDialogMode) {
          this.handleCloseDialog();
        }
      });
    }
  };

  handleCloseDialog = () => {
    this.props.appService.hideLogin();
  };

  handleShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  renderShowPassword = () => {
    const { showPassword } = this.state;

    const classes = `${styles.eyes} ${showPassword ? styles.showEyes : ''}`;

    return (
      <span onClick={ this.handleShowPassword } className={ classes } />
    );
  };

  renderContent = () => {
    const {
      username,
      password,
      isValid,
      error,
      loading,
      buttonDisabled,
      showPassword,
    } = this.state;

    const {
      isDialogMode,
      authService,
      uiSettingsProtocol,
    } = this.props;

    const typeText = buttonDisabled ? 'NORMAL_12' : 'NORMAL_14';
    const errorHtml = error && (
      <Text type={ typeText } className={ styles['error-message'] }>
        { error }
      </Text>
    );

    const stylesButton = !error ? styles.row : '';

    const passwordType = showPassword ? 'type' : 'password';

    const showPasswordHtml = this.renderShowPassword();

    let companyLogo = logoSmartway;
    let nameLogo = 'Smartway';
    const nameLogoSmartagent = 'Smartagent';

    if (isDialogMode && uiSettingsProtocol !== undefined) {
      const { img, name } = uiSettingsProtocol.getLogo();

      companyLogo = img;
      nameLogo = firstCapitalLetter(name);
    }

    const renderLogo = () => {
      if (!isSmartAgent) {
        return (
          <>
            <img src={ iconSmartway } alt={ nameLogo } title={ nameLogo } />
            <img src={ companyLogo } alt={ nameLogo } title={ nameLogo } />
          </>
        );
      }

      return (
        <img src={ iconSmartagent } alt={ nameLogoSmartagent } title={ nameLogoSmartagent } />
      );
    };

    return (
      <StyledWrapper className={ styles.wrapper }>
        <div className={ styles.logo }>
          {renderLogo()}
        </div>
        <form
          className={ styles.form }
          onSubmit={ this.handleSubmit }
        >
          <div className={ styles.row }>
            <div className={ styles.group }>
              <Text className={ styles.label } type='NORMAL_14'>
                { LABELS.email }
              </Text>
              <Input
                type='email'
                value={ username }
                onChange={ value => authService.setField('username', value) }
              />
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles.group }>
              <Text className={ styles.label } type='NORMAL_14'>
                { LABELS.password }
              </Text>
              <div className={ styles.password_group }>
                <Input
                  type={ passwordType }
                  value={ password }
                  onChange={ value => authService.setField('password', value) }
                  className={ styles.form_input }
                />
                {showPasswordHtml}
              </div>
            </div>
          </div>
          { errorHtml }
          <div className={ stylesButton }>
            <Button
              className={ styles.submit }
              loading={ loading }
              onClick={ this.handleSubmit }
              type='secondary'
              disabled={ !isValid || buttonDisabled }
            >
              { LABELS.signIn }
            </Button>
          </div>
        </form>
        <div className={ styles['restore-password'] }>
          <LinkButton onClick={ () => {
            document.location = config.RESTORE_PASSWORD;
          } }
          >
            { LABELS.reset }
          </LinkButton>
        </div>
      </StyledWrapper>
    );
  };

  render() {
    const { loginDialog } = this.state;
    const { isDialogMode } = this.props;

    if (!loginDialog) return null;

    if (isDialogMode) {
      return (
        <Dialog
          show={ loginDialog }
          onChange={ this.handleCloseDialog }
        >
          { this.renderContent() }
        </Dialog>
      );
    }

    return this.renderContent();
  }
}

export default LoginDialog;
