import React, { useEffect, useState } from 'react';
import { Icon, Price, Text, Tooltip } from 'new-ui';

import { getText } from '../../../../../i18n';

import TitleTrips from '../Title';

import { isSmartAgent } from '../../../../bi/utils/env';
import { getIconType } from '../../../../bi/utils/trip';
import { formatRangeDateWithSimplicity } from '../../../../bi/utils/formatDate';
import { reductionDot, reductionEmp, reductionProbel, truncateString } from '../../utils';

import { ICON_TYPES_SMARTDESK, TRIPSTATUS } from '../../../../bi/constants/trips';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { COUNT_EMPLOYEE_TRIP_SA, TITLE_TRIP_SA_LENGTH } from '../../const';

import { IEmployeesFull, ITripAgent } from '../../types';

import styles from './styles/index.module.css';

const LABELS = {
  MORE: (num: number) => getText('smartdesk:tripsPanel.more', { num }),
};

const TripAgent = ({
  value: {
    Name,
    CheckInDate,
    CheckOutDate,
    Price: Prices,
    Services,
    Status,
    EmployeesFull,
  },
  index,
  max,
}: ITripAgent) => {
  const [titleLength, setTitleLength] = useState(TITLE_TRIP_SA_LENGTH[1]);

  useEffect(() => {
    const uniqueService = new Set(Services.filter((value) => value !== SERVICETYPE.FLIGHT_CERTIFICATE
      && value !== SERVICETYPE.AIR_ADDITIONAL_SERVICE
      && value !== SERVICETYPE.CUSTOM
      && value !== SERVICETYPE.VIP_HALL,
    ));

    setTitleLength(TITLE_TRIP_SA_LENGTH[uniqueService.size]);
  }, [Services.length]);

  const servicesHtml = (service: string, key: number) => {
    const iconType = getIconType(service);

    if (!iconType || iconType === ICON_TYPES_SMARTDESK.OTHER) {
      return null;
    }

    return (
      <div className={ styles.icon } key={ `${service}_${key}` }>
        <Icon
          type={ iconType }
          className={ styles.service }
          alternativeDesign={ isSmartAgent }
        />
      </div>
    );
  };

  const renderEmployees = () => {
    const renderList = (value: IEmployeesFull[]) => (
      value.map(({ Surname, Name: Names, Patronymic, EmployeeId }, ind) => {
        if (ind > COUNT_EMPLOYEE_TRIP_SA.ONE) {
          return null;
        }

        const isDot = ind === COUNT_EMPLOYEE_TRIP_SA.ZERO && value.length > COUNT_EMPLOYEE_TRIP_SA.ONE;
        const isProbel = (ind === COUNT_EMPLOYEE_TRIP_SA.ZERO && value.length >= COUNT_EMPLOYEE_TRIP_SA.TWO)
          || (ind === COUNT_EMPLOYEE_TRIP_SA.ONE && value.length > COUNT_EMPLOYEE_TRIP_SA.TWO);

        return (
          <Text
            key={ EmployeeId }
            type='NORMAL_14'
            className={ styles.employee }
          >
            { Surname }&nbsp;
            { reductionEmp(Names[0]) }
            { reductionEmp(Patronymic[0]) }
            { reductionDot(isDot) }
            { reductionProbel(isProbel, <>&nbsp;</>) }
          </Text>
        );
      }));

    const renderAdditional = (value: IEmployeesFull[]) => {
      if (value.length <= COUNT_EMPLOYEE_TRIP_SA.TWO) {
        return null;
      }

      return value.map(({ Surname, Name: Names, Patronymic, EmployeeId }, ind) => {
        if (ind < COUNT_EMPLOYEE_TRIP_SA.TWO) {
          return null;
        }

        return (
          <div
            key={ EmployeeId }
            className={ styles.wrapper_additional }
          >
            <Text
              key={ EmployeeId }
              type='NORMAL_14'
              color='white'
              className={ styles.employee_additional }
            >
              { Surname }&nbsp;
              { reductionEmp(Names[0]) }
              { reductionEmp(Patronymic[0]) }
              { reductionDot(ind < EmployeesFull.length - COUNT_EMPLOYEE_TRIP_SA.ONE) }
            </Text>
          </div>
        );
      });
    };

    const renderMore = (value: IEmployeesFull[]) => {
      if (value.length <= COUNT_EMPLOYEE_TRIP_SA.TWO) {
        return null;
      }

      const renderTooltip = () => (
        <div className={ styles.wrap }>
          { renderAdditional(value) }
        </div>
      );

      return (
        <Tooltip
          renderContent={ () => renderTooltip() }
        >
          <Text color='emerald' type='NORMAL_14'>
            { LABELS.MORE(value.length - COUNT_EMPLOYEE_TRIP_SA.TWO) }
          </Text>
        </Tooltip>
      );
    };

    return (
      <>
        { renderList(EmployeesFull) }
        { renderMore(EmployeesFull) }
      </>
    );
  };

  const priceHtml = () => {
    if (!Prices || Status === TRIPSTATUS.AWAITINGTICKETING) {
      return null;
    }

    return (
      <Price
        marginSmall
        typeCurrency='SEMIBOLD_16'
        type='SEMIBOLD_16'
        className={ styles.wrapper_price }
        value={ Prices }
      />
    );
  };

  const renderInfo = () => (
    <div className={ `${styles.column} ${styles.three} ${styles.rigth}` }>
      <Text type='bold_16'>
        { truncateString(Name, titleLength) }
      </Text>
      <div className={ styles.employees }>
        { renderEmployees() }
      </div>
    </div>
  );

  const renderAdditionalInfo = () => (
    <div className={ styles.wrapper_additional_info }>
      <Text type='NORMAL_14'>
        { formatRangeDateWithSimplicity(CheckInDate, CheckOutDate) }
      </Text>
      { priceHtml() }
    </div>
  );

  const renderIcons = () => {
    const iconsContent = Services.map(servicesHtml);
    const isIconsEvery = iconsContent.every((element) => element === null);

    const content = !iconsContent.length || isIconsEvery
      ? <div className={ styles.empty_icons } />
      : iconsContent;

    return (
      <div className={ `${styles.column} ${styles.icons} ${styles.three}` }>
        { content }
      </div>
    );
  };

  return (
    <div>
      <TitleTrips isShow={ !index && max }/>
      <div className={ styles.wrapper }>
        { renderIcons() }
        <div className={ styles.wrapper_info }>
          { renderInfo() }
        </div>
      </div>
      { renderAdditionalInfo() }
    </div>
  );
};

export { TripAgent };
