import { action, makeObservable, observable } from 'mobx';

export interface IQualityStore {
  message: string,
  result: boolean,
  loadingButton: boolean,
  successResponse: boolean,
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable message = '';
  @observable result = false;
  @observable loadingButton = false;
  @observable successResponse = false;

  @action
  setMessage = (value: string): void => {
    this.message = value;
  };

  @action
  setResult = (value: boolean): void => {
    this.result = value;
  };

  @action
  setSuccessResponse = (value: boolean): void => {
    this.successResponse = value;
  };

  @action
  updateLoadingButton = (value: boolean): void => {
    this.loadingButton = value;
  };

  @action
  clearMessage = (): void => {
    this.message = '';
    this.result = false;
  };
}

const QualityStore = new Store();
export { QualityStore, Store as QualityStoreType };
