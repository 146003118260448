import { Component, ComponentType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import CONFIG from '../../../config';

import WorkspaceService from '../../bi/services/workspace';
import AppService from '../../bi/services/app';

import STATUS from '../../bi/constants/status';

const yaCounter = CONFIG.METRIKS.YANDEX.ID;
const enabled = CONFIG.METRIKS.YANDEX.ENABLED;

interface IYandexMetricProps {
  workspaceService: WorkspaceService,
  appService: AppService,
  children: ComponentType | any,
  history: RouteComponentProps['history'],
}

interface IYandexMetricState {
  lastPathName: any,
}

class YandexMetric extends Component<IYandexMetricProps, IYandexMetricState> {
  state: IYandexMetricState;

  constructor(props: IYandexMetricProps) {
    super(props);
    const { history } = props;

    this.state = {
      lastPathName: history.location.pathname,
    };

    this.initializeYandexMetric();
  }

  componentDidMount() {
    const { history } = this.props;

    history.listen((location) => {
      const { lastPathName } = this.state;

      if (lastPathName !== location.pathname) {
        this.changeUrl(lastPathName, location.pathname);
      }
    });
  }

  checkMetric = () => {
    const { appService } = this.props;
    const { appStatus } = appService.get();

    return enabled && appStatus !== STATUS.NOTAUTH && typeof (window[yaCounter]) !== 'undefined';
  };

  initializeYandexMetric = () => {
    const { workspaceService } = this.props;

    const { Email, CompanyName, CompanyId } = workspaceService.get();

    if (this.checkMetric()) {
      // @ts-ignore
      window[yaCounter].setUserID(CompanyId);
      // @ts-ignore
      window[yaCounter].userParams({
        email: Email,
        companyName: CompanyName,
      });
    }
  };

  changeUrl = (lastPathName: any, pathName: string) => {
    if (this.checkMetric()) {
      // @ts-ignore
      window[yaCounter].hit(pathName, { referer: lastPathName });
    }

    this.setState({ lastPathName: pathName });
  };

  render() {
    return this.props.children;
  }
}

export default withRouter(YandexMetric as ComponentType<any>);
