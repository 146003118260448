import React from 'react';
import { Text } from 'new-ui';

import styles from '../styles/trainCancelled.module.css';

const SorryTrainCancelled = () => (
  <div>
    <Text type='NORMAL_18'>
      Временно не работает отмена билетов ЖД из-за технического сбоя на стороне поставщика.
    </Text>
    <Text type='NORMAL_18' className={ styles.text }>
      Мы уже отправили отчет техническим специалистам, но, к сожалению, нет точных сроков, когда все исправят.
    </Text>
    <Text type='NORMAL_18' className={ styles.text }>
      Попробуйте, пожалуйста, повторить отмену после 12:00.
    </Text>
  </div>
);

export { SorryTrainCancelled };
