import React from 'react';
import { TabButton } from 'new-ui';

import { ALL } from '../../bi/constants/note';

import { Tab } from '../../bi/types/shared';

import styles from './styles/index.module.css';

interface FilterTabsProps {
  tabs: Tab[],
  currentTab: string | number,
  onClick(tab: Tab): void,
  qaAttr?: string,
}

const FilterTabs = ({ tabs, currentTab = ALL, onClick, qaAttr = '' }: FilterTabsProps) => {
  const tabsHtml = tabs.map((tab, index) => (
    <TabButton
      key={ `${tab.type}__${index}` }
      isActive={ currentTab === tab.value }
      onClick={ () => onClick(tab) }
      className={ styles.item }
      qaAttr={ `${qaAttr}-${tab.value}` }
    >
      { tab.label }
    </TabButton>
  ));

  return (
    <div className={ styles.wrap }>
      { tabsHtml }
    </div>
  );
};

export { FilterTabs };
