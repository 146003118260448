import { action, observable, makeObservable } from 'mobx';

import { SORT_TYPES } from '../../../constants/vipHall';

import type { SearchInfo, ResponseSearchType, Segment } from '../types';

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable searchInfo: SearchInfo = {
    sort_type: SORT_TYPES.BY_ASCENDING,
    items: [],
  };
  @observable route: Segment[] = [];
  @observable terminals: string[] = [];
  @observable selectedTerminal = '';
  @observable uuid = '';
  @observable availableAirports: string[] = [];
  @observable textTemplateWarning = '';

  @action
  setSelectedRoute = (route: Segment[]) => {
    this.route = route;
  };

  @action
  setTerminals = (result: ResponseSearchType, uuid: string) => {
    const listTerminals = result.services.map(({ terminal_name }) => terminal_name);

    this.terminals = [...new Set(listTerminals)];
    this.uuid = uuid;
  };

  @action
  setSelectedTerminal = (value: string) => {
    this.selectedTerminal = value;
  };

  @action
  setAvailableAirports = (airports: string[]) => {
    this.availableAirports = airports;
  };

  @action
  setTextTemplateWarning = (text: string) => {
    this.textTemplateWarning = text;
  };

  @action
  resetStore = () => {
    this.searchInfo = {
      sort_type: SORT_TYPES.BY_ASCENDING,
      items: [],
    };
    this.route = [];
    this.terminals = [];
    this.selectedTerminal = '';
    this.uuid = '';
  };
}

const VipHallSearchStore = new Store();

export interface IVipHallSearchStore {
  searchInfo: SearchInfo,
  route: Segment[],
  terminals: string[],
  selectedTerminal: string,
  uuid: string,
  availableAirports: string[],
  textTemplateWarning: string,
  setSelectedRoute(route: Segment[]): void,
  setTerminals(result: ResponseSearchType, uuid: string): void,
  setSelectedTerminal(value: string): void,
  setAvailableAirports(airports: string[]): void,
  setTextTemplateWarning(text: string): void,
  resetStore(): void,
}

export { VipHallSearchStore };
