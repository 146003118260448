import React, { FC } from 'react';
import { Text } from 'new-ui';
import { getText } from '../../../i18n';

import { Rating } from '../Rating';
import CollapsibleText from '../CollapsibleText';
import { formatDate, textualMonthPattern } from '../../bi/utils/formatDate';

import styles from './styles/index.module.css';
import { ReviewItem } from '../../bi/services/hotels/types';

type ReviewProps = {
  value: ReviewItem,
};

const LABELS = {
  DATE_OF_REVIEW: getText('components:review.dateOfReview'),
  TRIP_ADVISOR: getText('components:review.tripAdvisor'),
};

const prepareReviewDate = (date: string, withDot = false) => (
  date && new Date(date).getFullYear() !== 1
    ? `${LABELS.DATE_OF_REVIEW} ${formatDate(date, textualMonthPattern)}${withDot ? '. ' : ''}`
    : ''
);

const Review: FC<ReviewProps> = ({
  value: {
    Title,
    Name,
    Rating: rating,
    Cons,
    Pros,
    Comment,
    IsTripAdvisor,
    Date,
  },
}) => {
  const titleContent = Title && (
    <Text type='bold_22' className={ styles.title }>
      { Title }
    </Text>
  );

  const prosContent = Pros && (
    <div className={ styles.pros }>
      <div className={ styles['icon-wrapper'] }>
        <i className='material-icons'>add_circle</i>
      </div>
      <CollapsibleText text={ Pros } className={ styles.text } />
    </div>
  );

  const consContent = Cons && (
    <div className={ styles.cons }>
      <div className={ styles['icon-wrapper'] }>
        <i className='material-icons'>remove_circle</i>
      </div>
      <CollapsibleText text={ Cons } className={ styles.text } />
    </div>
  );

  const commentContent = Comment && (
    <div className={ styles.comment }>
      <CollapsibleText text={ Comment } className={ styles.text } />
    </div>
  );

  const dateText = IsTripAdvisor
    ? `${prepareReviewDate(Date, true)}${LABELS.TRIP_ADVISOR}`
    : `${prepareReviewDate(Date)}`;
  const dateContent = dateText && (
    <Text type='NORMAL_12' className={ styles.date }>
      { dateText }
    </Text>
  );

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.header }>
        <Rating value={ rating } style={ { marginRight: '16px' } } />
        <Text type='bold_18'>{ Name }</Text>
      </div>
      { titleContent }
      { prosContent }
      { consContent }
      { commentContent }
      { dateContent }
    </div>
  );
};

export { Review };
