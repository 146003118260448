import { observable, action, makeObservable } from 'mobx';

import { IAirAdditionalService } from '../../types/airlineAdditionalServices';

export class AirlineAdditionalServicesStore {
  constructor() {
    makeObservable(this);
  }

  @observable airlineAdditionalServices: IAirAdditionalService | null = null;

  @action
  updateAirlineAdditionalServices = (additionalData: IAirAdditionalService) => {
    this.airlineAdditionalServices = additionalData;
  };
}

export interface IAirlineAdditionalServicesStore {
  airlineAdditionalServices: IAirAdditionalService | null;
  updateAirlineAdditionalServices(additionalData: IAirAdditionalService): void;
}

export const airlineAdditionalServicesStore = new AirlineAdditionalServicesStore();
