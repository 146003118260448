import React from 'react';
import { observer } from 'mobx-react';

import { IUserPopup } from './types';

import Popup from './components/Popup';

const UserPopupsLayout = observer(({
  store: {
    popups,
  },
}: IUserPopup) => {
  if (!popups.length) {
    return null;
  }

  const content = popups.map(s => (<Popup popup={ s } key={ s.key } />));

  return <div>{ content }</div>;
});

export default UserPopupsLayout;
