import React from 'react';

import { WorkspaceService } from '../../bi/types/workspace';

// import CONFIG from '../../../config';

interface MixPanelMetricProp {
  workspaceService: WorkspaceService;
}

interface IData {
  IsDemo: boolean;
  CompanyId: number;
  ShortCompanyName: string;
  Email: string;
}

class MixPanelMetric extends React.Component<MixPanelMetricProp> {
  constructor(props: MixPanelMetricProp) {
    super(props);

    const data = props.workspaceService.get();

    this.initializeMixPanelMetric(data);
  }

  initializeMixPanelMetric = (data: IData) => {
    if (window.mixpanel && !data.IsDemo) {
      window.mixpanel.people.set({ Company: data.ShortCompanyName, User: data.Email });
      window.mixpanel.identify(data.CompanyId);
      window.mixpanel.isDemo = false;
    } else {
      window.mixpanel.isDemo = true;
    }
  };

  render() {
    return null;
  }
}

export default MixPanelMetric;
