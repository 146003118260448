type FramingWordsType = { [n: string]: string };

const FramingWords: FramingWordsType = {
  QUOTE: '\'',
  QUOTES: '"',
  ROUNDBRACKET: '(',
};

const transformFirstLetter = (word: string): string => {
  const temp = word.toLocaleLowerCase();

  return `${temp[0].toLocaleUpperCase()}${temp.slice(1)}`;
};

const transformFramingWords = (word: string): string => {
  const arrayFramingWords = Object.keys(FramingWords);

  let sign = '';
  let isFirstSign = false;

  arrayFramingWords.forEach((key) => {
    if (word.indexOf(FramingWords[key], 0) === 0) {
      sign = word[0];
      isFirstSign = true;
    }
  });

  if (isFirstSign) {
    return sign + transformFirstLetter(word.substring(1));
  }

  return transformFirstLetter(word);
};

const transformWordsWithDash = (word: string): string => {
  if (/[-]/.test(word)) {
    const result = word.split('-').map(transformFramingWords);

    return result.join('-');
  }

  return transformFramingWords(word);
};

const textTransformation = (sentence: string): string => {
  const result = sentence.split(' ').map(transformWordsWithDash);

  return result.join(' ');
};

export default textTransformation;
