import { currentLng } from '../../../../i18n';

const onload = '__react-yandex-maps-onload';
const onerror = '__react-yandex-maps-onerror';

const callbacks = { onload, onerror };
const ns = '__react-yandex-maps-api';
const lang = currentLng === 'en' ? 'en_US' : 'ru_RU';

const fetchScript = (url: string) =>
  new Promise((resolve, reject) => {
    const script: HTMLScriptElement = document.createElement('script');

    script.type = 'text/javascript';
    script.onload = resolve;
    script.onerror = reject;
    script.src = url;
    script.async = true;

    document.head.appendChild(script);
  });

const getQuery = (query: any, apikey: string) => {
  const options = { ns, lang, apikey, ...query, ...callbacks };

  return Object.keys(options)
    .reduce((prev, key) => {
      if (typeof options[key] === 'undefined') return prev;

      // @ts-ignore
      return prev.concat(`${key}=${options[key]}`);
    }, [])
    .join('&');
};

const getBaseUrl = (version = '2.1', enterprise = false) => {
  const protocol = 'https:';
  const host = enterprise ? 'enterprise.api-maps.yandex.ru' : 'api-maps.yandex.ru';

  return `${protocol}//${host}/${version}/`;
};

class YandexMapsApi {
  promise: Promise<void> | null;

  constructor() {
    this.promise = null;
  }

  load = (query: any, version: string, apikey: string, enterprise: boolean) => {
    if (this.promise) return this.promise;

    this.promise = new Promise((resolve, reject) => {
      const baseUrl = getBaseUrl(version, enterprise);
      const urlQuery = getQuery(query, apikey);

      // @ts-ignore
      window[onload] = (api) => {
        resolve(api);
        // @ts-ignore
        window[onload] = null;
      };
      // @ts-ignore
      window[onerror] = (error) => {
        reject(error);
        // @ts-ignore
        window[onerror] = null;
      };

      fetchScript(`${baseUrl}?${urlQuery}`).catch(reject);
    });

    return this.promise;
  };

  get = (query: string, version: string, apikey: string, enterprise = false) => this.load(query, version, apikey, enterprise).then((api) => api);
}

export default new YandexMapsApi();
