import { action, makeObservable, observable } from 'mobx';

export interface ICloudPaymentsStore {
  cantLeave: boolean
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable cantLeave = false;

  @action
  setCantLeave = (value: boolean): void => {
    this.cantLeave = value;
  };
}

const CloudPaymentsStore = new Store();

export { CloudPaymentsStore };
