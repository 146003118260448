import { action, observable, makeObservable } from 'mobx';
import { Moment } from 'moment';

import { currentDate, isBeforeDate } from '../../utils/formatDate';

import { VoucherStoreType } from './types';

interface Voucher {
  date: null | Moment,
  dateTo: null | Moment,
  minDate: Moment,
  minDateTo: null | Moment,
  amount: number | string,
  vat: number,
  count: number | string,
  availableClasses: number[],
}

export interface ITaxiStore {
  voucher: Voucher,
  activeVouchers: VoucherStoreType[],
  createdVouchers: VoucherStoreType[],
  loading: boolean,
  loadingActiveVouchers: boolean,
  loadingCreatedVouchers: boolean,
  textTemplateWarning: string,
  setDate(value: Moment | null): void,
  setDateTo(value: Moment | null): void,
  setAmount(value: number | string): void,
  setCount(value: number | string): void,
  setAvailableClasses(value: number): void,
  resetVoucher(): void,
  setActiveVouchers(vouchers: VoucherStoreType[]): void,
  setCreatedVouchers(vouchers: VoucherStoreType[]): void,
  setLoading(value: boolean): void,
  setLoadingActiveVouchers(value: boolean): void,
  voucherIsValid(): boolean,
  setTextTemplateWarning(value: string): void,
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable voucher: Voucher = {
    date: null,
    dateTo: null,
    minDate: currentDate().clone(),
    minDateTo: null,
    amount: 5000,
    vat: 1000,
    count: 1,
    availableClasses: [1],
  };
  @observable activeVouchers: VoucherStoreType[] = [];
  @observable createdVouchers: VoucherStoreType[] = [];
  @observable loading = true;
  @observable loadingActiveVouchers = false;
  @observable loadingCreatedVouchers = false;
  @observable textTemplateWarning = '';

  @action
  setDate = (value: Moment | null): void => {
    const { dateTo } = this.voucher;
    const newDateTo = dateTo && value && isBeforeDate(dateTo, value) ? value.clone().add(1, 'd') : dateTo;

    this.voucher.date = value;
    this.voucher.dateTo = newDateTo;

    this.voucher.minDateTo = value ? value.clone() : currentDate().clone();
  };

  @action
  setDateTo = (value: Moment | null): void => {
    this.voucher.dateTo = value || null;
  };

  @action
  setAmount = (value: number | string): void => {
    this.voucher.amount = value;
  };

  @action
  setVAT = (value: number | string) => {
    this.voucher.vat = Number(value) / 5;
  };

  @action
  setCount = (value: number | string): void => {
    this.voucher.count = value;
  };

  @action
  setAvailableClasses = (value: number): void => {
    const { availableClasses } = this.voucher;

    if (!availableClasses.includes(value)) {
      availableClasses.push(value);
    } else {
      this.voucher.availableClasses = availableClasses.filter(item => item !== value);
    }
  };

  @action
  resetVoucher = (): void => {
    this.voucher = {
      ...this.voucher,
      date: null,
      dateTo: null,
      minDate: currentDate().clone(),
      minDateTo: null,
      availableClasses: [1],
      amount: 5000,
      vat: 1000,
      count: 1,
    };
  };

  @action
  setActiveVouchers = (vouchers: VoucherStoreType[]): void => {
    this.activeVouchers = vouchers;
  };

  @action
  setCreatedVouchers = (vouchers: VoucherStoreType[]): void => {
    this.createdVouchers = vouchers;
  };

  @action
  setLoading = (value: boolean): void => {
    this.loading = value;
  };

  @action
  setLoadingActiveVouchers = (value: boolean): void => {
    this.loadingActiveVouchers = value;
  };

  @action
  setLoadingCreatedVouchers = (value: boolean): void => {
    this.loadingCreatedVouchers = value;
  };

  @action
  setTextTemplateWarning = (text: string): void => {
    this.textTemplateWarning = text;
  };

  @action
  voucherIsValid = (): boolean => {
    const { date, dateTo, amount, count, availableClasses } = this.voucher;

    return Boolean(date
      && dateTo
      && date.isValid()
      && dateTo.isValid()
      && dateTo.isAfter(date)
      && (amount as number) <= 41667
      && (count as number) >= 1
      && (count as number) <= 40
      && availableClasses.length,
    );
  };
}

const TaxiStore = new Store();

export { TaxiStore, Store as TaxiStoreType };
