import React from 'react';
import { MomentInput } from 'moment';

import { formatDate } from '../../bi/utils/formatDate';

import { PATTERN } from '../../bi/constants/dateFormats';

import styles from './styles/request-train-item.module.css';

interface IRequestTrainItemProps {
  searchOptions: {
    ArrivalStation: { Name: string },
    DepartureStation: { Name: string },
    DepartureDate: MomentInput,
  },
  comment: string,
}

const RequestTrainItem = ({ searchOptions, comment }: IRequestTrainItemProps) => {
  const departureDate = formatDate(searchOptions.DepartureDate, PATTERN.LONG_FULL_YEAR);

  const trainItem = (
    <div className={ styles.wrap }>
      <div className={ `${styles.column} ${styles.left}` }>
        <div className={ styles.city }>
          { searchOptions.DepartureStation.Name }
        </div>
        <div className={ styles.date }>
          { departureDate }
        </div>
      </div>
      <div className={ `${styles.column} ${styles.middle}` }>
        <div><span className={ `${styles.arrow} smartway-long_arrow_right` } /></div>
      </div>
      <div className={ `${styles.column} ${styles.right}` }>
        <div className={ styles.city }>
          { searchOptions.ArrivalStation.Name }
        </div>
      </div>
    </div>
  );

  return (
    <div>
      { trainItem }
      <div className={ styles.comment }>{ comment }</div>
    </div>
  );
};

export default RequestTrainItem;
