import { namespaces } from '../consts';

import applicationScheme from './namespaces/applicationScheme.json';
import common from './namespaces/common.json';
import smartdesk from './namespaces/smartdesk.json';
import services from './namespaces/services.json';
import components from './namespaces/components.json';
import search from './namespaces/search.json';
import booking from './namespaces/booking.json';
import favorites from './namespaces/favorites.json';
import constants from './namespaces/constants.json';
import expenseReports from './namespaces/expenseReports.json';
import hotels from './namespaces/hotels.json';
import air from './namespaces/air.json';
import trains from './namespaces/trains.json';
import taxi from './namespaces/taxi.json';
import login from './namespaces/login.json';
import quality from './namespaces/quality.json';
import quickApprove from './namespaces/quickApprove.json';
import reports from './namespaces/reports.json';
import requests from './namespaces/requests.json';
import note from './namespaces/note.json';
import cart from './namespaces/cart.json';
import approve from './namespaces/approve.json';
import approvalScheme from './namespaces/approvalScheme.json';
import checkout from './namespaces/checkout.json';
import settings from './namespaces/settings.json';
import trip from './namespaces/trip.json';
import trips from './namespaces/trips.json';
import utils from './namespaces/utils.json';
import aeroexpress from './namespaces/aeroexpress.json';
import analytics from './namespaces/analytics.json';
import chartsAnalytics from './namespaces/chartsAnalytics.json';
import offer from './namespaces/offer.json';
import offerSmartAgent from './namespaces/offerSmartAgent.json';
import transfer from './namespaces/transfer.json';
import vipHall from './namespaces/vipHall.json';
import travelPolicyQuiz from './namespaces/travelPolicyQuiz.json';
import event from './namespaces/event.json';
import payment from './namespaces/payment.json';

const ru = {
  [namespaces.common]: common,
  [namespaces.smartdesk]: smartdesk,
  [namespaces.services]: services,
  [namespaces.components]: components,
  [namespaces.search]: search,
  [namespaces.booking]: booking,
  [namespaces.favorites]: favorites,
  [namespaces.constants]: constants,
  [namespaces.expenseReports]: expenseReports,
  [namespaces.hotels]: hotels,
  [namespaces.air]: air,
  [namespaces.trains]: trains,
  [namespaces.taxi]: taxi,
  [namespaces.login]: login,
  [namespaces.quality]: quality,
  [namespaces.quickApprove]: quickApprove,
  [namespaces.reports]: reports,
  [namespaces.requests]: requests,
  [namespaces.note]: note,
  [namespaces.cart]: cart,
  [namespaces.approve]: approve,
  [namespaces.approvalScheme]: approvalScheme,
  [namespaces.checkout]: checkout,
  [namespaces.settings]: settings,
  [namespaces.trip]: trip,
  [namespaces.trips]: trips,
  [namespaces.utils]: utils,
  [namespaces.aeroexpress]: aeroexpress,
  [namespaces.analytics]: analytics,
  [namespaces.chartsAnalytics]: chartsAnalytics,
  [namespaces.offer]: offer,
  [namespaces.offerSmartAgent]: offerSmartAgent,
  [namespaces.transfer]: transfer,
  [namespaces.vipHall]: vipHall,
  [namespaces.applicationScheme]: applicationScheme,
  [namespaces.travelPolicyQuiz]: travelPolicyQuiz,
  [namespaces.event]: event,
  [namespaces.payment]: payment,
} as const;

export { ru };
