import { getText } from '../../../../i18n';

const STATUS_APPLICATION_EVENT = {
  CREATED: 'Created',
  BOOKED: 'Booked',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
};

const STATUS_EVENTS = [
  {
    label: getText('event:statusEvents.created'),
    value: STATUS_APPLICATION_EVENT.CREATED,
  },
  {
    label: getText('event:statusEvents.booked'),
    value: STATUS_APPLICATION_EVENT.BOOKED,
  },
  {
    label: getText('event:statusEvents.completed'),
    value: STATUS_APPLICATION_EVENT.COMPLETED,
  },
  {
    label: getText('event:statusEvents.cancelled'),
    value: STATUS_APPLICATION_EVENT.CANCELLED,
  },
];

const DEFAULT_EVENT = {
  CreatedDate: '',
  User: '',
  Name: '',
  Place: '',
  Member: '',
  Budget: '',
  Comment: '',
  StartDate: null,
  EndDate: null,
  Status: '',
  Trips: null,
  Id: null,
};

const EVENT_STATUSES = {
  DEFAULT: ['Created', 'Booked', 'Cancelled', 'Completed'],
  CART: ['Created', 'Booked'],
};

export {
  STATUS_EVENTS,
  DEFAULT_EVENT,
  EVENT_STATUSES,
};
