import React from 'react';

import styles from './styles.module.css';

import { AIRLINECLASSES } from '../../consts';

import type { AirlineClassType } from '../../types/types';

interface RowDesignationsType {
  airlineClass: AirlineClassType,
  columns: string
}

const RowDesignations = ({
  airlineClass,
  columns,
}: RowDesignationsType) => {
  const classNames = [styles.glyph];

  if (airlineClass === AIRLINECLASSES.econom) {
    classNames.push(styles.econom);
  }

  if (airlineClass === AIRLINECLASSES.business) {
    classNames.push(styles.business);
  }

  if (airlineClass === AIRLINECLASSES.first) {
    classNames.push(styles.first);
  }

  const renderedColumns = columns
    .split('')
    .map((glyph, index) =>
      <div key={ index } className={ classNames.join(' ') }>{glyph}</div>);

  return <div className={ styles.wrap }>{ renderedColumns }</div>;
};

export default RowDesignations;
