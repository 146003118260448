import React from 'react';
import { DotLoading, Text } from 'new-ui';

import styles from '../../index.module.css';

interface ILoadingProps {
  label: string
}

const Loading = ({
  label,
}: ILoadingProps) => (
  <div className={ styles.loading_wrap }>
    <Text type='NORMAL_16_140'>{ label }</Text>
    <div className={ styles.loader }>
      <DotLoading />
    </div>
  </div>
);

export { Loading };
