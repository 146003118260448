import { observable, action, makeObservable } from 'mobx';

import type {
  BonusProgramValueStore,
  BonusProgramEmailStore,
  BonusProgramRegistrationStore,
  BonusProgramAddingStore,
} from './types';

export interface IBonusProgramStore {
  bonuses: BonusProgramValueStore,
  addingCode: BonusProgramAddingStore,
  email: BonusProgramEmailStore,
  registration: BonusProgramRegistrationStore
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable bonuses: BonusProgramValueStore = {
    FailedBalances: [],
    Balances: [],
    UserIsAdmin: false,
    AdminName: '',
    AdminEmail: '',
    Status: 0,
    loading: false,
    error: false,
  };

  @observable addingCode: BonusProgramAddingStore = {
    Success: false,
    Error: null,
    Companies: [],
    loading: false,
    error: false,
  };

  @observable email: BonusProgramEmailStore = {
    Corporate: false,
    Individual: false,
    loading: false,
    error: false,
  };

  @observable registration: BonusProgramRegistrationStore = {
    Error: null,
    Company: '',
    Inn: '',
    Code: '',
    loading: false,
    error: false,
  };

  @action
  updateBonuses = (value: BonusProgramValueStore) => {
    this.bonuses = {
      ...this.bonuses,
      ...value,
    };
  };

  @action
  updateBonusEmail = (value: BonusProgramEmailStore) => {
    this.email = {
      ...this.email,
      ...value,
    };
  };

  @action
  updateBonusRegistration = (value: BonusProgramRegistrationStore) => {
    this.registration = {
      ...this.registration,
      ...value,
    };
  };

  @action
  updateAddingCode = (value: BonusProgramAddingStore) => {
    this.addingCode = {
      ...this.addingCode,
      ...value,
    };
  };
}

const BonusProgramStore = new Store();

export { BonusProgramStore, Store as BonusProgramStoreType };
