import React, { MouseEvent } from 'react';
import { Dropdown, Text, Button, Icon, Collapse } from 'new-ui';

import { getText } from '../../../../i18n';

import MoneyFormat from '../../../bi/utils/money';
import { formatDate, defaultPattern, daysAndMounth } from '../../../bi/utils/formatDate';
import { MainAnalytic } from '../../../bi/utils/analytics';

import FORMATS from '../../../bi/constants/formats';

import { IDetail, IOperationsList, PaymentType, FormatType } from '../../../bi/types/report';

import styles from '../styles/row.module.css';

interface RowProps {
  item: IOperationsList;
  onDownload: (operation: IOperationsList, format: FormatType, type: PaymentType) => void;
  onOpenSendInvoiceDialog: (invoiceNum: string) => void;
  renderPenaltyBySchema: boolean;
}

const {
  PDF,
  XLSX,
} = FORMATS;

const LABELS = {
  SEND: getText('components:operationsTable.row.send'),
  INVOICE_PDF: getText('components:operationsTable.row.invoicePdf'),
  INVOICE_XLSX: getText('components:operationsTable.row.invoiceXlsx'),
  INVOICE_PENALTY: (date: string) => getText('components:operationsTable.row.invoicePenalty', { date }),
};

const CREDIT_TYPE = '3';

const isPenalty = (type: number | string, details: IDetail[]) => type === 2 && details.find(detail => detail.Type === 2);

const getDebitAndCreditInMoneyFormat = ({ IsOutgoing, Credit, Debit, Type }: IOperationsList) => {
  if (IsOutgoing) {
    return { creditMoneyFormat: MoneyFormat.moneyWithDecimal(Credit, true), debitMoneyFormat: null };
  }

  if (Type === CREDIT_TYPE) {
    return { creditMoneyFormat: MoneyFormat.moneyWithDecimal(Credit, true), debitMoneyFormat: null };
  }

  return { creditMoneyFormat: null, debitMoneyFormat: MoneyFormat.moneyWithDecimal(Debit, true) };
};

const renderDownloadButton = (
  { InvoiceName, InvoiceNum, EventDate, Type, Details, IsOutgoing }: IOperationsList,
  renderPenaltyBySchema: boolean,
  handleDownload: (e: MouseEvent, format: FormatType, type: PaymentType) => void,
  handleOpenSendInvoiceDialog: (e: MouseEvent) => void,
) => {
  const hasPenalty = isPenalty(Type, Details);
  const date = formatDate(EventDate, defaultPattern);

  const handleSendAnalyticsAndDownload = (e: MouseEvent, pdf: FormatType, type: PaymentType) => {
    handleDownload(e, pdf, type);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.DOC_ARCHIVE);
  };

  const handleOpenSendAnalyticsAndInvoiceDialog = (e: MouseEvent) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.SEND_INVOICE);
    handleOpenSendInvoiceDialog(e);
  };

  const sendButtonHtml = (
    <Button
      type='textual'
      onClick={ handleOpenSendAnalyticsAndInvoiceDialog }
    >
      {LABELS.SEND}
    </Button>
  );

  if (IsOutgoing && InvoiceNum && !hasPenalty) {
    return (
      <div>
        <Text>{ InvoiceName }</Text>
        <Text type='NORMAL_16_140'> от { date }</Text>
        <div className={ styles.download }>
          <Dropdown
            title={ LABELS.INVOICE_PDF }
            onClick={ e => (handleSendAnalyticsAndDownload(e, PDF, 'invoice')) }
            smooth
          >
            <Button
              className={ styles.btn }
              type='textual'
              onClick={ e => handleDownload(e, XLSX, 'invoice') }
            >
              {LABELS.INVOICE_XLSX}
            </Button>
          </Dropdown>
        </div>
        { sendButtonHtml }
      </div>
    );
  }

  if (hasPenalty && renderPenaltyBySchema) {
    return (
      <div>
        <Text>{LABELS.INVOICE_PENALTY(date)}</Text>
        <div className={ styles.download }>
          <Dropdown
            title={ LABELS.INVOICE_PDF }
            onClick={ e => (handleSendAnalyticsAndDownload(e, PDF, 'penalty')) }
            smooth
          >
            <Button
              className={ styles.btn }
              type='textual'
              onClick={ e => (handleDownload(e, XLSX, 'penalty')) }
            >
              {LABELS.INVOICE_XLSX}
            </Button>
          </Dropdown>
        </div>
        { sendButtonHtml }
      </div>
    );
  }

  return null;
};

const renderDetail = ({ Description, Amount, Id }: IDetail) => {
  const refillInMoneyFormat = Amount > 0 ? null : MoneyFormat.moneyWithDecimal(Math.abs(Amount), true);
  const amountInMoneyFormat = Amount < 0 ? null : MoneyFormat.moneyWithDecimal(Math.abs(Amount), true);

  const refillHtml = refillInMoneyFormat && <Text type='SEMIBOLD_16'>{refillInMoneyFormat}</Text>;
  const amountHtml = amountInMoneyFormat && <Text type='SEMIBOLD_16'>{amountInMoneyFormat}</Text>;

  return (
    <div key={ Id } className={ styles.row }>
      <div className={ styles.date } />
      <Text type='NORMAL_14_130' color='gray' className={ styles.name }>
        { Description }
      </Text>
      <div className={ styles.refill }>{refillHtml}</div>
      <div className={ styles.amount }>{amountHtml}</div>
      <div className={ styles.document } />
    </div>
  );
};

const renderDetails = (details: IDetail[]) => {
  if (!details.length) {
    return null;
  }

  return (
    <div className={ styles.details }>
      {details.map(renderDetail)}
    </div>
  );
};

const Row = ({ item, onDownload, onOpenSendInvoiceDialog, renderPenaltyBySchema }: RowProps) => {
  const handleOpenSendInvoiceDialog = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    onOpenSendInvoiceDialog(item.InvoiceNum);
  };

  const handleDownload = (e: MouseEvent, format: FormatType, type: PaymentType) => {
    e.preventDefault();
    e.stopPropagation();

    onDownload(item, format, type);
  };

  const { Description, EventDate, Details } = item;

  const { creditMoneyFormat, debitMoneyFormat } = getDebitAndCreditInMoneyFormat(item);
  const debitHtml = debitMoneyFormat && <Text type='SEMIBOLD_18'>{ debitMoneyFormat }</Text>;
  const creditHtml = creditMoneyFormat && <Text type='SEMIBOLD_18'>{creditMoneyFormat }</Text>;

  const [title, nameAndDate] = Description;
  const nameAndDateHtml = nameAndDate &&
    (<Text type='NORMAL_16_140' className={ styles.names }>{nameAndDate}</Text>);

  const downLoadButtonHtml = renderDownloadButton(item, renderPenaltyBySchema, handleDownload, handleOpenSendInvoiceDialog);
  const descriptionHtml = renderDetails(Details);

  return (
    <Collapse
      className={ styles.wrap }
      openedClassName={ styles.open }
      opened={ false }
      renderContent={ () => (
        <div className={ styles.row }>
          <Text className={ styles.date }>{ formatDate(EventDate, daysAndMounth) }</Text>
          <div className={ styles.name }>
            <Text>{ title }</Text>
            { nameAndDateHtml }
          </div>
          <div className={ styles.refill }>{ debitHtml }</div>
          <div className={ styles.amount }>{ creditHtml }</div>
          <div className={ styles.document }>
            {downLoadButtonHtml}
          </div>
          <div className={ styles.arrow }>
            <Icon type='arrowsDownGray'/>
          </div>
        </div>
      ) }
    >
      { descriptionHtml }
    </Collapse>
  );
};

export { Row };
