import React from 'react';
import { Dialog, Button } from 'new-ui';
import { getText } from '../../../i18n';

import styles from './styles.module.css';

const LABELS = {
  CONFIRM: getText('trip:approvalRequestDialog.confirmButton'),
  TEXT: (RequestId: number) => getText('trip:approvalRequestDialog.text', { RequestId }),
};

interface ApproveActionDialogProps {
  show: boolean;
  loading: boolean;
  RequestId: number;
  onSave(): void;
  onClose(): void;
}

const ApproveActionDialog = ({
  show,
  RequestId,
  onClose,
  onSave,
  loading,
}: ApproveActionDialogProps) => {
  const buttonsHtml = (
    <div className={ styles.button_wrapper }>
      <Button onClick={ onSave } loading={ loading }>
        { LABELS.CONFIRM }
      </Button>
    </div>
  );

  const contentHtml = (
    <div className={ styles.wrapper }>
      <div className={ styles.text }>
        { LABELS.TEXT(RequestId) }
      </div>
      { buttonsHtml }
    </div>
  );

  return (
    <Dialog
      show={ show }
      onChange={ onClose }
      showClosing
      className={ styles.dialog }
    >
      { contentHtml }
    </Dialog>
  );
};

export default ApproveActionDialog;
