import React, { PropsWithChildren } from 'react';

import { ServiceReturnType } from './services';

export type GetterType = (value: string) => object;

export interface IProviderProps {
  getService <S extends keyof ServiceReturnType>(serviceName: S): ServiceReturnType[S]
  getProtocol: GetterType
}

export const servicesProtocolsContext = React.createContext<IProviderProps | null>(null);

export const ServicesProtocolProvider = ({
  children,
  getService,
  getProtocol,
}: PropsWithChildren<IProviderProps>) => (
  <servicesProtocolsContext.Provider value={ { getService, getProtocol } }>
    { children }
  </servicesProtocolsContext.Provider>
);
