import { getText } from '../../../i18n';

const TRAVEL_APPROVAL = {
  UNAVAILABLE: 'Unavailable',
  AVAILABLE: 'Available',
};

const TRAVEL_APPROVAL_RU = {
  UNAVAILABLE: getText('approvalScheme:questionnaire.2.1'),
  AVAILABLE: getText('approvalScheme:questionnaire.2.2'),
};

const APPROVAL = {
  EVERY: 'Every',
  DISRUPTED_TP: 'Disrupted_TP',
  UNAVAILABLE: 'Unavailable',
};

const APPROVAL_RU = {
  EVERY: getText('approvalScheme:questionnaire.3.1'),
  DISRUPTED_TP: getText('approvalScheme:questionnaire.3.2'),
  UNAVAILABLE: getText('approvalScheme:questionnaire.3.3'),
};

const WHO_BUY = {
  EMPLOYEES: 'Employees',
  TRAVEL_MANAGER: 'TravelManager',
};

const WHO_BUY_RU = {
  EMPLOYEES: getText('approvalScheme:questionnaire.1.1'),
  TRAVEL_MANAGER: getText('approvalScheme:questionnaire.1.2'),
};

const WHO_BUYS = [
  {
    name: WHO_BUY_RU.EMPLOYEES,
    id: WHO_BUY.EMPLOYEES,
    value: false,
  },
  {
    name: WHO_BUY_RU.TRAVEL_MANAGER,
    id: WHO_BUY.TRAVEL_MANAGER,
    value: false,
  },
];

enum StatusScheme {
  CREATE = 'Create',
  EDIT = 'Edit',
  SETTING_EMPLOYEES = 'SettingEmployees',
}

const APPROVAL_SCHEME_STEP_FIELDS = {
  TYPE: 'Type',
  CONDITION_OF_APPROVAL: 'ConditionOfApproval',
  APPROVERS: 'Approvers',
  ROLES: 'Roles',
  APPLY_FOR_ALL: 'ApplyForAll',
};

const APPROVAL_TYPES = {
  TRIP: 'trip',
  REPORT: 'report',
};

const DEFAULT_APPROVAL_SCHEME_FORM_STEP = {
  [APPROVAL_SCHEME_STEP_FIELDS.TYPE]: 0,
  [APPROVAL_SCHEME_STEP_FIELDS.CONDITION_OF_APPROVAL]: 0,
  [APPROVAL_SCHEME_STEP_FIELDS.APPROVERS]: [],
  [APPROVAL_SCHEME_STEP_FIELDS.ROLES]: [],
};

const DEFAULT_APPROVAL_SCHEME_FORM_PRE_STEP = {
  [APPROVAL_SCHEME_STEP_FIELDS.CONDITION_OF_APPROVAL]: 0,
  [APPROVAL_SCHEME_STEP_FIELDS.APPROVERS]: [],
  [APPROVAL_SCHEME_STEP_FIELDS.ROLES]: [],
};

const DEFAULT_APPROVAL_SCHEME_FORM_VALUE = {
  Id: null,
  Name: '',
  ApplyForAll: false,
  TemplateRequestId: 'default' as const,
  Roles: {
    value: [],
    hash: {},
    loading: false,
  },
  Employees: {
    value: [],
    hash: {},
    loading: false,
  },
  Steps: [],
  PreSteps: [],
  Utils: {
    submitLoading: false,
    admins: {
      value: [],
      loading: false,
    },
  },
};

const APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES_LABELS = {
  EVERY: getText('services:approvalSchemes.const.conditionOfApprovalTypes.every'),
  ONLY_ONE_SEND_EVERY: getText('services:approvalSchemes.const.conditionOfApprovalTypes.onlyOneSendEvery'),
  ONLY_ONE_SEND_CHOSEN: getText('services:approvalSchemes.const.conditionOfApprovalTypes.onlyOneSendChosen'),
};

const APPROVAL_SCHEME_TYPES_LABELS = {
  EVERY: getText('services:approvalSchemes.const.types.every'),
  DISRUPTED_TP: getText('services:approvalSchemes.const.types.disruptedTp'),
};

const APPROVAL_SCHEME_TYPES: { [key: string]: any } = {
  EVERY: { value: 0, label: APPROVAL_SCHEME_TYPES_LABELS.EVERY },
  DISRUPTED_TP: { value: 1, label: APPROVAL_SCHEME_TYPES_LABELS.DISRUPTED_TP },
};

const APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES = {
  EVERY: { value: 0, label: APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES_LABELS.EVERY },
  ONLY_ONE_SEND_EVERY: { value: 1, label: APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES_LABELS.ONLY_ONE_SEND_EVERY },
  ONLY_ONE_SEND_CHOSEN: { value: 2, label: APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES_LABELS.ONLY_ONE_SEND_CHOSEN },
};

const APPROVAL_STEP_DRAGGABLE_ITEM_TYPES = {
  EMPLOYEE: 'Approvers',
  ROLE: 'Roles',
};

const TABS_FAQ_FIELD = {
  ONE: '1_1',
  TWO: '1_2',
  THREE: '1_3',
  FOUR: '1_4',
  FIVE: '1_5',
  SECOND: '2',
  THIRD: '3',
};

const TABS_FAQ = [
  {
    id: '1',
    label: getText('approvalScheme:faq.tabs.1.title'),
    children: [
      {
        id: TABS_FAQ_FIELD.ONE,
        label: getText('approvalScheme:faq.tabs.1.1.title'),
      },
      {
        id: TABS_FAQ_FIELD.TWO,
        label: getText('approvalScheme:faq.tabs.1.2.title'),
      },
      {
        id: TABS_FAQ_FIELD.THREE,
        label: getText('approvalScheme:faq.tabs.1.3.title'),
      },
      {
        id: TABS_FAQ_FIELD.FOUR,
        label: getText('approvalScheme:faq.tabs.1.4.title'),
      },
      {
        id: TABS_FAQ_FIELD.FIVE,
        label: getText('approvalScheme:faq.tabs.1.5.title'),
      },
    ],
  },
  {
    id: '2',
    label: getText('approvalScheme:faq.tabs.2.title'),
  },
  {
    id: '3',
    label: getText('approvalScheme:faq.tabs.3.title'),
  },
];

const TABS_TEXT_FAQ = {
  [TABS_FAQ_FIELD.ONE]: {
    ONE: getText('approvalScheme:faq.tabs.1.1.texts.one'),
    TWO: getText('approvalScheme:faq.tabs.1.1.texts.two'),
  },
  [TABS_FAQ_FIELD.TWO]: {
    ONE: getText('approvalScheme:faq.tabs.1.2.texts.one'),
    TWO: getText('approvalScheme:faq.tabs.1.2.texts.two'),
  },
  [TABS_FAQ_FIELD.THREE]: {
    ONE: getText('approvalScheme:faq.tabs.1.3.texts.one'),
    TWO: getText('approvalScheme:faq.tabs.1.3.texts.two'),
  },
  [TABS_FAQ_FIELD.FOUR]: {
    ONE: getText('approvalScheme:faq.tabs.1.4.texts.one'),
    TWO: getText('approvalScheme:faq.tabs.1.4.texts.two'),
    THREE: getText('approvalScheme:faq.tabs.1.4.texts.three'),
  },
  [TABS_FAQ_FIELD.FIVE]: {
    ONE: getText('approvalScheme:faq.tabs.1.5.texts.one'),
    TWO: getText('approvalScheme:faq.tabs.1.5.texts.two'),
  },
  2: {
    ONE: getText('approvalScheme:faq.tabs.2.texts.one'),
    TWO: getText('approvalScheme:faq.tabs.2.texts.two'),
    THREE: getText('approvalScheme:faq.tabs.2.texts.three'),
    FOUR: getText('approvalScheme:faq.tabs.2.texts.four'),
    FIVE: getText('approvalScheme:faq.tabs.2.texts.five'),
    SIX: getText('approvalScheme:faq.tabs.2.texts.six'),
    SEVEN: getText('approvalScheme:faq.tabs.2.texts.seven'),
    EIGHT: getText('approvalScheme:faq.tabs.2.texts.eight'),
    NINE: getText('approvalScheme:faq.tabs.2.texts.nine'),
  },
  3: {
    ONE: getText('approvalScheme:faq.tabs.3.texts.one'),
    TWO: getText('approvalScheme:faq.tabs.3.texts.two'),
    THREE: getText('approvalScheme:faq.tabs.3.texts.three'),
  },
};

const DEFAULT_APPROVAL_SCHEME = {
  Id: null,
  Name: '',
  ApplyForAll: false,
  Roles: {
    value: [],
    hash: {},
    loading: true,
  },
  Employees: {
    value: [],
    hash: {},
    loading: true,
  },
  Steps: [],
  Utils: {
    submitLoading: false,
    admins: {
      value: [],
      loading: false,
    },
  },
};

const DEFAULT_APPROVAL_SCHEME_FORM_EXPENSE_REPORTS = {
  [APPROVAL_SCHEME_STEP_FIELDS.CONDITION_OF_APPROVAL]: 0,
  [APPROVAL_SCHEME_STEP_FIELDS.APPROVERS]: [],
  [APPROVAL_SCHEME_STEP_FIELDS.ROLES]: [],
};

const LOADINGS = {
  LIST: 'loadList',
  APPROVERS: 'loadApprovers',
  SCHEME: 'loadScheme',
  ROLES: 'loadRoles',
};

export {
  TRAVEL_APPROVAL,
  DEFAULT_APPROVAL_SCHEME,
  APPROVAL,
  WHO_BUYS,
  StatusScheme,
  TRAVEL_APPROVAL_RU,
  APPROVAL_RU,
  DEFAULT_APPROVAL_SCHEME_FORM_VALUE,
  DEFAULT_APPROVAL_SCHEME_FORM_STEP,
  APPROVAL_SCHEME_STEP_FIELDS,
  APPROVAL_SCHEME_TYPES,
  APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES,
  APPROVAL_STEP_DRAGGABLE_ITEM_TYPES,
  DEFAULT_APPROVAL_SCHEME_FORM_PRE_STEP,
  TABS_FAQ,
  TABS_TEXT_FAQ,
  TABS_FAQ_FIELD,
  DEFAULT_APPROVAL_SCHEME_FORM_EXPENSE_REPORTS,
  APPROVAL_TYPES,
  LOADINGS,
};
