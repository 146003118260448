import React from 'react';
import { MomentInput } from 'moment';

import FormatService from '../../bi/services/format';

import styles from './styles/request-transfer-item.module.css';

interface RequestTransferItemProps {
  formatService: FormatService,
  searchOptions: {
    DeparturePlace: { Name: string },
    DepartureDate: MomentInput,
    ArrivalPlace: { Name: string },
  },
  comment: string,
}

const RequestTransferItem = ({ formatService, searchOptions, comment }: RequestTransferItemProps) => {
  const transferItem = (
    <div className={ styles.wrap }>
      <div className={ `${styles.column} ${styles.left}` }>
        <div className={ styles.city }>
          { searchOptions.DeparturePlace.Name }
        </div>
        <div className={ styles.date }>
          { formatService.date(searchOptions.DepartureDate, 'DD.MM.YYYY HH:MM') }
        </div>
      </div>
      <div className={ `${styles.column} ${styles.middle}` }>
        <div><span className={ `${styles.arrow} smartway-long_arrow_right` } /></div>
      </div>
      <div className={ `${styles.column} ${styles.right}` }>
        <div className={ styles.city }>
          { searchOptions.ArrivalPlace.Name }
        </div>
      </div>
    </div>
  );

  return (
    <div>
      { transferItem }
      <div className={ styles.comment }>{ comment }</div>
    </div>
  );
};

export default RequestTransferItem;
