import React, { memo } from 'react';
import { Text, TextColor } from 'new-ui';

interface ITechnicalStopTextProps {
  technicalStopInfo: string[];
  className: string;
  textColor?: TextColor;
}

export const TechnicalStopS7Text = memo(({ technicalStopInfo, className, textColor = 'gray' }: ITechnicalStopTextProps) => (
  technicalStopInfo.map((technicalStop, index) => (
    <div key={ index } className={ className }>
      <Text
        type='NORMAL_14'
        color={ textColor }
      >
        { technicalStop }
      </Text>
    </div>
  ))
));
