import React, { Component } from 'react';
import { DotLoading, Text, Button, Icon, Dialog, Stars, Tooltip, ItemPanel, Collapsible } from 'new-ui';
import { getText } from '../../../i18n';

import { preloadOrigUrlAndUrl } from '../../bi/utils/images';
import { dateFormat, dateUtcFormat, formatDate, getReduceNumberDays, isCurrentYear } from '../../bi/utils/formatDate';

import FAVORITESACTION from '../../bi/constants/favorites';
import { MEAL } from '../../bi/constants/hotel';
import { DATEFORMATS, PATTERN } from '../../bi/constants/dateFormats';
import COUNTRIES from '../../bi/constants/countries';

import { RefundHotel } from '../RefundHotel';
import { HotelNameBlock } from '../HotelNameBlock';
import { HotelServices } from '../HotelServices';
import { ImageGallery } from '../ImageGallery/ImageGallery';
import { HotelCancellation } from '../HotelCancellation';
import { HotelCheckinCheckoutPanel } from '../HotelCheckinCheckoutPanel';

import HotelsService from '../../bi/services/hotels';
import NotificationService from '../../bi/services/notification';
import FavoritesService from '../../bi/services/favorites';
import { HotelStatic, HotelItemType } from '../../bi/services/hotels/types';

import { isSmartAgent } from '../../bi/utils/env';

import styles from './styles/index.module.css';

const LABELS = {
  INFO_BY_HOTEL: getText('components:hotelItem.infoByHotel'),
  FAVORITE_REMOVE: (name: string) => getText('components:hotelItem.notifications.favoriteRemove', { name }),
  FAVORITE_ADD: (name: string) => getText('components:hotelItem.notifications.favoriteAdd', { name }),
  FREE_WIFI: getText('components:hotelItem.freeWifi'),
  ROOM: (name: string) => getText('components:hotelItem.room', { name }),
  FREE_CANCELLATION: (cancellationDate: string | null) => getText('components:hotelItem.freeCancellation', { cancellationDate }),
  FREE_CANCELLATION_FOREIGN: (cancellationDate: string | null) => getText('components:hotelItem.freeCancellationForeign', { cancellationDate }),
  CENTER_DISTANCE: getText('components:hotelItem.centerDistance'),
  KM: getText('components:hotelItem.km'),
  SHOW_ON_MAP: getText('components:hotelItem.showOnMap'),
  EARLY_IN: (earlyIn: string) => getText('components:hotelItem.earlyIn', { earlyIn }),
  LATE_OUT: (lateOut: string) => getText('components:hotelItem.lateOut', { lateOut }),
  ATTENTION_FREE_CANCELLATION: getText('hotels:hotelResult.item.generalList.attentionFreeCancellation'),
  REQUIRED_FEES: getText('components:hotelItem.requiredFees'),
  FEES_TITLE: getText('hotels:requiredFees.title'),
  RESERVED: (value: string) => getText('hotels:reserved', { value }),
  HAS_CANCELATION: getText('hotels:hasCancellation'),
};

interface HotelItemProps {
  item: HotelItemType,
  additionalData?: { PropertyFees: string[] },
  hotelsService: HotelsService,
  notificationService: NotificationService,
  favoritesService: FavoritesService,
  fromTrip?: boolean,
  hotelItemFullInfo?: boolean,
  reservedHotelSmartagent?: boolean,
  soldOut?: boolean,
  preloadedHotel: HotelStatic | null,
  aggregationId: number | null,
  canBeReserved?: boolean,
  isReserved?: boolean,
  qaAttrTitle?: string,
  qaAttrDate?: string,
  qaAttrDetails?: string,
  qaAttrCancellation?: string,
  qaAttrDetailsBreakfast?: string,
}

interface HotelItemState {
  hotel: HotelStatic | null,
  showDetails: boolean,
  loading?: boolean,
  images: ({ OrigUrl: string, Url: string } | null)[],
}

class HotelItem extends Component<HotelItemProps, HotelItemState> {
  static defaultProps = {
    additionalData: null,
    fromTrip: false,
    hotelItemFullInfo: false,
    soldOut: false,
    aggregationId: null,
    hotelsService: {},
    notificationService: {},
    favoritesService: {},
    workspaceService: {},
    preloadedHotel: null,
    qaAttrTitle: '',
    qaAttrDate: '',
    qaAttrDetails: '',
    qaAttrCancellation: '',
    qaAttrDetailsBreakfast: '',
    canBeReserved: false,
    isReserved: false,
  };

  unsubscribeFn: () => void;

  constructor(props: HotelItemProps) {
    super(props);

    const { preloadedHotel } = props;

    this.state = {
      hotel: preloadedHotel || null,
      showDetails: false,
      images: [],
    };
  }

  prepareHotelClassificatorId = () => {
    const { item, fromTrip } = this.props;

    return fromTrip ? item.raw.Hotel.ClassificatorId : item.hotel.ClassificatorId;
  };

  componentDidMount = () => {
    const {
      hotelsService,
      hotelItemFullInfo,
      preloadedHotel,
    } = this.props;

    if (hotelItemFullInfo) {
      const id = this.prepareHotelClassificatorId();

      if (id) {
        this.unsubscribeFn = hotelsService.subscribeItems(this.updateState);

        if (!preloadedHotel) {
          hotelsService.addStaticHotel(this.prepareHotelClassificatorId());
        }
      }
    }
  };

  componentWillUnmount() {
    const { item, hotelItemFullInfo, hotelsService } = this.props;

    if (hotelItemFullInfo && this.unsubscribeFn) {
      hotelsService.removeStaticHotel(item.hotel.ClassificatorId);
      this.unsubscribeFn();
    }
  }

  updateState = async (state: { hotels: HotelStatic[], loading: boolean }) => {
    const hotel = state.hotels.find(({ ClassificatorId }) => String(ClassificatorId) === String(this.prepareHotelClassificatorId()));

    if (hotel && hotel?.Images) {
      this.setState({ hotel });
    }
  };

  handleFavoriteToggle = (action: string) => {
    const { favoritesService, hotelsService, notificationService } = this.props;
    const { hotel } = this.state;

    if (action === FAVORITESACTION.REMOVE) {
      return favoritesService.removeItem(hotel?.FavoriteId as string)
        .then(() => {
          notificationService.send({
            message: LABELS.FAVORITE_REMOVE(hotel?.Name as string),
          });

          hotelsService.removeFromFavoriteStaticHotel(hotel?.ClassificatorId as number);
          hotelsService.updateHotelFavorite(null);
        });
    }

    return hotelsService.addToFavorite(hotel)
      .then((favoriteId: string) => {
        notificationService.send({
          message: LABELS.FAVORITE_ADD(hotel?.Name as string),
        });

        const id = favoriteId.split('"').join('');

        hotelsService.addToFavoriteStaticHotel(hotel?.ClassificatorId as number, id);
        hotelsService.updateHotelFavorite(id);
      });
  };

  handleToggleHotelDetails = async (value: boolean) => {
    const images = [];

    this.setState({ loading: true });

    if (value && this.state.hotel) {
      const imagesUrl = this.state.hotel.Images;

      const urls = await this.filterImages(imagesUrl);

      images.push(...urls);
    }

    this.setState({ showDetails: value, images, loading: false });
  };

  renderLoading = () => (
    <div className={ styles.loading }>
      <DotLoading />
    </div>
  );

  getMealNameInfo = (mealName: string | null) => (mealName ? `${MEAL.HAS_MEAL_NAME}: ${mealName}` : MEAL.HAS_MEAL);

  getNoMealNameInfo = (mealName: string | null) => (mealName ? MEAL.NOT_HAS_MEAL_NAME : MEAL.NOT_HAS_MEAL);

  renderHotelDetails = () => {
    const {
      item,
      hotelItemFullInfo,
      fromTrip,
      soldOut,
      qaAttrDetails,
      qaAttrCancellation,
      qaAttrDetailsBreakfast,
    } = this.props;
    const { CancellationPenalties, CancellationInfo, HasCancellation } = item.room.Amenities;

    let mealName = null;
    let noMealName = null;

    const cancellationDate = CancellationInfo
      ? dateUtcFormat(CancellationInfo, PATTERN.DAY_OF_MONTH_TIME)
      : null;

    if (fromTrip) {
      const { raw: { Room: { Meal: { Name } } } } = item;

      mealName = this.getMealNameInfo(Name);
      noMealName = this.getNoMealNameInfo(Name);
    } else {
      const { Rate: { Amenities: { Meal: { Name } } } } = item;

      mealName = this.getMealNameInfo(Name);
      noMealName = this.getNoMealNameInfo(Name);
    }

    const hasMeal = fromTrip ? item.raw.Room.Meal.Include : item.Rate.Amenities.HasMeal;
    const mealTypeText = hasMeal ? mealName : noMealName;
    const countryCode = fromTrip ? item.raw.Hotel.CountryCode : item.hotel.CountryCode;
    const cancellationText = fromTrip ? '' : item.Rate.CancellationInfo;

    if (hotelItemFullInfo) {
      const roomName = item.room.Name ? (
        <Text type='NORMAL_14_130' className={ styles.text }>{LABELS.ROOM(item.room.Name.trim().toLowerCase())}</Text>
      ) : null;
      const internetHtml = item.room.Amenities.HasInternet && (
        <Text type='NORMAL_14_130' className={ styles.text }>{ LABELS.FREE_WIFI }</Text>
      );

      const detailsStyles = [styles.details];

      if (fromTrip) {
        detailsStyles.push(styles.not_hover);
      }

      return (
        <div className={ detailsStyles.join(' ') }>
          <div data-qa={ qaAttrDetails }>
            { roomName }
            { internetHtml }
            <Text
              qaAttr={ qaAttrDetailsBreakfast }
              type='NORMAL_14_130'
              className={ styles.text }
            >
              { mealTypeText }
            </Text>
          </div>
          <HotelCancellation
            className={ styles.text }
            fromTrip={ fromTrip }
            cancellationPenalties={ CancellationPenalties }
            // @ts-ignore
            cancellationDate={ cancellationDate }
            cancellationInfo={ CancellationInfo }
            cancellationText={ cancellationText }
            countryCode={ countryCode }
            hasCancellation={ HasCancellation }
            qaAttr={ qaAttrCancellation }
          />
          <Button
            type='textual'
            className={ styles.info }
            onClick={ () => this.handleToggleHotelDetails(true) }
          >
            { LABELS.INFO_BY_HOTEL }
          </Button>
        </div>
      );
    }

    const roomName = item.room.Name ? LABELS.ROOM(item.room.Name) : null;
    const cancellationByCountry = countryCode === COUNTRIES.RU.SHORTNAME ?
      LABELS.FREE_CANCELLATION(cancellationDate) : (
        <div className={ styles.cancellation }>
          { LABELS.FREE_CANCELLATION_FOREIGN(cancellationDate) }
          <Tooltip
            className={ styles.tooltip }
            renderContent={ () => (
              <Text type='NORMAL_14_130' className={ styles['tooltip-content'] } color='white'>
                { LABELS.ATTENTION_FREE_CANCELLATION }
              </Text>
            ) }
          >
            <Icon type='question' />
          </Tooltip>
        </div>
      );

    const cancellationHtml = CancellationInfo ? cancellationByCountry : (
      <div className={ styles.refund }>
        <RefundHotel countryCode={ countryCode } lowercaseText />
      </div>
    );

    return (
      <div className={ styles.details }>
        <Text type='NORMAL_14_130' className={ styles.text }>
          <b className={ soldOut && styles['sold-out'] }>{ roomName }</b>
          <span>{ ` ${mealTypeText.toLowerCase()}, ` }</span>
          <span>{ cancellationHtml }</span>
        </Text>
      </div>
    );
  };

  renderReservedDetalis = () => {
    const { item, canBeReserved, isReserved, reservedHotelSmartagent } = this.props;

    if (!canBeReserved || isReserved || !isSmartAgent || !reservedHotelSmartagent) {
      return null;
    }

    const { Rate: { Amenities: { CancellationInfo } } } = item;

    const info = getReduceNumberDays(CancellationInfo as string, 1);

    const reservedNames = isCurrentYear(info)
      ? dateFormat(info, PATTERN.TIME_DOT_FULL_NOT_YEAR)
      : dateUtcFormat(info, PATTERN.TIME_DOT_FULL_YEAR);

    return (
      <Text
        type='NORMAL_16'
        className={ styles.wrapper_reserved }
      >
        { LABELS.RESERVED(reservedNames) }
      </Text>
    );
  };

  renderHotelHeader = () => {
    const { aggregationId } = this.props;
    const { hotel } = this.state;

    return (
      <HotelNameBlock
        isDetails
        isSmart={ hotel?.IsSmartHotel }
        stars={ hotel?.Stars as number }
        name={ hotel?.Name as string }
        favoriteId={ hotel?.FavoriteId }
        aggregationId={ aggregationId }
        onFavoriteToggle={ this.handleFavoriteToggle }
        onClose={ () => this.handleToggleHotelDetails(false) }
      />
    );
  };

  filterImages = async (Images: { OrigUrl: string, Url: string }[]) => {
    const preloadedImages = await Promise.all(Images.map(preloadOrigUrlAndUrl));

    return preloadedImages.filter(Boolean);
  };

  renderHotelFullInfo = () => {
    const { hotelItemFullInfo } = this.props;
    const { hotel, showDetails } = this.state;
    const { images } = this.state;

    if (!hotelItemFullInfo || !hotel) {
      return null;
    }

    const imgsList = images.map(img => ({
      original: img?.OrigUrl,
      thumbnail: img?.Url,
    }));

    const { Amenities, Longitude, Latitude, CheckInTime, CheckOutTime } = hotel;

    const primaryInfoStyles = [styles['primary-info']];

    if (!images?.length) {
      primaryInfoStyles.push(styles['no-images']);
    }

    return (
      <Dialog
        className={ styles['full-info-wrapper'] }
        show={ hotel && showDetails }
        onChange={ this.handleToggleHotelDetails }
      >
        <ItemPanel renderHeader={ this.renderHotelHeader } favorite={ !!hotel.FavoriteId } className={ styles.panel }>
          <div className={ styles.content }>
            <div className={ styles.address }>
              <Text type='NORMAL_14'>{hotel.Address}</Text>
              <Text
                color='gray'
                type='NORMAL_14'
                className={ styles.distance }
              >
                { LABELS.CENTER_DISTANCE } { hotel.DistanceFromCenter } { LABELS.KM }
              </Text>
              <a
                href={ `https://yandex.ru/maps/?pt=${Longitude}, ${Latitude}&z=18` }
                target={ '_blank' }
                rel='noreferrer'
                className={ styles['show-on-map'] }
              >
                <Icon type='pinsMap' className={ styles.icon }/>
                <Text type='NORMAL_14'>{ LABELS.SHOW_ON_MAP }</Text>
              </a>
            </div>
            {!!images && !!images.length && (
              <div className={ styles.gallery }>
                <ImageGallery
                  items={ imgsList }
                  slideInterval={ 4000 }
                  autoPlay
                />
              </div>
            )}
            <div className={ primaryInfoStyles.join(' ') }>
              <div
                className={ styles.description }
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={ { __html: hotel.Description } }
              />
              <div className={ styles.header }>
                { this.renderHotelHeader() }
              </div>
              <HotelCheckinCheckoutPanel checkin={ CheckInTime } checkout={ CheckOutTime } className={ styles.time }/>
              { !!Amenities && !!Amenities.length && (<HotelServices hotel={ hotel } className={ styles.amenities }/>) }
            </div>
          </div>
        </ItemPanel>
      </Dialog>
    );
  };

  renderCollapsiblePropertyFees = () => {
    const { additionalData } = this.props;

    if (!additionalData || !additionalData.PropertyFees || !additionalData.PropertyFees.length) return null;

    const feesText = additionalData.PropertyFees.map((fee, idx) => (
      <Text key={ idx } type='NORMAL_14_130'>
        { fee }
      </Text>
    ));

    return (
      <Collapsible
        title={ <Text type='NORMAL_14' color='accent'>{ LABELS.REQUIRED_FEES }</Text> }
        wrapperClassname={ styles.collapsible }
        headerWrapperClassname={ styles.collapsible_header }
      >
        <div className={ styles.expedia_info }>
          <Text type='NORMAL_14_130'>{ LABELS.FEES_TITLE }</Text>
          { feesText }
        </div>
      </Collapsible>
    );
  };

  renderHotel = () => {
    const { item, fromTrip, qaAttrTitle, qaAttrDate } = this.props;
    const { hotel } = this.state;

    const preparedCheckin = (() => {
      const isCustom = fromTrip ? item.raw.CustomCheckInDate : item.Rate && item.Rate.CustomCheckInDate;
      const checkin = isCustom && !fromTrip ? item.Rate.CheckinDate : item.checkin;

      let checkInDate = formatDate(checkin, DATEFORMATS.DATE);

      if (isCustom) {
        const checkInTime = formatDate(checkin, DATEFORMATS.TIME);
        checkInDate += ` (${LABELS.EARLY_IN(checkInTime)})`;
      }

      return checkInDate;
    })();

    const preparedCheckout = (() => {
      const isCustom = fromTrip ? item.raw.CustomCheckOutDate : item.Rate && item.Rate.CustomCheckOutDate;
      const checkout = isCustom && !fromTrip ? item.Rate.CheckoutDate : item.checkout;

      let checkOutDate = formatDate(checkout, DATEFORMATS.DATE);

      if (isCustom) {
        const checkOutTime = formatDate(checkout, DATEFORMATS.TIME);
        checkOutDate += ` (${LABELS.LATE_OUT(checkOutTime)})`;
      }

      return checkOutDate;
    })();

    const dates = `${preparedCheckin} – ${preparedCheckout}`;

    const mapContent = hotel ? (
      <a
        href={ `https://yandex.ru/maps/?pt=${hotel.Longitude}, ${hotel.Latitude}&z=18` }
        target={ '_blank' }
        rel='noreferrer'
        className={ styles.address }
      >
        <Text color='accent' type='NORMAL_14'>{ item.hotel.City }, { item.hotel.Address }</Text>
      </a>
    ) : null;

    return (
      <div className={ styles.wrapper }>
        <div className={ styles.name }>
          <Text qaAttr={ qaAttrTitle } type='bold_18'>{ item.hotel.Name }</Text>
          <Stars count={ item.hotel.Stars } className={ styles.stars } />
        </div>
        { mapContent }
        <Text qaAttr={ qaAttrDate } className={ styles.dates } type='NORMAL_14' color='gray'>
          { dates }
        </Text>
        { this.renderReservedDetalis() }
        { this.renderHotelDetails() }
        { this.renderCollapsiblePropertyFees() }
        { this.renderHotelFullInfo() }
      </div>
    );
  };

  render() {
    const { hotel, loading } = this.state;
    const { hotelItemFullInfo } = this.props;
    const isShowHotel = hotel && !loading;

    if (hotelItemFullInfo && this.prepareHotelClassificatorId()) {
      return isShowHotel ? this.renderHotel() : this.renderLoading();
    }

    return this.renderHotel();
  }
}

export default HotelItem;
