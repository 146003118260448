import React, { FC, ReactNode } from 'react';
import { Dialog, IconButton } from 'new-ui';

import styles from './styles/index.module.css';

interface MapDialogProps {
  children: ReactNode,
  show: boolean,
  onChange(value: boolean): void,
  renderDependsOnShow?: boolean,
  qaAttr?: string,
  qaAttrClose?: string,
}

const MapDialog: FC<MapDialogProps> = ({
  show,
  children,
  onChange,
  qaAttr = '',
  qaAttrClose = '',
  renderDependsOnShow = false,
}) => {
  const content = renderDependsOnShow ? show && children : children;

  return (
    <Dialog
      qaAttr={ qaAttr }
      show={ show }
      onChange={ onChange }
      className={ styles.wrapper }
    >
      <IconButton
        qaAttr={ qaAttrClose }
        className={ styles.close }
        iconType='closeButtonRound'
        iconColor='accent'
        onClick={ () => onChange(false) }
      />
      { content }
    </Dialog>
  );
};

export { MapDialog };
