import { observable, action, computed, makeObservable } from 'mobx';

import type { ApprovalERSchemeFormStore, ApprovalERSchemesListStore, ApprovalERSchemeFAQStore } from './types';

import { checkApprovalSchemeArray } from '../../utils/approval';

import {
  DEFAULT_APPROVAL_SCHEME,
  StatusScheme,
} from '../../constants/approvalSchemes';

export interface IApprovalERSchemesStore {
  list: ApprovalERSchemesListStore,
  form: ApprovalERSchemeFormStore,
  faq: ApprovalERSchemeFAQStore,
  status: string,
}

export class ApprovalERSchemesStore {
  constructor() {
    makeObservable(this);
  }

  @observable list: ApprovalERSchemesListStore = {
    value: [],
    loading: false,
    error: false,
  };

  @observable form: ApprovalERSchemeFormStore = { ...DEFAULT_APPROVAL_SCHEME };
  @observable faq: ApprovalERSchemeFAQStore = { id: null };
  @observable status: StatusScheme = StatusScheme.CREATE;
  @observable schemeLoading = false;

  @computed
  get isFormValid(): boolean {
    const { Steps, Name } = this.form;

    return !!Name.trim().length && checkApprovalSchemeArray(Steps);
  }

  @action
  updateList = (value: Partial<ApprovalERSchemesListStore>) => {
    this.list = {
      ...this.list,
      ...value,
    };
  };

  @action
  setStatus = (value: StatusScheme) => {
    this.status = value;
  };

  @action
  setLoading = (value: boolean) => {
    this.schemeLoading = value;
  };

  @action
  updateForm = (value: Partial<ApprovalERSchemeFormStore>) => {
    this.form = {
      ...this.form,
      ...value,
    };
  };

  @action
  updateFaq = (value: ApprovalERSchemeFAQStore) => {
    this.faq = {
      ...this.faq,
      ...value,
    };
  };

  @action
  clearExceptList = () => {
    this.form = { ...DEFAULT_APPROVAL_SCHEME };
    this.faq = { id: null };
    this.status = StatusScheme.CREATE;
  };
}

export const approvalERSchemesStore = new ApprovalERSchemesStore();
