import React from 'react';
import { observer } from 'mobx-react';
import { Button, Dialog, Text } from 'new-ui';

import { useServices } from '../../../../../bi/context/services';

import { getEmployeeFullName } from '../../../../../bi/utils/employees';

import { LABELS } from '../../../consts';

import { LoadingStatus } from '../../../../../bi/services/utils/network/types';
import { LoadingFields } from '../../../../../bi/services/departments/types';

import styles from './styles.module.css';

interface IRemoveProps {
  isHead?: boolean
}

export const Remove = observer(({ isHead = false }: IRemoveProps) => {
  const {
    departmentsService: {
      removeEmployee,
      removeHead,
      networkStore,
      store: { closeDialog, dialogMeta },
    },
  } = useServices(['Departments']);

  const remove = () => (
    isHead ? removeHead() : removeEmployee(dialogMeta.Id)
  );

  const loading = networkStore.getIsStatus(LoadingFields.setOrRemoveEmployee, LoadingStatus.LOADING);

  const position = isHead
    ? LABELS.REMOVE.DESCRIPTION_HEAD
    : LABELS.REMOVE.DESCRIPTION_EMPLOYEE;

  return (
    <Dialog
      show
      onChange={ closeDialog }
      className={ styles.content }
    >
      <Text type='bold_22'>
        { LABELS.REMOVE.TITLE }
      </Text>
      <Text>
        { LABELS.REMOVE.DESCRIPTION(position, getEmployeeFullName(dialogMeta)) }
      </Text>
      <div className={ styles.bottom }>
        <Button onClick={ remove } type='secondary' loading={ loading }>
          { LABELS.REMOVE.CONFIRM }
        </Button>
        <Button onClick={ closeDialog } type='textual'>
          { LABELS.REMOVE.CANCEL }
        </Button>
      </div>
    </Dialog>
  );
});
