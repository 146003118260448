import React, { useEffect } from 'react';
import { Button, Dialog, Text, TextColor } from 'new-ui';

import { getText } from '../../../i18n';

import { formatRangeDateWithSimplicity, getDateDescriptorTime } from '../../bi/utils/formatDate';
import { MainAnalytic } from '../../bi/utils/analytics';
import MoneyFormat from '../../bi/utils/money';

import { TEventValue } from '../../bi/services/events/type';

import styles from './styles/index.module.css';

export interface IEventInfo {
  event: TEventValue,
  show: boolean,
  onChange(value: boolean): void,
}

const LABELS = {
  APPLICATIONS: getText('event:events.application'),
  OK: getText('event:events.ok'),
  FIELD: {
    CREATED: getText('event:events.fields.created'),
    NAME: getText('event:events.fields.name'),
    PLACE: getText('event:events.fields.place'),
    DATE: getText('event:events.fields.date'),
    BUDGET: getText('event:events.fields.budget'),
    ROUBLES: getText('event:events.fields.roubles'),
    MEMBERS: getText('event:events.fields.members'),
    STATUS: getText('event:events.fields.status'),
    COMMENT: getText('event:events.fields.comment'),
  },
  NO_FIELD: getText('event:events.fields.noField'),
};

const EventInfo = ({
  event: {
    CreatedDate,
    Name,
    Place,
    StartDate,
    EndDate,
    Budget,
    Member,
    Comment,
    User,
  },
  show,
  onChange,
}: IEventInfo) => {
  useEffect(() => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.OPEN_IN_TRIP);
  }, []);

  const renderField = (field: string, value: string | undefined | null, doubleValue?: string) => {
    const generalValue = value || LABELS.NO_FIELD;

    const isCreated = field === LABELS.FIELD.CREATED;

    const color: TextColor = isCreated ? 'gray' : 'default';
    const comma = isCreated ? ', ' : null;

    return (
      <div
        className={ styles.fields }
      >
        <div
          className={ styles.field }
        >
          <Text
            color='gray'
            type='NORMAL_16'
          >
            { field }
          </Text>
        </div>
        <div
          className={ styles.field }
        >
          <Text
            color={ color }
            type='NORMAL_16'
          >
            { generalValue }
            { comma }
            { doubleValue }
          </Text>
        </div>
      </div>
    );
  };

  const renderHeader = () => (
    <div
      className={ styles.header }
    >
      <Text
        type='bold_22'
      >
        { LABELS.APPLICATIONS }
      </Text>
    </div>
  );

  const renderOkButton = () => (
    <div
      className={ styles.button }
    >
      <Button
        onClick={ () => onChange(false) }
      >
        { LABELS.OK }
      </Button>
    </div>
  );

  return (
    <Dialog
      show={ show }
      showClosing
      onChange={ () => onChange(!show) }
    >
      <div
        className={ styles.wrapper }
      >
        { renderHeader() }
        { renderField(LABELS.FIELD.CREATED, User, getDateDescriptorTime(CreatedDate)) }
        { renderField(LABELS.FIELD.NAME, Name) }
        { renderField(LABELS.FIELD.PLACE, Place) }
        { renderField(LABELS.FIELD.DATE, formatRangeDateWithSimplicity(StartDate, EndDate)) }
        { renderField(LABELS.FIELD.BUDGET, MoneyFormat.moneyWithDecimalField(Budget, true)) }
        { renderField(LABELS.FIELD.MEMBERS, Member) }
        { renderField(LABELS.FIELD.COMMENT, Comment) }
        { renderOkButton() }
      </div>
    </Dialog>
  );
};

export { EventInfo };
