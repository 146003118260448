import React, { Component } from 'react';
import { Dialog, Input, Dropdown, Text, Button } from 'new-ui';

import { getText } from '../../../i18n';

import FORMATS from '../../bi/constants/formats';
import { DOTREGEXP, FLOATREGEXP, THREEDIGITS } from '../../bi/constants/regExp';

import styles from './styles/index.module.css';

const DELAY = 250;

const LABELS = {
  sumInvoice: getText('components:downloadDepositDialog.sumInvoice'),
  sum: getText('components:downloadDepositDialog.sum'),
  invoicePdf: getText('components:downloadDepositDialog.invoicePdf'),
  invoiceXlsx: getText('components:downloadDepositDialog.invoiceXlsx'),
  undo: getText('common:undo'),
};

interface DownloadDepositDialogProps {
  onDownloadDeposit(value: string, format: string): void;
  defaultValue: string;
  // autoSelect: boolean;
  onClose(): void;
  show: boolean;
}

class DownloadDepositDialog extends Component<DownloadDepositDialogProps, { value: string }> {
  static defaultProps = {
    defaultValue: '',
    autoSelect: false,
    onClose: () => {},
  };

  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: DownloadDepositDialogProps) {
    super(props);

    this.state = {
      value: props.defaultValue || '',
    };

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps: DownloadDepositDialogProps) {
    const unEqual = prevProps.show !== this.props.show;

    if (this.inputRef && unEqual) {
      setTimeout(() => {
        this.inputRef.current?.focus();
      }, DELAY);
    }
  }

  handleChangeInput = (value: string) => {
    const valueWithoutSpaces = value.split(' ').join('').toString();

    if (!(FLOATREGEXP.test(valueWithoutSpaces) || value === '')) return;

    const valueWithComma = valueWithoutSpaces.replace(DOTREGEXP, ',');
    const valueWithSpaces = valueWithComma.replace(THREEDIGITS, '$1 ');
    this.setState({ value: valueWithSpaces });
  };

  render() {
    const { onDownloadDeposit, onClose, show } = this.props;
    const { value } = this.state;

    return (
      <Dialog
        show={ show }
        showClosing
        onChange={ onClose }
      >
        <div className={ styles.wrapper }>
          <Text type='bold_20' className={ styles.title }>
            { LABELS.sumInvoice }
          </Text>
          <Input
            value={ value }
            placeholder={ LABELS.sum }
            onChange={ this.handleChangeInput }
            // selectOnFocus={ autoSelect }
            ref={ this.inputRef }
          />
          <div className={ styles.download }>
            <Dropdown
              onClick={ () => onDownloadDeposit(value, FORMATS.PDF) }
              theme='primary'
              renderLabel={ () => (
                <Text
                  className={ styles.dropdown_title }
                  type='NORMAL_14'
                >
                  { LABELS.invoicePdf }
                </Text>
              ) }
            >
              <div className={ styles.content }>
                <Button
                  type='textual'
                  onClick={ () => onDownloadDeposit(value, FORMATS.XLSX) }
                  className={ styles.btn }
                >
                  { LABELS.invoiceXlsx }
                </Button>
              </div>
            </Dropdown>
            <Button
              className={ styles.cancel }
              type='textual'
              onClick={ onClose }
            >
              { LABELS.undo }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export { DownloadDepositDialog };
