import React, { useState, FC } from 'react';
import { IconButton, Text, Button, Dialog } from 'new-ui';

import { getText } from '../../../i18n';

import FAVORITESACTION from '../../bi/constants/favorites';

import styles from './styles/index.module.css';

const LABELS = {
  MAIN: {
    FAVORITED: getText('components:favoriteAction.favorited'),
    TO_FAVORITE: getText('components:favoriteAction.toFavorite'),
  },
  DIALOG: {
    HEADER: getText('components:favoriteAction.dialog.title'),
    APPLY: getText('components:favoriteAction.dialog.apply'),
    CANCEL: getText('common:cancel'),
  },
};

interface FavoriteActionProps {
  onClick(item: string): void,
  id?: string | boolean | null,
  disabled?: boolean,
  className?: string,
  qaAttr?: string,
  qaAttrIcon?: string,
  qaAttrDialog?: {
    wrapper: string,
    close: string,
    buttons: {
      confirm: string,
      close: string,
    },
  },
}

const FavoriteAction: FC<FavoriteActionProps> = ({
  onClick,
  disabled = false,
  className = '',
  id = '',
  qaAttr = '',
  qaAttrIcon = '',
  qaAttrDialog = {
    wrapper: '',
    close: '',
    buttons: {
      confirm: '',
      close: '',
    },
  },
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleAddToFavorite = () => onClick(FAVORITESACTION.ADD);

  const handleAcceptRemoveFavorite = () => {
    onClick(FAVORITESACTION.REMOVE);
    setShowDialog(false);
  };

  const handleClick = id
    ? () => setShowDialog(true)
    : handleAddToFavorite;

  const label = id
    ? LABELS.MAIN.FAVORITED
    : LABELS.MAIN.TO_FAVORITE;

  const iconColor = id ? 'yellow' : 'blue';
  const content = (
    <IconButton
      className={ styles.label }
      disabled={ disabled }
      iconType='menuFavorite'
      iconColor={ iconColor }
      onClick={ handleClick }
      qaAttr={ qaAttrIcon }
    >
      <Text
        qaAttr={ qaAttr }
        type='NORMAL_14'
      >
        { label }
      </Text>
    </IconButton>
  );

  const dialog = (
    <Dialog
      showClosing
      className={ styles.dialog }
      show={ showDialog }
      onChange={ () => setShowDialog(false) }
      qaAttrWrapper={ qaAttrDialog.wrapper }
      qaAttr={ qaAttrDialog.close }
    >
      <Text type='bold_20_130' >
        { LABELS.DIALOG.HEADER }
      </Text>
      <div className={ styles.actions }>
        <Button
          className={ styles.submit }
          type='primary'
          onClick={ handleAcceptRemoveFavorite }
          qaAttr={ qaAttrDialog.buttons.confirm }
        >
          { LABELS.DIALOG.APPLY }
        </Button>
        <Button
          className={ styles.cancel }
          type='textual-medium'
          onClick={ () => setShowDialog(false) }
          qaAttr={ qaAttrDialog.buttons.close }
        >
          { LABELS.DIALOG.CANCEL }
        </Button>
      </div>
    </Dialog>
  );

  return (
    <div className={ className }>
      { content }
      { dialog }
    </div>
  );
};

export { FavoriteAction };
