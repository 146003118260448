import { Moment } from 'moment';
import { BORDER_COLOR_TYPE } from '../constants/chartsAnalytics';

import {
  getMoment,
  isAfterDate,
  isBeforeDate,
  getCurrentYear,
  getCurrentMonth,
  getBeginOfTheYear,
  getYear,
  getEndOfYear,
  getStartOfYear,
  formatDate,
} from './formatDate';
import { PATTERN } from '../constants/dateFormats';

// Если заходим в раздел с февраля по декабрь, значения по умолчанию: с первого января текущего года до сегодняшнего дня
// Если заходим в январе, диапазон по умолчанию — весь прошлый год. Для кабинетов, открытых в этом же январе, — с открытия до сегодняшнего дня
// Дату начала нельзя выбрать раньше даты открытия кабинета. Всегда если дата начала по умолчанию оказывается раньше даты открытия кабинета, меняем ее на дату открытия кабинета

const chartsAnalyticsDatesMaker = (createDate: any) => {
  const currentMonth = getCurrentMonth();
  let startDate;
  let endDate;

  if (!createDate) {
    const currentYear = getCurrentYear();

    return { startDate: getBeginOfTheYear(currentYear - 1), endDate: getMoment() };
  }

  if (currentMonth === 0) {
    const currentYear = getCurrentYear();
    const preparedStartDate = getBeginOfTheYear(currentYear - 1);
    const isAfterD = isAfterDate(createDate, preparedStartDate);
    const selectEndDate = isAfterD && getYear(createDate) === preparedStartDate ?
      getEndOfYear(preparedStartDate) : getMoment();

    startDate = isAfterD ? createDate : preparedStartDate;
    endDate = selectEndDate;
  } else {
    const currentYear = getCurrentYear();
    const preparedStartDate = getStartOfYear(currentYear);

    startDate = isBeforeDate(createDate, preparedStartDate) ? preparedStartDate : createDate;
    endDate = getMoment();
  }

  return {
    startDate, endDate,
  };
};

const preparedAutocompleted = (selected: any[], list: any[], commonList: any[]): any[] => {
  if (selected.length) {
    selected.forEach(id => {
      if (!list.find(({ value }) => value === id)) {
        const res = commonList.find(({ value }) => value === id);

        if (res) {
          list.push(res);
        }
      }
    });
  }

  return list;
};

const getSmartIndexChartTitle = (startDate: Moment | any, endDate: Moment): string => {
  const preparedCheckin = formatDate(
    startDate,
    PATTERN.DAY_OF_MONTH_LONG,
  );

  const preparedCheckout = formatDate(
    endDate,
    PATTERN.DAY_OF_MONTH_LONG,
  );

  return `${preparedCheckin} – ${preparedCheckout}`;
};

const preparedSmartIndexColor = (level: number) => {
  if (level > 7) {
    return BORDER_COLOR_TYPE.GREEN;
  }

  if (level > 4) {
    return BORDER_COLOR_TYPE.YELLOW;
  }

  if (level > 0) {
    return BORDER_COLOR_TYPE.RED;
  }

  return BORDER_COLOR_TYPE.GRAY;
};

export {
  chartsAnalyticsDatesMaker,
  preparedAutocompleted,
  getSmartIndexChartTitle,
  preparedSmartIndexColor,
};
