import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { IconButton, Suggest } from 'new-ui';
import { getText } from '../../../../../../i18n';

import { useStores } from '../../../../../bi/context';
import { MOBX_STORES } from '../../../../../bi/context/stores';

import { SearchSubMenu } from '../../../SearchSubMenu';

import { isSmartAgent } from '../../../../../bi/utils/env';

import { SERVICETYPE } from '../../../../../bi/constants/serviceType';
import { TRANSFER_SEARCH_FIELDS } from '../../../../../bi/constants/transfers';

import { TransferSubMenuProps, SuggestItems } from '../../../../../bi/types/transfer';

import styles from './styles.module.css';

const LABELS = {
  SEARCH: getText('components:menu.transfer.search'),
  FROM: getText('components:menu.transfer.from'),
  TO: getText('components:menu.transfer.to'),
};

const TransferSubMenu: FC<TransferSubMenuProps> = observer(({
  onSearch,
  swapPlaces,
  updateSuggestions,
  selectedSuggest,
  renderSuggestion,
  transferService,
}) => {
  const {
    transferSearchStore: {
      transferFrom,
      transferTo,
    },
  } = useStores([MOBX_STORES.TRANSFER_SEARCH_STORE]);

  useEffect(() => () => {
    if (!document.location.pathname.split('/')[3]) {
      transferService.resetStore();
    }
  }, []);

  const suggestValue = {
    from: transferFrom.value || '',
    to: transferTo.value || '',
  };

  const isActive = !!(transferFrom.selected && transferTo.selected);

  return (
    <SearchSubMenu
      onClick={ onSearch }
      serviceType={ SERVICETYPE.TRANSFER }
      disabled={ !isActive }
      buttonLabel={ LABELS.SEARCH }
    >
      <div className={ styles.submenu }>
        <div className={ `${styles.item} ${styles.suggests}` }>
          <div className={ styles.suggest_wrap }>
            <div className={ styles.suggest_field }>
              <Suggest
                className={ styles.suggest }
                theme='dark'
                placeholder={ LABELS.FROM }
                value={ suggestValue.from }
                items={ transferFrom.suggestions }
                onSelect={ (suggest: any) => selectedSuggest(TRANSFER_SEARCH_FIELDS.TRANSFER_FROM, suggest) }
                onChange={ query => updateSuggestions(TRANSFER_SEARCH_FIELDS.TRANSFER_FROM, query) }
                renderItem={ renderSuggestion }
                keyExtractor={ ({ Id }: SuggestItems) => Id }
              />
            </div>
          </div>
          <IconButton iconType='arrowsDoubleBlue' onClick={ swapPlaces } alternativeDesign={ isSmartAgent }/>
          <div className={ styles.suggest_wrap }>
            <div className={ styles.suggest_field }>
              <Suggest
                className={ styles.suggest }
                theme='dark'
                placeholder={ LABELS.TO }
                value={ suggestValue.to }
                items={ transferTo.suggestions }
                onSelect={ (suggest: any) => selectedSuggest(TRANSFER_SEARCH_FIELDS.TRANSFER_TO, suggest) }
                onChange={ query => updateSuggestions(TRANSFER_SEARCH_FIELDS.TRANSFER_TO, query) }
                renderItem={ renderSuggestion }
                keyExtractor={ ({ Id }: SuggestItems) => Id }
              />
            </div>
          </div>
        </div>
      </div>
    </SearchSubMenu>
  );
});

export { TransferSubMenu };
