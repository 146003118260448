import React from 'react';

import styles from './index.module.css';

const TARGETS = {
  BLANK: '_blank',
  SELF: '_self',
};

interface ExternalLinkProps {
  link: string,
  text: string,
  target?: string,
  className?: string,
  download?: string,
}

const ExternalLink = ({
  link,
  text,
  target = TARGETS.BLANK,
  className = '',
  download,
}: ExternalLinkProps) => {
  const linkClassNames = [styles.link];

  if (className) {
    linkClassNames.push(className);
  }

  return (
    <a className={ linkClassNames.join(' ') } target={ target } rel='noopener noreferrer' href={ link } download={ download }>
      { text }
    </a>
  );
};

export { ExternalLink, TARGETS };
