type SimpleAny = object | void | null | string | number;

type MethodWrapper<Return extends SimpleAny> = (originalMethod: () => Return) => Promise<Return> | Return;

export const withMethod = <Fn extends MethodWrapper<any>>(wrap: Fn) =>
  (_target: any, _propertyKey: string, descriptor: PropertyDescriptor) => {
    const method = descriptor.value;

    // eslint-disable-next-line no-param-reassign
    descriptor.value = function flowExecution(...args: any[]) {
      return wrap(() => method.apply(this, args));
    };
  };

export const withInternalMethod = <This extends object, Fn extends (obj: This) => MethodWrapper<any>>(wrap: Fn) =>
  (_target: This, _propertyKey: keyof This, descriptor: PropertyDescriptor) => {
    const method = descriptor.value;

    // eslint-disable-next-line no-param-reassign
    descriptor.value = function flowExecution(...args: any[]) {
      return wrap(this as This)(() => method.apply(this, args));
    };
  };

export const withIM = withInternalMethod;

export const bound = <This>(
  _target: This, propertyKey: string, descriptor: TypedPropertyDescriptor<any>,
): TypedPropertyDescriptor<any> | void => {
  if (!descriptor || (typeof descriptor.value !== 'function')) {
    throw new TypeError(`Only methods can be decorated with @bind. <${propertyKey}> is not a method!`);
  }

  return {
    configurable: true,
    get(this: any): any {
      const binder: any = descriptor.value!.bind(this);
      Object.defineProperty(this, propertyKey, {
        value: binder,
        configurable: true,
        writable: true,
      });

      return binder;
    },
  };
};
