import { SmartwayCommonEnumsSortField, SmartwayCommonEnumsSortType } from '@sw/api';
import { yup, yupEnum } from '../../../../utils/yup';

export enum EnhancedSignatureModalTypes {
  docToEmailInput,
  docToEmailSent,
  docToGovSent,
  docPreview,
}

export const GetDocumentsQuerySchema = yup.object({
  employeeId: yup.number().required().default(-1),
  page: yup.number().default(1),
  limit: yup.number().default(6),
  sortField: yupEnum(SmartwayCommonEnumsSortField).default(SmartwayCommonEnumsSortField.Status),
  sortType: yupEnum(SmartwayCommonEnumsSortType).default(SmartwayCommonEnumsSortType.Desc),
});

export type GetDocumentsQueryType = yup.InferType<typeof GetDocumentsQuerySchema>;
