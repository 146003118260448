// TODO: протипизировать ymaps при переходе на v3
// https://yandex.ru/dev/jsapi30/doc/ru/dg/concepts/typescript

import markerSolid from '../images/map-point-solid.png';
import markerGreen from '../images/map-point-solid-green.png';

// Нигде не используется
const clusterContentLayout = (ymaps: any) => {
  const myClusterContentLayout = ymaps.templateLayoutFactory.createClass(
    `<div class="ymap-cluster-wrapper">
       <div class="ymap-cluster-count-wrapper">
         <div class="ymap-cluster-count-icon-container"></div>
         {{ properties.geoObjects.length }}
       </div>
  </div>`,
    {
      build() {
        myClusterContentLayout.superclass.build.call(this);

        let minPrice: string = '';

        this.getData().properties.get('geoObjects').forEach((item: any, index: number) => {
          const price = item.properties.get('price');

          if (index === 0 || parseInt(price.replace(/\s/g, ''), 10) < parseInt(minPrice.replace(/\s/g, ''), 10)) {
            minPrice = price;
          }
        });

        if (minPrice) {
          const clusterWrapper = this._parentElement.querySelector('.ymap-cluster-wrapper');

          const clusterPriceHtml = `<div class="ymap-cluster-price">
            <span>от ${minPrice}</span>
          </div>`;
          const child = document.createElement('div');
          child.innerHTML = clusterPriceHtml;
          child.classList.add('ymap-cluster-price-wrapper');

          clusterWrapper.appendChild(child);
        }
      },
      clear() {
        myClusterContentLayout.superclass.clear.call(this);
      },
    },
  );

  return myClusterContentLayout;
};

const clusterMinContentLayout = (ymaps: any, isSmartAgent: boolean) => {
  const clusterIcon = isSmartAgent ? markerGreen : markerSolid; // сейчас такая реализация работает.
  const myClusterContentLayout = ymaps.templateLayoutFactory.createClass(
    `<div class="ymap-cluster-animation" style="background-image: url('${clusterIcon}'); background-size: contain; background-repeat: no-repeat;">
       {{ properties.geoObjects.length }}
     </div>`,
    {
      build() {
        myClusterContentLayout.superclass.build.call(this);

        this.wrapper = this.getParentElement().getElementsByClassName('ymap-cluster-animation')[0];

        this.getData().geoObject.events.add(['mousemove', 'mouseleave'], (e: any) => {
          const typeEvent = e.get('type');

          if (typeEvent === 'mousemove') {
            this.wrapper.classList.add('active');
          } else {
            this.wrapper.classList.remove('active');
          }
        });
      },
      clear() {
        myClusterContentLayout.superclass.clear.call(this);
      },
    },
  );

  return myClusterContentLayout;
};

export { clusterContentLayout, clusterMinContentLayout };
