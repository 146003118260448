const deg2rad = (deg: number): number => deg * (Math.PI / 180);

const getDistanceFromLatLonInKm = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const radiusOfPlanet = 6371;
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = radiusOfPlanet * c;

  return distance;
};

const getDistanceToCenter = (regionLocation: number[], point: number[]): number => {
  if (!regionLocation?.length || !point?.length) return 0;

  const [regionLatitude, regionLongitude] = regionLocation;
  const [pointLatitude, pointLongitude] = point;

  return Math.round(parseFloat(getDistanceFromLatLonInKm(regionLatitude, regionLongitude, pointLatitude, pointLongitude).toString()) * 100) / 100;
};

const getDistanceToArea = (center: number[], point: number[], selectionRadius: number): number => {
  const distanceToCenter = getDistanceToCenter(center, point);

  return distanceToCenter + selectionRadius;
};

const distanceToAreaMoreRegionRadius = (distance: number, radius: number): boolean => distance > radius;

export {
  deg2rad,
  getDistanceFromLatLonInKm,
  getDistanceToCenter,
  getDistanceToArea,
  distanceToAreaMoreRegionRadius,
};
