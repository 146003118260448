import { TitleLength } from './types';

export const TITLE_TRIP_SA_LENGTH : TitleLength = {
  0: 50,
  1: 46,
  2: 42,
  3: 38,
  4: 34,
  5: 30,
  6: 30,
  7: 30,
};

export const COUNT_EMPLOYEE_TRIP_SA = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
};

export const COUNT_TRIP = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
};
