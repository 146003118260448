import React from 'react';
import { Text } from 'new-ui';

import styles from './index.module.css';

interface IFieldLabelProps {
  text: React.ReactNode
  mustHave?: boolean
}

export const FieldLabel = ({ text, mustHave = false }: IFieldLabelProps) => (
  <Text className={ styles.label }>
    { text }
    { mustHave && <span className={ styles.must }>*</span> }
  </Text>
);
