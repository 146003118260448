import React from 'react';

import { Input } from 'new-ui';

import MoneyFormat from '../../bi/utils/money';

type NumberInputProps = {
  value: string,
  className?: string,
  onChange: (value: number, tvalue?: number) => void,
  onRender?: (value: HTMLInputElement | null) => void,
  error?: string,
  disabled?: boolean,
  onFocus?: (e: any) => void,
  placeholder?: string,
  withDecimal?: boolean,
  qaAttr?: string,
};

const prepareNewValue = (newValue: string, oldValue?: string) =>
  Number(new RegExp(/^\d*$/).test(newValue) ? newValue : oldValue);

const NumberInput = ({
  value,
  className = '',
  onChange,
  onRender,
  error,
  placeholder = '',
  disabled,
  onFocus,
  withDecimal,
  qaAttr = '',
}: NumberInputProps) => {
  const preparedValue = value && !withDecimal ? MoneyFormat.money(prepareNewValue(value)) : '';
  const preparedValueWithDecimal = value || '';

  const classNames = [];

  if (className) {
    classNames.push(className);
  }

  const onChangeValue = (val: string) => {
    if (withDecimal) {
      onChange(parseFloat(val.replace(',', '.').replace(/\s/g, '')));

      return;
    }

    onChange(prepareNewValue(val.replace(/\s/g, ''), value));
  };

  return (
    <Input
      qaAttr={ qaAttr }
      debounceMs={ 0 }
      className={ classNames.join(' ') }
      value={ withDecimal ? preparedValueWithDecimal : preparedValue }
      error={ error }
      disabled={ disabled }
      placeholder={ placeholder }
      onChange={ onChangeValue }
      onFocus={ (e) => {
        const tmp = e.target.value;
        e.target.value = '';
        e.target.value = tmp;
        e.target.select();

        if (onFocus) {
          onFocus(e);
        }
      } }
      ref={ (input) => {
        if (onRender) {
          onRender(input);
        }
      } }
    />
  );
};

export { NumberInput };
