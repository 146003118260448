import { Placemarks } from '../services/hotels/types';
import { CONSTANTS } from '../constants/yandexMap';

const throttleQueue = (func: (newPlacemarks: Placemarks[]) => void, waitTime: number) => {
  let isWaiting = false;
  const queue: Placemarks[][] = [];

  const executeFunc = (params: Placemarks[]) => {
    isWaiting = true;

    func(params);

    setTimeout(() => {
      isWaiting = false;

      if (queue.length > 0) {
        // @ts-ignored
        const execParams: Placemarks[] = queue.shift();
        executeFunc(execParams);
      }
    }, waitTime);
  };

  return (params: Placemarks[]) => {
    if (isWaiting) {
      queue.push(params);
    } else {
      executeFunc(params);
    }
  };
};

// Первые 30 отелей на выдаче
const getFirstThirtyHotels = (geoObjectsToAdd: any[], itemsMapIds: number[] = []) => {
  let newGeoObjectsToAdd = [];

  const firstItems = geoObjectsToAdd
    .filter(geoObjects => itemsMapIds
      .some(itemsMapId => itemsMapId === geoObjects.properties.get('hotelId')));

  if (firstItems.length <= CONSTANTS.PLACEMARKS_MAX_COUNT_ON_MAP) {
    newGeoObjectsToAdd = firstItems;
  }

  if (firstItems.length > CONSTANTS.PLACEMARKS_MAX_COUNT_ON_MAP) {
    newGeoObjectsToAdd = firstItems.slice(0, CONSTANTS.PLACEMARKS_MAX_COUNT_ON_MAP);
  } else {
    const difference = CONSTANTS.PLACEMARKS_MAX_COUNT_ON_MAP - firstItems.length;

    const secondItems = geoObjectsToAdd
      .filter(geoObjects => itemsMapIds
        .some(itemsMapId => itemsMapId !== geoObjects.properties.get('hotelId')));

    if (secondItems.length <= difference) {
      newGeoObjectsToAdd = [...newGeoObjectsToAdd, ...secondItems];
    }

    if (secondItems.length > difference) {
      newGeoObjectsToAdd = [...newGeoObjectsToAdd, ...secondItems.slice(0, difference)];
    }
  }

  return newGeoObjectsToAdd;
};

export { throttleQueue, getFirstThirtyHotels };
