import { observable, action, makeObservable } from 'mobx';

import { DEFAULT_VALIDATE_APPROVE_REQUEST, APPROVE_STATUS } from '../../../constants/travelApproval';
import { ApproveStatus, RequestCartValidateSchema, RequestCartValidateType, TravelApprovalType } from '../consts';

type TravelApprovalGroupByStatus = Record<'approvedList' | 'awaitingApprovalList', TravelApprovalType[]>;

export class TravelApprovalsStore {
  constructor() {
    makeObservable(this);
  }

  @observable sources: TravelApprovalType[] = [];
  @observable approvedList: TravelApprovalType[] = [];
  @observable awaitingApprovalList: TravelApprovalType[] = [];
  @observable loading = true;
  @observable chosenApprovedRequest: TravelApprovalType | null = null;
  @observable validateApprovedRequest: RequestCartValidateType = RequestCartValidateSchema.getDefault();
  @observable validateLoadingEmployee = false;
  @observable validateLoading = false;
  @observable requestExists = false;

  @action
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  setValidateLoading = (value: boolean) => {
    this.validateLoading = value;
  };

  @action
  setValidateLoadingEmployee = (value: boolean) => {
    this.validateLoadingEmployee = value;
  };

  @action
  setSources = (list: TravelApprovalType[]) => {
    this.sources = list.filter(({ Status }) => Status !== APPROVE_STATUS.DECLINED);

    const { approvedList, awaitingApprovalList } =
      this.sources.reduce<TravelApprovalGroupByStatus>(
        (acc, item) => (
          item.Status !== ApproveStatus.waitingApprove ? {
            approvedList: [...acc.approvedList, item],
            awaitingApprovalList: [...acc.awaitingApprovalList],
          } : {
            approvedList: [...acc.approvedList],
            awaitingApprovalList: [...acc.awaitingApprovalList, item],
          }
        ),
        { approvedList: [], awaitingApprovalList: [] },
      );

    this.approvedList = approvedList;
    this.awaitingApprovalList = awaitingApprovalList;
    this.chosenApprovedRequest = approvedList.length === 1 ? approvedList[0] : null;
  };

  @action
  addTravelApproval = (item: TravelApprovalType) => { // возможно не надо
    this.sources.push(item);
    this.awaitingApprovalList.push(item);
  };

  @action
  setValidateApprovedRequest = (res: RequestCartValidateType) => {
    this.validateApprovedRequest = res;
  };

  @action
  setChosenApprovedRequest = (reqId: number | null) => {
    this.chosenApprovedRequest = this.approvedList.find(({ Id }) => Id === reqId) || null;
  };

  @action
  resetStore = () => {
    this.sources = [];
    this.approvedList = [];
    this.awaitingApprovalList = [];
    this.loading = true;
  };

  @action
  setResetForCart = () => {
    this.chosenApprovedRequest = null;
    this.validateApprovedRequest = { ...DEFAULT_VALIDATE_APPROVE_REQUEST };
    this.validateLoading = false;
    this.validateLoadingEmployee = false;
  };

  @action
  setRequestExists = (value: boolean) => {
    this.requestExists = value;
  };
}

export const travelApprovalsStore = new TravelApprovalsStore();
