import React, { useRef } from 'react';

import { SearchMenuDate } from './components/SearchMenuDate';
import { SearchMenuDirection } from './components/SearchMenuDirection';

import { AirlineService } from '../../bi/types/airline';

import { PATH } from '../../bi/constants/changeAirTrip';

import { RouteSearchInterface } from '../../bi/utils/changeAirTrip/types';

interface ChangeAirTripSearchMenuProps {
  airlineService: AirlineService;
  history: object;
  path: string;
  tripItem?: object | null;
  routesInfo: Array<any>;
  routesSearch: RouteSearchInterface[];
  useRoutesInfo: boolean;
  onSearch: () => void;
  ticketData: any;
}

const ChangeAirTripSearchMenu = (props: ChangeAirTripSearchMenuProps) => {
  const isSearchDateRef = useRef(props.path === PATH.DATE || props.path === PATH.DATE_RESULT);

  const renderSearchMenu = () => (isSearchDateRef.current ? (
    <SearchMenuDate { ...props }/>
  ) : (
    <SearchMenuDirection { ...props } />
  ));

  return renderSearchMenu();
};

ChangeAirTripSearchMenu.defaultProps = {
  routesInfo: [],
  routesSearch: [],
  tripItem: null,
  useRoutesInfo: false,
};

export { ChangeAirTripSearchMenu };
