const ToDoChangeEvents = {
  ADD: 'add',
  EDIT: 'edit',
  CHANGE: 'change',
  DELETE: 'delete',
  DELETEALL: 'deleteAll',
  MODALOPENED: 'modalOpened',
};

export default ToDoChangeEvents;
