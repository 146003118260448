import React from 'react';
import { Text } from 'new-ui';

import { Moment } from 'moment';

import { formatDate } from '../../bi/utils/formatDate';

import styles from './styles/index.module.css';

export interface ICustomItem {
  item: {
    Name: string,
    CheckoutDate: Moment,
    CheckinDate: Moment,
  },
}

const CustomItem = ({
  item: {
    Name,
    CheckinDate,
    CheckoutDate,
  },
}: ICustomItem) => {
  const dates = `${formatDate(CheckinDate)} - ${formatDate(CheckoutDate)}`;

  return (
    <div className={ styles.wrapper }>
      <Text
        type='bold_18'
        className={ styles.title }
      >
        { Name }
      </Text>
      <Text
        type='NORMAL_14'
        color='gray'
      >
        { dates }
      </Text>
    </div>
  );
};

export { CustomItem };
