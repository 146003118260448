import * as React from 'react';
import { Text } from 'new-ui';

import { repeatTextFragment } from '../../bi/utils/text';

import styles from './styles/index.module.css';

interface HighlitedItemProps {
  highlitedWord?: string,
  text: string,
}

const HighlitedItem = ({
  highlitedWord = '',
  text,
}: HighlitedItemProps) => {
  let highlightedLine = '';

  if (highlitedWord) {
    const repeatValue = repeatTextFragment(text, highlitedWord);
    const { first, medium, last } = repeatValue;

    highlightedLine = `${first}<mark>${medium}</mark>${last}`;
  }

  const сontent = !highlitedWord && (
    <Text>
      { text }
    </Text>
  );

  return (
    <div className={ styles.wrapper }>
      {/* eslint-disable-next-line react/no-danger */}
      <div className={ styles.text } dangerouslySetInnerHTML={ { __html: highlightedLine } } />
      { сontent }
    </div>
  );
};

export default HighlitedItem;
