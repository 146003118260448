import React, { FC } from 'react';
import { Dialog, Text, Button } from 'new-ui';

import { firstSymbolUpperCase } from '../../bi/utils/text';

import { getText } from '../../../i18n';

import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import styles from './styles/index.module.css';

const LABELS = {
  ADD_TO_CART: getText('common:addToCart'),
  CANCEL: getText('common:undo'),
};

interface TravelPolicyDialogProps {
  show: boolean,
  errors: object,
  label?: string,
  onClose(): void,
  onAdd(e: React.MouseEvent): void,
}

const TravelPolicyDialog: FC<TravelPolicyDialogProps> = ({
  show = false,
  errors,
  label = LABELS.ADD_TO_CART,
  onClose,
  onAdd,
}) => {
  const arrayErrors = Object.keys(errors);

  const errorsHtml = arrayErrors.map((error, ind) => {
    const errorHtml = errors[error as keyof typeof errors];

    return (
      <Text key={ `error_${ind}` }>
        { firstSymbolUpperCase(errorHtml) }
      </Text>
    );
  });

  return (
    <Dialog show={ show } onChange={ onClose } qaAttrWrapper={ QA_ATTRIBUTES.settings.travelPolicy.warning.dialog }>
      <div className={ styles.wrapper }>
        { errorsHtml }
        <div className={ styles.buttons }>
          <Button type='primary' onClick={ onAdd } qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.warning.addButton }>
            { label }
          </Button>
          <Button
            className={ styles.cancel }
            type='textual-medium'
            onClick={ onClose }
          >
            <Text type='NORMAL_14' color='accent'>
              { LABELS.CANCEL }
            </Text>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export { TravelPolicyDialog };
