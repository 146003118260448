import { Component, ReactNode } from 'react';
import { withRouter } from 'react-router-dom';

import { ILocation } from '../../bi/types/transfer';

export interface ILocationInfo {
  hash: string;
  key: string;
  pathname: string;
  search: string;
  state: { noReloadingScroll: boolean },
}

export interface IHistoryInfo {
  action: string;
  block: (prompt: string) => void;
  createHref: (location: ILocationInfo) => string;
  go: (number: number) => void;
  goBack: () => void;
  goForward: () => void;
  length: number;
  listen: (listener: string) => () => void;
  location: ILocationInfo;
  push: (path: string) => void;
  replace: (path: string) => void;
}

interface ScrollToTopProps {
  children: ReactNode,
  location: ILocation,
  history: IHistoryInfo,
}

class ScrollToTop extends Component<ScrollToTopProps> {
  componentDidUpdate(prevProps: ScrollToTopProps) {
    const { location, history } = this.props;

    if (location !== prevProps.location && !(history.location.state && history.location.state.noReloadingScroll)) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop as any);
