import React from 'react';
import { Datepicker, Text } from 'new-ui';
import { Moment } from 'moment';

import { isSameDate, momentObject } from '../../bi/utils/formatDate';

import styles from './styles/index.module.css';

interface RangeDatePickerProps {
  startDate: string | Moment | null,
  endDate: string | Moment | null,
  maxDate: Moment | null,
  onChangeStartDate: (value: string | Moment | null) => void,
  onChangeEndDate: (value: string | Moment | null) => void,
}

const LABELS = {
  FROM: 'С',
  TO: 'по',
};

const RangeDatePicker = ({
  startDate,
  endDate,
  maxDate,
  onChangeStartDate,
  onChangeEndDate,
}: RangeDatePickerProps) => {
  const minDate = momentObject(startDate);

  const handleChangeStartDate = (value: string | Moment | null) => {
    if (!isSameDate(startDate, value)) {
      onChangeStartDate(value);
    }
  };

  const handleChangeEndDate = (value: string | Moment | null) => {
    if (!isSameDate(endDate, value)) {
      onChangeEndDate(value);
    }
  };

  return (
    <div className={ styles.wrap }>
      <Text color='gray'>{ LABELS.FROM }</Text>
      <Datepicker
        placeholder={ LABELS.FROM }
        wrapperClassName={ styles.date }
        value={ startDate }
        onChange={ (value) => handleChangeStartDate(value) }
        max={ maxDate }
      />
      <Text color='gray'>{ LABELS.TO }</Text>
      <Datepicker
        placeholder={ LABELS.TO }
        wrapperClassName={ styles.date }
        value={ endDate }
        onChange={ (value) => handleChangeEndDate(value) }
        min={ minDate }
        max={ maxDate }
      />
    </div>
  );
};

export default RangeDatePicker;
