import React, { forwardRef } from 'react';

import styles from '../index.module.css';

interface IntroVideoProps {
  path: string;
}

const DESCRIPTIONS = 'Your browser does not support the video tag.';

const IntroVideo = forwardRef<HTMLVideoElement, IntroVideoProps>(({
  path,
}, ref) => (
  <div className={ styles.wrap }>
    <video
      ref={ ref }
      controls
      className={ styles.video }
      poster={ '/images/smartway_intro.png' }
    >
      <source src={ path } type='video/mp4'/>
      {DESCRIPTIONS}
    </video>
  </div>
));

export { IntroVideo };
