// @ts-nocheck
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Icon, LinkButton, PROPS, Text } from 'new-ui';
import { getText } from '../../../../i18n';

import { ConditionalDialog } from '../components/conditionalOfApprovers/ConditionalDialog';
import { ApproverComponents } from '../components/approvers/Approvers';

import { getApproversAndRolesLength } from '../../../bi/utils/approval';

import { APPROVAL_SCHEME_STEP_FIELDS, APPROVAL_TYPES } from '../../../bi/constants/approvalSchemes';
import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';

import styles from './styles.module.css';

const {
  ICON: { COLORS: { GRAY }, TYPES: { ARROWS: { BACK } } },
  TEXT: { TYPES: { NORMAL_18, NORMAL_20 } },
  LINK_BUTTON: { THEMES: { BLUE_WITHOUT_BORDER } },
} = PROPS;

const LABELS = {
  STEP_COUNT: step => getText('approvalScheme:step.stepCount', { step }),
  REMOVE_STEP: getText('approvalScheme:step.removeStep'),
  DROP_CART: getText('approvalScheme:step.dropCart'),
};

const ApprovalSchemeStep = observer(({
  ind,
  value,
  draggableItem,
  employees,
  roles,
  field,
  renderEmployee,
  onRemove,
  onChange,
  onClickAddApprover,
  onDragRemovableApprover,
  onDragEndRemovableApprover,
}) => {
  const [canBeDropped, setCanBeDropped] = useState(false);
  const [dialog, setDialog] = useState({
    cb: () => {},
    show: false,
  });

  const { id: draggableItemId, type: draggableItemType } = draggableItem;
  const { Roles, Approvers } = value;

  const handleOnChange = (payload) => onChange({ ...value, ...payload });

  const handleDialogButtonClick = v => {
    const { cb } = dialog;

    handleOnChange({ [APPROVAL_SCHEME_STEP_FIELDS.CONDITION_OF_APPROVAL]: v, ...cb() });
  };

  const handleOnDrop = () => {
    setCanBeDropped(false);

    if (!draggableItemType || value[draggableItemType].includes(draggableItemId)) {
      return null;
    }

    const payload = { [draggableItemType]: Array.from(new Set(value[draggableItemType]).add(draggableItemId)) };

    if (getApproversAndRolesLength(Roles, Approvers) === 1) {
      return setDialog({
        show: true,
        cb: () => {
          setDialog({ show: false });
          setCanBeDropped(false);

          return payload;
        },
      });
    }

    return handleOnChange(payload);
  };

  const arrowContent = ind !== 1 && (
    <div className={ styles.arrow }>
      <Icon
        className={ styles.icon }
        color={ GRAY }
        type={ BACK }
      />
    </div>
  );

  const renderRemoveButton = () => {
    if (ind === 1) {
      return null;
    }

    return (
      <LinkButton
        theme={ BLUE_WITHOUT_BORDER }
        onClick={ onRemove }
      >
        { LABELS.REMOVE_STEP }
      </LinkButton>
    );
  };

  const canBeDroppedWithId = canBeDropped && draggableItemId !== null;

  const renderDroppable = () => (
    <div
      className={ styles['droppable-panel'] }
      onDragLeave={ () => setCanBeDropped(false) }
      onDragOver={ e => e.preventDefault() }
      onDrop={ handleOnDrop }
    >
      <Text type={ NORMAL_20 }>
        { LABELS.DROP_CART }
      </Text>
    </div>
  );

  const droppablePanel = canBeDroppedWithId && renderDroppable();

  const wrapperClassNames = [styles.wrapper];

  if (canBeDroppedWithId) {
    wrapperClassNames.push(styles.droppable);
  }

  if (draggableItemId !== null) {
    wrapperClassNames.push(styles.highlighted);
  }

  const onDragProps = canBeDroppedWithId ? {} : {
    onDragEnter: () => setCanBeDropped(true),
    onDragOver: e => e.preventDefault(),
    onDrop: handleOnDrop,
  };

  const qaAttrStep = `${QA_ATTRIBUTES.approvalSchemes.trips.preTravel.moveToStep}-${ind}`;

  return (
    <div>
      { arrowContent }
      <div className={ wrapperClassNames.join(' ') } { ...onDragProps }>
        <div className={ styles.common }>
          <div className={ styles.header }>
            <Text type={ NORMAL_18 }>
              { LABELS.STEP_COUNT(ind) }
            </Text>
            { renderRemoveButton() }
          </div>
          <ApproverComponents
            employees={ employees }
            roles={ roles }
            value={ value }
            field={ field }
            renderEmployee={ renderEmployee }
            onChange={ onChange }
            onClickAddApprover={ onClickAddApprover }
            onDragRemovableApprover={ onDragRemovableApprover }
            onDragEndRemovableApprover={ onDragEndRemovableApprover }
            qaAttr={ qaAttrStep }
          />
        </div>
        { droppablePanel }
        <ConditionalDialog
          show={ dialog.show }
          onClick={ handleDialogButtonClick }
        />
      </div>
    </div>
  );
});

ApprovalSchemeStep.propTypes = {
  field: PropTypes.string,
  settingsType: PropTypes.bool,
  ind: PropTypes.number.isRequired,
  value: PropTypes.object.isRequired,
  draggableItem: PropTypes.object.isRequired,
  employees: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired,
  renderEmployee: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickAddApprover: PropTypes.func.isRequired,
  onDragRemovableApprover: PropTypes.func.isRequired,
  onDragEndRemovableApprover: PropTypes.func.isRequired,
};

ApprovalSchemeStep.defaultProps = {
  field: APPROVAL_TYPES.TRIP,
};

export { ApprovalSchemeStep };
