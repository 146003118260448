const CLASSNAMESFORTESTS = {
  AIRLINES: {
    GETLINK: 'airline-get-link',
    TONOTEPAD: 'airline-to-notepad',
    TOFAVORITE: 'airline-to-favorite',
    TOCART: 'airline-to-cart',
    ITEM: 'airline-item',
  },
  HOTELS: {
    GETLINK: 'hotel-get-link',
    TOFAVORITE: 'hotel-to-favorite',
    SELECTROOM: 'hotel-select-room',
    TONOTEPAD: 'hotel-to-notepad',
    ITEM: 'hotel-item',
  },
  TRAINS: {
    GETLINK: 'train-get-link',
    TOFAVORITE: 'train-to-favorite',
    SELECTCAR: 'train-select-car',
    ITEM: 'train-item',
  },
  TRANSFERS: {
    TONOTEPAD: 'transfer-to-notepad',
    TOFAVORITE: 'transfer-to-favorite',
    TOCART: 'transfer-to-cart',
    ITEM: 'transfer-item',
  },
  VIP_HALL: {
    ITEM: 'vip-hall-item',
  },
  CARTITEM: {
    TONOTEPAD: 'cart-item-to-notepad',
    DELETE: 'cart-item-delete',
    ITEM: 'cart-item',
    SELECTEMPLOYEE: 'cart-item-select-employee',
  },
  CART: {
    DELETEALL: 'delete-all',
  },
  NOTEPADITEM: {
    TOCART: 'notepad-item-to-cart',
    DELETE: 'notepad-item-delete',
    BOOKING: 'notepad-item-booking',
    ITEM: 'notepad-item',
  },
  CHANGEPASSWORD: {
    INPUTOLD: 'change-password-input-old',
    INPUTNEW: 'change-password-input-new',
    INPUTNEWAGAIN: 'change-password-input-new-again',
    CHANGE: 'change-password',
    CANCEL: 'cancel-change-password',
  },
};

export default CLASSNAMESFORTESTS;
