const PATH = {
  MAIN: '/trip/:tripId/additionalServices/:ticketId',
  WAITING_ROOM: 'trip/:tripId/additionalServices/:ticketId/vipHall/:guid',
  ROUTE_VIP: '/trip/:tripId/additionalServices/:ticketId/vipHall',
  ROUTE_ESCORT: '/trip/:tripId/additionalServices/:ticketId/escort',
  DIRECTION: '/trip/:tripId/additionalServices/:ticketId/direction',
  TERMINAL: '/trip/:tripId/additionalServices/:ticketId/terminal',
  RESULT_VIP: '/trip/:tripId/additionalServices/:ticketId/vipHall/:guid',
  RESULT_ESCORT: '/trip/:tripId/additionalServices/:ticketId/escorts/:guid',
  INFO_VIP: '/trip/:tripId/additionalServices/:ticketId/vip/:guid',
  INFO_ESCORT: '/trip/:tripId/additionalServices/:ticketId/escort/:guid',
  PAYMENT_VIP: '/trip/:tripId/additionalServices/:ticketId/payment/vip/:guid',
  PAYMENT_ESCORT: '/trip/:tripId/additionalServices/:ticketId/payment/escort/:guid',
  FLIGHT_CERTIFICATE: '/trip/:tripId/additionalServices/:ticketId/orderFlightCertificate',
  OTHER_CHANGES: '/trip/:tripId/additionalServices/:ticketId/otherChanges/:additionalType?',
};

const ADDITIONAL_UNIQ = {
  OTHER: 'otherChanges',
  ORDER_FLIGHT_CERTIFICATE: 'orderFlightCertificate',
};

const ADDITIONAL_MAIN = {
  CHOOSE_SEAT: 'chooseSeat',
  EXTRA_BAGGAGE: 'extraBaggage',
};

const ADDITIONAL_TYPES = {
  VIP_HALL: 'vipHall',
  ESCORT: 'escort',
  CHOOSE_SEAT: 'chooseSeat',
  EXTRA_BAGGAGE: 'extraBaggage',
};

const VALUES_DIRECTION = {
  DEPARTURE: 'departure',
  ARRIVAL: 'arrival',
  TRANSIT: 'transit',
};

export { PATH, ADDITIONAL_TYPES, ADDITIONAL_MAIN, VALUES_DIRECTION, ADDITIONAL_UNIQ };
