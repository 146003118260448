const loadImageWithTimeout = (src: string, timeout = 1000): Promise<void> => new Promise((resolve, reject) => {
  const image = new Image();
  const timer = setTimeout(() => {
    image.src = '';
    reject(new Error(`Image load timed out: ${src}`));
  }, timeout);

  image.onload = () => {
    clearTimeout(timer);
    resolve();
  };

  image.onerror = () => {
    clearTimeout(timer);
    reject(new Error(`Failed to load image: ${src}`));
  };

  image.src = src;
});

const preloadOrigUrlAndUrl = async (img: { OrigUrl: string, Url: string }) => {
  const { OrigUrl, Url } = img;
  try {
    await Promise.all([
      loadImageWithTimeout(OrigUrl),
      loadImageWithTimeout(Url),
    ]);

    return img;
  } catch (e) {
    return null;
  }
};

const preloadOrigUrl = async (OriginUrl: string) => {
  try {
    const promise = await new Promise((res, rej) => {
      const imageOriginUrl: HTMLImageElement = new Image();
      imageOriginUrl.src = OriginUrl;
      imageOriginUrl.onload = res;
      imageOriginUrl.onerror = rej;
    });

    return promise;
  } catch {
    return null;
  }
};

export { preloadOrigUrlAndUrl, preloadOrigUrl };
