import React from 'react';
import { StyledWrapper, Text, Icon } from 'new-ui';
import { getText } from '../../../i18n';

import { svgColor } from '../../bi/utils/env';

import styles from './styles/index.module.css';

interface Amenties {
  Name: string,
  Values: string[],
}

interface ServicesComponentProps {
  hotel: {
    Description: string,
    Amenities: Amenties[],
  },
  className?: string,
}

const LABELS = {
  moreAboutOfHotel: getText('hotels:hotelResult.moreAboutOfHotel'),
};

const HotelServices = ({
  hotel: { Description = '', Amenities = [] },
  className,
}: ServicesComponentProps) => {
  const valuesHtml = (values: string[]) => values.map((value, ind) => (
    <div key={ `service_${ind}` } className={ styles.item }>
      <Icon type='checkMark' color={ svgColor }/>
      <Text type='NORMAL_16_140' className={ styles.text }>{ value }</Text>
    </div>
  ));

  const amenitiesHtml = Amenities && Amenities.length
    ? Amenities.map(({ Name, Values }, ind) => (
      <div key={ `services_${ind}` } className={ styles.amenities }>
        <Text type='bold_24' className={ styles.title }>
          { Name }
        </Text>
        { valuesHtml(Values) }
      </div>
    ))
    : null;

  const descriptionHtml = !!Description.length && (
    <div className={ styles.description }>
      <Text type='bold_24'>{ LABELS.moreAboutOfHotel }</Text>
      <div
        className={ styles.text }
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={ { __html: Description } }
      />
    </div>
  );

  const classNames = [styles.wrapper];

  if (className) {
    classNames.push(className);
  }

  return (
    <StyledWrapper className={ classNames.join(' ') }>
      { descriptionHtml }
      { amenitiesHtml }
    </StyledWrapper>
  );
};

export { HotelServices };
