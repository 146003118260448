import { observable, action, makeObservable } from 'mobx';
import { Moment } from 'moment';

import { chartsAnalyticsDatesMaker } from '../../../utils/chartsAnalytics';
import { momentObject } from '../../../utils/formatDate';

import { DEFAULT_CHARTS_ANALYTICS, DEFAULT_FILTERS, MENU, MENU_KEYS } from '../../../constants/chartsAnalytics';

import { CommonChartsAnalyticsType, MenuType } from '../types';

export interface IChartsAnalyticsStore {
  loading: boolean,
  commonChartsAnalytics: CommonChartsAnalyticsType,
  filters: any,
  menu: MenuType[],
  autocompleteEmployeesQuery: string,
  autocompleteDepartmentsQuery: string,
  autocompleteProjectsQuery: string,
  autocompleteTagsQuery: string,
  filtersType: string,
  showDepartments: boolean,
  notShowFilterDepartment: boolean,
  notShowFilterProject: boolean,
  cachedDates: null | any,
  id: null | number,
  customAnalytics: any[],
  downloadXlsLoading: boolean,
}

export class ChartsAnalyticsStore {
  constructor() {
    makeObservable(this);
  }

  @observable loading = true;
  @observable downloadXlsLoading = false;
  @observable commonChartsAnalytics: CommonChartsAnalyticsType = { ...DEFAULT_CHARTS_ANALYTICS };
  @observable filters = { ...DEFAULT_FILTERS };
  @observable menu = MENU();
  @observable autocompleteEmployeesQuery = '';
  @observable autocompleteDepartmentsQuery = '';
  @observable autocompleteProjectsQuery = '';
  @observable autocompleteTagsQuery = '';
  @observable filtersType = '';
  @observable showDepartments = false;
  @observable notShowFilterDepartment = false;
  @observable notShowFilterProject = false;
  @observable cachedDates = null;
  @observable id: number | null = null;
  @observable customAnalytics = [];

  @action
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  setDownloadXlsLoading = (value: boolean) => {
    this.downloadXlsLoading = value;
  };

  @action
  setCustomAnalyticsToMenu = (values: any) => {
    this.menu = MENU(values);
  };

  @action
  setShowDepartmentsToMenu = (value: boolean) => {
    this.showDepartments = value;
  };

  @action
  getShowDepartmentsMenu = () => this.showDepartments;

  @action
  setCustomAnalytics = (values: any) => {
    this.customAnalytics = values;
  };

  @action
  setMenu = (values: string[]) => {
    this.menu = this.menu.reduce((acc: any[], item: MenuType) => {
      const { id, items } = item;

      if ((!items.length && values.includes(id)) || id === MENU_KEYS.STRUCTURE.TITLE) {
        return [...acc, item];
      }

      const list = items.filter(({ id: itemId }) => values.includes(itemId));

      if (list.length) {
        const changedItem = {
          ...item,
          items: list,
        };

        return [...acc, changedItem];
      }

      return acc;
    }, []);
  };

  @action
  setEmployeesSelected = (values: string[] | any) => {
    this.filters.employees = {
      ...this.filters.employees,
      selected: values,
    };
  };

  @action
  setEmployeesList = (values: string[] | any) => {
    this.filters.employees = {
      ...this.filters.employees,
      list: values,
    };
  };

  @action
  setDepartmentsSelected = (values: number[] | any) => {
    this.filters.departments = {
      ...this.filters.departments,
      selected: values,
    };
  };

  @action
  setDepartmentsList = (values: string[] | any) => {
    this.filters.departments = {
      ...this.filters.departments,
      list: values,
    };
  };

  @action
  setFirstDepartmentsList = (values: string[] | any) => {
    this.filters.departments = {
      ...this.filters.departments,
      firstDepartments: values,
    };
  };

  @action
  setDepartmentsAutocompleteList = (values: string[] | any) => {
    this.filters.departmentsAutocomplete = {
      ...this.filters.departmentsAutocomplete,
      list: values,
    };
  };

  @action
  setAutocompleteDepartmentsQuery = (value: string) => {
    this.autocompleteDepartmentsQuery = value;
  };

  @action
  setProjectsSelected = (values: string[] | any) => {
    this.filters.projects = {
      ...this.filters.projects,
      selected: values,
    };
  };

  @action
  setProjectsList = (values: string[] | any) => {
    this.filters.projects = {
      ...this.filters.projects,
      list: values,
    };
  };

  @action
  setFirstProjectsList = (values: string[] | any) => {
    this.filters.projects = {
      ...this.filters.projects,
      firstProjects: values,
    };
  };

  @action
  setProjectsAutocompleteList = (values: string[] | any) => {
    this.filters.projectsAutocomplete = {
      ...this.filters.projectsAutocomplete,
      list: values,
    };
  };

  @action
  setAutocompleteProjectsQuery = (value: string) => {
    this.autocompleteProjectsQuery = value;
  };

  @action
  setPreparedDates = (createDate: string) => {
    const { startDate, endDate } = chartsAnalyticsDatesMaker(createDate);

    this.commonChartsAnalytics = {
      ...this.commonChartsAnalytics,
      minDate: createDate ? momentObject(createDate) : null,
      startDate: momentObject(startDate),
      endDate: momentObject(endDate),
    };
  };

  @action
  setPreparedCompanies = (companies: any[]) => {
    const preparedSelectedCompany = companies.map(({ ShortCompanyName, CompanyName, CompanyId }) => ({
      main: CompanyId,
      nested: [],
      label: ShortCompanyName || CompanyName,
    }))[0];

    const preparedCompanies = companies.map(({ ShortCompanyName, CompanyName, CompanyId }) => ({
      label: ShortCompanyName || CompanyName,
      value: CompanyId,
      nested: [],
    }));

    this.commonChartsAnalytics = {
      ...this.commonChartsAnalytics,
      companies: preparedCompanies,
      selectedCompanies: [preparedSelectedCompany],
    };
  };

  @action
  setStartDate = (value: Moment) => {
    if (value.isAfter(this.commonChartsAnalytics.endDate)) {
      this.commonChartsAnalytics = {
        ...this.commonChartsAnalytics,
        startDate: value,
        endDate: value,
      };
    } else {
      this.commonChartsAnalytics = {
        ...this.commonChartsAnalytics,
        startDate: value,
      };
    }
  };

  @action
  setEndDate = (value: Moment) => {
    this.commonChartsAnalytics = {
      ...this.commonChartsAnalytics,
      endDate: value,
    };
  };

  @action
  setCachedDates = (dates: string[] | any) => {
    this.cachedDates = dates;
  };

  @action
  setSelectedCompanies = (values: string[] | any) => {
    if (!values.length) return;

    const selectedCompanies = values.map((item: any) => ({
      ...item,
      label: this.commonChartsAnalytics.companies.find(({ value }) => value === Number(item.main))?.label || '',
    }));

    this.commonChartsAnalytics = {
      ...this.commonChartsAnalytics,
      selectedCompanies,
    };
  };

  @action
  setAnalyticsSelected = (values: number[] | any) => {
    this.filters.analytics = {
      ...this.filters.analytics,
      selected: values,
    };
  };

  @action
  setAnalyticsList = (values: number[] | any) => {
    this.filters.analytics = {
      ...this.filters.analytics,
      list: values,
    };
  };

  @action
  setResetStore = () => {
    this.commonChartsAnalytics = { ...DEFAULT_CHARTS_ANALYTICS };
    this.filters = { ...DEFAULT_FILTERS };
    this.loading = true;
    this.notShowFilterDepartment = false;
    this.notShowFilterProject = false;
  };

  @action
  setResetFilters = () => {
    this.filters = { ...DEFAULT_FILTERS };
  };

  @action
  setFiltersType = (value: string | any) => {
    this.filtersType = value;
  };

  @action
  setAutocompleteEmployeesQuery = (value: string) => {
    this.autocompleteEmployeesQuery = value;
  };

  @action
  setFirstEmployeesList = (values: string[] | any) => {
    this.filters.employees = {
      ...this.filters.employees,
      firstEmployees: values,
    };
  };

  @action
  setEmployeesAutocompleteList = (values: string[] | any) => {
    this.filters.employeesAutocomplete = {
      ...this.filters.employeesAutocomplete,
      list: values,
    };
  };

  @action
  setTagsSelected = (values: number[] | any) => {
    this.filters.tags = {
      ...this.filters.tags,
      selected: values,
    };
  };

  @action
  setTagsList = (values: string[] | any) => {
    this.filters.tags = {
      ...this.filters.tags,
      list: values,
    };
  };

  @action
  setFirstTagsList = (values: string[] | any) => {
    this.filters.tags = {
      ...this.filters.tags,
      firstTags: values,
    };
  };

  @action
  setTagsAutocompleteList = (values: string[] | any) => {
    this.filters.tagsAutocomplete = {
      ...this.filters.tagsAutocomplete,
      list: values,
    };
  };
  @action
  setAutocompleteTagsQuery = (value: string) => {
    this.autocompleteTagsQuery = value;
  };

  @action
  setShowFilterDepartment = (value: boolean) => {
    this.notShowFilterDepartment = value;
  };

  @action
  setShowFilterProject = (value: boolean) => {
    this.notShowFilterProject = value;
  };

  @action
  setRouteId = (id: string | any) => {
    this.id = id;
  };
}

export const chartsAnalyticsStore = new ChartsAnalyticsStore();
