import React from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../i18n';

import styles from './styles/index.module.css';

import cameraSvg from './styles/images/camera.svg';

const LABELS = {
  NO_PHOTO: getText('components:noPhoto.title'),
};

const NoPhoto = () => (
  <div className={ styles.wrapper }>
    <div className={ styles.info }>
      <img src={ cameraSvg } alt={ 'no-photo' } />
      <Text type='NORMAL_14' color='gray' className={ styles.text }>
        { LABELS.NO_PHOTO }
      </Text>
    </div>
  </div>
);

export { NoPhoto };
