import { SERVICETYPE } from '../constants/serviceType';
import parseJsonString from './parseJsonString';
import { IPrepareNoteItem } from '../services/notepad/types';

const getMultiTripIds = (sources: IPrepareNoteItem[]): { [key: string]: number } => sources.reduce((r, item) => {
  const { MultiTripInfo } = parseJsonString(item.Data);

  if (MultiTripInfo?.Id) {
    // @ts-ignore
    if (r?.[MultiTripInfo.Id]) {
      return {
        ...r,
        [MultiTripInfo.Id]: [
          // @ts-ignore
          ...r[MultiTripInfo.Id],
          item.Id,
        ],
      };
    }

    return {
      ...r,
      [MultiTripInfo.Id]: [item.Id],
    };
  }

  return {
    ...r,
  };
}, {});

const getMultiTripItems = (sources: any[]): any => sources.reduce((r, item) => {
  const { MultiTripInfo } = parseJsonString(item.Data);

  if (MultiTripInfo?.Id) {
    if (r?.[MultiTripInfo.Id]) {
      return {
        ...r,
        [MultiTripInfo.Id]: [
          ...r[MultiTripInfo.Id],
          item,
        ],
      };
    }

    return {
      ...r,
      [MultiTripInfo.Id]: [item],
    };
  }

  return {
    ...r,
  };
}, {});

const getCurrenMultiTriptIds = (items: any[]) => {
  if (!items?.length) {
    return [];
  }

  return items.filter(item => !item.BookDeadline).map(item => item.Id);
};

const uniqueElements = (array: any, key: any) => array.filter((value: any, index: number, self: any) =>
  index === self.findIndex((t: any) => (
    t[key] === value[key]
  )),
);

export const omit = <O extends object, OmitKeys extends (keyof O), R extends Omit<O, OmitKeys>>(
  obj: O, keys: OmitKeys[],
) => Object.keys(obj)
    .filter(key => keys.findIndex((e) => e === key) < 0)
    .reduce((newObj, key) => ({ ...newObj, [key]: (obj as any)[key] }), {}) as R;

export const isValueCompute = <V, O extends Record<string, V>, K extends keyof O>(value: V, src: O) => (
  Object.fromEntries(
    Object.keys(src).map((key) => ([key, value === src[key]])),
  ) as Record<K, boolean>
);

export const isValueComputeEnum = <V, O extends string>(value: V, src: Record<O, any>) => isValueCompute(value, src);

export const isServiceTypeCompute = (value: string) => isValueCompute(value, SERVICETYPE);

export { getMultiTripIds, getMultiTripItems, getCurrenMultiTriptIds, uniqueElements };
