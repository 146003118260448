import { getText } from '../../../i18n';

import { Fare, OrganizationType, Place } from '../../page/Cart/components/MinimalFares/types';

const LABELS = {
  AIR_DECLINES: (count: number) => getText('constants:airline.airDeclines.key', { count }),
  WITHOUT_AIR: getText('components:requestAirItem.withoutTransfers'),
  MINIMAL_TARIF: {
    HOURS: (hours: number, minutes: string) => (
      getText('constants:airline.minimalTarif.hours', {
        hours, minutes,
      })
    ),
    WITH_DAYS: (days: number, hours: number, minutes: string) => (
      getText('constants:airline.minimalTarif.withDays', {
        days, hours, minutes,
      })
    ),
  },
};

export const processData = (data: string) => {
  const { Price } = JSON.parse(data);
  const totalPrice: number | string = Price?.TotalPrice
    ? Price.TotalPrice.toFixed(2)
    : 0;

  return { totalPrice, Price };
};

export const getFormattedLocation = (location: Place) => `${location.Name} (${location.Code})`;

export const getFormattedOrganization = (organization: OrganizationType) => `${organization.Name} (${organization.Code})`;

export const calculateDuration = (duration: string) => {
  const [daysHours, minutes] = duration.split(':');
  const [days, hours] = daysHours.includes('.')
    ? daysHours.split('.').map(Number)
    : [0, Number(daysHours)];

  return days
    ? LABELS.MINIMAL_TARIF.WITH_DAYS(days, hours, minutes)
    : LABELS.MINIMAL_TARIF.HOURS(hours, minutes);
};

export const prepareTransfersLabel = (fare: Fare []) => {
  const hasTransfers = fare.length > 1;

  return hasTransfers
    ? LABELS.AIR_DECLINES(fare.length - 1)
    : LABELS.WITHOUT_AIR;
};

export const calculatePriceDiscount = (
  firstPrice: number,
  totalPrice: number,
) =>
  Math.abs(
    Math.round(
      ((firstPrice - Number(totalPrice)) / firstPrice) * 100,
    ),
  );
