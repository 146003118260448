import React, { useEffect, useState } from 'react';
import { Select, Text } from 'new-ui';

import { getText } from '../../../i18n';

import { ILabelProps, LabelItemSelect, LabelSelect } from './labels';

import { getEmployeeFullName, isSameNames } from '../../bi/utils/employees';
import { formatDate } from '../../bi/utils/formatDate';
import { isServiceTypeCompute } from '../../bi/utils/common';

import COUNTRIES from '../../bi/constants/countries';
import PASSPORTS from '../../bi/constants/passport';
import { SPACE_OR_HYPHEN } from '../../bi/constants/regExp';

import styles from './styles/index.module.css';

const LABELS = {
  TO: getText('components:employeePassport.to'),
  NUMBER: getText('components:employeePassport.number'),
  PASSPORT_TYPE_UNDEFINED: getText('components:employeePassport.passportTypeUndefined'),
  SELECT_PASSPORT: getText('components:employeePassport.selectPassport'),
  TEXT_NO_HYPHEN_AND_SPACE: getText('components:employeePassport.noHyphenAndSpace'),
};

const replacer = (s?: string | null) => s?.replace(SPACE_OR_HYPHEN, '') || '';

interface IEmployeePassportProps {
  documents: any[]
  employee: any
  enumOfDocuments: any
  currentDocumentId?: number
  serviceType: string
  readOnly: boolean
  onChange: (value: number) => void
  qaAttrText?: string
  qaAttrSelect?: string
}

export const EmployeePassport = ({
  documents,
  employee,
  employee: { Code },
  enumOfDocuments,
  currentDocumentId = 0,
  serviceType,
  readOnly,
  onChange,
  qaAttrText = '',
  qaAttrSelect = '',
}: IEmployeePassportProps) => {
  const isServiceType = isServiceTypeCompute(serviceType);

  const prepareDocumentNumber = (document: typeof documents[0]) => {
    const { DomesticPassport, ForeignPassport } = enumOfDocuments;
    const { Type, Number } = document;

    const numberMustBeSliced =
      Code === COUNTRIES.RU.SHORTNAME && (Type === DomesticPassport || Type === ForeignPassport);

    if (numberMustBeSliced) {
      return `${Number.substring(0, 2)} ${Number.substring(2, 4)} ${Number.substring(4)}`;
    }

    return Number;
  };

  const getPassportValue = (document: typeof documents[0]) => {
    if (Code !== COUNTRIES.RU.SHORTNAME) {
      switch (document.Type) {
        case enumOfDocuments.DomesticPassport: {
          return PASSPORTS.FOREIGNER_DOMESTIC_PASSPORT.VALUE;
        }
        case enumOfDocuments.ForeignPassport: {
          return PASSPORTS.FOREIGN_PASSPORT.VALUE;
        }
      }
    }

    if (Code === COUNTRIES.RU.SHORTNAME) {
      switch (document.Type) {
        case enumOfDocuments.DomesticPassport: {
          return PASSPORTS.RU_PASSPORT.VALUE;
        }

        case enumOfDocuments.ForeignPassport: {
          return PASSPORTS.FOREIGN_PASSPORT.VALUE;
        }

        case enumOfDocuments.BirthCertificate: {
          return PASSPORTS.BIRTH_CERTIFICATE.VALUE;
        }

        default: {
          return LABELS.PASSPORT_TYPE_UNDEFINED;
        }
      }
    }

    return PASSPORTS.DOMESTIC_PASSPORT.VALUE;
  };

  const mapDocuments = (list: any[] = []) => (
    list.map((document) => {
      const { DomesticPassport } = enumOfDocuments;
      const { Type, DueDate, Id } = document;
      const isRuPassport = Code === COUNTRIES.RU.SHORTNAME && Type === DomesticPassport;
      const showFullName = isRuPassport && !isSameNames(document, employee);
      const fullNameText = showFullName ? ` ${getEmployeeFullName(document)}` : '';
      const label = `${getPassportValue(document)}${fullNameText}`;
      const fullLabel = `${getPassportValue(document)}  ${getEmployeeFullName(document)}`;
      const dueDate = DueDate ? ` ${LABELS.TO} ${formatDate(DueDate)}` : '';
      const preparedNumber = prepareDocumentNumber(document);

      return ({
        label,
        fullLabel,
        secondLabel: `${LABELS.NUMBER} ${preparedNumber}${dueDate}`,
        anotherLabel: ` ${preparedNumber}`,
        value: Id,
      });
    })
  );

  const [items, setItems] = useState<ILabelProps[]>(mapDocuments(documents));

  useEffect(() => {
    setItems(mapDocuments(documents));
  }, [documents]);

  const renderLatNameEmployee = () => {
    if (!currentDocumentId) return null;

    const currentPassport = documents.find(({ Id }) => Id === currentDocumentId);

    if (!currentPassport) return null;

    const { Type, FirstName, Name, LastName, Surname, MiddleName, Patronymic } = currentPassport;
    let latFirstName = Name;
    let latLastName = Surname;
    let latPatronymic = Patronymic;

    const transliterateByService = isServiceType.AIR;

    const isRuPassport = Type === enumOfDocuments.DomesticPassport && employee.Code === COUNTRIES.RU.SHORTNAME;
    const cyrillicDomesticPassport = Type === enumOfDocuments.DomesticPassport
      && employee.Code !== COUNTRIES.RU.SHORTNAME
      && !!LastName
      && !!FirstName;

    let transliterate = cyrillicDomesticPassport ? (transliterateByService) : false;
    transliterate = (isRuPassport || !cyrillicDomesticPassport) ? isRuPassport : transliterate;

    if (transliterate) {
      latFirstName = FirstName;
      latLastName = LastName;
      latPatronymic = MiddleName;
    }

    const trainMiddleName = isServiceType.TRAIN && !!MiddleName;

    if (trainMiddleName) latPatronymic = MiddleName;

    const fullName = `${latLastName} ${latFirstName} ${latPatronymic || ''}`;

    if (!transliterateByService && !(isServiceType.TRAIN)) {
      return (
        <Text className={ styles.latName } type='NORMAL_14' color='gray'>
          { fullName }
        </Text>
      );
    }

    const clearFullName = `${replacer(latLastName)} ${replacer(latFirstName)} ${replacer(latPatronymic)}`;
    const employeeName = (isServiceType.AIR || isServiceType.TRAIN) ? fullName : clearFullName;

    return (
      <Text className={ styles.latName } type='NORMAL_14' color='gray'>
        { employeeName }
      </Text>
    );
  };

  if (items.length === 0) return null;

  if (items.length > 1 && !readOnly) {
    return (
      <div className={ styles.wrapper }>
        <Select
          placeholder={ LABELS.SELECT_PASSPORT }
          qaAttr={ qaAttrSelect }
          excludeValue
          value={ currentDocumentId }
          items={ items }
          onChange={ onChange }
          renderLabel={ LabelSelect }
          renderItem={ LabelItemSelect }
        />
        { renderLatNameEmployee() }
      </div>
    );
  }

  const currentDocumentItem = items
    .find(document => document.value === currentDocumentId);

  if (currentDocumentItem) {
    return (
      <div data-qa={ qaAttrText } className={ styles.wrapper }>
        <Text type='SEMIBOLD_16'>
          { `${currentDocumentItem.label}${currentDocumentItem.anotherLabel}` || ''}
        </Text>
        { renderLatNameEmployee() }
      </div>
    );
  }

  if (items.length) {
    return (
      <div className={ styles.wrapper }>
        <Select
          placeholder={ LABELS.SELECT_PASSPORT }
          qaAttr={ qaAttrSelect }
          excludeValue
          value={ currentDocumentId }
          items={ items }
          onChange={ onChange }
          renderLabel={ LabelSelect }
          renderItem={ LabelItemSelect }
        />
      </div>
    );
  }

  return null;
};
