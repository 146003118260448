const CONSTANTS = {
  CONTROLS: {
    FULLSCREENCONTROL: 'fullscreenControl',
    GEOLOCATIONCONTROL: 'geolocationControl',
    ROUTEEDITOR: 'routeEditor',
    RULERCONTROL: 'rulerControl',
    TRAFFICCONTROL: 'trafficControl',
    TYPESELECTOR: 'typeSelector',
    ZOOMCONTROL: 'zoomControl',
  },
  SEARCHCONTROLPROVIDERS: {
    YANDEXSEARCH: 'yandex#search',
  },
  PARAMS: {
    VERSION: '2.1',
    QUERY: '',
  },
  ZOOM: 12,
  DEFAULT_ZOOM: 17,
  PLACEMARKS_MAX_COUNT_ON_MAP: 30,
};

export { CONSTANTS };
