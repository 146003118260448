const textContainsPattern = (text: string, pattern: string) => text && text.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;

const removeSpaces = (v: any) => v.replace(/\s/g, '');

const onlyNumbersOrEmpty = (v: string) => /^\d+$/.test(v) || !v.length;

const onlyFractionalNumbersOrEmpty = (v: string) => /^\d*[,.]?\d*$/.test(v) || !v.length;

const sanitizeText = (text: string) => {
  const trimmedText = text.trim();

  return trimmedText.replace(/  +/g, ' ');
};

const firstSymbolUpperCase = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

const repeatTextFragment = (fullWord: string, textFragment: string) => {
  const indexWord = fullWord.toLowerCase().indexOf(textFragment.toLowerCase());

  const first = fullWord.substring(0, indexWord);
  const medium = fullWord.substring(indexWord, indexWord + textFragment.length);
  const last = fullWord.substring(indexWord + textFragment.length, fullWord.length);

  return {
    first,
    medium,
    last,
  };
};

const replacementComma = (value: string) => parseFloat(value.replace(',', '.')
  .replace(/\s/g, '')) || value;

export {
  textContainsPattern,
  removeSpaces,
  onlyNumbersOrEmpty,
  onlyFractionalNumbersOrEmpty,
  sanitizeText,
  firstSymbolUpperCase,
  repeatTextFragment,
  replacementComma,
};
