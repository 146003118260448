import React, { useState } from 'react';
import { Text, Button, Icon } from 'new-ui';
import { getText } from '../../../i18n';

import { dateUtcFormat } from '../../bi/utils/formatDate';
import toDecline from '../../bi/utils/toDecline';
import { getFullAddress } from '../../bi/utils/taxi';

import { TAXI_COUNT, TAXI_CLASSES_TITLE } from '../../bi/constants/taxi';
import { PATTERN } from '../../bi/constants/dateFormats';

import { TaxiItemRoutes } from './components/TaxiItemRoutes';

import { ITaxiItem, ITaxiItemRoute } from '../../bi/types/taxi';

import styles from './styles/index.module.css';

interface ITaxiItemProps {
  item: ITaxiItem,
}

const LABELS = {
  DATE_TIME: getText('taxi:route.dateTime'),
  FROM: getText('taxi:route.from'),
  TO: getText('taxi:route.to'),
};

const TaxiItem = ({ item: { EventDate, Class, Route } }: ITaxiItemProps) => {
  const [isShowRoutes, setIsShowRoutes] = useState(false);

  const countStops = Route.length - 2;

  const renderIcon = () => {
    if (isShowRoutes) {
      return (
        <Icon type='arrowsUpDimblue' />
      );
    }

    return <Icon type='arrowsDownDimblue' />;
  };

  const renderStopsButton = () => (countStops ? (
    <Button
      type='textual'
      onClick={ () => setIsShowRoutes(!isShowRoutes) }
    >
      { countStops } { toDecline(countStops, TAXI_COUNT) }
      { renderIcon() }
    </Button>
  ) : null);

  const renderRoutes = () => {
    const stopsHtml = Route.map((item, index) => {
      if (index !== 0 && index !== Route.length - 1) {
        return (
          <TaxiItemRoutes
            key={ index }
            item={ item }
            index={ index }
            length={ countStops }
          />
        );
      }

      return null;
    });

    return (
      <div className={ styles.routes }>
        { stopsHtml }
      </div>
    );
  };

  const renderRoute = (rout: ITaxiItemRoute) => {
    if (!rout) {
      return null;
    }

    return getFullAddress(rout);
  };

  const renderPlaces = () => {
    if (!Route.length) {
      return null;
    }

    const departurePlace = renderRoute(Route[0]);
    const arrivalPlace = renderRoute(Route[Route.length - 1]);

    return (
      <div className={ styles.route }>
        <div className={ styles.direction }>
          <Text className={ styles['departure-place'] } type='bold_18'>{departurePlace}</Text>
          <Text type='NORMAL_14' color='gray' className={ styles.direction_type }>{ LABELS.FROM }</Text>
        </div>
        <div className={ styles.direction }>
          <Text className={ styles['arrival-place'] } type='bold_18'>{arrivalPlace}</Text>
          <Text type='NORMAL_14' color='gray' className={ styles.direction_type }>{ LABELS.TO }</Text>
        </div>
      </div>
    );
  };

  const renderPlacesHtml = renderPlaces();

  const routesHtml = isShowRoutes ? renderRoutes() : null;

  return (
    <div className={ styles.wrapper }>
      {renderPlacesHtml}
      {renderStopsButton()}
      {routesHtml}
      <div className={ styles.details }>
        <Text type='NORMAL_16_130' className={ styles.text }>{LABELS.DATE_TIME}</Text>
        <Text type='bold_22'>
          {dateUtcFormat(EventDate, PATTERN.ACTION_IN_DATE_TIME_PATTERN)}
        </Text>
        <Text type='NORMAL_16_130' className={ styles.text }>
          {TAXI_CLASSES_TITLE[Class]}
        </Text>
      </div>
    </div>
  );
};

export { TaxiItem };
