import { TRIPSTATUS, VERSIONSTATUS } from '../constants/trips';

const canCancelledTrip = (status: string, statusOrderItem: number) =>
  (status === TRIPSTATUS.BOOKED || status === TRIPSTATUS.AWAITINGCONFIRMATION) &&
  statusOrderItem !== VERSIONSTATUS.CANCELLED;

const isActiveTrip = (status: string) => status !== TRIPSTATUS.CANCELLED && status !== TRIPSTATUS.AWAITINGTICKETING;

const isNotActiveTrip = (status: string) => status === TRIPSTATUS.COMPLETED || status === TRIPSTATUS.CANCELLED;

const isServiceTrip = (status: string) => isActiveTrip(status) && !isNotActiveTrip(status);

export {
  canCancelledTrip,
  isActiveTrip,
  isNotActiveTrip,
  isServiceTrip,
};
