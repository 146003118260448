import { ESCAPECHARS, SPECIALCHARSBYGROUPS } from '../constants/regExp';

const splitWithoutRemovingSeparator = (
  string?: string | any,
  separator: RegExp | string = SPECIALCHARSBYGROUPS,
  needConvertSeparatorToPattern?: boolean,
) => {
  if (typeof string !== 'string' || (typeof separator !== 'string' && typeof separator !== 'object')) return [];

  let pattern;

  if (needConvertSeparatorToPattern) {
    // @ts-ignore
    const escapeQuery = separator.replace(ESCAPECHARS, '\\$&');
    pattern = new RegExp(`(${escapeQuery})`, 'ig');
  } else {
    pattern = separator;
  }

  return string.split(pattern).filter(element => element !== '');
};

export default splitWithoutRemovingSeparator;
