import React from 'react';

import { currentLng } from '../../../i18n';

import styles from './styles/taxiProviders.module.css';

import citymobilEn from '../../../svg/taxi/en/citymobil.svg';
import citymobilRu from '../../../svg/taxi/ru/citymobil.svg';
import gettEn from '../../../svg/taxi/en/gett.svg';
import gettRu from '../../../svg/taxi/ru/gett.svg';
import yandexEn from '../../../svg/taxi/ru/yandex.svg';
import yandexRu from '../../../svg/taxi/en/yandex.svg';

interface ITaxiProvidersProps {
  className: string,
  providers: string[],
}
interface ISVGSItem {
  [citymobil: string]: string,
  gett: string,
  yandex: string,
}

type ISVGSKeys = 'ru' | 'en';

type ISVGS = Record<ISVGSKeys, ISVGSItem>;

const SVGS: ISVGS = {
  en: {
    citymobil: citymobilEn,
    gett: gettEn,
    yandex: yandexEn,
  },
  ru: {
    citymobil: citymobilRu,
    gett: gettRu,
    yandex: yandexRu,
  },
};

const TaxiProviders = ({
  providers,
  className,
}: ITaxiProvidersProps) => {
  const classNames = [styles.wrap];

  if (className) {
    classNames.push(className);
  }

  const renderProviders = () => {
    if (!providers) {
      return null;
    }

    const locale = currentLng as ISVGSKeys;

    return providers.map((provider, i) => (
      <img
        key={ `${provider}_${i}` }
        className={ styles.image }
        src={ SVGS[locale][provider] }
        alt={ provider }
      />
    ));
  };

  return (
    <div className={ classNames.join(' ') }>
      { renderProviders() }
    </div>
  );
};

export default TaxiProviders;
