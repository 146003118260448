import { observable, computed, action, makeObservable } from 'mobx';
import { Moment } from 'moment';

import {
  currentDate,
  getMoment,
  momentObject,
  isBeforeDate,
  nextDateAfterCurrent,
} from '../../../utils/formatDate';

import { ITrainStoreItem } from '../../../types/trains';
import { TrainHistoryItem } from '../../../types/train';

export interface ITrainSearchStore {
  from: IFromTo,
  to: IFromTo,
  travellers: number,
  date: Moment,
  dateBack: string | Moment,
  minDate: Moment,
  minDateBack: Moment,
  immediate: boolean,
  searchId: string,
  historyList: any[],
  firstSearch: boolean,
  isTravelApproval: boolean,
  schemeLoading: boolean,
  textTemplateWarning: string,
  isValid: boolean,
}

interface IFromTo {
  label: string,
  selected: ITrainStoreItem | object,
  suggests: any[],
  loading?: boolean,
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable from: IFromTo = {
    label: '',
    selected: {},
    suggests: [],
  };
  @observable to: IFromTo = {
    label: '',
    selected: {},
    suggests: [],
  };
  @observable travellers: number = 1;
  @observable date = currentDate().clone();
  @observable dateBack: string | Moment = '';
  @observable minDate: Moment = currentDate().clone();
  @observable minDateBack: Moment = currentDate().clone();
  @observable immediate: boolean = false;
  @observable searchId: string = '';
  @observable historyList: TrainHistoryItem[] = [];
  @observable firstSearch: boolean = false;
  @observable isTravelApproval: boolean = false;
  @observable schemeLoading: boolean = false;
  @observable textTemplateWarning: string = '';

  @action
  formatDefaultSuggests = (items: ITrainStoreItem[], selectedItemCode?: string): ITrainStoreItem[] => {
    const clonedItems = [...items];
    const indexToRemove = clonedItems.findIndex(suggest => suggest.Code === selectedItemCode);

    if (indexToRemove === -1) {
      clonedItems.splice(4, 1);
    } else {
      clonedItems.splice(indexToRemove, 1);
    }

    return clonedItems;
  };

  @action
  setFromSuggests = (query: any, items: ITrainStoreItem[], loading: boolean = false) => {
    const suggests = query === '' ? this.formatDefaultSuggests(items, (this.to.selected as ITrainStoreItem).Code) : items;

    this.from = {
      ...this.from,
      label: query,
      selected: {},
      suggests,
      loading,
    };
  };

  @action
  setFromSuggestsLoading = (loading: boolean) => {
    this.from = {
      ...this.from,
      loading,
    };
  };

  @action
  setToSuggestsLoading = (loading: boolean) => {
    this.to = {
      ...this.to,
      loading,
    };
  };

  @action
  setToSuggests = (query: any, items: ITrainStoreItem[], loading: boolean = false) => {
    const suggests = query === '' ? this.formatDefaultSuggests(items, (this.from.selected as ITrainStoreItem).Code) : items;

    this.to = {
      ...this.to,
      label: query,
      selected: {},
      suggests,
      loading,
    };
  };

  @action
  setFromSelected = (item: ITrainStoreItem): void => {
    this.from = {
      ...this.from,
      label: item.Name,
      suggests: [],
      selected: item,
    };
  };

  @action
  setToSelected = (item: ITrainStoreItem): void => {
    this.to = {
      ...this.to,
      label: item.Name,
      suggests: [],
      selected: item,
    };
  };

  @action
  setFromAndTo = (from: IFromTo, to: IFromTo) => {
    this.from = from;
    this.to = to;
  };

  @action
  setFlipFromTo = (): void => {
    const { from, to } = this;

    this.from = {
      ...to,
    };

    this.to = {
      ...from,
    };
  };

  @action
  setNewSearch = (): void => {
    this.from = {
      label: '',
      selected: {},
      suggests: [],
    };

    this.to = {
      label: '',
      selected: {},
      suggests: [],
    };

    this.travellers = 1;
    this.date = currentDate().clone();
    this.dateBack = '';
    this.minDate = currentDate().clone();
    this.minDateBack = currentDate().clone();
    this.immediate = false;
    this.isTravelApproval = false;
  };

  @action
  setSchemeLoading = (value: boolean): void => {
    this.schemeLoading = value;
  };

  @action
  setSearchFromApprovalRequest = ({ Destinations, Employees, StartDate, EndDate }: any): void => {
    const employeesLength = Employees.length;

    this.to = {
      label: Destinations.Name,
      selected: Destinations,
      suggests: [],
    };

    this.travellers = employeesLength > 4 ? 4 : employeesLength;
    this.date = nextDateAfterCurrent(StartDate);
    this.dateBack = momentObject(EndDate);
    this.minDate = currentDate().clone();
    this.minDateBack = currentDate().clone();
    this.immediate = false;
    this.isTravelApproval = true;
  };

  @action
  setDate = (value: Moment): void => {
    const newDateBack = this.dateBack && isBeforeDate(this.dateBack, value) ? value.clone().add(1, 'd') : this.dateBack;

    this.date = value;
    this.dateBack = newDateBack;
    this.minDateBack = value ? value.clone() : currentDate().clone();
  };

  @action
  setDateBack = (value: string): void => {
    this.dateBack = value || '';
  };

  @action
  setTravellers = (value: number): void => {
    this.travellers = value;
  };

  @action
  setHistoryInstance = ({ StationFrom, StationTo, Travellers, DepartureDate }: TrainHistoryItem, searchId: string): void => {
    this.date = momentObject(DepartureDate);
    this.minDate = getMoment().startOf('d');
    this.minDateBack = this.date.clone();

    this.from = {
      ...this.from,
      label: StationFrom.City,
      selected: StationFrom,
      suggests: [],
    };

    this.to = {
      ...this.to,
      label: StationTo.City,
      selected: StationTo,
      suggests: [],
    };

    this.travellers = Travellers;
    this.searchId = searchId;
  };

  @action
  setImmediateSearch = (value: boolean): void => {
    this.immediate = value;
  };

  @action
  setFirstSearch = (value: boolean): void => {
    this.firstSearch = value;
  };

  @action
  setHistoryList = (list: TrainHistoryItem[]): void => {
    this.historyList = list;
  };

  @action
  setWarningTemplate = (warningMessage: { content: { text: string } }): void => {
    this.textTemplateWarning = warningMessage?.content?.text || '';
  };

  @computed
  get isValid(): boolean | '' {
    const isValidRoutes = this.from.selected && (this.from.selected as ITrainStoreItem).Code && this.to.selected && (this.to.selected as ITrainStoreItem).Code;
    const isValidDate = this.date && this.date.isValid() && this.date.isSameOrAfter(this.minDate);
    const isValidDateBack = this.dateBack ? (this.dateBack as Moment).isValid() && (this.dateBack as Moment).isSameOrAfter(this.minDateBack) : true;

    return isValidRoutes && isValidDate && isValidDateBack;
  }
}

const TrainSearchStore = new Store();

export { TrainSearchStore };
