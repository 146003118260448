import React from 'react';

import { AirlineMainMenu } from './components/MainMenu';
import { AirlineSubMenu } from './components/SubMenu';

import { MainAnalytic } from '../../../bi/utils/analytics';

import { FIELDS_SEARCH_MENU } from '../../../bi/constants/airline';

import { IChildData } from '../../../bi/types/airline';
import { IAirlineSearchMenuProps } from './types';

const AirlineSearchMenu = ({
  subMenu = false,
  handleReturnToSearch = () => {},
  airlineService,
  requestsService,
  onSearchSubMenu,
  onSearch,
  featureFlagsService,
  qaAttrs = null,
  qaAttrsSubMenu = null,
}: IAirlineSearchMenuProps) => {
  const handleChangeAdult = (value: number) =>
    airlineService.setSearchValue(FIELDS_SEARCH_MENU.ADULT, value);

  const handleChangeChild = (data: IChildData) => {
    airlineService.setSearchValue(FIELDS_SEARCH_MENU.ADULT_CHILD, data);
  };

  const handleChangeFlightClass = (value: string) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.AIRLINE.SEARCH_AIR_CLASS_CHOSEN, {
      type: value,
    });

    return airlineService.setSearchValue(FIELDS_SEARCH_MENU.FLIGHT_CLASS, value);
  };

  const renderSubMenu = () => (
    <AirlineSubMenu
      requestsService={ requestsService }
      airlineService={ airlineService }
      featureFlagsService={ featureFlagsService }
      onSearch={ onSearchSubMenu }
      handleReturnToSearch={ handleReturnToSearch }
      handleChangeAdult={ handleChangeAdult }
      handleChangeChild={ handleChangeChild }
      handleChangeFlightClass={ handleChangeFlightClass }
      qaAttrs={ qaAttrsSubMenu }
    />
  );

  const renderPanelMenu = () => (
    <AirlineMainMenu
      airlineService={ airlineService }
      handleChangeAdult={ handleChangeAdult }
      handleChangeChild={ handleChangeChild }
      handleChangeFlightClass={ handleChangeFlightClass }
      featureFlagsService={ featureFlagsService }
      onSearch={ onSearch }
      qaAttrs={ qaAttrs }
    />
  );

  return subMenu
    ? renderSubMenu()
    : renderPanelMenu();
};

export default AirlineSearchMenu;
