import { getText } from '../../../i18n';

const PAYMENT = {
  EXITMESSAGE: getText('constants:payment.exitMessage'),
};

const PAYMENT_TYPE = {
  PREPAYMENT: 'Prepayment',
  PAYMENT_CLOSING: 'PaymentClosing',
  POST_PAYMENT: 'PostPayment',
};

const DEBT_TYPE = {
  DEBT: 'Debt',
  ZERO_BALANCE: 'Zero',
  OVERPAYMENT: 'Overpayment',
  BLOCK: 'Block',
};

export {
  PAYMENT,
  PAYMENT_TYPE,
  DEBT_TYPE,
};
