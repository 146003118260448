import React from 'react';
import { Text } from 'new-ui';

import styles from '../styles/intercom.module.css';

const WarningIntercom = () => (
  <div>
    <Text type='NORMAL_18'>
      Временно не работает чат тревел-помощник из-за сбоя у партнёра. Технические специалисты приложения и наши разработчики делают все возможное, чтобы восстановить сервис.
    </Text>
    <Text type='NORMAL_18' className={ styles.text }>
      Приносим извинения за неудобства. Если у вас срочный вопрос, позвоните нам, пожалуйста, по номеру 8 (800) 775 1428.
    </Text>
  </div>
);

export { WarningIntercom };
