import React, { ReactNode } from 'react';
import { BackgroundMap } from '../BackgroundMap';

import styles from './styles/index.module.css';

interface SearchProps {
  children: ReactNode,
  withPlanes?: boolean,
  withoutMargin?: boolean,
  positionStatic?: boolean,
}

const Search = ({
  children,
  withPlanes = false,
  withoutMargin = false,
  positionStatic = false,
}: SearchProps) => {
  const classNames = [styles['search-wrapper']];

  if (positionStatic) {
    classNames.push(styles.static);
  }

  return (
    <div id='search-box' className={ classNames.join(' ') }>
      <BackgroundMap withPlanes={ withPlanes } withoutMargin={ withoutMargin } />
      <div style={ { minHeight: 'inherit' } }>
        {children}
      </div>
    </div>
  );
};

export default Search;
