import React from 'react';
import { Button, Dialog, Text } from 'new-ui';

import { getText } from '../../../i18n';

import styles from './styles/index.module.css';

const LABELS = {
  CONFIRM_ACTION: getText('cart:normalCart.confirmAction'),
  YES: getText('common:yes'),
  NO: getText('common:no'),
};

export interface IConfirmDialog {
  visible?: boolean,
  title?: any,
  description: any,
  onChangeVisible(value: boolean): void,
  onClick?(): void,
  onClose?(): void,
  visibleAction: boolean,
}

const ConfirmDialog = ({
  visible = false,
  title,
  description,
  onChangeVisible,
  onClick = () => {},
  onClose = () => {},
  visibleAction = false,
}: IConfirmDialog) => {
  const titleHtml = typeof title === 'function'
    ? title()
    : title && (<Text type='bold_20' className={ styles.title }> { title } </Text>);

  const descriptionHtml = typeof description === 'function'
    ? description()
    : description && (<Text className={ styles.text } type='NORMAL_14_130'>
      {description}
    </Text>);

  return (
    <Dialog
      showClosing
      show={ visible }
      onChange={ onChangeVisible }
    >
      <div className={ styles['dialog-content'] }>
        { titleHtml}

        {descriptionHtml}

        {visibleAction &&
        <div className={ styles.actions }>
          <Button
            type='primary'
            onClick={ onClick }
          >
            { LABELS.YES }
          </Button>
          <Button
            className={ styles.cancel }
            type='textual-medium'
            onClick={ onClose }
          >
            { LABELS.NO }
          </Button>
        </div> }
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
