import React, { useEffect, useState } from 'react';
import { Price, Progress, Text } from 'new-ui';
import { Moment } from 'moment';
import clsx from 'clsx';

import { getText } from '../../../../../i18n';

import { DEBT_TYPE } from '../../../../bi/constants/payment';
import { PATTERN } from '../../../../bi/constants/dateFormats';

import { dateUtcFormat } from '../../../../bi/utils/formatDate';

import styles from '../../styles/index.module.css';

const LABELS = {
  ACCESS: getText('smartdesk:smartagentMenu.balance.access'),
  DEBT: getText('smartdesk:smartagentMenu.balance.debt'),
  LIMIT: getText('smartdesk:smartagentMenu.balance.limit'),
  OVER_PAYMENT: getText('smartdesk:smartagentMenu.balance.overPayment'),
  NEXT_DATE: getText('smartdesk:smartagentMenu.balance.nextDate'),
  LATER_PAY: getText('smartdesk:smartagentMenu.balance.laterPay'),
  BLOCK: getText('smartdesk:smartagentMenu.balance.block'),
  GENERAL: getText('smartdesk:smartagentMenu.balance.general'),
  EXPIRED: getText('smartdesk:smartagentMenu.balance.expired'),
};

const TYPE_TITLES = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
};

interface IPrepaymentClosing {
  hasDebt: boolean,
  totalLimit: number,
  bookingAmount: number,
  nextPaymentAmount: number,
  nextPaymentDate: Moment | null,
  debt: number,
}

const PrepaymentClosing = ({
  hasDebt,
  totalLimit,
  bookingAmount,
  nextPaymentAmount,
  nextPaymentDate,
  debt = 0,
}: IPrepaymentClosing) => {
  const [debtType, setDebtType] = useState('');

  useEffect(() => {
    if (debt < 0) {
      return setDebtType(DEBT_TYPE.OVERPAYMENT);
    }

    if (debt === 0) {
      return setDebtType(DEBT_TYPE.ZERO_BALANCE);
    }

    if (hasDebt) {
      return setDebtType(DEBT_TYPE.BLOCK);
    }

    return setDebtType(DEBT_TYPE.DEBT);
  }, []);

  const overdraftClassname = debtType === DEBT_TYPE.OVERPAYMENT ? styles.wrapper_title : '';
  const debtClassname = debtType === DEBT_TYPE.DEBT || debtType === DEBT_TYPE.BLOCK ? styles.wrapper_title_three : '';
  const wrapperLabel = debtType === DEBT_TYPE.OVERPAYMENT ? styles.wrapper_label : '';

  const bookingDebt = bookingAmount < 0 ? 0 : bookingAmount;
  const bookingColor = !bookingAmount || hasDebt ? 'red' : 'green';
  const bookingColorTwo = !nextPaymentAmount ? bookingColor : 'red';
  const labelsPayment = debtType === DEBT_TYPE.DEBT || debtType === DEBT_TYPE.BLOCK ? LABELS.DEBT : LABELS.OVER_PAYMENT;
  const paymentTwo = debtType !== DEBT_TYPE.DEBT ? TYPE_TITLES.ONE : nextPaymentAmount / debt;

  const selectClassnames = (num: number) => {
    switch (num) {
      case TYPE_TITLES.ONE:
        return styles.width_overdraft;
      case TYPE_TITLES.TWO:
        return styles.overdraft;
      default:
        return styles.width_debt;
    }
  };

  const renderPrice = (val: number) => (
    <Price
      marginSmall
      typeCurrency='bold_16'
      type='bold_16'
      value={ Math.abs(val) }
    />
  );

  const renderTitle = (val: number, label: string, isDebt: boolean = false, isThree: boolean = false) => {
    if (isDebt) {
      return null;
    }

    const overStyles = !isThree ? `${styles.text} ${styles.text_w}` : `${styles.text}`;

    const renderTitlePrice = (value: number) => {
      if (debtType === DEBT_TYPE.DEBT || debtType === DEBT_TYPE.BLOCK) {
        return null;
      }

      return renderPrice(value);
    };

    return (
      <div className={ overStyles }>
        <Text type='bold_16'>
          { label }
        </Text>
        { renderTitlePrice(val) }
      </div>
    );
  };

  const renderTable = (
    val: number,
    col: string,
    isDebt: boolean = false,
    order: number,
  ) => {
    if (isDebt) {
      return null;
    }

    return (
      <div className={ selectClassnames(order) }>
        <Progress
          isBig
          rounded
          value={ val }
          color={ col }
        />
      </div>
    );
  };

  const renderDebtInfo = (price: number, date: Moment | null = null, type: number) => {
    if (debtType !== DEBT_TYPE.DEBT && debtType !== DEBT_TYPE.BLOCK) {
      return null;
    }

    const renderDate = () => {
      if (!date) {
        return null;
      }

      return (
        <>
          { LABELS.NEXT_DATE }
          { dateUtcFormat(date, PATTERN.DAY_OF_MONTH_LONG) }
        </>
      );
    };

    const wrapper = clsx(
      styles.debt_info,
      styles.double_debt,
    );

    const renderSubtitle = () => {
      const hasDebtSubtitle = (hasDebt ? LABELS.EXPIRED : LABELS.LATER_PAY);
      const subtitle = () => {
        if (hasDebt && type === TYPE_TITLES.TWO) {
          return LABELS.GENERAL;
        }

        return (type === TYPE_TITLES.THREE ? hasDebtSubtitle : renderDate());
      };

      return (
        <Text type='NORMAL_12' color='gray'>
          { subtitle() }
        </Text>
      );
    };

    if (hasDebt && type === TYPE_TITLES.ONE) {
      return (
        <div className={ wrapper }>
          <Text type='NORMAL_12' color='gray'>
            { LABELS.BLOCK }
          </Text>
        </div>
      );
    }

    return (
      <div className={ wrapper }>
        { renderSubtitle() }
        { renderPrice(price) }
      </div>
    );
  };

  const renderThreeTable = () => {
    if (debtType !== DEBT_TYPE.DEBT && debtType !== DEBT_TYPE.BLOCK) {
      return null;
    }

    const color = debtType === DEBT_TYPE.DEBT ? 'yellow' : 'red';

    return renderTable((debt - nextPaymentAmount) / debt, color, debtType === DEBT_TYPE.ZERO_BALANCE, TYPE_TITLES.ONE);
  };

  const tableDebt = (value: number) => (debtType !== DEBT_TYPE.DEBT ? TYPE_TITLES.ONE : value);

  return (
    <div className={ styles.value }>
      <div className={ overdraftClassname || debtClassname }>
        { renderTitle(bookingAmount, LABELS.ACCESS) }
        { renderTitle(debt, labelsPayment, debtType === DEBT_TYPE.ZERO_BALANCE) }
      </div>
      <div className={ overdraftClassname || debtClassname }>
        { renderDebtInfo(bookingDebt, null, TYPE_TITLES.ONE) }
        { renderDebtInfo(nextPaymentAmount, nextPaymentDate, TYPE_TITLES.TWO) }
        { renderDebtInfo(debt - nextPaymentAmount, null, TYPE_TITLES.THREE) }
      </div>
      <div className={ wrapperLabel || debtClassname }>
        { renderTable(tableDebt(bookingDebt / totalLimit), bookingColor, false, TYPE_TITLES.ONE) }
        { renderTable(tableDebt(paymentTwo), bookingColorTwo, debtType === DEBT_TYPE.ZERO_BALANCE, TYPE_TITLES.TWO) }
        { renderThreeTable() }
      </div>
    </div>
  );
};

export default PrepaymentClosing;
