import { AirRoute } from '../types/airline';

const getAirlineSameCompany = (routes: AirRoute[]) => {
  const airlineCompanyFirstSegment =
    routes[0].Segments[0].MarketingAirline
      ? routes[0].Segments[0].MarketingAirline.Name
      : routes[0].Segments[0].Airline.Name;

  const isSameAirlineCompany = routes.every(({ Segments }) => Segments.every(({ MarketingAirline, Airline }) => {
    const name = MarketingAirline ? MarketingAirline.Name : Airline.Name;

    return name === airlineCompanyFirstSegment;
  }));

  return isSameAirlineCompany ? airlineCompanyFirstSegment : null;
};

export default getAirlineSameCompany;
