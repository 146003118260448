import React, { Component } from 'react';
import { Text, PageLoader } from 'new-ui';
import { getText } from '../../../../i18n';

import { Row } from './row';
import { SendInvoice } from './dialogs/SendInvoice/SendInvoice';

import FORMATS from '../../../bi/constants/formats';

import { IOperationsList, PaymentType, FormatType } from '../../../bi/types/report';
import { SendArgs } from '../../../bi/types/notification';

import styles from '../styles/table.module.css';

const LABELS = {
  DATE: getText('components:operationsTable.table.date'),
  NAME: getText('components:operationsTable.table.operation'),
  REFILL: getText('components:operationsTable.table.refill'),
  AMOUNT: getText('components:operationsTable.table.amount'),
  DOCUMENT: getText('components:operationsTable.table.document'),
  EMPTY_LIST: getText('components:operationsTable.table.emptyList'),
  SENT_ON_EMAIL_NOTIFICATION: (email: string) => getText('components:operationsTable.table.sentOnEmailNotification', { email }),
};

interface TableProps {
  items: IOperationsList[];
  loading: boolean;
  currentCompany: number;
  reportService: {
    downloadInvoice: (currentCompany: number, operation: IOperationsList, format: FormatType) => void;
    downloadPenalty: (currentCompany: number, id: number, format: FormatType) => void;
    sendInvoice: (
      invoiceData: {
        companyId: number,
        invoiceNumber: number | null,
        email: string,
        format: FormatType
      }
    ) => Promise<void>;
  };
  sendNotification: (item: SendArgs) => void;
  renderPenaltyBySchema: boolean,
}

class Table extends Component<TableProps> {
  state = {
    showEmailDialog: false,
    invoiceNumber: null,
    email: '',
    format: FORMATS.PDF,
    sendLoading: false,
  };

  handleDownload = (operation: IOperationsList, format: FormatType, type: PaymentType) => {
    const { reportService: { downloadInvoice, downloadPenalty }, currentCompany } = this.props;
    const downloadFns = {
      invoice: () => downloadInvoice(currentCompany, operation, format),
      penalty: () => downloadPenalty(currentCompany, operation.Id, format),
    };

    const fn = downloadFns[type];
    fn();
  };

  handleOpenSendInvoiceDialog = (invoiceNumber: string) => this.setState({ showEmailDialog: true, invoiceNumber });

  handleCloseSendInvoiceDialog = () => this.setState({ showEmailDialog: false, invoiceNumber: null });

  handleSendEmailByAddress = () => {
    const { reportService, sendNotification, currentCompany } = this.props;
    const { email, format, invoiceNumber } = this.state;

    this.setState({
      sendLoading: true,
    });

    reportService.sendInvoice({ companyId: currentCompany, invoiceNumber, email, format }).then(() => {
      sendNotification({
        message: LABELS.SENT_ON_EMAIL_NOTIFICATION(email),
        level: 'success',
      });
    }).finally(() => {
      this.setState({
        showEmailDialog: false,
        invoiceNumber: null,
        email: '',
        sendLoading: false,
      });
    });
  };

  handleChangeEmail = (value: string) => {
    this.setState({
      email: value,
    });
  };

  handleChangeFormat = (format: FormatType) => {
    this.setState({
      format,
    });
  };

  renderRows = () => {
    const { items, renderPenaltyBySchema } = this.props;

    if (!items.length) {
      return <div className={ styles.empty_wrap }>{LABELS.EMPTY_LIST}</div>;
    }

    return items.map((item: IOperationsList) => (
      <Row
        key={ item.Id }
        item={ item }
        onDownload={ this.handleDownload }
        onOpenSendInvoiceDialog={ this.handleOpenSendInvoiceDialog }
        renderPenaltyBySchema={ renderPenaltyBySchema }
      />
    ));
  };

  renderContent = () => {
    const { loading } = this.props;

    if (loading) {
      return (
        <div className={ styles.empty_wrap }>
          <PageLoader/>
        </div>
      );
    }

    return this.renderRows();
  };

  render() {
    const { showEmailDialog, email, format, sendLoading } = this.state;
    const contentHtml = this.renderContent();

    const dialogHtml = showEmailDialog && (
      <SendInvoice
        onChangeInput={ this.handleChangeEmail }
        onChangeFormat={ this.handleChangeFormat }
        onSendEmail={ this.handleSendEmailByAddress }
        onClose={ this.handleCloseSendInvoiceDialog }
        email={ email }
        format={ format }
        show={ showEmailDialog }
        sendLoading={ sendLoading }
      />
    );

    return (
      <div className={ styles.wrap }>
        <div className={ styles.header }>
          <Text className={ styles.date }>{LABELS.DATE}</Text>
          <Text className={ styles.name }>{LABELS.NAME}</Text>
          <Text className={ styles.refill }>{LABELS.REFILL}</Text>
          <Text className={ styles.amount }>{LABELS.AMOUNT}</Text>
          <Text className={ styles.document }>{LABELS.DOCUMENT}</Text>
        </div>
        { contentHtml }
        { dialogHtml }
      </div>
    );
  }
}

export { Table };
