import React, { useState, useCallback, useEffect } from 'react';
import { E164Number, getExampleNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
import { Input, Select, Text } from 'new-ui';
// eslint-disable-next-line import/no-unresolved
import flagIcons from 'country-flag-icons/3x2/flags.css?inline';
import phoneExamples from 'libphonenumber-js/examples.mobile.json';

import { countriesList } from './countries';

import { formattedPhoneNumber, preparedPhoneNumber } from '../../bi/utils/phoneNumber';

import { NUMBER_LENGTH } from '../../bi/constants/regExp';

import { IPhoneNumberCountries, ISelectItem } from '../../bi/types/shared';

import styles from './styles/index.module.css';

interface InputPhoneProps {
  value: string,
  onChange(numValue: E164Number | string): void,
  onFocus?(): void,
  onBlur(isValid: boolean): void,
  className?: string,
  error?: string,
  debounceMs?: number,
  qaAttr?: string,
  qaAttrPrefix?: string,
}

const LABELS = {
  CODE: 'UZ',
};

const InputPhone = ({
  value,
  onFocus,
  className = '',
  onBlur,
  onChange,
  error = '',
  debounceMs = 300,
  qaAttr = '',
  qaAttrPrefix = '',
}: InputPhoneProps) => {
  const { prepareRegion, prepareNum } = preparedPhoneNumber(value);

  const [region, setRegion] = useState(prepareRegion);
  const [num, setNum] = useState(formattedPhoneNumber(prepareNum, prepareRegion));

  useEffect(() => {
    const { prepareRegion: newPrepareRegion, prepareNum: newPrepareNum } = preparedPhoneNumber(value);

    setRegion(newPrepareRegion);
    setNum(formattedPhoneNumber(newPrepareNum, newPrepareRegion));
  }, [value]);

  const handleOnChange = useCallback((curNum: string, curRegion: any) => {
    const numValue = parsePhoneNumberFromString(curNum, curRegion)?.number || '';
    onChange(numValue);
  }, [onChange]);

  const handleNumChange = useCallback((val: string) => {
    setNum(val);
    handleOnChange(val, region);
  }, [handleOnChange, region]);

  const handleRegionChange = useCallback((val: string) => {
    setRegion(val);
    handleOnChange(num, val);
  }, [handleOnChange, num]);

  const handleBlur = useCallback(() => {
    // @ts-ignore
    const parsedPhoneNumber = parsePhoneNumberFromString(num, region);
    const checkUzb = region === LABELS.CODE ? NUMBER_LENGTH.test(num) : parsedPhoneNumber?.isValid();
    const isValid = checkUzb || false; // TODO: поменять обратно, когда обновится либа

    if (isValid) {
      // @ts-ignore
      setNum(formattedPhoneNumber(parsedPhoneNumber.nationalNumber, region));
    }

    onBlur(isValid);
  }, [num, onBlur, region]);

  const renderSelectItem = ({ label, name, value: itemValue }: IPhoneNumberCountries) => {
    // @ts-ignore
    const icons = flagIcons[`flag:${itemValue}`];

    return (
      <div className={ styles.item }>
        <span className={ icons }/>
        <Text className={ styles.label } type='NORMAL_14'>{ name }</Text>
        <Text color='gray' type='NORMAL_14' className={ styles.code }>{ label }</Text>
      </div>
    );
  };

  const renderSelectLabel = ({ label, value: itemValue }: ISelectItem) => {
    // @ts-ignore
    const icons = flagIcons[`flag:${itemValue}`];

    return (
      <div className={ styles.item }>
        <span className={ icons }/>
        <Text className={ styles.label }>{ label }</Text>
      </div>
    );
  };

  const selectContent = (
    <Select
      tabIndex={ -1 }
      className={ styles.select }
      theme='gray'
      onChange={ handleRegionChange }
      items={ countriesList }
      value={ region }
      renderItem={ renderSelectItem }
      renderLabel={ renderSelectLabel }
      qaAttr={ qaAttrPrefix }
    />
  );

  const inputContent = () => {
    // @ts-ignore
    const { nationalNumber: placeholder }: any = getExampleNumber(region, phoneExamples);

    return (
      <Input
        className={ styles.input }
        onChange={ handleNumChange }
        placeholder={ placeholder }
        value={ num }
        debounceMs={ debounceMs }
        qaAttr={ qaAttr }
      />
    );
  };

  const errorContent = error && (
    <div className={ styles.error }>
      <Text type='NORMAL_10' className={ styles.text }>{ error }</Text>
    </div>
  );

  const wrapClassNames = [styles.container];

  if (className) {
    wrapClassNames.push(className);
  }

  if (error) {
    wrapClassNames.push(styles['has-error']);
  }

  return (
    <div
      className={ wrapClassNames.join(' ') }
      onFocus={ onFocus }
      onBlur={ handleBlur }
    >
      { selectContent }
      { inputContent() }
      { errorContent }
    </div>
  );
};

export { InputPhone };
