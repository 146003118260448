import React, { useEffect, useState } from 'react';
import { Checkbox, Text, Textarea } from 'new-ui';
import { getText } from '../../../../../i18n';

import parseJsonString from '../../../../bi/utils/parseJsonString';
import MoneyFormat from '../../../../bi/utils/money';
import { textualMonthWithHoursAndMinutesPattern } from '../../../../bi/utils/formatDate';
import { isSmartAgent } from '../../../../bi/utils/env';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { TRAIN_TARIFFS } from '../../../../bi/constants/train';
import { ERRORS_TYPES } from '../../../../bi/constants/trips';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { ButtonTrip } from '../ButtonTrip';

import { ITripItem } from '../../../../bi/types/trips';

import OrderService from '../../../../bi/services/order';

import { FreeTaxi } from './components/FreeTaxi/FreeTaxi';
import { ErrorDialog } from './components/ErrorDialog/ErrorDialog';

import { Loading } from './components/Loading/Loading';

import styles from './index.module.css';

const LABELS = {
  CONFIRM_CANCELLATION: {
    APPROVE: getText('components:tripDetailsDialog.automaticCancelTrip.confirmCancellation.approve'),
    AGREE: getText('components:tripDetailsDialog.automaticCancelTrip.confirmCancellation.agree'),
  },
  CLOSE_DIALOG: getText('components:tripDetailsDialog.automaticCancelTrip.close'),
  CANCEL_BOTH_TICKETS: getText('components:tripDetailsDialog.automaticCancelTrip.cancelBothTickets'),
  CANCEL_COUPE: getText('components:tripDetailsDialog.automaticCancelTrip.cancelCoupe'),
  CANCEL_KUPEK: getText('components:tripDetailsDialog.automaticCancelTrip.cancelKupek'),
  CANCEL: getText('components:tripDetailsDialog.automaticCancelTrip.cancel'),
  FINAL_STEP: (value: string) =>
    getText('components:tripDetailsDialog.automaticCancelTrip.finalStep', { value }),
  WARNING: (value: string) => getText('components:tripDetailsDialog.automaticCancelTrip.warning', { value }),
  MULTI_WARNING: getText('components:tripDetailsDialog.automaticCancelTrip.multiWarning'),
  FULL_COST: {
    FIRST: getText('components:tripDetailsDialog.automaticCancelTrip.fullCost.first'),
    SECOND: getText('components:tripDetailsDialog.automaticCancelTrip.fullCost.second'),
  },
  FREE_CANCELLED: {
    FIRST: getText('components:tripDetailsDialog.automaticCancelTrip.freeCancelled.first'),
    SECOND: getText('components:tripDetailsDialog.automaticCancelTrip.freeCancelled.cancelled'),
  },
  TICKETS_WILL_BE_CANCELLED: getText('components:tripDetailsDialog.automaticCancelTrip.ticketsWillBeCancelled'),
  TICKET_WILL_BE_CANCELLED: getText('components:tripDetailsDialog.automaticCancelTrip.ticketWillBeCancelled'),
  SERVICE_WILL_BE_CANCELLED: getText('components:tripDetailsDialog.automaticCancelTrip.serviceWillBeCancelled'),
  OF_TICKETS: getText('components:tripDetailsDialog.automaticCancelTrip.ofTickets'),
  OF_TICKET: getText('components:tripDetailsDialog.automaticCancelTrip.ofTicket'),
  THIS_TICKETS: getText('components:tripDetailsDialog.automaticCancelTrip.thisTicket'),
  THIS_TICKET: getText('components:tripDetailsDialog.automaticCancelTrip.thisTickets'),
  REFUND: (value: string) => getText('components:tripDetailsDialog.automaticCancelTrip.refund', { value }),
  REFUND_SMARTAGENT: (value: string) =>
    getText('components:tripDetailsDialog.automaticCancelTrip.refundSmartagent', { value }),
  REFUND_PENALTY: (value: string) =>
    getText('components:tripDetailsDialog.automaticCancelTrip.refundPenalty', { value }),
  REFUND_PENALTY_SMARTAGENT: (value: string) =>
    getText('components:tripDetailsDialog.automaticCancelTrip.refundPenaltySmartagent', { value }),
  REFUND_REMAINDER: (value: string) =>
    getText('components:tripDetailsDialog.automaticCancelTrip.refundRemainder', { value }),
  PENALTY: getText('components:tripDetailsDialog.automaticCancelTrip.penalty'),
  FREE_PENALTY: {
    FIRST: getText('components:tripDetailsDialog.automaticCancelTrip.freePenalty.first'),
    SECOND: getText('components:tripDetailsDialog.automaticCancelTrip.freePenalty.second'),
  },
  CURRENCY: '₽',
  BOOKING_WILL_BE_CANCELLED: getText('components:tripDetailsDialog.automaticCancelTrip.bookingWillBeCancelled'),
  LOADING: getText('components:tripDetailsDialog.automaticCancelTrip.loading'),
  LOADING_CANCELLATION: getText('components:tripDetailsDialog.automaticCancelTrip.loadingCancellation'),
  SOON_ANSWER: getText('components:tripDetailsDialog.cancelDialog.soonAnswerDate'),
  SOON_ANSWER_ERROR: getText('components:tripDetailsDialog.cancelDialog.soonAnswerError'),
  HEADER: {
    HOTEL: getText('components:tripDetailsDialog.cancelDialog.header.hotel'),
    BUS: getText('components:busItem.cancellation'),
  },
  CONTENT: getText('components:tripDetailsDialog.automaticCancelTrip.content'),
};

interface IAutomaticCancelTripProps {
  item: ITripItem;
  orderService:OrderService;
  hasDisabled: boolean;
  isHotel?: boolean;
  isAir?: boolean;
  isTaxiVoucher?: boolean;
  isVipHall?: boolean;
  isTransfer?: boolean;
  isChatLoading?: boolean;
  isBus?: boolean;
  voidTimeLimit?: any;
  multipleTickets?: boolean;
  showErrorDialog: boolean;
  loadingCancellation: boolean;
  errorMsg: string;
  hidePreCancelActions?: boolean;
  labelError?: string;
  onCloseDialog(): void;
  onSubmitCancellationModal(value: string): void;
  onCancellationBack?(): void;
  onRequestAutomaticCancellation?(): void;
  onCancelAutomaticCancellation?(): void;
  onCancelTrip(): any;
  toggleErrorDialog(): void;
}

const AutomaticCancelTrip = ({
  onCancellationBack = () => {},
  onRequestAutomaticCancellation = () => {},
  onCancelAutomaticCancellation = () => {},
  isHotel = false,
  isAir = false,
  isTaxiVoucher = false,
  isVipHall = false,
  isTransfer = false,
  isChatLoading = false,
  isBus = false,
  voidTimeLimit = null,
  multipleTickets = false,
  hidePreCancelActions = false,
  labelError = '',
  item,
  orderService,
  hasDisabled,
  showErrorDialog,
  loadingCancellation,
  errorMsg,
  onCloseDialog,
  onSubmitCancellationModal,
  onCancelTrip,
  toggleErrorDialog,
}: IAutomaticCancelTripProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [agreement, setAgreement] = useState<boolean>(false);
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [refund, setRefund] = useState<number | null>(null);
  const [penalty, setPenalty] = useState<number | null>(null);
  const [errorCancel, setErrorCancel] = useState<any>(null);
  const [success, setSuccess] = useState<boolean>(true);
  const [version, setVersion] = useState<any>({});
  const [tickets, setTickets] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState<string>('');

  const getRefund = (companyId: number, tripItemId: number, serviceType: string) => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let method = (ci: number, ti: number): any => {};

    switch (serviceType) {
      case SERVICETYPE.AEROEXPRESS: {
        // @ts-ignore
        method = (ci: number, ti: number) => orderService.getRefundAeroexpress(ti);
        break;
      }
      case SERVICETYPE.TRAIN: {
        method = orderService.getRefundTrain;
        break;
      }
      case SERVICETYPE.HOTEL: {
        // @ts-ignore
        method = (ci: number, ti: number) => orderService.getRefundHotel(ti);
        break;
      }
      case SERVICETYPE.AIR: {
        const { refundAir: { TotalPenaltySum, TotalRefundSum } } = orderService.get();

        setLoading(false);
        setPenalty(TotalPenaltySum);
        setRefund(TotalRefundSum);

        return;
      }
      case SERVICETYPE.TAXI_VOUCHER: {
        // @ts-ignore
        method = (ci: number, ti: number) => orderService.getRefundTaxi(ti);

        break;
      }
      case SERVICETYPE.VIP_HALL: {
        const { refundVipHall: { Penalty } } = orderService.get();

        setLoading(false);
        setPenalty(Penalty);
        setRefund(0);

        return;
      }
      case SERVICETYPE.TRANSFER: {
        const { refundTransfer: { Penalty, Success } } = orderService.get();

        setLoading(false);
        setPenalty(Penalty);
        setSuccess(Success);
        setRefund(0);

        return;
      }
      case SERVICETYPE.BUS: {
        setLoading(false);
        setPenalty(0);
        setSuccess(false);
        setRefund(0);

        return;
      }
    }

    // @ts-ignore
    // eslint-disable-next-line consistent-return
    return method(companyId, tripItemId).then((res) => {
      if (res.Result && serviceType === SERVICETYPE.AEROEXPRESS) {
        setLoading(false);
        setPenalty(res.RefundSum);
        setRefund(0);

        return;
      }

      if (serviceType === SERVICETYPE.HOTEL) {
        setLoading(false);
        setPenalty(res.Penalty);
        setRefund(res.Refund);
        setSuccess(res.Success);
        setErrorCancel(res.Error);

        return;
      }

      if (serviceType === SERVICETYPE.TAXI_VOUCHER) {
        setLoading(false);
        setPenalty(0);
        setRefund(res.Amount);

        return;
      }

      if (res.Result) {
        setLoading(false);
        setPenalty(res.PenaltySum);
        setRefund(res.RefundSum);
        setTickets(res.Tickets || []);

        return;
      }

      setLoading(false);
      setError(res.Error);
    });
  };

  useEffect(() => {
    const { CompanyId, TripItemId, ServiceType } = item.ActualVersion;

    setVersion(item.ActualVersion);

    getRefund(CompanyId, TripItemId, ServiceType);
  }, []);

  const getRuleText = () => {
    if (isHotel) {
      return LABELS.BOOKING_WILL_BE_CANCELLED;
    }

    if (isVipHall || isTransfer) {
      return LABELS.SERVICE_WILL_BE_CANCELLED;
    }

    return LABELS.TICKET_WILL_BE_CANCELLED;
  };

  const handleChangeCheckbox = (value: boolean) => {
    setAgreement(value);

    if (!value) {
      setShowCancel(false);
    }
  };

  const handleShowCancelTrip = () => {
    setShowCancel(true);

    onRequestAutomaticCancellation();
  };

  const handleConfirmDialog = () => onSubmitCancellationModal(inputValue);

  const handleChange = (value: string) => setInputValue(value);

  // обертка над функцией отмены ваучера для отлавливания ошибки при отмене аэроэкспресса
  const handleCancelTripDiolog = async () => {
    const result = await onCancelTrip();

    // @ts-ignore
    if (result?.Error) {
      setLoading(false);
      setError(result.Error);
    }
  };

  // const renderLoading = (label: string) => (
  //   <div className={ styles.loading_wrap }>
  //     <Text type='NORMAL_16_140'>{ label }</Text>
  //     <div className={ styles.loader }>
  //       <DotLoading />
  //     </div>
  //   </div>
  // );

  const renderCancel = () => (
    <>
      <Text className={ styles.warning } type='NORMAL_16_140' color='red'>
        {LABELS.FINAL_STEP(getRuleText())}
      </Text>
      <ButtonTrip
        loading={ loadingCancellation }
        hasDisabled={ hasDisabled }
        isDoubleCancelTrain
        isAir={ isAir }
        disabledTrain={ !agreement }
        onCloseDialog={ onCloseDialog }
        onSubmitCancellationModal={ handleCancelTripDiolog }
        onCancel={ onCancelAutomaticCancellation }
      />
      <ErrorDialog
        showErrorDialog={ showErrorDialog }
        errorMsg={ errorMsg }
        toggleErrorDialog={ toggleErrorDialog }
      />
    </>
  );
  const renderPenalty = () => {
    const { ServiceType } = version;

    const theseTicketsString = multipleTickets
      ? LABELS.THIS_TICKETS
      : LABELS.THIS_TICKET;

    if (!penalty && isHotel) {
      return (
        <Text type='NORMAL_16_140'>
          { LABELS.FREE_PENALTY.FIRST }
          &nbsp;
          <span
            style={ { fontWeight: 'bold' } }
            data-qa={ QA_ATTRIBUTES.trips.trip.cancelDialog.freeCancellation }
          >
            { LABELS.FREE_PENALTY.SECOND }
          </span>
        </Text>
      );
    }

    if (isAir && voidTimeLimit) {
      return (
        <Text type='NORMAL_16_140'>
          { LABELS.FREE_CANCELLED.FIRST }
          &nbsp;
          {theseTicketsString}
          &nbsp;
          <span
            style={ { fontWeight: 'bold' } }
            data-qa={ QA_ATTRIBUTES.trips.trip.cancelDialog.freeCancellation }
          >
            { LABELS.FREE_CANCELLED.SECOND }
          </span>
        </Text>
      );
    }

    if (!penalty && (isVipHall || isTransfer)) {
      return (
        <Text type='NORMAL_16_140'>
          { LABELS.FREE_PENALTY.FIRST }
          &nbsp;
          <span style={ { fontWeight: 'bold' } }>
            { LABELS.FREE_PENALTY.SECOND }
          </span>
        </Text>
      );
    }

    if (!penalty && ServiceType !== SERVICETYPE.AEROEXPRESS) {
      return null;
    }

    return (
      <Text type='NORMAL_16_140'>
        { LABELS.PENALTY }
        &nbsp;
        <span style={ { fontWeight: 'bold' } }>
          <span data-qa={ QA_ATTRIBUTES.trips.trip.cancelDialog.penalty }>
            { MoneyFormat.moneyWithDecimal(penalty as number) }
          </span>
          { LABELS.CURRENCY }
        </span>
      </Text>
    );
  };

  const renderRefund = () => {
    const { ServiceType } = version;

    if (refund as number <= 0) {
      return null;
    }

    if (penalty) {
      const labelRefundPenalty = isSmartAgent
        ? LABELS.REFUND_PENALTY_SMARTAGENT
        : LABELS.REFUND_PENALTY;

      return (
        <Text className={ styles.penalty } type='NORMAL_16_140'>
          { labelRefundPenalty(MoneyFormat.moneyWithDecimal(refund as number)) }
        </Text>
      );
    }

    if (ServiceType === SERVICETYPE.TAXI_VOUCHER) {
      return (
        <Text type='NORMAL_16_140'>
          { LABELS.REFUND_REMAINDER(MoneyFormat.moneyWithDecimal(refund as number)) }
        </Text>
      );
    }

    const labelRefund = isSmartAgent ? LABELS.REFUND_SMARTAGENT : LABELS.REFUND;

    return (
      <Text type='NORMAL_16_140'>
        { labelRefund(MoneyFormat.moneyWithDecimal(refund as number)) }
      </Text>
    );
  };

  const renderRoundTripWarning = () => {
    const { ServiceType, JsonData } = version;

    if (ServiceType !== SERVICETYPE.TRAIN || tickets.length !== 2) {
      return null;
    }

    const { Tariff } = parseJsonString(JsonData);

    if (Tariff !== TRAIN_TARIFFS.TO) {
      return null;
    }

    return (
      <Text
        className={ styles.warning }
        color='red'
        type='NORMAL_16_140'
      >
        { LABELS.CANCEL_BOTH_TICKETS }
      </Text>
    );
  };

  const renderAllTicketNumbers = () => tickets.map((ticket, ind) => (
    <div key={ ind }>{ ticket.TicketId }</div>
  ));

  const renderCoupeWarning = () => {
    const { ServiceType, JsonData } = version;
    const { Tariff } = parseJsonString(JsonData);

    if (ServiceType !== SERVICETYPE.TRAIN ||
        tickets.length === 1 ||
        (Tariff !== TRAIN_TARIFFS.COUPE_MEETING &&
          Tariff !== TRAIN_TARIFFS.COUPE_BUY_TWO &&
          Tariff !== TRAIN_TARIFFS.KUPEK_IN_TRIP)
    ) {
      return null;
    }

    const cancelText = Tariff === TRAIN_TARIFFS.KUPEK_IN_TRIP
      ? LABELS.CANCEL_KUPEK
      : LABELS.CANCEL_COUPE;

    return (
      <Text
        className={ styles.warning }
        color='red'
        type='NORMAL_16_140'
      >
        { cancelText }
        { renderAllTicketNumbers() }
      </Text>
    );
  };

  const renderInfo = () => {
    if (isHotel && loadingCancellation) return null;

    return (
      <>
        { renderPenalty() }
        { renderRefund() }
      </>
    );
  };

  const renderActions = () => {
    if (hidePreCancelActions) return null;

    if (isHotel && loadingCancellation) {
      return <Loading label={ LABELS.LOADING_CANCELLATION } />;
    }

    const isHotelWithoutPenalty = isHotel && penalty === 0;
    const disabledButton = isHotelWithoutPenalty
      ? !agreement
      : agreement;

    const checkboxLabel = isHotelWithoutPenalty
      ? LABELS.CONFIRM_CANCELLATION.APPROVE
      : LABELS.CONFIRM_CANCELLATION.AGREE;

    const cancelHtml = (agreement && showCancel) || isHotelWithoutPenalty || isVipHall
      ? renderCancel()
      : (
        <ButtonTrip
          isHotel={ isHotel }
          hasDisabled={ hasDisabled }
          firstWord={ LABELS.CANCEL }
          isCancelTrain
          isTransfer={ isTransfer }
          isAir={ isAir }
          disabledTrain={ !disabledButton }
          onCloseDialog={ onCloseDialog }
          onSubmitCancellationModal={ handleShowCancelTrip }
          onClick={ onCancellationBack }
        />
      );

    return (
      <>
        <div className={ styles.checkbox }>
          <Checkbox
            value={ agreement }
            onChange={ handleChangeCheckbox }
            qaAttr={ QA_ATTRIBUTES.trips.trip.cancelDialog.checkboxAgree }
          >
            { checkboxLabel }
          </Checkbox>
        </div>
        { cancelHtml }
      </>
    );
  };

  const renderPreCancel = () => {
    const voidWarning = (isAir && voidTimeLimit) &&
      LABELS.WARNING(voidTimeLimit.local().format(textualMonthWithHoursAndMinutesPattern));

    return (
      <>
        { renderInfo() }
        { renderRoundTripWarning() }
        { renderCoupeWarning() }
        <Text
          className={ styles.warning }
          color='gray'
          type='NORMAL_16_140'
        >
          { voidWarning }
        </Text>
        { renderActions() }
      </>
    );
  };

  const renderPreCancelActualDate = () => {
    const voidWarning = (isAir && voidTimeLimit) &&
      LABELS.WARNING(voidTimeLimit.local().format(textualMonthWithHoursAndMinutesPattern));

    // @ts-ignore
    const text = errorCancel?.Type === ERRORS_TYPES.CHECK_IN_TIME_BEGAN
      ? LABELS.SOON_ANSWER
      : LABELS.SOON_ANSWER_ERROR;

    const placeholder = isBus ? LABELS.HEADER.BUS : LABELS.HEADER.HOTEL;

    return (
      <>
        <Text type='NORMAL_16_140'>{ text }</Text>
        { renderRoundTripWarning() }
        { renderCoupeWarning() }
        <Text
          className={ styles.warning }
          color='gray'
          type='NORMAL_14_130'
        >
          { voidWarning }
        </Text>
        <Textarea
          placeholder={ placeholder }
          className={ styles.textarea }
          value={ inputValue }
          onChange={ handleChange }
        />
        <Text type='NORMAL_14_130' color='gray' className={ styles.content }>
          { LABELS.CONTENT }
        </Text>
        <ButtonTrip
          valueIsEmpty={ !inputValue }
          loading={ isChatLoading }
          hasDisabled={ hasDisabled }
          onCloseDialog={ onCloseDialog }
          onSubmitCancellationModal={ handleConfirmDialog }
        />
      </>
    );
  };

  const renderContent = () => {
    if (error.length) {
      return (
        <>
          <Text type='NORMAL_16_140'>{ labelError }</Text>
          <ButtonTrip
            hasDisabled={ hasDisabled }
            firstWord={ LABELS.CANCEL }
            onCloseDialog={ onCloseDialog }
            onSubmitCancellationModal={ onSubmitCancellationModal }
          />
        </>
      );
    }

    if (isTaxiVoucher) {
      return <FreeTaxi
        agreement={ agreement }
        refund={ refund as number }
        loadingCancellation={ loadingCancellation }
        isTaxiVoucher={ isTaxiVoucher }
        hasDisabled={ hasDisabled }
        showErrorDialog={ showErrorDialog }
        errorMsg={ errorMsg }
        handleChangeCheckbox={ handleChangeCheckbox }
        onCloseDialog={ onCloseDialog }
        onCancelTrip={ onCancelTrip }
        onCancelAutomaticCancellation={ onCancelAutomaticCancellation }
        toggleErrorDialog={ toggleErrorDialog }
      />;
    }

    if (!success) {
      return renderPreCancelActualDate();
    }

    return renderPreCancel();
  };

  if (loading) {
    return <Loading label={ LABELS.LOADING } />;
  }

  return renderContent();
};

export { AutomaticCancelTrip };
