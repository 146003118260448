import { getText } from '../../../i18n';

const APPROVAL_STATUS = {
  CREATE: 'Create',
  WAITING_APPROVE: 'WaitingApprove',
  APPROVE: 'Accepted',
  NOT_APPROVE: 'NotApprove',
  APPROVER: 'Approver',
};

const APPROVAL_STATUS_PAGE = {
  CREATE: 'Create',
  WAITING_APPROVE: 'WaitingApprove',
  APPROVE: 'Approve',
  NOT_APPROVE: 'NotApprove',
  APPROVER: 'Approver',
  ARCHIVED: 'Accepted',
};

const APPROVE_STATUS = {
  ACCEPTED: 'Accepted',
  AUTO_ACCEPTED: 'AutoAccepted',
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  WAITING_APPROVE: 'WaitingApprove',
  ARCHIVED: 'Archived',
};

export enum ApprovalWarning {
  wrongDates = 'WrongDates',
  wrongPlace = 'WrongPlace',
  wrongEmployees = 'WrongEmployees',
  valid = 'Valid',
}

const TRAVEL_APPROVAL_TYPE = {
  IS_APPROVAL_PAGE: 'isApprovePage',
  TRAVEL_APPROVAL_IN_CART: 'travelApprovalInCart',
  TRAVEL_APPROVAL_IN_CART_NOT_VALID: 'travelApprovalInCartNotValid',
};

const DEFAULT_VALIDATE_APPROVE_REQUEST = {
  AvailableBalance: null,
  CartItemValidationStatuses: null,
  Valid: false,
};

const DEFAULT_PURPOSE = [
  { label: getText('components:travelApproval.conclusionOnContract'), value: 0 },
  { label: getText('components:travelApproval.conductOfNegotiations'), value: 1 },
  { label: getText('components:travelApproval.participationInTheConference'), value: 2 },
  { label: getText('components:travelApproval.serviceMaintenance'), value: 3 },
];

export {
  TRAVEL_APPROVAL_TYPE,
  DEFAULT_VALIDATE_APPROVE_REQUEST,
  DEFAULT_PURPOSE,
  APPROVAL_STATUS,
  APPROVE_STATUS,
  APPROVAL_STATUS_PAGE,
};
