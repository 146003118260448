import React from 'react';
import { Icon, Text, Tooltip } from 'new-ui';
import { getText } from '../../../../../../../i18n';

import { QA_ATTRIBUTES } from '../../../../../../bi/constants/attributesForTests';
import COUNTRIES from '../../../../../../bi/constants/countries';

import styles from './styles/index.module.css';

const LABELS = {
  WITH_BREAKFAST: getText('hotels:hotelResult.item.generalList.withBreakfast'),
  WITHOUT_BREAKFAST: getText('hotels:hotelResult.item.generalList.withoutBreakfast'),
  WITH_BREAKFAST_NAME: getText('hotels:hotelResult.item.generalList.withBreakfastName'),
  WITHOUT_BREAKFAST_NAME: getText('hotels:hotelResult.item.generalList.withoutBreakfastName'),
  NO_MONEY_BACK: getText('hotels:hotelResult.item.generalList.noMoneyBack'),
  SPECIAL_OFFER: getText('hotels:hotelResult.item.generalList.specialOffer'),
  SPECIAL_OFFER_FOREIGN: getText('hotels:hotelResult.item.generalList.specialOfferForeign'),
  FREE_CANCELLATION: (date: string) => getText('hotels:hotelResult.item.generalList.freeCancellation', { date }),
  FREE_CANCELLATION_FOREIGN: (date: string) => getText('hotels:hotelResult.item.generalList.freeCancellationForeign', { date }),
  ATTENTION_FREE_CANCELLATION: getText('hotels:hotelResult.item.generalList.attentionFreeCancellation'),
};

interface GeneralInfoListProps {
  refundable: boolean,
  meal: { Included: boolean, Name: string },
  date: string,
  countryCode: string,
}

const GeneralInfoList = ({ refundable, meal, date, countryCode }: GeneralInfoListProps) => {
  const { Included, Name } = meal;

  const mealBreakfast = Name ? `${LABELS.WITH_BREAKFAST_NAME}: ${Name}` : LABELS.WITH_BREAKFAST;
  const noMealBreakfast = Name ? LABELS.WITHOUT_BREAKFAST_NAME : LABELS.WITHOUT_BREAKFAST;

  const cancellationByCountry = countryCode !== COUNTRIES.RU.SHORTNAME ? (
    <div className={ styles.active }>
      <Text
        qaAttr={ QA_ATTRIBUTES.hotels.current.room.cancellation }
        type='NORMAL_16_140'
      >
        { LABELS.FREE_CANCELLATION_FOREIGN(date) }
        <Tooltip
          className={ styles.icon }
          bodyClassName={ styles.tooltip }
          renderContent={ () => (
            <Text type='NORMAL_14_130' className={ styles['tooltip-content'] } color='white'>
              { LABELS.ATTENTION_FREE_CANCELLATION }
            </Text>
          ) }
        >
          <Icon type='question' />
        </Tooltip>
      </Text>
    </div>
  ) : (
    <Text
      qaAttr={ QA_ATTRIBUTES.hotels.current.room.cancellation }
      type='NORMAL_16_140'
    >
      { LABELS.FREE_CANCELLATION(date) }
    </Text>
  );

  const mealContent = Included ? (
    <Text qaAttr={ QA_ATTRIBUTES.hotels.current.room.breakfast } className={ styles.active }>
      { mealBreakfast }
      <Icon type='meal' className={ styles.icon }/>
    </Text>
  ) : (
    <Text
      qaAttr={ QA_ATTRIBUTES.hotels.current.room.breakfast }
      color='gray'
    >
      { noMealBreakfast }
    </Text>
  );

  const specialOfferText = countryCode !== COUNTRIES.RU.SHORTNAME ? LABELS.SPECIAL_OFFER_FOREIGN : LABELS.SPECIAL_OFFER;

  const cancellationContent = refundable ? cancellationByCountry : (
    <div className={ styles.active }>
      <Text qaAttr={ QA_ATTRIBUTES.hotels.current.room.cancellation } color='red'>
        { LABELS.NO_MONEY_BACK }
      </Text>
      <Tooltip
        className={ styles.icon }
        bodyClassName={ styles.tooltip }
        renderContent={ () => (
          <Text type='NORMAL_14_130' className={ styles['tooltip-content'] } color='white'>
            { specialOfferText }
          </Text>
        ) }
      >
        <Icon type='warning' />
      </Tooltip>
    </div>
  );

  return (
    <div className={ styles.wrapper }>
      { cancellationContent }
      <div className={ styles.meal }>
        { mealContent }
      </div>
    </div>
  );
};

export { GeneralInfoList };
