const getAccessToken = () => {
  try {
    const token = window.localStorage.getItem('smartway-current');

    return token;
  } catch (error) {
    return null;
  }
};

const getAccountId = () => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    return null;
  }

  return JSON.parse(atob(accessToken.split('.')[1])).account_id;
};

export default getAccountId;
