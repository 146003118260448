import React from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../i18n';

import styles from './index.module.css';

const LABELS = {
  ALERT_TEXT: getText('search:hotel.alertText'),
};

const WarningMessage = () => (
  <Text className={ styles.alertText }>
    { LABELS.ALERT_TEXT }
  </Text>
);

export { WarningMessage };
