import { getAirlineType } from './airline';

import { SERVICETYPE } from '../constants/serviceType';

import {
  IAirItem,
  IAirRoute,
  IAirSegment,
  IFavorite,
  IHotelItem,
  ITrainItem,
  ITransferItem,
  TAB_TYPES,
} from '../types/favorites';

const FILTERED_TYPE_FN = {
  [SERVICETYPE.AIR]: (item: IAirItem, query: string): number =>
    item.Routes.filter((route: IAirRoute) => route.Segments.filter((segment: IAirSegment) => {
      const airlineType = getAirlineType(segment);

      return segment.AircraftName.toLowerCase().includes(query)
        || segment[airlineType].Name.toLowerCase().includes(query)
        || segment.ArrivalAirport.Name.toLowerCase().includes(query)
        || segment.DepartureAirport.Name.toLowerCase().includes(query)
        || segment.DepartureCity.toLowerCase().includes(query)
        || segment.ArrivalCity.toLowerCase().includes(query)
        || segment.FlightNumber.toLowerCase().includes(query);
    }).length).length,
  [SERVICETYPE.TRANSFER]: (item: ITransferItem, query: string): boolean => item.Places.StartPlace.Address.toLowerCase().includes(query)
    || item.Places.FinishPlace.Address.toLowerCase().includes(query),
  [SERVICETYPE.TRAIN]: (item: ITrainItem, query: string): boolean => item.StationFrom.toLowerCase().includes(query)
    || item.StationTo.toLowerCase().includes(query)
    || item.TrainNumber.toLowerCase().includes(query),
  [SERVICETYPE.HOTEL]: (item: IHotelItem, query: string): boolean => item.Address.toLowerCase().includes(query)
    || item.Name.toLowerCase().includes(query),
};

const filteredFavoritesByType = (list: IFavorite[], currentTab: string): IFavorite[] => {
  if (currentTab === TAB_TYPES.All) {
    return list;
  }

  return list.filter(item => item.Type === currentTab);
};

const filteredFavoritesBySearch = (list: IFavorite[], query: string): IFavorite[] => {
  const search = query.toLowerCase();

  return list.filter((item: IFavorite) => {
    const fn = FILTERED_TYPE_FN[item.Type];

    if (fn) {
      // @ts-ignore
      return fn(item.Item, search);
    }

    return false;
  });
};

export {
  filteredFavoritesByType,
  filteredFavoritesBySearch,
};
