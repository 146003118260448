import React from 'react';
import { Icon, Text } from 'new-ui';

import MoneyFormat from '../../bi/utils/money';
import { isSmartAgent } from '../../bi/utils/env';

import styles from './styles.module.css';

interface AgencyFeeProps {
  fee: number | string,
  agentMode: boolean,
}

const AgencyFee = ({
  fee,
  agentMode,
}: AgencyFeeProps) => {
  const preparedColor = isSmartAgent ? 'green' : 'default';

  if (!fee || !agentMode) {
    return null;
  }

  return (
    <div className={ styles.wrapper }>
      <Text
        type='NORMAL_12'
        className={ styles.fee }
        color={ preparedColor }
      >
        { MoneyFormat.moneyWithDecimal(+fee, true) }
      </Text>
      <Icon
        type='wallet'
        color='dim_green'
        alternativeDesign={ isSmartAgent }
      />
    </div>
  );
};

export default AgencyFee;
