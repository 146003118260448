import { action, makeObservable, observable } from 'mobx';

import {
  DEFAULT_CHART,
  DEFAULT_PAGINATE,
  DEFAULT_SUB_TABLE_INFO,
  DEFAULT_TABLE_INFO,
} from '../../../../constants/chartsAnalytics';

import {
  ChartType,
  SmartIndexesType,
  SummaryIndexType,
} from '../../types';

export class ChartsAnalyticsSmartSummaryStore {
  constructor() {
    makeObservable(this);
  }

  @observable loading = true;
  @observable chart: ChartType = { ...DEFAULT_CHART };
  @observable summaryIndex = null;
  @observable indexes = [];
  @observable type = '';
  @observable subSmartType = '';

  @observable tableInfo = { ...DEFAULT_TABLE_INFO };
  @observable subPageInfo = null;
  @observable label = '';
  @observable subTableInfo = { ...DEFAULT_SUB_TABLE_INFO };
  @observable paginate = { ...DEFAULT_PAGINATE };
  @observable countTrips = 0;
  @observable voucherId = '';

  @action
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  setType = (value: string) => {
    this.type = value;
  };

  @action
  setSmartSubType = (value: string) => {
    this.subSmartType = value;
  };

  @action
  setChart = ({
    dates,
    values,
    labels,
    timeliness = '',
  }: {
    dates: string[],
    values: string[] | any,
    labels: string[],
    timeliness?: any,
  }) => {
    this.chart = {
      ...this.chart,
      values,
      dates,
      labels,
      timeliness,
    };
  };

  @action
  setSummaryIndex = (summaryIndex: SummaryIndexType | any) => {
    this.summaryIndex = summaryIndex;
  };

  @action
  setSubPageInfo = (type: string | any) => {
    this.subPageInfo = type;
  };

  @action
  setLabel = (label: string) => {
    this.label = label;
  };

  @action
  setSummaryIndexes = (indexes: SmartIndexesType[] | any) => {
    this.indexes = indexes;
  };

  @action
  setVoucherId = (type: string) => {
    this.voucherId = type;
  };

  @action
  setTableInfo = ({
    labelsHeader,
    strings,
  }: {
    labelsHeader: string[] | any,
    strings: string[] | any,
  }) => {
    this.tableInfo = {
      labelsHeader,
      content: strings,
    };
  };

  @action
  setCountTrips = (value: number) => {
    this.countTrips = value;
    this.paginate = {
      ...this.paginate,
      total: value,
    };
  };

  @action
  setTrips = (strings: string[] | any, columnsName: string[] | any) => {
    this.subTableInfo = {
      ...this.subTableInfo,
      strings,
      columnsName,
    };
  };

  @action
  setPageTrips = (page: number) => {
    this.paginate = {
      ...this.paginate,
      currentPage: page,
    };
  };

  @action
  setResetPaginate = () => {
    this.paginate = { ...DEFAULT_PAGINATE };

    return this.paginate;
  };
}

export const chartsAnalyticsSmartSummaryStore = new ChartsAnalyticsSmartSummaryStore();
