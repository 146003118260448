import React, { useRef, useEffect, useState } from 'react';
import { Dialog, Button, RadioButton, Input, Text } from 'new-ui';
import { getText } from '../../../../../../i18n';

import { isEmail } from '../../../../../bi/utils/validation';

import FORMATS from '../../../../../bi/constants/formats';

import styles from './index.module.css';

type SendInvoiceProps = {
  onChangeInput(value: string): void;
  onChangeFormat(value: string): void;
  onSendEmail(): void;
  onClose(): void;
  email: string;
  format: string;
  show: boolean;
  sendLoading: boolean;
};

const LABELS = {
  TITLE: getText('components:operationsTable.dialogs.sendInvoice.title'),
  EMAIL: getText('components:operationsTable.dialogs.sendInvoice.email'),
  IN_PDF: getText('components:operationsTable.dialogs.sendInvoice.inPdf'),
  IN_XMLX: getText('components:operationsTable.dialogs.sendInvoice.inXml'),
  SEND: getText('common:send'),
  CANCEL: getText('common:undo'),
  PLACEHOLDER: getText('components:operationsTable.dialogs.sendInvoice.placeholder'),
  ERROR: getText('components:operationsTable.dialogs.sendInvoice.error'),
};

const {
  PDF,
  XLSX,
} = FORMATS;

const DELAY = 250;

const SendInvoice = ({
  onChangeInput,
  onChangeFormat,
  onSendEmail,
  onClose,
  email,
  format,
  show,
  sendLoading,
}: SendInvoiceProps) => {
  const emailRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (emailRef?.current && show) {
      setTimeout(() => emailRef.current?.focus(), DELAY);
    }
  }, [show]);

  const handleSendEmail = () => {
    const isValid = isEmail(email);

    if (!isValid) {
      return setError(LABELS.ERROR);
    }

    return onSendEmail();
  };

  const handleChangeInput = (value: string) => {
    onChangeInput(value);
    setError('');
  };

  return (
    <Dialog show={ show } onChange={ onClose } showClosing isForm>
      <Text type='normal_20'>{LABELS.TITLE}</Text>
      <Text className={ styles.label }>{LABELS.EMAIL}</Text>
      <Input
        value={ email }
        onChange={ handleChangeInput }
        ref={ emailRef }
        placeholder={ LABELS.PLACEHOLDER }
        error={ error }
      />
      <div className={ styles.radio_buttons }>
        <RadioButton
          value={ PDF }
          checked={ format === PDF }
          onChange={ () => onChangeFormat(PDF) }
          className={ styles.radio }
        >
          <Text color='gray'>{ LABELS.IN_PDF }</Text>
        </RadioButton>
        <RadioButton
          value={ XLSX }
          checked={ format === XLSX }
          onChange={ () => onChangeFormat(XLSX) }
        >
          <Text color='gray'>{ LABELS.IN_XMLX }</Text>
        </RadioButton>
      </div>
      <div className={ styles.action_buttons }>
        <Button
          className={ styles.action_button }
          formType='submit'
          onClick={ handleSendEmail }
          loading={ sendLoading }
          disabled={ !!error || sendLoading }
        >
          {LABELS.SEND}
        </Button>
        <Button
          className={ styles.action_button }
          type='textual'
          onClick={ onClose }
        >
          {LABELS.CANCEL}
        </Button>
      </div>
    </Dialog>
  );
};

export { SendInvoice };
