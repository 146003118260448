import React from 'react';
import { Text, Tooltip, Icon } from 'new-ui';
import { Moment } from 'moment';

import { getText } from '../../../i18n';

import {
  momentSubPeriod,
  isAfterDate,
  getMoment,
  formatDate,
} from '../../bi/utils/formatDate';
import MoneyFormat from '../../bi/utils/money';

import { PATTERN } from '../../bi/constants/dateFormats';
import COUNTRIES from '../../bi/constants/countries';

import styles from './styles.module.css';

interface HotelCancellationProps {
  fromTrip?: boolean,
  cancellationPenalties: any[],
  cancellationDate: string,
  cancellationInfo: string | null,
  cancellationText?: string,
  hasCancellation?: boolean,
  countryCode?: string,
  className?: string,
  qaAttr?: string,
}

const LABELS = {
  FROM: getText('components:hotelCancellation.from'),
  BEFORE: getText('components:hotelCancellation.before'),
  CANCELLATION_CONDITION: getText('components:hotelCancellation.cancellationConditions'),
  CANCELLATION_CONDITION_FOREIGN: getText('components:hotelCancellation.cancellationConditionsForeign'),
  BEFORE_FREE: (cancellationDate: string) => getText('components:hotelCancellation.beforeFree', { cancellationDate }),
  FREE_CANCELLATION_WITH_PENALTIES: (total: number) => getText('components:hotelCancellation.freeCancellationWithPenalties', { total }),
  NON_FREE_CANCELLATION: getText('components:hotelCancellation.noFreeCancellation'),
  NON_FREE_CANCELLATION_FOREIGN: getText('components:hotelCancellation.noFreeCancellationForeign'),
  FREE_BEFORE: (cancellationDate: string) => getText('components:hotelCancellation.freeBefore', { cancellationDate }),
  FREE_BEFORE_FOREIGN: (cancellationDate: string) => getText('components:hotelCancellation.freeBeforeForeign', { cancellationDate }),
  MSK_WITH_PENALTIES: (total: string) => getText('components:hotelCancellation.mskWithPenalties', { total }),
  ATTENTION_FREE_CANCELLATION: getText('hotels:hotelResult.item.generalList.attentionFreeCancellation'),
  SPETIAL_OFFER_FOREIGN: getText('hotels:hotelResult.item.generalList.specialOfferForeign'),
};

const MINUTES = 'minutes';
const MIN_PENALTIES_LENGTH = 1;

const HotelCancellation = ({
  fromTrip = false,
  cancellationPenalties,
  cancellationDate,
  cancellationInfo,
  cancellationText = '',
  countryCode,
  hasCancellation = false,
  className = '',
  qaAttr = '',
}: HotelCancellationProps) => {
  const cancelConditionsNames = [styles['condition-text']];

  if (className) {
    cancelConditionsNames.push(className);
  }

  const tooltipText = !cancellationInfo ? LABELS.SPETIAL_OFFER_FOREIGN : LABELS.ATTENTION_FREE_CANCELLATION;

  const showTooltipCancellation = !fromTrip ? (
    <Tooltip
      className={ styles.tooltip }
      renderContent={ () => (
        <Text type='NORMAL_14_130' className={ styles['tooltip-content'] } color='white'>
          { tooltipText }
        </Text>
      ) }
    >
      <Icon type='question' />
    </Tooltip>
  ) : null;

  const cancellationConditionsByCountry = countryCode !== COUNTRIES.RU.SHORTNAME ? (
    <div className={ styles.cancellation }>
      <Text type='SEMIBOLD_14_130' className={ cancelConditionsNames.join(' ') }>
        { LABELS.CANCELLATION_CONDITION_FOREIGN }
      </Text>
      { showTooltipCancellation }
    </div>
  ) : (
    <Text type='SEMIBOLD_14_130' className={ cancelConditionsNames.join(' ') }>
      { LABELS.CANCELLATION_CONDITION }
    </Text>
  );

  const headerConditions = (cancellationPenalties && cancellationPenalties.length > 1) || fromTrip
    ? cancellationConditionsByCountry
    : null;

  const createItemList = (prefix: string, from: Moment, total: number, index: number) =>
    <Text key={ `${from}_${index}` } type='NORMAL_14_130' className={ className }>
      {`${prefix} ${formatDate(from, PATTERN.DAY_OF_MONTH_TIME)} ${LABELS.MSK_WITH_PENALTIES(MoneyFormat.money(total))}`}
    </Text>;

  let isFreeCancellation: string | JSX.Element = '';
  const penaltyList: any[] = [];
  const validPenaltyList: boolean[] = [];
  const freeCancellation = LABELS.BEFORE_FREE(cancellationDate);

  const validatePenaliesDate = (date: string) => {
    const isValidDate = isAfterDate(date, getMoment());

    validPenaltyList.push(isValidDate);
  };

  const cancellationNotFromTrip = () => {
    if (hasCancellation && !cancellationPenalties.length) return cancellationText;

    if (!cancellationPenalties || !cancellationPenalties.length) return LABELS.NON_FREE_CANCELLATION;

    if (cancellationInfo) {
      cancellationPenalties.forEach(({ From, Total, Additional }, i) => {
        penaltyList.push(!Additional && createItemList(LABELS.FROM, From, Total, i));

        validatePenaliesDate(From);
      });

      return freeCancellation;
    }

    isFreeCancellation = cancellationPenalties.length === 1 ? LABELS.FREE_CANCELLATION_WITH_PENALTIES(cancellationPenalties[0].Total) : '';

    if (cancellationPenalties.length <= MIN_PENALTIES_LENGTH) return isFreeCancellation;

    cancellationPenalties.forEach(({ From, Total, Additional }, i) => {
      if (Additional) {
        isFreeCancellation = !penaltyList.length ? LABELS.NON_FREE_CANCELLATION : isFreeCancellation;

        return isFreeCancellation;
      }

      validatePenaliesDate(From);

      const labelFirstCondition = !hasCancellation ?
        LABELS.FROM :
        LABELS.BEFORE;

      if (i === 0) {
        return penaltyList.push(createItemList(
          labelFirstCondition,
          momentSubPeriod(cancellationPenalties[0].From, 0, MINUTES),
          Total,
          i,
        ));
      }

      return penaltyList.push(createItemList(LABELS.FROM, From, Total, i));
    });

    return isFreeCancellation;
  };

  const cancellationByCountry = countryCode === COUNTRIES.RU.SHORTNAME ?
    LABELS.FREE_BEFORE(cancellationDate) : (
      <div className={ styles.cancellation }>
        { LABELS.FREE_BEFORE_FOREIGN(cancellationDate) }
        <Tooltip
          className={ styles.tooltip }
          renderContent={ () => (
            <Text type='NORMAL_14_130' className={ styles['tooltip-content'] } color='white'>
              { LABELS.ATTENTION_FREE_CANCELLATION }
            </Text>
          ) }
        >
          <Icon type='question' />
        </Tooltip>
      </div>
    );

  const nonFreeCancellationByCountry = countryCode === COUNTRIES.RU.SHORTNAME ?
    LABELS.NON_FREE_CANCELLATION : (
      <div className={ styles.cancellation }>
        { LABELS.NON_FREE_CANCELLATION_FOREIGN }
        <Tooltip
          className={ styles.tooltip }
          renderContent={ () => (
            <Text type='NORMAL_14_130' className={ styles['tooltip-content'] } color='white'>
              { LABELS.SPETIAL_OFFER_FOREIGN }
            </Text>
          ) }
        >
          <Icon type='question' />
        </Tooltip>
      </div>
    );

  const cancellationFromTrip = !cancellationInfo ? nonFreeCancellationByCountry : cancellationByCountry;

  isFreeCancellation = !fromTrip ? cancellationNotFromTrip() : cancellationFromTrip;

  const renderPenalties = () => {
    if (!validPenaltyList.length) return null;

    const isShowPenalties = validPenaltyList.every(penaltyTime => !penaltyTime);

    return isShowPenalties ? (
      <Text type='NORMAL_14_130' className={ className }>
        { LABELS.NON_FREE_CANCELLATION }
      </Text>
    ) : penaltyList;
  };

  return (
    <div data-qa={ qaAttr }>
      { headerConditions }
      <Text type='NORMAL_14_130' className={ className }>{ isFreeCancellation }</Text>
      { renderPenalties() }
    </div>
  );
};

export { HotelCancellation };
