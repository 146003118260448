import React from 'react';

import { getText } from '../../../../../i18n';

import type { PropertyType } from '../../types/types';

import styles from './styles.module.css';

interface PropertiesType {
  className: string,
  description: string
}
const PROPERTIES = {
  extraSpace: {
    className: styles.extra_space,
    description: getText('components:airlineSeatMap.property.extraSpace'),
  },
  infant: {
    className: styles.infant,
    description: getText('components:airlineSeatMap.property.infant'),
  },
  notRecline: {
    className: styles.not_recline_seat,
    description: getText('components:airlineSeatMap.property.notRecline'),
  },
  child: {
    className: styles.child,
    description: getText('components:airlineSeatMap.property.child'),
  },
};

const getClassNameAndDescription = (property: PropertyType): PropertiesType | null => {
  if (!Object.keys(PROPERTIES).includes(property)) {
    return null;
  }

  // @ts-ignore
  // eslint-disable-next-line newline-before-return
  return PROPERTIES[property];
};

type PropertyProps = {
  property: PropertyType,
};

const Property = ({ property }: PropertyProps) => {
  const data = getClassNameAndDescription(property);

  if (!data) {
    return null;
  }

  return (
    <div className={ styles.wrap }>
      <div className={ `${styles.icon} ${data.className}` } />
      <div className={ styles.description }>
        { data.description }
      </div>
    </div>
  );
};

export default Property;
