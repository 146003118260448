import { observable, action, computed, makeObservable } from 'mobx';

import { TRAVEL_APPROVAL, APPROVAL, WHO_BUYS } from '../../../constants/approvalSchemes';

import { IApproverListFact } from '../types';

export interface ISettingSchemeStore {
  whoBuys: { name: string, id: string, value: boolean }[],
  travelApproval: string,
  approval: string,
  loadings: {
    loadApprovers: boolean,
    loadScheme: boolean,
    loadRoles: boolean,
  },
  isApprovalUnavailable: boolean,
  isTravelApprovalUnavailable: boolean,
  isApprovalDisrupted_TP: boolean,
  isValid: boolean,
  isSettingEmployeesStatus: boolean,
  isApprovalEvery: boolean,
}

const DEFAULT_LOADINGS = {
  loadApprovers: false,
  loadScheme: false,
  loadRoles: false,
};

export class SettingSchemeStore implements ISettingSchemeStore {
  constructor() {
    makeObservable(this);
  }

  @observable whoBuys = WHO_BUYS;
  @observable travelApproval = TRAVEL_APPROVAL.UNAVAILABLE;
  @observable approval = APPROVAL.EVERY;
  @observable loadings = DEFAULT_LOADINGS;
  @observable approverListFact: IApproverListFact | null = null;

  @computed
  get isTravelApprovalUnavailable() {
    return this.travelApproval === TRAVEL_APPROVAL.UNAVAILABLE;
  }

  @computed
  get isApprovalEvery() {
    return this.approval === APPROVAL.EVERY;
  }

  @computed
  get isApprovalDisrupted_TP() {
    return this.approval === APPROVAL.DISRUPTED_TP;
  }

  @computed
  get isApprovalUnavailable() {
    return this.approval === APPROVAL.UNAVAILABLE;
  }

  @computed
  get isValid() {
    return this.whoBuys.some(({ value }) => value);
  }

  @computed
  get isSettingEmployeesStatus() {
    return this.travelApproval === TRAVEL_APPROVAL.UNAVAILABLE && this.approval === APPROVAL.UNAVAILABLE;
  }

  @action
  setWhoBuys = (id: string, value: any) => {
    this.whoBuys = this.whoBuys.map(item => ({
      ...item,
      value: item.id === id ? value : item.value,
    }));
  };

  @action
  setTravelApproval = (id: string) => {
    this.travelApproval = id;
  };

  @action
  setFactApproversList = (data: IApproverListFact) => {
    this.approverListFact = data;
  };

  @action
  setApproval = (id: string) => {
    this.approval = id;
  };
  @action
  setLoadings = (field: string, value: boolean) => {
    this.loadings = {
      ...this.loadings,
      [field]: value,
    };
  };

  @action
  clear = () => {
    this.whoBuys = WHO_BUYS;
    this.travelApproval = TRAVEL_APPROVAL.UNAVAILABLE;
    this.approval = APPROVAL.EVERY;
    this.loadings = DEFAULT_LOADINGS;
  };
}

export const settingSchemeStore = new SettingSchemeStore();
