import React from 'react';

import styles from './styles/index.module.css';

const DRAGGABLE_ITEMS_STYLES: React.CSSProperties = { width: '100%', textAlign: 'center' };

interface IDraggableItem {
  isRed?: boolean,
  onDragStart?(): void,
  onDragEnd?(): void,
  onClick?(): void,
  text?: string,
  renderContent?: any,
  readonly?: boolean,
  isStyle?: boolean,
  qaAttr?: string,
}

const DraggableItem = ({
  isRed = false,
  onDragStart = () => {},
  onDragEnd = () => {},
  onClick = () => {},
  text = '',
  renderContent = null,
  readonly = false,
  isStyle = false,
  qaAttr = '',
}: IDraggableItem) => {
  const classNames = [styles.wrapper];

  if (isRed) {
    classNames.push(styles.red);
  }

  if (!readonly && !!onClick) {
    classNames.push(styles.pointer);
  }

  const styleDrag = isStyle ? DRAGGABLE_ITEMS_STYLES : undefined;

  const content = renderContent ? renderContent() : text;

  return (
    <div
      draggable={ !readonly }
      onDragStart={ (e) => {
        onDragStart();

        // Firefox & Safari dragndrop fix sooqa
        const txt = e.dataTransfer.getData('text');

        e.dataTransfer.setData('text', txt);
      } }
      onDragEnd={ onDragEnd }
      className={ classNames.join(' ') }
      onClick={ onClick }
      style={ styleDrag }
      data-qa={ qaAttr }
    >
      { content }
    </div>
  );
};

export { DraggableItem };
