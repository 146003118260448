import React, { useState, useEffect, useRef } from 'react';
import { Moment } from 'moment';
import { Button, StyledWrapper, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { SearchDateRoute } from '../SearchDateRoute';

import {
  defaultFieldSearchChange,
  getCurrentSearchRoute,
  updatedFieldSearchChangeRoutesInfo,
  updatedFieldSearchChangeRoutesSearch,
} from '../../../../bi/utils/changeAirTrip';

import DIRECTIONS from '../../../../bi/constants/directions';
import { FIELDS_CHANGE, PATH } from '../../../../bi/constants/changeAirTrip';
import { HISTORY_ACTIONS } from '../../../../bi/constants/history';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { SelectedInterface } from '../../../../bi/utils/changeAirTrip/types';
import { Route, SearchMenuDateProps } from './types';

import styles from './styles/index.module.css';

const LABELS = {
  PICK_UP_OPTIONS: getText('components:changeAirTrip.pickUpOptions'),
};

const { changeTrip: { air: { searchMenu: { searchButton } } } } = QA_ATTRIBUTES;

const SearchMenuDate = ({
  airlineService,
  history,
  path,
  ticketData,
  routesInfo,
  routesSearch,
  useRoutesInfo,
  onSearch,
}: SearchMenuDateProps) => {
  const [searchRoutes, setSearchRoutes] = useState<Route[]>([]);
  const [valid, setValid] = useState<boolean | null>(null);

  const currentSearchRouteRef = useRef(ticketData && getCurrentSearchRoute(ticketData.Routes, ticketData.Fare?.IsTicketChangeableAfterDeparture));
  const visibleCheckboxRef = useRef(currentSearchRouteRef.current.length > 1);

  const updateAirlineState = ({ routes, isValid }: { routes: Route[], isValid: boolean }) => {
    setSearchRoutes(() => [...routes]);
    setValid(isValid);
  };

  const updateSearchValue = (index: number, dataDirectionFrom: SelectedInterface, dataDirectionTo: SelectedInterface, departureDateMoment: Moment | SelectedInterface) => {
    airlineService.setSearchValue(FIELDS_CHANGE.SUGGEST_SELECT, { field: DIRECTIONS.FROM, key: index, value: dataDirectionFrom });
    airlineService.setSearchValue(FIELDS_CHANGE.SUGGEST_SELECT, { field: DIRECTIONS.TO, key: index, value: dataDirectionTo });
    airlineService.setSearchValue(FIELDS_CHANGE.DATE, { key: index, value: departureDateMoment });
  };

  const handleDefaultFieldSearch = () => currentSearchRouteRef.current.forEach((route, index) => {
    const [dataDirectionFrom, dataDirectionTo, departureDateMoment] = defaultFieldSearchChange(route);
    updateSearchValue(index, dataDirectionFrom, dataDirectionTo, departureDateMoment);
  });

  const handleUpdateFieldSearch = () => {
    if (useRoutesInfo) {
      return routesInfo?.forEach((route, index) => {
        const [dataDirectionFrom, dataDirectionTo, departureDateMoment] = updatedFieldSearchChangeRoutesInfo(route);
        updateSearchValue(index, dataDirectionFrom, dataDirectionTo, departureDateMoment);
      });
    }

    return routesSearch?.forEach((route, index) => {
      const [dataDirectionFrom, dataDirectionTo, departureDateMoment] = updatedFieldSearchChangeRoutesSearch(route);
      updateSearchValue(index, dataDirectionFrom, dataDirectionTo, departureDateMoment);
    });
  };

  useEffect(() => {
    const unsubscribeAirlineFn = airlineService.subscribeSearch(updateAirlineState);

    if (path === PATH.DATE) {
      airlineService.setNewSearch();

      if (currentSearchRouteRef.current.length > 1) {
        airlineService.setSearchValue(FIELDS_CHANGE.COMPLEX, true);
      }

      currentSearchRouteRef.current.forEach((_, index) => index !== 0 && airlineService.addSearchRoute());
      handleDefaultFieldSearch();
    }

    if (path === PATH.DATE_RESULT) {
      if (history.action === HISTORY_ACTIONS.POP) {
        airlineService.setSearchValue(FIELDS_CHANGE.COMPLEX, true);
        routesInfo?.forEach((_, index) => index !== 0 && airlineService.addSearchRoute());
      }

      handleUpdateFieldSearch();
    }

    return () => {
      unsubscribeAirlineFn();
    };
  }, []);

  const renderSearchRoutes = () =>
    !!searchRoutes.length && searchRoutes.map((route, index) => (
      <SearchDateRoute
        key={ `search_route_date_${index}` }
        airlineService={ airlineService }
        index={ index }
        route={ route }
        defaultRoute={ currentSearchRouteRef.current[index] }
        visibleCheckbox={ visibleCheckboxRef.current }
      />
    ));

  return (
    <StyledWrapper className={ styles.wrapper }>
      { renderSearchRoutes() }
      <div className={ styles.search }>
        <Button
          qaAttr={ searchButton }
          type='secondary'
          className={ styles.search_button }
          disabled={ !valid }
          onClick={ onSearch }
        >
          <Text type='NORMAL_18' color='white'>
            { LABELS.PICK_UP_OPTIONS }
          </Text>
        </Button>
      </div>
    </StyledWrapper>
  );
};

export { SearchMenuDate };
