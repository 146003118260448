import React, { useState } from 'react';
import { Button, StyledWrapper, Text, Icon } from 'new-ui';

import { getText } from '../../../i18n';

import { isSmartAgent } from '../../bi/utils/env';

import styles from './styles/index.module.css';

const LABELS = {
  SAVE: getText('settings:companies.save'),
  CANCEL: getText('settings:companies.cancel'),
};

type TData = {
  id: string,
  name: string,
  order: number
};

type DragAndDropProps = {
  data: TData[],
  setData(value: TData[]): void,
  onSave(value: string[] | number[]): void,
  setCompany(value: string[] | number[]): void,
  onCancel (value: boolean): void,
  setAnalytics?(): void,
};

const DraggableSingleList = ({
  data,
  setData,
  onSave,
  onCancel,
  setCompany,
  setAnalytics = () => {},
}: DragAndDropProps) => {
  const [currentCard, setCurrentCart] = useState({ order: Number, id: Number });
  const [orderItems, setOrderItems] = useState<TData[]>(data);

  const handleDragStart = (cart: any) => {
    setCurrentCart(cart);
    setAnalytics();
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (event: any, cartDrop: TData) => {
    event.preventDefault();

    const newOrder: TData[] = orderItems.map((cart: any) => {
      if (cart.id === cartDrop.id) {
        return { ...cart, order: currentCard.order };
      }

      if (cart.id === currentCard.id) {
        return { ...cart, order: cartDrop.order };
      }

      return cart;
    });

    setOrderItems(newOrder);
  };

  const sortCard = (cartOne: TData, cartTwo: TData) =>
    ((cartOne.order > cartTwo.order) ? 1 : -1);

  const handleSave = () => {
    const saveData = orderItems.map((item) => item.id);
    setCompany(saveData);
    setData(orderItems);
    onCancel(false);

    return onSave(saveData);
  };

  const renderDragnDrop = () => {
    const dataSorted: TData[] = orderItems.sort(sortCard);

    const dragnDropHtml = (item: TData) => (
      <div
        key={ item.id }
        draggable={ false }
        onDragStart={ () => handleDragStart(item) }
        onDragOver={ (e) => handleDragOver(e) }
        onDrop={ (e) => handleDrop(e, item) }
      >
        <StyledWrapper
          className={ styles.company_dragn }
        >
          <div
            className={ styles.company_wrapper }
          >
            <Icon
              className={ styles.square_wrapper }
              alternativeDesign={ isSmartAgent }
              type='squareIcon'
            />
            <Text
              type='normal_20'
            >
              { item.name }
            </Text>
          </div>
        </StyledWrapper>
      </div>
    );

    return dataSorted.map(dragnDropHtml);
  };

  return (
    <>
      <div className={ styles.button_wrapper }>
        <Button
          type='textual'
          className={ styles.button_save }
          onClick={ () => onCancel(false) }
        >
          { LABELS.CANCEL }
        </Button>
        <Button
          type='secondary'
          onClick={ () => handleSave() }
        >
          { LABELS.SAVE }
        </Button>
      </div>
      { renderDragnDrop() }
    </>
  );
};

export { DraggableSingleList };
