import React from 'react';
import { Text, Tooltip, Icon } from 'new-ui';

import { TechnicalStopS7Text, TechnicalStopText } from '../AirlineTechnicalStop/components';

import { getAirlineType, getImageLogo } from '../../bi/utils/airline';
import { secondsToLabel } from '../../bi/utils/time';

import { getText } from '../../../i18n';

import { AirSegment, AirTerminal } from '../../bi/types/airline';

import styles from './styles/index.module.css';

const LABELS = {
  attention: getText('components:airlineItemSegment.attention'),
  infoAirport: getText('components:airlineItemSegment.infoAirport'),
  infoTerminal: getText('components:airlineItemSegment.infoTerminal'),
  transfer: getText('components:airlineItemSegment.transfer'),
  terminal: getText('components:airlineItemSegment.terminal'),
  airline: getText('components:airlineItemSegment.airline'),
};

const renderTooltip = (info: string, city: string) => (
  <Text
    color='white'
    type='NORMAL_14_130'
    className={ styles.tooltip_box }
  >
    <div>{ LABELS.attention }</div>
    <div>{ info }{ city }</div>
  </Text>
);

const renderCity = (city: string, id: string | null, changed: boolean) => {
  const color = changed ? 'red' : 'default';

  return (
    <Tooltip show={ changed } renderContent={ () => renderTooltip(LABELS.infoAirport, city) }>
      <Text
        type='SEMIBOLD_16'
        color={ color }
      >
        { city }{ id }
      </Text>
    </Tooltip>
  );
};

const renderTerminal = (terminal: string, changed: boolean, city: string) => {
  if (!terminal || terminal.trim().length === 0) return null;

  const color = changed ? 'red' : 'default';

  return (
    <Tooltip
      show={ changed }
      renderContent={ () => renderTooltip(LABELS.infoTerminal, city) }
      className={ styles.airport_name }
    >
      <Text type='NORMAL_14' color={ color }>
        { LABELS.terminal } { terminal }
      </Text>
    </Tooltip>
  );
};

interface AirlineItemSegmentProps {
  fromTrip?: boolean,
  segment: AirSegment,
  arrivalChanged?: boolean,
  departureChanged?: boolean,
  arrivalTerminal: AirTerminal,
  departureTerminal: AirTerminal,
  opts: {
    airlineId: string,
    arrivalAirportId: string | null,
    arrivalDate: string,
    arrivalTime: string,
    arrivalWeek: string,
    departureAirportId: string | null,
    departureDate: string,
    departureTime: string,
    departureWeek: string,
  },
}

const AirlineItemSegment = ({
  fromTrip = false,
  segment,
  opts,
  arrivalChanged = false,
  departureChanged = false,
  arrivalTerminal,
  departureTerminal,
}: AirlineItemSegmentProps) => {
  const {
    ChangeDuration,
    ArrivalCity,
    DepartureCity,
    OperatingAirline,
    DepartureAirport,
    FlightNumber,
    AircraftName,
    ArrivalAirport,
    TechnicalStop,
    ServiceStops,
  } = segment;

  const changeDurationHtml = ChangeDuration > 0 ? (
    <div className={ styles.change }>
      <Text
        type='NORMAL_14'
        color='gray'
        className={ styles.title }
      >
        { LABELS.transfer }
      </Text>
      <Text
        type='NORMAL_14'
        color='gray'
        className={ styles.duration }
      >
        { secondsToLabel(ChangeDuration, true) }
      </Text>
    </div>
  ) : null;
  const airlineType = getAirlineType(segment);

  const wrappedStyles = [styles.wrapped];
  const {
    airlineId,
    departureAirportId,
    arrivalAirportId,
    departureTime,
    arrivalTime,
    departureDate,
    arrivalDate,
    departureWeek,
    arrivalWeek,
  } = opts;

  if (fromTrip) {
    wrappedStyles.push(styles.not_count);
  }

  const arrivalCityHtml = renderCity(ArrivalCity, arrivalAirportId, arrivalChanged);
  const departureCityHtml = renderCity(DepartureCity, departureAirportId, departureChanged);

  const differentOperatingAirline = segment[airlineType].ID !== OperatingAirline.ID && (
    <div className={ styles.operating_airline }>
      <img
        className={ styles.logo }
        src={ getImageLogo(OperatingAirline.ID) }
        title={ OperatingAirline.Name }
        alt={ OperatingAirline.Name }
      />
      <Text
        type='NORMAL_14'
        color='gray'
      >
        { LABELS.airline } { OperatingAirline.Name }
      </Text>
    </div>
  );

  const technicalStopStyles = `${styles.row} ${styles.technical_stop}`;

  const arrivalTerminalHtml = renderTerminal(arrivalTerminal.terminal, arrivalTerminal.different, ArrivalCity);
  const departureTerminalHtml = renderTerminal(departureTerminal.terminal, departureTerminal.different, DepartureCity);

  const renderTechnicalStop = () => {
    if (!TechnicalStop?.length && !ServiceStops) return null;

    if (TechnicalStop?.length) {
      return (
        <TechnicalStopText
          technicalStopInfo={ TechnicalStop }
          className={ technicalStopStyles }
        />
      );
    }

    return (
      <TechnicalStopS7Text
        technicalStopInfo={ ServiceStops as string[] }
        className={ technicalStopStyles }
      />
    );
  };

  return (
    <div className={ wrappedStyles.join(' ') }>
      <div className={ styles.row }>
        <div className={ styles.logo }>
          <img
            className={ styles.img }
            src={ getImageLogo(airlineId) }
            title={ segment[airlineType].Name }
            alt={ segment[airlineType].Name }
          />
        </div>
        <div className={ styles.item }>
          <div className={ styles.col_departure }>
            <Text>{departureDate}, {departureWeek}</Text>
            <Text
              type='bold_22'
              className={ styles.time }
            >
              { departureTime }
            </Text>
            { departureCityHtml }
            <Text
              type='NORMAL_14'
              className={ styles.airport_name }
            >
              { DepartureAirport.Name }
            </Text>
            { departureTerminalHtml }
          </div>
          <div className={ styles.col_airline }>
            <Text
              type='NORMAL_14'
              color='gray'
              className={ styles.airline_name }
            >
              <span>{ segment[airlineType].Name }</span>&ensp;
              <span>({airlineId} {FlightNumber})</span>
            </Text>
            <Text
              type='NORMAL_14'
              color='gray'
            >
              { AircraftName }
            </Text>
            <Icon type='smartdeskAir' className={ styles.icon } />
          </div>
          <div className={ styles.col_arrival }>
            <Text>{arrivalDate}, {arrivalWeek}</Text>
            <Text
              type='bold_22'
              className={ styles.time }
            >
              { arrivalTime }
            </Text>
            { arrivalCityHtml }
            <Text
              type='NORMAL_14'
              className={ styles.airport_name }
            >
              { ArrivalAirport.Name }
            </Text>
            { arrivalTerminalHtml }
          </div>
        </div>
      </div>
      { renderTechnicalStop() }
      { differentOperatingAirline }
      { changeDurationHtml }
    </div>
  );
};

export default AirlineItemSegment;
