import React, { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import styles from './styles/link.module.css';

const prepareLabel = (label: null | string, renderLabel: (() => ReactNode) | null) =>
  (renderLabel ? renderLabel() : label);

interface ILinkProps {
  name?: string | null;
  onClick?: () => void;
  className?: string;
  classLink?: string;
  solidLine?: boolean;
  emptyLine?: boolean;
  disabled?: boolean;
  reactRouterLink?: boolean;
  multilineBorder?: boolean;
  to?: string;
  children?: ReactNode | null;
  renderLabel?: (() => ReactNode) | null;
}

const Link = ({
  name = null,
  onClick = () => {},
  className = '',
  solidLine = false,
  emptyLine = false,
  disabled = false,
  reactRouterLink = false,
  multilineBorder = false,
  to = '',
  children = null,
  classLink = '',
  renderLabel = null,
}: ILinkProps) => {
  if (reactRouterLink) {
    return (
      <RouterLink to={ to } onClick={ onClick } className={ className }>
        { children }
      </RouterLink>
    );
  }

  const onClickFn = disabled ? () => {} : onClick;
  const label = prepareLabel(name, renderLabel);

  const link = multilineBorder ? (
    <p>
      <span onClick={ onClickFn } className={ classLink }>{ label }</span>
    </p>
  ) : (
    <a onClick={ onClickFn } className={ classLink }>{ label }</a>
  );

  const solidClass = solidLine ? styles.solid : '';
  const emptyClass = emptyLine ? styles.empty : '';
  const disabledClass = disabled ? styles.disabled : '';
  const classNames = `${styles.cancel} ${className} ${solidClass} ${emptyClass} ${disabledClass}`;

  return (
    <div className={ classNames }>
      { link }
    </div>
  );
};

export default Link;
