import React from 'react';

import styles from './styles/request-item-footer.module.css';

const RequestItemFooter = ({ employees }: { employees: string[] }) => {
  const employeesHtml = employees.map((employee, idx: number) => (
    <li key={ idx }>
      <span className={ styles.number }>{ idx + 1 }.</span>
      <span className={ styles.employee }> { employee }</span>
    </li>
  ));

  return (
    <div className={ styles.actions }>
      <ul className={ styles.employees }>
        { employeesHtml }
      </ul>
    </div>
  );
};

export default RequestItemFooter;
