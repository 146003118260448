import React from 'react';
import { Icon, IconButton, Text, Tooltip } from 'new-ui';

import { isSmartAgent } from '../../bi/utils/env';

import { ICheker } from './types';

import styles from './styles/index.module.css';

const Checker = ({
  value,
  title,
  subtitle,
  onChangeUp,
  onChangeDown,
  disabledUp,
  disabledDown,
  renderTooltip = () => null,
}: ICheker) => {
  const tooltipContent = () => {
    if (!renderTooltip()) {
      return null;
    }

    return (
      <Tooltip
        position='bottom'
        renderContent={ () => renderTooltip() }
      >
        <Icon
          type='question'
          alternativeDesign={ isSmartAgent }
        />
      </Tooltip>
    );
  };

  return (
    <div className={ styles.wrapper }>
      <div>
        <Text type='NORMAL_16' className={ styles.title }>
          { title }
        </Text>
        <div className={ styles.wrapper_subtitle }>
          <Text type='NORMAL_12' color='dim-gray' className={ styles.subtitle }>
            { subtitle }
          </Text>
          { tooltipContent() }
        </div>
      </div>
      <div className={ styles.wrapper_icon }>
        <IconButton
          iconType='minusRound'
          alternativeDesign={ isSmartAgent }
          iconColor='green'
          onClick={ onChangeDown }
          disabled={ disabledDown }
        />
        <Text color='default'>
          { String(value) }
        </Text>
        <IconButton
          iconType='plusRound'
          alternativeDesign={ isSmartAgent }
          onClick={ onChangeUp }
          disabled={ disabledUp }
        />
      </div>
    </div>
  );
};

export default Checker;
