import moment, { Moment } from 'moment';

interface IDiffTime {
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
}

export default (time: Moment | string, currentTime = moment()) => {
  let cloneTime = time;

  if (typeof time === 'string') {
    cloneTime = moment(time);
  }

  if ((cloneTime as Moment).isValid && (cloneTime as Moment).isValid()) {
    const diff = (cloneTime as Moment).diff(currentTime);
    const diffDuration = moment.duration(diff);

    const days = diffDuration.days();
    const hours = diffDuration.hours();
    const minutes = diffDuration.minutes();
    const seconds = diffDuration.seconds();

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  return null;
};

export type { IDiffTime };
