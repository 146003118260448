import React, { FC } from 'react';
import { StyledWrapper, Text, Icon } from 'new-ui';

import { getText } from '../../../i18n';

import { isSmartAgent } from '../../bi/utils/env';

import styles from './styles/index.module.css';

const LABELS = {
  TIME_IN: getText('components:hotelCheckinCheckoutPanel.timeIn'),
  TIME_OUT: getText('components:hotelCheckinCheckoutPanel.timeOut'),
};

interface HotelCheckinCheckoutPanelProps {
  checkin: string,
  checkout: string,
  className?: string,
  onRender?(ref: HTMLDivElement): void,
}

const HotelCheckinCheckoutPanel: FC<HotelCheckinCheckoutPanelProps> = ({
  onRender = () => {},
  checkin,
  checkout,
  className,
}) => {
  const classNames = [styles.wrapper];

  if (className) {
    classNames.push(className);
  }

  return (
    <StyledWrapper className={ classNames.join(' ') } ref={ onRender }>
      <Icon type='clock' alternativeDesign={ isSmartAgent }/>
      <div className={ styles.item }>
        <Text type='NORMAL_18'>{ checkin }</Text>
        <Text type='NORMAL_12' color='gray' className={ styles.hint }>{ LABELS.TIME_IN }</Text>
      </div>
      <div className={ styles.item }>
        <Text type='NORMAL_18'>{ checkout }</Text>
        <Text type='NORMAL_12' color='gray' className={ styles.hint }>{ LABELS.TIME_OUT }</Text>
      </div>
    </StyledWrapper>
  );
};

export { HotelCheckinCheckoutPanel };
