import React from 'react';

import AnalyticsSelect from '../AnalyticsSelect';

import { IAnalyticsType } from '../../bi/services/customAnalytics/types';
import { UserAnalytic } from '../../bi/types/order';

import styles from './styles.module.css';

interface IAnalyticsBarProps {
  withRequiredValidation?: boolean,
  disabled?: boolean,
  analyticsList: IAnalyticsType[],
  cartId?: number | null | string,
  tripId?: number | null,
  userAnalytics: number[] | UserAnalytic[],
  onSet?(id: number | string | null, customAnalyticsId: any): void,
  onUnset?(id: number | string | null, analyticsId: number): void,
  onAddAnalyticsValue?(analyticsId: number, data: string): void,
  hasUnsetRequiredTripAnalytics?: boolean,
  onlyNewAnalytics?: boolean,
  loading?: boolean;
}

const AnalyticsBar = ({
  withRequiredValidation = false,
  disabled = false,
  analyticsList = [],
  cartId = null,
  tripId = null,
  loading = false,
  userAnalytics = [],
  onSet = () => {},
  onUnset = () => {},
  onAddAnalyticsValue = () => {},
  hasUnsetRequiredTripAnalytics = false,
  onlyNewAnalytics = false,
}: IAnalyticsBarProps) => {
  if (!analyticsList.length) {
    return null;
  }

  let alreadyHighlighted = false;

  const customAnalyticsList: any[] =
    analyticsList.reduce((acc: any, analytics: IAnalyticsType) => {
      const customAnalyticsValue = analytics.Values.find(({ Id }) =>
        // @ts-ignore
        userAnalytics.includes(Id));

      if (disabled && !customAnalyticsValue) {
        return acc;
      }

      const id = cartId || tripId;

      alreadyHighlighted = !alreadyHighlighted && hasUnsetRequiredTripAnalytics && analytics.Required && !customAnalyticsValue;

      return [
        ...acc,
        (
          <div key={ analytics.Id } className={ styles.custom_analytics_item }>
            <AnalyticsSelect
              analyticsLoading={ loading }
              highlighted={ alreadyHighlighted }
              onlyNewAnalytics={ onlyNewAnalytics }
              barMode
              withRequiredValidation={ withRequiredValidation }
              readonly={ disabled }
              analytics={ analytics }
              // @ts-ignore
              value={ customAnalyticsValue }
              onSet={ ({ Id: customAnalyticsId }) => onSet(id, customAnalyticsId) }
              onUnset={ (analyticsId) => onUnset(id, analyticsId) }
              onAdd={ onAddAnalyticsValue }
            />
          </div>
        ),
      ];
    }, []);

  return (
    <div className={ styles.custom_analytics }>
      { customAnalyticsList }
    </div>
  );
};

export default AnalyticsBar;
