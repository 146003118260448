export enum LoadingStatus {
  NEVER,
  LOADING,
  LOADED,
  ERROR,
}

export interface StatusObjectProto<Return> {
  onLoaded: Return
  onError: Return
  onLoading: Return
  onNever: Return
}

interface StatusObjectWithFallback<Return> extends Partial<StatusObjectProto<Return>> {
  fallback: Return
}

export type StatusObject<Return> = StatusObjectProto<Return> | StatusObjectWithFallback<Return>;

export interface Config {
  contextRecord: boolean
}

export interface LoaderContext {
  callNumbers: Partial<Record<LoadingStatus, number>>
  axiosAbort?: AbortController
}

export const isLoaderContext = (ctx: any): ctx is LoaderContext => (typeof ctx === 'object');
