import React, { useState, useRef, useEffect, FC } from 'react';
import { Slider, Input, Text } from 'new-ui';

import { getText } from '../../../i18n';

import MoneyFormat from '../../bi/utils/money';

import styles from './styles/index.module.css';

const VALUESTYPES = {
  MIN: 'MIN',
  MAX: 'MAX',
};

const LABELS = {
  FROM: getText('components:timeSlider.from'),
  TO: getText('components:timeSlider.to'),
  RUB: '₽',
};

const DEFAULT_STEP = 500;

interface PriceSliderProps {
  min: number,
  max: number,
  start: number,
  end: number,
  step?: number,
  onSlide(array: number[], number: number): void,
  qaAttrPriceMin?: string,
  qaAttrPriceMax?: string,
}

const PriceSlider: FC<PriceSliderProps> = ({
  min,
  max,
  start,
  end,
  step = DEFAULT_STEP,
  onSlide,
  qaAttrPriceMin = '',
  qaAttrPriceMax = '',
}) => {
  const prevProps = useRef({ start, end });
  const debounceTimer = useRef<any>(null);
  const [localStart, setLocalStart] = useState(start);
  const [localEnd, setLocalEnd] = useState(end);

  useEffect(() => {
    if (prevProps.current.start !== start) {
      setLocalStart(start);
    }

    if (prevProps.current.end !== end) {
      setLocalEnd(end);
    }

    prevProps.current = {
      start,
      end,
    };
  }, [start, end]);

  const handleSlide = ({ min: cMin, max: cMax }: { min: number, max: number }) => {
    setLocalStart(cMin);
    setLocalEnd(cMax);

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // @ts-ignore
    debounceTimer.current = setTimeout(() => onSlide([cMin, cMax]), 300);
  };

  const handleChangeInput = (value: string, type: string) => {
    const rawValue = value.replace(/ /g, '');
    const numValue = parseInt(rawValue, 10) || 0;

    return type === VALUESTYPES.MIN
      ? handleSlide({ min: numValue, max: end })
      : handleSlide({ min: start, max: numValue });
  };

  const preparedMinValue = MoneyFormat.money(Math.floor(localStart));
  const preparedMaxValue = MoneyFormat.money(Math.ceil(localEnd));

  return (
    <div className={ styles.wrapper }>
      <Slider
        className={ styles.slider }
        min={ min }
        max={ max }
        value={ { min: localStart, max: localEnd } }
        step={ step }
        onChange={ handleSlide }
        debounceMs={ 0 }
      />
      <div className={ styles.description }>
        <div className={ styles.input }>
          <Text
            type='NORMAL_14'
            className={ styles.text }
          >
            { LABELS.FROM }
          </Text>
          <Input
            qaAttr={ qaAttrPriceMin }
            value={ preparedMinValue }
            debounceMs={ 0 }
            className={ styles['side-padding-fix'] }
            subText={ LABELS.RUB }
            onChange={ value => handleChangeInput(value, VALUESTYPES.MIN) }
          />
        </div>
        <div className={ styles.input }>
          <Text
            type='NORMAL_14'
            className={ styles.text }
          >
            { LABELS.TO }
          </Text>
          <Input
            qaAttr={ qaAttrPriceMax }
            value={ preparedMaxValue }
            debounceMs={ 0 }
            className={ styles['side-padding-fix'] }
            subText={ LABELS.RUB }
            onChange={ value => handleChangeInput(value, VALUESTYPES.MAX) }
          />
        </div>
      </div>
    </div>
  );
};

export { PriceSlider };
