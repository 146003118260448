import { observable, action, makeObservable } from 'mobx';

import STATUS from '../../../constants/employee';
import { DEFAULT_FEE_CONSTRUCTION } from '../../../constants/settings';

import {
  EmployeesType,
  IEmployee,
  IVoucherSettings,
  CompaniesType,
  IFeeConstruction,
} from '../types';

const DEFAULT_FILTER = {
  search: '',
  select: STATUS.ACCESS.ALL,
  companyId: 0,
};

export const BRAND_NAMES = {
  SMARTWAY: 'Smartway',
  SMARTAGENT: 'Smartagent',
  SMARTWAY_KZ: 'SmartwayKZ',
};

const DEFAULT_SETTINGS_VOUCHER = {
  AdditionalInfo: '',
  ContactInfo: '',
  LogoFileId: '',
  AccountId: 0,
  InterLogoFileId: '',
  CompanyId: 0,
  Id: 0,
  SuccessUpload: false,
  OriginalFileName: '',
  OriginalFileSize: '',
  IsLogo: false,
  loaderPreview: false,
  previewPayload: [{
    Key: '',
    Value: '',
  }],
  ErrorUplpadLogo: '',
  IsErrorLogo: true,
};

const DEFAULT_SETTINGS_VOUCHER_EDIT = {
  showUpload: false,
  fileName: '',
  fileSize: '',
  isLogo: false,
  showPreview: false,
  uploadSettings: false,
  loadProgress: '',
  isValid: false,
};

const DEFAULT_STATE = {
  employees: [],
  companies: [],
  company: [],
  filter: DEFAULT_FILTER,
  employeesIsFiltered: false,
  paginate: {
    currentPage: 1,
    itemsPerPage: 50,
    total: 0,
  },
  waitingResponse: true,
  employeesCount: 0,
  isEmployee: true,
  interNameCostCenter: '',
};

export interface ISettingsStore {
  employees: EmployeesType,
  companies: any[],
  prohibitionVouchers: any[],
  companiesWithCostCenter: any[],
  company: any[],
  filters: {
    search: string,
    select: string,
    companyId: number
  },
  employeesIsFiltered: boolean,
  paginate: {
    currentPage: number,
    itemsPerPage: number,
    total: number
  },
  waitingResponse: boolean,
  employeesCount: number,
  isEmployee: true,
  voucherSetting: {
    AccountId: number,
    AdditionalInfo: string,
    ContactInfo: string,
    LogoFileId: string,
    CompanyId: number,
    Id: number,
    SuccessUpload: boolean,
    OriginalFileName: string,
    OriginalFileSize: string,
    IsLogo: boolean,
    loaderPreview: boolean,
    previewPayload: [{
      Key: string,
      Value: any,
    }],
    ErrorUplpadLogo: string,
    IsErrorLogo: boolean,
  }
  editVoucherSetting: {
    showUpload: boolean,
    fileName: string,
    fileSize: string,
    isLogo: boolean,
    showPreview: boolean,
    uploadSettings: boolean,
    loadProgress: string,
    isValid: boolean,
  }
  interNameCostCenter: string,
  feeConstruction: IFeeConstruction[],
  feeWaitingResponse: boolean,
}

export interface IBrandName {
  AccountId: number,
  AccountUuid: string,
  BrandName: string,
  Id: string
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable employees = [];
  @observable companies = [];
  @observable prohibitionVouchers = [];
  @observable companiesWithCostCenter = [];
  @observable voucherSetting = DEFAULT_SETTINGS_VOUCHER;
  @observable editVoucherSetting = DEFAULT_SETTINGS_VOUCHER_EDIT;
  @observable company = [];
  @observable filter = DEFAULT_FILTER;
  @observable employeesIsFiltered = false;
  @observable paginate = {
    currentPage: 1,
    itemsPerPage: 50,
    total: 0,
  };
  @observable waitingResponse = true;
  @observable isEmployee = true;
  @observable employeesCount = 0;
  @observable whiteLabelCompanies = [];
  @observable interNameCostCenter = '';
  @observable feeConstruction = DEFAULT_FEE_CONSTRUCTION;
  @observable feeWaitingResponse = false;

  @observable brandName = BRAND_NAMES.SMARTWAY;

  @action
  setEmployees = (list: EmployeesType | any) => {
    this.employees = list.map((employee: IEmployee) =>
      ({ ...employee, readableTags: employee.Tags.map(({ Value }) => Value).join(', ') }));
    this.employeesIsFiltered = JSON.stringify(this.filter) !== JSON.stringify(DEFAULT_FILTER);
  };

  @action
  setInterNameCostCenter = (value = '') => {
    this.interNameCostCenter = value;
  };

  @action
  setCompaniesWithCostCenter = (companies: number[] | any) => {
    this.companiesWithCostCenter = companies;
  };

  @action
  getInterVoucherSettings = () => {
    this.editVoucherSetting.fileName = this.voucherSetting.OriginalFileName;
    this.editVoucherSetting.fileSize = this.voucherSetting.OriginalFileSize;
    this.editVoucherSetting.isLogo = this.voucherSetting.IsLogo;
  };

  @action
  setIsLogo = (value = false) => {
    this.editVoucherSetting.isLogo = value;
  };

  @action
  setShowUpload = (value: boolean) => {
    this.editVoucherSetting.showUpload = value;
  };

  @action
  setEditFile = ({ FileName, FileSize }: { FileName: string, FileSize: string }) => {
    this.editVoucherSetting.fileName = FileName;
    this.editVoucherSetting.fileSize = FileSize;
    this.editVoucherSetting.isLogo = true;
  };

  @action
  setResetFile = () => {
    this.editVoucherSetting.fileName = '';
    this.editVoucherSetting.fileSize = '';
    this.editVoucherSetting.isLogo = false;
  };

  @action
  setShowPreview = (value: boolean) => {
    this.editVoucherSetting.showPreview = value;
  };

  @action
  setIsValid = (value: boolean) => {
    this.editVoucherSetting.isValid = value;
  };

  @action
  setLoadProgress = (value = '') => {
    this.editVoucherSetting.loadProgress = value;
  };

  @action
  setSuccessUpload = (value: boolean) => {
    this.voucherSetting.SuccessUpload = value;
  };

  @action
  setVoucherSettings = (data: IVoucherSettings | any) => {
    this.voucherSetting = data;
    this.voucherSetting.InterLogoFileId = data.LogoFileId;
    this.voucherSetting.IsLogo = false;

    if (data?.LogoFileId || data?.isLogo) {
      this.voucherSetting.IsLogo = true;
      this.voucherSetting.IsErrorLogo = false;
    }

    this.voucherSetting.SuccessUpload = true;
    this.getInterVoucherSettings();
  };

  @action
  setValidationDownload = (isError: boolean, value = '') => {
    this.voucherSetting.IsErrorLogo = isError;
    this.voucherSetting.ErrorUplpadLogo = value;
  };

  @action
  setLoaderPreview = (value: boolean) => {
    this.voucherSetting.loaderPreview = value;
  };

  @action
  setShowEditVoucher = (value: boolean) => {
    // @ts-ignore
    this.voucherSetting.isLogo = value;
  };

  @action
  setInterLogo = (data = '') => {
    this.voucherSetting.InterLogoFileId = data;
  };

  @action
  setInterIsLogo = (value: boolean) => {
    this.editVoucherSetting.isLogo = value;
  };

  setItemPreview = (data: [{ Value: string, Key: string }]) => {
    this.voucherSetting.previewPayload = data;
  };

  @action
  setProhibitionVouchers = (companies: CompaniesType | any) => {
    this.prohibitionVouchers = companies;
  };

  @action
  setCompany = (list: CompaniesType | any) => {
    this.company = list;
  };

  @action
  setCompanies = (list: CompaniesType | any) => {
    this.companies = list;
  };

  @action
  setItemPerPage = (step: number) => {
    this.paginate = {
      ...this.paginate,
      itemsPerPage: step,
    };
  };

  @action
  setEmployeesFilters = (field: string, value: string) => {
    this.filter = {
      ...this.filter,
      [field]: value,
    };
  };

  @action
  setEmployeesCount = (res: number) => {
    this.employeesCount = res;
    this.paginate = {
      ...this.paginate,
      total: res,
    };
  };

  @action
  setPageEmployees = (page: number) => {
    this.paginate = {
      ...this.paginate,
      currentPage: page,
    };
  };

  @action
  setWaitingResponse = (waitingResponse: boolean) => {
    this.waitingResponse = waitingResponse;
  };

  @action
  setFeeWaitingResponse = (feeWaitingResponse: boolean) => {
    this.feeWaitingResponse = feeWaitingResponse;
  };

  @action
  setEmployeeFlag = (isEmployee: boolean) => {
    this.isEmployee = isEmployee;
  };

  @action
  resetFilters = (field: string, value: string, step: number) => {
    this.filter = {
      ...DEFAULT_FILTER,
      [field]: value,
    };
    this.paginate = {
      ...DEFAULT_STATE.paginate,
      itemsPerPage: step,
    };
    this.waitingResponse = DEFAULT_STATE.waitingResponse;
  };

  @action
  setFeeConstruction = (res: IFeeConstruction[]) => {
    this.feeConstruction = res;
  };

  setBrandNameError = () => {
    this.brandName = BRAND_NAMES.SMARTWAY;
  };

  getBrandName = (data: IBrandName | null) => {
    if (!data) {
      this.setBrandNameError();
    } else {
      this.brandName = data.BrandName;
    }
  };

  @action
  defaultStore = () => {
    this.filter = DEFAULT_FILTER;
    this.paginate = DEFAULT_STATE.paginate;
    this.employees = DEFAULT_STATE.employees;
    this.waitingResponse = DEFAULT_STATE.waitingResponse;
    this.employeesCount = DEFAULT_STATE.employeesCount;
    this.isEmployee = DEFAULT_STATE.isEmployee;
  };
}

const SettingsStore = new Store();

export { SettingsStore, Store as SettingsStoreType };
