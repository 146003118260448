import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Dialog, Text } from 'new-ui';

import { useServices } from '../../../../../bi/context/services';

import { MainAnalytic } from '../../../../../bi/utils/analytics';

import { LABELS } from '../../../consts';
import { EmployeeSuggest } from '../../../../../components/EmployeeSuggest';
import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';

import { LoadingFields } from '../../../../../bi/services/departments/types';
import { LoadingStatus } from '../../../../../bi/services/utils/network/types';
import { EmployeeType } from '../../../../../bi/services/employee/consts';

import styles from './styles.module.css';

export const EditHead = observer(() => {
  const {
    departmentsService: {
      setHead,
      networkStore,
      autocomplete,
      store: {
        closeDialog,
        form: { employeesHash, value: { headOfDepartment },
        },
      },
    },
  } = useServices(['Departments']);

  const [
    headOfDepartmentId, setHeadOfDepartmentId,
  ] = useState<number | null>(headOfDepartment?.Id || null);

  const loading = networkStore.getIsStatus(LoadingFields.setOrRemoveEmployee, LoadingStatus.LOADING);

  const mappedHeadOfDepartment = headOfDepartmentId ? employeesHash[headOfDepartmentId] : {};

  const handleSelectHeadOfDepartment = ({ Id }: { Id: number }) => {
    setHeadOfDepartmentId(Id);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.DEPARTMENTS_ADD_DIRECTOR);
  };

  const handleSubmit = () => headOfDepartmentId !== null && setHead(headOfDepartmentId);

  return (
    <Dialog
      show
      showClosing
      onChange={ closeDialog }
    >
      <div className={ styles.dialog }>
        <Text type='bold_20'>
          { LABELS.HEAD_OF_DEPARTMENT }
        </Text>
        <EmployeeSuggest
          canAddEmployee={ false }
          onFetch={ autocomplete as (v: string) => Promise<EmployeeType[]> }
          onSelect={ handleSelectHeadOfDepartment as any }
          value={ mappedHeadOfDepartment }
          qaAttrInputEmployee={ QA_ATTRIBUTES.settings.departments.create.leaderInput }
          qaAttrFirstEl={ QA_ATTRIBUTES.settings.departments.create.leaderFirstElement }
        />
        <div className={ styles.bottom }>
          <Button
            type='primary'
            onClick={ handleSubmit }
            loading={ loading }
            disabled={ headOfDepartmentId === null }
            qaAttr={ QA_ATTRIBUTES.settings.departments.create.addButton }
          >
            { LABELS.DIALOG_SUBMIT }
          </Button>
          <Button
            onClick={ closeDialog }
            type='textual-medium'
          >
            { LABELS.DIALOG_CANCEL }
          </Button>
        </div>
      </div>
    </Dialog>
  );
});
