import { action, makeObservable, observable } from 'mobx';

import { PAYMENT_METHODS, PaymentMethodTypes, CARD_FORM, ERRORS_CARD_FORM } from '../../types/paymentMethod';

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable accountId = '';
  @observable paymentMethod: PaymentMethodTypes = PAYMENT_METHODS.ORGANIZATION_ACCOUNT;
  @observable cardForm: CARD_FORM = {
    cardNumber: '',
    expDate: '',
    cvv: '',
  };
  @observable cardFormErrors: ERRORS_CARD_FORM;
  @observable cryptogram = '';
  @observable amountOfDeposit = '';
  @observable commission = '250';
  @observable email = '';
  @observable canPayWithPersonalFunds = false;
  @observable loading = false;

  @action
  setAccountId = (accountId: string) => {
    this.accountId = accountId;
  };

  @action
  setCanPayWithPersonalFunds = (value: boolean) => {
    this.canPayWithPersonalFunds = value;
  };

  @action
  setPaymentMethod = (paymentMethod: PaymentMethodTypes) => {
    this.paymentMethod = paymentMethod;
  };

  @action
  setCardNumber = (value: string) => {
    this.cardForm.cardNumber = value;
  };

  @action
  setExpDate = (value: string) => {
    this.cardForm.expDate = value;
  };

  @action
  setCvv = (value: string) => {
    this.cardForm.cvv = value;
  };

  @action
  setCardFormErrors = (value: ERRORS_CARD_FORM) => {
    this.cardFormErrors = value;
  };

  @action
  setCryptogram = (value: string) => {
    this.cryptogram = value;
  };

  @action
  setAmountOfDeposit = (value: string) => {
    this.amountOfDeposit = value;
  };

  @action
  setCommission = (value: string) => {
    this.commission = value;
  };

  @action
  setEmail = (value: string) => {
    this.email = value;
  };

  @action
  setIsLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  clearStore = () => {
    this.cardForm = {
      cardNumber: '',
      expDate: '',
      cvv: '',
    };
    this.email = '';
    this.commission = '250';
    this.amountOfDeposit = '';
    this.cryptogram = '';
    this.cardFormErrors = '' as unknown as ERRORS_CARD_FORM;
  };
}
const PaymentMethodsStore = new Store();
export { PaymentMethodsStore, Store as CloudPaymentStoreType };
