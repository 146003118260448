import React, { Component } from 'react';
import { Dialog, Dropdown, Text } from 'new-ui';

import { getText, getTextArray } from '../../../i18n';

import { getImageImageLogo } from '../../bi/utils/airline';

import { CODE_IMAGE_BIG } from '../../bi/constants/airline';

import styles from './styles/index.module.css';

const AIRLINELOGOHEIGHT = '35px';
const SUIMG = getImageImageLogo(CODE_IMAGE_BIG.SU);
const SSEVENIMG = getImageImageLogo(CODE_IMAGE_BIG.S7);

const LABELS = {
  title: getText('components:modalBonus.title'),
  subTitle: getText('components:modalBonus.subTitle'),
  bonusSize: getText('components:modalBonus.bonusSize'),
  saleSize: getText('components:modalBonus.saleSize'),
  byTariff: getText('components:modalBonus.byTariff'),
  firstBonusPercent: getText('components:modalBonus.firstItem.bonusPersent'),
  firstSalePercent: getText('components:modalBonus.firstItem.salePercent'),
  firstSaleText: getText('components:modalBonus.firstItem.saleText'),
  secondSaleText: getText('components:modalBonus.secondItem.saleText'),
  secondSalePercent: getText('components:modalBonus.secondItem.salePercent'),
  secondBonusPercent: getText('components:modalBonus.secondItem.bonusPersent'),
  whyNeed: getText('components:modalBonus.whyNeed'),
  becomeClient: getText('components:modalBonus.becomeClient'),
  toTravelHelper: getText('components:modalBonus.toTravelHelper'),
  signDocs: getText('components:modalBonus.signDocs'),
  afterTenDays: getText('components:modalBonus.afterTenDays'),
  faq: getText('components:modalBonus.faq'),
  questions: getTextArray('components:modalBonus.questions'),
};

interface ModalBonusProps {
  show: boolean;
  onClose(): void;
}

type Questions = { preview: string; full: string }[];

export default class ModalBonus extends Component<ModalBonusProps> {
  renderLabelDropDown = (label: string) => (
    <Text type='NORMAL_18' className={ styles.label }>{ label }</Text>
  );

  render() {
    const { show, onClose } = this.props;

    const questionsHtml = (LABELS.questions as Questions).map(({ preview, full }, index: number) => (
      <div key={ `question_${index}` } className={ styles.question }>
        <Dropdown
          theme='secondary'
          renderLabel={ () => this.renderLabelDropDown(preview) }
          onClick={ () => {} }
        >
          <Text type='NORMAL_16_140'>
            { full }
          </Text>
        </Dropdown>
      </div>
    ));

    return (
      <Dialog
        show={ show }
        showClosing
        onChange={ onClose }
      >
        <div className={ styles.wrapped }>
          <div className={ styles.header }>
            <Text type='bold_32'>
              { LABELS.title }
            </Text>
            <Text className={ styles.sub_title }>
              { LABELS.subTitle }
            </Text>
          </div>
          <div className={ styles.block }>
            <Text type='bold_20'>
              { LABELS.bonusSize }
            </Text>
            <div className={ styles.info_block }>
              <div className={ styles.info }>
                <div className={ styles.img }>
                  <img src={ SUIMG } alt='SU' height={ AIRLINELOGOHEIGHT } />
                </div>
                <div>
                  <Text type='bold_24' className={ styles.text_big }>
                    { LABELS.firstBonusPercent }
                  </Text>
                  <Text>
                    { LABELS.byTariff }
                  </Text>
                </div>
              </div>
              <div className={ styles.info }>
                <div className={ styles.img }>
                  <img src={ SSEVENIMG } alt='S7' height={ AIRLINELOGOHEIGHT } />
                </div>
                <div>
                  <Text type='bold_24' className={ styles.text_big }>
                    { LABELS.secondBonusPercent }
                  </Text>
                  <Text>
                    { LABELS.byTariff }
                  </Text>
                </div>
              </div>
            </div>
            <Text type='bold_20'>
              { LABELS.saleSize }
            </Text>
            <div className={ styles.info_block }>
              <div className={ styles.info }>
                <div className={ styles.img }>
                  <img src={ SUIMG } alt='SU' height={ AIRLINELOGOHEIGHT } />
                </div>
                <div>
                  <Text type='bold_24' className={ styles.text_big }>
                    { LABELS.firstSalePercent }
                  </Text>
                  <Text>
                    { LABELS.firstSaleText }
                  </Text>
                </div>
              </div>
              <div className={ styles.info }>
                <div className={ styles.img }>
                  <img src={ SSEVENIMG } alt='S7' height={ AIRLINELOGOHEIGHT } />
                </div>
                <div>
                  <Text type='bold_24' className={ styles.text_big }>
                    { LABELS.secondSalePercent }
                  </Text>
                  <Text>
                    { LABELS.secondSaleText }
                  </Text>
                </div>
              </div>
            </div>
            <Text type='bold_20'>
              { LABELS.whyNeed }
            </Text>
            <div className={ styles.info_block_two }>
              <div className={ styles.info }>
                <div className={ styles.img }>
                  <Text type='bold_24' color='accent' className={ styles.round }>1</Text>
                  <div className={ styles.border } />
                </div>
                <Text type='NORMAL_16_140' className={ styles.text }>Стать клиентом Smartway</Text>
              </div>
              <div className={ styles.info }>
                <div className={ styles.img }>
                  <Text type='bold_24' color='accent' className={ styles.round }>2</Text>
                  <div className={ styles.border } />
                </div>
                <Text type='NORMAL_16_140' className={ styles.text }>
                  { LABELS.toTravelHelper }
                </Text>
              </div>
              <div className={ styles.info }>
                <div className={ styles.img }>
                  <Text type='bold_24' color='accent' className={ styles.round }>3</Text>
                  <div className={ styles.border } />
                </div>
                <Text type='NORMAL_16_140' className={ styles.text }>
                  { LABELS.signDocs }
                </Text>
              </div>
              <div className={ styles.info }>
                <Text type='bold_24' color='accent' className={ styles.round }>4</Text>
                <Text type='NORMAL_16_140' className={ styles.text }>
                  { LABELS.afterTenDays }
                </Text>
              </div>
            </div>
            <Text type='bold_20'>
              { LABELS.faq }
            </Text>
            <div className={ styles.questions }>{ questionsHtml }</div>
          </div>
        </div>
      </Dialog>
    );
  }
}
