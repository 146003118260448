import React, { useState } from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../../../../i18n';

import MultiPassangers from '../../../../MultiPassangers';
import Checker from '../../../../Checker';

import { TYPE_ACTION, TYPE_PASS } from '../../../../MultiPassangers/const';
import { PASSANGERS, TYPE_AIR_SEARCH } from '../../const';

import { IChildData } from '../../../../../bi/types/airline';
import { IChildPassangers, TPassengers } from '../../types';

import styles from '../MainMenu/styles.module.css';

const LABELS = {
  TRAVELLERS: getText('components:menu.air.main.passengers'),
  CHECKER: {
    ADULT_TITLE: getText('components:menu.air.checker.adults'),
    ADULT_SUBTITLE: getText('components:menu.air.checker.subAdults'),
    CHILDREN_TITLE: getText('components:menu.air.checker.child'),
    CHILDREN_SUBTITLE: getText('components:menu.air.checker.subChild'),
    INFANT_TITLE: getText('components:menu.air.checker.infant'),
    INFANT_SUBTITLE: getText('components:menu.air.checker.subInfant'),
    INFANT_INFO: getText('components:menu.air.checker.infantInfo'),
  },
};

const ChildrenPassangers = ({
  handleChangeAdult,
  handleChangeChild,
  airlineService,
  type,
  colorText,
}: IChildPassangers) => {
  const {
    travellers: sumTraveller,
    travellersSA: {
      adult: adults,
      children: childrens,
      infant: infants,
    },
  } = airlineService.getSearch();

  const [travellers, setTravellers] = useState(sumTraveller);
  const [passangers, setPassangers] = useState<TPassengers>({
    adult: adults,
    infant: infants,
    children: childrens,
  });

  const { adult, infant, children } = passangers;

  const isDisabledUp = adult + children === PASSANGERS.MAX;
  const isDisabledUpInfant = adult < infant + PASSANGERS.ONE;

  const renderTooltip = () => (
    <Text color='white' className={ styles.tooltip }>
      { LABELS.CHECKER.INFANT_INFO }
    </Text>
  );

  const renderLabel = (label: string, style: string) => (
    <Text
      type='NORMAL_14'
      className={ style }
      color={ colorText }
    >
      { label }
    </Text>
  );

  const handleSetAdult = async (types: string, action: string, value: number) => {
    const updatedPassangers = { ...passangers };

    if (action === TYPE_ACTION.PLUS) {
      updatedPassangers[types] = value + PASSANGERS.ONE;

      setPassangers(updatedPassangers);
      setTravellers(travellers + PASSANGERS.ONE);
      handleChangeAdult(travellers + PASSANGERS.ONE);
    }

    if (action === TYPE_ACTION.MINUS &&
      ((types === TYPE_PASS.ADULTS && value > PASSANGERS.ONE) || (types !== TYPE_PASS.ADULTS && value > 0))
    ) {
      updatedPassangers[types] = value - PASSANGERS.ONE;

      setPassangers(updatedPassangers);
      setTravellers(travellers - PASSANGERS.ONE);
      handleChangeAdult(travellers - PASSANGERS.ONE);
    }

    handleChangeChild(updatedPassangers as IChildData);
  };

  const renderMultiPassangers = () => (
    <MultiPassangers
      sum={ travellers }
      type={ type }
    >
      <Checker
        title={ LABELS.CHECKER.ADULT_TITLE }
        subtitle={ LABELS.CHECKER.ADULT_SUBTITLE }
        value={ adult }
        disabledUp={ isDisabledUp }
        disabledDown={ adult === PASSANGERS.ONE }
        onChangeUp={ () => handleSetAdult(TYPE_PASS.ADULTS, TYPE_ACTION.PLUS, adult) }
        onChangeDown={ () => handleSetAdult(TYPE_PASS.ADULTS, TYPE_ACTION.MINUS, adult) }
      />
      <Checker
        title={ LABELS.CHECKER.CHILDREN_TITLE }
        subtitle={ LABELS.CHECKER.CHILDREN_SUBTITLE }
        disabledUp={ isDisabledUp }
        disabledDown={ !children }
        value={ children }
        onChangeUp={ () => handleSetAdult(TYPE_PASS.CHILDREN, TYPE_ACTION.PLUS, children) }
        onChangeDown={ () => handleSetAdult(TYPE_PASS.CHILDREN, TYPE_ACTION.MINUS, children) }
      />
      <Checker
        title={ LABELS.CHECKER.INFANT_TITLE }
        subtitle={ LABELS.CHECKER.INFANT_SUBTITLE }
        value={ infant }
        disabledDown={ !infant }
        disabledUp={ isDisabledUpInfant }
        onChangeUp={ () => handleSetAdult(TYPE_PASS.INFANT, TYPE_ACTION.PLUS, infant) }
        onChangeDown={ () => handleSetAdult(TYPE_PASS.INFANT, TYPE_ACTION.MINUS, infant) }
        renderTooltip={ renderTooltip }
      />
    </MultiPassangers>
  );

  const renderContent = () => {
    if (type === TYPE_AIR_SEARCH.MAIN) {
      return (
        <>
          { renderLabel(LABELS.TRAVELLERS, styles.label) }
          { renderMultiPassangers() }
        </>
      );
    }

    return renderMultiPassangers();
  };

  return renderContent();
};

export default ChildrenPassangers;
