import React from 'react';
import { Text, IconButton, Stars, Icon } from 'new-ui';
import { getText } from '../../../i18n';

import { isSmartAgent, svgColor } from '../../bi/utils/env';

import CLASSNAMESFORTESTS from '../../bi/constants/classnamesfortests';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { FavoriteAction } from '../FavoriteAction';

import styles from './styles/index.module.css';

const LABELS = {
  REVIEWS: getText('components:hotelNameBlock.reviews'),
  CLOSE_WINDOW: getText('components:hotelNameBlock.closeWindow'),
};

interface HotelNameBlockProps {
  name: string
  stars: number,
  aggregationId?: number | null,
  favoriteId?: string,
  isDetails?: boolean
  isSmart?: boolean
  qaAttrText?: string
  qaAttrStars?: string,
  qaAttrFavorites?: string
  qaAttrReviews?: string,
  onClose?(): void,
  onFavoriteToggle(action: string): void,
  onClickReviews?(): void,
}

const HotelNameBlock = ({
  favoriteId,
  aggregationId = null,
  stars,
  name,
  isDetails,
  isSmart,
  qaAttrText,
  qaAttrStars,
  qaAttrFavorites,
  qaAttrReviews,
  onClose,
  onFavoriteToggle,
  onClickReviews,
}: HotelNameBlockProps) => {
  const favoritesHtml = !isSmartAgent && (
    <div className={ styles.favorites }>
      <FavoriteAction
        qaAttr={ qaAttrFavorites }
        id={ favoriteId }
        className={ CLASSNAMESFORTESTS.HOTELS.TOFAVORITE }
        onClick={ onFavoriteToggle }
        qaAttrDialog={ QA_ATTRIBUTES.favorites.deleteDialog }
      />
    </div>
  );

  if (isDetails) {
    const smartholtelIcon = isSmart && !isSmartAgent && aggregationId && (
      <div className={ styles.icon }>
        <Icon
          type='smartHotel'
          alternativeDesign={ isSmartAgent }
        />
      </div>
    );

    const nameClasses = [styles.name];

    if (isSmart) {
      nameClasses.push(styles['with-smart']);
    }

    return (
      <div className={ styles['details-wrapper'] }>
        <div className={ styles.info }>
          { smartholtelIcon }
          <Text type='NORMAL_18' className={ nameClasses.join(' ') }>
            <span data-qa={ qaAttrText }>{ name }</span>
            <div className={ styles.stars }>
              <Stars qaAttr={ qaAttrStars } count={ stars } />
            </div>
          </Text>
        </div>
        <div className={ styles.actions }>
          { favoritesHtml }
          <IconButton
            className={ styles.close }
            onClick={ onClose }
            iconType='closeButtonRound'
            iconColor={ svgColor }
          >
            <Text type='NORMAL_14' >
              { LABELS.CLOSE_WINDOW }
            </Text>
          </IconButton>
        </div>
      </div>
    );
  }

  const reviewsContent = onClickReviews && (
    <IconButton
      iconType='chat'
      onClick={ onClickReviews }
      iconColor={ svgColor }
      alternativeDesign={ isSmartAgent }
    >
      <Text qaAttr={ qaAttrReviews } type='NORMAL_14' >{ LABELS.REVIEWS }</Text>
    </IconButton>
  );

  return (
    <div className={ styles.wrapper }>
      <Text type='bold_32' className={ styles.name }>
        <span data-qa={ qaAttrText }>{name}</span>
        <div className={ styles.stars }>
          <Stars qaAttr={ qaAttrStars } count={ stars } />
        </div>
      </Text>
      <div className={ styles.actions }>
        { reviewsContent }
        { favoritesHtml }
      </div>
    </div>
  );
};

HotelNameBlock.defaultProps = {
  favoriteId: null,
  qaAttrText: '',
  qaAttrStars: '',
  qaAttrFavorites: '',
  qaAttrReviews: '',
  onClickReviews: () => {},
  onClose: () => {},
};

export { HotelNameBlock };
