// @ts-nocheck
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip, Icon, Text, BurgerMenu, DynamicLoginBurgerMenu, PROPS } from 'new-ui';
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';

import { getText } from '../../../../../i18n';

import ItemWrap from '../ItemWrap';

import { isSmartAgent } from '../../../../bi/utils/env';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import ROUTES from '../../../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './styles/index.module.css';

const {
  ICON: { TYPES: { MENU: { FAVORITE, NOTE }, WARNING }, COLORS: { DEFAULT, YELLOW } },
  TEXT: { TYPES: { NORMAL_14, NORMAL_14_130 } },
  TOOLTIP: { POSITION: { BOTTOM } },
} = PROPS;

const LABELS = {
  qualityControl: getText('components:header.headerItems.qualityControl'),
  note: getText('components:header.headerItems.note'),
  settings: getText('components:header.settings'),
  exit: getText('components:header.exit'),
  favorites: getText('components:header.headerItems.favorites'),
  TRAVEL_HELPER: getText('components:header.headerItems.travelHelper'),
  user: getText('constants:checkout.duplicate.user'),
  quit: getText('components:header.quit'),
  INTERCOM_WARNING: {
    ONE: getText('components:header.headerItems.intercomWarning.one'),
    TWO: getText('components:header.headerItems.intercomWarning.two'),
    THREE: getText('components:header.headerItems.intercomWarning.three'),
  },
  INTERCOM_WARNING_SA: {
    ONE: getText('components:header.headerItems.intercomWarning.one'),
    TWO: getText('components:header.headerItems.intercomWarning.two'),
    THREE: getText('components:header.headerItems.intercomWarning.three'),
  },
  MODE: {
    AGENT: getText('components:header.mode.agent'),
    CLIENT: getText('components:header.mode.client'),
  },
};

class MainHeaderItems extends Component {
  static propTypes = {
    appService: PropTypes.object.isRequired,
    noteItemsCount: PropTypes.number.isRequired,
    favoriteItemsCount: PropTypes.number.isRequired,
    isDemo: PropTypes.bool.isRequired,
    handleLogout: PropTypes.func.isRequired,
    onOpenTravelChat: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onChangeAgentMode: PropTypes.func.isRequired,
    qaAttrFavorites: PropTypes.string,
    qaAttrFavoritesIcon: PropTypes.object,
    sendIntercomAfterLoadPage: PropTypes.func.isRequired,
    getShowElementForSmartagent: PropTypes.bool.isRequired,
    accountRights: PropTypes.object.isRequired,
  };

  static defaultProps = {
    qaAttrFavorites: '',
    qaAttrFavoritesIcon: {
      default: '',
      yellow: '',
    },
  };

  state = {
    showIntercomWarning: false,
    enterOnMenu: false,
    showMenuList: false,
  };

  intervalId;

  componentDidMount() {
    this.intervalId = setInterval(() => this.getIntercomWarning(), 2000);
    setTimeout(this.sendErrorToProductAnalytics, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  getIntercomWarning = () => {
    const intercomIframe = document.getElementById('intercom-frame');

    if (
      (intercomIframe && !window.carrotquest)
      || (!intercomIframe && window.carrotquest)
      || (intercomIframe && window.carrotquest)
    ) {
      this.setState({ showIntercomWarning: false });

      return clearInterval(this.intervalId);
    }

    return this.setState({ showIntercomWarning: true });
  };

  sendErrorToProductAnalytics = () => {
    const intercomIframe = document.getElementById('intercom-frame');

    if (!intercomIframe) {
      this.props.sendIntercomAfterLoadPage();
    }
  };

  renderFavorite = () => {
    const {
      favoriteItemsCount,
      qaAttrFavorites,
      qaAttrFavoritesIcon,
      onClick,
      getShowElementForSmartagent,
    } = this.props;

    if (isSmartAgent) return null;

    const favoriteIcon = favoriteItemsCount ? qaAttrFavoritesIcon.yellow : qaAttrFavoritesIcon.default;
    const iconColor = favoriteItemsCount ? YELLOW : DEFAULT;

    const label = (
      <MediaQuery minWidth={ 750 }>{LABELS.favorites}</MediaQuery>
    );

    return (
      <div className={ styles.item }>
        <ItemWrap
          subItem
          href={ ROUTES.FAVORITES }
          getIcon={ () => FAVORITE }
          getIconColor={ () => iconColor }
          label={ label }
          getShowElementForSmartagent={ getShowElementForSmartagent }
          onClick={ () => onClick(LABELS.favorites, 0) }
          qaAttr={ qaAttrFavorites }
          qaAttrIcon={ favoriteIcon }
        />
      </div>
    );
  };

  renderNote = () => {
    const {
      noteItemsCount,
      onClick,
      getShowElementForSmartagent,
    } = this.props;

    if (isSmartAgent && getShowElementForSmartagent) {
      return this.renderTravelHelper();
    }

    const clssNames = isSmartAgent ? 'item none_margin' : 'item';

    const label = (
      <MediaQuery minWidth={ 750 }>
        { LABELS.note }
      </MediaQuery>
    );

    return (
      <div className={ styles[`${clssNames}`] }>
        <ItemWrap
          subItem
          count={ noteItemsCount }
          href={ ROUTES.NOTE.MAIN }
          getIcon={ () => NOTE }
          label={ label }
          onClick={ () => onClick(LABELS.note, noteItemsCount) }
          className={ styles['note-link'] }
          getShowElementForSmartagent={ getShowElementForSmartagent }
          qaAttr={ QA_ATTRIBUTES.header.note }
          qaAttrCounter={ QA_ATTRIBUTES.header.noteCounter }
        />
      </div>
    );
  };

  renderTravelHelper = () => {
    const { onOpenTravelChat } = this.props;

    if (!isSmartAgent) {
      return null;
    }

    const handleTravelChat = () => {
      MainAnalytic.sendAmplitudeArrayArgs(MainAnalytic.ACTIONS.SMART_AGENT.REDISIGN.MAIN_SMARTHELP_OPENED('header'));

      onOpenTravelChat();
    };

    return (
      <div onClick={ handleTravelChat }>
        <Text
          className={ `${styles.travel_helper} ${styles.travel_header}` }
          type={ NORMAL_14 }
        >
          { LABELS.TRAVEL_HELPER }
        </Text>
      </div>
    );
  };

  handleEnterOnMenu = value => this.setState({ enterOnMenu: value });

  handleShowMenuList = value => this.setState({ showMenuList: value, enterOnMenu: value });

  handleChangeAgentMode = (value: boolean) => {
    const { onChangeAgentMode } = this.props;

    onChangeAgentMode(value);
  };

  getBurgerItems = (isDynamic) => {
    const {
      accountRights: { Login: accountLogin },
      handleLogout,
      onOpenTravelChat,
    } = this.props;

    const commonItems = [
      {
        label: LABELS.settings,
        link: ROUTES.SETTINGS.MAIN,
        onItemClick: null,
      },
      {
        label: LABELS.quit,
        link: '',
        onItemClick: () => handleLogout(),
      },
    ];

    const dynamicItems = isDynamic
      ? [
        {
          label: accountLogin,
          link: '',
          onItemClick: null,
        },
      ]
      : [
        {
          label: LABELS.qualityControl,
          link: ROUTES.QUALITY.GENERAL,
          onItemClick: null,
        },
        {
          label: LABELS.TRAVEL_HELPER,
          link: '',
          onItemClick: () => onOpenTravelChat(),
        },
      ];

    return [...dynamicItems, ...commonItems];
  };

  getNewBurgerItems = () => ([
    {
      label: LABELS.settings,
      link: `${ROUTES.SETTINGS.MAIN}`,
      onItemClick: null,
    },
    {
      label: LABELS.exit,
      link: '',
      onItemClick: () => this.props.handleLogout(),
    },
  ]);

  renderBeforeRdesignNote = () => {
    const { getShowElementForSmartagent } = this.props;

    const wrapper = isSmartAgent && getShowElementForSmartagent
      ? `${styles.with_border} ${styles.no_border}`
      : styles.with_border;

    return (
      <div className={ wrapper }>
        { this.renderNote() }
      </div>
    );
  };

  renderOldNotePade = () => {
    if (isSmartAgent && !this.props.getShowElementForSmartagent) {
      return this.renderBeforeRdesignNote();
    }

    return null;
  };

  renderAlternativeMenu = () => {
    const { enterOnMenu, showMenuList } = this.state;
    const { getShowElementForSmartagent } = this.props;

    const iconColor = enterOnMenu || showMenuList
      ? 'second_green'
      : 'default';

    return (
      <>
        { this.renderOldNotePade() }
        { this.renderMode(getShowElementForSmartagent) }
        <div
          onMouseEnter={ () => this.handleEnterOnMenu(true) }
          onMouseLeave={ () => this.handleEnterOnMenu(false) }
          className={ `${styles.with_border} ${styles.none_padding}` }
        >
          <BurgerMenu
            icon='burgerMenu'
            listPosition='right'
            items={ this.getBurgerItems(false) }
            className={ styles.burger_menu }
            onClick={ () => this.handleShowMenuList(!this.state.showMenuList) }
            onBlur={ () => this.handleShowMenuList(false) }
            iconColor={ iconColor }
          />
        </div>
      </>
    );
  };

  renderNotePade = () => {
    if (isSmartAgent && this.props.getShowElementForSmartagent) {
      return this.renderBeforeRdesignNote();
    }

    return null;
  };

  renderMode = (isTypeDesign = false) => {
    const { agentMode } = this.props.appService.get();

    if (!isSmartAgent) return null;

    const wrapper = isTypeDesign
      ? `${styles.with_border} ${styles.none_padding} ${styles.no_border}`
      : `${styles.with_border} ${styles.none_padding} ${styles.with_border_eyes}`;

    if (agentMode) {
      return (
        <div
          className={ wrapper }
          onClick={ () => this.handleChangeAgentMode(!agentMode) }
        >
          <Tooltip
            bodyClassName={ styles.tooltipItem }
            position={ BOTTOM }
            renderContent={ () => (
              <div onClick={ (event) => event.stopPropagation() }>
                <Text type='NORMAL_14_130' color='white' className={ styles.tooltipEye }>
                  { LABELS.MODE.AGENT }
                </Text>
              </div>
            ) }
          >
            <Icon
              isShow
              type='avaEye'
              color='orange'
            />
          </Tooltip>
        </div>
      );
    }

    return (
      <div
        className={ wrapper }
        onClick={ () => this.handleChangeAgentMode(!agentMode) }
      >
        <Tooltip
          bodyClassName={ styles.tooltipItem }
          position={ BOTTOM }
          renderContent={ () => (
            <div onClick={ (event) => event.stopPropagation() }>
              <Text type='NORMAL_14_130' color='white' className={ styles.tooltipEye }>
                { LABELS.MODE.CLIENT }
              </Text>
            </div>
          ) }
        >
          <Icon type='avaEye' color='orange'/>
        </Tooltip>
      </div>
    );
  };

  renderAfterRedisignMenu = () => {
    const { getShowElementForSmartagent } = this.props;
    const capitalizedUser = LABELS.user.charAt(0).toUpperCase() + LABELS.user.slice(1);

    return (
      <>
        { this.renderNotePade() }
        { this.renderMode(getShowElementForSmartagent) }
        <div className={ `${styles.with_border} ${styles.none_padding} ${styles.no_border}` }>
          <DynamicLoginBurgerMenu
            icon='burgerMenu'
            listPosition='right'
            items={ this.getBurgerItems(true) }
            className={ styles.burger_menu }
            onClick={ () => this.handleShowMenuList(!this.state.showMenuList) }
            onBlur={ () => this.handleShowMenuList(false) }
            showIcon
            userText={ capitalizedUser }
          />
        </div>
      </>
    );
  };

  renderMenuBurger = () => {
    if (isSmartAgent && !this.props.getShowElementForSmartagent) {
      return this.renderAlternativeMenu();
    }

    return this.renderAfterRedisignMenu();
  };

  renderIntercomWarning = () => {
    const { isDemo } = this.props;
    const { showIntercomWarning } = this.state;

    if (showIntercomWarning && !isDemo) {
      const intercomTextOne = isSmartAgent ? LABELS.INTERCOM_WARNING_SA.ONE : LABELS.INTERCOM_WARNING.ONE;
      const intercomTextTwo = isSmartAgent ? LABELS.INTERCOM_WARNING_SA.TWO : LABELS.INTERCOM_WARNING.TWO;
      const intercomTextThree = isSmartAgent ? LABELS.INTERCOM_WARNING_SA.THREE : LABELS.INTERCOM_WARNING.THREE;

      return (
        <Tooltip
          position={ BOTTOM }
          className={ styles['intercom-warning-wrapper'] }
          renderContent={ () => (
            <div className={ styles.content }>
              <Text className={ styles.text } type={ NORMAL_14_130 }>
                { intercomTextOne }
              </Text>
              <Text className={ styles.text } type={ NORMAL_14_130 }>
                { intercomTextTwo }
              </Text>
              <Text className={ styles.text } type={ NORMAL_14_130 }>
                { intercomTextThree }
              </Text>
            </div>
          ) }
        >
          <Icon type={ WARNING } />
        </Tooltip>
      );
    }

    return null;
  };

  renderMenu = () => {
    const {
      onOpenTravelChat,
      onClick,
    } = this.props;

    return (
      <>
        <MediaQuery minWidth={ 820 }>
          <div className={ `${styles.item}` }>
            <div onClick={ onOpenTravelChat }>
              <Text
                className={ styles.travel_helper }
                type='NORMAL_14'
              >
                { LABELS.TRAVEL_HELPER }
              </Text>
            </div>
            { this.renderIntercomWarning() }
          </div>
        </MediaQuery>
        <MediaQuery minWidth={ 970 }>
          <div
            className={ `${styles.item} ${styles.quality_item}` }
            onClick={ () => onClick(LABELS.qualityControl, 0) }
          >
            <NavLink to={ ROUTES.QUALITY.GENERAL }>
              <Text type={ NORMAL_14 } className={ styles.quality }>
                { LABELS.qualityControl }
              </Text>
            </NavLink>
          </div>
        </MediaQuery>
        { this.renderNote() }
        { this.renderFavorite() }
      </>
    );
  };

  renderContent = () => {
    if (isSmartAgent) {
      return this.renderMenuBurger();
    }

    return this.renderMenu();
  };

  render() {
    return (
      <div className={ styles.wrapper }>
        { this.renderContent() }
      </div>
    );
  }
}

export default MainHeaderItems;
