import { observable, action, makeObservable } from 'mobx';

import { DEFAULT_BOOKED_INSURANCE } from '../../constants/insurance';

import {
  EnumRateName,
  IInsuranceStore,
  InsuranceRateType,
  IListItem,
  IOrderedInsurance,
  IItemInTrip,
} from '../../types/insurance';

class Store implements IInsuranceStore {
  constructor() {
    makeObservable(this);
  }

  @observable rates: InsuranceRateType[] = [];
  @observable bookedInsurance: IOrderedInsurance = DEFAULT_BOOKED_INSURANCE;
  @observable isOpenInsuranceSidePanel = false;
  @observable listItems: IListItem[] = [];
  @observable disabledItems: number[] = [];
  @observable selectedItems: IItemInTrip[] = [];
  @observable isSelectedAll: boolean = false;
  @observable addedItemsToInsurance: number[] = [];
  @observable isValidDate: boolean = false;
  @observable choosenRate: EnumRateName = EnumRateName.DEFAULT;

  @action
  setRates = (rates: InsuranceRateType[]) => {
    this.rates = rates;
  };

  @action
  setBookedInsurance = (rate: IOrderedInsurance) => {
    this.bookedInsurance = rate;
  };

  @action
  setAddedInsurance = (cartIds: number[]) => {
    this.addedItemsToInsurance = cartIds;
  };

  @action
  setInsuranceSidePanel = (isOpen: boolean) => {
    this.isOpenInsuranceSidePanel = isOpen;
  };

  @action
  setListItems = (list: IListItem[], disabledItems: number[]) => {
    this.listItems = list;
    this.disabledItems = disabledItems;
  };

  @action
  setSelectedItems = (arr: IItemInTrip[]) => {
    this.selectedItems = arr;
  };

  @action
  setAllItems = () => {
    this.selectedItems = this.listItems.reduce((prev: IItemInTrip[], { TripItemId, Amount }) => {
      if (!this.disabledItems.includes(TripItemId)) {
        return [...prev, { TripItemId, Amount }];
      }

      return prev;
    }, []);
    this.isSelectedAll = true;
  };

  @action
  clearSelectedItems = () => {
    this.selectedItems = [];
    this.isSelectedAll = false;
  };

  @action
  setIsValiDates = (validate: boolean) => {
    this.isValidDate = validate;
  };

  @action
  setChoosenRate = (rate: EnumRateName) => {
    this.choosenRate = rate;
  };

  @action
  setIsSelectedAll = () => {
    if (this.selectedItems.length === this.listItems.length) {
      this.isSelectedAll = true;
    }

    if (!this.selectedItems.length) {
      this.isSelectedAll = false;
    }
  };
}

const InsuranceStore = new Store();

export { InsuranceStore, Store as IInsuranceStore };
