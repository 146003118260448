import { getText } from '../../../i18n';

import { SERVICETYPE } from './serviceType';

const NOTIFICATIONS = {
  [SERVICETYPE.AIR]: getText('constants:notifications.air'),
  [SERVICETYPE.HOTEL]: getText('constants:notifications.hotel'),
  [SERVICETYPE.TRAIN]: getText('constants:notifications.train'),
  SERVICE: getText('constants:notifications.service'),
  DEFAULT: getText('constants:notifications.default'),
};

const NOTIFICATION_LEVEL = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export {
  NOTIFICATIONS as default,
  NOTIFICATION_LEVEL,
};
