import React from 'react';
import { Text } from 'new-ui';

import styles from '../styles/trainCancelled.module.css';

const WarningLetters = () => (
  <div>
    <Text type='NORMAL_18'>
      Сейчас автоматически не приходят письма из-за сложностей на стороне поставщика. Примерный срок исправления - до 12:00 29.11.
    </Text>
    <Text type='NORMAL_18' className={ styles.text }>
      Пожалуйста, скачайте ваучер в разделе &quot;Поездки&quot; или напишите запрос в чат, чтобы мы прислали нужный документ. Приносим свои извинения за неудобства.
    </Text>
  </div>
);

export { WarningLetters };
