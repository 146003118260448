// Поезда "Ласточка" с базовыми вагонами, кроме 5 и 10 - бизнес класс.
const typeA = [
  '742А',
  '715В',
  '720В',
  '743В',
  '744В',
  '719М',
  '725М',
  '726М',
  '7003М',
  '7004М',
  '7001А',
  '7002А',
  '716Ч',
  '804Ч',
  '806Ч',
  '803Ч',
  '805Ч',
];

const typeB = [
  '722Б',
  '718Б',
  '745В',
  '746Г',
  '721Ч',
  '715М',
  '716М',
  '717М',
  '721М',
  '722М',
  '729М',
];

// Поезда "Ласточка" со всеми базовыми вагонами.
const typeC = [
  '731М',
  '732М',
  '733М',
  '734М',
  '801С',
  '802С',
  '814С',
  '815С',
  '816С',
  '818С',
  '823С',
  '827С',
  '812Э',
  '813*Э',
  '814Э',
  '823Э',
  '827Э',
  '802Е',
  '803Е',
  '811Е',
  '814Е',
  '849Е',
];

// Поезда "Ласточка" со всеми базовыми вагонами.
const typeD = [
  '727М',
  '735М',
  '736М',
  '701Г',
  '703Г',
  '705Г',
  '707Г',
  '709Г',
  '728Г',
  '730Г',
  '732Г',
  '734Г',
  '727Г',
  '729Г',
  '731Г',
  '733Г',
  '811*С',
  '812С',
  '701Ч',
  '703Ч',
  '705Ч',
  '707Ч',
  '709Ч',
];

// Поезда "Ласточка" 1,2,3 - эконом, 4,5 - базовый и 1 вагон - бизнес.
const typeE = [
  '718А',
  '724В',
  '809В',
  '810В',
  '811В',
  '812В',
  '813В',
  '814В',
  '819В',
  '820В',
  '820*В',
  '823В',
  '824В',
  '718И',
  '738И',
  '740И',
  '809Ч',
  '814Ч',
  '816Ч',
  '818Ч',
  '821Ч',
  '822Ч',
  '838Ч',
  '725Ч',
  '726Ч',
  '816Щ',
  '715Я',
  '716Я',
  '717Я',
  '718Я',
  '721Я',
  '722Я',
  '738Я',
  '740Я',
  '813Я',
  '815Я',
  '816Я',
  '817Я',
];

// Поезда "Ласточка" все базовые вагоны, кроме 1 - бизнес класс.
const typeF = [
  '724В',
  '723Р',
  '815Р',
  '809С',
  '810С',
];

const typeG = [
  '806С',
  '806Э',
  '808С',
  '808Э',
];

const typeH = [
  '820С',
  '820Э',
];

const lastochkaTrains = [
  ...typeA,
  ...typeB,
  ...typeC,
  ...typeD,
  ...typeE,
  ...typeF,
  ...typeG,
  ...typeH,
];

const isLastochka = ({ Number: trainNumber = '' }) => lastochkaTrains.includes(trainNumber);

export { lastochkaTrains, isLastochka };
