import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Button, Dialog, MultiSelect, Text } from 'new-ui';

import { useServices } from '../../../../../bi/context/services';

import { LABELS } from '../../../consts';
import ROUTES from '../../../../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';

import { LoadingFields } from '../../../../../bi/services/departments/types';
import { LoadingStatus } from '../../../../../bi/services/utils/network/types';

import styles from './styles.module.css';

export const EditEmployees = observer(() => {
  const {
    departmentsService: {
      setEmployeeList,
      networkStore,
      store: { closeDialog, form: { employeesList, value } },
    },
    workspaceService,
  } = useServices(['Departments', 'Workspace']);

  const history = useHistory();

  const [employees, setEmployees] = useState<number[]>(
    Object.keys(value.employees).map(Number),
  );

  const isLoading = networkStore.getIsStatus(LoadingFields.setOrRemoveEmployee, LoadingStatus.LOADING);

  return (
    <Dialog
      show
      showClosing
      outsideClickClosing={ false }
      onChange={ closeDialog }
    >
      <div className={ styles.dialog }>
        <Text type='bold_20'>
          { LABELS.EMPLOYEES }
        </Text>
        <MultiSelect
          search
          addButton={ workspaceService.canEditEmployee }
          placeholder={ LABELS.SELECT_EMPLOYEES }
          addButtonLabel={ LABELS.ADD_EMPLOYEE }
          onAdd={ () => history.push(ROUTES.EMPLOYEE) }
          list={ employeesList }
          values={ employees }
          onChange={ setEmployees as any }
          qaAttrSearch={ QA_ATTRIBUTES.settings.departments.create.employeeInput }
          qaAttr={ QA_ATTRIBUTES.settings.departments.create.employeeSelect }
          qaAttrFirstEl={ QA_ATTRIBUTES.settings.departments.create.employeeFirst }
        />
        <div className={ styles.bottom }>
          <Button
            loading={ isLoading }
            onClick={ () => setEmployeeList(employees) }
            type='primary'
            qaAttr={ QA_ATTRIBUTES.settings.departments.create.addButton }
          >
            { LABELS.DIALOG_SUBMIT }
          </Button>
          <Button
            type='textual-medium'
            onClick={ closeDialog }
          >
            { LABELS.DIALOG_CANCEL }
          </Button>
        </div>
      </div>
    </Dialog>
  );
});
