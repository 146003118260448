import React from 'react';

import Exit from '../Exit';

import { EXITTYPES } from '../../consts';

import type { WingType, ExitType } from '../../types/types';

import styles from './styles.module.css';

const WINGSSTYLES: Record<WingType, string> = {
  none: styles.wings__none,
  start: styles.wings__start,
  end: styles.wings__end,
  regular: styles.wings__regular,
};

interface RowProps {
  number?: number,
  centeredNumber?: boolean,
  children?: React.ReactNode,
  wingType?: WingType,
  exitType?: ExitType,
  qaAttr?: string,
}

const Row = ({
  children = null,
  wingType = 'none',
  exitType = 'none',
  number = 0,
  centeredNumber = false,
  qaAttr = '',
}: RowProps) => {
  const classNames: string[] = [styles.wrap];

  if (wingType) {
    classNames.push(WINGSSTYLES[wingType]);
  }

  const numberClassNames = [styles.number];

  if (centeredNumber) {
    numberClassNames.push(styles.number_centered);
  }

  const frontExit = exitType === EXITTYPES.front;
  const backExit = exitType === EXITTYPES.back;

  return (
    <div data-qa={ qaAttr } className={ classNames.join(' ') }>
      <div className={ `${styles.wing} ${styles.left}` } />
      <div className={ `${styles.wing} ${styles.right}` } />
      { frontExit && <Exit /> }
      <div className={ styles.row }>
        <div className={ numberClassNames.join(' ') }>
          {number}
        </div>
        {children}
      </div>
      { backExit && <Exit /> }
    </div>
  );
};

export default Row;
