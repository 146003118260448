import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Dialog, Text, Input, Price, Button, Checkbox } from 'new-ui';

import { getText } from '../../../i18n';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import CloudPaymentService from '../../bi/services/cloudPayment';
import { PersonalPaymentProtocol } from '../../bi/protocols/personalPayment';

import { FieldLabel } from '../FieldLabel';

import { CARD_FORM_ERRORS } from '../../bi/utils/paymentMethods';

import styles from './index.module.css';

const LABEL = {
  AMOUNT_OF_DEPOSIT: getText('payment:topUpPersonalAccount.amountDeposit'),
  TOP_UP_PERSONAL_ACCOUNT: getText('payment:topUpPersonalAccount.topUpPersonalAccount'),
  TOP_UP: getText('payment:topUpPersonalAccount.topUp'),
  CANCEL: getText('payment:topUpPersonalAccount.cancel'),
  CARD_EXP: getText('payment:topUpPersonalAccount.cardExp'),
  CARD_NUMBER: getText('payment:topUpPersonalAccount.cardNumber'),
  SEND_RECEIPT_TO_EMAIL: getText('payment:topUpPersonalAccount.sendReceiptToEmail'),
  COMMISSION: getText('payment:topUpPersonalAccount.commission'),
  CVC: getText('payment:topUpPersonalAccount.cvc'),
};

interface PaymentWidgetProps {
  onConfirm(): void,
  onCancel(): void,
  onChangeDialog(): void,
  cloudPaymentService: CloudPaymentService,
  personalPaymentProtocol: PersonalPaymentProtocol,
  isOpen: boolean,
}

export const PaymentWidget = observer(({
  onConfirm,
  onCancel,
  isOpen,
  onChangeDialog,
  cloudPaymentService,
}: PaymentWidgetProps) => {
  const [sendToEmail, setSendToEmail] = useState(false);
  const {
    paymentMethodStore: {
      cardForm: { expDate, cardNumber, cvv },
      cardFormErrors,
      amountOfDeposit,
      commission,
      email,
      loading,
    } } = useStores([MOBX_STORES.PAYMENT_METHOD]);

  const handleChangeCardNumber = (value: string) => {
    cloudPaymentService.onChangeCardNumber(value);
  };

  const handleChangeExpDate = (value: string) => {
    cloudPaymentService.onChangeExpDate(value);
  };

  const handleChangeCvc = (value: string) => {
    cloudPaymentService.onChangeCvv(value);
  };

  const handleChangeAmountOfDeposit = (value: string) => {
    cloudPaymentService.onChangeAmountOfDeposit(value);
  };

  const handleChangeEmail = (value: string) => {
    cloudPaymentService.onChangeEmail(value);
  };

  const handleConfirm = async () => {
    const cryptogramm = await cloudPaymentService.createPaymentCryptogram();
    const isValid = cloudPaymentService.validatePaymentForm();

    if (isValid && typeof cryptogramm === 'string') {
      await cloudPaymentService.payViaApi();
      onConfirm();
    }
  };

  const renderCommission = () => (
    <div className={ styles.column_align_left }>
      <FieldLabel text={ LABEL.COMMISSION } />
      <Price
        value={ commission }
        type='NORMAL_14'
        typeCurrency='NORMAL_14'
      />
    </div>
  );

  const renderEmailInput = () => {
    const emailInputHtml = sendToEmail ?
      <Input value={ email } onChange={ handleChangeEmail } /> :
      null;

    return (
      <div className={ `${styles.column_align_left} ${styles.full_width}` }>
        <div className={ styles.contact_inputs }>
          <Checkbox value={ sendToEmail } onChange={ () => setSendToEmail(!sendToEmail) }>
            <Text>{ LABEL.SEND_RECEIPT_TO_EMAIL }</Text>
          </Checkbox>
        </div>
        {emailInputHtml}
      </div>
    );
  };

  const renderTopUpAmount = () => {
    const errorAmountOfDeposit = CARD_FORM_ERRORS[cardFormErrors?.emptyAmount];

    return (
      <div className={ `${styles.column_align_left} ${styles.full_width}` }>
        <FieldLabel
          text={ LABEL.AMOUNT_OF_DEPOSIT }
          mustHave
        />
        <Input
          value={ amountOfDeposit }
          onChange={ handleChangeAmountOfDeposit }
          error={ errorAmountOfDeposit }
        />
      </div>
    );
  };

  const renderCardInfoInputs = () => {
    const errorCardNumber = CARD_FORM_ERRORS[cardFormErrors?.cardNumber];
    const errorExpMonth = CARD_FORM_ERRORS[cardFormErrors?.expDateMonth];
    const errorExpYear = CARD_FORM_ERRORS[cardFormErrors?.expDateYear];
    const errorCvv = CARD_FORM_ERRORS[cardFormErrors?.cvv];

    return (
      <div className={ styles.card_form }>
        <div className={ styles.column_align_left }>
          <Text type='NORMAL_14_130'>{ LABEL.CARD_NUMBER }</Text>
          <Input
            value={ cardNumber }
            onChange={ handleChangeCardNumber }
            error={ errorCardNumber }
          />
        </div>
        <div className={ styles.column_align_left }>
          <Text type='NORMAL_14_130'> { LABEL.CARD_EXP }</Text>
          <Input
            className={ styles.mini_input }
            value={ expDate }
            onChange={ handleChangeExpDate }
            error={ errorExpMonth || errorExpYear }
          />
        </div>
        <div className={ `${styles.cvc} ${styles.full_width}` }>
          <Text type='NORMAL_14_130'>{ LABEL.CVC }</Text>
          <Input
            className={ styles.mini_input }
            value={ cvv }
            onChange={ handleChangeCvc }
            error={ errorCvv }
          />
        </div>
      </div>
    );
  };

  return (
    <Dialog
      show={ isOpen }
      showClosing
      onChange={ onChangeDialog }
    >
      <div className={ styles.widget_wrapper }>
        <Text className={ styles.header } type='SEMIBOLD_18'>{ LABEL.TOP_UP_PERSONAL_ACCOUNT }</Text>
        { renderCardInfoInputs() }
        { renderTopUpAmount() }
        { renderCommission() }
        <div className={ styles.contact_inputs }>
          { renderEmailInput() }
        </div>
        <div className={ `${styles.contact_inputs} ${styles.full_width}` }>
          <Button type='secondary' onClick={ handleConfirm } loading={ loading }>{ LABEL.TOP_UP}</Button>
          <Button type='textual' onClick={ onCancel } disabled={ loading }>{ LABEL.CANCEL }</Button>
        </div>
      </div>
    </Dialog>
  );
});
