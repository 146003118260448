import React, { Component } from 'react';

import { IconButton, Text, Button, Badge, Dialog, Checkbox, Icon } from 'new-ui';

import { getText } from '../../../i18n';

import { MainAnalytic } from '../../bi/utils/analytics';

import { EditableText } from '../EditableText';
import ToDoChangeEvents from '../../page/Smartdesk/consts';

import {
  IListModesProps,
  IToDoItem,
  IToDoProps,
  IToDoState,
} from './types';

import styles from './styles/index.module.css';

type IListModes = Record<IListModesKeys, IListModesProps>;

type IListModesKeys = 'false' | 'true';

const LIST_MODES: IListModes = {
  false: {
    label: getText('components:toDo.current'),
  },
  true: {
    label: getText('components:toDo.cancelled'),
  },
};

const LABELS = {
  ITEM_PLACEHOLDER: '',
  NEW_ITEM_PLACEHOLDER: getText('components:toDo.putTaskName'),
  ADD_TASK: getText('components:toDo.addTask'),
  EMPTY_LIST: {
    one: getText('components:toDo.emptyList.one'),
    two: getText('components:toDo.emptyList.two'),
    three: getText('components:toDo.emptyList.three'),
  },
  REMOVE_ALL: getText('components:toDo.removeAllTasks'),
  TASKS: getText('components:toDo.tasks'),
  ALL_TASKS: getText('components:toDo.allTasks'),
  LIST_OF_TASKS: getText('components:toDo.listOfTasks'),
  CURRENT: getText('components:toDo.current'),
  CANCELLED: getText('components:toDo.cancelled'),
};

class ToDo extends Component<IToDoProps, IToDoState> {
  state = {
    mode: false,
    dialog: {
      show: false,
      mode: false,
    },
  };

  setDialogShow = (value: boolean) => {
    this.setState({ dialog: { ...this.state.dialog, show: value } });

    if (value) {
      this.props.onChange({ type: ToDoChangeEvents.MODALOPENED });
    }
  };

  setDialogMode = (value: boolean) => this.setState({ dialog: { ...this.state.dialog, mode: value } });

  editItem = (value: IToDoItem) => {
    this.props.onChange({ type: ToDoChangeEvents.EDIT, payload: value });
  };

  removeItem = (id: number) => {
    this.props.onChange({ type: ToDoChangeEvents.DELETE, payload: id });
  };

  addTask = (value: string) => {
    this.props.onChange({ type: ToDoChangeEvents.ADD, payload: { Text: value, isModal: this.state.dialog.show } });

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMARTDESC.SMARTDESK_ADD_TASK_PRESSED);
  };

  removeAllTasks = () => {
    this.props.onChange({ type: ToDoChangeEvents.DELETEALL });
  };

  renderItem = ({ Status, Text: T, Id }: IToDoItem) => (
    <div className={ styles.item }>
      <Checkbox
        value={ Status }
        contentClassName={ styles.checkbox }
        onChange={ (v) => this.editItem({ Id, Status: v, Text: T }) }
      />
      <EditableText
        value={ T }
        placeholder={ LABELS.ITEM_PLACEHOLDER }
        renderPreview={ () => (
          <div className={ styles.content }>
            <Text className={ styles.text }>
              { T }
            </Text>
            <IconButton
              className={ styles['remove-icon'] }
              iconType='trashcan'
              iconColor='accent'
              onClick={ (e) => {
                e.stopPropagation();
                this.removeItem(Id);
              } }
            />
          </div>
        ) }
        editClassName={ styles['edit-to-do'] }
        onChange={ (value: string) => this.editItem({ Id, Status, Text: value }) }
      />
    </div>
  );

  renderAddTask = () => (
    <EditableText
      renderPreview={ () => (
        <div className={ styles['add-button'] }>
          <Icon type='plusRound' />
          <Button type='textual' className={ styles.btn }>
            { LABELS.ADD_TASK }
          </Button>
        </div>
      ) }
      editClassName={ styles['add-editable'] }
      placeholder={ LABELS.NEW_ITEM_PLACEHOLDER }
      iconType='plusRound'
      onChange={ this.addTask }
    />
  );

  renderList = (list: IToDoItem[]) => {
    const { EMPTY_LIST: { one, two, three } } = LABELS;

    if (!list.length) {
      return (
        <div className={ styles.empty }>
          <Text type='NORMAL_14_130'>{ one }</Text>
          <Text type='NORMAL_14_130'>{ two }</Text>
          <Text type='NORMAL_14_130'>{ three }</Text>
        </div>
      );
    }

    return (
      <div className={ styles.list }>
        { list.map((i, ind) => (
          <React.Fragment key={ `${i.Id}_${ind}` }>
            { this.renderItem(i) }
          </React.Fragment>
        ))}
      </div>
    );
  };

  renderBody = () => {
    const { items } = this.props;
    const { mode } = this.state;

    if (!items) {
      return null;
    }

    const preparedItems = items.filter(({ Status }) => Status === mode)?.slice(0, 5);

    return this.renderList(preparedItems);
  };

  renderDialog = () => {
    const { items } = this.props;
    const { dialog: { show, mode } } = this.state;

    if (!items || !items.length) {
      return null;
    }

    const list = items.filter(({ Status }) => Status === mode);

    const actionsContent = () => {
      if (!items || !items.length) {
        return null;
      }

      return (
        <div className={ styles.actions }>
          <Button
            type='textual'
            onClick={ this.removeAllTasks }
          >
            { LABELS.REMOVE_ALL }
          </Button>
        </div>
      );
    };

    const addBtnContent = !mode && (
      <div className={ styles['add-btn'] }>
        { this.renderAddTask() }
      </div>
    );

    return (
      <Dialog
        show={ show }
        onChange={ (v) => this.setDialogShow(v) }
        showClosing
      >
        <div className={ styles['dialog-wrapper'] }>
          <div className={ styles.content }>
            <Text type='bold_20'>
              { LABELS.TASKS }
            </Text>
            <div className={ styles.tabs }>
              <Button
                type='textual'
                className={ `${styles.button} ${!mode ? styles.active : ''}` }
                onClick={ () => this.setDialogMode(false) }
              >
                <Text className={ styles.label }>
                  { LABELS.CURRENT }
                </Text>
              </Button>
              <Button
                type='textual'
                className={ `${styles.button} ${mode ? styles.active : ''}` }
                onClick={ () => this.setDialogMode(true) }
              >
                <Text className={ styles.label }>
                  { LABELS.CANCELLED }
                </Text>
              </Button>
            </div>
            { addBtnContent }
            { this.renderList(list) }
          </div>
          { actionsContent() }
        </div>
      </Dialog>
    );
  };

  renderFooter = () => {
    const { items } = this.props;
    const { mode } = this.state;

    if (!items || !items.length) {
      return null;
    }

    const actualTasksLength = items.filter(({ Status }) => !Status).length;
    const modeStr = String(!mode) as IListModesKeys;

    return (
      <div className={ styles.footer }>
        <div className={ styles.all }>
          <Button
            type='textual'
            className={ styles.btn }
            onClick={ () => this.setDialogShow(true) }
          >
            { LABELS.ALL_TASKS }
          </Button>
          { !!actualTasksLength && (
            <Badge>{ actualTasksLength }</Badge>
          ) }
        </div>

        <Button
          onClick={ () => this.setState({ mode: !this.state.mode }) }
          type='textual'
        >
          { LIST_MODES[modeStr].label }
        </Button>
      </div>
    );
  };

  render() {
    return (
      <div className={ styles.wrapper }>
        <div className={ styles.content }>
          <div className={ styles.header }>
            <Text type='bold_24'>
              { LABELS.LIST_OF_TASKS }
            </Text>
            <div className={ styles['add-btn'] }>
              { this.renderAddTask() }
            </div>
          </div>
          { this.renderBody() }
        </div>
        { this.renderFooter() }
        { this.renderDialog() }
      </div>
    );
  }
}

export { ToDo };
