import { observable, action, makeObservable } from 'mobx';

export interface IBreadCrumbsStore {
  currentPath: string,
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable currentPath = '';

  @action
  setCurrentPath = (path = '') => {
    this.currentPath = path;
  };
}

const BreadCrumbsStore = new Store();

export type BreadCrumbsStoreType = Store;
export { BreadCrumbsStore };
