import React from 'react';
import { Tooltip, Text, IconButton } from 'new-ui';

import styles from './styles/index.module.css';

type HelpIconProps = {
  text: string,
  onClick?: () => void,
};

const HelpIcon = ({ text, onClick = () => {} }: HelpIconProps) => (
  <Tooltip
    show={ !!text }
    renderContent={ () => (
      <div className={ styles.tooltip }>
        <Text type='NORMAL_14_130' color='white'>{ text }</Text>
      </div>
    ) }
  >
    <IconButton iconType='question' onClick={ onClick } />
  </Tooltip>
);

export default HelpIcon;
