import { VALID_SYMBLOL } from '../constants/cart';

const additionalFeeValidation = (value: string, valid: RegExp, validNoOgr: RegExp, isRestrictions: boolean = true) => {
  const validFirstSybmol = value ? value[0] !== VALID_SYMBLOL.DOT && value[0] !== VALID_SYMBLOL.ZERO && value[0] !== VALID_SYMBLOL.COMMA : value;

  const fiftyCent = value
    ? (value[0] === VALID_SYMBLOL.ZERO && value[1] === VALID_SYMBLOL.DOT) || (value[0] === VALID_SYMBLOL.ZERO && value[1] === VALID_SYMBLOL.COMMA)
    : !value;

  const regular = isRestrictions ? valid : validNoOgr;

  return !!(regular.test(value) && (fiftyCent || validFirstSybmol));
};

export {
  additionalFeeValidation,
};
