import React from 'react';
import { Tag, Button } from 'new-ui';

import { getText } from '../../../../../i18n';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import { VALUE_FIELD_FILTERS } from '../../../../bi/constants/employee';

import { FilterSelectorType, TagsType } from '../../../../bi/types/filters';

import styles from './styles/index.module.css';

const LABELS = {
  CLEAR_FILTERS: getText('settings:employees.allFilters.resetTags'),
};

interface tagsFiltersProps {
  deleteTag(value: TagsType, type: string): void,
  sex: TagsType[],
  document: TagsType[],
  company: TagsType[],
  status: TagsType[],
  tags: TagsType[],
  travelPolicies: TagsType[],
  bonusCards: TagsType[],
  projects: TagsType[],
  departments: TagsType[],
  codes: TagsType[],
  filterSelector: FilterSelectorType,
  counter: number,
  resetTags(): void,
}

const TagsFilters = ({
  deleteTag,
  sex,
  document,
  company,
  status,
  tags,
  travelPolicies,
  bonusCards,
  projects,
  departments,
  codes,
  filterSelector,
  counter,
  resetTags,
}: tagsFiltersProps) => {
  const isStandartBlock = (value: number) => value === VALUE_FIELD_FILTERS.NEW_NULL;
  const isStandartBlockString = (value: number | string) => value === String(VALUE_FIELD_FILTERS.NEW_NULL);

  const resetAllTags = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.FILTERS_DELETE_ALL_TAGS);
    resetTags();
  };

  const deleteTags = (tag: TagsType, type: string) => {
    deleteTag(tag, type);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.FILTERS_DELETE_TAG);
  };

  const renderTagsFilter = (values: TagsType[], isBlock: any, type: string) => {
    if (!values) {
      return null;
    }

    return (values).map((tag: TagsType) => {
      const { label, value } = tag;

      if (isBlock(value)) {
        return null;
      }

      return (
        <Tag
          label={ label }
          onClick={ () => deleteTags(tag, type) }
          key={ value }
          className={ styles.tag }
          type='gray'
          iconRemoveColor='black'
          textRemoveColor='default'
        />
      );
    });
  };

  const renderCleanTags = () => {
    if (counter < 2) {
      return null;
    }

    return (
      <Button
        className={ styles.button }
        type='primary-outline'
        onClick={ resetAllTags }
      >
        { LABELS.CLEAR_FILTERS }
      </Button>
    );
  };

  return (
    <div
      className={ styles.filters_wrapper }
    >
      { renderTagsFilter(status, isStandartBlock, filterSelector.Status) }
      { renderTagsFilter(company, isStandartBlock, filterSelector.Company) }
      { renderTagsFilter(departments, isStandartBlock, filterSelector.Departments) }
      { renderTagsFilter(sex, isStandartBlock, filterSelector.Sex) }
      { renderTagsFilter(codes, isStandartBlockString, filterSelector.Codes) }
      { renderTagsFilter(document, isStandartBlock, filterSelector.Document) }
      { renderTagsFilter(travelPolicies, isStandartBlockString, filterSelector.TravelPolicies) }
      { renderTagsFilter(tags, isStandartBlock, filterSelector.Tags) }
      { renderTagsFilter(bonusCards, isStandartBlock, filterSelector.BonusCards) }
      { renderTagsFilter(projects, isStandartBlock, filterSelector.Projects) }
      { renderCleanTags() }
    </div>
  );
};

export { TagsFilters };
