import React from 'react';

import styles from './styles/panel.module.css';

interface PanelProps {
  shadow: string;
  isLoading?: boolean;
  children: JSX.Element
}

const Panel = ({ shadow = 'main', children, isLoading = false }: PanelProps) => (
  <div className={ `${styles.wrap} ${styles[shadow]} ${isLoading ? styles.loading : ''}` }>
    { children }
  </div>
);

/**
 * @param shadow - shadow type - main || light || panel
 */

export default Panel;
