const STATUS = {
  NOTAUTH: 401,
  OK: 200,
  BAD_REQUEST: 400,
};

export enum STATUS_CODE {
  NOTAUTH = 401, // потом переделать
  OK = 200,
}

export default STATUS;
