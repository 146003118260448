import React, { useState } from 'react';
import { Dialog, Button, Checkbox, Text } from 'new-ui';

import { getText } from '../../../../i18n';

import { isSmartAgent } from '../../../bi/utils/env';

import styles from '../styles/info.module.css';

interface IInfoDialog {
  show: boolean;
  forNote?: boolean;
  onlyInfo?: boolean;
  onAddToCart?: (value: boolean) => void;
  onAddToNote?: (value: boolean) => void;
  warningShortTransferTime?: boolean;
}

const LABELS = {
  toNote: getText('components:airlineSearchItem.infoDialog.toNote'),
  toCart: getText('components:airlineSearchItem.infoDialog.toCart'),
  acceptRules: getText('components:airlineSearchItem.infoDialog.acceptRules'),
  title: getText('components:airlineSearchItem.infoDialog.title'),
  description: {
    one: getText('components:airlineSearchItem.infoDialog.description.one'),
    nameSmartagent: getText('components:airlineSearchItem.infoDialog.description.nameSmartagent'),
    nameSmartway: getText('components:airlineSearchItem.infoDialog.description.nameSmartway'),
    two: getText('components:airlineSearchItem.infoDialog.description.two'),
    three: getText('components:airlineSearchItem.infoDialog.description.three'),
    four: getText('components:airlineSearchItem.infoDialog.description.four'),
    five: getText('components:airlineSearchItem.infoDialog.description.five'),
    six: getText('components:airlineSearchItem.infoDialog.description.six'),
    seven: getText('components:airlineSearchItem.infoDialog.description.seven'),
  },
  cancel: getText('common:undo'),
};

const InfoDialog: React.FC<IInfoDialog> = ({
  show,
  forNote = false,
  onlyInfo = false,
  onAddToCart = () => {},
  onAddToNote = () => {},
  warningShortTransferTime = false,
}) => {
  const [accepted, setAccepted] = useState(false);

  const handleCheck = (value: boolean) => setAccepted(value);

  const handleDialogClose = () => {
    const onDialogClose = forNote
      ? () => onAddToNote(false)
      : () => onAddToCart(false);
    onDialogClose();
    setAccepted(false);
  };

  const handleButtonClick = () => {
    const onButtonClick = forNote
      ? () => onAddToNote(true)
      : () => onAddToCart(true);
    onButtonClick();
    handleDialogClose();
  };

  const renderActions = () => {
    if (onlyInfo) return null;

    const label = forNote ? LABELS.toNote : LABELS.toCart;

    return (
      <div>
        <Checkbox value={ accepted } onChange={ handleCheck }>
          { LABELS.acceptRules }
        </Checkbox>
        <div className={ styles.actions }>
          <Button
            disabled={ !accepted }
            type='primary'
            onClick={ handleButtonClick }
          >
            { label }
          </Button>
          <Button
            className={ styles.link }
            type='textual-medium'
            onClick={ handleDialogClose }
          >
            { LABELS.cancel }
          </Button>
        </div>
      </div>
    );
  };

  const warningShortTransferTimeHtml = warningShortTransferTime && (
    <Text className={ styles.text_after_list }>{ LABELS.description.seven }</Text>
  );

  const preparedName = isSmartAgent ? LABELS.description.nameSmartagent : LABELS.description.nameSmartway;

  return (
    <Dialog
      showClosing
      show={ show }
      onChange={ handleDialogClose }
    >
      <div className={ styles.wrapper }>
        <Text type='bold_20' className={ styles.header }>
          { LABELS.title }
        </Text>
        <Text className={ styles.text }>
          { preparedName } { LABELS.description.one }
        </Text>
        <Text className={ styles.text_before_list }>
          { LABELS.description.two }
        </Text>
        <Text className={ styles.item }>
          { LABELS.description.three }
        </Text>
        <Text className={ styles.item }>
          { LABELS.description.four }
        </Text>
        <Text className={ styles.item }>
          { LABELS.description.six }
        </Text>
        <Text className={ styles.text_after_list }>
          { LABELS.description.five }
        </Text>
        { warningShortTransferTimeHtml }
        { renderActions() }
      </div>
    </Dialog>
  );
};

export default InfoDialog;
