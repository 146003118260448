import React, { useState } from 'react';
import { IconButton, Text } from 'new-ui';
import { getTextArray, getText, currentLng } from '../../../i18n';
import { languages } from '../../../i18n/locales/consts';

import { AIRLINENAMES } from '../../bi/constants/airline';

import ModalBonus from '../ModalBonus';

import toDecline from '../../bi/utils/toDecline';

import styles from './styles/index.module.css';

const LABELS = {
  bonusDeclines: getTextArray('components:airlineBonus.bonusDeclines'),
  from: getText('components:airlineBonus.from'),
};

interface AirlineBonusProps {
  bonus: number;
  sameAirlineCompany: string;
}

const AirlineBonus = ({
  bonus,
  sameAirlineCompany,
}: AirlineBonusProps) => {
  const [show, setShow] = useState(false);

  let airlineCompany;

  if (sameAirlineCompany === AIRLINENAMES.AEROFLOT) airlineCompany = `${AIRLINENAMES.AEROFLOT}${currentLng === languages.ru && 'a'}`;

  if (sameAirlineCompany === AIRLINENAMES.SSEVEN) airlineCompany = AIRLINENAMES.SSEVEN;

  if (!airlineCompany) return null;

  const declinedBonusLabel = `${bonus} ${toDecline(bonus, LABELS.bonusDeclines)}`;

  return (
    <div
      className={ styles.wrapper }
      onClick={ () => setShow(true) }
    >
      <Text type='NORMAL_12' color='gray' className={ styles.text }>
        +{ declinedBonusLabel } { LABELS.from } { airlineCompany }
      </Text>
      <IconButton className={ styles.question } iconType='question' />
      <ModalBonus
        show={ show }
        onClose={ () => setShow(false) }
      />
    </div>
  );
};

export default AirlineBonus;
