import { getText } from '../../../i18n';

const Unavailable = 'Unavailable';
const Available = 'Available';
const OnlyAfterApprove = 'OnlyAfterApprove';
const Unlimited = 'Unlimited';
const Limited = 'Limited';
const TravelPolicy = 'TravelPolicy';
const ApprovalScheme = 'ApprovalScheme';
const PersonalVouchers = 'PersonalVouchers';
const PersonalInvoices = 'PersonalInvoices';
const PersonalVouchersAndInvoices = 'PersonalVouchersAndInvoices';
const AccountVouchers = 'AccountVouchers';
const AccountInvoices = 'AccountInvoices';
const AccountVouchersAndInvoices = 'AccountVouchersAndInvoices';
const All = 'All';
const Personal = 'Personal';
const Undefined = 'Undefined';
const PersonalTrips = 'personalTrips';
const AccountTrips = 'accountTrips';

export const BUYTRIPSPERSONALRIGHT = {
  Unavailable,
  OnlyAfterApprove,
  Limited,
  TravelPolicy,
  Unlimited,
  ApprovalScheme,
};

export const BUYTRIPSACCOUNTRIGHT = {
  Unavailable,
  OnlyAfterApprove,
  TravelPolicy,
  Unlimited,
  ApprovalScheme,
};

export const FINANCEDOCUMENTSRIGHT = {
  Unavailable,
  Available,
};

export const APPROVE_EXPENSE_REPORTS = {
  Unavailable,
  Available,
};

export const NOTIFICATIONRIGHT = {
  Unavailable,
  PersonalVouchers,
  PersonalInvoices,
  PersonalVouchersAndInvoices,
  AccountVouchers,
  AccountInvoices,
  AccountVouchersAndInvoices,
};

export const APPROVETRIPRIGHT = {
  Unavailable,
  Available,
};

export const EDITACCESSRIGHT = {
  Unavailable,
  Available,
};

export const EDIT_BUY_WITH_PERSONAL_FUNDS = {
  Unavailable,
  Personal,
  All,
  Undefined,
};

export const EDITEMPLOYEERIGHT = {
  All,
  Personal,
  Undefined,
};

export const VIEWTRIPSRIGHT = {
  Undefined,
  Personal,
  All,
};

export const VIEW_MICE_RIGHT = {
  Undefined,
  Personal,
  All,
};

export const VIEW_EXPENSE_REPORTS = {
  Undefined,
  Personal,
  All,
};

export const COMMONRIGHT = {
  Unavailable,
  OnlyAfterApprove,
  TravelPolicy,
  Unlimited,
  ApprovalScheme,
};

export const CREATE_EXPENSE_REPORT = {
  OnlyAfterApprove,
  Unlimited,
  ApprovalScheme,
};

export const DOCUMENTS = {
  PersonalTrips,
  AccountTrips,
};

export const THEMEACTION = {
  DEFAULT: 'default',
  MEDIUM: 'medium',
  DONE: 'done',
  DELETE: 'delete',
  COPY: 'copy',
};

export const STEPSRIGHTS = {
  CREATE: 'create',
  MAIN: 'main',
  LIMIT: 'limit',
  APPROVE_EXPENSE_REPORTS: 'approveExpenseReports',
  ADMIN: 'admin',
  NOTIFICATION: 'notification',
  COPY: 'copy',
  EDIT: 'edit',
  CLOSE: 'close',
};

export const FIELDRIGHTS = {
  MANUALLY: 'manually',
  COPY: 'copy',
  BUYTRIPPERSONAL: 'buyTripPersonal',
  BUYTRIPACCOUNT: 'buyTripAccount',
  APPROVE: 'approve',
  FINANCE: 'finance',
  BUYUNLIMITED: 'buyUnlimited',
  BUYAFTERAPPROVE: 'buyAfterApprove',
  BUYTRAVELPOLICY: 'buyTravelPolicy',
  BUY_APPROVAL_SCHEME: 'buyApprovalScheme',
  CREATE_ER_APPROVAL_SCHEME: 'createERApprovalScheme',
  CREATE_ER_AFTER_APPROVE: 'createERAfterApprove',
  CREATE_ER_UNLIMITED: 'createERUnlimited',
  BUYLIMITED: 'buyLimited',
  EDITEMPLOYEE: 'editEmployee',
  VIEWTRIPS: 'viewTrips',
  EDITRIGHTS: 'editRights',
  EDITTRAVELPOLICIES: 'editTravelPolicies',
  VIEW_EXPENSE_REPORTS: 'viewExpenseReports',
  CANGETVOUCHERS: 'canGetVouchers',
  CANGETINVOICES: 'canGetInvoices',
  SENDDOCUMENTS: 'sendDocuments',
  APPROVE_EXPENSE_REPORTS: 'approveExpenseReports',
  VIEW_MICE_EVENTS: 'viewMiceEvents',
  APPROVERS: 'approvers',
  VOUCHERS: 'vouchers',
  INVOICES: 'invoices',
  EDIT: 'edit',
  CLOSE: 'close',
  BUY_WITH_PERSONAL_FUNDS: 'Acquiring',
  BUY_INSURANCE: 'buyInsurance',
};

export const SHORTFIELDRIGHTS = {
  BUYTRIP: 'buyTrip',
  EDITSELF: 'editSelf',
  DOCUMENTS: 'documents',
  NOTBUYTRIP: 'notBuyTrip',
  SEARCHBESTTRIP: 'searchBestTrip',
  VIEWSELFTRIPS: 'viewSelfTrips',
  CREATE_EXPENSE_REPORTS: 'createExpenseReports',
  BUY_WITH_PERSONAL_FUNDS: 'buyWithPersonalFunds',
  VIEW_MICE_EVENT: 'viewMiceEvent',
};

export const RIGHTSTEXT = {
  SEARCHBESTTRIP: getText('constants:rights.searchBestTrip'),
  BUYTRIPPERSONAL: getText('constants:rights.buyTripPersonal'),
  BUYTRIPACCOUNT: getText('constants:rights.buyTripAccount'),
  FOR_ANOTHERS: getText('constants:rights.forAnothers'),
  BUYUNLIMITED: getText('constants:rights.buyUnlimited'),
  BUYAFTERAPPROVE: getText('constants:rights.buyAfterApprove'),
  BUYTRAVELPOLICY: getText('constants:rights.buyTravelPolicy'),
  BUY_APPROVAL_SCHEME: getText('constants:rights.buyApprovalScheme'),
  VIEWTRIPS: getText('settings:employees.employee.steps.rights.steps.admin.anotherTrips'),
  VIEWSELFTRIPS: getText('constants:rights.viewSelfTrips'),
  FINANCE: getText('constants:rights.finance'),
  APPROVE: getText('constants:rights.approve'),
  EDITSELF: getText('constants:rights.editSelf'),
  BUY_WITH_PERSONAL_FUNDS: getText('constants:rights.buyWithPersonalFunds'),
  EDITEMPLOYEE: getText('constants:rights.editSelfEmployee'),
  BUY_INSURANCE: getText('constants:rights.buyInsurance'),
  EDITEMPLOYEE_AND_PASSANGERS: getText('constants:rights.editSelfEmployeeAndPassangers'),
  EDITTRAVELPOLICIES: getText('constants:rights.editTravelPolicies'),
  EDITRIGHTS: getText('constants:rights.editRights'),
  EDITRIGHTS_FOR_AGENTS: getText('constants:rights.editRightsAgents'),
  CANGETVOUCHERS: getText('constants:rights.canGetVouchers'),
  CANGETINVOICES: getText('constants:rights.canGetInvoices'),
  CANGETVOUCHERSANDINVOICES: getText('constants:rights.canGetVouchersAndInvoices'),
  PERSONALTRIPS: getText('constants:rights.personalTrips'),
  ACCOUNTTRIPS: getText('constants:rights.accountTrips'),
  SENDDOCUMENTS: getText('constants:rights.sendDocuments'),
  NOTBUYTRIP: getText('constants:rights.notBuyTrip'),
  NOTBUYTRIPACCOUNT: getText('constants:rights.notBuyTripAccount'),
  VIEW_MICE_EVENTS: getText('constants:rights.viewMiceEvents'),
  NOT_BUY_TRIP_ACCOUNT_USERS: getText('constants:rights.notBuyTripAccountUsers'),
  NOTVIEWTRIPS: getText('constants:rights.notViewTrips'),
  NOTEDITSELF: getText('constants:rights.notEditSelf'),
  NOT_EDIT_SELF_USERS: getText('constants:rights.notEditSelfUsers'),
  NOTEDITEMPLOYEE: getText('constants:rights.notEditEmployee'),
  NOT_EDIT_USERS: getText('constants:rights.notEditUsers'),
  NOPERSONALTRIPS: getText('constants:rights.noPersonalTrips'),
  APPROVE_EXPENSE_REPORTS: getText('constants:rights.approveExpenseReports'),
  VIEW_EXPENSE_REPORTS: getText('constants:rights.viewExpenseReports'),
  IN_APPROVE_WITH: getText('constants:rights.inApproveWith'),
};

export const ACQUIRING_LEVEL_TEXT = [
  {
    value: Unavailable,
    label: getText('settings:employees.employee.steps.rights.buyWithPersonalFundsLevel.unavailable'),
  },
  {
    value: Personal,
    label: getText('settings:employees.employee.steps.rights.buyWithPersonalFundsLevel.personal'),
  },
  {
    value: All,
    label: getText('settings:employees.employee.steps.rights.buyWithPersonalFundsLevel.all'),
  },
  {
    value: Undefined,
    label: getText('settings:employees.employee.steps.rights.buyWithPersonalFundsLevel.unavailable'),
  },
];

export const EMPTYRIGHTS = {
  Approve: Unavailable,
  BuyTripAccount: Unavailable,
  BuyTripPersonal: Unavailable,
  CanBook: false,
  CanEditRights: false,
  CanGetDebtNotification: false,
  CanGetInvoiceNotification: 0,
  CanGetNotifications: false,
  CanGetVoucherNotification: 0,
  CanLogin: false,
  CanViewReports: false,
  EditEmployee: Undefined,
  EditRights: Unavailable,
  Finance: Unavailable,
  Login: '',
  Notifications: Unavailable,
  PersonalMoneyPerDayBookLimit: 0,
  PersonalNumberPerDayBookLimit: 0,
  ViewTrips: Undefined,
  ApproveExpenseReports: Unavailable,
  ViewExpenseReports: Undefined,
  ViewMiceEvents: Personal,
  CreateExpenseReports: Unavailable,
  Acquiring: Unavailable,
};

export const CREATERIGHTS = {
  CanBook: false,
  CanLogin: true,
};

export const DELETERIGHTS = {
  CanBook: true,
  CanLogin: false,
  Login: '',
};

export const EDITRIGHTS = {
  CanBook: true,
  CanLogin: true,
};
