import React from 'react';
import { Icon, List, NoResults, Skeleton, StyledWrapper, Text } from 'new-ui';

import { getText } from '../../../i18n';

import { TripAgent } from './components/Trips';
import TitleTrips from './components/Title';

import { isSmartAgent } from '../../bi/utils/env';
import { MainAnalytic } from '../../bi/utils/analytics';

import { COUNT_TRIP } from './const';

import { ITripPanel } from './types';

import styles from './styles/index.module.css';

const LABELS = {
  NO_RESULT: getText('smartdesk:tripsPanel.noResult'),
  COMMING_TRIP: getText('smartdesk:tripsPanel.comingTrips'),
};

const TripsPanel = ({
  isTrips,
  trips,
  history,
}: ITripPanel) => {
  const handleGoToTrip = (tripId: number) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.REDISIGN.MAINTRIPS_TRIP_PRESSED);

    history.push(`/trip/${tripId}`);
  };

  if (!isTrips) {
    return (
      <StyledWrapper className={ styles.skeleton }>
        <Skeleton className={ styles.skeleton }/>
      </StyledWrapper>
    );
  }

  if (!trips.length) {
    return (
      <StyledWrapper className={ styles.wrapper }>
        <div className={ styles.wrapper_title }>
          <TitleTrips
            isShow
            isShowGoTo={ false }
          />
        </div>
        <div className={ styles.wrapper_result }>
          <NoResults
            alternativeDesign={ isSmartAgent }
            label={ LABELS.NO_RESULT }
          />
        </div>
      </StyledWrapper>
    );
  }

  const renderTrips = () => {
    const renderList = () => (
      <List
        isLittle
        className={ styles.list }
        renderItem={ (trip: any, index: number) => (
          <TripAgent
            key={ trip.Id }
            value={ trip }
            index={ index }
            handleGoToTrip={ handleGoToTrip }
            max={ trips.length === COUNT_TRIP.THREE }
          />
        ) }
        items={ trips }
        onClickItem={ ({ Id }) => handleGoToTrip(Id) }
      />
    );

    const renderSubList = (classname: string) => (
      <>
        <div className={ styles.wrapper_title }>
          <TitleTrips isShow/>
        </div>
        <div className={ `${styles.wrapper_uncomming} ${classname}` }>
          <Icon type='luggage'/>
          <Text type='bold_16' className={ styles.text }>{ LABELS.COMMING_TRIP } </Text>
        </div>
        { renderList() }
      </>
    );

    if (trips.length === COUNT_TRIP.ONE) {
      return renderSubList(styles.upcomming_two);
    }

    if (trips.length === COUNT_TRIP.TWO) {
      return renderSubList(styles.upcomming_one);
    }

    return renderList();
  };

  return (
    <StyledWrapper className={ styles.wrapper }>
      { renderTrips() }
    </StyledWrapper>
  );
};

export default TripsPanel;
